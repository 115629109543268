import { Box, NativeBaseProvider } from 'native-base';
import { ToastContainer } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const App = (props) => {
	const { childComp } = props;
	const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHERS_KEY}`);
	return (
		<Elements stripe={stripePromise}>
			<NativeBaseProvider>
				<ToastContainer />
				{childComp}
			</NativeBaseProvider>
		</Elements>
	);
};

export default App;
