import { gql } from "@apollo/client";



export const VerifySignInOtp=gql`
mutation($otp:String!){
    checkResetOtp(otp:$otp){
      success
      message
    }
  }
  

`