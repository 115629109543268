import React from 'react';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import ViewNotifications from '../../components/Notifications/ViewNotification';
const Notifications = () => {
	return (
		<Sidebar
			activeText='Notifications'
			// activeSubText='Subscribers'
			childComponent={<ViewNotifications />}
		/>
	);
};
export default Notifications;
