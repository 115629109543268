import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Modal,
  Input,
  Radio,
} from "native-base";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { AddSevicersModal } from "./AddServicesModal/AddServicesModal";

import ProfileTitle from "../ProfileTitle";

export const AddTeamModal = (props) => {
    const {modalFlag,close}=props;
    const [value, setValue] = useState(1);
  return (  
      <Modal
        isOpen={modalFlag}
        bg={"#00000070 "}
        size={"full"}
        w={{xl: '35%', lg: "40%", base: "90%" }}
        m={"auto"}
        h={"50%"}
        onClose={close}
      >
        <Box w={"100%"} bg={"#141313"} rounded={"lg"}>
          <HStack w={"95%"} alignSelf={"center"} my={3}>
            <HStack w={"90%"}>
              <Box alignSelf={"center"}>
                <BusinessCenterIcon sx={{ color: "#AFAFAF" }} />
              </Box>
              <Heading
                px={3}
                color={"#E3E3E3"}
                fontWeight={"normal"}
                fontSize={{ lg: "2xl", base: "lg" }}
              >
                Add Services
              </Heading>
            </HStack>
            <Button
              w={"10%"}
              variant={"ghost"}
              p={0}
              justifyContent={'flex-end'}
              onPress={close}
            >
              <CloseIcon sx={{ color: "#AFAFAF" }} />
            </Button>
          </HStack>
          <Divider />
          <Box w={"95%"} alignSelf={"center"}>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Name
              </Heading>
              <Input
                placeholder={"Enter Name"}
                width={"100%"}
                py={2.5}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
              />
            </Box>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                What permissions would you like over these users?
              </Heading>
              <Input
                placeholder={"Enter your Price"}
                width={"100%"}
                py={2.5}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
                InputRightElement={
                  <HStack alignSelf={"center"}>
                    <Heading
                      fontSize={"md"}
                      fontWeight={"normal"}
                      color={"#afafaf"}
                    >
                      Artist
                    </Heading>
                    <KeyboardArrowDownOutlinedIcon sx={{ color: "#afafaf" }} />
                  </HStack>
                }
              />
            </Box>
            <Box mt={2} mb={4}>
              <Radio.Group
                name="myRadioGroup"
                accessibilityLabel="favorite number"
                value={value}
                onChange={(nextValue) => {
                  setValue(nextValue);
                }}
              >
                <HStack flexWrap={"wrap"} flexGrow={1} alignSelf={"flex-start"}>
                  <Radio size="sm" value={1} my={1} colorScheme="emerald">
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      Full Access
                    </Heading>
                  </Radio>
                  <Radio
                    size="sm"
                    value={2}
                    my={1}
                    mx={1}
                    colorScheme="emerald"
                  >
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      Manager
                    </Heading>
                  </Radio>
                  <Radio
                    size="sm"
                    value={3}
                    my={1}
                    mx={1}
                    colorScheme="emerald"
                  >
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      A&R
                    </Heading>
                  </Radio>
                  <Radio
                    size="sm"
                    value={4}
                    my={1}
                    mx={1}
                    colorScheme="emerald"
                  >
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      Legal
                    </Heading>
                  </Radio>
                  <Radio size="sm" value={5} my={1} colorScheme="emerald">
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}

                    >
                      No Permissions
                    </Heading>
                  </Radio>
                </HStack>
              </Radio.Group>
            </Box>
            <HStack
              my={5}
              alignSelf={"center"}
              w={"60%"}
              justifyContent={"space-between"}
            >
              <Button
                w={"40%"}
                variant={"ghost"}
                borderWidth={1}
                borderColor={"#AFAFAF"}
                _pressed={{ bg: "transparent", borderColor: "#AFAFAF" }}
              >
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  Cancel
                </Heading>
              </Button>
              <Button w={"40%"} bg={"#2EBB55"}>
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  OK
                </Heading>
              </Button>
            </HStack>
          </Box>
        </Box>
      </Modal>

    
  );
};
