import { useMutation } from '@apollo/client';
import { TandC } from './Mutation';
import { Notification } from '../../NotifyMsg/Notification';
const TermsandConditionsMutation = () => {
	const [sendTermsAndCondition] = useMutation(TandC, {
		onCompleted(data) {
			if (data?.sendTermsAndCondition?.success) {
				Notification('success', data.sendTermsAndCondition.message);
				setTimeout(() => {}, 1400);
			} else {
				Notification('error', data.sendTermsAndCondition.message);
			}
		},
		onError(error) {
			console.log('rttpt');
		},
	});

	return { sendTermsAndCondition };
};

export default TermsandConditionsMutation;
