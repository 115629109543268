import axios from "axios";

export const getSuggestedUsersByUserId = (userId) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_COMPOSITION_URL}/suggestedusers/${userId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  );
};

export const getFeedsByUserId = (userId) => {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_COMPOSITION_URL}/feed/${userId}`)
      .then((response) => resolve(response))
      .catch((err) => reject(err))
  );
};
