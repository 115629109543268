import { gql } from "@apollo/client";

export const REMOVEFAVOURITEARTIST=gql `mutation($artistId:ID!){
    removeFavoriteArtist(artistId:$artistId){
      success
      message
      isTokenExpired
    }
  }
  `
