import { Button, HStack, Image, Text, Box, Heading } from 'native-base';
import axios from 'axios';
import { toast } from 'react-toastify';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import coverImage from '../../assets/Images/songCover.png';
import BasicFeedBackModal from './Modals/BasicFeedBackModal';
import AudioFeedback from './Feedback';
function AudioDetail() {
	const headerkey = `Bearer ${localStorage.getItem('token')}`;
	const path = window.location.href.split('/');
	const lastSegment = path.pop();
	const navigate = useNavigate();
	const [beatPack, setBeatPack] = useState({});
	const [beatPackId, setBeatPackId] = useState(null);
	const [showBasicFeedback, setShowBasicFeeedback] = useState(false);
	const [showHighLevelFeedback, setShowHighLevelFeedback] = useState(false);
	const saveToLibrary = () => {
		var config = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/file/savetolibrary/${beatPackId}`,
			headers: {
				Authorization: `${headerkey}`,
			},
		};
		axios(config)
			.then(function (response) {
				if (response?.data?.auth === false) {
					localStorage.clear();
					navigate('/signin');
				} else {
					toast.success('Pack successfully saved in library');
				}
			})
			.catch(function (error) {
				if (error?.response?.status === 401) {
					localStorage.clear();
					navigate('/signin');
				}
				if (error?.response?.status === 400) {
					toast.error(error?.response?.data?.message);
				}
				console.log(error);
			});
	};
	const downloadFiles = (filename) => {
		if (!beatPack.files) return;
		else {
			const zip = new JSZip();
			const folder = zip.folder('files'); // folder name where all files will be placed in
			beatPack.files.forEach((url) => {
				const blobPromise = fetch(url.file.url).then((r) => {
					if (r.status === 200) return r.blob();
					return Promise.reject(new Error(r.statusText));
				});
				const name = url.file.url.substring(url.signedUrl.lastIndexOf('/') + 1);
				console.log('url.signedUrl', url.file.url);
				console.log(name);
				folder.file(name, blobPromise);
			});
			zip
				.generateAsync({ type: 'blob' })
				.then((blob) => saveAs(blob, filename));
		}
	};
	const viewFiles = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey ? headerkey : null },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/onbeatpackopen/${lastSegment}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					setBeatPackId(result?.beatPackId);
					setBeatPack({
						name: result?.beatpack?.beatPackName,
						cover: result?.beatpack?.beatPackCover,
						tags: result?.beatpack?.tags,
						userImage: result?.userInfo?.profilePic,
						userFirstName: result?.userInfo?.firstname,
						userLastName: result?.userInfo?.lastname,
						isDownload: result?.beatpack?.isDisableDownload,
						isFeedback: result?.beatpack?.isEnableFeedback,
						files: result?.files,
					});
				}
			})
			.catch((error) => console.log('error', error));
	};

	useEffect(() => {
		viewFiles();
	}, []);
	return (
		<Box bg={'#000201'} w={'100%'} h={'100vh'}>
			{showHighLevelFeedback ? (
				<AudioFeedback audioObj={beatPack.files[0]} />
			) : (
				<Box
					justifyContent={'center'}
					alignItems={'center'}
					alignSelf={'center'}
					h={'100vh'}
					w={'100%'}
				>
					<img
						src={beatPack.cover ? beatPack.cover : coverImage}
						style={{
							width: '140px',
							height: '140px',
							borderRadius: 5,
							alignSelf: 'center',
						}}
					/>
					<Text
						w={'60%'}
						fontSize={{ xl: '32px', lg: '30px', md: '24px', base: '14px' }}
						fontWeight={'medium'}
						textAlign={'center'}
						fontFamily={'Poppins'}
						color={'#fff'}
						mt={8}
						mb={5}
					>
						<BasicFeedBackModal
							showBasicFeedback={showBasicFeedback}
							closeBasicFeedback={() => setShowBasicFeeedback(false)}
							setHighLevelFeedback={setShowHighLevelFeedback}
						/>
						{beatPack['name'] || 'Beat Pack'}
					</Text>
					<HStack alignSelf={'center'}>
						<img
							src={
								beatPack.userImage && beatPack.userImage !== 'undefined'
									? beatPack.userImage
									: coverImage
							}
							alt='user cover image'
							style={{
								width: '35px',
								height: '35px',
								borderRadius: 5,
								alignSelf: 'center',
							}}
						/>

						<Text
							fontSize={{ xl: '20px', lg: '18px', md: '16px', base: '12px' }}
							fontWeight={'normal'}
							fontFamily={'Poppins'}
							color={'#afafaf'}
							alignSelf={'center'}
							px={5}
						>
							{(beatPack.userFirstName || ' ') +
								' ' +
								(beatPack.userLastName || '')}
						</Text>
					</HStack>
					<HStack alignSelf={'center'} my={5}>
						{beatPack
							? beatPack?.tags?.slice(0, 2)?.map((tag, index) => (
									<Box
										px={{ xl: 5, lg: 3, base: 1 }}
										py={{ xl: 2 }}
										m={1}
										borderWidth={'1'}
										borderColor={'#AFAFAF'}
										rounded={'md'}
										key={'beatPack_tag' + index}
									>
										<Heading
											fontSize={{ xl: '18px', lg: '16px', base: '10px' }}
											fontWeight={'normal'}
											color={'#afafaf'}
											fontFamily={'Poppins'}
											alignSelf={'center'}
										>
											{tag}
										</Heading>
									</Box>
							  ))
							: ''}
					</HStack>
					<HStack w={'100%'} justifyContent={'center'} my={5}>
						<Button
							bg={'#2EBB55'}
							borderWidth={1}
							borderColor={'#2EBB55'}
							_hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
							_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
							_text={{
								color: '#e3e3e3',
								fontSize: { xl: '24px', lg: '20px', md: '16px', base: '12px' },
								fontFamily: 'Poppins',
								textAlign: 'center',
								textTransform: 'uppercase',
								fontWeight: 'medium',
							}}
							px={{ lg: 8, base: 4 }}
							py={{ xl: 1.5, lg: 1.5, base: '2px' }}
							alignSelf={'center'}
							onPress={() => {
								setShowBasicFeeedback(true);
							}}
						>
							Leave Feedback
						</Button>
						<Button
							disabled={beatPack.isDownload}
							variant='ghost'
							onPress={() => {
								console.log('sdsad');
								downloadFiles(beatPack.name);
							}}
							_hover={{ bg: 'transparent' }}
							_pressed={{ bg: 'transparent' }}
							_focus={{ bg: 'transparent' }}
						>
							<Image
								source={require('../../assets/Images/Download.png')}
								w={'32px'}
								h={'32px'}
								rounded={'full'}
								alignSelf={'center'}
								mx={{ lg: 10, base: 4 }}
							/>
						</Button>
						<Button
							_hover={{ bg: 'transparent' }}
							_pressed={{ bg: 'transparent' }}
							_focus={{ bg: 'transparent' }}
							variant='ghost'
							onPress={() => saveToLibrary()}
						>
							<Image
								source={require('../../assets/Images/add-circle.png')}
								w={'32px'}
								h={'32px'}
								rounded={'full'}
								alignSelf={'center'}
							/>
						</Button>
					</HStack>
				</Box>
			)}
		</Box>
	);
}

export default AudioDetail;
