/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { fontSize } from '@mui/system';
import {
  Box,
  Button,
  CheckIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Menu,
  Text,
} from 'native-base';
import Background from '../../assets/Images/Background.png';
import React, { useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

function Inbox() {
  return (
    <Box w={'100%'} bg={'#fff'}>
      <Image source={Background} w={'100%'} h={'100vh'} zIndex={10} />
      <Box
        w={{ lg: '30%', base: '80%' }}
        alignSelf={'center'}
        alignItems={'center'}
        justifyContent={'center'}
        h={'100vh'}
        bg={'#transparent'}
        zIndex={20}
        position={'absolute'}
      >
        <Box bg={'#fff'} w={'100%'} rounded={'lg'} py={2}>
          <Box
            py={{ lg: 7, base: 5 }}
            px={{ lg: 9, base: 7 }}
            rounded={'full'}
            my={{ lg: 6, base: 4 }}
            alignSelf={'center'}
            bg={'#1c1b1b'}
            _text={{
              fontSize: 'md',
              fontWeight: 'medium',
              color: '#e3e3e3',
            }}
          >
            b
          </Box>
          <Heading
            fontSize={{ xl: 'xl', base: 'md' }}
            fontWeight={'bold'}
            fontFamily={'Poppins'}
            color={'#1c1b1b'}
            textAlign={'center'}
          >
            Beenish Khan
          </Heading>
          <Box my={5}>
            <Menu
              rounded={'lg'}
              borderColor={'#fff'}
              borderWidth={1}
              alignSelf={'center'}
              color={'#afafaf'}
              // fontSize={{ md: '16px' }}
              w={'150px'}
              mx={3}
              _text={{
                color: '#afafaf',
              }}
              background={'#1C1B1B'}
              placement={'bottom '}
              trigger={(triggerProps) => {
                return (
                  <Button
                    variant={'ghost'}
                    rounded={'3xl'}
                    bg={'#1c1b1b'}
                    w={'40%'}
                    px={4}
                    alignSelf={'center'}
                    _hover={{ bg: '#1c1b1b' }}
                    _focused={{ bg: '#1c1b1b' }}
                    _pressed={{ bg: '#1c1b1b' }}
                    {...triggerProps}
                    _text={{
                      color: '#AFAFAF',
                      fontFamily: 'Poppins',
                      fontSize: { xl: 'lg', base: 'sm' },
                    }}
                    endIcon={
                      <KeyboardArrowDownRoundedIcon
                        sx={{ color: '#AFAFAF', fontSize: 20 }}
                      />
                    }
                  >
                    Send files
                  </Button>
                );
              }}
            >
              <Menu.Item
                bg={'#1C1B1B'}
                borderBottomWidth='1px'
                borderBottomColor='#afafaf'
                m={0}
                p={0}
                _focus={{
                  bg: '#1C1B1B',
                }}
                _hover={{
                  bg: '#1C1B1B',
                }}
                _pressed={{
                  bg: '#1C1B1B',
                }}
              >
                <HStack>
                  <Box alignSelf={'center'} px={1}>
                    <UploadFileRoundedIcon
                      sx={{ color: '#AFAFAF', fontSize: 25 }}
                    />
                  </Box>
                  <Heading
                    my={2}
                    px={2}
                    fontSize={{ xl: 'md', base: 'xs' }}
                    fontWeight={'normal'}
                    fontFamily={'Poppins'}
                    textAlign={'left'}
                    textTransform={'capitalize'}
                    color={'#fff'}
                  >
                    File
                  </Heading>
                </HStack>
              </Menu.Item>
              <Menu.Item
                bg={'#1C1B1B'}
                m={0}
                p={0}
                _focus={{
                  bg: '#1C1B1B',
                }}
                _hover={{
                  bg: '#1C1B1B',
                }}
                _pressed={{
                  bg: '#1C1B1B',
                }}
              >
                <HStack>
                  <Box alignSelf={'center'} px={1}>
                    <DriveFolderUploadRoundedIcon
                      sx={{ color: '#AFAFAF', fontSize: 25 }}
                    />
                  </Box>

                  <Heading
                    my={2}
                    px={2}
                    fontSize={{ xl: 'md', base: 'xs' }}
                    fontWeight={'normal'}
                    fontFamily={'Poppins'}
                    textAlign={'left'}
                    textTransform={'capitalize'}
                    color={'#fff'}
                  >
                    Folder
                  </Heading>
                </HStack>
              </Menu.Item>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Inbox;
