import {
	Button,
	Divider,
	HStack,
	Image,
	Input,
	Text,
	VStack,
	Box,
	Heading,
	Checkbox,
	ScrollView,
	Hidden,
	Modal,
} from 'native-base';
import TagIcon from '../Icons/TagIcon';
import KeyandBPM from '../Icons/KeyandBPM';
import TagIconMbl from '../Icons/TagIconMbl';
import KeyandBPMMbl from '../Icons/KeyandBPMMbl';
import detect from 'bpm-detective';
import { toast } from 'react-toastify';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

import coverImg from '../../assets/Images/1cover.png';
import styles from './styles.module.css';
import AudioUpload from '../AudioLibrary/AudioUpload';
import EditFilePopup from './EditFilePopup';
import Pencil from '../Icons/Pencil';
const AudioContext = window.AudioContext || window.webkitAudioContext;
const context = new AudioContext();
const BeatPackCreation = (props) => {
	const {
		openModal,
		closeModal,
		getBeatPacks,
		getAllAudioFiles,
		audioData,
		onhandleSearchLibrary,
		librarySearch,
		onEditFile,
		editFileModal,
		showFileModal,
		showTagModal,
		showKeyBpmModal,
	} = props;

	const myRef = useRef();
	const toastId = useRef(null);
	const headerkey = `Bearer ${localStorage.getItem('token')}`;

	const [bplink, setLink] = useState('');

	const [editFile, setEditFile] = useState(null);
	const [bpId, setbpId] = useState();
	const [showTagList, setShowTagList] = useState([]);
	const [uploadModal, setUploadModal] = useState(false);
	const [activeURL, setActiveURL] = useState('');
	const [tags, setTags] = useState([]);
	const [added, setAdded] = useState(false);
	const [bpFiles, setBpFiles] = useState([]);
	const [addTitle, setAddTitle] = useState('');
	const [addNote, setAddNote] = useState('');
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedAudio, setSelectedAudio] = useState(null);
	const [selectedCover, setSelectedCover] = useState(null);
	const [coverURL, setCoverUrl] = useState();
	const [bpm, setBPM] = useState('');
	const [scale, setScale] = useState('');
	const [k, setKey] = useState('');
	const [audioName, setAudioName] = useState('');
	const [isCreated, setIsCreated] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');
	const [isPlay, setIsPlay] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [activeSong, setActiveSong] = useState({});
	const [addedFiles, setAddedFiles] = useState([]);
	const [showNext, setShowNext] = useState(false);
	const [additionalOptions, setAdditionalOptios] = useState({
		download: false,
		feedback: false,
		notify: false,
		lyrics: false,
	});
	const [myAudio, setMyAudio] = useState(new Audio());
	const showTagsListItems = (id) => {
		if (!showTagList.includes(id)) {
			setShowTagList([...showTagList, id]);
		}
	};

	const getNote = (val) => {
		setAddNote(val.target.value);
	};
	const getTitle = (val) => {
		setAddTitle(val.target.value);
	};

	const toggleState = () => {
		setAdded(!added);
	};

	const addFiles = () => {
		addFiles(addedFiles, bpId);
		addedFiles.map((fId) => {
			var data = {
				fileId: fId,
				beatPackId: bpId,
			};
			var config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/file/addtobeatpack`,
				headers: { Authorization: headerkey },

				data: data,
			};

			axios(config)
				.then(function (response) {
					getBeatPacks();
				})
				.catch(function (error) {
					console.log(error);
				});
		});

		setAddedFiles([]);
		setBpFiles([]);
	};
	const addFuncObj = (file_obj, file_id) => {
		bpFiles.push(file_obj);
		addedFiles.push(file_id);
	};
	const remFuncObj = (file_obj, file_id) => {
		const newArr = bpFiles.filter((file) => file !== file_obj);
		setBpFiles(newArr);
		const arr = addedFiles.filter((file) => file !== file_id);
		setAddedFiles(arr);
	};
	const handleState = () => {
		isCreated === true ? addFiles() : createBeatPack();
	};
	const handleAudioUpload = async (val) => {
		setSelectedAudio(val.target.files[0]);
		setAudioName(val.target.files[0].name);
		const buffer = await val.target.files[0].arrayBuffer();
		const data = await new Promise(function (resolve, reject) {
			context.decodeAudioData(buffer, resolve, reject);
		});
		const bpm = detect(data);
		setBPM(bpm);
		setUploadModal(true);
	};
	const handleInputCover = (val) => {
		setSelectedCover(val.target.files[0]);
		uploadProfile(val.target.files[0]);
	};
	const uploadProfile = (pic) => {
		var data = new FormData();
		data.append('file', pic);
		data.append('type', 'Public');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/uploadprofilepicture`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				setCoverUrl(response.data.file.url);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const uploadFile = (selectedAudio, k, scale, bpm, tags, coverImage) => {
		var data = new FormData();
		data.append('file', selectedAudio);
		data.append('type', 'Public');
		data.append('audioicon', coverImage);
		data.append('BPM', bpm);
		data.append('key', k);
		data.append('scale', scale);
		data.append('tags', tags);
		data.append('isBeatInvitationAccepted', 'false');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/upload`,
			headers: { Authorization: headerkey },
			data: data,
			onUploadProgress: (p) => {
				const progress = p.loaded / p.total;
				if (toastId.current === null) {
					toastId.current = toast('Upload in Progress', { progress });
				} else {
					toast.update(toastId.current, { progress });
				}
			},
		};

		axios(config)
			.then(function (response) {
				getAllAudioFiles();
				toast.success('File Uploaded Successfully');
				setUploadModal(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	function Detailsheading(props) {
		const { name } = props;
		return (
			<Box
				mx={2}
				my={1}
				borderWidth={'1'}
				borderColor={'#AFAFAF'}
				rounded={'md'}
			>
				<Heading
					px={{ xl: 3, lg: 3, base: 2 }}
					py={{ xl: 1.5, lg: 1.5, base: 1 }}
					fontSize={{ xl: '12px', lg: '12px', base: '12px' }}
					fontWeight={'normal'}
					color={'#afafaf'}
					fontFamily={'poppins'}
				>
					{name}
				</Heading>
			</Box>
		);
	}

	const createBeatPack = () => {
		if (addTitle?.trim()?.length > 1) {
			var newProfile = coverURL ? coverURL : coverImg;
			var data = {
				beatPackName: addTitle,
				beatPackCover: newProfile,
				files: addedFiles,
				note: addNote,
				isDisableDownload: additionalOptions['download'],
				isEnableFeedback: additionalOptions['feedback'],
				isNotifyMeAccessed: additionalOptions['notify'],
				allowArtistLyrics: additionalOptions['lyrics'],
			};
			var config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/file/createbeatpack`,
				headers: { Authorization: headerkey },
				data: data,
			};

			axios(config)
				.then(function (response) {
					setErrorMsg('');

					setIsDisable(false);
					toast.success('Created BeatPack Successfully');
					setbpId(response.data.newBeatPack._id);
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			setErrorMsg('Beat pack name is required');
		}
	};

	const copyBeatPack = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getbeatpacklink/${bpId}/false`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				setLink(
					`${process.env.REACT_APP_BASE_URL + result.beatPackLinkwoDomain}`
				);
				navigator.clipboard.writeText(
					process.env.REACT_APP_BASE_URL + result.beatPackLinkwoDomain
				);
				toast.success('Link Copied!');
			})
			.catch((error) => console.log('error', error));
	};
	const pauseAudio = () => {
		myRef?.current?.pause();
		setPlaying(false);
	};
	const startAudio = () => {
		myRef?.current?.play();
	};

	const playAudio = (url, isPlay) => {
		setActiveURL(url);
		setIsPlay(isPlay);
	};

	useEffect(() => {
		if (activeURL && isPlay) {
			myRef.current.play();
			//startAudio();
		} else {
			if (myRef.current) {
				myRef.current.pause();
			}

			//pauseAudio();
		}
	}, [activeURL, isPlay]);

	useEffect(() => {});
	const onUpload = (k, scale, bpm, tags, coverImage) => {
		uploadFile(selectedAudio, k, scale, bpm, tags, coverImage);
	};
	return (
		<>
			<Modal
				isOpen={openModal}
				onClose={closeModal}
				w={{ lg: '80%', base: '90%' }}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.CloseButton
					_hover={{ bg: 'transparent' }}
					_focused={{ bg: 'transparent' }}
					_pressed={{ bg: 'transparent' }}
					mx={5}
				/>
				<HStack w={'100%'} justifyContent={'space-between'}>
					{!showNext && (
						<VStack
							w={{ lg: '38%', md: '38%', base: '100%' }}
							h={'100vh'}
							mb={5}
						>
							<Box
								h={isPlay ? '60vh' : { xl: '100vh', lg: '100vh', base: '70vh' }}
								rounded='md'
								backgroundColor={'#141313'}
								w={'100%'}
							>
								<HStack my={2} justifyContent={'flex-start'}>
									<Box w={'100%'} px='3' py='3' alignSelf={'center'}>
										<Text
											fontSize={{ lg: 'xl', md: 'xl', sm: 'lg' }}
											color='#e3e3e3'
											fontFamily={'Poppins'}
											fontWeight={'normal'}
											alignSelf={'center'}
										>
											Select Files
										</Text>
									</Box>
								</HStack>
								<HStack px={5}>
									<HStack
										backgroundColor={'#1c1b1b'}
										w={'70%'}
										borderRadius={5}
										height={42}
										alignSelf={'center'}
										pr={10}
										_focus={{ bg: 'transparent', borderWidth: 0 }}
									>
										<Input
											w={'100%'}
											color={'#AFAFAF'}
											fontSize={{ md: 'lg', base: 'sm' }}
											borderWidth={0}
											py={0}
											rounded={'lg'}
											borderColor={'transparent'}
											bg={'1C1B1B'}
											placeholder={'Search Pack'}
											value={librarySearch}
											onChangeText={(e) => {
												onhandleSearchLibrary(e);
											}}
											_important={{
												bg: 'transparent',
												_focus: { borderColor: '#2eba55', shadow: 0 },
											}}
											focusOutlineColor={'#2eba55'}
											InputLeftElement={
												<Image
													source={require('../../assets/Icons/searchIcon.png')}
													alt='Search Field'
													color='#ffff'
													size={{ xl: 5, md: 5.5, base: 4 }}
													ml={3}
													alignItems={'center'}
												/>
											}
										/>
									</HStack>
									<HStack w={'30%'} px='1' py='' alignSelf={'center'}>
										<Button
											bg={'#2EBB55'}
											borderWidth={1}
											borderColor={'#2EBB55'}
											_hover={{
												bg: 'transparent',
												_text: { color: '#2EBB55' },
											}}
											_pressed={{
												bg: '#2EBB55',
												_text: { color: '#e3e3e3' },
											}}
											_text={{
												color: '#e3e3e3',
												fontSize: { xl: '18px', base: 'lg' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											w={'90%'}
											py={2}
											alignSelf={'center'}
										>
											<label
												htmlFor='hhj'
												cursor='pointer'
												style={{
													fontSize: 'px',
													color: 'white',
													fontFamily: 'Poppins',
												}}
											>
												Upload
											</label>
											<input
												accept='audio/*'
												type='file'
												id='hhj'
												style={{ display: 'none', visibility: 'none' }}
												onChange={handleAudioUpload}
											/>
										</Button>
									</HStack>
								</HStack>
								<HStack my={5} px={{ lg: 0, base: 5 }}>
									<Box
										px={{ xl: 5, lg: 4, md: 4, base: 0 }}
										alignSelf={'center'}
									>
										<div className={styles.tagIcon}>
											<Button
												size={'sm'}
												py={{ xl: '10px', lg: '10px', md: '8px', base: '5px' }}
												bg='transparent'
												borderWidth={'1'}
												borderColor={'#afafaf'}
												w={{ lg: '100%', base: '80%' }}
												rounded={'md'}
												alignSelf={'center'}
												_text={{
													color: '#afafaf',
													fontSize: {
														lg: '16px',
														md: '16px',
														sm: '14px',
														base: '14px',
													},
													px: 1,
													fontFamily: 'Poppins',
												}}
												_hover={{
													borderColor: '#2eba55',
													bg: '#2EBA55',
													_text: {
														color: '#fff',
													},
												}}
												_focused={{ bg: 'transparent' }}
												_pressed={{ bg: 'transparent' }}
												onPress={() => {
													//setShowTagModal(true);
													showTagModal(true);
												}}
												leftIcon={
													<>
														<Hidden from='base' till='md'>
															<TagIcon />
														</Hidden>
														<Hidden from='md'>
															<TagIconMbl />
														</Hidden>
													</>
												}
											>
												Tags
											</Button>
										</div>
									</Box>
									<Box alignSelf={'center'}>
										<div className={styles.bpmandkey}>
											<Button
												size={'sm'}
												py={{ xl: '10px', lg: '10px', md: '8px', base: '5px' }}
												bg='transparent'
												borderWidth={'1'}
												borderColor={'#afafaf'}
												w={{ lg: '100%', base: '90%' }}
												rounded={'md'}
												alignSelf={'center'}
												justifyContent={'flex-start'}
												_text={{
													color: '#afafaf',
													fontSize: {
														lg: '16px',
														md: '16px',
														sm: '14px',
														base: '14px',
													},

													px: 1,
													fontFamily: 'Poppins',
												}}
												_hover={{
													bg: '#2EBA55',
													borderColor: '#2eba55',
													_text: {
														color: '#fff',
													},
												}}
												_focused={{ bg: 'transparent' }}
												_pressed={{ bg: 'transparent' }}
												onPress={() => {
													showKeyBpmModal(true);
												}}
												leftIcon={
													<>
														<Hidden from='base' till='md'>
															<KeyandBPM />
														</Hidden>
														<Hidden from='md'>
															<KeyandBPMMbl />
														</Hidden>
													</>
												}
											>
												{toggleState === 1 ? 'Key & BPM' : 'BPM'}
											</Button>
										</div>
									</Box>
								</HStack>
								<Divider />

								<ScrollView showsVerticalScrollIndicator={false} mb={5}>
									{audioData && audioData.length > 0 ? (
										audioData.map((audioObj, index) => (
											<>
												<HStack
													key={index}
													w={'90%'}
													alignSelf={'center'}
													justifyContent={'space-between'}
													py={2}
													flexWrap={'wrap'}
												>
													<Box w={'20%'}>
														{audioObj.file.audioIconUrl ? (
															<img
																src={audioObj.file.audioIconUrl}
																alt='cover image'
																onClick={() => {
																	setActiveSong({
																		name: audioObj.file.fileName,
																		url: audioObj.file.url,
																		cover: audioObj.file.audioIconUrl,
																	});
																	playAudio(audioObj.file.url, true);
																}}
																className={styles.coverImg}
															/>
														) : (
															<img
																src={coverImg}
																alt='cover image'
																onClick={() => {
																	setActiveSong({
																		name: audioObj.file.fileName,
																		url: audioObj.file.url,
																		cover: audioObj.file.audioIconUrl,
																	});
																	playAudio(audioObj.file.url, true);
																}}
																className={styles.coverImg}
															/>
														)}
													</Box>
													<VStack w={'70%'} alignSelf={'center'}>
														<Text
															fontSize={{
																xl: '16px',
																lg: '16px',
																sm: '14px',
																base: '14px',
															}}
															color='#fff'
															mx={1}
														>
															{audioObj.file.fileName}
														</Text>
														<HStack width={'100%'} flexWrap='wrap'>
															{audioObj.file.BPM != null && (
																<Detailsheading
																	name={audioObj.file.BPM + ' bpm'}
																/>
															)}
															{audioObj.file.fileKey != null && (
																<Detailsheading name={audioObj.file.fileKey} />
															)}
															{audioObj.file.scale != null && (
																<Detailsheading name={audioObj.file.scale} />
															)}

															{audioObj.file.tags &&
															audioObj.file.tags.length > 0 &&
															!showTagList.includes(audioObj.file._id) ? (
																<Button
																	px={{ xl: 3, lg: 3, base: 2 }}
																	py={{ xl: 1.5, lg: 1.5, base: 1 }}
																	m={1}
																	borderWidth={'1'}
																	borderColor={'#AFAFAF'}
																	_text={{
																		fontSize: {
																			xl: '12px',
																			lg: '12px',
																			base: '12px',
																		},
																		fontWeight: 'normal',
																		color: '#afafaf',
																		fontFamily: 'poppins',
																	}}
																	rounded={'md'}
																	bg='transparent'
																	_hover={{
																		bg: '#2EBA55',
																		color: '#fff',
																		_text: {
																			color: '#fff',
																		},
																	}}
																	_focused={{ bg: 'transparent' }}
																	_pressed={{ bg: 'transparent' }}
																	onPress={() => {
																		showTagsListItems(audioObj.file._id);
																	}}
																>
																	{`+  ${audioObj.file.tags.length} more`}
																</Button>
															) : null}
															{showTagList.includes(audioObj.file._id)
																? audioObj.file.tags.map((tag) => (
																		<Detailsheading name={'# ' + tag} />
																  ))
																: ''}
															<Hidden from='base' till='md'>
																<div className={styles.pencilIcon}>
																	<Button
																		size={'sm'}
																		bg='transparent'
																		borderWidth={'1'}
																		borderColor={'#afafaf'}
																		w={'100%'}
																		rounded={'md'}
																		alignSelf={'center'}
																		_text={{
																			color: '#afafaf',
																			fontSize: {
																				lg: '12px',
																				md: '12px',
																				sm: '12px',
																				base: '12px',
																			},
																			fontFamily: 'Poppins',
																		}}
																		_hover={{
																			cursor: 'pointer',
																			bg: '#2EBA55',
																			_text: {
																				color: '#fff',
																			},
																		}}
																		_focused={{ bg: '#2EBA55' }}
																		_pressed={{ bg: 'transparent' }}
																		onPress={() => {
																			showFileModal();

																			setEditFile(audioObj.file);
																		}}
																		rightIcon={<Pencil />}
																	>
																		Edit
																	</Button>
																</div>
															</Hidden>
															<Hidden from='md'>
																<Button
																	bg='transparent'
																	alignSelf={'center'}
																	_hover={{
																		cursor: 'pointer',
																		bg: '#2EBA55',
																		_text: {
																			color: '#fff',
																		},
																	}}
																	_focused={{ bg: '#2EBA55' }}
																	_pressed={{ bg: 'transparent' }}
																	onPress={() => {
																		showFileModal();
																		setEditFile(audioObj.file);
																	}}
																>
																	<Pencil />
																</Button>
															</Hidden>
														</HStack>
													</VStack>
													<Box w={'10%'} alignSelf={'center'}>
														{bpFiles.includes(audioObj) ? (
															<Button
																onPress={() => {
																	remFuncObj(audioObj, audioObj.file._id);
																	toggleState();
																}}
																p={0}
																py={1.5}
																px={1.5}
																bg={'#2EBA5520'}
																alignSelf={'center'}
																_hover={{ bg: '#2EBA5520' }}
																_focused={{ bg: '#2EBA5520' }}
																_pressed={{ bg: '#2EBA5520' }}
															>
																<DoneRoundedIcon
																	sx={{ color: '#2ebb55', fontSize: 15 }}
																/>
															</Button>
														) : (
															<Button
																onPress={() => {
																	addFuncObj(audioObj, audioObj.file._id);
																	toggleState();
																}}
																bg={'#1C1B1B'}
																p={0}
																py={1}
																px={2.5}
																alignSelf={'center'}
																_hover={{ bg: '#1C1B1B' }}
																_focused={{ bg: '#1C1B1B' }}
																_pressed={{ bg: '#1C1B1B' }}
															>
																+
															</Button>
														)}
													</Box>
												</HStack>
											</>
										))
									) : (
										<HStack mx={5} my={5} justifyContent={'center'}>
											<Text
												fontSize={{
													xl: '18px',
													lg: '18px',
													sm: '12px',
													base: '13px',
												}}
												py={1}
												color='#afafaf'
												fontWeight={'normal'}
											>
												No Files Found
											</Text>
										</HStack>
									)}
								</ScrollView>
							</Box>
							<audio ref={myRef} src={activeURL} />
							{isPlay ? (
								<Box
									w={'100%'}
									mt={5}
									alignSelf={'center'}
									justifyContent={'center'}
									h={{ lg: '30vh', base: '15vh' }}
									rounded='md'
									backgroundColor={'#141313'}
								>
									<HStack
										w={'90%'}
										alignSelf={'center'}
										justifyContent={'space-between'}
										py={2}
										flexWrap={'wrap'}
									>
										<Box w={'20%'} alignSelf={'center'}>
											{activeSong.cover ? (
												<img
													src={activeSong.cover}
													alt='cover image'
													className={styles.coverImg}
												/>
											) : (
												<img
													src={coverImg}
													alt='cover image'
													className={styles.coverImg}
												/>
											)}
										</Box>
										<VStack w={'55%'} alignSelf={'center'}>
											<Text
												fontSize={{
													xl: '16px',
													lg: '16px',
													sm: '14px',
													base: '14px',
												}}
												color='#fff'
											>
												{activeSong['name']}
											</Text>
										</VStack>

										<Button
											onPress={() => {
												//playAudio(activeSong.url, true);
												startAudio();
											}}
											bg={'#898989'}
											// p={0}
											py={2.5}
											px={2.5}
											rounded={'full'}
											alignSelf={'center'}
											_hover={{ bg: '#2EBB55' }}
											_focused={{ bg: '#2EBB55' }}
											_pressed={{ bg: '#2EBB55' }}
										>
											<FaPlay
												size={10}
												style={{
													cursor: 'pointer',
													alignSelf: 'center',
													color: '#1c1b1b',
												}}
											/>
										</Button>
										<Button
											onPress={() => {
												//playAudio(activeSong.url, false);
												pauseAudio();
											}}
											bg={'#898989'}
											p={3.5}
											rounded={'md'}
											alignSelf={'center'}
											_hover={{ bg: '#2EBB55' }}
											_focused={{ bg: '#2EBB55' }}
											_pressed={{ bg: '#2EBB55' }}
										>
											{/* <FaPause
						size={20}
						style={{
						  cursor: 'pointer',
						  alignSelf: 'center',
						  color: 'white',
						}}
					  /> */}
										</Button>
									</HStack>
								</Box>
							) : (
								''
							)}
							<Hidden from='md'>
								<HStack
									w={'100%'}
									alignSelf={'center'}
									justifyContent={'space-between'}
									py={2}
									px={5}
									h={'8vh'}
									flexWrap={'wrap'}
									rounded='md'
									backgroundColor={'#141313'}
									mt={5}
								>
									<Heading
										fontWeight={'normal'}
										fontSize={'14px'}
										fontFamily={'Poppins'}
										color={'#fff'}
										alignSelf={'center'}
									>
										{bpFiles.length + ' Files Selected'}
									</Heading>
									<Button
										px={5}
										py={2}
										bg={'transparent'}
										borderWidth={1}
										borderColor={'#AFAFAF'}
										_text={{
											fontSize: '12px',
											fontWeight: 'normal',
											fontFamily: 'Poppins',
											color: '#AFAFAF',
										}}
										alignSelf={'center'}
										_hover={{ bg: '2eba55', borderColor: '2eba55' }}
										_pressed={{ bg: 'transparent' }}
										_focus={{ bg: 'transparent' }}
										onPress={() => setShowNext(true)}
									>
										Next
									</Button>
								</HStack>
							</Hidden>
						</VStack>
					)}
					<Hidden from='base' till='md'>
						<VStack w={'58%'} h={'100vh'} mb={5}>
							<Box
								w={'100%'}
								rounded='md'
								backgroundColor={'#141313'}
								h={audioData.length < 1 ? '100vh' : '60vh'}
							>
								<HStack px={10} py={5}>
									<VStack>
										<HStack>
											<img
												width={'136px'}
												height={'142px'}
												mx={5}
												alt={'cover'}
												src={
													selectedCover == null
														? coverImg
														: URL.createObjectURL(selectedCover)
												}
											/>
										</HStack>
										<HStack
											variant={'unstyled'}
											px={2}
											rounded={'md'}
											borderWidth={1}
											borderStyle={'solid'}
											borderColor={'#afafaf'}
											my={3}
											p={3}
											justifyContent={'center'}
											cursor={'pointer'}
										>
											<label
												htmlFor='cover'
												style={{
													fontSize: '16px',
													color: '#fff',
													borderRadius: 18,
													fontFamily: 'Poppins',
													backgroundColor: 'transparent',
													cursor: 'pointer',
												}}
											>
												Edit Cover
											</label>
											<input
												accept='image/*'
												type='file'
												id='cover'
												style={{ display: 'none', visibility: 'none' }}
												onChange={handleInputCover}
											/>
										</HStack>
									</VStack>
									<VStack>
										<Input
											placeholder='Untitled Beatpack '
											onChange={getTitle}
											borderRadius={0}
											mx={2}
											w={'100%'}
											variant={'unstyled'}
											_important={{ backgroundColor: 'transparent' }}
											fontSize={{
												xl: '24px',
												lg: '24px',
												md: '16px',
												sm: '12px',
											}}
											fontWeight={500}
											fontFamily={'poppins'}
											color={'#e3e3e3'}
										></Input>

										<Text
											fontSize={{
												lg: '16px',
												sm: '14px',
												base: '14px',
											}}
											py={1}
											px={2}
											color='#FF0000'
										>
											{errorMsg}
										</Text>

										<Input
											placeholder='Add a note'
											onChange={getNote}
											borderRadius={0}
											w={'100%'}
											mx={2}
											variant={'unstyled'}
											_important={{ backgroundColor: 'transparent' }}
											fontSize={{ lg: '16px', md: '12px', sm: '10px' }}
											color={'#afafaf'}
										></Input>
									</VStack>
								</HStack>

								<ScrollView showsVerticalScrollIndicator={false}>
									{audioData.length < 1 && librarySearch.length < 1 ? (
										<AudioUpload header={headerkey} uploadFile={uploadFile} />
									) : (
										''
									)}
									{bpFiles &&
										bpFiles.map((bpFileObj, index) => (
											<HStack
												key={index}
												w={'95%'}
												alignSelf={'center'}
												justifyContent={'space-between'}
												py={2}
												px={10}
												mt={5}
											>
												<Box>
													<img
														src={
															bpFileObj.file.audioIconUrl
																? bpFileObj.file.audioIconUrl
																: coverImg
														}
														className={styles.coverImg}
														alt='file cover image'
													/>
												</Box>

												<Text
													fontSize={{ lg: '18px', sm: '14px' }}
													color='white'
													w={'60%'}
													px={2}
													py={5}
													alignSelf={'center'}
												>
													{bpFileObj.file.fileName}
												</Text>

												<Box w={'10%'} alignSelf={'center'}>
													<Button
														onPress={() => {
															remFuncObj(bpFileObj, bpFileObj.file._id);
															toggleState();
														}}
														p={0}
														py={1.5}
														px={1.5}
														bg={'#2EBA5520'}
														alignSelf={'center'}
														_hover={{ bg: '#2EBA5520' }}
														_focused={{ bg: '#2EBA5520' }}
														_pressed={{ bg: '#2EBA5520' }}
													>
														<DoneRoundedIcon
															sx={{ color: '#2ebb55', fontSize: 15 }}
														/>
													</Button>
												</Box>
											</HStack>
										))}
								</ScrollView>
							</Box>
							{audioData.length < 1 ? (
								''
							) : (
								<HStack
									w={'100%'}
									justifyContent={'center'}
									rounded='md'
									backgroundColor={'#141313'}
									my={5}
									h={'30vh'}
									px={5}
									mt={5}
									alignSelf={'center'}
								>
									<Box w={'55%'} mt={5}>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['downalod']}
												alignItem={'center'}
												p={1}
												mx={2}
												my={1.5}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												onChange={() => {
													setAdditionalOptios({
														...additionalOptions,
														download: !additionalOptions['download'],
													});
												}}
											>
												<Text
													fontSize={{
														xl: '16px',
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Disable Downloads
												</Text>
											</Checkbox>
										</HStack>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['feedback']}
												alignSelf={'center'}
												p={1}
												mx={2}
												my={1.5}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												onChange={() => {
													setAdditionalOptios({
														...additionalOptions,
														feedback: !additionalOptions['feedback'],
													});
												}}
											>
												<Text
													fontSize={{
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Enable Feedback
												</Text>
											</Checkbox>
										</HStack>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['notify']}
												alignSelf={'center'}
												mx={2}
												my={1.5}
												p={1}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												onChange={() => {
													setAdditionalOptios({
														...additionalOptions,
														notify: !additionalOptions['notify'],
													});
												}}
											>
												<Text
													fontSize={{
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Notify me When Accessed
												</Text>
											</Checkbox>
										</HStack>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['lyrics']}
												alignSelf={'center'}
												mx={2}
												my={1.5}
												p={1}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
											>
												<Text
													fontSize={{
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Allow Artist to Write Lyrics
												</Text>
											</Checkbox>
										</HStack>
									</Box>
									<HStack
										w={'45%'}
										justifyContent={'space-between'}
										alignSelf={'self-end'}
										my={5}
										px={3}
									>
										<Button
											onPress={() => {
												handleState();
											}}
											borderWidth={'1'}
											borderColor={'#2EBA55'}
											rounded={'md'}
											w={'45%'}
											py={1}
											bg='transparent'
											_text={{
												color: '#2EBA55',
												fontSize: { xl: '18px', base: 'sm' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											_hover={{ bg: '#2EBA55', _text: { color: '#e3e3e3' } }}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: 'transparent' }}
											alignSelf={'center'}
										>
											Save
										</Button>
										<Button
											isDisabled={isDisable}
											bg={'#2EBB55'}
											borderWidth={1}
											borderColor={'#2EBB55'}
											_hover={{
												bg: 'transparent',
												_text: { color: '#2EBB55' },
											}}
											_pressed={{
												bg: '#2EBB55',
												_text: { color: '#e3e3e3' },
											}}
											_text={{
												color: '#e3e3e3',
												fontSize: { xl: '18px', base: 'sm' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											w={'45%'}
											py={1}
											alignSelf={'center'}
											onPress={() => {
												copyBeatPack();
											}}
										>
											Copy Link
										</Button>
									</HStack>
								</HStack>
							)}
						</VStack>
					</Hidden>
					{showNext && (
						<VStack w={{ lg: '58%', base: '100%' }} h={'100vh'} mb={5}>
							<Box
								w={'100%'}
								rounded='md'
								backgroundColor={'#141313'}
								maxh={audioData.length < 1 ? '100vh' : '60vh'}
							>
								<HStack px={5} pt={10} pb={5}>
									<VStack>
										<HStack>
											<img
												className={styles.editImg}
												mx={5}
												alt={'cover'}
												src={
													selectedCover == null
														? coverImg
														: URL.createObjectURL(selectedCover)
												}
											/>
										</HStack>
										<HStack
											w={{ lg: '136px', base: '70px' }}
											bg={'transparent'}
											rounded={'md'}
											_hover={{ bg: '#2EBA55' }}
											_focused={{ bg: 'transparent' }}
											_pressed={{ bg: 'transparent' }}
											borderWidth={1}
											borderColor={'#afafaf'}
											mt={5}
											py={2}
											style={{
												cursor: 'pointer',
											}}
											alignItems={'center'}
											justifyContent={'center'}
										>
											<label htmlFor='cover' className={styles.editLabel}>
												Edit Cover
											</label>
											<input
												accept='image/*'
												type='file'
												id='cover'
												style={{ display: 'none', visibility: 'none' }}
												onChange={handleInputCover}
											/>
										</HStack>
									</VStack>
									<VStack>
										<Input
											placeholder='Untitled Beatpack '
											onChange={getTitle}
											borderRadius={0}
											mx={2}
											w={'100%'}
											variant={'unstyled'}
											_important={{ backgroundColor: 'transparent' }}
											fontSize={{
												xl: '24px',
												lg: '24px',
												md: '16px',
												sm: '12px',
											}}
											fontWeight={500}
											fontFamily={'poppins'}
											color={'#e3e3e3'}
										></Input>

										<Text
											fontSize={{
												lg: '16px',
												sm: '14px',
												base: '14px',
											}}
											py={1}
											px={2}
											color='#FF0000'
										>
											{errorMsg}
										</Text>

										<Input
											placeholder='Add a note'
											onChange={getNote}
											borderRadius={0}
											w={'100%'}
											mx={2}
											variant={'unstyled'}
											_important={{ backgroundColor: 'transparent' }}
											fontSize={{ lg: '16px', md: '12px', sm: '10px' }}
											color={'#afafaf'}
										></Input>
									</VStack>
								</HStack>

								<ScrollView showsVerticalScrollIndicator={false}>
									{audioData.length < 1 && librarySearch.length < 1 ? (
										<AudioUpload header={headerkey} uploadFile={uploadFile} />
									) : (
										''
									)}
									{bpFiles &&
										bpFiles.map((bpFileObj, index) => (
											<HStack
												key={index}
												w={'95%'}
												alignSelf={'center'}
												justifyContent={'space-between'}
												px={5}
											>
												<Box alignSelf={'center'}>
													<img
														src={
															bpFileObj.file.audioIconUrl
																? bpFileObj.file.audioIconUrl
																: coverImg
														}
														className={styles.coverImg}
														alt='file cover image'
													/>
												</Box>

												<Text
													fontSize={{ lg: '18px', sm: '14px' }}
													color='white'
													w={'60%'}
													px={2}
													py={5}
													alignSelf={'center'}
												>
													{bpFileObj.file.fileName}
												</Text>

												<Box w={'10%'} alignSelf={'center'}>
													<Button
														onPress={() => {
															remFuncObj(bpFileObj, bpFileObj.file._id);
															toggleState();
														}}
														p={0}
														py={1.5}
														px={1.5}
														bg={'#2EBA5520'}
														alignSelf={'center'}
														_hover={{ bg: '#2EBA5520' }}
														_focused={{ bg: '#2EBA5520' }}
														_pressed={{ bg: '#2EBA5520' }}
													>
														<DoneRoundedIcon
															sx={{ color: '#2ebb55', fontSize: 15 }}
														/>
													</Button>
												</Box>
											</HStack>
										))}
								</ScrollView>
							</Box>
							{audioData.length < 1 ? (
								''
							) : (
								<VStack
									w={'100%'}
									justifyContent={'center'}
									rounded='md'
									backgroundColor={'#141313'}
									my={5}
									maxh={'30vh'}
									px={5}
									mt={5}
									alignSelf={'center'}
								>
									<Box w={'55%'} mt={5}>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['downalod']}
												alignItem={'center'}
												p={1}
												mx={2}
												my={1.5}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												onChange={() => {
													setAdditionalOptios({
														...additionalOptions,
														download: !additionalOptions['download'],
													});
												}}
											>
												<Text
													fontSize={{
														xl: '16px',
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Disable Downloads
												</Text>
											</Checkbox>
										</HStack>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['feedback']}
												alignSelf={'center'}
												p={1}
												mx={2}
												my={1.5}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												onChange={() => {
													setAdditionalOptios({
														...additionalOptions,
														feedback: !additionalOptions['feedback'],
													});
												}}
											>
												<Text
													fontSize={{
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Enable Feedback
												</Text>
											</Checkbox>
										</HStack>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['notify']}
												alignSelf={'center'}
												mx={2}
												my={1.5}
												p={1}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												onChange={() => {
													setAdditionalOptios({
														...additionalOptions,
														notify: !additionalOptions['notify'],
													});
												}}
											>
												<Text
													fontSize={{
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Notify me When Accessed
												</Text>
											</Checkbox>
										</HStack>
										<HStack w={'100%'} alignSelf={'center'}>
											<Checkbox
												value={additionalOptions['lyrics']}
												alignSelf={'center'}
												mx={2}
												my={1.5}
												p={1}
												colorScheme={'green'}
												bg={'#2ebb5520'}
												borderColor={'transparent'}
												_checked={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
												_hover={{
													borderColor: 'transparent',
													bg: '#2EBA55',
												}}
											>
												<Text
													fontSize={{
														lg: '16px',
														md: '14px',
														base: '12px',
													}}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													alignSelf={'center'}
													color='#fff'
													mx={2}
												>
													Allow Artist to Write Lyrics
												</Text>
											</Checkbox>
										</HStack>
									</Box>
									<HStack
										w={'65%'}
										justifyContent={'space-between'}
										alignSelf={'self-end'}
										my={5}
									>
										<Button
											onPress={() => {
												handleState();
											}}
											borderWidth={'1'}
											borderColor={'#2EBA55'}
											rounded={'md'}
											w={'45%'}
											py={1}
											bg='transparent'
											_text={{
												color: '#2EBA55',
												fontSize: { xl: '18px', base: 'sm' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											_hover={{ bg: '#2EBA55', _text: { color: '#e3e3e3' } }}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: 'transparent' }}
											alignSelf={'center'}
										>
											Save
										</Button>
										<Button
											isDisabled={isDisable}
											bg={'#2EBB55'}
											borderWidth={1}
											borderColor={'#2EBB55'}
											_hover={{
												bg: 'transparent',
												_text: { color: '#2EBB55' },
											}}
											_pressed={{
												bg: '#2EBB55',
												_text: { color: '#e3e3e3' },
											}}
											_text={{
												color: '#e3e3e3',
												fontSize: { xl: '18px', base: 'sm' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											w={'45%'}
											py={1}
											alignSelf={'center'}
											onPress={() => {
												copyBeatPack();
											}}
										>
											Copy Link
										</Button>
									</HStack>
								</VStack>
							)}
						</VStack>
					)}
				</HStack>
			</Modal>

			{uploadModal ? (
				<EditFilePopup
					openModal={uploadModal}
					closeModal={() => {
						setUploadModal(false);
						setSelectedAudio(null);
						setAudioName(null);
					}}
					isEdit={false}
					data={{ fileName: audioName, BPM: bpm }}
					onUpload={onUpload}
				/>
			) : (
				''
			)}
			{editFileModal ? (
				<EditFilePopup
					openModal={editFileModal}
					isEdit={true}
					closeModal={showFileModal}
					data={editFile}
					onEdit={onEditFile}
				/>
			) : (
				''
			)}
		</>
	);
};

export default BeatPackCreation;
