import React, { useState } from 'react';
import GetPlayListPosition from '../../../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/GetPlayListPosition';
import { Box, HStack } from 'native-base';
import { TableHeaderCol4 } from '../../TalentComponentHeader/TableHeaderCol4';
import { TalentcomponentHeader } from '../../TalentComponentHeader/TalentComponentHeader';
import AreaChart from '../../Graphs/Area';
import moment from 'moment/moment';
import SeeMore from '../../SeeMoreButton/SeeMore';
import { Loader } from '../../../Loader';
import styles from './../styles.module.css';
function SpotifyPlayList({ playList, isLoading,SetPlayLists,sorted,setSorted }) {
	const [seeMore, SetSeeMore] = useState(false);
	
	console.log('playlist', playList);
	const handleSortIcon=()=>{
		setSorted(!sorted)
		console.log("spotify sorted")
		SetPlayLists(playList.sort((a, b) => (a.playlist.latestSubscriberCount) - (b.playlist.latestSubscriberCount)))
	
	}
	return (
		<Box my={5} py={5} bg={'#1C1B1B'} rounded={'lg'}>
			<Box width={'92%'} alignSelf={'center'}>
				<TalentcomponentHeader
					image={require('../../../../assets/TiktokComponents/spotify.png')}
					title={'spotify playlist'}
					subtitle={'Top playlist by number of followers'}
				/>
				<HStack
					flexDirection={{ lg: 'row', base: 'column' }}
					justifyContent={'space-between'}
					flexWrap={'wrap'}
				>
					<Box w={{ lg: '48%', base: '100%' }}>
						<TableHeaderCol4
							isHeader={true}
							showArrow={true}
							title1={'playlist name'}
							title2={'position'}
							title3={'add on'}
							title4={'likes'}
							handleSort={handleSortIcon}
							sorted={sorted}
						/>

						{isLoading && (
							<div className={styles.loaderDiv}>
								<Loader />
							</div>
						)}
						{playList.map(
							(data, index) =>
								index < (seeMore ? 14 : 5) && (
									<TableHeaderCol4
										isHeader={false}
										showArrow={false}
										title1={data?.playlist?.name}
										title2={data?.position}
										title3={moment(data?.entryDate).format('DD MMM YYYY')}
										title4={data?.playlist?.latestSubscriberCount}
									/>
								)
						)}

						<SeeMore seeMore={seeMore} SetSeeMore={SetSeeMore} />
					</Box>

					<AreaChart />
				</HStack>
			</Box>
		</Box>
	);
}

export default SpotifyPlayList;
