import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  Divider,
  useBreakpointValue,
} from "native-base";
import { TableHeaderCol2 } from "../../TalentComponentHeader/TableHeaderCol2";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TalentcomponentHeader } from "../../TalentComponentHeader/TalentComponentHeader";
import { TableHeaderCol4 } from "../../TalentComponentHeader/TableHeaderCol4";
import { TableHeaderCol4e } from "../../TalentComponentHeader/TableHeaderCol4e";
import { TableContentCol4 } from "../../TableContent/TableContentCol4";
import SeeMore from "../../SeeMoreButton/SeeMore";
import { Loader } from "../../../Loader";
import styles from "./../styles.module.css";
export const AirplayDemographics = (props) => {
  const { title, subtitle, isLoading } = props;

  const [countryList, setCountryList] = useState([]);
  const [seeMore, SetSeeMore] = useState(false);
  const [sorted,setSorted]=useState(false)
	const handleSortIcon=()=>{
		setSorted(!sorted)
		console.log("spotify sorted")
		setCountryList(countryList.sort((a, b) => (a.playCount) - (b.playCount)))
	
	}
  useEffect(() => {
    const configOptions = {
      method: "get",
      url: `https://sandbox.api.soundcharts.com/api/v2/song/7d534228-5165-11e9-9375-549f35161576/broadcast-groups?endDate=2020-10-10&offset=0&limit=100" -H "x-app-id: soundcharts" -H "x-api-key: soundcharts`,
      headers: {
        "x-api-key": "soundcharts",
        "x-app-id": "soundcharts",
      },
    };

    axios(configOptions)
      .then((response) => {
        console.log(response.data.items);
  !sorted && setCountryList(response.data.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sorted]);

  return (
    <Box my={5} py={5} bg={"#1C1B1B"} w={"100%"} rounded={"lg"}>
      <Box w={"92%"} alignSelf={"center"}>
        <TalentcomponentHeader image={""} title={title} subtitle={subtitle} />

        <HStack flexDirection={{ xl: "row", base: "column" }}>
          <Box w={{ lg: "48%", base: "100%" }}>
            <TableHeaderCol2
              isHeader={true}
              title1={"country"}
              title2={"This Month"}
              handleSort={handleSortIcon}
							sorted={sorted}
            />
            {isLoading && (
              <div className={styles.loaderDiv}>
                <Loader />
              </div>
            )}

            {countryList.map(
              (data, index) =>
                index < (seeMore ? 14 : 5) && (
                  <Box>
                    <TableHeaderCol2
                      isHeader={false}
                      title1={data.radio.countryName}
                      title2={data.playCount}
                    />
                  </Box>
                )
            )}

            <SeeMore seeMore={seeMore} SetSeeMore={SetSeeMore} />
          </Box>
          <Box
            //  w={"50%"}
            w={{ xl: "60%", base: "100%" }}
          >
            <Image
              source={require("../../../..//assets/TiktokComponents/worldMap.png")}
              w={"100%"}
              h={"350px"}
              resizeMode={"contain"}
              mt="0.5"
            />
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
