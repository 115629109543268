import {
	Button,
	Divider,
	HStack,
	Image,
	Text,
	VStack,
	Radio,
	Switch,
	Center,
	Container,
	Box,
} from 'native-base';
import { useState } from 'react';
import { Modal } from 'native-base';
import React from 'react';
import AudioLibraryHeader from './AudioLibraryHeader/AudioLibraryHeader';
import { Dashboard } from '@mui/icons-material';
import { DashboardHeader } from '../Headers';
// import SideBar from "../SideBar/SideBar";
import { Sidebar } from '../layout/Sidebar/Sidebar.js';
import AudioLibraryBody from './AudioLibraryBody';
import AudioUpload from './AudioUpload';
import AudioLibrarySample from './AudioLibraryMain';

const AudioLibrary = () => {
	return (
		<>
			<Box w={'100%'} bg={'#000'}>
				<HStack w={'100%'}>
					{/* <Box
						display={{ lg: 'block', base: 'none' }}
						// w={{ xl: '17%', lg: '17%', base: '0%' }}
					> */}
					<Sidebar childComponent={<AudioLibrarySample />} />
					{/* </Box> */}
					{/* <Box
						borderLeftWidth={{ xl: 1, lg: 1, md: 0 }}
						borderColor={'#fff'}
						w={{ xl: '83%', lg: '83%', base: '100%' }}
					>
						<DashboardHeader />
						<Divider mt={1} /> */}

					{/* <AudioLibrarySample /> */}
					{/* </Box> */}
				</HStack>
			</Box>
		</>
	);
};
export default AudioLibrary;
