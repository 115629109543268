import { Box, Divider, HStack, Image } from 'native-base';
import React from 'react';
import { useRef } from 'react';
import Footer from '../Footer/Footer';
import GetNotified from '../Getnotified/GetNotified';
import HeaderComp from '../Header/HeaderComp';
import NewLandingPageBanner from './NewLandingPageBanner';
import Services from './Services';

function LandingPageV3() {
  const homeOnClickRef = useRef(null);
  const scrollEffect = (targetRef) => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  return (
    <Box w={'100%'} bg={'#000201'}>
      <HeaderComp scrollFtn={scrollEffect} homeOnClickRef={homeOnClickRef} />
      <NewLandingPageBanner />
      <Services homeOnClickRef={homeOnClickRef} />
      {/* <GetNotified /> */}
      <Footer />
    </Box>
  );
}

export default LandingPageV3;
