import React from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  Divider,
} from "native-base";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CountryFilter from "../Tabs/Airplay/CountryFilter";
import DaysFilter from "../Tabs/Airplay/DaysFilter";
import StationFilter from "../Tabs/Airplay/StationFilter";
import { ThemeProvider,createTheme } from "@mui/material/styles";
import ApplyFiters from "../Tabs/ApplyFiters";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function TalentHeadingWithFilters(props) {
  
    const { image, title, subtitle, data, countryHandler, daysHandler, stationHandler, handler } = props;
    return (
      <Box  bg={"#1C1B1B"} w={"100%"} rounded={"lg"} >
      <HStack  flexDirection={{md:"row",base:"column"}}  justifyContent={{md:'space-between'}} alignItems={{md:'center'}}  > 
        <Box  >
          <HStack py={2}>
          {image !== "" && (
              <Image
              source={image}
              size={{ lg: "6", base: "3" }}
              mt="0.5"
              mr={3}
              />
            )}
          
            <Heading
             
              textTransform={"capitalize"}
              color={"#fff"}
              fontFamily={"Poppins"}
              fontSize={{xl:"2xl", md:"lg", base:"md"}}
              fontWeight={"medium"}
              textAlign={"center"}
            >
              {title}
            </Heading>
          </HStack>
          <Heading
         
            color={"#AFAFAF"}
            fontFamily={"Poppins"}
            fontSize={{xl:"2xl", md:"lg", base:"md"}}
            fontWeight={"normal"}
            textAlign={"left"}
          >
            {subtitle}
          </Heading>
        </Box>
        <HStack flexDirection={{md:"row",base:"column"}} my={5} px={{md: 5}} >
        {/* <ThemeProvider theme={darkTheme}> */}
          <CountryFilter value={data.country} handler={countryHandler} />
          <DaysFilter value={data.days} handler={daysHandler} />
          <StationFilter value={data.stations} handler={stationHandler} />
          <ApplyFiters handler={handler} />
        {/* </ThemeProvider> */}
        </HStack ></HStack>
      </Box>
    );
}

export default TalentHeadingWithFilters