/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Hidden,
	Link,
} from 'native-base';
import React from 'react';
import bag from '../../assets/Publishing/bag.png';
import globe from '../../assets/Publishing/globe.png';
import Tv from '../../assets/Publishing/Tv.png';
import money from '../../assets/Publishing/money.png';
import { SubTitle } from 'chart.js';

function Servicers() {
	return (
		<Box w={'100%'} bg={'#000201'}>
			<Leverage />
			<MoneyDeserve />
			<Different />
			<Box w={'100%'} bg={'#1c1b1b'} py={10} mt={8}>
				<Collect />
			</Box>
			<PublishMusic />
			<Box w={'100%'} bg={'#1c1b1b'} py={10}>
				<Terms />
			</Box>
			<Faq />
		</Box>
	);

	function Header(props) {
		const { title, subtitle } = props;
		return (
			<Box w={'100%'}>
				<Heading
					textTransform={'capitalize'}
					color={'#fff'}
					fontSize={{ xl: '56px', md: '40px', base: '24px' }}
					lineHeight={{ xl: '84px', md: '60px', base: '36px' }}
					my={5}
					fontFamily={'Poppins'}
					fontWeight={'extrabold'}
					textAlign={'left'}
				>
					{title}
				</Heading>
				{subtitle && (
					<Heading
						color={'#AFAFAF'}
						fontFamily={'Poppins'}
						fontSize={{ xl: '24px', lg: '20px', base: '14px' }}
						lineHeight={{ xl: '36px', lg: '30px', md: '26px', base: '21px' }}
						fontWeight={'normal'}
						textAlign={'left'}
						mb={5}
					>
						{subtitle}
					</Heading>
				)}
			</Box>
		);
	}

	function Leverage() {
		return (
			<Box w={'80%'} alignSelf={'center'} my={{ xl: 20, base: 10 }}>
				{/* <Header
          title={'Leverage our Premium Partnerships'}
          subtitle={
            'Our label team finds talent through data, offering tailored label deals and services to amplify the success of tracks with momentum.'
          }
        /> */}
				<Heading
					textTransform={'capitalize'}
					color={'#fff'}
					fontSize={{ xl: '56px', md: '40px', base: '24px' }}
					lineHeight={{ xl: '84px', md: '60px', base: '36px' }}
					my={5}
					fontFamily={'Poppins'}
					fontWeight={'extrabold'}
					textAlign={'center'}
				>
					Leverage our Premium Partnerships
				</Heading>
				<Heading
					w={'80%'}
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ xl: '24px', lg: '20px', base: '14px' }}
					lineHeight={{ xl: '36px', md: '26px', base: '21px' }}
					fontWeight={'normal'}
					alignSelf={'center'}
					textAlign={'center'}
					mb={5}
				>
					Our label team finds talent through data, offering tailored label
					deals and services to amplify the success of tracks with momentum.
				</Heading>

				<HStack w={'100%'} my={5} justifyContent={'space-between'}>
					<Box w={'15%'} alignSelf={'center'}>
						<Image
							source={require('../../assets/Publishing/partner1.png')}
							resizeMode={'contain'}
							size={{ '2xl': 32, xl: 24, lg: 20, base: 12 }}
							alignSelf={'center'}
						/>
					</Box>
					<Box w={'15%'} alignSelf={'center'}>
						<Image
							source={require('../../assets/Publishing/partner2.png')}
							resizeMode={'contain'}
							size={{ '2xl': 32, xl: 24, lg: 20, base: 12 }}
						/>
					</Box>
					<Box w={'15%'} alignSelf={'center'}>
						<Image
							source={require('../../assets/Publishing/partner3.png')}
							resizeMode={'contain'}
							size={{ '2xl': 40, xl: 32, lg: 20, base: 12 }}
						/>
					</Box>
					<Box w={'15%'} alignSelf={'center'}>
						<Image
							source={require('../../assets/Publishing/partner4.png')}
							resizeMode={'contain'}
							size={{ '2xl': 40, xl: 32, lg: 20, base: 12 }}
						/>
					</Box>
					<Box w={'15%'} alignSelf={'center'}>
						<Image
							source={require('../../assets/Publishing/partner5.png')}
							resizeMode={'contain'}
							size={{ '2xl': 32, xl: 24, lg: 20, base: 12 }}
						/>
					</Box>
					<Box w={'15%'} alignSelf={'center'}>
						<Image
							source={require('../../assets/Publishing/partner6.png')}
							resizeMode={'contain'}
							size={{ '2xl': 32, xl: 24, lg: 20, base: 12 }}
						/>
					</Box>
				</HStack>
				<Box w={'25%'} py={5} alignSelf={'center'}>
					<Image
						source={require('../../assets/Publishing/partner7.png')}
						resizeMode={'contain'}
						w={'100%'}
						h={{ '2xl': '90px', xl: '70px', lg: '50px', base: '30px' }}
					/>
				</Box>
			</Box>
		);
	}

	function MoneyDeserve() {
		return (
			<Box w={'100%'} alignSelf={'center'}>
				<Box w={'80%'} alignSelf={'center'}>
					<Box
						w={{ '2xl': '50%', xl: '60%', lg: '50%', md: '65%', base: '100%' }}
						alignSelf={'flex-start'}
					>
						<Header
							title={'Get The Money You Deserve'}
							subtitle={
								"Register your works with more than 60 global PRO's and CMO's tostart collecting earned revenue from music you write and compose."
							}
						/>
						{/* <Heading
              textTransform={'capitalize'}
              color={'#fff'}
              fontSize={{ xl: '56px', md: '40px', base: '30px' }}
              lineHeight={{ xl: '84px', md: '60px', base: '30px' }}
              mt={5}
              fontFamily={'Poppins'}
              fontWeight={'extrabold'}
              textAlign={'left'}
            >
              Get The Money You
            </Heading>
            <Heading
              textTransform={'capitalize'}
              color={'#fff'}
              fontSize={{ xl: '56px', md: '40px', base: '30px' }}
              lineHeight={{ xl: '84px', md: '60px', base: '30px' }}
              mb={5}
              fontFamily={'Poppins'}
              fontWeight={'extrabold'}
              textAlign={'left'}
            >
              Deserve
            </Heading>
            <Heading
              color={'#AFAFAF'}
              fontFamily={'Poppins'}
              fontSize={{ xl: '24px', base: '16px' }}
              lineHeight={{ xl: '36px', md: '26px', base: '15px' }}
              fontWeight={'normal'}
              textAlign={'left'}
              mb={5}
            >
              Register your works with more than 60 global PRO's and CMO's to
              start collecting earned revenue from music you write and compose.
            </Heading> */}
					</Box>
				</Box>
				<HStack
					w={'80%'}
					alignSelf={'center'}
					mb={5}
					mt={{ xl: 20, base: 10 }}
					justifyContent={'space-between'}
					flexGrow={1}
					flexWrap={'wrap'}
				>
					<MoneyCard
						title={'1'}
						subtitle={'Sign Up and Transfer your Catalog'}
					/>
					<MoneyCard
						title={'2'}
						subtitle={
							"We'll work with every streaming platform to collect every dollar you're missing out on"
						}
					/>
					<MoneyCard
						title={'3'}
						subtitle={
							'Get paid! You do the hard part of creating new music while we handle the business side of things.'
						}
					/>
				</HStack>
			</Box>
		);
	}

	function MoneyCard(props) {
		const { title, subtitle } = props;
		return (
			<Box
				w={{ xl: '32%', md: '32%', base: ' 45%' }}
				h={{ xl: '310px', lg: '200px', md: '200px', base: '170px' }}
				bg={'#1c1b1b'}
				rounded={'md'}
				mb={5}
			>
				<Heading
					textTransform={'uppercase'}
					color={'#2EBB55'}
					fontFamily={'Poppins'}
					fontSize={{ xl: '5xl', md: '4xl', base: '2xl' }}
					lineHeight={{ xl: '72px', md: '50px', base: '30px' }}
					fontWeight={'normal'}
					textAlign={'center'}
					my={{ xl: 7, base: 4 }}
				>
					{title}
				</Heading>
				<Heading
					color={'#fff'}
					fontFamily={'Poppins'}
					fontSize={{ xl: '24px', lg: '20px', base: '12px' }}
					lineHeight={{ xl: '36px', md: '26px', base: '15px' }}
					fontWeight={'normal'}
					textAlign={'center'}
					alignSelf={'center'}
					w={'90%'}
					mb={5}
				>
					{subtitle}
				</Heading>
			</Box>
		);
	}

	function Different() {
		return (
			<HStack
				w={'80%'}
				alignSelf={'center'}
				flexGrow={1}
				flexWrap={'wrap'}
				my={{ xl: 20, base: 10 }}
			>
				<Box w={{ xl: '60%', lg: '62%', base: '100%' }}>
					<Header
						title={"How We're Different"}
						subtitle={
							'United Market is not only focused on making sure you collect all your compositions both from PROS and all content monetizable platforms. We also provide you placement opportunities to aid in increasing your catalog. We want to put more money in your pocket and also make sure you are always protected.'
						}
					/>
					{/* <Heading
            textTransform={'capitalize'}
            color={'#fff'}
            fontSize={{ xl: '56px', md: '40px', base: '30px' }}
            my={5}
            fontFamily={'Poppins'}
            fontWeight={'extrabold'}
            textAlign={'left'}
          >
            How We're Different
          </Heading>
          <Heading
            color={'#AFAFAF'}
            fontFamily={'Poppins'}
            fontSize={{ xl: '24px', base: '16px' }}
            lineHeight={{ xl: '36px', md: '26px', base: '15px' }}
            fontWeight={'normal'}
            textAlign={'left'}
            mb={5}
          >
            United Market is not only focused on making sure you collect all
            your compositions both from PROS and all content monetizable
            platforms. We also provide you placement opportunities to aid in
            increasing your catalog. We want to put more money in your pocket
            and also make sure you are always protected.
          </Heading> */}
				</Box>
				<Box
					w={{ xl: '40%', lg: '38%', base: '100%' }}
					alignSelf={'center'}
					// borderWidth={1}
					// borderColor={'#fff'}
				>
					<Image
						source={require('../../assets/Publishing/Computer.png')}
						w={'80%'}
						h={{ xl: '40vh', lg: '50vh', base: '40vh' }}
						resizeMode={'contain'}
						alignSelf={'center'}
					/>
				</Box>
			</HStack>
		);
	}

	function Collect() {
		return (
			<Box w={'80%'} alignSelf={'center'} mb={8}>
				<Box
					w={{ '2xl': '60%', xl: '70%', md: '70%', base: '100%' }}
					alignSelf={'flex-start'}
				>
					<Header
						title={'Collect What Yours'}
						subtitle={
							'Assert your composition rights to collect revenue whenever your song is performed, streamed or reproduced.'
						}
					/>
					{/* <Heading
            textTransform={'capitalize'}
            color={'#fff'}
            fontSize={{ xl: '56px', md: '40px', base: '30px' }}
            mb={15}
            fontFamily={'Poppins'}
            fontWeight={'extrabold'}
            textAlign={'left'}
          >
            Collect What Yours
          </Heading>
          <Heading
            color={'#E3E3E3'}
            fontFamily={'Poppins'}
            fontSize={{ xl: '24px', base: '16px' }}
            lineHeight={{ xl: '36px', md: '26px', base: '15px' }}
            fontWeight={'normal'}
            textAlign={'left'}
            mb={10}
          >
            Assert your composition rights to collect revenue whenever your song
            is performed, streamed or reproduced.
          </Heading> */}
				</Box>
				<HStack
					w={'100%'}
					space={{ xl: 8, lg: 6, base: 0 }}
					flexGrow={1}
					flexWrap={'wrap'}
				>
					<HStack
						w={{ xl: '48%', lg: '48%', base: '100%' }}
						mt={{ xl: 10, base: 5 }}
						justifyContent={'space-between'}
						flexGrow={1}
						flexWrap={'wrap'}
					>
						<CollectCard
							image={bag}
							title={'Pub Admin, Simplified'}
							subtitle={
								'The United Market platform allows you to collect revenue from your compositions. Once you provide the metadata, we will handle the rest.'
							}
						/>
						<CollectCard
							image={globe}
							title={'Global Registration & Licensing'}
							subtitle={
								'Our publishing team will register all of your works to YouTube Content ID as well as global PROs and CMOs, such as The MLC, BMI, PRS, and ASCAP.'
							}
						/>
					</HStack>
					<HStack
						w={{ xl: '48%', lg: '48%', base: '100%' }}
						mt={{ xl: 10, base: 5 }}
						justifyContent={'space-between'}
						flexGrow={1}
						flexWrap={'wrap'}
					>
						<CollectCard
							image={Tv}
							title={'Exclusive Opportunities With Sync'}
							subtitle={
								'Maximize your earning potential by submitting songs to be pitched for licensing opportunities in TV, Film, Advertisement, and Gaming.'
							}
						/>
						<CollectCard
							image={money}
							title={'Quick & Direct Payments'}
							subtitle={
								'Collect publishing and sync revenue each month from one centralized platform.'
							}
						/>
					</HStack>
				</HStack>
			</Box>
		);
	}
	function CollectCard(props) {
		const { image, title, subtitle } = props;
		return (
			<Box
				w={{ xl: '48%', md: '48%', base: '48%' }}
				h={{
					'2xl': '430px',
					xl: '490px',
					lg: '420px',
					md: '400px',
					base: '350px',
				}}
				bg={'#000201'}
				p={{ xl: 6, md: 5, base: 4 }}
				mb={5}
			>
				<Image source={image} size={10} />
				<Heading
					my={5}
					color={'#fff'}
					fontSize={{ xl: '24px', lg: '20px', base: '14px' }}
					lineHeight={{ xl: '36px', lg: '30px', md: '26px', base: '21px' }}
					fontWeight={'medium'}
					fontFamily={'Poppins'}
					textAlign={'left'}
				>
					{title}
				</Heading>
				<Heading
					color={'#AFAFAF'}
					lineHeight={{ xl: '30px', lg: '26px', md: '24px', base: '18px' }}
					fontSize={{ xl: '20px', lg: '18px', md: '16px', base: '12px' }}
					fontWeight={'normal'}
					fontFamily={'Poppins'}
					textAlign={'left'}
				>
					{subtitle}
				</Heading>
			</Box>
		);
	}

	function PublishMusic() {
		return (
			<Box w={'80%'} alignSelf={'center'} my={{ xl: 10, base: 5 }}>
				<Box
					w={{ '2xl': '100%', xl: '100%', md: '100%', base: '100%' }}
					alignSelf={'flex-start'}
					my={{ xl: 10, base: 5 }}
				>
					<Hidden from='base' till='md'>
						<Heading
							textTransform={'capitalize'}
							color={'#fff'}
							fontSize={{ xl: '56px', md: '40px', base: '24px' }}
							lineHeight={{ xl: '84px', md: '44px', base: '36px' }}
							my={5}
							fontFamily={'Poppins'}
							fontWeight={'extrabold'}
							textAlign={'left'}
						>
							Apply To Publish Your Music Alongside
						</Heading>
						<Heading
							textTransform={'capitalize'}
							color={'#fff'}
							fontSize={{ xl: '56px', md: '40px', base: '30px' }}
							lineHeight={{ xl: '84px', md: '44px', base: '15px' }}
							fontFamily={'Poppins'}
							fontWeight={'extrabold'}
							textAlign={'left'}
						>
							Some Of The Top Producers In The
						</Heading>
						<Heading
							textTransform={'capitalize'}
							color={'#fff'}
							fontSize={{ xl: '56px', md: '40px', base: '30px' }}
							lineHeight={{ xl: '84px', md: '44px', base: '15px' }}
							mb={5}
							fontFamily={'Poppins'}
							fontWeight={'extrabold'}
							textAlign={'left'}
						>
							Game
						</Heading>
					</Hidden>
					<Hidden from='md'>
						<Heading
							textTransform={'capitalize'}
							color={'#fff'}
							fontSize={{ xl: '56px', md: '40px', base: '20px' }}
							lineHeight={{ xl: '84px', md: '44px', base: '36px' }}
							my={5}
							fontFamily={'Poppins'}
							fontWeight={'extrabold'}
							textAlign={'left'}
						>
							Apply To Publish Your Music Alongside Some Of The Top Producers In
							The Game
						</Heading>
					</Hidden>
					<Link>
						<button
							data-tf-popup='F9YFcKqF'
							data-tf-iframe-props='title=Composition Collection'
							data-tf-medium='snippet'
							className='ApplyNowPublishingReverse'
						>
							&nbsp;Apply Now
						</button>
					</Link>
				</Box>
			</Box>
		);
	}
	function Terms() {
		return (
			<Box
				w={'80%'}
				alignSelf={'center'}
				mt={{ xl: 8, base: 4 }}
				mb={{ xl: 16, base: 8 }}
			>
				<Heading
					textTransform={'capitalize'}
					color={'#fff'}
					fontSize={{ xl: '56px', md: '40px', base: '24px' }}
					lineHeight={{ xl: '84px', md: '44px', base: '36px' }}
					mt={5}
					mb={10}
					fontFamily={'Poppins'}
					fontWeight={'extrabold'}
					alignSelf={'center'}
					textAlign={'left'}
				>
					Terms & Prices
				</Heading>
				<Box w={{ xl: '80%', base: '100%' }} alignSelf={'center'}>
					{/* <TermsCard
            title={'$150/Year'}
            subtitle={
              'We charge $150/year per member. This gives you access to our platform+ helps us manage your entire catalog, no matter the size.'
            }
          /> */}
					<TermsCard
						title={'15 %'}
						subtitle={
							'We charge a 15% administration fee for all royalty types around the world. You keep the rest.'
						}
					/>
					<TermsCard
						title={'12 Months'}
						subtitle={
							'We collect royalties on any songs delivered to us in your first 365 days as a United Market member. If you decide to cancel your accounts, we’ve still got your back for the full year.'
						}
					/>
					<TermsCard
						title={'Keep Your Rights'}
						subtitle={
							'United Market leaves copyright ownership and creative control where it belongs - with you. That way you can make important decisions as your career develops rather than feel rushed.'
						}
					/>
				</Box>
			</Box>
		);
	}
	function TermsCard(props) {
		const { title, subtitle } = props;
		return (
			<HStack>
				<Heading
					w={{ xl: '25%', base: '30%' }}
					textTransform={'capitalize'}
					color={'#f3f3f3'}
					fontSize={{ xl: '40px', lg: '30px', md: '24px', base: '16px' }}
					lineHeight={{ xl: '60px', lg: '48px', md: '40px', base: '24px' }}
					alignSelf={'center'}
					// pr={10}
					fontFamily={'Poppins'}
					fontWeight={'bold'}
					textAlign={'left'}
				>
					{title}
				</Heading>
				<Box
					w={{ xl: '75%', base: '70%' }}
					py={{ xl: 8, md: 6, base: 4 }}
					borderLeftWidth={1}
					borderColor={'#AFAFAF'}
				>
					<Heading
						textTransform={'capitalize'}
						color={'#afafaf'}
						fontSize={{ xl: '20px', lg: '16px', md: '14px', base: '10px' }}
						lineHeight={{ xl: '30px', lg: '26px', md: '24px', base: '15px' }}
						fontFamily={'Poppins'}
						pl={{ xl: 20, lg: 10, base: 4 }}
						fontWeight={'normal'}
						textAlign={'left'}
					>
						{subtitle}
					</Heading>
				</Box>
			</HStack>
		);
	}

	function Faq() {
		const [showFaq, setShowFaq] = React.useState();

		const data = [
			{
				id: 1,
				name: 'Do I keep my masters?',
				des: 'Yes. You created it, you keep it! When distributing through United Market you will retain 100% of your masters.',
			},
			{
				id: 2,
				name: 'When can I start collecting my compositions through United Market?',
				des: 'You must apply to collect your royalties through United Market. Please allow us time to review your application.',
			},
			{
				id: 3,
				name: 'What perks do I get for having United Market as my publisher?',
				des: 'We offer our clients exclusive placement and sync opportunities, collection from streaming services worldwide, and quick payouts.',
			},
			{
				id: 4,
				name: 'Do I have to publish through United Market to be a member?',
				des: 'No. You can have a United Market account and not publish through us.',
			},
			{
				id: 5,
				name: 'How long is the contract term?',
				des: 'Our contract lasts for 12 months.',
			},
		];
		const handleFAQDrop = (value) => {
			if (value === showFaq) {
				setShowFaq(null);
			} else {
				setShowFaq(value);
			}
		};
		return (
			<Box
				w={'80%'}
				alignSelf={'center'}
				mt={{ xl: 20, base: 10 }}
				mb={{ xl: 20, base: 10 }}
			>
				<Heading
					textTransform={'capitalize'}
					color={'#fff'}
					fontSize={{ xl: '56px', md: '40px', base: '20px' }}
					lineHeight={{ xl: '84px', md: '44px', base: '36px' }}
					mt={5}
					mb={{ xl: 10, base: 5 }}
					fontFamily={'Poppins'}
					fontWeight={'extrabold'}
					textAlign={'left'}
				>
					Frequently Asked Questions
				</Heading>
				<Heading
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ xl: '24px', lg: '20px', base: '14px' }}
					lineHeight={{ xl: '36px', md: '26px', base: '21px' }}
					fontWeight={'normal'}
					textAlign={'left'}
					mb={5}
				>
					Have questions? We’re here to help.
				</Heading>
				{data.map((item, index) => {
					return (
						<Box key={index} w={'100%'} alignItems={'center'}>
							<Box bg={'#000201'} rounded={'md'} mx={10} py={3} w={'100%'}>
								<HStack w={'100%'} alignItems={'center'}>
									<Heading
										fontSize={{ xl: '18px', lg: '16px', base: '14px' }}
										lineHeight={{ xl: '27px', base: '21px' }}
										color={'#fff'}
										fontWeight={'medium'}
										alignSelf={'center'}
										py={3}
										w={'85%'}
									>
										{item.name}
									</Heading>
									<Button
										variant={'ghost'}
										w={'15%'}
										alignSelf={'center'}
										_pressed={{ bg: 'transparent' }}
										_hover={{ bg: 'transparent' }}
										_focus={{ bg: 'transparent' }}
										onPress={() => {
											handleFAQDrop(item.id);
										}}
										m={0}
										justifyContent={'flex-end'}
									>
										{item.id === showFaq ? (
											<Heading
												fontSize={'xl'}
												color={'#AFAFAF'}
												px={1.5}
												rounded={'full'}
												// borderWidth={1}
												// borderColor={'#afafaf'}
											>
												-
											</Heading>
										) : (
											<Heading
												fontSize={'xl'}
												px={1.5}
												rounded={'full'}
												color={'#AFAFAF'}
												alignSelf={'center'}
												// borderWidth={1}
												// borderColor={'#afafaf'}
											>
												+
											</Heading>
										)}
									</Button>
								</HStack>
								{item.id === showFaq && (
									<Heading
										w={'65%'}
										pb={5}
										color={'#AFAFAF'}
										fontWeight={'normal'}
										fontSize={{ xl: '14px', lg: '12px', base: '10px' }}
										lineHeight={{ xl: '21px', base: '15px' }}
										textAlign={'left'}
									>
										{item.des}
									</Heading>
								)}
							</Box>
							<Divider bg={'#AFAFAF'} />
						</Box>
					);
				})}
			</Box>
		);
	}
}

export default Servicers;
