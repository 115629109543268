/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

function HomeIcon() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9.02 2.83975L3.63 7.03975C3.15049 7.45635 2.75923 7.96473 2.47928 8.53492C2.19934 9.10512 2.03638 9.72558 2 10.3597L2 17.7697C2.00131 18.8868 2.44497 19.9579 3.23393 20.7488C4.02289 21.5396 5.09293 21.9858 6.21 21.9897H17.79C18.9055 21.9863 19.9743 21.5417 20.7631 20.7529C21.5519 19.9641 21.9966 18.8953 22 17.7797V10.4997C21.9623 9.82983 21.7822 9.17564 21.4718 8.58075C21.1614 7.98587 20.7279 7.46395 20.2 7.04975L14.02 2.71675C13.2685 2.23867 12.3917 1.99508 11.5013 2.01698C10.6109 2.03889 9.7471 2.3253 9.02 2.83975V2.83975Z'
				stroke='#AFAFAF'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12 17.9922V14.9922'
				stroke='#AFAFAF'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default HomeIcon;
