/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import { Box, Divider, Heading, HStack, Image, Text } from "native-base";
import { Avatar, Tooltip } from "@mui/material";
function SideCard(props) {
    const {image, title, subtitle} = props;
  return (
    <Box w={"100%"} bg={"#1C1B1B"} my={2} rounded={'lg'} px={3}>
      <Box w={"100%"} alignSelf={"center"}>
        <HStack w={"100%"} py={2}>
          <Box w={"22%"} alignSelf={"center"}>
            <Avatar src={image} alt="" variant="square" style={{height:60, width:60, borderRadius:5}} />
          </Box>
          <Box w={"78%"} h={"100%"} justifyContent="center">
            <Tooltip placement="top" arrow title={title}>
              <Heading
                fontSize={{ xl: "xl", base: "md" }}
                fontWeight={"medium"}
                fontFamily={"Poppins"}
                textAlign={"left"}
                textTransform={"capitalize"}
                color={"#e3e3e3"}
                pb={2}
                style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}
              >
                {title}
              </Heading>
            </Tooltip>
            <Heading
              fontSize={{ xl: "14px", base: "xs" }}
              fontWeight={"normal"}
              fontFamily={"Poppins"}
              textAlign={"left"}
              textTransform={"capitalize"}
              color={"#AFAFAF"}
            >
              {subtitle}
            </Heading>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}

export default SideCard;
