import React, { useEffect } from 'react';
import {
	Box,
	Image,
	Heading,
	HStack,
	Button,
	Divider,
	VStack,
} from 'native-base';
import axios from 'axios';
import moment from 'moment';
import { Loader } from '../../components/Loader';

import profile from '../../assets/header/profile.png';
import { useState } from 'react';
import TwoLineChart from './Graphs/TwoLine';
export const TalentDashboard = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [label, setLabel] = useState([]);
	const [youtubeData, setYoutubeData] = useState([]);
	const [spotifyData, setSpotifyData] = useState([]);
	const [grapgObj, setGraphObj] = useState({});
	const options = {
		opacity: 0.5,
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					display: true,
					borderDash: [8, 2],
					color: '#fff',
				},
			},
		},
		responsive: true,

		plugins: {
			legend: {
				display: false,
			},
		},
	};

	const data = [
		{
			id: 1,
			number: '0',
			name: 'Submitted Opportunities',
			link: '',
		},
		{
			id: 2,
			number: '0',
			name: 'Opportunities Types',
			link: '',
		},
		{
			id: 3,
			number: '0',
			name: 'MY Opportunities',
			link: 'Opportunity Posts',
		},
	];
	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};
	const getGraphObj = () => {
		return {
			labels: label,
			position: 'top',

			datasets: [
				{
					data: spotifyData,

					borderColor: '#2EBA55',
					fill: false,
					backgroundColor: 'rgba(46, 186, 85, 0.3)',
					tension: 0.5,
					borderWidth: 1,
				},
				{
					data: youtubeData,
					borderColor: 'rgba(24, 160, 251, 1)',
					fill: false,
					backgroundColor: 'rgba(24, 160, 251, 1)',
					tension: 0.5,
					borderWidth: 1,
				},
			],
		};
	};
	const getSpotify = async () => {
		try {
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 90)
			).format('YYYY-MM-DD');

			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/song/7d534228-5165-11e9-9375-549f35161576/spotify/popularity?endDate=2021-07-01`
			);
			const response = await axios(config);
			const { items } = response.data;
			let label = items.map((item) => {
				return item.date.split('T')[0];
			});
			setLabel(label);
			let data = items.map((item) => {
				return item.value;
			});
			setSpotifyData(data);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log('spotify data', err);
		}
	};
	const getYoutube = async () => {
		try {
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 90)
			).format('YYYY-MM-DD');

			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/song/7d534228-5165-11e9-9375-549f35161576/youtube/views?endDate=2021-07-01`
			);
			const response = await axios(config);
			const { items } = response.data;

			let data = items.map((item) => {
				let views = 0;
				item.videoPlots.map((val) => {
					views = views + val.value;
				});
				return views;
			});
			setYoutubeData(data);
		} catch (err) {
			console.log('youtube data', err);
		}
	};
	useEffect(() => {
		getSpotify();
		getYoutube();
	}, []);
	return (
		<Box bg={'#000201'} w={'100%'}>
			<Box w={'100%'}>
				<HStack
					bg={'#1C1B1B'}
					my={5}
					py={5}
					px={5}
					flexGrow={1}
					flexWrap={'wrap'}
					rounded={'lg'}
				>
					{data.map((item, index) => (
						<Box w={{ xl: '30%', lg: '30%', md: '85%', base: '85%' }}>
							<VStack w={'80%'} alignSelf={'center'} flex={1}>
								<Box
									bg={'#323333'}
									p={5}
									w={'10%'}
									rounded={'full'}
									my={5}
								></Box>
								<Box>
									<Heading
										width={index === 1 ? '70%' : '100%'}
										color={'#E3E3E3'}
										fontSize={'lg'}
										mb={2}
										fontFamily={'poppins'}
										fontWeight={'normal'}
									>
										{item.name}
									</Heading>
									<Heading
										// pb={item.link === "" ? 5 : 7}
										color={'#AFAFAF'}
										fontSize={'xs'}
										fontFamily={'poppins'}
										fontWeight={'normal'}
									>
										{item.link}
									</Heading>
								</Box>
								<HStack
									w={'100%'}
									my={5}
									flex={1}
									justifyContent={'space-between'}
								>
									<Heading
										color={'#E3E3E3'}
										fontWeight={'medium'}
										fontSize={'3xl'}
										fontFamily={'poppins'}
										alignSelf={'center'}
									>
										{item.number}
									</Heading>
									{/* <Button
                    alignSelf={"center"}
                
                    variant={"ghost"}
                    _pressed={{ bg: "transparent" }}
                  >
                    <Heading
                      color={"#AFAFAF"}
                      alignSelf={"center"}
                      fontFamily={"poppins"}
                      fontSize={"xs"}
                      fontWeight={"normal"}
                    >
                      See All
                    </Heading>
                    <Divider />
                  </Button> */}
								</HStack>
							</VStack>
						</Box>
					))}
				</HStack>
				<HStack
					bg={'#000201'}
					my={5}
					flexGrow={1}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
				>
					<Box
						w={{ xl: '65%', lg: '65%', md: '95%', base: '100%' }}
						alignSelf={'flex-start'}
						bg={'#1C1B1B'}
						rounded={'lg'}
					>
						<TwoLineChart options={options} data={getGraphObj()} />
					</Box>
					<Box
						mt={{ xl: 0, base: 4 }}
						w={{ xl: '30%', lg: '30%', md: '95%', base: '100%' }}
						// alignSelf={'flex-start'}
						bg={'#1C1B1B'}
						rounded={'lg'}
					>
						<Box w={'80%'} p={4}>
							<Box bg={'#323333'} p={5} w={'10%'} rounded={'full'} my={5}></Box>
							<Box flex={1} w={'80%'} pb={5}>
								<Heading
									color={'#E3E3E3'}
									fontSize={'lg'}
									fontFamily={'poppins'}
									fontWeight={'normal'}
								>
									Recieved Submissions
								</Heading>
								<Heading
									pb={5}
									color={'#AFAFAF'}
									fontSize={'xs'}
									fontWeight={'normal'}
								></Heading>
							</Box>
							<HStack w={'100%'} my={5} flex={1}>
								<Heading
									w={'70%'}
									color={'#E3E3E3'}
									fontWeight={'medium'}
									fontSize={'3xl'}
									alignSelf={'center'}
								>
									0
								</Heading>
								{/* <Button
									alignSelf={'center'}
									w={'20%'}
									variant={'ghost'}
									_pressed={{ bg: 'transparent' }}
								>
									<Heading
										color={'#AFAFAF'}
										alignSelf={'center'}
										fontFamily={'poppins'}
										fontSize={'xs'}
										fontWeight={'normal'}
									>
										See All
									</Heading>
									<Divider />
								</Button> */}
							</HStack>
						</Box>
					</Box>
				</HStack>
			</Box>
		</Box>
	);
};
