/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import { Box, Divider, Heading, HStack, Image, Text } from "native-base";
import SideCard from "./SideCard";
import followImg from "../../../assets/Feeds/followImg.png";
import trendingImg from "../../../assets/Feeds/trendingImg.png";
import { useLazyQuery } from "@apollo/client";
import useFeedsQuery from "../../Hooks/Feeds/FeedsQuery";
import { Typography } from "@mui/material";

function Follows() {
  const [loading, followings, getFollowers] = useFeedsQuery();
  React.useEffect(() => {
    getFollowers();
  }, []);
  console.log('followingss data...', followings)
  console.log('loading ...', loading);
  return (
    <Box w={"100%"} bg={"#000201"} my={5}>
      <Heading
        my={5}
        fontSize={{ xl: "2xl", base: "lg" }}
        fontWeight={"medium"}
        fontFamily={"Poppins"}
        color={"#fff"}
        textAlign={"left"}
        textTransform={"capitalize"}
      >
        People You Follow
      </Heading>
      {
        loading ? <Typography style={{color:'#AFAFAF', fontSizr:16, textAlign:'center'}}>Loading ...</Typography> : 
        followings?.length > 0 ? followings.map((following, key) => (
          <React.Fragment key={key}>
            <SideCard image={following?.followed?.profilePic} title={following?.followed?.userName || following?.followed?.firstname + ' ' + following?.followed?.lastname} subtitle={"Followed"}/>
          </React.Fragment>
        )) : <Typography style={{color:'#AFAFAF', fontSizr:16, textAlign:'center'}}>No following found</Typography>
      }
      
     
    </Box>
  );
}

export default Follows;
