import React from "react";
import { Button } from "native-base";
import FormControl from "@mui/material/FormControl";

function ApplyFiters(props) {
  const { handler } = props;
  return (
    <FormControl sx={{alignSelf:"center"}}  className="country_filter">
      {/* <Button  variant="outlined" className='apply_fitlters' onClick={handler}>Apply</Button> */}
      <Button
        onPress={handler}
        mt={{ sm: 0, base: 4 }}
        height={"50px"}
        px={5}
        w={{ base: "125px" }}
        bg={"#2EBB55"}
        _text={{ fontSize: "1rem", fontFamily: "poppins" }}
        fontFamily="poppins"
        _hover={{
          background: "transaprent",
          borderColor: "#2EBB55",
          borderWidth: 2,
          _text: { color: "#2EBB55" },
        }}
      >
        Apply
      </Button>
    </FormControl>
  );
}

export default ApplyFiters;
  