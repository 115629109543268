import { gql } from "@apollo/client";

export const GETFOLLOWINGS = gql`
  query {
    getMyFollowings {
      success
      message
      followings {
        followed {
          _id
          userName
          firstname
          lastname
          email
          phoneno
          countrycode
          profilePic
        }
      }
    }
  }
`;

export const MOSTUPVOTES = gql`
  query {
    catalogItems(
      shopIds: ["cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="]
      sortBy: upVotes
      sortOrder: desc
      first: 3
    ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
        __typename
      }
      edges {
        cursor
        node {
          _id
          ... on CatalogItemProduct {
            product {
              _id
              title
              slug
              description
              vendor
              isLowQuantity
              isSoldOut
              upVotes
              uploadedBy {
                name
                userId
              }
              isBackorder
              variants {
                _id
              }
              metafields {
                description
                key
                namespace
                scope
                value
                valueType
                __typename
              }
              shop {
                currency {
                  code
                  __typename
                }
                __typename
              }
              pricing {
                compareAtPrice {
                  displayAmount
                  __typename
                }
                currency {
                  code
                  __typename
                }
                displayPrice
                minPrice
                maxPrice
                __typename
              }
              primaryImage {
                URLs {
                  thumbnail
                  small
                  medium
                  large
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const YOUTUBESCRIBER = gql`
  query {
    getYoutubeSubscribers {
      success
      message
      youtubeFollowers
      isTokenExpired
    }
  }
`;

export const TWITCHSUBSCRIBER = gql`
  query {
    getTwitchFollowers {
      success
      message
      twitchFollowers
    }
  }
`;

export const SUBSCRIBETOYOUTUBE = gql`
  mutation subscirbeToYoutube($accessToken: String!) {
    addYoutubeAccount(accessToken: $accessToken) {
      success
      message
    }
  }
`;

export const GETFEEDBYID = gql`
  query catalogItemProductQuery($slugOrId: String!) {
    catalogItemProduct(slugOrId: $slugOrId) {
      product {
        _id
        productId
        title
        slug
        description
        vendor
        isLowQuantity
        isSoldOut
        isBackorder
        metafields {
          description
          key
          namespace
          scope
          value
          valueType
        }
        pricing {
          currency {
            code
          }
          displayPrice
          minPrice
          maxPrice
        }
        shop {
          currency {
            code
          }
        }
        primaryImage {
          URLs {
            large
            medium
            original
            small
            thumbnail
          }
          priority
          productId
          variantId
        }
        media {
          priority
          productId
          variantId
          URLs {
            thumbnail
            small
            medium
            large
            original
          }
        }
        tags {
          nodes {
            name
            slug
            position
          }
        }
        variants {
          _id
          variantId
          title
          optionTitle
          index
          pricing {
            compareAtPrice {
              displayAmount
            }
            price
            currency {
              code
            }
            displayPrice
          }
          canBackorder
          inventoryAvailableToSell
          isBackorder
          isSoldOut
          isLowQuantity
          options {
            _id
            variantId
            title
            index
            pricing {
              compareAtPrice {
                displayAmount
              }
              price
              currency {
                code
              }
              displayPrice
            }
            optionTitle
            canBackorder
            inventoryAvailableToSell
            isBackorder
            isSoldOut
            isLowQuantity
            media {
              priority
              productId
              variantId
              URLs {
                thumbnail
                small
                medium
                large
                original
              }
            }
            metafields {
              description
              key
              namespace
              scope
              value
              valueType
            }
            primaryImage {
              URLs {
                large
                medium
                original
                small
                thumbnail
              }
              priority
              productId
              variantId
            }
          }
          media {
            priority
            productId
            variantId
            URLs {
              thumbnail
              small
              medium
              large
              original
            }
          }
          metafields {
            description
            key
            namespace
            scope
            value
            valueType
          }
          primaryImage {
            URLs {
              large
              medium
              original
              small
              thumbnail
            }
            priority
            productId
            variantId
          }
        }
      }
    }
  }
`;

export const ADDFOLLOWER = gql`
  mutation follow($profileId: ID!) {
    onFollow(profileId: $profileId) {
      success
      message
    }
  }
`;
