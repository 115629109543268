import { color, textTransform } from '@mui/system';
import { Box, Button, Divider, Heading, HStack, Image } from 'native-base';
import React from 'react';
import Footer from '../../Footer/Footer';
import GetNotified from '../../Getnotified/GetNotified';
import HeaderComp from '../../Header/HeaderComp';
import AlbumsCard from './AlbumsCard';

const CreditsLayout = (props) => {
	const { title, subtitle, image, recentAlbum, otherAlbum } = props;

	return (
		<Box w={'100%'} bg={'#000201'}>
			<div className='website-header'>
				<HeaderComp />
			</div>
			<Box
				w={{ xl: '60%', lg: '70%', base: '80%' }}
				alignSelf={'center'}
				my={10}
			>
				<Heading
					fontFamily={'Poppins'}
					pb={5}
					textAlign={'center'}
					fontSize={{ xl: '6xl', lg: '5xl', md: '4xl', base: '3xl' }}
					fontWeight={'extrabold'}
					color={'#2EBB55'}
					textTransform={'uppercase'}
				>
					{title}
				</Heading>

				<Heading
					fontFamily={'Poppins'}
					textAlign={'center'}
					fontSize={{ xl: '2xl', lg: '2xl', md: 'xl', base: 'lg' }}
					fontWeight={'normal'}
					color={'#AFAFAF'}
					textTransform={'capitalize'}
				>
					{subtitle}
				</Heading>
			</Box>
			<Box w={{ xl: '95%', base: '90%' }} alignSelf={'center'}>
				<Image
					source={image}
					w={'100%'}
					resizeMode={'cover'}
					h={{ xl: '700px', lg: '500px', md: '400px', base: '300px' }}
					rounded={'lg'}
				/>
				<Box my={5}>
					<Heading
						my={10}
						fontSize={{ xl: '40', lg: '4xl', md: '3xl', base: '2xl' }}
						fontWeight={'medium'}
						fontFamily={'Poppins'}
						textTransform={'uppercase'}
						textAlign={'center'}
						color={'#e3e3e3'}
					>
						RECENT RELEASE
					</Heading>
					<AlbumsCard card={recentAlbum} />
				</Box>
				<Box my={5}>
					<Heading
						my={10}
						fontSize={{ xl: '40', lg: '4xl', md: '3xl', base: '2xl' }}
						fontWeight={'medium'}
						fontFamily={'Poppins'}
						textTransform={'uppercase'}
						textAlign={'center'}
						color={'#e3e3e3'}
					>
						Other Albumbs
					</Heading>
					<AlbumsCard card={otherAlbum} />

					<Button
						variant={'ghost'}
						borderWidth={1}
						borderColor={'#AFAFAF'}
						_hover={{
							bg: '#2EBB55',
							borderColor: '#2EBB55',
							_text: { color: '#e3e3e3' },
						}}
						_pressed={{ bg: 'transparent', _text: { color: '#AFAFAF' } }}
						_text={{
							color: '#AFAFAF',
							fontSize: { xl: '2xl', base: 'lg' },
							fontFamily: 'Poppins',
							textAlign: 'center',
							textTransform: 'capitalize',
							fontWeight: 'medium',
						}}
						px={10}
						py={2}
						my={10}
						alignSelf={'center'}
					>
						See More
					</Button>
				</Box>
			</Box>
			<GetNotified />
			<Footer />
		</Box>
	);
};

export default CreditsLayout;
