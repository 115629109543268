import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { ADDTOFAVOURITESONGS } from "./Mutation";

import { Notification } from "../../../../NotifyMsg/Notification";






const  useFavouriteSongMutation=()=> {


    const [addFavoriteSong,{data,error}]=useMutation(ADDTOFAVOURITESONGS,{
onCompleted(data){
  if (data?.addFavoriteSong?.success) {

    Notification("success", data.addFavoriteSong.message);
    setTimeout(() => {
   

    }, 1400);
  } else {

    Notification("error", data.addFavoriteSong.message);

  }
}
    })


  return {addFavoriteSong}


}

export default useFavouriteSongMutation