import React, { useState, useEffect } from 'react';
import { Box, Button, Heading, HStack, Text, IconButton } from 'native-base';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import {
  addPaymentMethod,
  defaultPaymentMethod,
  deletePaymentMethod,
} from '../../Graphql_services/PaymentsMutation';
import { DeletePayment } from './Modal/DeletePayment';
import { DefualtPayment } from './Modal/DefaultPayment';

import FlagSvg from './cardSvg/Flag';
import VisaSvg from './cardSvg/visa';
import PLaceholderSvg from './cardSvg/Placeholder';
import AmericanSvg from './cardSvg/American';
import MasterCardSvg from './cardSvg/MasterCard';
import HiperCardSvg from './cardSvg/HiperCard';
import UnionPaySvg from './cardSvg/UnionPay';
import JCBSvg from './cardSvg/JCB';
import DiscoverSvg from './cardSvg/Discover';
import DinerClubSvg from './cardSvg/DinerClub';
import DeleteSvg from './cardSvg/Delete';
import EditSvg from './cardSvg/Edit';
function CardDetail(props) {
  const {
    cardNumber,
    expireDate,
    index,
    setEdit,
    edit,
    defaultPayment,
    GetMyPaymentMethod,
    type,
    data,
    id,
  } = props;

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [deletePopup, setDeletePopup] = useState(false);
  const [defaultPopup, setDefaultPopup] = useState(false);

  const [AddPaymentMethod] = useMutation(addPaymentMethod, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.log('AddPaymentMethod', data);
      if (data?.addPaymentMethod?.success) {
        toast.success('Payment Methord Updated successfully');
        GetMyPaymentMethod();
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on  AddPaymentMethod', err);
    },
  });

  const [AddDefaultPaymentMethod] = useMutation(defaultPaymentMethod, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.log('AddDefaultPaymentMethod', data);
      if (data?.setDefaultPaymentMethod?.success) {
        toast.success('Payment Method attached as default successfully');
        GetMyPaymentMethod();
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on  AddDefaultPaymentMethod', err);
    },
  });

  const [DeletePaymentMethod] = useMutation(deletePaymentMethod, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.log('DeletePaymentMethod', data);
      if (data?.deletePaymentMethod?.success) {
        toast.success('Deleted successfully');
        GetMyPaymentMethod();
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on  DeletePaymentMethod', err);
    },
  });

  const handleSubmit = (stripe, elements) => async () => {
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      console.log('[error]', error);
      setErrMsg(error.message);
    } else {
      setErrMsg('');
      console.log('[PaymentMethod]', paymentMethod);
      AddPaymentMethod({
        variables: {
          paymentMethodId: paymentMethod?.id,
        },
      });
    }
  };

  const stripes = useStripe();
  const elements = useElements();

  return (
    <Box borderWidth={1} borderColor={'#323333'} rounded={'md'} my={3}>
      <HStack
        py={3}
        px={{ lg: 3, base: 2 }}
        w={'100%'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        {edit === index ? (
          <form
            onSubmit={handleSubmit(stripes, elements)}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              mx={{ lg: 3, base: 1 }}
              bg={'transparrent'}
              w={{ lg: '85%', base: '75%' }}
              alignSelf={'center'}
            >
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#fff',
                      border: '1px solid #afafaf',
                      borderRadius: '4px',
                      padding: '8px',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                  },
                }}
              />
              {errMsg != '' && (
                <Text
                  mt={10}
                  textTransform='capitalize'
                  color='#FF0000'
                  fontWeight={'normal'}
                  fontFamily='poppins'
                  fontSize={{
                    lg: '1.1rem',
                    md: '0.9rem',
                    base: '0.9rem',
                  }}
                >
                  {errMsg}
                </Text>
              )}
            </Box>
            <Button
              _text={{
                fontFamily: 'Poppins',
                fontWeight: 'normal',
                fontSize: { lg: '14px', base: '10px' },
                color: '#fff',
              }}
              borderWidth={1}
              borderColor={'#2ebb55'}
              p={0}
              py={{ lg: 2, base: 2 }}
              w={{ lg: '10%', base: '20%' }}
              alignSelf={'center'}
              rounded={'lg'}
              bg={'#2ebb55'}
              onPress={handleSubmit(stripes, elements)}
              _hover={{
                bg: 'transparent',
              }}
              _pressed={{ bg: '#2ebb55', _text: { color: '#fff' } }}
            >
              Save
            </Button>
          </form>
        ) : (
          <HStack w={'100%'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <HStack w={{ lg: '70%', base: '100%' }} py={{ base: 2 }}>
              <Box
                w={{ lg: '10%', base: '20%' }}
                alignItems={{ lg: 'center', base: 'flex-start' }}
                p={1}
              >
                {type == 'visa' ? (
                  <VisaSvg width={'60px'} height={'35px'} />
                ) : type == 'mastercard' ? (
                  <MasterCardSvg width={'60px'} height={'35px'} />
                ) : type == 'amex' ? (
                  <AmericanSvg width={'60px'} height={'35px'} />
                ) : type == 'hiper' ? (
                  <HiperCardSvg width={'60px'} height={'35px'} />
                ) : type == 'unionpay' ? (
                  <UnionPaySvg width={'60px'} height={'35px'} />
                ) : type == 'jcb' ? (
                  <JCBSvg width={'60px'} height={'35px'} />
                ) : type == 'discover' ? (
                  <DiscoverSvg width={'60px'} height={'35px'} />
                ) : type == 'diners' ? (
                  <DinerClubSvg width={'60px'} height={'35px'} />
                ) : (
                  <PLaceholderSvg width={'60px'} height={'35px'} />
                )}
              </Box>
              <Box alignSelf={'center'} mx={{ lg: 3, base: 1 }}>
                <Heading
                  alignSelf={'center'}
                  fontSize={{ lg: '20px', base: '14px' }}
                  fontWeight={'medium'}
                  fontFamily={'Poppins'}
                  color={'#fff'}
                >
                  {cardNumber}
                </Heading>

                <Heading
                  fontSize={{ lg: '16px', base: '10px' }}
                  fontWeight={'normal'}
                  fontFamily={'Poppins'}
                  color={'#898989'}
                >
                  {'Expire: ' + expireDate}
                </Heading>
              </Box>
            </HStack>
            <HStack
              justifyContent={'space-between'}
              w={{ lg: '20%', base: '50%' }}
            >
              <IconButton
                icon={
                  <FlagSvg
                    color={defaultPayment ? '#2ebb55' : '#afafaf'}
                    width={'20px'}
                    height={'20px'}
                  />
                }
                p={2}
                rounded={'full'}
                bg={defaultPayment ? '#2ebb5520' : '#323333'}
                _hover={{ bg: defaultPayment ? '#2ebb5550' : '#32333380' }}
                _pressed={{ bg: defaultPayment ? '#2ebb5520' : '#323333' }}
                // disabled={false ? false : true}
                alignSelf={'center'}
                onPress={() => {
                  setDefaultPopup(true);
                }}
              />
              <IconButton
                icon={
                  <DeleteSvg
                    colorSvg={data.length != 1 ? '#ff0000' : '#afafaf'}
                  />
                }
                p={2}
                rounded={'full'}
                bg={data.length != 1 ? '#ff000020' : '#323333'}
                _hover={{ bg: data.length != 1 ? '#ff000050' : '#32333380' }}
                _pressed={{ bg: data.length != 1 ? '#ff000020' : '#323333' }}
                disabled={data.length != 1 ? false : true}
                alignSelf={'center'}
                onPress={() => {
                  setDeletePopup(true);
                }}
              />
              <Button
                _text={{
                  fontFamily: 'Poppins',
                  fontWeight: 'normal',
                  fontSize: { lg: '14px', base: '10px' },
                  color: '#2EBB55',
                }}
                p={0}
                py={2}
                w={{ lg: '40%', base: '40%' }}
                leftIcon={<EditSvg width={'15px'} height={'15px'} />}
                _hover={{ bg: '#2ebb5550' }}
                _pressed={{ bg: '#2ebb5520' }}
                alignSelf={'center'}
                bg={'#2ebb5520'}
                rounded={'3xl'}
                onPress={() => {
                  setEdit(index);
                }}
              >
                Edit
              </Button>
            </HStack>
          </HStack>
        )}
      </HStack>
      <DeletePayment
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={() =>
          DeletePaymentMethod({
            variables: {
              paymentMethodId: id,
            },
          })
        }
      />
      <DefualtPayment
        openModal={defaultPopup}
        closeModal={() => setDefaultPopup(false)}
        defaultFtn={() =>
          AddDefaultPaymentMethod({
            variables: {
              paymentMethodId: id,
            },
          })
        }
      />
    </Box>
  );
}

export default CardDetail;
