import { useLazyQuery, useMutation } from '@apollo/client';
import React from 'react'
import { toast } from 'react-toastify';
import { SUBSCRIBETOYOUTUBE, YOUTUBESCRIBER } from './Query';

function useYoutubeSubscriber() {
  const [isYoutubeLoading, setIsYoutubeLoading] = React.useState(false);
  const [isConnectedYoutube, setIsConnectedYoutube] = React.useState(false);
  const [youtubeSubscriber, setYoutubeSubscriber] = React.useState(0);

  const [getYoutubeSubscriber] = useLazyQuery(YOUTUBESCRIBER, {
    context: { clientName: 'user' },
    fetchPolicy:'no-cache',
    onCompleted(data) {
        setIsYoutubeLoading(false);
        setIsConnectedYoutube(data?.getYoutubeSubscribers?.success);
        setYoutubeSubscriber(data?.getYoutubeSubscribers?.youtubeFollowers);
        // console.log('data...', data);
    },
    onError(err) {
        // console.log('error ..', err);
        setIsYoutubeLoading(false);
    }
  });

  const [subscribeToYoutube] = useMutation(SUBSCRIBETOYOUTUBE, {
    context: {clientName:'user'},
    fetchPolicy:'no-cache',
    onCompleted(data){
        setIsYoutubeLoading(false);
        if(data?.addYoutubeAccount?.success) {
          console.log('subscribe to youtube data...', data);
          setIsConnectedYoutube(true);
        }
        else {
          return toast.error(`You don't have any channel on youtube. Please create a youtube channel in order to connect to youtube.`)
        }
    },
    onError(err) {
        setIsYoutubeLoading(false);
        console.log('err on subscribing youtube...', err)
    }
  })
  
  React.useEffect(() => {
    getYoutubeSubscriber();
  }, [])

  const youtubeSubscribe = async(accessToken) => {
    setIsYoutubeLoading(true);
    // let accessToken = localStorage.getItem('token');
    if(accessToken){
        subscribeToYoutube({
            variables: {
                accessToken
            }
        })
    }
    else {
        toast.error('Session Expired');
        return window.location.href = "/signin";
    }
  }

  return [isYoutubeLoading, isConnectedYoutube, youtubeSubscriber, youtubeSubscribe];
}

export default useYoutubeSubscriber