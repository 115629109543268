import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScrollTo } from 'react-use-window-scroll';
import { GetRoles } from '../../Graphql_services/SignUpMutation';
import {
	Box,
	Container,
	Image,
	Flex,
	Button,
	Menu,
	Pressable,
	HamburgerIcon,
	Heading,
	Hidden,
	HStack,
	Link,
	VStack,
	Badge,
	CircleIcon,
	Divider,
} from 'native-base';
import { Dimensions } from 'react-native-web';
import SidebarItem from '../SideBar/SidebarItem';
import SocialIconTray from '../SideBar/SocialIconTray';
import items from '../SideBar/sidebar.json';
import prefItems from '../SideBar/preferenceMenu.json';
import arrowDown from '../../assets/header/icon/arrow-down.png';
import searchIcon from '../../assets/header/icon/search.png';
import notification from '../../assets/header/icon/notification.png';
import DefaultProfile from '../../assets/header/profile.png';
import avatar from '../../assets/header/avatar.png';
import { useQuery } from '@apollo/client';
export const DashboardHeader = (props) => {
	const navigate = useNavigate(null);
	const { data } = useQuery(GetRoles);
	const profilePic = localStorage.getItem('profilePic');
	const userName = localStorage.getItem('userName');
	const accountType = localStorage.getItem('accountType');
	const scrollTo = useScrollTo();
	const width = Dimensions.get('window').width;
	const height = Dimensions.get('window').height;
	const options = [
		{ name: 'Profile', link: '/profile' },
		{ name: 'Settings', link: '' },
		{ name: 'Ex HitMarker', link: '' },
		{ name: 'Logout', link: '/signin' },
	];

	const navigateTo = (path, index) => {
		if (index === 3) {
			// localStorage.removeItem('token');
			// localStorage.removeItem('accountType');
			// localStorage.removeItem('profilePic');
			// localStorage.removeItem('name');
			// localStorage.removeItem('userName');
			// localStorage.removeItem('coverPhoto');
			localStorage.clear();
			// localStorage.removeItem('coverPhoto');
			//1BwVHdO4iLidODGlZHb5CppTUqQ#user
			//1BwVHdO4iLidODGlZHb5CppTUqQ#user
			//1BwVHdO4iLidODGlZHb5CppTUqQ#user
		}
		navigate(path);
	};
	return (
		<Box py={6} backgroundColor='#000' w={{ xl: '100%', base: '100%' }}>
			<Container w='100%' maxW={{ md: '95%', base: '90%' }}>
				<Flex
					direction='row'
					justifyContent={'center'}
					alignSelf={{ md: 'flex-end', base: 'flex-end' }}
				>
					<Hidden from='base' till='md'>
						<HStack w='100%' alignSelf={'flex-end'}>
							<Box width={'15%'}>
								<Image
									h='50px'
									width='20px'
									resizeMode='contain'
									source={searchIcon}
									alt='image'
								/>
							</Box>
							<Box width={'20%'}>
								<Badge
									alignSelf={'flex-end'}
									mr={0}
									mb={-3}
									zIndex={1}
									bg={'transparent'}
								>
									<CircleIcon color={'#2EBA55'} size={2} />
								</Badge>
								<Image
									h='50px'
									width='20px'
									resizeMode='contain'
									source={notification}
									alt='image'
								/>
							</Box>

							<Box>
								<Menu
									rounded={'lg'}
									borderColor={'#fff'}
									borderWidth={1}
									color={'#afafaf'}
									fontSize={{ md: '16px' }}
									w='220'
									_text={{
										color: '#afafaf',
									}}
									background={'#1C1B1B'}
									trigger={(triggerProps) => {
										return (
											<Pressable
												accessibilityLabel='More options menu'
												{...triggerProps}
											>
												<HStack alignItems={'center'}>
													<Box>
														<Image
															h='50px'
															width='50px'
															resizeMode='cover'
															borderRadius={'50%'}
															source={profilePic ? profilePic : avatar}
															alt='image'
														/>
													</Box>
													&nbsp;&nbsp;
													<VStack>
														<Box>
															<Heading
																color={'#fff'}
																fontSize={{
																	md: '16px',
																	sm: '14px',
																	base: '14px',
																}}
																fontWeight={400}
																fontFamily={'poppins'}
																textTransform={'capitalize'}
																flexWrap={'wrap'}
																noOfLines={1}
																width={'100%'}
															>
																{userName}
															</Heading>
														</Box>
														<HStack>
															<Box>
																<Heading
																	color={'#AFAFAF'}
																	fontSize={{
																		md: '14px',
																		sm: '14px',
																		base: '14px',
																	}}
																	fontWeight={400}
																	fontFamily={'poppins'}
																>
																	{accountType ? accountType : 'Guest'}
																</Heading>
															</Box>
															&nbsp;&nbsp;
															<Box>
																<Image
																	h='20px'
																	width='20px'
																	resizeMode='contain'
																	source={arrowDown}
																	alt='image'
																/>
															</Box>
														</HStack>
													</VStack>
												</HStack>
											</Pressable>
										);
									}}
								>
									{options.map((item, index) => (
										<Menu.Item
											key={'key' + index}
											// href={item.link}

											bg={'#1C1B1B'}
											m={0}
											p={0}
											_focus={{
												bg: '#1C1B1B',
											}}
											_hover={{
												bg: '#1C1B1B',
											}}
										>
											<Button
												onPress={() => navigateTo(item.link, index)}
												variant={'ghost'}
												p={0}
												m={0}
												justifyContent={'center'}
												alignSelf={'stretch'}
												bg={'#1C1B1B'}
												w={'100%'}
												borderBottomWidth='1px'
												borderBottomColor='#afafaf'
												textDecoration={'none'}
												color={'#fff'}
												py={1}
												_text={{
													color: '#fff',
													textAlign: 'center',
													margin: 'auto',
													textDecoration: 'none',
													py: 2,
												}}
												_focus={{
													_text: {
														color: '#2EBA55',
														bg: '#1C1B1B',
													},
													bg: '#1C1B1B',
												}}
												_hover={{
													_text: {
														color: '#2EBA55',
													},
													bg: '#1C1B1B',
												}}
												fontSize={{ md: '16px', base: '14px' }}
											>
												{item.name}
											</Button>
										</Menu.Item>
									))}
								</Menu>
							</Box>
						</HStack>
					</Hidden>

					<Hidden from='md'>
						<Box w={'100%'} overflow={'scroll'} justifyContent='flex-end'>
							<Menu
								overflow={'hidden'}
								backgroundColor='#000'
								top='20px'
								right='0'
								height={`${height}px`}
								width={`${width}px`}
								trigger={(triggerProps) => {
									return (
										<Pressable
											alignSelf={'flex-end'}
											accessibilityLabel='More options menu'
											{...triggerProps}
										>
											<HamburgerIcon justifyContent='flex-end' />
										</Pressable>
									);
								}}
							>
								<Menu.Item p={0} m={0} backgroundColor={'#000'}>
									<Link
										href='#home'
										textDecoration='none'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'flex-start'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='#000'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '16px' },
												fontFamily: 'poppins',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
										>
											Search
										</Button>
									</Link>
								</Menu.Item>
								<Menu.Item p={0} m={0} backgroundColor={'#000'}>
									<Link
										// href='#features'
										textDecoration='none'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											onPress={() =>
												scrollTo({ top: 1700, left: 0, behavior: 'smooth' })
											}
											justifyContent={'flex-start'}
											variant={'ghost'}
											px={0}
											py={2}
											m={0}
											backgroundColor='#000'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '16px' },
												fontFamily: 'poppins',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
										>
											Notification
										</Button>
									</Link>
								</Menu.Item>

								<Menu.Item backgroundColor={'#000'}>
									<Menu
										rounded={'lg'}
										borderColor={'#fff'}
										borderWidth={1}
										color={'#afafaf'}
										fontSize={{ md: '16px' }}
										w='220'
										_text={{
											color: '#afafaf',
										}}
										background={'#000'}
										trigger={(triggerProps) => {
											return (
												<Pressable
													accessibilityLabel='More options menu'
													{...triggerProps}
												>
													<HStack>
														<Box>
															<Image
																h='50px'
																width='50px'
																resizeMode='contain'
																source={profilePic ? profilePic : avatar}
																alt='image'
															/>
														</Box>
														&nbsp;&nbsp;
														<VStack>
															<Box>
																<Heading
																	color={'#fff'}
																	fontSize={{
																		md: '16px',
																		sm: '14px',
																		base: '16px',
																	}}
																	fontWeight={400}
																	fontFamily={'poppins'}
																	flexWrap={'wrap'}
																	noOfLines={1}
																	width={'100%'}
																>
																	{userName}
																</Heading>
															</Box>
															<HStack>
																<Box>
																	<Heading
																		color={'#AFAFAF'}
																		fontSize={{
																			md: '14px',
																			sm: '14px',
																			base: '16px',
																		}}
																		fontWeight={400}
																		fontFamily={'poppins'}
																	>
																		{accountType ? accountType : 'user'}
																	</Heading>
																</Box>
																&nbsp;&nbsp;
																<Box>
																	<Image
																		h='20px'
																		width='20px'
																		resizeMode='contain'
																		source={arrowDown}
																		alt='image'
																	/>
																</Box>
															</HStack>
														</VStack>
													</HStack>
												</Pressable>
											);
										}}
									>
										{options.map((item, index) => (
											<Menu.Item key={'key' + index}>
												<Link>
													<Button
														bg={'#1C1B1B'}
														w={'100%'}
														borderBottomWidth='1px'
														borderBottomColor='#afafaf'
														_text={{
															color: '#fff',
															textAlign: 'center',
															margin: 'auto',
														}}
														_hover={{
															_text: {
																color: '#2EBA55',
																bg: '#1C1B1B',
															},
															backgroundColor: '#1C1B1B',
														}}
														fontSize={{ md: '16px', base: '14px' }}
													>
														{item.name + 'asd'}
													</Button>
												</Link>
											</Menu.Item>
										))}
									</Menu>
								</Menu.Item>

								<Menu.Item p={0} m={0} backgroundColor={'#000'}>
									<Box backgroundColor={'#000'} width={['90%', '90%', '100%']}>
										{items.map((item, index) => (
											<SidebarItem key={index} item={item} />
										))}
										<Divider w={'90%'} alignSelf={'center'} h='0.5'></Divider>
									</Box>
								</Menu.Item>

								<Menu.Item p={0} mt={2} backgroundColor={'000'}>
									<Box backgroundColor={'#000'} width={['90%', '90%', '100%']}>
										{prefItems.map((item, index) => (
											<SidebarItem key={index} item={item} />
										))}
									</Box>
								</Menu.Item>
								<Menu.Item p={0} mt={2} backgroundColor={'000'}>
									<Box backgroundColor={'#000'} paddingBottom={'200px'}>
										<SocialIconTray
											instagramUrl='https://www.instagram.com'
											twitterUrl='https://www.twitter.com'
											discordUrl='https://www.discord.com'
											youtubeUrl='https://www.youtube.com'
										/>
									</Box>
								</Menu.Item>
							</Menu>
						</Box>
					</Hidden>
				</Flex>
			</Container>
		</Box>
	);
};
