import React, { useEffect, useState } from "react";
import { Box, Button, Hidden, HStack, VStack } from "native-base";
import { TableHeaderCol4 } from "../../TalentComponentHeader/TableHeaderCol4";
import GetPlayListPosition from "../../../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/GetPlayListPosition";
import DropDown from "../../../DropdownMenu/DropDown";
import moment from "moment";
import { Loader } from "../../../Loader";
import styles from "./../styles.module.css";
import CountryFilter from "../Airplay/CountryFilter";
import PlatFormDropDown from "./PlatFormDropDown";
import ExportCsv from "../../../../pages/talentDashboard/ExportCsv";
import PlaylistTypes from "./PlaylistTypes";
import CurrentPlaylist from "./CurrentPLaylist";
import ApplyFiters from "../ApplyFiters";
import { TableHeaderCol5 } from "../../TalentComponentHeader/TableHeaderCol5";
const Playlists = () => {
  const [playLists, SetPlayLists] = useState([]);
  const [platform, setPlatform] = useState("spotify");
  const [playlistType, setPlaylistType] = useState("");
  const [currentPlaylist, setCurrentPlaylist] = useState("");
  const [country, setCountry] = useState("all");
  const [applyFilter, SetApplyFilter] = useState(false);
  const [dateOrder, setDateOrder] = useState(false);
  const [seeMore, SetSeeMore] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [csvData, setCSVdata] = useState([]);
  const headers = [
    { label: "PLAYLIST NAME", key: "playListName" },
    { label: "OWNER", key: "owner" },
    { label: "LIKES", key: "likes" },
    { label: "POSITIONS", key: "positions" },
    { label: "ADD ON", key: "Addon" },
  ];
  const data = [
    {
      playListName: "SONGS TO SING IN THE CAR",
      owner: "SPOTIFY",
      likes: 10138392,
      positions: 63,
      Addon: "29 AUG 2019",
    },
    {
      playListName: "ALL OUT 2010S",
      owner: "SPOTIFY",
      likes: 7283736,
      positions: 68,
      Addon: "29 AUG 2019",
    },
    {
      playListName: "MEGA HIT MIX",
      owner: "SPOTIFY",
      likes: 6818667,
      positions: 45,
      Addon: "29 AUG 2019",
    },
    {
      playListName: "POP UP",
      owner: "SPOTIFY",
      likes: 6216278,
      positions: 62,
      Addon: "29 AUG 2019",
    },
    {
      playListName: "THIS IS BILLIE EILISH",
      owner: "SPOTIFY",
      likes: 3642548,
      positions: 24,
      Addon: "29 AUG 2019",
    },
  ];
  // const getdata = () => {
  //   return playLists.map((data, index) => [
  //     ...paylis,
  //     {
  //       playListName: data?.playlist?.name,
  //       owner: data?.playlist?.platform,
  //       likes: data?.playlist?.latestSubscriberCount,
  //       positions: 24,
  //       Addon: "29 AUG 2019",
  //     },
  //   ]);
  // };
  const handleFilter = () => {
    console.log("hell")
    SetApplyFilter(!applyFilter);
  };

  const handleSort=()=>{
setDateOrder(!dateOrder)
  }
  useEffect(() => {
    const GetPlaylistForPlatform = async () => {
      try {
        const apiResponse = await GetPlayListPosition(platform, dateOrder);
        console.log("playlist", apiResponse.data.items);
        setIsloading(false);
        SetPlayLists(apiResponse.data.items);
      } catch (err) {
        console.log(err);
      }
    };
    GetPlaylistForPlatform();
  }, [applyFilter, dateOrder]);
  useEffect(() => {
    console.log("csv", playLists);
    setCSVdata(
      playLists.map((data, index) => {
        return {
          playListName: data?.playlist?.name,
          owner: data?.playlist?.platform,
          likes: data?.playlist?.latestSubscriberCount,
          positions: data?.position,
          Addon: moment(data?.entryDate).format("DD MMM YYYY"),
        };
      })
    );
  }, [playLists]);

  return (
    <Box minH={"100vh"} py={4} w={"100%"}>
      <Box alignItems={"center"}>
        <HStack
          flexDirection={{ xl: "row", lg: "column" }}
          justifyContent={"space-between"}
          w={"100%"}
          alignItems="center"
        >
          <Hidden from="base" till="md">
            <HStack w={{xl:"60%",md:"90%"}} space={2}>
              <PlatFormDropDown setPlatform={setPlatform} />

              <PlaylistTypes />

              <CurrentPlaylist />
              <CountryFilter />
            </HStack>
          </Hidden>

          <Hidden  from="md">
            <HStack
              space={4}
              flexDirection={{ sm: "row", base: "column" }}
     width={"100%"}
              // justifyContent={{ md: "space-between", base: "center" }}
            >
              <VStack w={{sm:"50%",base:"100%"}} alignItems={"center"} space={5}>
                <Box w={"97%"}>      <PlatFormDropDown />
                </Box>
          <Box  w={"97%"}>   <PlaylistTypes /></Box>
             
              </VStack >

              <VStack w={{sm:"50%",base:"100%"}} alignItems={"center"} space={5}>
                <Box  w={"97%"}>          <CurrentPlaylist /></Box>
      
                <Box  w={"97%"}>   <CountryFilter /></Box>
             
              </VStack>
            </HStack>
          </Hidden>
          {/* 
              <Hidden from="sm" till="md">  
  <VStack >
         <PlatFormDropDown />
           
           <PlaylistTypes />
         
 <CurrentPlaylist />
              <CountryFilter />
          
              </VStack></Hidden>     */}
          <HStack mt={{xl:"0",base:"4"}} justifyContent={{xl:"end",lg:"center"}} width={{xl:"40%",md:"50%"}} space={3} alignItems={"center"}>
            <ApplyFiters handler={handleFilter} />
            <Button
              mt={{ sm: "0", base: 4 }}
              // w={{sm:"120px",base:"180px"}}
              height={"50px"}
              px={4}
              bg={"#2EBB55"}
              _text={{ fontSize: "1rem", fontFamily: "poppins" }}
              fontFamily="poppins"
              _hover={{
                background: "transaprent",
                borderColor: "#2EBB55",
                borderWidth: 2,
                _text: { color: "#2EBB55" },
              }}
            >
              <ExportCsv
                // getdata={getdata}
                color="#fff"
                data={csvData}
                headers={headers}
              />
            </Button>
          </HStack>

          {/* <HStack
        
              mt={{ lg: 0, base: 6 }}
              justifyContent={{ md: "start", base: "center" }}
              alignItems={"center"}
            >
             
            </HStack>

            <HStack
         
              mt={{ lg: 0, base: 6 }}
              justifyContent={{ md: "start", base: "center" }}
              alignItems={"center"}
            > */}
          {/* <DropDown showIcon={false} text={"current playlist"}/> */}

          {/* </HStack> */}
        </HStack>
        <Box mt={10} w={"100%"}>
          <TableHeaderCol5
            bg={"#000"}
            isHeader={true}
            title1={"play list name "}
            title4={"positions"}
            title2={"owner"}
            title3={"likes"}
            title5={"add on"}
            handleSort={handleSort}
            dateOrder={dateOrder}
          />
          {isLoading && (
            <div className={styles.loaderDiv}>
              <Loader />
            </div>
          )}
          {playLists.map(
            (data, index) =>
              index < (seeMore ? 14 : 5) && (
                <TableHeaderCol5
                  isHeader={false}
                  title1={data?.playlist?.name}
                  title2={data?.playlist?.platform}
                  title3={data?.playlist?.latestSubscriberCount}
                  title4={data?.position}
                  title5={moment(data?.entryDate).format("DD MMM YYYY")}
                />
              )
          )}
        </Box>
      </Box>

      {/* <TableHeaderCol4  bg={"#000"} isHeader={true} title1={'Mega Exitos 2022 '} title2={'Spotify'} title3={'2,878,993'} title4={"34"} title5={"Today"} />
 <TableHeaderCol4  bg={"#000"}  isHeader={true} title1={'Mega Exitos 2022 '} title2={'Spotify'} title3={'2,878,993'} title4={"34"} title5={"Today"} />
 <TableHeaderCol4  bg={"#000"} isHeader={true} title1={'Mega Exitos 2022 '} title2={'Spotify'} title3={'2,878,993'} title4={"34"} title5={"Today"} />
 <TableHeaderCol4  bg={"#000"} isHeader={true} title1={'Mega Exitos 2022 '} title2={'Spotify'} title3={'2,878,993'} title4={"34"} title5={"Today"} /> */}
    </Box>
  );
};

export default Playlists;
