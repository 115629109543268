/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Box, Divider, Heading, HStack, Image, Text } from 'native-base';
import React from 'react';
import { DashboardHeader } from '../../Headers';
import SideBar from '../../SideBar/SideBar';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import profile from '../../../assets/Feeds/profile.png';
import chat from '../../../assets/Feeds/Chat.png';
import { fontSize } from '@mui/system';
import { Rating } from '@mui/material';
import Waveform from '../../AudioLibrary/Waveform';
import WavesurferPlayer from './WavesurferPlayer';

function ConnectMeTable() {
  function TableHeader(props) {
    const { w, title, alignSelf } = props;
    return (
      <Box w={w}>
        <Heading
          w={'90%'}
          fontSize={{ lg: 'lg', md: 'md', sm: 'sm' }}
          color={'#fff'}
          fontWeight={'normal'}
          fontFamily={'Poppins'}
          textAlign={title == 'User' ? 'left' : 'center'}
          alignSelf={alignSelf}
          px={'3'}
        >
          {title}
        </Heading>
      </Box>
    );
  }

  function TableContent(props) {
    const {
      profile,
      name,
      verified,
      active,
      lastSeen,
      stars,
      genre,
      location,
      label,
      beat,
      kit,
      audioUrl,
      percentage,
    } = props;
    return (
      <HStack my={5}>
        <HStack w={'25%'}>
          <Image source={profile} size={20} mx={1} />
          <Box mx={2}>
            <HStack>
              <Heading
                fontSize={{ xl: '2xl', base: 'lg' }}
                textAlign={'left'}
                fontFamily={'Poppins'}
                fontWeight={'medium'}
                alignSelf={'center'}
                textTransform={'capitalize'}
                color={'#fff'}
                pr={2}
              >
                {name}
              </Heading>
              <Box alignSelf={'center'}>
                {verified && (
                  <VerifiedRoundedIcon
                    style={{
                      paddingLeft: 2,
                      color: '#2EBB55',
                      fontSize: 20,
                    }}
                  />
                )}
              </Box>
            </HStack>
            <Heading
              my={1}
              fontSize={{ xl: 'md', base: 'xs' }}
              textAlign={'left'}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
              color={active ? '#2EBB55' : '#afafaf'}
            >
              {active ? 'Active' : lastSeen}
            </Heading>
            <Rating
              name='read-only'
              value={stars}
              emptyIcon={
                <StarRoundedIcon style={{ color: '#e4e5e9', fontSize: '25' }} />
              }
              readOnly
            />
          </Box>
        </HStack>
        <Box w={'75%'}>
          <HStack w={'100%'}>
            <Box w={'8%'}>
              <Image source={chat} size={30} alignSelf={'center'} />
            </Box>
            <Box w={'18%'}>
              <Heading
                fontSize={{ lg: 'lg', md: 'md', sm: 'sm' }}
                color={'#afafaf'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                textAlign={'center'}
              >
                {genre}
              </Heading>
            </Box>
            <Box w={'18%'}>
              <Heading
                fontSize={{ lg: 'lg', md: 'md', sm: 'sm' }}
                color={'#2EBB55'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                textAlign={'center'}
              >
                {label}
              </Heading>
            </Box>
            <Box w={'18%'}>
              <Heading
                fontSize={{ lg: 'lg', md: 'md', sm: 'sm' }}
                color={'#afafaf'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                textAlign={'center'}
              >
                {location}
              </Heading>
            </Box>
            <Box w={'18%'}>
              <Heading
                fontSize={{ lg: 'lg', md: 'md', sm: 'sm' }}
                color={'#afafaf'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                textAlign={'center'}
              >
                {beat}
              </Heading>
            </Box>
            <Box w={'18%'}>
              <Heading
                fontSize={{ lg: 'lg', md: 'md', sm: 'sm' }}
                color={'#afafaf'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                textAlign={'center'}
              >
                {kit}
              </Heading>
            </Box>
          </HStack>
          <Box w={'100%'} my={2}>
            <WavesurferPlayer url={audioUrl} percentage={percentage} />
          </Box>
        </Box>
      </HStack>
    );
  }

  return (
    <Box w={'100%'} bg={'#000201'} my={5}>
      <HStack>
        <TableHeader w={'30%'} title={'User'} alignSelf={'flex-Start'} />
        <TableHeader w={'14%'} title={'Genre'} alignSelf={'center'} />
        <TableHeader w={'14%'} title={'Label Status'} alignSelf={'center'} />
        <TableHeader w={'14%'} title={'Location'} alignSelf={'center'} />
        <TableHeader
          w={'14%'}
          title={'Beat Specialities'}
          alignSelf={'center'}
        />
        <TableHeader
          w={'14%'}
          title={'Kit/Loop Specialities'}
          alignSelf={'center'}
        />
      </HStack>
      <TableContent
        profile={profile}
        name={'Sameer'}
        verified={true}
        active={true}
        lastSeen={''}
        stars={4}
        genre={'Manager'}
        label={'Signed'}
        location={'USA'}
        beat={'Classical'}
        kit={'Classical'}
        audioUrl={
          'https://res.cloudinary.com/ehsanahmadi/video/upload/v1573550770/Sirvan-Khosravi-Dorost-Nemisham-128_kb8urq.mp3'
        }
        percentage={'84%'}
      />
      <TableContent
        profile={profile}
        name={'Haroon'}
        verified={false}
        active={false}
        lastSeen={'Active 2h ago'}
        stars={3}
        genre={'Manager'}
        label={'Signed'}
        location={'USA'}
        beat={'Classical'}
        kit={'Classical'}
        audioUrl={
          'https://res.cloudinary.com/ehsanahmadi/video/upload/v1573550770/Sirvan-Khosravi-Dorost-Nemisham-128_kb8urq.mp3'
        }
        percentage={'45%'}
      />
    </Box>
  );
}

export default ConnectMeTable;
