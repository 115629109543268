import { gql } from '@apollo/client';

export const PAYMENTMUTAION = gql`
	mutation ($priceId: String!, $email: String!, $accountType: String!) {
		createCheckoutSession(
			priceId: $priceId
			email: $email
			accountType: $accountType
		) {
			success
			message
			url
		}
	}
`;
