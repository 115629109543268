import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../Auth/Layout";

function Validations() {
  const {
    setErrMsg,

    setActiveStep,
    activeStep,
    accountType,
    setIsEmail,

    setRegisteredphone,

    setPassword,
    setRegisteredEmail,
  } = useContext(AuthContext);
  const email_phoneNoValidation = (data) => {
    const regexPhone =
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

    if (
      data?.phoneNumber?.length > 0 ||
      data?.email?.length > 0 ||
      data?.phone?.length > 0 ||
      data?.email_phone?.length > 0
    ) {
      if (
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,30}$/i.test(
          data?.email || data?.email_phone
        )
      ) {
        setRegisteredEmail(data.email);
        setIsEmail(true);
        return true;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          data.email || data.email_phone
        ) &&
        (data?.email?.length > 0 || data?.email_phone?.length > 0) &&
        !regexPhone.test(data.email_phone)
      ) {
        setErrMsg("invalid email address");
        return false;
      }
      if (regexPhone.test(data.phoneNumber || data.phone || data.email_phone)) {
        setIsEmail(false);
        // setEmail(data.phoneNumber);
        setRegisteredphone(data.phone || data.phoneNumber);
        return true;
      } else if (
        !regexPhone.test(data.phoneNumber || data.phone || data.email_phone)
      ) {
        setErrMsg("invalid phone number");
        return false;
      }
    }
  };
  const ValidatePassword = (data) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;

    const digitsRegExp = /(?=.*?[0-9])/;

    const uppercasePassword = uppercaseRegExp.test(data.password);

    const digitsPassword = digitsRegExp.test(data.password);

    if (data.password && data.confirmPassword) {
      if (data.password.length >= 8 && data.confirmPassword.length >= 8) {
        if (data.password === data.confirmPassword) {
          if (uppercasePassword && digitsPassword) {
            setPassword(data.password);

            return true;
          } else {
            setErrMsg(
              "8 character at least with 1 or more upper case and 1 or more number "
            );
            return false;
          }
        } else {
          setErrMsg("Password and confirm password should match ");
          setActiveStep(activeStep);
          return false;
        }
      } else {
        setErrMsg("Length must be atleast 8 characters");
        setActiveStep(activeStep);
        return false;
      }
    } else {
      setErrMsg("Password and confirm password should match ");
      setActiveStep(activeStep);
      return false;
    }
    // } else if (data.password.length >= 8) {
    // 	console.log('in pass sas');
    // 	return true;
    // }
  };
  const ValidateRoles = () => {
    if (accountType.length > 0) {
      return true;
    } else {
      setActiveStep(activeStep);
      return false;
    }
  };

  return { email_phoneNoValidation, ValidatePassword };
}

export default Validations;

// export const email_phoneNoValidation=(data)=>{
//
