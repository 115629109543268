import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Login } from './Mutation';
import { AuthContext } from '../../../../Auth/Layout';
import { Notification } from '../../../../NotifyMsg/Notification';
import useAuth from '../../../../../context/AuthContext';

function useLoginMutation() {
	const { setUser } = useAuth();
	const {
		activeStep,
		setActiveStep,
		setIsDisableBtn,
		setRegisteredEmail,
		setPassword,
	} = useContext(AuthContext);
	const [login] = useMutation(Login, {
		onCompleted(data) {
			console.log('login successfully..', data);
			if (data?.login?.success) {
				localStorage.setItem('token', data.login.accessToken);
				localStorage.setItem(
					'opportunityToken',
					data.login?.reactionAccessToken
				);
				setUser(data?.login.user);
				Notification('success', data.login.message);
				localStorage.setItem(
					'accountType',
					data?.login?.user?.roleId?.roleName
				);
				localStorage.setItem('name', data.login.user.email.split('@')[0]);
				localStorage.setItem('userName', data.login.user?.userName);
				if (data.login.user.profilePic) {
					localStorage.setItem('profilePic', data.login.user.profilePic);
				}
				if (data.login.user.coverPic) {
					localStorage.setItem('coverPhoto', data.login.user.coverPic);
				}
				if (data.login.user._id) {
					localStorage.setItem('userId', data.login.user._id);
				}
				if (data.login.sessionId) {
					document.cookie = `sessionId=${data.login.sessionId};domain=unitedmarket.com`;
				}

				window.location.href = '/dashboard';
				setIsDisableBtn(false);
			} else {
				Notification('error', data.login.message);
				localStorage.clear();
				setRegisteredEmail('');
				setPassword('');
				setActiveStep(activeStep);
				setIsDisableBtn(false);
				if (data?.login?.user?.roleId?.roleName === 'Music Producer') {
					if (!data?.login?.isPaid) {
						if (data?.login?.user._id && data?.login?.user?.email) {
							localStorage.setItem('userId', data.login.user._id);
							localStorage.setItem('email', data.login.user.email);
							localStorage.setItem(
								'accountType',
								data?.login?.user?.roleId?.roleName
							);
							if (data?.login?.isPreSignUpUser) {
								window.location.href = '/pre-signup/1?step=8';
							} else {
								window.location.href = '/approved/signup/1?step=8';
							}
						}
					}
				}
			}
		},
	});

	return { login };
}

export default useLoginMutation;
