import { Box, Divider, Heading, HStack, Image } from 'native-base';
import React from 'react';
import { DashboardHeader } from '../../Headers';
import SideBar from '../../SideBar/SideBar';
import TermsConditionBody from './TermConditionBody';

const BarTermsCondition = (props) => {
	return (
		<Box w={'100%'} bg={'#000201'}>
			<HStack w={'100%'}>
				<Box
					display={{ lg: 'block', base: 'none' }}
					w={{ xl: '17%', lg: '17%', base: '0%' }}
				>
					<SideBar />
				</Box>

				<Box
					w={{ xl: '83%', lg: '83%', base: '100%' }}
					borderLeftWidth={{ lg: 1, md: 0 }}
					borderColor={'#fff'}
				>
					<DashboardHeader />
					<Divider />
					<TermsConditionBody />
				</Box>
			</HStack>
		</Box>
	);
};

export default BarTermsCondition;
