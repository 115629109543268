import { gql } from "@apollo/client";

export const ForgetPass=gql`
mutation($email:String!,$countrycode:Float,$phoneno:Float){
    forgetPassword(email:$email,countrycode:$countrycode,phoneno:$phoneno){
      success
      message
    }
  }
  `
