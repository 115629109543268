import { gql } from "@apollo/client";

export const ADDTOFAVOURITESONGS=gql`
mutation($songId:ID!){
    addFavoriteSong(songId:$songId){
      success
      message
      isTokenExpired
    }
  }
`