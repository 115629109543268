import { color, textTransform } from "@mui/system";
import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import staticLabels from "../../assets/staticWebsite/staticLabels.png";
import Layout from "../../components/StaticWebSite/LandingPagesComp/Layout";

const Labels = (props) => {
  
  const data = [
    {
      id: 1,
      des: "Running a music label is hard, especially when finding new talent and managing current talent and employees. United Market allows labels to boost efficiency and make finding potential clients 10x easier.",
    },
    {
      id: 2,
      des: "On United Market, you will no longer need to send A&Rs across the country to find new producers or artists. The platform offers a plethora of eager music producers ready to sign a major deal. In addition to finding top talent, completing legal documentation in-app will save your company time and money. Send and receive contracts via DocuSign and pay your signees on the app. No more middle man.",
    },
    {
      id: 3,
      des: "Once you have your new music producer or artist signed, managing the team efficiency is easier and more effective when using United Market. Your label can keep track of clients and employees while having one central hub to handle all of the team’s business needs.",
    },
    // {
    //   id: 4,
    //   des: " United Market is your all-in-one collaboration and project management platform. Streamline your current clientele directly to your U.M profile and take advantage of everything we offer!",
    // },
  ];

  return (
    <Box w={"100%"} bg={"#000201"}>
        <Layout
        title={'LABELS'}
        subtitle={'UNITED MARKET FOR LABELS'}
        desc={'Register your label on United Market to boost efficeincy and new signees by 10x'}
        image={staticLabels}
        content={data}
        />

    </Box>
  );
};

export default Labels;