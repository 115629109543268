import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { REMOVEFAVOURITESONGS } from "./Mutation";

import { Notification } from "../../../../NotifyMsg/Notification";






const  useRemoveFavouriteSongMutation=()=> {


    const [removeFavoriteSong,{data,error}]=useMutation(REMOVEFAVOURITESONGS,{
onCompleted(data){
  if (data?.removeFavoriteSong?.success) {

    Notification("success", data.removeFavoriteSong.message);
    setTimeout(() => {
   

    }, 1400);
  } else {

    Notification("error", data.removeFavoriteSong.message);

  }
}
    })


  return {removeFavoriteSong}


}

export default useRemoveFavouriteSongMutation