import { gql } from '@apollo/client';

export const getUser = gql`
  query {
    getUser {
      success
      message
      isTokenExpired
      user {
        userName
        firstname
        lastname
        _id
        email
        phoneno
        accountType
        labelName
        aboutMe
        genre
        roleId {
          roleName
        }
        credits
        accolades
        experience {
          title
          employmentType
          companyName
          profileHeadline
          startDate
          endDate
          description
        }
        howDidYouKnow {
          name
        }
        instaUrl
        profilePic
        coverPic
        opportunityId
      }
    }
  }
`;
