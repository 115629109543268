import { useLazyQuery } from '@apollo/client';
import React from 'react'
import { TWITCHSUBSCRIBER } from './Query';

function useTwitchSubscriber() {
    const [isTwitchLoading, setIsTwitchLoading] = React.useState(false);
    const [isConnectedTwitch, setIsConnectedTwitch] = React.useState(false);
    const [twitchSubscribers, setTwitchSubscribers] = React.useState(false);

    const [getTwitchSubscribers] = useLazyQuery(TWITCHSUBSCRIBER, {
        context: { clientName: 'user' },
        fetchPolicy:'no-cache',
        onCompleted(data) {
            setIsTwitchLoading(false);
            setIsConnectedTwitch(data?.getTwitchFollowers?.success);
            setTwitchSubscribers(data?.getTwitchFollowers?.twitchFollowers)
            console.log('data...', data);
        },
        onError(err) {
            setIsConnectedTwitch(false);
            setIsTwitchLoading(false);
            console.log('error,..', err);
        }
    });

    React.useEffect(() => {
        setIsTwitchLoading(true);
        getTwitchSubscribers();
    }, [])

    return [isTwitchLoading, isConnectedTwitch, twitchSubscribers];
}

export default useTwitchSubscriber