import { gql } from "@apollo/client";



export const ResetPasswordSignIn=gql`
mutation($password:String!,$confirmPassword:String!,$otp:String!){
    resetPassword(password:$password,confirmPassword:$confirmPassword,otp:$otp){
      success
      message
    }
}
`