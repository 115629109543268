import React, { useState } from 'react';
import { Box, Heading, HStack, Button, Modal, Input } from 'native-base';
import { ToastContainer } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Rating } from '@mui/material';
import { Notification } from '../../NotifyMsg/Notification';
import SendFeedBackMutation from '../../Hooks/SendFeedback/SendFeedback';

export const FeedbackModal = (props) => {
	const { sendFeedback } = SendFeedBackMutation();
	const { modalFlag, close } = props;
	const [value, setValue] = useState(0);
	const [recommend, setRecommend] = useState('');
	const handleChangeRecommendation = (e) => {
		setRecommend(e.target.value);
	};
	const sendFeedbackFtn = async () => {
		let result = await sendFeedback({
			variables: {
				feedback: recommend,
				rating: parseFloat(value),
			},
			context: { clientName: 'user' },
		});
		if (result.data?.sendFeedback?.success) {
			Notification('success', result.data.sendFeedback.message);
			setTimeout(() => {
				setRecommend('');
				setValue(0);
				close();
			}, 3000);
		} else {
			Notification('error', result.data.sendFeedback.message);
		}
	};
	return (
		<Modal
			isOpen={modalFlag}
			bg={'#00000070 '}
			size={'full'}
			w={{ xl: '35%', lg: '40%', base: '90%' }}
			m={'auto'}
			h={'50%'}
			onClose={close}
		>
			<Box w={'100%'} bg={'#141313'} rounded={'lg'} py={10} px={5}>
				<ToastContainer />
				<HStack w={'95%'} alignSelf={'center'}>
					<Box w={'90%'}>
						<Heading
							color={'#afafaf'}
							fontFamily={'Poppins'}
							fontWeight={'medium'}
							fontSize={{ lg: '24px', base: '16px' }}
							marginBottom={{ xl: '30px', lg: '30px', base: '15px' }}
						>
							Feedback
						</Heading>
					</Box>
					<Button
						w={'10%'}
						variant={'ghost'}
						_hover={{ bg: 'transparent' }}
						_pressed={{ bg: 'transparent' }}
						p={0}
						marginTop={'-50px'}
						justifyContent={'flex-end'}
						onPress={close}
					>
						<CloseIcon sx={{ color: '#AFAFAF' }} />
					</Button>
				</HStack>

				<Box w={'95%'} alignSelf={'center'}>
					<Box mt={2}>
						<Heading
							py={2}
							mb={2}
							color={'#AFAFAF'}
							fontSize={{ lg: '2xl', base: 'lg' }}
							fontFamily={'Poppins'}
							fontWeight={'medium'}
							textAlign={'left'}
						>
							Rate our interface designs
						</Heading>
						<Rating
							name='simple-controlled'
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							emptyIcon={
								<StarBorderIcon sx={{ color: '#afafaf', fontSize: 25 }} />
							}
						/>
					</Box>
					<Box mt={10}>
						<Heading
							py={2}
							color={'#AFAFAF'}
							fontSize={{ lg: '2xl', base: 'lg' }}
							fontFamily={'Poppins'}
							fontWeight={'medium'}
							textAlign={'left'}
						>
							Recommendations
						</Heading>
						<Input
							placeholder={''}
							width={'100%'}
							multiline={true}
							numberOfLines={6}
							value={recommend}
							onChange={(e) => handleChangeRecommendation(e)}
							// _web={{borderColor: "#2EBB55",_active: {borderColor: '#2EBB55'},_focus: {borderColor: "#2ebb55"}}}
							_focus={{
								bg: 'transparent',
								borderColor: '#2EBB55',
								_hover: { borderColor: '#2EBB55' },
							}}
							_hover={{ borderColor: '#2EBB55' }}
							py={2.5}
							rounded={'md'}
							my={2}
							bg={'#141313'}
							color={'#fff'}
							fontSize={'md'}
							placeholderTextColor={'#AFAEAE70'}
							size={'lg'}
						/>
					</Box>
					<Box mt={6} mb={4}>
						<Button
							onPress={sendFeedbackFtn}
							py={2}
							_hover={{
								backgroundColor: 'transparent',
								borderColor: '#2EBB55',
								borderWidth: 1,
							}}
							w={'25%'}
							alignSelf={'flex-end'}
							bg={'#2EBB55'}
						>
							<Heading
								fontSize={'xl'}
								fontWeight={'medium'}
								color={'#e3e3e3'}
								textAlign={'center'}
							>
								Send
							</Heading>
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};
