import React from 'react';
import { Grid } from '@mui/material';

import styles from './styles.module.css';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import TalentDashboard from './TalentDashboard';

const TalentDashboardMain = () => {
	return (
		<>
			<Grid className={styles.bgBlack}>
				<Sidebar
					activeText='Talent Dashboard'
					childComponent={<TalentDashboard />}
				/>
			</Grid>
		</>
	);
};
export default TalentDashboardMain;
