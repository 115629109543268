import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const configOptions = (method, url) => {
    return {
        method: method,
        url: url,
        headers: {
            'x-api-key': 'soundcharts',
            'x-app-id': 'soundcharts',
        },
    };
};
function StationFilter(props) {
    const [stations, setStations] = React.useState([])

    const {value, handler} = props
    const handleChange = (event) => {
      // console.log('selected value : ', event.target.value)
      // setAge(event.target.value);
      handler(event.target.value)
    };
  React.useEffect(() => {
    getAllStations()
  }, [])

  const getAllStations = async () => {
    try{
        const config = configOptions(
            'get',
            `https://sandbox.api.soundcharts.com/api/v2.22/radio`
          );
          const response = await axios(config)
          if(response.status === 200) {
            console.log('station data : ',response.data.items.map((item) => item.name))
            setStations(response.data.items.map((item) => item.name))
          }
          console.log('response of countries: ', response.data)
    }catch(err) {

    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
   <FormControl sx={{ m: [0,0,1], minWidth: [30, 120, 240] }} className='country_filter'>
    <Select
      labelId="demo-simple-select-helper-label"
      value={value}
      displayEmpty
      onChange={handleChange}
      style={{color:'#AFAFAF', border: '#AFAFAF'}}
      MenuProps={{ PaperProps: { sx: { maxHeight: '300px', backgroundColor: '#1c1b1b' } } }}
    >
      <MenuItem  sx={{  
        "&:focus": {
          background: "#2EBB55",
          color: "white"
          },
       "&:hover": {
            background: "#AFAFAF",
            color: "white"
            }
        
        }}
        value="all_stations">
        All Stations
      </MenuItem>

      {
        stations.length > 0 && stations.map((station) => 
        <MenuItem 
        sx={{  
          "&:focus": {
            background: "#2EBB55",
            color: "white"
            },
         "&:hover": {
              background: "#AFAFAF",
              color: "white"
              }
          
          }}
        
        value={station}>{station}</MenuItem>)
      }
    </Select>
  </FormControl>
    </ThemeProvider>
 
  )
}

export default StationFilter