import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { getUser } from '../components/Hooks/Authentication/User/query';

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
  const [user, setUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const [fetchUser] = useLazyQuery(getUser, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.getUser.user) {
        setIsAuthenticated(true);
        setUser(data.getUser.user);
        console.log('loading false');
        setLoading(false);
      } else {
        // setIsAuthenticated(false);
        setLoading(false);
      }
    },
    onError(err) {
      setLoading(false);
      // setIsAuthenticated(false);
      console.log('error ... login:', err);
    },
  });

  const loadUser = () => {
    setLoading(true);
    fetchUser();
  };

  React.useEffect(() => {
    loadUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        setLoading,
        loadUser,
        isAuthenticated,
        setIsAuthenticated,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default function useAuth() {
  const authContext = React.useContext(AuthContext);
  return authContext;
}
