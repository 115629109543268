import React, { useState, useEffect } from 'react';
import { Loader } from '../Loader';
import { toast } from 'react-toastify';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import { Box, Button, Heading, HStack, Text, Input } from 'native-base';
import {
  getUser,
  UpdateUserImages,
} from '../../Graphql_services/UsersMutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import styles from './styles.module.css';
import DefaultProfile from '../../assets/Icons/profile.png';
import EditProfile from '../Icons/EditProfile';
import defaultCover from '../../assets/Home/defaultCover.png';
import moment from 'moment';
import { ResetPasswordPopup } from '../PopUp/ResetPasswordPopup';
import { CancelSubscription } from '../PopUp/CancelSubscription';
import { SwitchPlanPopup } from '../PopUp/SwitchPlanPopup';

const Settings = () => {
  const navigate = useNavigate();
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingCover, setIsLoadingCover] = useState(false);
  const [showResetModal, SetShowResetModal] = useState(false);
  const [showSubscriptionModal, SetShowSubscriptionModal] = useState(false);
  const [showPlanModal, SetShowPlanModal] = useState(false);
  const [user, setUser] = useState([]);
  const [planName, setPlanName] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [nextPlan, setNextPlan] = useState(null);
  const types = ['image/jpeg', 'image/png'];
  const [getProfileData] = useLazyQuery(getUser, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.log('getUser', data);
      if (data.getUser.success) {
        setUser(data.getUser.user);
        if (data.getUser.plan) {
          setCurrentPlan(data.getUser.currentPlan);
          if (data.getUser.nextPlan == data.getUser.currentPlan) {
            setNextPlan(null);
            setPlanName(data.getUser.currentPlan.planName);
          } else {
            setNextPlan(data.getUser.nextPlan);
            setPlanName(data.getUser.nextPlan.planName);
          }
        } else {
          setCurrentPlan(null);
        }
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on getting user info', err);
    },
  });
  const [UpdateImages] = useMutation(UpdateUserImages, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.updateProfile.success) {
        //setUser(data.updateProfile.user);
        toast.success('Profile updated successfully');
        localStorage.setItem('profilePic', user.profilePic);
        localStorage.setItem('coverPhoto', user.coverPic);
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on getting user info', err);
    },
  });

  useEffect(() => {
    getProfileData();
  }, []);

  const handleSave = () => {
    UpdateImages({
      variables: {
        profilePic: user.profilePic || null,
        coverPic: null,
      },
    });
  };
  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      if (!types.includes(currentFileType)) {
        console.log('not accepted');
        toast.error('.png, .jpg,.jpeg file format are accepted');
        return false;
      }
      return true;
    }
  };

  const handleOnDropLogoImage1 = async (files, rejectedFiles) => {
    const profileData = new FormData();
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (verifyImageFile(files)) {
        profileData.append('file', files[0]);
        profileData.append('type', 'Public');
        setIsLoadingProfile(true);
        var config = {
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/file/uploadprofilepicture`,

          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: profileData,
        };
        axios(config)
          .then(function (response) {
            setUser({ ...user, profilePic: response.data.file.url });
            setIsLoadingProfile(false);
          })
          .catch(function (error) {
            console.log(error);
            setIsLoadingProfile(false);
          });
        return;
      } else {
        setIsLoadingProfile(false);
        return;
      }
    }
  };

  return (
    <Box w={'100%'} bg={'#000201'}>
      <Box w={'90%'} alignSelf={'center'} mt={10}>
        <HStack justifyContent={'space-between'} flexWrap={'wrap'}>
          <Box>
            <Heading
              my={5}
              textTransform={'capitalize'}
              fontSize={{
                xl: '40px',
                lg: '36px',
                md: '30px',
                base: '30px',
              }}
              fontWeight={'medium'}
              textAlign={'left'}
              color={'#fff'}
            >
              Profile
            </Heading>
            <Box
              my={5}
              left={{ sm: isLoadingProfile ? '20px' : '20px', base: '20px' }}
              height={isLoadingProfile ? '150px' : '150px'}
              width={isLoadingProfile ? '150px' : '150px'}
              resizeMode='cover'
              alignItems={'center'}
              borderRadius={'50%'}
              justifyContent={'center'}
              src={require('../../assets/Profile/userProfile.png')}
            >
              {isLoadingProfile ? (
                <div className={styles.loaderProfile}>
                  <Loader />
                </div>
              ) : (
                <Dropzone
                  name='dropzone1'
                  accept='image/*'
                  onDrop={handleOnDropLogoImage1}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className={styles.section}>
                      <div className={styles.presentation} {...getRootProps()}>
                        <div className={styles.UploadIconContainer}>
                          <img
                            src={
                              user.profilePic ? user.profilePic : DefaultProfile
                            }
                            style={{
                              borderRadius: '50%',
                            }}
                            className={styles.profilePic}
                          />
                          <input {...getInputProps()} />

                          <button className={styles.profileEditBtn}>
                            <EditProfile />
                          </button>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </Box>
          </Box>
          {currentPlan != null && (
            <Box
              bg={'#2ebb55'}
              w={{ '2xl': '20%', xl: '25%', lg: '25%', base: '100%' }}
              rounded={'lg'}
              my={5}
            >
              <HStack
                w={'80%'}
                my={5}
                justifyContent={'space-between'}
                alignSelf={'center'}
              >
                <Heading
                  fontFamily={'Poppins'}
                  fontWeight={'medium'}
                  fontSize={'16px'}
                  color={'#fff'}
                  alignSelf={'center'}
                >
                  Your Plan
                </Heading>
                <Button
                  variant={'ghost'}
                  p={0}
                  _hover={{ bg: 'transparent' }}
                  _pressed={{ bg: 'transparent' }}
                  _focus={{ bg: 'transparent' }}
                  onPress={() => SetShowPlanModal(true)}
                >
                  <RepeatRoundedIcon sx={{ fontSize: '20px', color: '#fff' }} />
                </Button>
              </HStack>
              <Heading
                w={'80%'}
                fontFamily={'Poppins'}
                fontWeight={'bold'}
                fontSize={'24px'}
                color={'#fff'}
                line-height={'36px'}
                alignSelf={'center'}
              >
                {currentPlan.planName?.split(' ')[0]}
              </Heading>
              <Heading
                w={'80%'}
                fontFamily={'Poppins'}
                fontWeight={'bold'}
                fontSize={'24px'}
                color={'#fff'}
                line-height={'36px'}
                alignSelf={'center'}
              >
                Membership
              </Heading>
              <Heading
                w={'80%'}
                fontFamily={'Poppins'}
                fontWeight={'medium'}
                fontSize={'12px'}
                color={'#fff'}
                alignSelf={'center'}
              >
                {currentPlan?.planName == nextPlan?.planName
                  ? 'Renews on ' +
                    moment(currentPlan?.currentPeriodEnd).format('MMM, YYYY')
                  : 'Your will be update to ' +
                    nextPlan?.planName +
                    ' on ' +
                    moment(currentPlan?.currentPeriodEnd).format('MMM, YYYY')}
              </Heading>
              <Button
                w={'80%'}
                variant={'ghost'}
                _hover={{
                  backgroundColor: '#2EBA55',
                  _text: {
                    color: '#fff',
                  },
                }}
                _pressed={{
                  bg: '#2EBB55',
                  _text: {
                    color: '#fff',
                  },
                }}
                _focus={{ bg: 'transparent' }}
                mt={10}
                mb={5}
                p={0}
                py={1.5}
                rounded={'lg'}
                borderColor='#fff'
                borderWidth={1}
                onPress={() => SetShowSubscriptionModal(true)}
                alignSelf={'center'}
                _text={{
                  color: '#fff',
                  fontWeight: 'medium',
                  fontSize: {
                    xl: '14px',
                    lg: '14px',
                    md: '12px',
                    base: '12px',
                  },
                  fontFamily: 'Poppins',
                }}
              >
                Cancel Subscription
              </Button>
            </Box>
          )}
        </HStack>
        <HStack
          width={'100%'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          flexGrow={1}
        >
          <Box width={'100%'}>
            <Heading
              my={5}
              textTransform={'capitalize'}
              fontSize={{ xl: '24px', lg: '24px', md: '20px', base: '18px' }}
              fontWeight={500}
              fontFamily={'Poppins'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              Email
            </Heading>

            <Input
              _text={{ fontSize: '24px', fontFamily: 'poppins' }}
              _hover={{ borderColor: '#AFAFAF' }}
              _focus={{ bg: 'transparent' }}
              value={user?.email}
              editable={false}
              //onChange={handleChange}
              focusOutlineColor='#2EBB55'
              fontFamily='poppins'
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              color={'#AFAFAF'}
              borderColor='#AFAFAF'
              height={{
                xl: '60px',
                base: '45px',
              }}
            />
          </Box>
          <Box width={'100%'}>
            <Heading
              my={5}
              textTransform={'capitalize'}
              fontSize={{ xl: '24px', lg: '24px', md: '20px', base: '18px' }}
              fontWeight={500}
              fontFamily={'Poppins'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              Phone Number
            </Heading>
            <Input
              _text={{ fontSize: '1rem', fontFamily: 'poppins' }}
              _hover={{ borderColor: '#AFAFAF' }}
              _focus={{ bg: 'transparent' }}
              value={user.phoneno || null}
              editable={false}
              //onChange={handleChange}
              focusOutlineColor='#2EBB55'
              fontFamily='poppins'
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              color={'#AFAFAF'}
              // onBlur={onBlur}
              borderColor='#AFAFAF'
              height={{
                xl: '60px',
                base: '45px',
              }}
            />
          </Box>
        </HStack>

        <HStack
          alignSelf={'flex-end'}
          w={{ lg: '50%', base: '100%' }}
          my={{ lg: 10, base: 5 }}
          justifyContent={'space-between'}
        >
          <Button
            w={'45%'}
            variant={'ghost'}
            _hover={{
              backgroundColor: '#2EBA55',
              _text: {
                color: '#fff',
              },
            }}
            _pressed={{
              bg: '#2EBB55',
              _text: {
                color: '#fff',
              },
            }}
            _focus={{ bg: 'transparent' }}
            // mt={10}
            p={0}
            py={2.5}
            borderColor='#2EBA55'
            borderWidth={1}
            onPress={() => SetShowResetModal(true)}
            alignSelf={'center'}
            _text={{
              color: '#2ebb55',
              fontWeight: 'medium',
              fontSize: { xl: '18px', lg: '18px', md: '16px', base: '14px' },
              fontFamily: 'Poppins',
            }}
          >
            Reset Password
          </Button>
          <Button
            w={'45%'}
            borderColor='#2EBA55'
            borderWidth={1}
            _pressed={{
              bg: '#2EBB55',
              _text: {
                color: '#fff',
              },
            }}
            backgroundColor='#2EBA55'
            py={2.5}
            _text={{
              color: '#fff',
              fontSize: { xl: '18px', lg: '18px', md: '16px', base: '14px' },
              fontWeight: 'medium',
              textTransform: 'capitalize',
              fontFamily: 'Poppins',
            }}
            _hover={{
              backgroundColor: 'transparent',
              _text: {
                color: '#2EBA55',
              },
            }}
            alignSelf={'center'}
            onPress={handleSave}
          >
            Update
          </Button>
        </HStack>
      </Box>
      <ResetPasswordPopup
        openModal={showResetModal}
        closeModal={() => SetShowResetModal(false)}
      />
      <CancelSubscription
        openModal={showSubscriptionModal}
        closeModal={() => SetShowSubscriptionModal(false)}
      />
      {currentPlan != null && (
        <SwitchPlanPopup
          openModal={showPlanModal}
          closeModal={() => SetShowPlanModal(false)}
          plan={planName?.split(' ')[0]}
          getUser={() => getProfileData()}
        />
      )}
    </Box>
  );
};

export default Settings;
