import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Auth/Layout';
import Landingpage from './Landingpage';
import TalentDashboard from './pages/talentDashboard/TalentDashboard';
import TalentDashboardCharts from './pages/talentDashboard/charts/TalentDashboardCharts';
import App from './App';
import SignIn from './components/Auth/SignIn/SignIn';
import SignUp from './components/Auth/SignUp/SignUp';
import PreSignUp from './components/Auth/SignUp/PreSignUp';
import { Sidebar } from './components/layout/Sidebar/Sidebar';
import ViewProfile from './components/Profile/ViewProfile/ViewProfile';
import AddUsers from './components/Profile/AddUsers/AddUsers';
import UserProfile from './components/Profile/UserProfile';
import MyUserProfile from './pages/User/Profile';
import TalentDashboardLayout from './components/TalentDashboard/TalentDashboardLayout';
import CompareArtistLayout from './pages/talentDashboard/comapre/';
import AudioLibrary from './components/AudioLibrary/AudioLibrary';
import AudioFeedback from './components/AudioLibrary/Feedback';
import BeatPack from './components/AudioLibrary/BeatPack';
import SharedBeatPack from './components/AudioLibrary/sharedPack';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import Artist from './pages/artist';
import BarMarketPlace from './components/BarCopmonent/BarCommingSoon/BarMarketPlace/BarMarketPlace';
import BarOpportunities from './components/BarCopmonent/BarCommingSoon/BarOpportunitites/BarOpportunities';
import BarHome from './components/BarCopmonent/BarHome/BarHome';
import BarTermsCondition from './components/BarCopmonent/BarTermsCondition/BarTermsCondition';
import Help from './pages/Help/Help';
import MySettings from './pages/User/Settings';
import ArtistProfile from './pages/talentDashboard/artist/ArtistProfile';
import Artists from './pages/artists';
import Labels from './pages/StaticWebSite/Labels';
import Manager from './pages/StaticWebSite/Manager';
import Artist1 from './pages/StaticWebSite/Artist';
import MusicProducer from './pages/StaticWebSite/MusicProducer';
import A_RS from './pages/StaticWebSite/A_RS';
import CreditDetails from './components/StaticWebSite/LandingPagesComp/CreditDetails';
import Credits from './pages/StaticWebSite/Credits';
import SaasProducer from './pages/StaticWebSite/SaasProducer';
import Financials from './pages/Financials/Financials';
import MusicProducerForm from './components/Growth/MusicProducerForm';
import Inbox from './components/Inbox/Inbox';
import TalentDashboardMain from './pages/talentDashboard/TalentDashboardMain';
import SongDetailLayout from './pages/talentDashboard/detail/SongDetailLayout';
import ChartsLayout from './pages/talentDashboard/charts/ChartsLayout';
import Publishing from './components/Publishing/Publishing';
import AudioDetail from './components/AudioLibrary/AudioDetail';
import Growth from './pages/Growth/Growth';
import Goal from './pages/Goals/Goal';
import FinancialsDetails from './pages/Financials/FinancialsDetails';
import Analytic from './pages/Goals/Analytic';
import BookACollab from './pages/BookCollab/BookACollab';
import Notifications from './pages/Notifications/Notifications';
import Feeds from './pages/Socials/Feeds';
import Favourites from './pages/Socials/Favourites';
import Subscribers from './pages/Socials/Subscribers';
import ProtectedRoutes from './routes/protectedRoutes';
import NotFound from './pages/NotFound';
import ConnectMe from './pages/Socials/ConnectMe';
import Distribution from './components/Distribution/Distribution';
import NewLandingPage from './components/NewLandingPage/NewLandingPage';
import LandingPageV3 from './components/LandingPageV3/LandingPageV3';
import MyPayment from './pages/User/Payment';
function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App childComp={<LandingPageV3 />} />} />
          <Route
            path='/approved/signup'
            element={<App childComp={<Layout childComp={<SignUp />} />} />}
          />
          <Route
            path='/signup'
            element={<App childComp={<Layout childComp={<SignUp />} />} />}
          />
          <Route
            path='/pre-signup'
            element={<App childComp={<Layout childComp={<PreSignUp />} />} />}
          />
          <Route
            path='/approved/signup/:isSignup'
            element={<App childComp={<Layout childComp={<SignUp />} />} />}
          />
          <Route
            path='/pre-signup/:isSignup'
            element={<App childComp={<Layout childComp={<PreSignUp />} />} />}
          />
          <Route
            path='/signin'
            element={<App childComp={<Layout childComp={<SignIn />} />} />}
          />
          <Route path='/home' element={<App childComp={<LandingPageV3 />} />} />
          <Route
            path='/adduser'
            element={
              <App childComp={<UserProfile childComp={<AddUsers />} />} />
            }
          />
          <Route
            path='/viewprofile'
            element={
              <App childComp={<UserProfile childComp={<ViewProfile />} />} />
            }
          />

          {/* Routes Created today  */}
          <Route
            path='/viewtalentdashboard'
            element={<App childComp={<TalentDashboardLayout />} />}
          />
          <Route
            path='/talent-dashboard/artist-profile/'
            element={<App childComp={<ArtistProfile />} />}
          />
          <Route path='/artist' element={<App childComp={<Artist1 />} />} />
          <Route path='/artists' element={<App childComp={<Artists />} />} />

          {/* <Route></Route> */}
          <Route path='/label' element={<App childComp={<Labels />} />} />
          <Route path='/manager' element={<App childComp={<Manager />} />} />
          <Route
            path='/music-producer'
            element={<App childComp={<MusicProducer />} />}
          />
          <Route path='/a-and-r' element={<App childComp={<A_RS />} />} />
          <Route
            path='/beat-pack/share/:id'
            element={<App childComp={<SharedBeatPack />} />}
          />
          <Route
            path='/credit-details'
            element={<App childComp={<CreditDetails />} />}
          />
          <Route path='/credits' element={<App childComp={<Credits />} />} />
          <Route
            path='/saasproducer'
            element={<App childComp={<SaasProducer />} />}
          />
          <Route
            path='/publishing'
            element={<App childComp={<Publishing />} />}
          />
          <Route
            path='/audio-library/share/:id'
            element={<App childComp={<AudioDetail />} />}
          />
          <Route
            path='/audio-library/high-level-feedback/:id'
            element={<App childComp={<AudioFeedback />} />}
          />
          <Route
            path='/distribution'
            element={<App childComp={<Distribution />} />}
          />
          <Route
            path='/landingPageV3'
            element={<App childComp={<LandingPageV3 />} />}
          />
          <Route
            path='/user/payment'
            element={<App childComp={<MyPayment />} />}
          />
          <Route exact element={<ProtectedRoutes />}>
            <Route
              path='/talent-dashboard'
              element={<App childComp={<TalentDashboardMain />} />}
            />
            <Route
              path='/talent-dashboard/charts'
              element={<App childComp={<ChartsLayout />} />}
            />
            <Route
              path='/talent-dashboard/song/'
              element={<App childComp={<SongDetailLayout />} />}
            />
            <Route
              path='/talent-dashboard/compare/'
              element={<App childComp={<CompareArtistLayout />} />}
            />
            <Route
              path='/dashboard'
              element={<App childComp={<BarHome />} />}
            />
            <Route
              path='/profile'
              element={<App childComp={<MyUserProfile />} />}
            />
            <Route
              path='/terms-and-conditions'
              element={<App childComp={<BarTermsCondition />} />}
            />
            <Route
              path='/beat-packs'
              element={<App childComp={<BeatPack />} />}
            />
            <Route path='/help' element={<App childComp={<Help />} />} />
            <Route
              path='/user/settings'
              element={<App childComp={<MySettings />} />}
            />
            <Route
              path='/opporunity'
              element={<App childComp={<BarOpportunities />} />}
            />
            <Route
              path='/notifications'
              element={<App childComp={<Notifications />} />}
            />

            <Route
              path='/musicproducerform'
              element={<App childComp={<MusicProducerForm />} />}
            />
            <Route
              path='/audio-library'
              element={<App childComp={<AudioLibrary />} />}
            />
            <Route
              path='/marketplace'
              element={<App childComp={<BarMarketPlace />} />}
            />
            <Route path='/goals' element={<App childComp={<Goal />} />} />
            <Route path='/inbox' element={<App childComp={<Inbox />} />} />
            <Route
              path='/analytics'
              element={<App childComp={<Analytic />} />}
            />
            <Route
              path='/financials'
              element={<App childComp={<Financials />} />}
            />
            <Route
              path='/financial/details'
              element={<App childComp={<FinancialsDetails />} />}
            />
            <Route path='/growth' element={<App childComp={<Growth />} />} />
            <Route
              path='/book/collab'
              element={<App childComp={<BookACollab />} />}
            />
            <Route path='/feeds' element={<App childComp={<Feeds />} />} />
            <Route
              path='/favourites'
              element={<App childComp={<Favourites />} />}
            />
            <Route
              path='/subscribers'
              element={<App childComp={<Subscribers />} />}
            />
            <Route
              path='/connect-me'
              element={<App childComp={<ConnectMe />} />}
            />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
