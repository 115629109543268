import { AspectRatio, Box, Image, Link } from 'native-base';
import React from 'react';

const FooterIcons = ({ imgName, imgUrl, imgPath }) => {
  return (
    <Link href={imgUrl}>
      <Box>
        <Image
          resizeMode='contain'
          mx={{ xl: 2, lg: 2, sm: 1, base: 1 }}
          size={
            imgPath === 'SignIn'
              ? { lg: '1.5rem', md: '1.2rem', base: '1rem' }
              : { xl: '2.5rem', lg: '2.5rem', base: '1.5rem' }
          }
          source={require(`../../assets/${imgPath}/${imgName}`)}
        />
      </Box>
    </Link>
  );
};

export default FooterIcons;
