import { Box, HStack, VStack, Button, Hidden, Text } from 'native-base';
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './../Layout';
import { useQuery } from '@apollo/client';

export default function PartOfLabel(props) {
	const { w, h, borderadius } = props;
	const { labelPart, setLabelPart } = useContext(AuthContext);

	const handlePress = (e) => {
		if (e.currentTarget.innerText.trim() === 'True') {
			setLabelPart(true);
		} else {
			setLabelPart(false);
		}
	};
	return (
		<Box mb={{ md: 10, base: 5 }}>
			{/* <Hidden from="base" till="lg"> */}

			{/* <Hidden from='base' till='sm'> */}
			<VStack space={10}>
				<HStack
					space={{ xl: '30px', md: '30px', base: '30px' }}
					flexGrow={1}
					flexWrap={'wrap'}
					justifyContent={{
						lg: 'center',
						md: 'space-between',
						base: 'center',
					}}
				>
					<div className='btn'>
						<Button
							nativeID={'true'}
							mb={{ md: 0, base: 5 }}
							onPress={handlePress}
							_focus={{
								backgroundColor: '#2EBB55',
								_text: {
									color: '#E3E3E3',
								},
							}}
							_text={{
								color: labelPart === true ? '#E3E3E3' : '#AFAFAF',
								textTransform: 'capitalize',
								fontFamily: 'poppins',
								textAlign: 'center',
								fontSize: {
									xl: '18px',
									md: '1rem',
									sm: '0.9rem',
									base: '0.75rem',
								},
							}}
							_hover={{
								backgroundColor: '#2EBB55',
								_text: {
									color: '#E3E3E3',
								},
							}}
							backgroundColor={labelPart === true ? '#2EBB55' : '#1C1B1B'}
							borderColor={'#AFAFAF'}
							borderRadius={borderadius}
							// borderWidth="1"
							w={w}
							h={h}
						>
							{'True'}
						</Button>
					</div>
					<div className='btn'>
						<Button
							nativeID={'false'}
							mb={{ md: 0, base: 5 }}
							onPress={handlePress}
							_focus={{
								backgroundColor: '#2EBB55',
								_text: {
									color: '#E3E3E3',
								},
							}}
							_text={{
								color: labelPart === false ? '#E3E3E3' : '#AFAFAF',
								textTransform: 'capitalize',
								fontFamily: 'poppins',
								textAlign: 'center',
								fontSize: {
									xl: '18px',
									md: '1rem',
									sm: '0.9rem',
									base: '0.75rem',
								},
							}}
							_hover={{
								backgroundColor: '#2EBB55',
								_text: {
									color: '#E3E3E3',
								},
							}}
							backgroundColor={labelPart === false ? '#2EBB55' : '#1C1B1B'}
							borderColor={'#AFAFAF'}
							borderRadius={borderadius}
							// borderWidth="1"
							w={w}
							h={h}
						>
							{'False'}
						</Button>
					</div>
				</HStack>
				<Hidden>
					<HStack></HStack>
				</Hidden>
			</VStack>
		</Box>
	);
}
