/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Heading,
	HStack,
	Image,
	Menu,
	Text,
} from 'native-base';
import React, { useState } from 'react';
import { DashboardHeader } from '../../Headers';
import SideBar from '../../SideBar/SideBar';
import slider from '../../../assets/Notification/slider.png';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { BarGraphBeats } from './Graphs/BarGraphBeats';
import { LineGraphFilled } from './Graphs/LineGraphFilled';
import { LineGraph } from './Graphs/LineGraph';
import { CountryGraph } from './Graphs/CountryGraph';

function Analytics() {
	return (
		// <Box w={'100%'} bg={'#000201'}>
		// 	<HStack w={'100%'}>
		// 		<Box
		// 			borderLeftWidth={{ lg: 1, md: 0 }}
		// 			borderColor={'#fff'}
		// 			w={{ xl: '83%', lg: '83%', base: '100%' }}
		// 		>
		// <DashboardHeader />
		// <Divider mt={1} />
		<Box w={'100%'}>
			<Box w={'90%'} alignSelf={'center'} py={5}>
				<HStack w={'100%'} justifyContent={'space-between'}>
					<Heading
						my={5}
						fontSize={{
							xl: '40px',
							lg: '36px',
							md: '30px',
							base: '20px',
						}}
						color={'#fff'}
						textTransform={'capitalize'}
						fontFamily={'poppins'}
					>
						Analytics
					</Heading>

					<AnalyticsMenu />
				</HStack>
				<Box>
					<HStack justifyContent={'space-between'} flexWrap={'wrap'}>
						<PointCard
							number={'17'}
							downvote={true}
							title={'No. of Loops sent'}
						/>
						<PointCard
							number={'17'}
							downvote={true}
							title={'No. of Beats sent'}
						/>
						<PointCard
							number={'21'}
							upvote={true}
							title={'No. of Beats received'}
						/>
						<PointCard
							number={'35'}
							upvote={true}
							title={'No. of Loops received'}
						/>
						<PointCard number={'08'} open={true} title={'No. of Opens'} />
						<PointCard
							number={'4.1'}
							star={true}
							title={'Average raiting for Lopps'}
						/>
						<PointCard
							number={'4.9'}
							star={true}
							title={'Average raiting for Beats'}
						/>
					</HStack>
				</Box>
				<Box my={5}>
					<HStack
						w={'100%'}
						flexGrow={1}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
						alignSelf={'center'}
					>
						<Box
							w={{ xl: '48%', md: '48%', base: '100%' }}
							alignSelf={'center'}
						>
							<Box w={'100%'}>
								<GraphHeading
									title={'Profile Views'}
									subtitle={'Updated Yesterday at 9:05 AM'}
								/>
								<Box
									w={'100%'}
									rounded={'lg'}
									py={3}
									bg={'#1c1b1b'}
									h={{ xl: '50vh', md: '35vh', base: '32vh' }}
								>
									<LineGraph />
									<Heading
										fontSize={{ xl: '3xl', md: '2xl', base: 'xl' }}
										color={'#2EBB55'}
										fontFamily={'Poppins'}
										fontWeight={'medium'}
										px={4}
									>
										{'6 '}
										<Heading
											fontSize={{ xl: 'xl', base: 'md' }}
											color={'#2EBB55'}
											fontFamily={'Poppins'}
											fontWeight={'medium'}
											textAlign={'center'}
										>
											{'Views '}
											<Heading
												fontSize={{ xl: 'md', base: 'xs' }}
												color={'#fff'}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
											>
												In last 7 days.
											</Heading>
										</Heading>
									</Heading>
								</Box>
							</Box>
						</Box>
						<Box
							w={{ xl: '48%', md: '48%', base: '100%' }}
							mt={{ xl: 0, md: 0, base: 5 }}
						>
							<GraphHeading
								title={'Location'}
								subtitle={'The 7 most common location.'}
							/>
							<Box
								bg={'#1c1b1b'}
								h={{ xl: '50vh', md: '35vh', base: '35vh' }}
								w={'100%'}
								rounded={'lg'}
							>
								<CountryGraph />
							</Box>
						</Box>
					</HStack>
				</Box>
				<Box w={'100%'} my={5}>
					<GraphHeading
						title={'Conversation Rate'}
						subtitle={'Updated Yesterday at 9:05 AM'}
					/>
					<Box w={'100%'} rounded={'lg'} py={3} bg={'#1c1b1b'}>
						<LineGraphFilled />
					</Box>
				</Box>
				<Box w={'100%'} my={5}>
					<GraphHeading
						title={'Beat Analytics'}
						subtitle={'Updated Yesterday at 9:05 AM'}
					/>
					<Box w={'100%'} rounded={'lg'} py={3} bg={'#1c1b1b'}>
						<BarGraphBeats />
					</Box>
				</Box>
				<Box w={'100%'} rounded={'lg'} py={4} bg={'#1c1b1b'} mt={2} mb={5}>
					<ContentTable
						title1={'Beat Name'}
						title2={'Clicks'}
						title3={'Impression'}
						data={[
							{
								id: 1,
								beat: 'Beat Name',
								click: '06',
								impression: '100',
							},
							{
								id: 1,
								beat: 'Beat Name',
								click: '04',
								impression: '200',
							},
							{
								id: 1,
								beat: 'Beat Name',
								click: '02',
								impression: '150',
							},
							{
								id: 1,
								beat: 'Beat Name',
								click: '03',
								impression: '100',
							},
							{
								id: 1,
								beat: 'Beat Name',
								click: '01',
								impression: '100',
							},
						]}
					/>
				</Box>
				<Box w={'100%'} my={5}>
					<GraphHeading
						title={'Kit Analytics'}
						subtitle={'Updated Yesterday at 9:05 AM'}
					/>
					<Box w={'100%'} rounded={'lg'} py={3} bg={'#1c1b1b'}>
						<BarGraphBeats />
					</Box>
				</Box>
				<Box w={'100%'} rounded={'lg'} py={4} bg={'#1c1b1b'} mt={2} mb={5}>
					<ContentTable
						title1={'Kit Name'}
						title2={'Clicks'}
						title3={'Impression'}
						data={[
							{
								id: 1,
								beat: 'Kit Name',
								click: '06',
								impression: '100',
							},
							{
								id: 1,
								beat: 'Kit Name',
								click: '04',
								impression: '200',
							},
							{
								id: 1,
								beat: 'Kit Name',
								click: '02',
								impression: '150',
							},
							{
								id: 1,
								beat: 'Kit Name',
								click: '03',
								impression: '100',
							},
							{
								id: 1,
								beat: 'Kit Name',
								click: '01',
								impression: '100',
							},
						]}
					/>
				</Box>
				<Box w={'100%'} my={5}>
					<GraphHeading
						title={'Instagram And Twitter Analytics'}
						subtitle={'Updated Yesterday at 9:05 AM'}
					/>
					<Box w={'100%'} rounded={'lg'} py={3} bg={'#1c1b1b'}>
						<BarGraphBeats />
					</Box>
				</Box>
			</Box>
		</Box>
		// 		</Box>
		// 	</HStack>
		// </Box>
	);

	function AnalyticsMenu() {
		return (
			<Menu
				rounded={'lg'}
				borderColor={'#fff'}
				borderWidth={1}
				alignSelf={'center'}
				color={'#afafaf'}
				// fontSize={{ md: '16px' }}
				w={'180px'}
				mx={3}
				_text={{
					color: '#afafaf',
				}}
				background={'#1C1B1B'}
				placement={'bottom left'}
				trigger={(triggerProps) => {
					return (
						<Button
							variant={'ghost'}
							rounded={'md'}
							_hover={{ bg: 'transparent' }}
							_focused={{ bg: '2ebb55' }}
							_pressed={{ bg: 'transparent' }}
							{...triggerProps}
						>
							<Image source={slider} size={6} />
						</Button>
					);
				}}
			>
				<Menu.Item
					bg={'#1C1B1B'}
					borderBottomWidth='1px'
					borderBottomColor='#afafaf'
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Heading
						w={'100%'}
						my={2}
						fontSize={{ xl: 'xl', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#fff'}
					>
						Daily
					</Heading>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					borderBottomWidth='1px'
					borderBottomColor='#afafaf'
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Heading
						w={'100%'}
						my={2}
						fontSize={{ xl: 'xl', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#fff'}
					>
						Weekly
					</Heading>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					borderBottomWidth='1px'
					borderBottomColor='#afafaf'
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Heading
						w={'100%'}
						my={2}
						fontSize={{ xl: 'xl', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#fff'}
					>
						Mountly
					</Heading>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Heading
						w={'100%'}
						my={2}
						fontSize={{ xl: 'xl', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#fff'}
					>
						Yearly
					</Heading>
				</Menu.Item>
			</Menu>
		);
	}
	function PointCard(props) {
		const { number, upvote, downvote, open, star, title } = props;
		return (
			<Box
				bg={'#1C1B1B'}
				w={{ xl: '13%', md: '13%', base: '46%' }}
				px={2}
				py={3}
				rounded={'lg'}
				my={{ lg: 5, base: 3 }}
			>
				<HStack justifyContent={'space-between'} my={4}>
					<Heading
						fontSize={{ xl: '3xl', base: 'xl' }}
						color={'#e3e3e3'}
						fontFamily={'Poppins'}
						fontWeight={'medium'}
					>
						{number}
					</Heading>
					{upvote && (
						<Box alignSelf={'center'} p={1} bg={'#2EBB5550'} rounded={'full'}>
							<ArrowUpwardRoundedIcon sx={{ color: '#2EBB55', fontSize: 20 }} />
						</Box>
					)}
					{downvote && (
						<Box alignSelf={'center'} p={1} bg={'#ff000050'} rounded={'full'}>
							<ArrowDownwardRoundedIcon sx={{ color: '#f00', fontSize: 20 }} />
						</Box>
					)}
					{open && (
						<Box alignSelf={'center'} p={1} bg={'#2EBB5550'} rounded={'full'}>
							<LaunchRoundedIcon sx={{ color: '#2EBB55', fontSize: 20 }} />
						</Box>
					)}
					{star && (
						<Box alignSelf={'center'} p={1} bg={'#DBFF0050'} rounded={'full'}>
							<StarRoundedIcon sx={{ color: '#DBFF00', fontSize: 20 }} />
						</Box>
					)}
				</HStack>
				<Heading
					mb={3}
					fontSize={'sm'}
					fontWeight={'medium'}
					fontFamily={'Poppins'}
					color={'#AFAFAF'}
				>
					{title}
				</Heading>
			</Box>
		);
	}
	function GraphHeading(props) {
		const { title, subtitle } = props;
		return (
			<HStack mb={5}>
				<Heading
					fontSize={{ xl: '24px', lg: '20px', md: '18px', base: '16px' }}
					color={'#fff'}
					fontWeight={'medium'}
					textTransform={'capitalize'}
					fontFamily={'poppins'}
				>
					{title}
				</Heading>
				<Heading
					pl={{ lg: 4, base: 2 }}
					fontSize={{
						xl: '14px',
						lg: '14px',
						md: '12px',
						base: '10px',
					}}
					color={'#AFAFAF'}
					fontWeight={'normal'}
					textTransform={'capitalize'}
					fontFamily={'poppins'}
					alignSelf={'center'}
				>
					{subtitle}
				</Heading>
			</HStack>
		);
	}
	function ContentTable(props) {
		const { title1, title2, title3, data } = props;
		return (
			<Box w={'100%'}>
				<HStack w={'90%'} mb={6} mt={2} alignSelf={'center'}>
					<Heading
						w={{ lg: '70%', base: '55%' }}
						fontSize={{ xl: 'lg', base: 'sm' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						color={'#fff'}
						textAlign={'left'}
					>
						{title1}
					</Heading>
					<Heading
						w={{ lg: '15%', base: '20%' }}
						fontSize={{ xl: 'lg', base: 'sm' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						color={'#fff'}
						textAlign={'center'}
					>
						{title2}
					</Heading>
					<Heading
						w={{ lg: '15%', base: '25%' }}
						fontSize={{ xl: 'lg', base: 'sm' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						color={'#fff'}
						textAlign={'center'}
					>
						{title3}
					</Heading>
				</HStack>

				{data.map((item) => (
					<Box w={'100%'}>
						<Divider bg={'#AFAFAF'} />
						<HStack w={'90%'} my={3} alignSelf={'center'}>
							<Heading
								w={{ lg: '70%', base: '55%' }}
								fontSize={{ xl: 'md', base: 'xs' }}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								color={'#e3e3e3'}
								textAlign={'left'}
							>
								{item.beat}
							</Heading>
							<Heading
								w={{ lg: '15%', base: '20%' }}
								fontSize={{ xl: 'md', base: 'xs' }}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								color={'#e3e3e3'}
								textAlign={'center'}
							>
								{item.click}
							</Heading>
							<Heading
								w={{ lg: '15%', base: '25%' }}
								fontSize={{ xl: 'md', base: 'xs' }}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								color={'#e3e3e3'}
								textAlign={'center'}
							>
								{item.impression}
							</Heading>
						</HStack>
					</Box>
				))}
			</Box>
		);
	}
}

export default Analytics;
