import React from 'react'

function YoutubeIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0008 3.99295H7.00077C6.33366 3.95269 5.6656 4.05444 5.04073 4.29147C4.41586 4.52851 3.84839 4.89543 3.37582 5.368C2.90324 5.84058 2.53632 6.40804 2.29929 7.03292C2.06225 7.65779 1.96051 8.32585 2.00077 8.99295V14.993C1.96051 15.6601 2.06225 16.3281 2.29929 16.953C2.53632 17.5779 2.90324 18.1453 3.37582 18.6179C3.84839 19.0905 4.41586 19.4574 5.04073 19.6944C5.6656 19.9315 6.33366 20.0332 7.00077 19.993H17.0008C17.6679 20.0332 18.3359 19.9315 18.9608 19.6944C19.5857 19.4574 20.1531 19.0905 20.6257 18.6179C21.0983 18.1453 21.4652 17.5779 21.7022 16.953C21.9393 16.3281 22.041 15.6601 22.0008 14.993V8.99295C22.041 8.32585 21.9393 7.65779 21.7022 7.03292C21.4652 6.40804 21.0983 5.84058 20.6257 5.368C20.1531 4.89543 19.5857 4.52851 18.9608 4.29147C18.3359 4.05444 17.6679 3.95269 17.0008 3.99295ZM13.8908 13.023L11.4208 14.503C10.4208 15.103 9.60077 14.643 9.60077 13.473V10.503C9.60077 9.33295 10.4208 8.87295 11.4208 9.47295L13.8908 10.953C14.1004 11.033 14.2807 11.1749 14.4079 11.3597C14.5351 11.5445 14.6032 11.7636 14.6032 11.988C14.6032 12.2123 14.5351 12.4314 14.4079 12.6162C14.2807 12.801 14.1004 12.9429 13.8908 13.023Z" fill="#AFAFAF"/>
    </svg>
  )
}

export default YoutubeIcon
