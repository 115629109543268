import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { VerifySignInOtp } from "./Mutation";
import { AuthContext } from "../../../../Auth/Layout";
import { Notification } from "../../../../NotifyMsg/Notification";






const  useVerifyOTP=()=> {
  const {
    activeStep,
    setActiveStep,

    setIsDisableBtn,
  } = useContext(AuthContext);

    const [checkResetOtp,{data,error}]=useMutation(VerifySignInOtp,{
onCompleted(data){
  if (data?.checkResetOtp?.success) {
    Notification("success", data.checkResetOtp.message);
    setTimeout(() => {
   

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   setIsDisableBtn(false)
    }, 1400);
  } else {
    Notification("error", data.checkResetOtp.message);
    setActiveStep(activeStep);
 setIsDisableBtn(false)
  }
}
    })


  return {checkResetOtp}


}

export default useVerifyOTP