import React, { useState, useEffect } from 'react';
import {
	Box,
	Image,
	Heading,
	HStack,
	VStack,
	Button,
	Divider,
	useBreakpointValue,
} from 'native-base';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TalentcomponentHeader } from '../TalentComponentHeader/TalentComponentHeader';
import styles from './styles.module.css';
export const WorldMap = (props) => {
	const [seeMore, setSeeMore] = useState(false);
	const { artist, data } = props;
	const isHeader = false;

	return (
		<Box
			marginTop={{ xl: 5, base: 5 }}
			marginBottom={{ xl: 5, base: 1 }}
			py={5}
			bg={'#1C1B1B'}
			w={'100%'}
			rounded={'lg'}
		>
			<Box w={'95%'} alignSelf={'center'}>
				<TalentcomponentHeader
					image={''}
					title={'Airplay demographics'}
					subtitle={'Top countries by radio plays in the last 28 days'}
				/>

				<HStack flexDirection={{ xl: 'row', base: 'column' }}>
					<Box w={{ xl: '50%', base: '100%' }}>
						{/* <TableHeaderCol4 title1={'country'} /> */}
						{artist ? (
							<Box>
								<HStack w={'100%'} py={2}>
									<Heading
										w={'23%'}
										textTransform={'uppercase'}
										color={isHeader ? '#AFAFAF' : '#5F6368'}
										fontFamily={'Poppins'}
										fontSize={isHeader ? 'sm' : 'md'}
										fontWeight={'normal'}
										textAlign={'left'}
									>
										{'Country'}
									</Heading>
									{artist.map((item) => (
										<Heading
											w={'30%'}
											textTransform={'uppercase'}
											color={isHeader ? '#AFAFAF' : '#5F6368'}
											fontFamily={'Poppins'}
											fontSize={isHeader ? 'sm' : 'md'}
											fontWeight={'normal'}
											textAlign={'left'}
										>
											{item.name}
										</Heading>
									))}
								</HStack>
								<Divider my={1} />
							</Box>
						) : (
							''
						)}
						{/* {data
							? data[0].map((item) => (
									<Box
									// flex={1}
									>
										<TableContentCol4
											title1={item.country}
											title2={item.singerFirst}
											// title3={item.singerSecond ? item.singerSecond : ''}
											// title4={item.singerThird ? item.singerThird : ''}
										/>
									</Box>
							  ))
							: ''} */}
						{data && data.length > 0
							? (!seeMore ? data.slice(0, 10) : data).map((item, index) => (
									<Box bg={'#1C1B1B'} w={'100%'}>
										<HStack w={'100%'} py={4}>
											<Heading
												w={'30%'}
												color={'#E3E3E3'}
												textTransform={'capitalize'}
												fontFamily={'Poppins'}
												fontSize={'md'}
												fontWeight={'normal'}
												textAlign={'left'}
											>
												{item.country_name}
											</Heading>
											<Heading
												w={'30%'}
												color={'#E3E3E3'}
												textTransform={'capitalize'}
												fontFamily={'Poppins'}
												fontSize={'md'}
												fontWeight={'normal'}
												textAlign={'left'}
											>
												{item.value1}
											</Heading>
											<Heading
												w={'30%'}
												color={'#E3E3E3'}
												textTransform={'capitalize'}
												fontFamily={'Poppins'}
												fontSize={'md'}
												fontWeight={'normal'}
												textAlign={'left'}
											>
												{item?.value2}
											</Heading>
											<Heading
												w={'30%'}
												color={'#E3E3E3'}
												textTransform={'capitalize'}
												fontFamily={'Poppins'}
												fontSize={'md'}
												fontWeight={'normal'}
												textAlign={'left'}
											>
												{item?.value3}
											</Heading>
										</HStack>
										<Divider />
									</Box>
							  ))
							: ''}
					</Box>
					<Box w={{ xl: '50%', base: '100%' }}>
						<Image
							source={require('../../../assets/TiktokComponents/worldMap.png')}
							w={'100%'}
							h={'350px'}
							resizeMode={'contain'}
							mt='0.5'
						/>
					</Box>
				</HStack>
				<Button
					mt={8}
					p={1}
					variant={'ghost'}
					alignSelf={'flex-start'}
					borderWidth={'2'}
					borderColor={'#1c1b1c'}
					_hover={{
						backgroundColor: 'transparent',
						borderColor: '#2ebb55',
						borderWidth: '2',
					}}
					_icon={<ArrowDropDownIcon />}
					onPress={() => setSeeMore(!seeMore)}
				>
					<HStack>
						<Heading
							color={'#E3E3E3'}
							textTransform={'capitalize'}
							fontFamily={'Poppins'}
							fontSize={'md'}
							fontWeight={'normal'}
							textAlign={'left'}
						>
							{seeMore ? 'See Less' : 'See More'}
						</Heading>
						{seeMore ? (
							<ArrowDropUpIcon sx={{ color: '#E3E3E3' }} />
						) : (
							<ArrowDropDownIcon sx={{ color: '#E3E3E3' }} />
						)}
					</HStack>
				</Button>
			</Box>
		</Box>
	);
};
