import { HStack, Text, VStack } from 'native-base';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { Notification } from '../../../components/NotifyMsg/Notification';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Loader } from '../../../components/Loader';
import { DashboardHeader } from '../../../components/Headers';
import { SearchFieldComponent } from '../../../components/SearchField';
import SideBar from '../../../components/SideBar/SideBar';
import { WorldMap } from '../../../components/TalentDashboard/WorldMapCompare/WorldMap';

import arrowBack from '../../../assets/Icons/arrowLeft.png';
import spotifyImg from '../../../assets/TiktokComponents/spotify.png';
import shazam from '../../../assets/TiktokComponents/shazam.png';
import youtube from '../../../assets/TiktokComponents/youtube.png';
import styles from './styles.module.css';
import MultiSelectSearch from '../../../components/SearchField/MultiSelectSearch';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);
const CompareArtist = () => {
	const [shazamCount, setShazamCount] = useState(0);
	const [searchUUIDs, setSearchUUIDs] = useState([]);
	const [youtubeCount, setYoutubeCount] = useState(0);
	const [spotifyCount, setSpotifyCount] = useState(0);
	const [graphLabel, setGraphLabel] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [spotifyPopularityGraphLabel, setSpotifyPopularityGraphLabel] =
		useState([]);
	const [spotifyPopularityDataset, setSpotifyPopularityDataset] = useState([]);
	const [spotifyDataset, setSpotifyDataset] = useState([]);
	const [youtubeDataset, setYoutubeDataset] = useState([]);
	const [shazamDataset, setShazamDataset] = useState([]);
	const [airplayData, setAirPlayData] = useState([]);
	const [similarArtist, setSimilarArtist] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getChartData = (platform) => {
		switch (platform) {
			case 'spotify': {
				let datasets = spotifyDataset;
				let obj = {
					labels: graphLabel,
					position: 'bottom',
					datasets: datasets,
				};
				return obj;
			}
			case 'sPopularity': {
				let datasets = spotifyPopularityDataset;
				let obj = {
					labels: spotifyPopularityGraphLabel,
					position: 'bottom',
					datasets: datasets,
				};
				return obj;
			}
			case 'youtube': {
				let datasets = youtubeDataset;
				let obj = {
					labels: graphLabel,
					position: 'bottom',
					datasets: datasets,
				};
				return obj;
			}
			case 'shazam': {
				let datasets = shazamDataset;
				let obj = {
					labels: graphLabel,
					position: 'bottom',
					datasets: datasets,
				};
				return obj;
			}
			default:
				return '';
		}
	};

	const options = {
		opacity: 0.5,
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					display: true,
					borderDash: [8, 2],
					color: '#fff',
				},
			},
		},
		responsive: true,
	};
	const navigate = useNavigate();
	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};

	const spotifyPopularity = async (data) => {
		try {
			const platform = 'spotify';
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
			).format('YYYY-MM-DD');
			let tempData = await Promise.allSettled(
				data.map(async (searchData, index) => {
					setIsLoading(true);
					const config = configOptions(
						'get',
						`https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/spotify/popularity?endDate=2020-10-10`
					);
					const response = await axios(config);
					const { items } = response.data;
					setSpotifyPopularityGraphLabel(
						items.map((item) => {
							return item.date.split('T')[0];
						})
					);
					let spotifyPopularityData = {
						label: searchData.name,
						data: items.map((item) => {
							return item.value;
						}),
						borderColor:
							index === 0
								? '#2EBA55'
								: index === 1
								? 'rgb(53, 162, 235)'
								: 'rgb(255, 99, 132)',
						fill: false,
						backgroundColor:
							index === 0
								? 'rgba(46, 186, 85, 0.3)'
								: index === 1
								? 'rgba(53, 162, 235, 0.5)'
								: 'rgba(255, 99, 132, 0.5)',
						tension: 0.5,
						borderWidth: 2,
					};
					return spotifyPopularityData;
				})
			);
			setSpotifyPopularityDataset([...tempData.map((td) => td.value)]);
			setIsLoading(false);
		} catch (err) {
			console.log('getShazamcount', err);
		}
	};
	const getSimilarArtist = async (uuid) => {
		const config = configOptions(
			'get',
			`https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/related?offset=0&limit=100`
		);
		const response = await axios(config);
		const { items } = response.data;
		let len = items.length;
		setSimilarArtist(
			items.map((item, index) => {
				if (index === len - 1) {
					console.log('in if');
					return item.name;
				} else {
					return item.name + ',';
				}
			})
		);
	};
	const getYoutubeCount = async (data) => {
		try {
			const platform = 'youtube';
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
			).format('YYYY-MM-DD');
			let tempData = await Promise.allSettled(
				data.map(async (searchData, index) => {
					const config = configOptions(
						'get',
						`https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/audience/instagram?endDate=2021-09-24`
					);
					const response = await axios(config);
					const { items } = response.data;
					setYoutubeCount(items.length);
					setGraphLabel(
						items.map((item) => {
							return item.date.split('T')[0];
						})
					);

					let youtube = {
						label: searchData?.name,
						data: items.map((item) => {
							return item.followerCount;
						}),
						borderColor:
							index === 0
								? '#2EBA55'
								: index === 1
								? 'rgb(53, 162, 235)'
								: 'rgb(255, 99, 132)',
						fill: false,
						backgroundColor:
							index === 0
								? 'rgba(46, 186, 85, 0.3)'
								: index === 1
								? 'rgba(53, 162, 235, 0.5)'
								: 'rgba(255, 99, 132, 0.5)',
						tension: 0.5,
						borderWidth: 2,
					};
					return youtube;
				})
			);
			setYoutubeDataset([...tempData.map((td) => td.value)]);
		} catch (err) {
			console.log('youtube', err);
		}
	};
	const getSpotifycount = async (data) => {
		try {
			const platform = 'spotify';
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
			).format('YYYY-MM-DD');
			let tempData = await Promise.allSettled(
				data.map(async (searchData, index) => {
					setIsLoading(true);
					const config = configOptions(
						'get',
						`https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/audience/instagram?endDate=2021-09-24`
					);
					const response = await axios(config);
					const { items } = response.data;
					setSpotifyCount(items.length);
					setGraphLabel(
						items.map((item) => {
							return item.date.split('T')[0];
						})
					);
					let spotifyData = {
						label: searchData.name,
						data: items.map((item) => {
							return item.followerCount;
						}),
						borderColor:
							index === 0
								? '#2EBA55'
								: index === 1
								? 'rgb(53, 162, 235)'
								: 'rgb(255, 99, 132)',
						fill: false,
						backgroundColor:
							index === 0
								? 'rgba(46, 186, 85, 0.3)'
								: index === 1
								? 'rgba(53, 162, 235, 0.5)'
								: 'rgba(255, 99, 132, 0.5)',
						tension: 0.5,
						borderWidth: 2,
					};
					return spotifyData;
					// tempData.push(spotifyData);
					// console.log('spotify data : ', spotifyData);
					// setSpotifyDataset([...tempData]);
				})
			);
			setSpotifyDataset([...tempData.map((td) => td.value)]);
			setIsLoading(false);
		} catch (err) {
			console.log('getShazamcount', err);
		}
	};
	const getShazamcount = async (data) => {
		try {
			const platform = 'shazam';
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
			).format('YYYY-MM-DD');

			let tempData = await Promise.allSettled(
				data.map(async (searchData, index) => {
					setIsLoading(true);
					const config = configOptions(
						'get',
						`https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/audience/instagram?endDate=2021-09-24`
					);
					const response = await axios(config);
					const { items } = response.data;
					setShazamCount(items.length);
					setGraphLabel(
						items.map((item) => {
							return item.date.split('T')[0];
						})
					);

					let shazam = {
						label: searchData?.name,
						data: items.map((item) => {
							return item.followerCount;
						}),
						borderColor:
							index === 0
								? '#2EBA55'
								: index === 1
								? 'rgb(53, 162, 235)'
								: 'rgb(255, 99, 132)',
						fill: false,
						backgroundColor:
							index === 0
								? 'rgba(46, 186, 85, 0.3)'
								: index === 1
								? 'rgba(53, 162, 235, 0.5)'
								: 'rgba(255, 99, 132, 0.5)',
						tension: 0.5,
						borderWidth: 2,
					};
					return shazam;
				})
			);
			setShazamDataset([...tempData.map((td) => td.value)]);
			setIsLoading(false);
		} catch (err) {
			console.log('getShazamcount', err);
		}
	};
	const getAirplay = async (data) => {
		try {
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
			).format('YYYY-MM-DD');
			let tempData = await Promise.allSettled(
				data.map(async (searchData, index) => {
					setIsLoading(true);
					const config = configOptions(
						'get',
						`https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/broadcasts?offset=0&limit=100`
					);
					const response = await axios(config);
					const { items } = response.data;
					const data = items.map((item, index) => {
						return {
							country_name: item.radio.countryName,
							plays: item.duration,
						};
					});
					let countryWithPlays = [];
					for (var i = 0; i < data.length; i++) {
						//get all countries with their plays
						if (countryWithPlays.length === 0) {
							countryWithPlays.push(data[i]);
						} else {
							// eslint-disable-next-line no-loop-func
							let checkCountry = countryWithPlays.findIndex(
								(play) => play.country_name === data[i].country_name
							);
							if (checkCountry < 0) {
								countryWithPlays.push(data[i]);
							} else {
								countryWithPlays[checkCountry].plays += data[i].plays;
							}
						}
					}
					return countryWithPlays;

					setIsLoading(false);
				})
			);

			let commonCountries = [];
			if (tempData.length === 3) {
				let firstData = tempData[0].value;
				let secondData = tempData[1].value;
				let thirdData = tempData[2].value;

				for (let i = 0; i < firstData.length; i++) {
					const firstComparison = secondData.findIndex(
						(data) => data.country_name === firstData[i].country_name
					);
					const secondComparison = thirdData.findIndex(
						(data) => data.country_name === firstData[i].country_name
					);
					if (firstComparison > -1 && secondComparison > -1) {
						commonCountries.push({
							country_name: firstData[i].country_name,
							value1: firstData[i].plays,
							value2: secondData[firstComparison].plays,
							value3: thirdData[secondComparison].plays,
						});
					}
				}
			} else if (tempData.length === 2) {
				let firstData = tempData[0].value;
				let secondData = tempData[1].value;

				for (let i = 0; i < firstData.length; i++) {
					const firstComparison = secondData.findIndex(
						(data) => data.country_name === firstData[i].country_name
					);
					if (firstComparison > -1) {
						commonCountries.push({
							country_name: firstData[i].country_name,
							value1: firstData[i].plays,
							value2: secondData[firstComparison].plays,
						});
					}
				}
			} else {
				let firstData = tempData[0].value;
				for (let i = 0; i < firstData.length; i++) {
					commonCountries.push({
						country_name: firstData[i].country_name,
						value1: firstData[i].plays,
					});
				}
			}

			setAirPlayData(commonCountries);
		} catch (err) {
			console.log(err);
		}
	};
	// const searchResult = async (e) => {
	// 	try {
	// 		console.log('value of search data : ',e)
	// 		let searchItems = e.split(',');
	// 		if (searchItems.length > 0 && searchItems.length < 4) {
	// 			setSearchUUIDs([]);
	// 			let searchData = await Promise.allSettled(
	// 				searchItems.map(async (artist) => {
	// 					setIsLoading(true);
	// 					if (artist.trim().length > 0) {
	// 						const art = artist.trim();
	// 						const config = configOptions(
	// 							'get',
	// 							`https://sandbox.api.soundcharts.com/api/v2/artist/search/${art}?offset=0&limit=20`
	// 						);
	// 						const response = await axios(config);
	// 						const { items } = response.data;
	// 						setSearchData([...items])
	// 						console.log('search data: ', items)
	// 						getSimilarArtist(items[0].uuid);
	// 						return { uuid: items[0].uuid, name: art };
	// 					}
	// 				})
	// 			);
	// 			setSearchUUIDs([
	// 				...searchData
	// 					.filter((sd) => sd.value && Object.keys(sd.value).length > 0)
	// 					.map((sd) => sd.value),
	// 			]);
	// 			setIsLoading(false);
	// 		}
	// 		if (searchItems.length > 3) {
	// 			Notification('error', 'Maximum 3 artist can be compared');
	// 		}
	// 	} catch (err) {}
	// };
	const searchResult = async (e) => {
		try {
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/artist/search/${e}?offset=0&limit=20`
			);
			const response = await axios(config);
			const { items } = response.data;
			setSearchData([...items]);

			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};
	const onChangeSearch = (e) => {
		searchResult(e);
	};
	const handleBack = () => {
		navigate(-1);
	};
	useEffect(() => {
		if (searchUUIDs.length > 0 && searchUUIDs.length < 4) {
			getShazamcount(searchUUIDs);
			getYoutubeCount(searchUUIDs);
			getSpotifycount(searchUUIDs);
			spotifyPopularity(searchUUIDs);
			getAirplay(searchUUIDs);
		}
		if (searchUUIDs.length >= 4) {
			Notification('error', 'Maximum 3 artist can be compared');
		}
	}, [searchUUIDs]);

	return (
		<Grid container className={styles.bgBlack}>
			<ToastContainer />

			<Grid item xs={12} md={12}>
				<Grid className={styles.content}>
					<Grid container direction='row'>
						<Grid item md={8} sm={12} className={styles.headingDiv}>
							<img
								src={arrowBack}
								alt='back icon'
								className={styles.backIcon}
								onClick={() => handleBack()}
							/>{' '}
							&nbsp; &nbsp;
							<p className={styles.heading}>
								Which artists do you want to compare?
							</p>
						</Grid>
					</Grid>
					<Grid container direction='row'>
						{/* <Grid item md={12} sm={12} className={styles.searchDiv}>
							<SearchFieldComponent
								placeholder='Compare up to 3 artist'
								onChangeSearch={onChangeSearch}
							/>
						</Grid> */}
						<Grid item md={12} sm={12}>
							<MultiSelectSearch
								onChangeSearch={onChangeSearch}
								data={searchData}
								setSearchUUIDs={setSearchUUIDs}
								getSimilarArtist={getSimilarArtist}
							/>
						</Grid>
					</Grid>
					<Grid container direction='row'>
						<Grid item md={12} sm={12} className={styles.similarArtistDiv}>
							<p>
								Similar artist: &nbsp;<span>{similarArtist}</span>
							</p>
						</Grid>
					</Grid>
					{searchUUIDs.length > 0 ? (
						<>
							{' '}
							<Grid container direction='row'>
								<Grid item md={12} sm={12}>
									<WorldMap artist={searchUUIDs} data={airplayData} />
								</Grid>
							</Grid>
							{isLoading ? (
								<div className={styles.loaderDiv}>
									<Loader />
								</div>
							) : (
								<>
									<Grid container direction='row' className={styles.graphRow}>
										<Grid item className={styles.spotifyStream}>
											<div>
												<img src={spotifyImg} alt='spotify icon' />
												<p className={styles.chartHeading}>
													{spotifyCount} Total Spotify Stream
												</p>
											</div>

											<p className={styles.chartSubHeading}>
												Total stream over last 28 days
											</p>
											<Line options={options} data={getChartData('spotify')} />
										</Grid>
										<Grid item className={styles.spotifyStream}>
											<div>
												<img src={spotifyImg} alt='spotify icon' />
												<p className={styles.chartHeading}>
													Spotify Popuarity Index
												</p>
											</div>
											<p className={styles.chartSubHeading}>
												Popularity index last 28 days
											</p>
											<Line
												options={options}
												data={getChartData('sPopularity')}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										direction='row'
										className={styles.graphRowTwo}
									>
										<Grid item className={styles.spotifyStream}>
											<div>
												<img src={shazam} alt='shazam icon' />
												<p className={styles.chartHeading}>
													{shazamCount} Total Shazam Count
												</p>
											</div>
											<p className={styles.chartSubHeading}>
												Total videos over last 28 days
											</p>
											<Line options={options} data={getChartData('shazam')} />
										</Grid>
										<Grid item className={styles.spotifyStream}>
											<div>
												<img src={youtube} alt='spotify icon' />
												<p className={styles.chartHeading}>
													{youtubeCount} Total Youtube View
												</p>
											</div>
											<p className={styles.chartSubHeading}>
												Total videos over last 28 days
											</p>
											<Line options={options} data={getChartData('youtube')} />
										</Grid>
									</Grid>
								</>
							)}
						</>
					) : (
						''
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CompareArtist;
