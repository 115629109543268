/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { fontSize } from '@mui/system';
import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	Hidden,
	HStack,
	Image,
	Menu,
	Text,
} from 'native-base';

import React, { useState } from 'react';
import { DashboardHeader } from '../../Headers';
import SideBar from '../../SideBar/SideBar';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import StaticProgress from './StaticProgress';
import { AddGoalsModal } from './Modal/AddGoalsModal';
import { EditGoalsModal } from './Modal/EditGoalsModal';

function Goals() {
	const [showCompleted, setShowCompleted] = useState(true);
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);

	return (
		<Box w={'100%'}>
			{/* <HStack w={'100%'}>
				<Box
					borderLeftWidth={{ lg: 1, md: 0 }}
					borderColor={'#fff'}
					w={{ xl: '83%', lg: '83%', base: '100%' }}
				> */}
			<Box w={'100%'}>
				<Box w={'90%'} alignSelf={'center'} py={5}>
					<HStack justifyContent={'space-between'}>
						<Heading
							my={5}
							fontSize={{
								xl: '40px',
								lg: '36px',
								md: '30px',
								base: '20px',
							}}
							color={'#fff'}
							textTransform={'capitalize'}
							fontFamily={'poppins'}
						>
							Goals
						</Heading>

						<Button
							size={'sm'}
							bg='#2EBA55'
							borderWidth={1}
							borderColor='#2EBA55'
							_text={{
								color: '#fff',
								fontSize: {
									xl: '20px',
									lg: '18px',
									md: '16px',
									base: '14px',
								},
								fontFamily: 'Poppins',
							}}
							_hover={{
								bg: 'transparent',
								_text: {
									color: '#2EBA55',
								},
							}}
							onPress={() => {
								setAddModal(true);
							}}
							w={{ lg: '15%', base: '30%' }}
							rounded={'md'}
							alignSelf={'center'}
							_focused={{
								bg: '#2EBA55',
								_text: {
									color: '#fff',
								},
							}}
							_pressed={{
								bg: '#2EBA55',
								_text: {
									color: '#fff',
								},
							}}
						>
							ADD GOAL
						</Button>
					</HStack>
					<GoalsCard
						progress={95}
						title={'Import feature fix'}
						status={'Urgent'}
					/>
					<GoalsCard
						progress={29}
						title={'Start new marketing campaign'}
						status={'Long term'}
					/>
					<GoalsCard
						progress={75}
						title={'Increase conversation by 25%'}
						status={'short  term'}
					/>
					<GoalsCard progress={65} title={'Beta launch'} status={'01-sep-22'} />

					<Box>
						<HStack>
							<Heading
								my={5}
								fontSize={{ xl: '2xl', base: 'lg' }}
								color={'#fff'}
								textTransform={'capitalize'}
								fontFamily={'poppins'}
							>
								Completed
							</Heading>
							<Button
								variant={'ghost'}
								p={0}
								px={4}
								_focus={{ bg: 'trandparent' }}
								_hover={{ bg: 'trandparent' }}
								_pressed={{ bg: 'trandparent' }}
								_text={{
									color: '#2ebb55',
									fontWeight: 'normal',
									fontSize: { xl: 'md', base: 'xs' },
									fontFamily: 'Poppins',
									underline: 1,
								}}
								onPress={() => setShowCompleted(!showCompleted)}
							>
								{showCompleted ? 'Hide' : ' Show'}
							</Button>
						</HStack>
						{showCompleted && (
							<Box>
								<GoalsCard
									completed={true}
									progress={100}
									title={'Import feature fix'}
									status={'Urgent'}
								/>
								<GoalsCard
									completed={true}
									progress={100}
									title={'Start new marketing campaign'}
									status={'Long term'}
								/>
								<GoalsCard
									completed={true}
									progress={100}
									title={'Increase conversation by 25%'}
									status={'short  term'}
								/>
								<GoalsCard
									completed={true}
									progress={100}
									title={'Beta launch'}
									status={'01-sep-22'}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
			{/* </Box>
			</HStack> */}
			<AddGoalsModal
				showModal={addModal}
				closeModal={() => setAddModal(false)}
			/>
			<EditGoalsModal
				showModal={editModal}
				closeModal={() => setEditModal(false)}
			/>
		</Box>
	);

	function GoalsCard(props) {
		const { progress, title, status, completed } = props;
		return (
			<HStack w={'100%'} justifyContent={'space-between'} my={3}>
				<Box alignSelf={'center'}>
					{completed ? (
						<>
							<Hidden from='base' till='md'>
								<DoneRoundedIcon sx={{ color: '#afafaf', fontSize: 25 }} />
							</Hidden>
							<Hidden from='md'>
								<DoneRoundedIcon sx={{ color: '#afafaf', fontSize: 16 }} />
							</Hidden>
						</>
					) : (
						<>
							<Hidden from='base' till='md'>
								<AccessTimeRoundedIcon
									sx={{ color: '#afafaf', fontSize: 25 }}
								/>
							</Hidden>
							<Hidden from='md'>
								<AccessTimeRoundedIcon
									sx={{ color: '#afafaf', fontSize: 16 }}
								/>
							</Hidden>
						</>
					)}
				</Box>
				<Box w={{ lg: '55%', md: '55%', base: '50%' }} alignSelf={'center'}>
					<Heading
						fontSize={{ xl: '18px', lg: '16px', md: '14px', base: '12px' }}
						color={'#e3e3e3'}
						fontWeight={'medium'}
						textTransform={'capitalize'}
						fontFamily={'poppins'}
						px={3}
					>
						{title}
					</Heading>
				</Box>
				<Box w={'15%'} alignSelf={'center'}>
					<StaticProgress progress={progress} />
				</Box>
				<Box w={'25%'} alignSelf={'center'}>
					<Heading
						fontSize={{ xl: '16px', md: '16px', base: '10px' }}
						color={
							completed ? '#afafaf' : status == 'Urgent' ? '#f00' : '#2ebb55'
						}
						fontWeight={'normal'}
						textTransform={'capitalize'}
						fontFamily={'poppins'}
						px={2}
					>
						{status}
					</Heading>
				</Box>
				{completed ? (
					<Box px={3} />
				) : (
					<Menu
						rounded={'lg'}
						borderColor={'#fff'}
						borderWidth={1}
						alignSelf={'center'}
						color={'#afafaf'}
						// fontSize={{ md: '16px' }}
						w={'150px'}
						mx={3}
						_text={{
							color: '#afafaf',
						}}
						background={'#1C1B1B'}
						placement={'bottom left'}
						trigger={(triggerProps) => {
							return (
								<Button
									variant={'ghost'}
									rounded={'md'}
									p={0}
									_hover={{ bg: 'transparent' }}
									_focused={{ bg: '2ebb55' }}
									_pressed={{ bg: 'transparent' }}
									{...triggerProps}
								>
									<MoreVertRoundedIcon
										sx={{ color: '#afafaf', fontSize: 25 }}
									/>
								</Button>
							);
						}}
					>
						<Menu.Item
							bg={'#1C1B1B'}
							borderBottomWidth='1px'
							borderBottomColor='#afafaf'
							m={0}
							p={0}
							_focus={{
								bg: '#1C1B1B',
							}}
							_hover={{
								bg: '#1C1B1B',
							}}
							_pressed={{
								bg: '#1C1B1B',
							}}
							onPress={() => setEditModal(true)}
						>
							<Heading
								my={2}
								fontSize={{ xl: 'md', base: 'xs' }}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								textAlign={'left'}
								textTransform={'capitalize'}
								color={'#fff'}
							>
								Edit
							</Heading>
						</Menu.Item>
						<Menu.Item
							bg={'#1C1B1B'}
							m={0}
							p={0}
							_focus={{
								bg: '#1C1B1B',
							}}
							_hover={{
								bg: '#1C1B1B',
							}}
							_pressed={{
								bg: '#1C1B1B',
							}}
						>
							<Heading
								my={2}
								fontSize={{ xl: 'md', base: 'xs' }}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								textAlign={'left'}
								textTransform={'capitalize'}
								color={'#fff'}
							>
								Delete
							</Heading>
						</Menu.Item>
					</Menu>
				)}
			</HStack>
		);
	}
}

export default Goals;
