import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import { TabContext } from '@mui/lab';
import Getsongs from '../../RestApis/Services/TalentDashboard/Getsongs';
import { useNavigate } from 'react-router-dom';
import SideBar from '../../components/SideBar/SideBar';
import { Loader } from '../../components/Loader';
import { DashboardHeader } from '../../components/Headers';
import { TrendingArtist } from '../../components/TalentDashboard';

import { SearchFieldComponent } from '../../components/SearchField';
import TalentDashboardCharts from './charts/TalentDashboardCharts';
import { GETFAVOURITEARTIST } from '../../components/Hooks/TalentDashboard/GetFavouriteArtists/Query';
import { GETFAVOURITESONGS } from '../../components/Hooks/TalentDashboard/Songs/GetFavouriteSONG/Query';
import styles from './styles.module.css';
import { useQuery } from '@apollo/client';
import { Divider } from 'native-base';
import { ToastContainer } from 'react-toastify';

const TalentDashboard = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	if (!token) {
		navigate('/signin');
	}
	const [value, setValue] = React.useState(-1);
	const res1 = useQuery(GETFAVOURITEARTIST, {
		context: { clientName: 'user' },
	});
	const res2 = useQuery(GETFAVOURITESONGS, { context: { clientName: 'user' } });
	const [trendingArtist, setTrendingArtist] = useState([]);
	// const [queryData,setqueryadata]=useState(data)
	const [favArtist, setFavArtist] = useState([]);
	const [favSongid, setFavSongId] = useState([]);
	const [favSong, setFavSong] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [activeState, setActiveState] = useState(-1);
	const [slug, setSlug] = useState('global-28');
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(100);
	const [tabOptions, setTabOptions] = useState([]);
	const [fav, setFav] = useState([]);

	// 	const getSong = async (data) => {
	// 		const config = configOptions(
	// 			'get',
	// 			`https://sandbox.api.soundcharts.com/api/v2.8/song/7d534228-5165-11e9-9375-549f35161576`
	// 		);

	// 		// const { uuid, change, doc } = data;

	// 		const response = await axios(config);
	// 		console.log("response",response)
	// 		const { object } = response.data;

	// 		const { releaseDate, name, label, imageUrl, creditName,uuid } = object;
	// return {releaseDate, name, label, imageUrl, creditName,uuid}
	// 		}

	const getChart = async () => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.14/chart/song/global-28/ranking/latest?offset=0&limit=100`
			);
			const response = await axios(config);
			const { items } = response.data;
			// console.log("items",items)

			// let songs = items.map((item) => {
			// 	let temp = {
			// 		uuid: item?.song?.uuid,
			// 		change: item?.positionEvolution,
			// 		doc: item?.timeOnChart,
			// 	};
			// 	return temp;
			// });
			// if (songs) {
			// 	let data = songs.map(async (item) => {
			// 		await getSong(item);
			// 	});
			// 	// if (data) {
			// 	// 	console.log(data);
			// 	// }
			// 	//setTableData(data);
		} catch (err) {
			console.log(err);
		}
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
		if (newValue === 0) {
			navigate('/talent-dashboard/charts');
		}
		if (newValue === 1) {
			navigate('/talent-dashboard/compare');
		} else {
			setActiveState(newValue);
		}
	};

	useEffect(() => {
		console.log('in trending artist useEffect');
		console.log(trendingArtist);
		setFavArtist(
			trendingArtist.filter((item, index) => item.profileLink === fav[index])
		);
	}, [trendingArtist]);

	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};

	const getLatestSongs = async () => {
		try {
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.14/chart/song/${slug}/ranking/latest?offset=${offset}&limit=${limit}`
			);
			const response = await axios(config);
			// console.log("response",response)
			const { items } = response.data;
			let songs = items.map((item) => {
				return item.song.uuid;
			});
			const config2 = configOptions(
				'get',
				'https://sandbox.api.soundcharts.com/api/v2.8/song/7d534228-5165-11e9-9375-549f35161576'
			);
			const artistResponse = await axios(config2);
			console.log('artits trepsonse', artistResponse);
			const { object } = artistResponse.data;
			const { artists } = object;
			setTrendingArtist(
				artists?.map((art) => {
					return {
						name: art.name,
						image: art.imageUrl,
						role: 'Music Artist',
						profileLink: art.uuid,
					};
				})
			);
			setIsLoading(false);
		} catch (err) {}
	};

	useEffect(() => {
		getLatestSongs();
		setTabOptions([
			'charts',
			'Compare Artists',
			'Add Favorite Artists',
			'Add Favorite Songs',
		]);
	}, []);

	const searchIdentifier = async (e) => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.9/artist/by-platform/spotify/${e}`
			);
			const response = await axios(config);
			const { object } = response.data;
			setTrendingArtist([
				{
					uuid: object.uuid,
					name: object.name,
					image: object.imageUrl,
					role: 'Music Artist',
					profileLink: object.uuid,
				},
			]);
		} catch (err) {
			console.log(err);
		}
	};

	const searchResult = async (e) => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/artist/search/${e}?offset=0&limit=20`
			);
			const response = await axios(config);
			const { items } = response.data;
			if (items) {
				setTrendingArtist(
					items.map((art) => {
						return {
							uuid: art.uuid,
							name: art.name,
							image: art.imageUrl,
							role: 'Music Artist',
							profileLink: art.uuid,
						};
					})
				);
			}
		} catch (err) {
			if (err.response.status === 403) {
				searchIdentifier(e);
			}
		}
	};

	const onChangeSearch = (e) => {
		searchResult(e);
	};

	useEffect(() => {
		if (res1.data) {
			if (res1.data.getMyFavoriteArtists.isTokenExpired) {
				navigate('/signin');
			}
			setFav(res1.data.getMyFavoriteArtists.favoriteArtists);
		}
	}, [res1.data]);

	useEffect(() => {
		if (res2.data) {
			setFavSongId(res2.data.getMyFavoriteSongs.favoriteSongs);
		}
	}, [res2.data]);

	useEffect(() => {
		setFavArtist(
			trendingArtist.filter((item, index) => item.profileLink === fav[index])
		);
	}, [fav, res1.data]);

	useEffect(() => {
		console.log('value', value);
		const getSong = async (uuid) => {
			// const { uuid, change, doc } = data;

			const response = await Getsongs(uuid);
			console.log('response', response);
			const { object } = response.data;

			setFavSong((favSong) => [...favSong, object]);
		};
		favSongid?.map((item) => getSong(item));
		// getSong(favSongid.map(item=>item))
		// 	const arrayResponse=
		// console.log("3dsadas",arrayResponse)
		// const { releaseDate, name, label, imageUrl, creditName,uuid } = object;
		// setFavSong(object.uuid===favSongid[0] ?object:"")

		// getSong(favSongid.map(item=>item))
		// 	const arrayResponse=
		// console.log("3dsadas",arrayResponse)
		// const { releaseDate, name, label, imageUrl, creditName,uuid } = object;
		// setFavSong(object.uuid===favSongid[0] ?object:"")
	}, [favSongid, res2.data]);

	useEffect(() => {
		getChart();
	}, []);
	return (
		<>
			<ToastContainer />
			<Grid container className={styles.bgBlack}>
				{/* <Grid
					item
					display={{ xs: 'none', lg: 'block' }}
					xs={0}
					md={2}
					className={styles.rightBorder}
				>
					<SideBar />
				</Grid> */}
				<Grid item xs={12} lg={12}>
					{/* <Grid item xs={12} md={12} className={styles.bottomBorder}>
						<DashboardHeader />
					</Grid> */}

					<Grid className={styles.content}>
						<div className={styles.searchDiv}>
							<SearchFieldComponent
								placeholder='Search for artists by name or Spotify ID'
								onChangeSearch={onChangeSearch}
							/>
						</div>
						<Grid container className={styles.optionDiv}>
							<TabContext value={value}>
								<Box>
									<Tabs
										onChange={handleChange}
										aria-label='lab API tabs example'
										variant='scrollable'
										scrollButtons={false}
									>
										{tabOptions.map((item, index) => (
											<Tab
												key={index}
												label={item}
												className={styles.option}
												onClick={() => setActiveState(index)}
												value={index}
												wrapped
												sx={{
													flex: 1,
													borderBottom: activeState === index ? 4 : 0,
													borderColor: activeState === index ? '#2EBB55' : '',
												}}
											/>
										))}
										<Divider
											alignSelf={'end'}
											borderColor={'#AFAFAF'}
											borderBottomWidth={4}
											w={'80%'}
										/>
									</Tabs>
								</Box>
							</TabContext>
						</Grid>

						<Grid className={''}>
							{value === -1 ? (
								isLoading ? (
									<div className={styles.loaderDiv}>
										<Loader />
									</div>
								) : (
									<TrendingArtist
										// favoriteArtists={data?.getMyFavoriteArtists?.favoriteArtists}
										isTrending={true}
										fav={fav}
										value={-1}
										setFav={setFav}
										artistData={trendingArtist}
										heading='trending Artists'
									/>
								)
							) : value === 2 ? (
								isLoading ? (
									<div className={styles.loaderDiv}>
										<Loader />
									</div>
								) : (
									<TrendingArtist
										value={2}
										artistData={favArtist}
										fav={fav}
										setFav={setFav}
										heading='Favorite Artists'
									/>
								)
							) : value === 3 ? (
								<TrendingArtist
									isTrending={false}
									songsData={favSong}
									setFavSong={setFavSong}
									value={3}
									favSongid={favSongid}
									setFavSongId={setFavSongId}
									heading='Favorite Songs'
								/>
							) : (
								''
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
export default TalentDashboard;
