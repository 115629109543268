import React from "react";
import { getFeedById } from "../Hooks/Feeds/UpVotes";
import Feed from "./Feed";

export const getAllMetaFields = (metafields) => {
  const obj = {};

  for (let i = 0; i < metafields?.length; i++) {
    if (metafields[i].key === "collaborator") {
      console.log("meta fields...", metafields[i]);
      if (metafields[i].valueType && metafields[i].valueType !== "undefined") {
        let arr = {};
        arr["collab_id"] = metafields[i].valueType;
        arr["userName"] = metafields[i].value;
        arr["profilePic"] = metafields[i].description;
        if (obj["collaborators"]) {
          obj["collaborators"] = [...obj["collaborators"], arr];
        } else {
          obj["collaborators"] = [arr];
        }
      }
    } else if (metafields[i].key === "artist") {
      obj["artistPhoto"] = metafields[i].description;
      obj["artistName"] = metafields[i].value;
    } else {
      obj[metafields[i].key] = metafields[i].value;
    }
  }
  return obj;
};

function FeedComponent(props) {
  const { feed } = props;
  const meta_fields = getAllMetaFields(feed.metafields);

  return (
    <Feed
      profile={meta_fields?.cover_photo}
      coverPhoto={meta_fields?.cover_photo}
      title={feed.title}
      name={"Toms Harry"}
      opportunity={"Posted an opportunity"}
      date={"Aug/20/22"}
      lable={"@ rockstar"}
      ads={false}
      friend={true}
    />
  );
}

export default FeedComponent;
