import { Box, Heading, HStack, Button } from 'native-base';
import React, { useState } from 'react';
import { TalentcomponentHeader } from '../../TalentComponentHeader/TalentComponentHeader';
import { TableHeaderCol4 } from '../../TalentComponentHeader/TableHeaderCol4';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SeeMore from '../../SeeMoreButton/SeeMore';
import { Loader } from '../../../Loader';
import styles from './../styles.module.css';
function DeezerPlayList({ playList, isLoading,setSorted,sorted,SetPlayLists }) {
	const [seeMore, SetSeeMore] = useState(false);

	const handleSortIcon=()=>{
		setSorted(!sorted)
		console.log("spotify sorted")
		SetPlayLists(playList.sort((a, b) => (a.playlist.latestSubscriberCount) - (b.playlist.latestSubscriberCount)))
	
	}
	return (
		<Box
			my={5}
			py={5}
			bg={'#1C1B1B'}
			w={{ md: '48%', base: '100%' }}
			rounded={'lg'}
		>
			<Box width={'92%'} alignSelf={'center'}>
				<TalentcomponentHeader
					image={require('../../../../assets/TiktokComponents/deezer1.png')}
					title={'deezers playlist'}
					subtitle={'Top playlist by number of followers'}
				/>
				<TableHeaderCol4
					isHeader={true}
					title1={'play list name '}
					title2={'positions'}
					title3={'add on'}
					title4={'fans'}
					handleSort={handleSortIcon}
					sorted={sorted}
				/>
				{isLoading && (
					<div className={styles.loaderDiv}>
						<Loader />
					</div>
				)}
				{playList.map(
					(data, index) =>
						index < (seeMore ? 14 : 5) && (
							<TableHeaderCol4
								isHeader={false}
								title1={data?.playlist?.name}
								title2={data?.position}
								title3={moment(data?.entryDate).format('DD MMM YYYY')}
								title4={data?.playlist?.latestSubscriberCount}
							/>
						)
				)}
				<SeeMore seeMore={seeMore} SetSeeMore={SetSeeMore} />
			</Box>
		</Box>
	);
}

export default DeezerPlayList;
