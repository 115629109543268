import React from 'react';

function Filter() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.39965 2.10034H18.5997C19.1296 2.10192 19.6374 2.31315 20.0121 2.68788C20.3868 3.06261 20.5981 3.5704 20.5997 4.10034V6.30034C20.5415 7.15939 20.1883 7.97194 19.5997 8.60034L15.2997 12.4003C14.9892 12.6985 14.7409 13.0554 14.5692 13.4502C14.3976 13.845 14.306 14.2699 14.2997 14.7003V19.0003C14.2862 19.333 14.1984 19.6583 14.0427 19.9525C13.8869 20.2467 13.6672 20.5023 13.3997 20.7003L11.9997 21.6003C11.6935 21.7873 11.3435 21.8902 10.9849 21.8986C10.6263 21.907 10.2718 21.8206 9.95734 21.6481C9.64283 21.4756 9.37941 21.2232 9.19369 20.9163C9.00798 20.6094 8.90655 20.259 8.89965 19.9003V14.6003C8.84686 13.8366 8.56842 13.1057 8.09965 12.5003L4.29965 8.50034C3.78142 7.9551 3.46405 7.24981 3.39965 6.50034V4.20034C3.39031 3.93043 3.43492 3.66137 3.53087 3.40892C3.62682 3.15647 3.77218 2.92571 3.95844 2.73014C4.14469 2.53457 4.3681 2.37813 4.61557 2.26999C4.86305 2.16185 5.12961 2.10417 5.39965 2.10034V2.10034Z'
				stroke='#AFAFAF'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.93 2.10034L6 10.0003'
				stroke='#AFAFAF'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default Filter;
