import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { ADDTOFAVOURITEARTIST } from "./Mutation";

import { Notification } from "../../../NotifyMsg/Notification";






const  useFavouriteArtistMutation=()=> {


    const [addFavoriteArtist,{data,error}]=useMutation(ADDTOFAVOURITEARTIST,{
onCompleted(data){
  if (data?.addFavoriteArtist?.success) {

    Notification("success", data.addFavoriteArtist.message);
    setTimeout(() => {
   

    }, 1400);
  } else {

    Notification("error", data.addFavoriteArtist.message);

  }
}
    })


  return {addFavoriteArtist}


}

export default useFavouriteArtistMutation