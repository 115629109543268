import React from 'react'
import { Box, Heading, HStack } from 'native-base'
import ButtonComponent from '../ButtonComponent'
import ProfileTitle from './ProfileTitle'
const  Subscribe=()=> {
  return (
 <HStack pl={{lg:"16px",md:0}} mt={5} justifyContent={"space-between"} alignItems="center">
<ProfileTitle fontSize={{md:"2xl",base:"lg"}}   text="subscribe"/>

<ButtonComponent 
    
    backgroundColor={"transparent"}

    paddingXMd={"80px"}
    paddingXSm={"10px"}
    paddingYSm={"6px"}
text={"subscribe"}
    paddingYMd={4}
    textProperties={{
        color: "#2EBB55",
        fontWeight: "",
        fontSize: { md: "1.2rem", base: "0.8rem" },
        fontFamily: "poppins",
        textTransform: "capitalize",
      }}
      bordercolor={"#2EBB55"}
borderwidth={1}
    onpress={""}
    flag={true}/>
 </HStack>
  )
}

export default Subscribe