/* eslint-disable react/prop-types */
/* eslint-disable key-spacing */
/* eslint-disable import/order */
/* eslint-disable indent */
/* eslint-disable quote-props */
/* eslint-disable require-jsdoc */
/* eslint-disable quotes */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Container from '@material-ui/core/Container';
import AudioLibrary from '../../Icons/AudioLibrary';
import DiscordIcon from './DiscordIcon';
import InstaIcon from './InstaIcon';
import Twitter from './Twitter';
import YoutubeIcon from './YoutubeIcon';
import Navbar from '../Navbar/Navbar';
import {
	Box,
	Hidden,
	createTheme,
	ThemeProvider,
	useMediaQuery,
} from '@material-ui/core';
import StorefrontIcon from '@material-ui/icons/Storefront';
import HighlightOutlinedIcon from '@material-ui/icons/HighlightOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
// import { useRouter } from 'next/router';
import { useNavigate } from 'react-router';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
	Apps,
	Menu,
	ContactMail,
	AssignmentInd,
	CancelOutlined,
	ExpandLess,
	ExpandMore,
	Home,
} from '@material-ui/icons';
import classes from './Sidebar.module.css';
import HomeIcon from './HomeIcon';
import ShopIcon from './ShopIcon';
import OpportunityIcon from './OpportunityIcon';
import PeopleIcon from './PeopleIcon';
import MusicIcon from './MusicIcon';
import TalentIcon from './TalentIcon';
// import useOpportunityStore from 'hooks/globalStores/useOpportunityStore'
import HelpIcon from './HelpIcon';
import SettingsIcon from './SettingsIcon';
import ConditionIcon from './ConditionIcon';
import LogoutIcon from './LogoutIcon';
import useWindowDimensions from '../../utils/WindowDimensions';
// import Cookies from 'js-cookie';
import UserInfo from './UserInfo';

const customBreakpointValues = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		customMd: 1100,
		lg: 1200,
		xl: 1560,
	},
};

const breakPointTheme = createTheme({
	breakpoints: {
		...customBreakpointValues,
	},
});
const main_menu = [
	{
		id: 1,
		title: 'Home',
		icon: <HomeIcon />,
		iconKey: 'stroke',
		url: '/dashboard',
		isSelf: true,
	},
	// {
	//   id: 2,
	//   title: 'Marketplace',
	//   icon: <StorefrontIcon />,
	//   iconKey: 'marketplace',
	//   url: 'https://stagingdev.unitedmarket.com/marketplace',
	//   isSelf: false
	// },
	{
		id: 3,
		title: 'Opportunities',
		icon: <HighlightOutlinedIcon />,
		iconKey: 'opportunities',
		url: `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities`,
		isSelf: false,
		isExpanded: true,
		subMenu: [
			{
				id: 31,
				title: 'Opportunities Feed',
				url: `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities`,
			},
			{
				id: 32,
				title: 'My Opportunities',
				url: `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/my_opportunities`,
			},
			{
				id: 33,
				title: 'My Submissions',
				url: `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/my_opportunities?my_submission`,
			},
		],
	},
	// {
	// 	id: 4,
	// 	title: 'Social',
	// 	icon: <PeopleOutlineIcon />,
	// 	iconKey: 'social',
	// 	url: '/dashboard',
	// 	isExpanded: true,
	// 	isSelf: true,
	// 	subMenu: [
	// 		{
	// 			id: 41,
	// 			title: 'Feed',
	// 			url: '/feeds',
	// 		},
	// 		{
	// 			id: 42,
	// 			title: 'ConnectMe',
	// 			url: '/connect-me',
	// 		},
	// 		{
	// 			id: 43,
	// 			title: 'Subscribers',
	// 			url: '/subscribers',
	// 		},
	// 	],
	// },
	// {
	//   id: 4,
	//   title: 'Social',
	//   icon: <PeopleOutlineIcon />,
	//   iconKey: 'social',
	//   url: 'https://stagingdev.unitedmarket.com/dashboard',
	//   isSelf: false
	// },
	// {
	//   id: 5,
	//   title: 'My Audio Library',
	//   icon: <MusicIcon />,
	//   iconKey: 'stroke',
	//   url: 'https://stagingdev.unitedmarket.com/audio-player',
	//   isSelf: false
	// },
	// {
	// 	id: 6,
	// 	title: 'Talent Dashboard',
	// 	icon: <TalentIcon />,
	// 	iconKey: 'stroke',
	// 	url: '/talent-dashboard',
	// 	isSelf: true,
	// },
	// {
	// 	id: 7,
	// 	title: 'Audio Library',
	// 	icon: <AudioLibrary />,
	// 	iconKey: 'stroke',
	// 	url: '/audio-library',
	// 	isSelf: false,
	// 	isExpanded: true,
	// 	subMenu: [
	// 		{
	// 			id: 1,
	// 			title: 'Audio Library',
	// 			url: '/audio-library',
	// 		},
	// 		{
	// 			id: 2,
	// 			title: 'Beat Packs',
	// 			url: '/beat-packs',
	// 		},
	// 		{
	// 			id: 3,
	// 			title: 'Inbox',
	// 			url: '/inbox',
	// 		},
	// 	],
	// },
];

const secondary_menu = [
	{
		id: 1,
		title: 'Help',
		icon: <HelpIcon />,
		iconKey: 'fill',
		url: '/help',
		isLogout: false,
	},
	{
		id: 2,
		title: 'Settings',
		icon: <SettingsIcon />,
		iconKey: 'stroke',
		url: '/user/settings',
		isLogout: false,
	},
	// {
	//   id: 3,
	//   title: 'Terms & Conditions',
	//   icon: <ConditionIcon />,
	//   iconKey: 'fill',
	//   url: 'https://stagingdev.unitedmarket.com/terms-and-conditions',
	//   isLogout: false
	// },
	{
		id: 4,
		title: 'Log Out',
		icon: <LogoutIcon />,
		iconKey: 'fill',
		url: '/signin',
		isLogout: true,
	},
];

export function Sidebar(props) {
	const router = useNavigate();
	// const {user} = useOpportunityStore();
	const { activeText, activeSubText } = props;
	const isDesktop = useMediaQuery('(min-width: 1200px)');
	// const classes = useStyles();
	const [open, setOpen] = React.useState('');
	const [show, setShow] = React.useState(true);
	const { height, width } = useWindowDimensions();
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const handleClick = (value) => {
		if (value === open) {
			setOpen('');
		} else {
			setOpen(value);
		}
	};

	React.useEffect(() => {}, [isDesktop]);

	React.useEffect(() => {
		if (width > 1100) {
			setShow(true);
		} else {
			setShow(false);
		}
	}, [width]);

	const navigateTo = (url, isSelf) => {
		if (isSelf) {
			return router(url);
		} else {
			return (window.location.href = url);
		}
	};

	const secondaryMenuNavigateTo = (url, isLogout) => {
		if (isLogout) {
			// Cookies.remove('sessionId', { path: '/', domain: 'localhost' });
			localStorage.clear();
			return router('/signin');
		}
		return (window.location.href = url);
	};
	return (
		<ThemeProvider theme={breakPointTheme}>
			<div className={classes.root}>
				<CssBaseline />
				<Navbar open={openDrawer} setOpen={setOpenDrawer} />
				<Box className={classes.dflex}>
					{isDesktop ? (
						<Drawer
							className={classes.drawer}
							variant='permanent'
							classes={{
								paper: classes.drawerPaper,
							}}
							anchor='left'
						>
							<Box px={3} py={2}>
								<img src='/images/united_market.png' alt='' width={200} />
							</Box>
							<Divider />
							<Typography
								style={{
									color: '#FFF',
									fontFamily: 'Poppins',
									fontSize: 15,
									padding: '20px 30px 10px',
								}}
							>
								Main Menu
							</Typography>
							<List className={classes.list}>
								{main_menu.map((text, index) => {
									return text.isExpanded ? (
										<>
											<ListItem
												button
												key={text.id}
												className={`${classes.listItem} ${
													activeText === text.title && classes.active
												}`}
												onClick={(e) => handleClick(text)}
											>
												<ListItemIcon
													className={`${classes.listIcon} ${
														classes[text.iconKey]
													}`}
												>
													{text.icon}
												</ListItemIcon>
												<ListItemText
													primary={text.title}
													className={classes.listItemText}
												/>
												{open === text ? <ExpandLess /> : <ExpandMore />}
											</ListItem>
											<Collapse
												in={open === text}
												timeout='auto'
												unmountOnExit
												className={classes.collapseMenu}
											>
												<List>
													{text.subMenu.map((submenu, key) => (
														<ListItem
															button
															key={key}
															className={`${classes.listSubItem} ${
																classes.expandMenu
															} ${
																activeSubText === submenu.title &&
																classes.subActive
															}`}
															onClick={(e) =>
																navigateTo(submenu.url, text.isSelf)
															}
														>
															<ListItemText
																primary={submenu.title}
																className={`${classes.listSubItemText}`}
																style={{ paddingLeft: '10px' }}
															/>
														</ListItem>
													))}
												</List>
											</Collapse>
										</>
									) : (
										<ListItem
											button
											key={text.id}
											className={`${classes.listItem} ${
												activeText === text.title && classes.active
											}`}
											onClick={(e) => navigateTo(text.url, text.isSelf)}
										>
											<ListItemIcon
												className={`${classes.listIcon} ${
													classes[text.iconKey]
												}`}
											>
												{text.icon}
											</ListItemIcon>
											<ListItemText
												primary={text.title}
												className={classes.listItemText}
											/>
										</ListItem>
									);
								})}
							</List>
							<Divider className={classes.list_divider} />
							<Typography
								style={{
									color: '#FFF',
									fontFamily: 'Poppins',
									fontSize: 15,
									padding: '20px 30px 10px',
								}}
							>
								Preferences
							</Typography>
							<List className={classes.list}>
								{secondary_menu.map((text, index) => (
									<ListItem
										button
										key={text.id}
										className={`${classes.listItem} ${
											activeText === text.title && classes.active
										}`}
										onClick={(e) =>
											secondaryMenuNavigateTo(text.url, text.isLogout)
										}
									>
										<ListItemIcon
											className={`${classes.listIcon} ${classes[text.iconKey]}`}
										>
											{text.icon}
										</ListItemIcon>
										<ListItemText
											primary={text.title}
											className={classes.listItemText}
										/>
									</ListItem>
								))}
							</List>
							<Box className={classes.iconWrapper}>
								<a
									href='https://www.instagram.com/unitedmarket/?utm_medium'
									target='_blank'
									rel='noreferrer'
								>
									<InstaIcon />
								</a>
								<a
									href='https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw'
									target='_blank'
									rel='noreferrer'
								>
									<Twitter />
								</a>
								<a
									href='https://discord.com/invite/VNTqdtK3PY'
									target='_blank'
									rel='noreferrer'
								>
									<DiscordIcon />
								</a>
								<a
									href='https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ?app=desktop'
									target='_blank'
									rel='noreferrer'
								>
									<YoutubeIcon />
								</a>
							</Box>
							<Box
								style={{
									flexGrow: 1,
									display: 'flex',
									alignItems: 'flex-end',
									height: '100%',
									width: '100%',
								}}
							>
								<Typography
									style={{
										color: '#AFAFAF',
										textAlign: 'center',
										width: '100%',
									}}
								>
									@{new Date().getFullYear()} United Market
								</Typography>
							</Box>
						</Drawer>
					) : (
						<Drawer
							className={classes.drawer}
							// variant="persistent"
							classes={{
								paper: classes.drawerPaper,
							}}
							open={openDrawer}
							anchor='left'
							onClose={(e) => setOpenDrawer(false)}
						>
							<Box px={2} pt={4} pb={0}>
								<img
									src='/svgs/add2.svg'
									alt=''
									onClick={() => setOpenDrawer(false)}
								/>
								{/* <img src="/images/united_market.png" alt="" /> */}
							</Box>
							<Box px={2}>{/* <UserInfo user={user} /> */}</Box>
							<Divider style={{ backgroundColor: '#323333' }} />
							<Typography
								style={{
									color: '#FFF',
									fontFamily: 'Poppins',
									fontSize: 15,
									padding: '20px 30px 10px',
								}}
							>
								Main Menu
							</Typography>
							<List className={classes.list}>
								{main_menu.map((text, index) => {
									return text.isExpanded ? (
										<>
											<ListItem
												button
												key={text.id}
												className={`${classes.listItem} ${
													activeText === text.title && classes.active
												}`}
												onClick={(e) => handleClick(text)}
											>
												<ListItemIcon
													className={`${classes.listIcon} ${
														classes[text.iconKey]
													}`}
												>
													{text.icon}
												</ListItemIcon>
												<ListItemText
													primary={text.title}
													className={classes.listItemText}
												/>
												{open === text ? <ExpandLess /> : <ExpandMore />}
											</ListItem>
											<Collapse
												in={open === text}
												timeout='auto'
												unmountOnExit
												className={classes.collapseMenu}
											>
												<List>
													{text.subMenu.map((submenu, key) => (
														<ListItem
															button
															key={key}
															className={`${classes.listSubItem} ${
																classes.expandMenu
															} ${
																activeSubText === submenu.title &&
																classes.subActive
															}`}
															onClick={(e) =>
																navigateTo(submenu.url, text.isSelf)
															}
														>
															<ListItemText
																primary={submenu.title}
																className={`${classes.listSubItemText}`}
																style={{ paddingLeft: '10px' }}
															/>
														</ListItem>
													))}
												</List>
											</Collapse>
										</>
									) : (
										<ListItem
											button
											key={text.id}
											className={classes.listItem}
											onClick={(e) => navigateTo(text.url, text.isSelf)}
										>
											<ListItemIcon
												className={`${classes.listIcon} ${
													classes[text.iconKey]
												}`}
											>
												{text.icon}
											</ListItemIcon>
											<ListItemText
												primary={text.title}
												className={classes.listItemText}
											/>
										</ListItem>
									);
								})}
							</List>
							<Divider className={classes.list_divider} />
							<Typography
								style={{
									color: '#FFF',
									fontFamily: 'Poppins',
									fontSize: 15,
									padding: '20px 30px 10px',
								}}
							>
								Preferences
							</Typography>
							<List className={classes.list}>
								{secondary_menu.map((text, index) => (
									<ListItem button key={text.id} className={classes.listItem}>
										<ListItemIcon
											className={`${classes.listIcon} ${classes[text.iconKey]}`}
											onClick={(e) =>
												secondaryMenuNavigateTo(text.url, text.isLogout)
											}
										>
											{text.icon}
										</ListItemIcon>
										<ListItemText
											primary={text.title}
											className={classes.listItemText}
										/>
									</ListItem>
								))}
							</List>
							<Box className={classes.iconWrapper}>
								<a
									href='https://www.instagram.com/unitedmarket/?utm_medium'
									target='_blank'
									rel='noreferrer'
								>
									<InstaIcon />
								</a>
								<a
									href='https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw'
									target='_blank'
									rel='noreferrer'
								>
									<Twitter />
								</a>
								<a
									href='https://discord.com/invite/VNTqdtK3PY'
									target='_blank'
									rel='noreferrer'
								>
									<DiscordIcon />
								</a>
								<a
									href='https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ?app=desktop'
									target='_blank'
									rel='noreferrer'
								>
									<YoutubeIcon />
								</a>
							</Box>
							<Box
								style={{
									flexGrow: 1,
									display: 'flex',
									alignItems: 'flex-end',
									height: '100%',
									width: '100%',
								}}
							>
								<Typography
									style={{
										color: '#AFAFAF',
										textAlign: 'center',
										width: '100%',
									}}
								>
									@2022 United Market
								</Typography>
							</Box>
						</Drawer>
					)}

					<div className={`${classes.content} ${classes.bg_dark}`}>
						{/* <Container maxWidth='xl' className={classes.container}> */}
						<div className={classes.toolbar} />
						{props.childComponent}
						{/* </Container> */}
					</div>
				</Box>
			</div>
		</ThemeProvider>
	);
}
