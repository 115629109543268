import { GraphQLClient } from 'graphql-request';

/**
 * Executes an arbitrary GraphQL query against the Reaction API
 *
 * @param {Object} query - The GraphQL query to execute
 * @param {Object} variables - The query's variables
 * @returns {Object} data - the resulting query data
 */
export default async function graphQLRequest(query, variables) {
	const endpoint = `${process.env.REACT_APP_OPPORTUNITY_URL}/graphql`;

	try {
		const graphQLClient = new GraphQLClient(endpoint, {
			timeout: 20000,
		});

		const data = await graphQLClient.request(query, variables);
		return data;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('error-fetching-graphql', error);
		return null;
	}
}
