import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import dotImage from "../../../assets/Icons/chartBottomicon.png"
import { Line } from "react-chartjs-2";
import { Box, Checkbox, HStack, Image, Text, VStack } from "native-base";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,

);
const dataObject = {
    "labels": ["Sep 19,22", "Sep 23,22", "Sep 28,22"],
    "data":  [50, 100, 60, 70, 40, 50, 20],
    "subscribers": "2,100,000",
    "platform": "Spotify",
    "socialIcon": "/spotify.png"

}
function AreaChart() {
const lables = ["","Sep 19,22", "Sep 23,22", "Sep 28,22"];
    
    const data = {
    labels: lables,
  
    position: "top",
    datasets: [
      {   
  
        // label: "Dataset 1",
        // pointStyle: 'line',
        // data: labels.map(() => 50,100,80,70,20,50,20),
        data: [0, 40, 60, 100, 120, 160],
        
        borderColor: "#2EBA55",
        // pointColor : "#fff",
        // pointStrokeColor : "#ff6c23",
        // pointHighlightFill: "#fff",
        // pointHighlightStroke: "#ff6c23",
        // gradient: "0.5",
         fill: true,
        //  backgroundColor: "#81D599 ",
         backgroundColor: "rgba(46, 186, 85, 0.3)",

        // fill: "start",
        // backgroundColor: (ScriptableContext<"line"> ) => {
        //           const ctx = context.chart.ctx;
        //           const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        //           gradient.addColorStop(0, "rgba(250,174,50,1)");
        //           gradient.addColorStop(1, "rgba(250,174,50,0)");
        //           return gradient;
        //         },

      tension: 0.5,
      borderWidth: 1,
      },
    ],
  };
  const options = {
    opacity: 0.5,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value + "M";
        },
          stepSize: 40
      },
        grid: {
          display: true,
          borderDash: [8, 2],
          color: "#AFAFAF",
    
        },
      
      },
    },
    responsive: true,

    plugins: {
      legend: {
  display:false
      },
    },
  };

  return (
  <Box
    
      p={{md:10,base:5}}
      // w={"500px"}
      w={{lg:"48%",base:"100%"}}
    >
      <VStack>
        <HStack>
          {/* <Image
            variant="ghost"
            size={"5"}
            ={"5px"}
            // source={require("../../../assets/Icons/" + {dataObject.socialIcon})}
            // source={require(`../../assets/Icons${dataObject.socialIcon}`)}
            source={require(`../../../assets/Icons${dataObject.socialIcon}`)}
            alt="Spotify Content"
          /> */}
          {/* <Text
            marginRight={5}
            paddingRight={10}
            fontSize={"18px"}
            fontWeight={"500"}
            fontFamily={"poppins"}
           // marginBottom={20}
            color="#E3E3E3"
          >
           
            {dataObject.subscribers} Total {dataObject.platform} Streams
          </Text> */}
        </HStack>
        {/* <Text
            // marginRight={5}
            // paddingLeft={10}
            fontSize={"14px"}
            fontWeight={"500"}
            fontFamily={"poppins"}
           // marginBottom={20}
            color="#555555"
          >Total videos over last 28 days</Text> */}
        <Line options={options} data={data} />
        <HStack justifyContent={"center"} alignItems={"center"} mt={4}>
<Image source={require("../../../assets/Icons/chartBottomicon.png")} resizeMode="contain" height={"16px"} width={"16px"} />
<Text ml={4} fontSize={"0.8rem"} color={"#E3E3E3"} fontFamily={"poppins"} >Total Playlist Followers</Text>
        </HStack>
        {/* <HStack space={2}
        alignItems={"center"}
        >
          <Checkbox
            color={"#2EBA55"}
            backgroundColor={"#fff"}
            value="test"
            accessibilityLabel="Video checkbox"
            colorScheme={"#2EBA55"}
            defaultIsChecked
          />
          <Box
            w={"20px"}
            h={"10px"}
            marginBottom={"2px"}
            borderBottomStyle="line"
            borderBottomWidth={"2"}
            borderColor={"#2EBA55"}
          ></Box>
          <Text fontSize="12px" color="#ffff">
            Videos
          </Text>
          <Checkbox
            backgroundColor={"#fff"}
            colorScheme={"#2EBA55"}
            value="test"
            accessibilityLabel="Changes checkbox"
            defaultIsChecked
          />
          <Box
             w={"20px"}
            h={"10px"}
            //   py={'2px'}
            marginBottom={"2px"}
            borderBottomStyle="line"
            borderBottomWidth={"2"}
            borderColor={"#2EBA55"}
          >
          
          </Box>
          <Text fontSize="12px" color="#ffff">
            Changes
          </Text>
        </HStack> */}
      </VStack>
    </Box>
  );
}

export default AreaChart;
