import { Box } from 'native-base';
import React from 'react'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  } from "chart.js";
import moment from 'moment';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,

  
  );
const PlaysBarChart=(props)=> {
const lables = props.data.map((record) => moment(record.airedAt).format('MMMM-DD-YYYY'));
const [barData, setBarData] = React.useState([])
React.useEffect(() => {
  if(props.data?.length > 0) {
    setBarData(props.data.map((record) => record.plays))
  }
}, [props.data])
    
const data = {
labels: lables,
position: "top",

datasets: [
  {
    yAxisID:"A",
    data: barData,
    borderColor: "#2EBA55",
    fill: false,
    backgroundColor: "rgba(46, 186, 85, 0.3)",
    tension: 0.5,
    borderWidth: 1,
  },

],
};
const options = {


opacity: 0.5,
scales: {
  x: {
    grid: {
      display: false,
    },
  },
 A: {

    type: 'linear',
    position: 'left',
    ticks: {
      callback: function (value, index, values) {
        return value + "M";
    },
    precision:2,
  
},
    grid: {
      display: true,
      borderDash: [8, 2],
      color: "#AFAFAF",
    },
  },
 B: {

  
    type: 'linear',
    position: 'right',
    ticks: {
      callback: function (value, index, values) {
        return value + "K";
    },
      precision:0,
      stepSize:25
},

    },
     
},
responsive: true,

plugins: {
legend:{
display:false
}
},
};
  return (

    <Box  >

    <Bar data={data} options={options}/>
    </Box>
  )
}

export default PlaysBarChart