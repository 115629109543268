/* eslint-disable require-jsdoc */
import React from 'react';
import {
	Box,
	Tooltip,
	Avatar,
	IconButton,
	Typography,
	createTheme,
	ThemeProvider,
	ListItemText,
	Hidden,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import inject from 'hocs/inject';
// import { OpportunityContext } from '../../context/OpportunityContext';
// import { get_role } from 'staticUtils/auth/Auth';
// import useViewer from 'hooks/viewer/useViewer';
import { DarkMenu, DarkMenuItem } from './DarkMenu';
import { Navigate, useNavigate } from 'react-router-dom';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const secondary_menu = [
	{
		id: 1,
		title: 'Profile',
		url: '/profile',
		isLogout: false,
	},
	{
		id: 2,
		title: 'Settings',
		url: '/user/settings',
		isLogout: false,
	},
	// {
	// 	id: 3,
	// 	title: 'Ex HitMarker',
	// 	url: '/',
	// 	isLogout: false,
	// },
	{
		id: 4,
		title: 'Logout',
		url: '/signin',
		isLogout: true,
	},
];

const secondaryMenuNavigateTo = (url, isLogout) => {
	if (isLogout) {
		// Cookies.remove('sessionId', { path: '/', domain: 'localhost' } )
		localStorage.clear();
		return (window.location.href = '/signin');
	}
	return (window.location.href = url);
};
// const settings = ['Profile', 'Settings', 'Ex HitMarker', 'Logout'];
function ProfileMenu() {
	const navigate = useNavigate();
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	// const { user } = React.useContext(OpportunityContext);
	// const [viewer, , refetch] = useViewer();
	const [roles, setRoles] = React.useState('');
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	const handleMenuOption = (name, url) => {
		if (name.toLowerCase() === 'logout') {
			localStorage.clear();
			return (window.location.href = '/signin');
		} else {
			navigate(url);
		}
	};

	return (
		<Box sx={{ flexGrow: 0 }}>
			<Hidden xsDown>
				<Tooltip title='Open settings'>
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						<Avatar
							alt='Remy Sharp'
							src={
								localStorage.getItem('profilePic')
									? localStorage.getItem('profilePic')
									: '/static/images/avatar/2.jpg'
							}
							style={{ width: '45px', height: '45px' }}
						/>
						<div style={{ paddingLeft: 10 }}>
							{console.log(typeof localStorage.getItem('userName'))}
							<Typography style={{ color: '#E3E3E3', fontSize: 18 }}>
								{localStorage.getItem('userName') &&
								localStorage.getItem('userName') != null &&
								localStorage.getItem('userName') !== 'null'
									? localStorage.getItem('userName')
									: 'Guest'}
							</Typography>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-end',
								}}
							>
								<Typography style={{ color: '#AFAFAF', fontSize: 13 }}>
									{localStorage.getItem('accountType') || 'Guest'}
								</Typography>
								<ExpandMoreIcon style={{ color: '#AFAFAF' }} />
							</div>
						</div>
					</IconButton>
				</Tooltip>
				<DarkMenu
					id='customized-menu'
					anchorEl={anchorElUser}
					keepMounted
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					{secondary_menu.map((setting, key) => (
						<React.Fragment key={key}>
							<DarkMenuItem>
								<ListItemText
									primary={setting.title}
									onClick={() => handleMenuOption(setting.title, setting.url)}
									style={{ fontFamily: 'Poppins' }}
								/>
							</DarkMenuItem>
						</React.Fragment>
					))}
				</DarkMenu>
			</Hidden>
			<Hidden smUp>
				<Avatar
					alt='Profile image'
					src={
						localStorage.getItem('profilePic')
							? localStorage.getItem('profilePic')
							: '/static/images/avatar/2.jpg'
					}
					style={{ width: '33px', height: '33px' }}
				/>
			</Hidden>
		</Box>
	);
}

export default ProfileMenu;
