/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { fontSize } from '@mui/system';
import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Menu,
	Text,
} from 'native-base';

import React, { useState } from 'react';
import { DashboardHeader } from '../Headers';
import SideBar from '../SideBar/SideBar';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import slider from '../../assets/Notification/slider.png';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import DaysFilter from '../TalentDashboard/Tabs/Airplay/DaysFilter';
import DaySelecter from './DaySelecter';
import RightBar from './RightBar';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler
// );
// const dataObject = {
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   data: [50, 100, 60, 70, 40, 50, 20],
//   subscribers: '2,100,000',
//   platform: 'Spotify',
//   socialIcon: '/spotify.png',
// };
// {'0%', '10%', '20%', '30%', '40%', '50'}
function FinanicalGraphs() {
	const lables = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

	const data = {
		labels: lables,
		position: 'top',

		datasets: [
			{
				data: [230, 250, 270, 300, 350, 370, 380],
				label: 'Card',
				borderColor: '#2EBA55',
				fill: true,
				backgroundColor: 'rgba(46, 186, 85, 0.3)',
				tension: 0,
				borderWidth: 2,
				pointStyle: 'circle',
			},
			//   {
			//     data: [10, 15, 20, 33, 30, 20, 35, 25, 28, 30, 35, 30],
			//     label: 'cash',
			//     borderColor: 'rgba(24, 160, 251, 1)',
			//     fill: false,
			//     backgroundColor: 'rgba(24, 160, 251, 1)',
			//     tension: 0.5,
			//     borderWidth: 2,
			//   },
		],
	};
	const options = {
		opacity: 0.5,

		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					display: true,
					borderDash: [8, 2],
					color: '#fff',
				},
				ticks: {
					precision: 0,
				},
			},
		},
		// responsive: true,

		plugins: {
			legend: {
				// backgroundColor: "#000",
				//color: "#0000",
				position: 'bottom',

				align: 'start',
			},
		},
	};

	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};

	return (
		// <Box w={'100%'} bg={'#000201'}>
		// 	<HStack w={'100%'}>
		// 		<Box
		// 			borderLeftWidth={{ lg: 1, md: 0 }}
		// 			borderColor={'#fff'}
		// 			w={{ xl: '83%', lg: '83%', base: '100%' }}
		// 		>
		<Box w={'100%'}>
			<HStack w={'100%'} flexGrow={1} flexWrap={'wrap'}>
				<Box w={{ xl: '75%', lg: '75%', base: '100%' }}>
					<Box w={'90%'} alignSelf={'center'} pt={5} pb={{ lg: 5, base: 0 }}>
						<HStack w={'100%'} justifyContent={'space-between'}>
							<Heading
								my={5}
								fontSize={{
									xl: '40px',
									lg: '36px',
									md: '30px',
									base: '20px',
								}}
								color={'#fff'}
								textTransform={'capitalize'}
								fontFamily={'poppins'}
								fontWeight={'medium'}
							>
								Hello Kibriya
							</Heading>
							<FilterMenu />
						</HStack>
						<Heading
							mb={3}
							fontSize={{
								xl: '18px',
								lg: '16px',
								md: '14px',
								base: '12px',
							}}
							color={'#e3e3e3'}
							textTransform={'capitalize'}
							fontFamily={'poppins'}
							fontWeight={'normal'}
						>
							Welcome Back
						</Heading>
						<Box my={5}>
							<HStack mt={{ lg: 5, base: 0 }} mb={5}>
								<Heading
									color={'#fff'}
									fontSize={{
										xl: '24px',
										lg: '20px',
										md: '18px',
										base: '16px',
									}}
									fontFamily={'Poppins'}
									fontWeight={'medium'}
								>
									Beats Revenue
								</Heading>
								<Heading
									px={2}
									alignSelf={'center'}
									color={'#AFAFAF'}
									fontSize={{
										xl: '14px',
										lg: '14px',
										md: '12px',
										base: '10px',
									}}
									fontFamily={'Poppins'}
									fontWeight={'normal'}
								>
									Updated Yesterday at 9:05 AM
								</Heading>
							</HStack>
							<Box
								w={'100%'}
								alignSelf={'center'}
								bg={'#1c1b1b'}
								rounded={'lg'}
								py={5}
							>
								<Box w={'90%'} alignSelf={'center'}>
									<Line options={options} data={data} />
								</Box>
							</Box>
						</Box>
						<Box my={5}>
							<HStack mt={{ lg: 5, base: 0 }} mb={5}>
								<Heading
									color={'#fff'}
									fontSize={{
										xl: '24px',
										lg: '20px',
										md: '18px',
										base: '16px',
									}}
									fontFamily={'Poppins'}
									fontWeight={'medium'}
								>
									Kits Revenue
								</Heading>
								<Heading
									px={2}
									alignSelf={'center'}
									color={'#AFAFAF'}
									fontSize={{
										xl: '14px',
										lg: '14px',
										md: '12px',
										base: '10px',
									}}
									fontFamily={'Poppins'}
									fontWeight={'normal'}
								>
									Updated Yesterday at 9:05 AM
								</Heading>
							</HStack>
							<Box
								w={'100%'}
								alignSelf={'center'}
								rounded={'lg'}
								bg={'#1c1b1b'}
								py={5}
							>
								<Box w={'90%'} alignSelf={'center'}>
									<Line options={options} data={data} />
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					w={{ xl: '25%', lg: '25%', base: '100%' }}
					borderLeftWidth={{ lg: 1, md: 0, base: 0 }}
					borderColor={'#fff'}
					// borderWidth={1}
				>
					<Box w={'90%'} alignSelf={'center'} pt={{ xl: 8, md: 0, base: 0 }}>
						<RightBar />
					</Box>
				</Box>
			</HStack>
		</Box>
		// 		</Box>
		// 	</HStack>
		// </Box>
	);

	function FilterMenu() {
		return (
			<Menu
				rounded={'lg'}
				borderColor={'#fff'}
				borderWidth={1}
				alignSelf={'center'}
				color={'#afafaf'}
				// fontSize={{ md: '16px' }}
				w={'150px'}
				mx={3}
				_text={{
					color: '#afafaf',
				}}
				background={'#1C1B1B'}
				placement={'bottom left'}
				trigger={(triggerProps) => {
					return (
						<Button
							variant={'ghost'}
							rounded={'md'}
							_hover={{ bg: 'transparent' }}
							_focused={{ bg: '2ebb55' }}
							_pressed={{ bg: 'transparent' }}
							{...triggerProps}
						>
							<Box alignSelf={'center'} mx={2}>
								<Image source={slider} size={6} />
							</Box>
						</Button>
					);
				}}
			>
				<Menu.Item
					bg={'#1C1B1B'}
					borderBottomWidth='1px'
					borderBottomColor='#afafaf'
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
					w={'100%'}
					alignSelf={'center'}
				>
					<Heading
						w={'100%'}
						my={2}
						fontSize={{ xl: 'lg', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#afafaf'}
						alignSelf={'center'}
					>
						Daily
					</Heading>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					borderBottomWidth='1px'
					borderBottomColor='#afafaf'
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
					w={'100%'}
					alignSelf={'center'}
				>
					<Heading
						my={2}
						fontSize={{ xl: 'lg', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#afafaf'}
						w={'100%'}
						alignSelf={'center'}
					>
						Weekly
					</Heading>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					borderBottomWidth='1px'
					borderBottomColor='#afafaf'
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
					alignSelf={'center'}
					w={'100%'}
				>
					<Heading
						my={2}
						fontSize={{ xl: 'lg', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#afafaf'}
						w={'100%'}
						alignSelf={'center'}
					>
						Monthly
					</Heading>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
					w={'100%'}
					alignSelf={'center'}
				>
					<Heading
						my={2}
						fontSize={{ xl: 'lg', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						textTransform={'capitalize'}
						color={'#afafaf'}
						w={'100%'}
						alignSelf={'center'}
					>
						Yearly
					</Heading>
				</Menu.Item>
			</Menu>
		);
	}
}

export default FinanicalGraphs;
