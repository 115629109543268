import { gql } from '@apollo/client';

export const isEmailexist = gql`
	mutation ($email: String!) {
		isEmailExists(email: $email) {
			success
			message
			isLeftUser
			user {
				_id
				email
				roleId {
					_id
					roleName
				}
			}
		}
	}
`;
