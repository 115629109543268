/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Box, Divider, HStack, Image } from 'native-base';
import React from 'react';

import Footer from '../Footer/Footer';
import GetNotified from '../Getnotified/GetNotified';
import HeaderComp from '../Header/HeaderComp';
import DistributionBanner from './DistributionBanner';
import Services from './Services';

function Distribution() {
  return (
    <Box w={'100%'} bg={'#000201'}>
      <HeaderComp />
      <DistributionBanner />
      <Services />

      <GetNotified />
      <Footer />
    </Box>
  );
}

export default Distribution;
