import React, { useContext, useState } from 'react';
import { Button, Input, Text } from 'native-base';
import { Notification } from '../../NotifyMsg/Notification';
import useAuth from '../../../context/AuthContext';

import { useMutation } from '@apollo/client';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { AuthContext } from '../Layout';
import { createSubscription } from '../../../Graphql_services/PaymentsMutation';
import { verify_PromoCode } from '../../../Graphql_services/PromoCodeMutation';
import styles from './styles.module.css';
export default function CustomStripeForm() {
	const { setActiveStep, setProgressbarValue, priceID, Email } =
		useContext(AuthContext);
	const { setUser, setIsAuthenticated } = useAuth();
	const [errMsg, setErrMsg] = useState('');
	const [isDisbaled, setIsDisabled] = useState(false);
	const [promo, setPromo] = useState('');
	const stripe = useStripe('');
	const elements = useElements();
	const [Subscription] = useMutation(createSubscription, {
		fetchPolicy: 'no-cache',
		context: { clientName: 'auth' },
		onCompleted(data) {
			console.log(data, 'asdasdsad');
			if (data.attachSubscriptionForUser.success) {
				localStorage.setItem(
					'profilePic',
					data?.attachSubscriptionForUser?.user?.profilePic
				);
				localStorage.setItem(
					'userName',
					data?.attachSubscriptionForUser?.user?.userName
				);
				localStorage.setItem(
					'accountType',
					data?.attachSubscriptionForUser?.user?.roleId?.roleName
				);
				localStorage.setItem(
					'token',
					data?.attachSubscriptionForUser?.accessToken
				);
				localStorage.setItem(
					'userId',
					data?.attachSubscriptionForUser?.user?._id
				);
				localStorage.setItem(
					'opportunityToken',
					data?.attachSubscriptionForUser?.reactionAccessToken
				);
				document.cookie = `sessionId=${data?.attachSubscriptionForUser?.sessionId};path=/;domain=unitedmarket.com`;
				setUser(data?.attachSubscriptionForUser?.user);
				setIsAuthenticated(true);
				setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				setIsDisabled(false);
			}
		},
		onError(error) {
			console.log('error.....', error);
			Notification('error', error);
			setIsDisabled(false);
		},
	});
	const [verifyPromoCode] = useMutation(verify_PromoCode, {
		fetchPolicy: 'no-cache',
		context: { clientName: 'auth' },
		onCompleted(data) {
			console.log(data, 'in verify promo');
			if (data?.checkPromocode?.success) {
				Notification('success', data?.checkPromocode?.message);
			}
			if (!data.checkPromocode.success) {
				Notification('error', data?.checkPromocode?.message);
			}
		},
		onError(error) {
			console.log('error.....', error);
			Notification('error', error);
			setIsDisabled(false);
		},
	});
	const handleSubmit = async (event) => {
		event.preventDefault();
		console.log('submitting');
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		});
		if (error) {
			console.log('[error]', error);
			setErrMsg(error.message);
		} else {
			setIsDisabled(true);
			await Subscription({
				variables: {
					priceId: priceID,
					email: localStorage.getItem('email'),
					paymentMethodId: paymentMethod.id,
					promoCode: promo,
				},
			});
			setErrMsg('');
			// TODO: pass paymentMethod.id to your server and save it in your database
		}
	};
	const verifyPromo = async () => {
		console.log('verifyPromo');
		await verifyPromoCode({
			variables: {
				promoCode: promo,
				email: localStorage.getItem('email'),
			},
		});
	};
	return (
		<div className={styles.cardInfo}>
			{' '}
			<form onSubmit={handleSubmit}>
				<CardElement
					options={{
						style: {
							base: {
								fontSize: '16px',
								color: '#fff',
								border: '1px solid #afafaf',
								borderRadius: '4px',
								padding: '8px',
								'::placeholder': {
									color: '#aab7c4',
								},
							},
						},
					}}
				/>

				<Text
					mt={10}
					textTransform='capitalize'
					color='#FF0000'
					fontWeight={'normal'}
					fontFamily='poppins'
					fontSize={{
						lg: '1.1rem',
						md: '0.9rem',
						base: '0.9rem',
					}}
				>
					{errMsg}
				</Text>
				<br />
				<Input
					placeholder='Promo Code'
					color='#E3E3E3'
					fontSize={{ xl: '1.2rem', md: '1rem', base: '0.9rem' }}
					onChange={(e) => setPromo(e.target.value)}
					value={promo}
					InputRightElement={
						<Button
							_hover={{
								backgroundColor: 'transparent',
								borderColor: '#2EBB55',
								borderWidth: 1,
								_text: { color: '#2EBB55' },
							}}
							_text={{
								color: '#E3E3E3',
								fontWeight: 'semibold',
								fontSize: { xl: '1.2rem', md: '1rem', base: '0.9rem' },
								fontFamily: 'poppins',
								textTransform: 'uppercase',
							}}
							_pressed={{ bg: '#2ebb55', _text: { color: '#e3e3e3' } }}
							px={{ md: 7, base: 3 }}
							py={{ md: 2, base: 1 }}
							mx={3}
							my={3}
							h={{ lg: '45px', sm: '35px', base: '48px' }}
							disabled={isDisbaled}
							borderColor={5}
							backgroundColor={'#2EBB55'}
							borderWidth={1}
							// type='submit'
							onPress={() => verifyPromo()}
						>
							Apply
						</Button>
					}
				></Input>

				<Button
					_hover={{
						backgroundColor: 'transparent',
						borderColor: '#2EBB55',
						borderWidth: 1,
						_text: { color: '#2EBB55' },
					}}
					_text={{
						color: '#E3E3E3',
						fontWeight: 'semibold',
						fontSize: { xl: '1.2rem', md: '1rem', base: '0.9rem' },
						fontFamily: 'poppins',
						textTransform: 'uppercase',
					}}
					px={{ md: 7, base: 3 }}
					py={{ md: 2, base: 1 }}
					h={{ lg: '45px', sm: '35px', base: '48px' }}
					mt={5}
					disabled={isDisbaled}
					borderColor={5}
					backgroundColor={'#2EBB55'}
					borderWidth={1}
					type='submit'
					onPress={handleSubmit}
					isDisabled={!stripe}
				>
					Pay
				</Button>
				{/* <button type='submit' disabled={!stripe}>
				Pay
			</button> */}
			</form>
		</div>
	);
}
