/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Title } from 'chart.js';
import {
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Hidden,
	Link,
} from 'native-base';
import React from 'react';
import styles from './styles.module.css';
import UploadMusic from '../../assets/Distribution/UploadMusic.png';
import Stream from '../../assets/Distribution/Stream.png';
import GetPaid from '../../assets/Distribution/GetPaid.png';
import FundCareerImg from '../../assets/Distribution/FundCareer.png';
import Lable from '../../assets/Distribution/Lable.png';
import Global from '../../assets/Distribution/Global.png';
import Master from '../../assets/Distribution/Master.png';
function Services() {
	return (
		<Box w={'100%'} bg={'#000201'}>
			<DistribitionTiles />
			<DistributionOffer />
			<FundCareer />
		</Box>
	);

	function DistribitionTiles() {
		return (
			//  <Hidden from='base' till='sm'>
			<Box
				// w={{ xl: '80%', md: '100%', base: '90%' }}
				className='card_wraper'
				alignSelf={'center'}
				mt={{ xl: 20, base: 10 }}
				mb={{ xl: 10, base: 5 }}
			>
				<div className={styles.features}>
					<HStack justifyContent={'space-between'} flexWrap={'wrap'}>
						<TabTiles
							image={UploadMusic}
							number={'01'}
							title={'Upload Your Music'}
							desc={
								'After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music.'
							}
						/>
						<TabTiles
							image={Stream}
							number={'02'}
							title={'It Streams Everywhere'}
							desc={
								'Once we have your song, we’ll put it our on Apple Music, Spotify, and wherever else you want it streaming. We make sure your song is heard so you have the best chance of success and reaching your career goals.'
							}
						/>
						<TabTiles
							image={GetPaid}
							number={'03'}
							title={'Upload Your Music'}
							desc={
								'Get paid on time, every time your song is streamed... which will be often. Not only will you make more money with United Market’s personalized plan, but you’ll also keep 100% of your masters! Withdraw the money from your United Market account as soon as the money hits or wait for it to pile up. The choice is yours!'
							}
						/>
					</HStack>
				</div>
			</Box>
		);
	}
	function TabTiles(props) {
		const { image, number, title, desc } = props;

		return (
			<div
				className={
					number === '01'
						? styles.distributionService
						: number === '02'
						? styles.distributionServiceTwo
						: styles.distributionServiceThree
				}
			>
				<Box>
					<Heading
						my={5}
						alignSelf={'center'}
						fontFamily={'Mulish'}
						fontWeight={800}
						fontSize={{ xl: '48px', lg: '40px', md: '36px', base: '24px' }}
						color={'#2ebb55'}
					>
						{number}
					</Heading>
					<Heading
						alignSelf={'center'}
						textAlign={'center'}
						fontFamily={'Poppins'}
						fontWeight={'semibold'}
						fontSize={{
							'2xl': '32px',
							xl: '28px',
							lg: '24px',
							md: '20px',
							base: '14px',
						}}
						color={'#fff'}
					>
						{title}
					</Heading>
					<Heading
						mt={{ md: 10, base: 5 }}
						w={'90%'}
						textAlign={'center'}
						alignSelf={'center'}
						fontFamily={'Poppins'}
						fontWeight={'normal'}
						fontSize={{ xl: '16px', lg: '14px', md: '12px', base: '10px' }}
						lineHeight={{ xl: '24px', lg: '20px', base: '16px' }}
						color={'#fff'}
					>
						{desc}
					</Heading>
				</Box>
				{/*</Box> */}
			</div>
		);
	}
	function DistributionOffer() {
		return (
			<Box
				w={{ xl: '80%', lg: '80%', base: '90%' }}
				alignSelf={'center'}
				my={{ xl: 10, base: 5 }}
			>
				<HStack justifyContent={'space-between'} my={5}>
					<Box justifyContent={'center'}>
						<img
							src={Global}
							className={styles.offerImg}
							alt='Global Music Distribution'
						/>
					</Box>
					<Box w={'65%'}>
						<Heading
							fontSize={{ xl: '32px', lg: '24px', md: '20px', base: '16px' }}
							fontFamily={'Poppins'}
							fontWeight={'semibold'}
							color={'#fff'}
							mb={{ xl: '40px', lg: '30px', md: '25px', base: '10px' }}
						>
							Global Music Distribution
						</Heading>
						<Heading
							fontSize={{ xl: '24px', lg: '20px', md: '16px', base: '10px' }}
							lineHeight={{ xl: '36px', lg: '30px', md: '24px', base: '16px' }}
							fontFamily={'Poppins'}
							fontWeight={400}
							color={'#fff'}
						>
							Connect with more people than ever before using United Market to
							release your music on major streaming platforms like Spotify,
							Apple Music, Amazon, and Deezer. Get your music streamed and go
							viral on TikTok and other social media platforms like never
							before. Your music is distributed everywhere in the world so that
							you can grow your domestic and international fan base.
						</Heading>
					</Box>
				</HStack>
				<HStack justifyContent={'space-between'} my={8}>
					<Box justifyContent={'center'}>
						<img
							src={Master}
							className={styles.offerImg}
							alt='Own Your Masters'
						/>
					</Box>
					<Box w={'65%'}>
						<Heading
							fontSize={{ xl: '32px', lg: '24px', md: '20px', base: '16px' }}
							fontFamily={'Poppins'}
							fontWeight={'semibold'}
							color={'#fff'}
							mb={{ xl: '40px', lg: '30px', md: '25px', base: '10px' }}
						>
							Own Your Masters
						</Heading>
						<Heading
							mb={{ xl: '30px', lg: '15px', md: '15px', base: '10px' }}
							fontSize={{ xl: '24px', lg: '20px', md: '16px', base: '10px' }}
							lineHeight={{ xl: '36px', lg: '30px', md: '24px', base: '16px' }}
							fontFamily={'Poppins'}
							fontWeight={'normal'}
							color={'#fff'}
						>
							It’s your music-- own it! With us, you never have to worry about
							getting screwed over and not owning your creativity. Keep 100% of
							your masters on every song, EP, album, or anything else you want
							the world to hear.
						</Heading>
						<Link>
							<button
								data-tf-popup='F9YFcKqF'
								data-tf-iframe-props='title=Composition Collection'
								data-tf-medium='snippet'
								className='ApplyNowPublishingReverse'
							>
								&nbsp;Apply Now
							</button>
						</Link>
					</Box>
				</HStack>
				<HStack justifyContent={'space-between'} my={5}>
					<Box justifyContent={'center'}>
						<img
							src={Lable}
							className={styles.offerImg}
							alt='See What Labels See'
						/>
					</Box>
					<Box w={'65%'}>
						<Heading
							fontSize={{ xl: '32px', lg: '24px', md: '20px', base: '16px' }}
							fontFamily={'Poppins'}
							fontWeight={'semibold'}
							color={'#fff'}
							mb={{ xl: '40px', lg: '30px', md: '25px', base: '10px' }}
						>
							See What Labels See
						</Heading>
						<Heading
							fontSize={{ xl: '24px', lg: '20px', md: '16px', base: '10px' }}
							lineHeight={{ xl: '36px', lg: '30px', md: '24px', base: '16px' }}
							fontFamily={'Poppins'}
							fontWeight={'normal'}
							color={'#fff'}
						>
							Get the most accurate and up-to-date statistics on your music. You
							see what the labels and A&Rs see when scouting talent.
						</Heading>
						<Heading
							mt={{ xl: '20px', lg: '15px', md: '5px', base: '10px' }}
							fontSize={{ xl: '24px', lg: '20px', md: '16px', base: '10px' }}
							lineHeight={{ xl: '36px', lg: '30px', md: '24px', base: '16px' }}
							fontFamily={'Poppins'}
							fontWeight={'normal'}
							color={'#fff'}
						>
							Your insights are personalized and track the performances of the
							songs from every platform and every country it’s streamed. These
							stats can help us better target your audience and help you plan
							the best next steps for your career.
						</Heading>
					</Box>
				</HStack>
			</Box>
		);
	}
	function Offer(props) {
		const { image, title, desc } = props;

		return (
			<HStack justifyContent={'space-between'}>
				<Box>
					<img src={image} width={'300px'} height={'300px'} alt={title} />
				</Box>
				<Box w={'65%'}>
					<Heading
						fontSize={{ xl: '32px', lg: '24px', md: '20px', base: '16px' }}
						fontFamily={'Poppins'}
						fontWeight={'semibold'}
						color={'#fff'}
					>
						{title}
					</Heading>
					<Heading
						mt={8}
						fontSize={{ xl: '24px', lg: '20px', md: '16px', base: '10px' }}
						lineHeight={{ xl: '36px', lg: '30px', md: '24px', base: '16px' }}
						fontFamily={'Poppins'}
						fontWeight={'normal'}
						color={'#fff'}
					>
						{desc}
					</Heading>
				</Box>
			</HStack>
		);
	}
	function FundCareer() {
		return (
			<Box
				w={'100%'}
				alignSelf={'center'}
				my={{ xl: '80px', lg: '25px', md: '20px', base: '5px' }}
				py={{ xl: '80px', lg: '25px', md: '20px', base: '5px' }}
				bg={'#0F0F0F'}
			>
				<Box
					w={{ xl: '80%', lg: '80%', base: '90%' }}
					alignSelf={'center'}
					my={8}
				>
					<HStack justifyContent={'space-between'}>
						<Box w={'55%'}>
							<Heading
								my={{ xl: 5, base: 3 }}
								textAlign={'left'}
								fontFamily={'Poppins'}
								fontWeight={'semibold'}
								fontSize={{ xl: '50px', lg: '40px', md: '30px', base: '16px' }}
								color={'#f3f3f3'}
							>
								Fund Your Career
							</Heading>
							<Heading
								my={{ xl: 5, base: 3 }}
								textAlign={'left'}
								fontFamily={'Poppins'}
								fontWeight={'normal'}
								lineHeight={{ xl: '36px', lg: '20px', base: '16px' }}
								fontSize={{ xl: '24px', lg: '20px', md: '14px', base: '10px' }}
								color={'#cccccc'}
							>
								We invest in you and your music. Get funding for your career.
								You will get a personalized funding plan to fit your needs, so
								every dollar goes towards getting to the next level!
							</Heading>
						</Box>
						<Image
							source={FundCareerImg}
							w={{ xl: '373px', lg: '323px', base: '33%' }}
							h={{ xl: '454px', lg: '404px', base: '150px' }}
						/>
					</HStack>
					<Hidden from='base' till='sm'>
						<div className={styles.features}>
							<HStack>
								<FundTab title={'We offer a tailored distribution deal'} />
								<FundTab title={'We help grow your music and audience'} />
								<FundTab title={'Your music achieves new heights'} />
							</HStack>
						</div>
					</Hidden>
					<Hidden from='sm'>
						<HStack flexWrap={'wrap'} justifyContent={'space-between'}>
							<FundTabMob title={'We offer a tailored distribution deal'} />
							<FundTabMob title={'We help grow your music and audience'} />
							<FundTabMob title={'Your music achieves new heights'} />
						</HStack>
					</Hidden>
				</Box>
			</Box>
		);
	}
	function FundTab(props) {
		const { title } = props;
		return (
			<div className={styles.info}>
				<Box
					// w={{ xl: '406px', lg: '31.5%', base: '47%' }}
					bg={'#000'}
					mt={8}
					// h={{ xl: '292px', lg: '250px', base: '130px' }}
					alignItems={'center'}
					justifyContent={'center'}
					rounded={'md'}
				>
					<Heading
						w={{ xl: '90%', base: '80%' }}
						alignSelf={'center'}
						fontSize={{ xl: '32px', lg: '30px', md: '24px', base: '16px' }}
						lineHeight={{ xl: '54px', lg: '40px', mb: '30px', base: '24px' }}
						fontWeight={'medium'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						alignItems={'center'}
						justifyContent={'center'}
						color={'#fff'}
					>
						{title}
					</Heading>
				</Box>
			</div>
		);
	}
	function FundTabMob(props) {
		const { title } = props;
		return (
			<Box
				w={{ xl: '406px', lg: '31.5%', base: '47%' }}
				bg={'#000'}
				mt={8}
				h={{ xl: '292px', lg: '250px', base: '130px' }}
				alignItems={'center'}
				justifyContent={'center'}
				rounded={'md'}
			>
				<Heading
					w={{ xl: '90%', base: '80%' }}
					alignSelf={'center'}
					fontSize={{ xl: '32px', lg: '30px', md: '24px', base: '16px' }}
					lineHeight={{ xl: '54px', lg: '40px', mb: '30px', base: '24px' }}
					fontWeight={'medium'}
					fontFamily={'Poppins'}
					textAlign={'center'}
					alignItems={'center'}
					justifyContent={'center'}
					color={'#fff'}
				>
					{title}
				</Heading>
			</Box>
		);
	}
}

export default Services;
