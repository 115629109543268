import React, { useState } from 'react';
import {
	Box,
	Center,
	HamburgerIcon,
	Heading,
	HStack,
	Image,
	Menu,
	Pressable,
	Text,
	VStack,
	Hidden,
} from 'native-base';
import SidebarItem from './SidebarItem';
import items from './sidebar.json';
import prefItems from './preferenceMenu.json';
import SocialIconTray from './SocialIconTray';
import { Dimensions } from 'react-native-web';
import { useEffect } from 'react';
// import { Icon } from "@mui/material";
// import { icons } from "react-icons";
// import { useMediaQuery } from 'native-base';

const SideBar = (props) => {
	const width = Dimensions.get('window').width;
	const [isOpen, setIsopen] = useState(false);
	// const [isValue, setValue] = useState("");

	// const cbhandler=()=>{
	//   let value=isValue
	//   return value;
	//   }

	// const Example = () => {
	//   const [isSmallScreen] = useMediaQuery({
	//     minHeight: 280,
	//     maxHeight: 480
	//   });
	const ToggleSidebar = () => {
		isOpen === true ? setIsopen(false) : setIsopen(true);
		if (isOpen === true) {
			props.cbhandler('10%');
		} else {
			props.cbhandler('100%');
		}
	};
	useEffect(() => {
		if (Dimensions.get('window').width >= 600) {
			setIsopen(true);
		}
	}, []);
	return (
		<>
			{/* <VStack 
     elevation={10}
         //z-Index={999}
          position={"static"}
          width={"100%"}
          justifyContent={"center"}
          backgroundColor={"#000"} 
          //z-Index={1}
          // paddingTop={"20px"}
          //zIndex={2}
          px={3}
          >
       <Image
                h={{lg:"120px", sm:"50px"}}
                width={{lg:"180px", md: "180px", sm: "150"}}
               // size={"180"}
                resizeMode="contain"
              //  p={5}
                marginLeft={2}
                source={require("../../assets/Logo/Group 1583.png")}
                alt="image"
                />
          </VStack> */}
			{/* <Hidden from='base' till='lg'> */}
			<VStack
				width={'100%'}
				flex={1}
				// width={"150px"}
				// w={{ xl: "20%", base: "100%" }}
				//position={"sticky"}
				backgroundColor={'#000'}
				zIndex={999}
				minHeight={'100vh'}
				// flex={1}
				// top={1}
				// borderRightWidth={1}
				// borderColor={"#fff"}
			>
				{/* <Center> */}
				<Image
					h={{ lg: '120px', sm: '40px' }}
					href={'/'}
					width={{
						xl: '200px',
						lg: '140px',
						md: '140px',
						sm: '100px',
						base: '80px',
					}}
					// size={"180"}
					resizeMode='contain'
					//  p={5}
					marginLeft={5}
					marginRight={5}
					// paddingX={"20px"}
					source={require('../../assets/Logo/Group 1583.png')}
					alt='logo'
				/>
				{/* </Center> */}
				{isOpen === false ? (
					<HamburgerIcon
						display={{ xl: 'none', lg: 'none' }}
						backgroundColor='#000'
						justifyContent='centre'
						size={'sm'}
						//  m={5}
						p={1}
						onClick={ToggleSidebar}
					/>
				) : (
					<Image
						height={'15px'}
						width={'15px'}
						display={{ lg: 'none' }}
						marginTop={10}
						onClick={ToggleSidebar}
						marginX={5}
						marginY={5}
						// paddingTop={5}
						source={require('../../assets/Icons/crossIcon.png')}
						alt='Drop Arrow Icon'
					/>
				)}

				{isOpen === true ? (
					<VStack
						zIndex={2}
						backgroundColor='#000'
						px={2}
						width={{ xl: '100%', base: '100%' }}
						py={3}
					>
						<Heading
							backgroundColor='#000'
							color='#E3E3E3'
							fontWeight={'500'}
							fontSize={'14px'}
							fontFamily={'poppins'}
							paddingLeft={'8%'}
							paddingBottom={'30px'}
						>
							Main Menu
						</Heading>

						<Box
							backgroundColor='#000'
							width={'100%'}
							textDecoration={'none'}
							_hover={{ bg: '#2EBA55' }}
						>
							{items.map((item, index) => (
								<SidebarItem key={index} item={item} />
							))}
						</Box>
						<Box
							w={'85%'}
							h={'20px'}
							py={'6px'}
							marginLeft={'15px'}
							borderBottomStyle='dotted'
							borderBottomWidth={'2'}
							borderColor={'#fff'}
						></Box>

						<Heading
							color='#E3E3E3'
							marginTop={'4'}
							paddingTop={'20px'}
							fontWeight={'500'}
							fontSize={'14px'}
							paddingRight={'2px'}
							fontFamily={'poppins'}
							paddingLeft={'6%'}
							paddingBottom={'30px'}
						>
							Preferences
						</Heading>

						<Box
							flexdirection='row'
							backgroundColor={'#000'}
							textDecoration={'none'}
						>
							{prefItems.map((item, index) => (
								<SidebarItem key={index} item={item} />
							))}
						</Box>

						<Box paddingBottom={'80px'}>
							<SocialIconTray
								instagramUrl='https://www.instagram.com/unitedmarket/'
								twitterUrl='https://twitter.com/unitedmarketapp?s=11'
								discordUrl='https://www.linkedin.com/company/unitedmarket/'
								youtubeUrl='https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ'
							/>
						</Box>
						<Box
							position={'absolute'}
							bottom={0}
							alignSelf={'center'}
							paddingBottom={5}
						>
							<Text
								fontSize={{
									xl: '12px',
									lg: '10px',
									md: '8px',
									base: '14px',
								}}
								color='#ffff'
							>
								{' '}
								@ 2022{' '}
								<Text href='https://unitedmarket.com' textDecoration='none'>
									{' '}
									United Market
								</Text>
							</Text>
						</Box>
					</VStack>
				) : (
					//  </VStack>
					<Box from='lg'>
						<Menu
							backgroundColor={'transparent'}
							width={`${width}px`}
							trigger={(triggerProps) => {
								return (
									<Pressable {...triggerProps}>
										{/* <HamburgerIcon
                    display={{ lg: "none" }}
                    justifyContent="centre"
                    size={"sm"}
                    m={5}
                    onClick={ToggleSidebar}
                  />  */}
										{/* <Heading color={"#000"}></Heading>*/}
									</Pressable>
								);
							}}
						>
							<Center>
								<Heading
									color='#E3E3E3'
									py='5px'
									px='5px'
									marginY={10}
									paddingRight={'2px'}
									marginBottom={'12'}
									fontSize={'14px'}
									fontWeight={'500'}
									fontFamily={'poppins'}
									paddingLeft={'8%'}
									paddingBottom={'30px'}
								>
									{' '}
									Main Menu{' '}
								</Heading>
							</Center>

							<Box
								flexdirection='row'
								backgroundColor='#000'
								_hover={{
									bg: '#E3E3E3',
								}}
							>
								{' '}
								{items.map((item, index) => (
									<SidebarItem key={index} item={item} />
								))}
							</Box>

							<Center>
								<Heading
									color='#E3E3E3'
									py='5px'
									paddingRight={'2px'}
									px='5px'
									marginY={5}
									fontWeight={'semibold'}
									fontSize={'16px'}
									fontFamily={'poppins'}
									paddingLeft={'8%'}
									paddingBottom={'30px'}
								>
									Preferences
								</Heading>
							</Center>

							<Box>
								{prefItems.map((item, index) => (
									<SidebarItem key={index} item={item} />
								))}{' '}
							</Box>
							{/* <VStack
                // position={"relative"}
                bottom={1
                 <Box
            bottom={1}
            >}
            > */}
							<Box
								// position={"static"}
								paddingBottom={'200px'}
							>
								<SocialIconTray
									// position={"bottom"}

									// textAlign="center"
									instagramUrl='https://www.instagram.com'
									twitterUrl='https://www.twitter.com'
									discordUrl='https://www.discord.com'
									youtubeUrl='https://www.youtube.com'
								/>
							</Box>

							{/* </VStack> */}
						</Menu>
					</Box>
				)}
				<Center>
					<Box bottom={0} paddingBottom={0} justifySelf={'flex-end'}>
						{/* <Text
							fontSize={{
								xl: '12px',
								lg: '10px',
								md: '8px',
								base: '14px',
							}}
							color='#ffff'
						>
							@ 2022xz{' '}
							<Text href='https://unitedmarket.com' textDecoration='none'>
								United Market
							</Text>
						</Text> */}
					</Box>
				</Center>
			</VStack>
			{/* </Hidden> */}
		</>
	);
};

export default SideBar;
