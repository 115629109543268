import { Box, HStack, VStack, Button, Hidden, Text } from 'native-base';
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './../Layout';
import { useQuery } from '@apollo/client';

const InterestedInPublishing = (props) => {
	const { w, h, borderadius } = props;
	const { selectedPublish, setSelectedPublish } = useContext(AuthContext);

	const handlePress = (e) => {
		if (e.currentTarget.innerText === 'True') {
			setSelectedPublish(true);
		} else {
			setSelectedPublish(false);
		}
	};

	return (
		<Box mb={{ md: 10, base: 5 }}>
			{/* <Hidden from="base" till="lg"> */}
			<>
				{/* <Hidden from='base' till='sm'> */}
				<VStack space={10}>
					<HStack
						space={{ xl: '30px', md: '30px', base: '30px' }}
						flexGrow={1}
						flexWrap={'wrap'}
						justifyContent={{
							lg: 'center',
							md: 'space-between',
							base: 'center',
						}}
					>
						<div className='btn'>
							<Button
								nativeID={'true'}
								mb={{ md: 0, base: 5 }}
								onPress={handlePress}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								_text={{
									color: selectedPublish === true ? '#E3E3E3' : '#AFAFAF',
									textTransform: 'capitalize',
									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.9rem',
										base: '0.75rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedPublish === true ? '#2EBB55' : '#1C1B1B'
								}
								borderColor={'#AFAFAF'}
								borderRadius={borderadius}
								// borderWidth="1"
								w={w}
								h={h}
							>
								{'True'}
							</Button>
						</div>
						<div className='btn'>
							<Button
								nativeID={'false'}
								mb={{ md: 0, base: 5 }}
								onPress={handlePress}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								_text={{
									color: selectedPublish === false ? '#E3E3E3' : '#AFAFAF',
									textTransform: 'capitalize',
									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.9rem',
										base: '0.75rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedPublish === false ? '#2EBB55' : '#1C1B1B'
								}
								borderColor={'#AFAFAF'}
								borderRadius={borderadius}
								// borderWidth="1"
								w={w}
								h={h}
							>
								{'False'}
							</Button>
						</div>
					</HStack>

					<Hidden>
						<HStack></HStack>
					</Hidden>
				</VStack>
				{/* </Hidden> */}
				{/* <Hidden from='sm'>
						<div className='accounttypesSignup'>
							<VStack space={5}>
								<HStack
									space={'10px'}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={'space-between'}
								>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[2]._id
													: data?.getHowDoYouKnows[0]._id
											}
											onPress={(e) => handlePress(e)}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[2]._id
														? '#fff'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[2]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											_
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[0]}
										</Button>
									</div>

									<div className='button'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[1]._id
													: data?.getHowDoYouKnows[1]._id
											}
											onPress={handlePress}
											px={{ base: 1 }}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[1]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[1]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											w={w}
											h={h}
										>
											{accounts[1]}
										</Button>
									</div>
								</HStack>
								<HStack
									space={'10px'}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={'space-between'}
								>
									<div className='button btn'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[3]._id
													: data?.getHowDoYouKnows[3]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[3]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											_text={{
												color:
													selectedroleid === data?.getRoles[3]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[2]}
										</Button>
									</div>
									<div className='button'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[4]._id
													: data?.getHowDoYouKnows[4]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[4]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[4]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											h={h}
											w={w}
										>
											{accounts[3]}
										</Button>
									</div>
								</HStack>
								<HStack justifyContent={'center'}>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[0]._id
													: data?.getHowDoYouKnows[2]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[0]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[0]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[4]}
										</Button>
									</div>
								</HStack>
							</VStack>
						</div>
					</Hidden> */}
			</>
		</Box>
	);
};

export default InterestedInPublishing;
