import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { PAYMENTMUTAION } from './Mutation';
import { AuthContext } from '../../Auth/Layout';
import { Notification } from '../../NotifyMsg/Notification';

function usePaymentMutation() {
	const { activeStep, setActiveStep, setIsDisableBtn, setProgressbarValue } =
		useContext(AuthContext);
	const [createCheckoutSession] = useMutation(PAYMENTMUTAION, {
		onCompleted(data) {
			if (data?.createCheckoutSession?.success) {
				//console.log('url', data.createCheckoutSession.url);
				// Notification("success", data.createCheckoutSession.message);
				window.location.href = data.createCheckoutSession.url;
				setIsDisableBtn(false);
			} else {
				console.log('error in payment mutation', data);
				Notification('error', 'Something went worng');
				// setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
				setActiveStep(activeStep);
				setIsDisableBtn(false);
			}
		},
	});

	return { createCheckoutSession };
}

export default usePaymentMutation;
