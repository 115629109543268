import { VStack, Text, HStack, Input, Button, Modal } from 'native-base';
import React, { useEffect, useRef } from 'react';
import Rating from '@mui/material/Rating';
import { useState } from 'react';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import moment from 'moment';
import { toast } from 'react-toastify';

const BasicFeedBackModal = (props) => {
	const { showBasicFeedback, closeBasicFeedback, setHighLevelFeedback } = props;
	console.log('setHighLevelFeedback.......', setHighLevelFeedback);
	const [showHighLevelFeedback, setShowHighLevelFeedback] = useState(false);
	const [feedbackText, setFeedbackText] = useState('');
	const [starRating, setRating] = useState(null);
	const [errorMsg, setErrorMsg] = useState('');
	const sendFeedback = () => {
		console.log('text input', feedbackText);
		if (!feedbackText || feedbackText?.trim()?.length < 1) {
			setErrorMsg('Feedback message is required');
		} else {
			var feedbackPostHeader = new Headers();
			feedbackPostHeader.append('Authorization', `${props.header}`);
			feedbackPostHeader.append('Content-Type', 'application/json');

			var raw = JSON.stringify({
				review: feedbackText,
				stars: starRating,
				startDuration: '00:00',
				endDuration: '00:29',
			});

			var postFeedback = {
				method: 'POST',
				headers: feedbackPostHeader,
				body: raw,
				redirect: 'follow',
			};

			fetch(
				`${process.env.REACT_APP_API_URL}/file/createfeedback/`,
				postFeedback
			)
				.then((response) => response.text())
				.then((result) => {
					console.log(result);
					toast.success('Feedback submitted successfully');
				})
				.catch((error) => {
					console.log('error', error);
					toast.error('Something went worng. Feedback couldnt be submitted');
				});

			//setBasicFeedback(false);
			setFeedbackText('');
		}
	};
	const handleClose = () => {
		setErrorMsg('');
		setRating(null);
		closeBasicFeedback();
	};
	return (
		<>
			<Modal
				isOpen={showBasicFeedback}
				onClose={handleClose}
				w={'40%'}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.Content backgroundColor={'#1C1B1B'} py={10}>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						mx={5}
					/>

					<Modal.Header
						fontSize={'lg'}
						backgroundColor={'#1C1B1B'}
						borderBottomWidth={0}
					>
						<Text
							fontSize={{ lg: '3xl', md: '2xl', base: 'xl' }}
							color='white'
							alignSelf={'center'}
						>
							Send Feedback
						</Text>
					</Modal.Header>

					<Modal.Body backgroundColor={'#1C1B1B'}>
						<VStack px='1' py='2'>
							<HStack
								w={'90%'}
								justifyContent={'space-between'}
								alignSelf={'center'}
							>
								<Text
									fontSize={{ xl: '2xl', lg: '2xl', sm: 'xl', base: 'lg' }}
									color={'#afafaf'}
									alignSelf={'center'}
								>
									Basic Feedback
								</Text>
								<Rating
									name='simple-controlled'
									value={starRating}
									emptyIcon={
										<StarRoundedIcon
											xs={{ color: '#e3e3e330', fontSize: '35' }}
										/>
									}
									onChange={(event, newValue) => {
										setRating(newValue);
									}}
								/>
								{/* <HStack>
                  {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;

                    return (
                      <label>
                        <input
                          type="radio"
                          style={{ display: "none" }}
                          name="rating"
                          value={ratingValue}
                          onClick={() => setRating(ratingValue)}
                        />
                        <GoStar
                          size={25}
                          style={{ cursor: "pointer" }}
                          color={
                            ratingValue <= (starHover || starRating)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    );
                  })}
                </HStack> */}
							</HStack>
						</VStack>
						<VStack w={'90%'} px='1' py='30' alignSelf={'center'}>
							<Input
								//  py={10}
								// onChange={getFeedbackText}
								value={feedbackText}
								onChange={(e) => setFeedbackText(e.target.value)}
								setFeedbackText
								multiline={true}
								numberOfLines={5}
								backgroundColor={'transparent'}
								borderColor='#afafaf'
								borderWidth={2}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '2',
								}}
								fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
								color={'#afafaf'}

								//  overflowY={"scroll"}
							></Input>
							<Text
								fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
								color={'#FF0000'}
								fontFamily={'Poppins'}
								py={3}
								alignSelf={'left'}
							>
								{errorMsg}
							</Text>
							<Text
								py={5}
								fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
								color={'#afafaf'}
								alignSelf={'center'}
							>
								OR
							</Text>
						</VStack>
					</Modal.Body>

					<Modal.Footer backgroundColor={'#1C1B1B'} borderTopWidth={0}>
						<Button.Group justifyContent={'space-around'} width={'100%'}>
							<Button
								size={'sm'}
								bg='#1c1b1b'
								borderStyle={'solid'}
								borderWidth={'1'}
								borderColor={'#2EBA55'}
								w={'40%'}
								rounded={'md'}
								alignSelf={'center'}
								_text={{
									color: '#2EBA55',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
								}}
								_hover={{
									bg: '#2EBA55',
									_text: {
										color: '#fff',
									},
								}}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: 'transparent' }}
								onPress={() => {
									// setBasicFeedback(false);
									setShowHighLevelFeedback(true);
									setHighLevelFeedback(true);
								}}
							>
								High Level Feedback
							</Button>
							{/* <Button onPress={() => { sendFeedback(savefileId); setBasicFeedback(false); }}
         size="lg"
         w={"20%"}
         bg={"#2EBA55"} 
         rounded={"md"}
         _hover={{ bg:  "#1C1B1B"}}
         _focused={{ bg:  "#2EBA55"}}
         _pressed={{ bg:  "#1C1B1B"}}
         alignSelf={"center"}
        
        >
       <Text fontSize={{ lg: "32px", lg: "24px", md: "16px", sm: "12px", base: "18px" }} color="white" >
          Send
        </Text>
        </Button> */}

							<Button
								size={'sm'}
								bg='#2EBA55'
								borderWidth={1}
								borderColor='#2EBA55'
								_text={{
									color: '#fff',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
								}}
								_hover={{
									bg: 'transparent',
									_text: {
										color: '#2EBA55',
									},
								}}
								onPress={() => {
									sendFeedback();
									// setBasicFeedback(false);
								}}
								w={'20%'}
								rounded={'md'}
								alignSelf={'center'}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: '#2EBA55' }}
							>
								Send
							</Button>
						</Button.Group>
					</Modal.Footer>
				</Modal.Content>
			</Modal>
		</>
	);
};
export default BasicFeedBackModal;
