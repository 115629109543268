import React from "react";
import { CSVLink } from "react-csv";
function ExportCsv({ data, headers, color, getdata }) {
  return (
    <CSVLink
      onClick={getdata}
      filename={"data"}
      asyncOnClick={true}
      style={{
        color: color,
        textDecoration: "none",
      
      }}
      data={data}
      headers={headers}
    >
      Export CSV
    </CSVLink>
  );
}

export default ExportCsv;
