import { useContext, useEffect } from 'react';
import { VStack, Box, Button, HStack, Hidden } from 'native-base';
import { AuthContext } from '../Layout';
function Genre(props) {
	const { selectedGenre, setselectedGenre, genre, setGenre, setothersGenre } =
		useContext(AuthContext);
	const { w, h, flag, borderadius } = props;
	const genres = [
		'Rap/Hip-Hop',
		'R&B',
		'Alternative',
		'Indie',
		'Country',
		'Pop',
		'Other',
	];
	const handlePress = (e) => {
		setGenre(e.currentTarget.innerText);
		// e.currentTarget.innerText === 'Other'
		// 	? setothersGenre(true)
		// 	: setothersGenre(false);
		setselectedGenre(e.target.id);
	};

	return (
		<Box mb={{ xl: 5, lg: 5, md: 5, base: 5 }}>
			<Hidden from='base' till='sm'>
				<VStack mt={5} space={8}>
					<VStack space={5}>
						<HStack
							space={{ lg: '40px', md: '30px', base: '30px' }}
							flexGrow={1}
							flexWrap={'wrap'}
							justifyContent={{
								lg: 'center',
								md: 'space-between',
								base: 'center',
							}}
						>
							<Button
								nativeID={`${genres[0]}0`}
								onPress={(e) => handlePress(e)}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedGenre === `${genres[0]}0` ? '#2EBB55' : '#1C1B1B'
								}
								_text={{
									color: selectedGenre === `${genres[0]}0` ? '#fff' : '#AFAFAF',
									textTransform: 'capitalize',

									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.75rem',
										base: '1rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								borderColor={'#AFAFAF'}
								borderRadius={5}
								w={w}
								h={h}
							>
								{genres[0]}
							</Button>
							<Button
								nativeID={`${genres[1]}1`}
								onPress={(e) => handlePress(e)}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedGenre === `${genres[1]}1` ? '#2EBB55' : '#1C1B1B'
								}
								_text={{
									color: selectedGenre === `${genres[1]}1` ? '#fff' : '#AFAFAF',
									textTransform: 'capitalize',

									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.75rem',
										base: '1rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								borderColor={'#AFAFAF'}
								borderRadius={5}
								w={w}
								h={h}
							>
								{genres[1]}
							</Button>
							<Button
								nativeID={`${genres[2]}2`}
								onPress={(e) => handlePress(e)}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedGenre === `${genres[2]}2` ? '#2EBB55' : '#1C1B1B'
								}
								_text={{
									color: selectedGenre === `${genres[2]}2` ? '#fff' : '#AFAFAF',
									textTransform: 'capitalize',

									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.75rem',
										base: '1rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								borderColor={'#AFAFAF'}
								borderRadius={5}
								w={w}
								h={h}
							>
								{genres[2]}
							</Button>
						</HStack>
					</VStack>
					<VStack space={5}>
						<HStack
							space={{ lg: '40px', md: '30px', base: '30px' }}
							justifyContent={{
								lg: 'center',
								md: 'space-between',
								base: 'center',
							}}
						>
							<Button
								nativeID={`${genres[3]}3`}
								onPress={(e) => handlePress(e)}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedGenre === `${genres[3]}3` ? '#2EBB55' : '#1C1B1B'
								}
								_text={{
									color: selectedGenre === `${genres[3]}3` ? '#fff' : '#AFAFAF',
									textTransform: 'capitalize',

									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.75rem',
										base: '1rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								borderColor={'#AFAFAF'}
								borderRadius={5}
								w={w}
								h={h}
							>
								{genres[3]}
							</Button>
							<Button
								nativeID={`${genres[4]}4`}
								onPress={(e) => handlePress(e)}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedGenre === `${genres[4]}4` ? '#2EBB55' : '#1C1B1B'
								}
								_text={{
									color: selectedGenre === `${genres[4]}4` ? '#fff' : '#AFAFAF',
									textTransform: 'capitalize',

									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.75rem',
										base: '1rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								borderColor={'#AFAFAF'}
								borderRadius={5}
								w={w}
								h={h}
							>
								{genres[4]}
							</Button>
							<Button
								nativeID={`${genres[5]}5`}
								onPress={(e) => handlePress(e)}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedGenre === `${genres[5]}5` ? '#2EBB55' : '#1C1B1B'
								}
								_text={{
									color: selectedGenre === `${genres[5]}5` ? '#fff' : '#AFAFAF',
									textTransform: 'capitalize',

									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.75rem',
										base: '1rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								borderColor={'#AFAFAF'}
								borderRadius={5}
								w={w}
								h={h}
							>
								{genres[5]}
							</Button>
						</HStack>
					</VStack>

					<VStack space={5}>
						<HStack
							space={{ lg: '40px', md: '30px', base: '30px' }}
							justifyContent={{
								lg: 'center',
								md: 'left',
								base: 'center',
							}}
						>
							<Button
								nativeID={`${genres[6]}6`}
								onPress={(e) => handlePress(e)}
								_focus={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								backgroundColor={
									selectedGenre === `${genres[6]}6` ? '#2EBB55' : '#1C1B1B'
								}
								_text={{
									color: selectedGenre === `${genres[6]}6` ? '#fff' : '#AFAFAF',
									textTransform: 'capitalize',
									fontFamily: 'poppins',
									textAlign: 'center',
									fontSize: {
										xl: '18px',
										md: '1rem',
										sm: '0.75rem',
										base: '1rem',
									},
								}}
								_hover={{
									backgroundColor: '#2EBB55',
									_text: {
										color: '#E3E3E3',
									},
								}}
								borderColor={'#AFAFAF'}
								borderRadius={5}
								w={w}
								h={h}
							>
								{genres[6]}
							</Button>
							<Button backgroundColor={'transparent'} w={w} h={h}></Button>
							<Button backgroundColor={'transparent'} w={w} h={h}></Button>
						</HStack>
					</VStack>
				</VStack>
			</Hidden>
			<Hidden from='sm'>
				<div className='accounttypesSignup'>
					<VStack space={5}>
						<HStack
							space={'10px'}
							flexGrow={1}
							flexWrap={'wrap'}
							justifyContent={'space-between'}
						>
							<div className='button'>
								<Button
									nativeID={`${genres[0]}0`}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedGenre === `${genres[0]}0` ? '#2EBB55' : '#1C1B1B'
									}
									_text={{
										color:
											selectedGenre === `${genres[0]}0` ? '#fff' : '#AFAFAF',
										textTransform: 'capitalize',

										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.75rem',
											base: '1rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={5}
									w={w}
									h={h}
								>
									{genres[0]}
								</Button>
							</div>
							<div className='button'>
								<Button
									nativeID={`${genres[1]}1`}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedGenre === `${genres[1]}1` ? '#2EBB55' : '#1C1B1B'
									}
									_text={{
										color:
											selectedGenre === `${genres[1]}1` ? '#fff' : '#AFAFAF',
										textTransform: 'capitalize',

										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.75rem',
											base: '1rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={5}
									w={w}
									h={h}
								>
									{genres[1]}
								</Button>
							</div>
						</HStack>
						<HStack
							space={'10px'}
							flexGrow={1}
							flexWrap={'wrap'}
							justifyContent={'space-between'}
						>
							<div className='button btn'>
								{' '}
								<Button
									nativeID={`${genres[2]}2`}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedGenre === `${genres[2]}2` ? '#2EBB55' : '#1C1B1B'
									}
									_text={{
										color:
											selectedGenre === `${genres[2]}2` ? '#fff' : '#AFAFAF',
										textTransform: 'capitalize',

										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.75rem',
											base: '1rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={5}
									w={w}
									h={h}
								>
									{genres[2]}
								</Button>
							</div>
							<div className='button'>
								{' '}
								<Button
									nativeID={`${genres[3]}3`}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedGenre === `${genres[3]}3` ? '#2EBB55' : '#1C1B1B'
									}
									_text={{
										color:
											selectedGenre === `${genres[3]}3` ? '#fff' : '#AFAFAF',
										textTransform: 'capitalize',

										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.75rem',
											base: '1rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={5}
									w={w}
									h={h}
								>
									{genres[3]}
								</Button>
							</div>
						</HStack>
						<HStack
							space={'10px'}
							flexGrow={1}
							flexWrap={'wrap'}
							justifyContent={'space-between'}
						>
							<div className='button btn'>
								{' '}
								<Button
									nativeID={`${genres[4]}4`}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedGenre === `${genres[4]}4` ? '#2EBB55' : '#1C1B1B'
									}
									_text={{
										color:
											selectedGenre === `${genres[4]}4` ? '#fff' : '#AFAFAF',
										textTransform: 'capitalize',

										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.75rem',
											base: '1rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={5}
									w={w}
									h={h}
								>
									{genres[4]}
								</Button>
							</div>
							<div className='button'>
								{' '}
								<Button
									nativeID={`${genres[5]}5`}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedGenre === `${genres[5]}5` ? '#2EBB55' : '#1C1B1B'
									}
									_text={{
										color:
											selectedGenre === `${genres[5]}5` ? '#fff' : '#AFAFAF',
										textTransform: 'capitalize',

										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.75rem',
											base: '1rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={5}
									w={w}
									h={h}
								>
									{genres[5]}
								</Button>
							</div>
						</HStack>
						<HStack justifyContent={'center'}>
							<div className='button'>
								<Button
									nativeID={`${genres[6]}6`}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedGenre === `${genres[6]}6` ? '#2EBB55' : '#1C1B1B'
									}
									_text={{
										color:
											selectedGenre === `${genres[6]}6` ? '#fff' : '#AFAFAF',
										textTransform: 'capitalize',

										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.75rem',
											base: '1rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={5}
									w={w}
									h={h}
								>
									{genres[6]}
								</Button>
							</div>
						</HStack>
					</VStack>
				</div>
			</Hidden>
		</Box>
	);
}

export default Genre;
