/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
  Box,
  Button,
  Checkbox,
  CloseIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Menu,
  Modal,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import { useNavigate } from 'react-router';
import { fontSize } from '@mui/system';
import { SearchFieldComponent } from '../SearchField';

export function TagsPopup(props) {
  const {
    openModal,
    closeModal,
    value,
    handleChangeTag,
    data,
    selectedTags,
    addTag,
    removeTag,
  } = props;

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      w={{ xl: '50%', md: '50%', base: '90%' }}
      size={'full'}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={20}
    >
      <Box w={'90%'} bg={'#141313'} rounded={'lg'}>
        <HStack
          alignSelf={'center'}
          justifyContent={'space-between'}
          my={5}
          w={'90%'}
        >
          <Heading
            w={'100%'}
            color={'#e3e3e3'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
            textAlign={'center'}
            fontSize={{ lg: '2xl', base: 'lg' }}
          >
            Tags
          </Heading>

          <Button
            variant={'ghost'}
            px={5}
            // py={3}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeModal}
            alignItems={'center'}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>
        <Box w={'90%'} mt={5} alignSelf={'center'}>
          <HStack
            backgroundColor={'#1c1b1b'}
            w={'100%'}
            borderRadius={10}
            // py={{ md: 2, base: 1 }}
            _focus={{ bg: 'transparent', borderWidth: 0 }}
          >
            <Input
              w={'100%'}
              pl={1}
              py={4}
              color={'#AFAFAF'}
              fontSize={{ xl: '20', lg: '18', md: '20', base: '16' }}
              borderWidth={0}
              rounded={'lg'}
              borderColor={'transparent'}
              bg={'1C1B1B'}
              placeholder={'Search tags in your library'}
              value={value}
              onChangeText={(e) => {
                handleChangeTag(e);
              }}
              _important={{
                bg: 'transparent',
                borderWidth: 0.5,
                borderColor: '#afafaf',
                _focus: { borderColor: '#2eba55', shadow: 0 },
              }}
              focusOutlineColor={'#2eba55'}
              InputLeftElement={
                <Image
                  source={require('../../assets/Icons/searchIcon.png')}
                  alt='Search Field'
                  color='#ffff'
                  size={{ xl: 5, lg: 5, md: 5.5, base: 4 }}
                  mx={3}
                  alignItems={'center'}
                />
              }
            />
          </HStack>
        </Box>
        <HStack
          flexGrow={1}
          alignSelf={'center'}
          flexWrap={'wrap'}
          width={'90%'}
          my={8}
        >
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <Button
                key={'tag_key_' + index}
                size={'sm'}
                bg={selectedTags.includes(item.tag) ? '#2EBA55' : '#1c1b1b'}
                rounded={'md'}
                alignSelf={'center'}
                _hover={{
                  bg: 'transparent',
                }}
                _focused={{ bg: 'transparent' }}
                _pressed={{ bg: 'transparent' }}
                onPress={() => {
                  selectedTags.includes(item.tag)
                    ? removeTag(item.tag)
                    : addTag(item.tag);
                }}
                px={1}
                mx={2}
                my={2}
              >
                <HStack>
                  <Heading
                    px={1}
                    color={selectedTags.includes(item.tag) ? '#fff' : '#AFAFAF'}
                    fontWeight={'normal'}
                    fontFamily={'Poppins'}
                    alignSelf={'center'}
                    fontSize={{ lg: 'lg', base: 'sm' }}
                  >
                    {item.tag}
                  </Heading>
                  <Heading
                    px={1.5}
                    bg={'#AFAFAF'}
                    mx={1}
                    rounded={'sm'}
                    color={selectedTags.includes(item.tag) ? '#fff' : '#1c1b1b'}
                    fontWeight={'normal'}
                    fontFamily={'Poppins'}
                    alignSelf={'center'}
                    fontSize={{ lg: 'md', base: 'xs' }}
                  >
                    {item.count}
                  </Heading>
                </HStack>
              </Button>
            ))
          ) : (
            <Heading
              w={'100%'}
              color={'#afafaf'}
              fontWeight={'normal'}
              fontFamily={'Poppins'}
              alignSelf={'center'}
              fontSize={{ xl: '18px', lg: '16px', base: 'xs' }}
              justifyContent={'center'}
              textAlign={'center'}
            >
              No tags available
            </Heading>
          )}
        </HStack>
      </Box>
    </Modal>
  );
}
