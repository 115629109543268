/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  CheckIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Text,
} from 'native-base';
import profile from '../../assets/Growth/profile.png';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function MusicProducerForm() {
  const [service, setService] = useState('');
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Box w={'100%'}>
      <Box w={'90%'} alignSelf={'center'} my={5}>
        <Heading
          my={5}
          fontSize={{
            xl: '40px',
            lg: '36px',
            md: '30px',
            base: '20px',
          }}
          color={'#fff'}
          textTransform={'capitalize'}
          fontFamily={'poppins'}
        >
          Music Producer
        </Heading>
        <Box my={10}>
          <HStack mb={{ xl: 5, base: 0 }} flexGrow={1} flexWrap={'wrap'}>
            <Box w={{ xl: '35%', base: '100%' }} alignSelf={'center'}>
              <Image source={profile} size={'2xl'} alignSelf={'center'} />
            </Box>
            <Box w={{ xl: '65%', base: '100%' }}>
              <InputField title={'Username'} />

              <Heading
                my={5}
                textTransform={'capitalize'}
                fontSize={{
                  xl: '24px',
                  lg: '20px',
                  md: '18px',
                  base: '16px',
                }}
                fontWeight={'medium'}
                textAlign={'left'}
                color={'#afafaf'}
              >
                Job Field
              </Heading>
              <ThemeProvider theme={darkTheme}>
                <FormControl
                  sx={{ m: [0, 0, 0.5], p: 0, mainWidth: [20, 120, 200] }}
                  className='country_filter'
                >
                  <Select
                    labelId='demo-simple-select-helper-label'
                    value={value}
                    onChange={handleChange}
                    style={{
                      color: '#FFF',
                      border: '#AFAFAF',
                      fontSize: 16,
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '300px',
                          backgroundColor: '#1c1b1b',
                          color: 'afafaf',
                        },
                      },
                    }}
                    sx={{
                      '& .MuiSelect-outlined': {
                        padding: '15px !important',
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        '&:focus': {
                          background: '#2EBB55',
                          color: 'white',
                        },
                        '&:hover': {
                          background: '#AFAFAF',
                          color: 'afafaf',
                        },
                        fontSize: 14,
                      }}
                      value='1'
                    >
                      Music Producer
                    </MenuItem>
                    <MenuItem
                      sx={{
                        '&:focus': {
                          background: '#2EBB55',
                          color: 'white',
                        },
                        '&:hover': {
                          background: '#AFAFAF',
                          color: 'afafaf',
                        },
                        fontSize: 14,
                      }}
                      value='2'
                    >
                      Artists
                    </MenuItem>
                    <MenuItem
                      sx={{
                        '&:focus': {
                          background: '#2EBB55',
                          color: 'white',
                        },
                        '&:hover': {
                          background: '#AFAFAF',
                          color: 'white',
                        },
                        fontSize: 14,
                      }}
                      value='3'
                    >
                      label
                    </MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Box>
          </HStack>
          <InputField title={'About Me'} />
          <HStack
            flexGrow={1}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Box w={{ xl: '48%', base: '100%' }}>
              <InputField title={'Location'} />
            </Box>
            <Box w={{ xl: '48%', base: '100%' }}>
              <InputField title={'Accolades'} />
            </Box>
            <Box w={{ xl: '48%', base: '100%' }}>
              <InputField title={'Genre'} />
            </Box>
            <Box w={{ xl: '48%', base: '100%' }}>
              <InputField title={"Who I've worked with"} />
            </Box>
            <Box w={{ xl: '48%', base: '100%' }}>
              <InputField title={'Beats'} />
            </Box>
            <Box w={{ xl: '48%', base: '100%' }}>
              <InputField title={'Kit'} />
            </Box>
            <Box w={{ xl: '48%', base: '100%' }}>
              <InputField title={'Are you signed to a label?'} />
            </Box>
            <Button
              bg={'#2EBB55'}
              borderWidth={1}
              borderColor={'#2EBB55'}
              _hover={{
                bg: 'transparent',
                _text: { color: '#2EBB55' },
              }}
              _pressed={{
                bg: '#2EBB55',
                _text: { color: '#e3e3e3' },
              }}
              _text={{
                color: '#e3e3e3',
                fontSize: { xl: '20px', lg: '18px', md: '16px', base: '14px' },
                fontFamily: 'Poppins',
                textAlign: 'center',
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              w={{ lg: '15%', base: '30%' }}
              py={{ lg: 3, base: 2 }}
              my={{ lg: 0, base: 5 }}
              alignSelf={'flex-end'}
              onPress={() => {}}
            >
              Create
            </Button>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
  function InputField(props) {
    const { title } = props;
    return (
      <Box w={'100%'}>
        <Heading
          my={5}
          textTransform={'capitalize'}
          fontSize={{
            xl: '24px',
            lg: '20px',
            md: '18px',
            base: '16px',
          }}
          fontWeight={'medium'}
          textAlign={'left'}
          color={'#AFAFAF'}
        >
          {title}
        </Heading>
        <Input
          py={'15px'}
          my={2}
          _important={{
            backgroundColor: 'transparent',
            borderColor: '#afafaf',
            borderWidth: '1',
            _focus: { borderColor: '#afafaf', shadow: 0 },
          }}
          focusOutlineColor={'#afafaf'}
          fontSize={{ lg: '16px', sm: '12px' }}
          borderWidth={1}
          borderColor={'#AFAFAF'}
          color={'#fff'}
        />
      </Box>
    );
  }
}

export default MusicProducerForm;
