/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	CloseIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Menu,
	Modal,
	Pressable,
	ScrollView,
	Text,
	VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useNavigate } from 'react-router';
import { fontSize } from '@mui/system';

export function SentPopup(props) {
	const { openModal, closeModal } = props;

	return (
		<Modal
			isOpen={openModal}
			onClose={closeModal}
			w={{ xl: '35%', md: '35%', base: '90%' }}
			size={'full'}
			alignSelf={'center'}
			justifyContent={'flex-start'}
			mt={20}
		>
			<Modal.Content backgroundColor={'#141313'}>
				<Modal.CloseButton
					_hover={{ bg: '#141313' }}
					_focused={{ bg: '#2EBA55' }}
					_pressed={{ bg: 'transparent' }}
					mx={5}
				/>

				<VStack
					py={10}
					mb={10}
					backgroundColor={'#141313'}
					w={'90%'}
					alignSelf={'center'}
				>
					<CheckBoxIcon
						sx={{
							color: '#2ebb55',
							fontSize: 65,
							alignSelf: 'center',
							borderRadius: '30px',
						}}
					/>

					<Text
						fontSize={{ lg: '24px', md: '16px', sm: '16px' }}
						fontStyle={'bold'}
						color={'#e3e3e3'}
						py='5'
						alignSelf={'center'}
					>
						FILE SENT
					</Text>

					<Text
						w={'80%'}
						fontSize={{ lg: '20px', md: '16px', sm: '16px' }}
						fontStyle={'bold'}
						color={'#afafaf'}
						py='2'
						alignSelf={'center'}
						textAlign={'center'}
					>
						Your file has been successfully sent!
					</Text>
				</VStack>
			</Modal.Content>
		</Modal>
	);
}
