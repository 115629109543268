import { Box, Card, Heading, HStack, Image, Text, VStack } from "native-base";
import React, { useState } from "react";
import FeedProfile from "./FeedProfile";
import SocialIcons from "./SocialIcons";
import ButtonComponent from "../ButtonComponent";
import profile from "../../assets/Feeds/profile.png";
import { SubmitModal } from "./Modals/SubmitModal";
function Feed(props) {
  const {
    profile,
    name,
    opportunity,
    date,
    lable,
    ads,
    friend,
    title,
    coverPhoto,
  } = props;
  const [submitModal, setSubmitModal] = useState(false);
  return (
    <Box my={5} p={5} bg="#1C1B1B" rounded={"lg"}>
      <FeedProfile
        profile={profile}
        name={name}
        opportunity={opportunity}
        date={date}
        lable={lable}
        ads={ads}
        friend={friend}
      />
      <Box mt={3} my={5}>
        <HStack flexWrap={"wrap"} flexGrow={1} w={"100%"} alignSelf={"center"}>
          <Box w={{ xl: "25%", md: "30%", base: "90%" }} alignSelf={"center"}>
            <Image
              resizeMode="cover"
              source={coverPhoto}
              h={{ xl: "15rem", md: "11rem", base: "9rem" }}
              rounded={"lg"}
              w={"100%"}
            />
          </Box>
          {/* card content */}
          <Box
            w={{ xl: "75%", md: "70%", base: "90%" }}
            px={{ xl: 5, md: 5, base: 0 }}
            py={3}
            pb={0}
          >
            <Heading
              color={"#E3E3E3"}
              fontWeight={"semibold"}
              fontSize={{ xl: "3xl", base: "xl" }}
              textTransform={"capitalize"}
              pb={1.5}
            >
              {title}
            </Heading>
            <Text my={1.5} color={"#2EBB55"}>
              Beat <Text color={"#AFAFAF"}> by </Text>
              <Text color={"#2EBB55"}>Kibriya Zameer</Text>{" "}
              <Text color={"#AFAFAF"}> @rockstar</Text>
            </Text>
            <Text my={0.5} color={"#AFAFAF"}>
              Released on Aug 20, 2022
            </Text>
            <Text my={0.5} mb={5} color={"#AFAFAF"} flexGrow={1}>
              Energetic, Soulfull, Turnup
            </Text>
            <ButtonComponent
              textProperties={{
                color: "#E3E3E3",
                fontWeight: "semibold",
                fontSize: { xl: "1.2rem", md: "1rem", base: "0.7rem" },
                fontFamily: "poppins",
                textTransform: "uppercase",
              }}
              flag={false}
              paddingXMd={5}
              paddingXSm={3}
              paddingYMd={2}
              paddingYSm={1}
              w={"40%"}
              text={"$50.00"}
              backgroundColor="#2EBB55"
              borderwidth={1}
              bordercolor={"#2EBB55"}
              onpress={() => setSubmitModal(true)}
            ></ButtonComponent>
          </Box>
        </HStack>
      </Box>
      <SocialIcons />
      <SubmitModal
        showSubmitModal={submitModal}
        closeSubmitModal={() => setSubmitModal(false)}
      />
    </Box>
  );
}

export default Feed;
