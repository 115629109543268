import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
} from "native-base";
import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import GetNotified from "../../Getnotified/GetNotified";
import HeaderComp from "../../Header/HeaderComp";
import { useLocation } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import saasproducers from "../../../assets/staticWebsite/saasproducers.png";

function Services(props) {
  const { service } = props;
  return (
    <HStack my={1}>
      <CheckCircleRoundedIcon sx={{ color: "#2EBB55", fontSize: 20 }} />
      <Heading
        pl={3}
        color={"#AFAFAF"}
        fontSize={{ xl: "xl", base: "md" }}
        fontFamily={"Poppins"}
        fontWeight={"normal"}
        textAlign={"left"}
        textTransform={"capitalize"}
      >
        {service}
      </Heading>
    </HStack>
  );
}
function SeeMoreBtn(props) {
  const { route } = props;
  return (
    <Button
      alignSelf={"flex-start"}
      variant={"ghost"}
      py={2}
      px={0}
      _hover={{ bg: "transparent" }}
      _pressed={{ bg: "transparent" }}
      _text={{
        underline: "1",
        color: "#AFAFAF",
        fontWeight: "normal",
        fontSize: { xl: "xl", base: "md" },
        textAlign: "left",
        textTransform: "uppercase",
        fontFamily: "Poppins",
      }}
    >
      See More
    </Button>
  );
}
function PlanHeader(props) {
  const { title, desc } = props;
  return (
    <>
      <Heading
        color={"#e3e3e3"}
        fontSize={{ xl: "3xl", md: "2xl", base: "xl" }}
        fontFamily={"Poppins"}
        fontWeight={"medium"}
        textAlign={"left"}
        textTransform={"uppercase"}
      >
        {title}
      </Heading>
      <Heading
        py={5}
        color={"#AFAFAF"}
        fontSize={{ xl: "xl", base: "md" }}
        fontFamily={"Poppins"}
        fontWeight={"normal"}
        textAlign={"left"}
        textTransform={"capitalize"}
      >
        {desc}
      </Heading>
    </>
  );
}

const SaasMusicProducer = (props) => {
    const {planFlag} = props;
 
  return (
    <Box w={"100%"} bg={"#000201"}>
      <HeaderComp />
      <Box my={20}>
        <HStack w={"100%"} flexGrow={1} flexWrap={"wrap"}>
          <Box w={{ xl: "50%", base: "100%" }} pl={10}>
            <Heading
              fontFamily={"Poppins"}
              py={5}
              textAlign={"left"}
              fontSize={{ xl: "6xl", lg: "5xl", md: "4xl", base: "3xl" }}
              fontWeight={"extrabold"}
              color={"#E3E3E3"}
              textTransform={"uppercase"}
            >
              BECOME A
            </Heading>
            <Heading
              fontFamily={"Poppins"}
              textAlign={"left"}
              fontSize={{ xl: "6xl", lg: "5xl", md: "4xl", base: "3xl" }}
              fontWeight={"extrabold"}
              color={"#2EBB55"}
              textTransform={"uppercase"}
            >
              HITMAKING PRODUCER
            </Heading>
            <Heading
              fontFamily={"Poppins"}
              py={5}
              textAlign={"left"}
              fontSize={{ xl: "2xl", base: "lg" }}
              fontWeight={"normal"}
              color={"#AFAFAF"}
              textTransform={"capitalize"}
            >
              Take control of your career and find your next placement
              opportunity on United Market.
            </Heading>
            <Button
              bg={"#2EBB55"}
              borderWidth={1}
              borderColor={"#2EBB55"}
              _hover={{ bg: "transparent", _text: { color: "#2EBB55" } }}
              _pressed={{ bg: "#2EBB55", _text: { color: "#e3e3e3" } }}
              _text={{
                color: "#e3e3e3",
                fontSize: { xl: "2xl", base: "lg" },
                fontFamily: "Poppins",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "medium",
              }}
              px={10}
              py={2}
              mt={20}
              my={10}
              alignSelf={"flex-start"}
            >
              INVEST IN YOURSELF
            </Button>
          </Box>
          <Box w={{ xl: "50%", base: "100%" }}>
            <Box w={"90%"} alignSelf={"flex-end"}>
              <Image
                source={saasproducers}
                w={"100%"}
                resizeMode={"contain"}
                resizeMethod={"scale"}
                h={{ xl: "60vh", lg: "60vh", md: "50vh", base: "40vh" }}
              />
            </Box>
          </Box>
        </HStack>
      </Box>

      <Box w={{ xl: "95%", base: "90%" }} alignSelf={"center"} my={10}>
        <Box
          alignSelf={"center"}
          w={{ xl: "60%", lg: "70%", base: "80%" }}
          my={10}
        >
          <Heading
            fontFamily={"Poppins"}
            textAlign={"center"}
            fontSize={{ xl: "4xl", md: "3xl", base: "2xl" }}
            fontWeight={"extrabold"}
            color={"#e3e3e3"}
            textTransform={"uppercase"}
          >
            FIND A PLAN THAT’S{" "}
            <Heading
              color={"#2EBB55"}
              fontFamily={"Poppins"}
              textAlign={"center"}
              fontSize={{ xl: "4xl", md: "3xl", base: "2xl" }}
              fontWeight={"extrabold"}
              textTransform={"uppercase"}
            >
              RIGHT FOR YOU
            </Heading>
          </Heading>
          <Heading
            fontFamily={"Poppins"}
            py={5}
            textAlign={"center"}
            fontSize={{ xl: "2xl", base: "lg" }}
            fontWeight={"normal"}
            color={"#AFAFAF"}
            textTransform={"capitalize"}
          >
            Whether you are just starting your music production career or have
            been griding and ready for your next opportunity, there is a plan
            for you.
          </Heading>
        </Box>
        <Box my={10} w={"100%"}>
          <HStack
            w={"100%"}
            flexGrow={1}
            flexWrap={"wrap"}
            justifyContent={{
              xl: "space-between",
              lg: "space-between",
              md: "space-between",
              base: "center",
            }}
            alignSelf={"center"}
          >
            <Box
              w={{ xl: "30%", md: "30%", base: "90%" }}
              bg={"#1C1B1B"}
              rounded={"lg"}
              py={10}
              my={5}
            >
              <Box w={"85%"} alignSelf={"center"}>
                <PlanHeader
                  title={"Free"}
                  desc={
                    "In Publishing And Graphic Design, Lorem Ipsum Is A Placeholder Text Commonly."
                  }
                />
                <Button
                  bg={"#2EBB55"}
                  borderWidth={1}
                  borderColor={"#2EBB55"}
                  _hover={{ bg: "transparent", _text: { color: "#2EBB55" } }}
                  _pressed={{ bg: "#2EBB55", _text: { color: "#e3e3e3" } }}
                  _text={{
                    color: "#e3e3e3",
                    fontSize: { xl: "2xl", base: "lg" },
                    fontFamily: "Poppins",
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: "medium",
                  }}
                  w={"100%"}
                  px={10}
                  py={2}
                  my={5}
                  alignSelf={"flex-start"}
                >
                  GET START
                </Button>
                <Box my={5} h={{ xl: "55vh", lg: "55vh", md: "50vh" }}>
                  <Services service={"Keep 100% of Earnings"} />
                  <Services service={"Sell Beats"} />
                  <Services service={"Sell Kits"} />
                  <Services service={"Sell Services"} />
                  <Services service={"Upload 35 Beats or Kits"} />
                  <Services service={"ConnectMe only 5 Swipes a day"} />
                  <Services service={"Create 2 Beatpacks per month"} />
                  <Services service={"ConnectMe → 5 Swipes per week"} />
                  <Services
                    service={
                      "Submit for 1 opportunity per month 1 Submission per opportunity"
                    }
                  />
                </Box>
                <SeeMoreBtn />
              </Box>
            </Box>

            <Box
              w={{ xl: "30%", md: "30%", base: "90%" }}
              bg={"#1C1B1B"}
              rounded={"lg"}
              py={10}
              my={5}
            >
              <Box w={"85%"} alignSelf={"center"}>
                <PlanHeader
                  title={"HITMAKING"}
                  desc={
                    "In Publishing And Graphic Design, Lorem Ipsum Is A Placeholder Text Commonly."
                  }
                />
                <Button
                  variant={"ghost"}
                  borderWidth={1}
                  borderColor={"#2EBB55"}
                  _hover={{ bg: "#2EBB55", _text: { color: "#e3e3e3" } }}
                  _pressed={{ bg: "transparent", _text: { color: "#e3e3e3" } }}
                  _text={{
                    color: "#2EBB55",
                    fontSize: { xl: "2xl", base: "lg" },
                    fontFamily: "Poppins",
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: "medium",
                  }}
                  w={"100%"}
                  py={2}
                  my={5}
                  alignSelf={"flex-start"}
                >
                  ACTIVE
                </Button>
                <Box my={5} h={{ xl: "55vh", lg: "55vh", md: "50vh" }}>
                  <Services service={"Keep 100% of Earnings"} />
                  <Services service={"Sell Services"} />
                  <Services service={"Sell Kits"} />
                  <Services service={"Sell Beats"} />
                  <Services
                    service={
                      "Share Loops With Top Verified Music Producers & Be The First To Know When They Are Accepting Loops"
                    }
                  />
                  <Services service={"Upload Unlimited Beats"} />
                  <Services
                    service={
                      "Submit For Unlimited Opportunities Up To 5 Submissions Per Opportunity"
                    }
                  />
                  <Services service={"Unlimited ConnectMe Swipes"} />
                </Box>
                <SeeMoreBtn />
              </Box>
            </Box>

            <Box
              w={{ xl: "30%", md: "30%", base: "90%" }}
              bg={"#1C1B1B"}
              rounded={"lg"}
              py={10}
              my={5}
            >
              <Box w={"85%"} alignSelf={"center"}>
                <PlanHeader
                  title={"ENTERPRISE"}
                  desc={
                    "In Publishing And Graphic Design, Lorem Ipsum Is A Placeholder Text Commonly."
                  }
                />
                <Button
                  bg={"#2EBB55"}
                  borderWidth={1}
                  borderColor={"#2EBB55"}
                  _hover={{ bg: "transparent", _text: { color: "#2EBB55" } }}
                  _pressed={{ bg: "#2EBB55", _text: { color: "#e3e3e3" } }}
                  _text={{
                    color: "#e3e3e3",
                    fontSize: { xl: "2xl", base: "lg" },
                    fontFamily: "Poppins",
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: "medium",
                  }}
                  w={"100%"}
                  py={2}
                  my={5}
                  alignSelf={"flex-start"}
                >
                  REQUEST DEMO
                </Button>
                <Heading
                  py={5}
                  color={"#AFAFAF"}
                  fontSize={{ xl: "xl", base: "md" }}
                  fontFamily={"Poppins"}
                  fontWeight={"normal"}
                  textAlign={"left"}
                  textTransform={"capitalize"}
                >
                  Are you a larger music production team or a Label? That is
                  great, we are excited to onboard and have you a part of our
                  community. Schedule a Demo for us to walk through the product
                  with you and let us know ideally how many users you are
                  onboarding for a proper quote.
                </Heading>
                {planFlag ? (
                  <Input
                    my={5}
                    py={3}
                    w={'100%'}
                    InputRightElement={
                        <Button
                        bg={"#2EBB55"}
                        borderWidth={1}
                        roundedRight={'md'}
                        borderColor={"#2EBB55"}
                        _hover={{ bg: "transparent", _text: { color: "#2EBB55" } }}
                        _pressed={{ bg: "#2EBB55", _text: { color: "#e3e3e3" } }}
                        _text={{
                          color: "#e3e3e3",
                          fontSize: { xl: "xl", base: "md" },
                          fontFamily: "Poppins",
                          textAlign: "center",
                          textTransform: "uppercase",
                          fontWeight: "medium",
                        }}
                        px={4}
                        py={2}
                        
                        alignSelf={"flex-start"}
                      >
                        Add
                      </Button>
                    }
                    rounded={"md"}
                    placeholder={
                      "Number Of Team Member"
                    }
                    placeholderTextColor={"#AFAFAF"}
                    fontSize={{ xl: "lg", md: "sm", base: "2xs" }}
                    color={"#e3e3e3"}
                  />
                ) : (
                <Button
                  bg={"#2EBB55"}
                  borderWidth={1}
                  borderColor={"#2EBB55"}
                  _hover={{ bg: "transparent", _text: { color: "#2EBB55" } }}
                  _pressed={{ bg: "#2EBB55", _text: { color: "#e3e3e3" } }}
                  _text={{
                    color: "#e3e3e3",
                    fontSize: { xl: "xl", base: "md" },
                    fontFamily: "Poppins",
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: "medium",
                  }}
                  w={"100%"}
                  py={2}
                  my={5}
                  alignSelf={"flex-start"}
                >
                  Successfully Added!
                </Button>)}
              </Box>
            </Box>
          </HStack>
        </Box>
      </Box>
      <GetNotified />
      <Footer />
    </Box>
  );
};

export default SaasMusicProducer;
