import React from 'react'
import Barchart from '../../Graphs/Barchart'
import { Box, VStack } from 'native-base'
import PlaysBarChart from './PlaysBarChart'
import TalentHeadingWithFilters  from '../../TalentComponentHeader/TalentHeadingWithFilters'
import _ from 'lodash'
import {configOptions} from '../../../../RestApis/AxiosInstance.js/SoundChartAPIConfig'
import axios from 'axios'
import styles from './styles.module.css';
import { Loader } from '../../../../components/Loader';

function PlaysChart(props) {
  const {filterHandler, loading} = props
  const [filters, setFilters] = React.useState({
    country: 'all_countries',
    days:'28',
    stations: 'all_stations'
  })

  const countryHandler = (e) => {
    console.log('country handler', e)
    setFilters(prevState =>({
      ...prevState,
      country: e
    }))
    // filterDebounce()
  }
  const daysHandler = (e) => {
    setFilters(prevState =>({
      ...prevState,
      days: e
    }))
  }
  const stationHandler = (e) => {
    setFilters(prevState =>({
      ...prevState,
      stations: e
    }))
  }

  const applyFilters = async () => {
    console.log('applying filters ...', filters)
    filterHandler()
  }
  // const filterDebounce = React.useCallback(_.debounce(() => getStations(), 300), [])

  // const getStations = async () => {
  //   try{
  //     const config = configOptions(
  //        'get',
  //        `https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/broadcasts?offset=0&limit=100`
  //      )
  //      const response = await axios(config);
  //      console.log('response station api: ', response)
  //    }catch(err) {

  //    }
  // }
  return (
    <Box p={5}    bg={"#1C1B1B"} py={5}   rounded={"lg"}
  >

  <TalentHeadingWithFilters
        image={""}
        title={'Airplay over time'}
        subtitle={'Radio plays over the last 28 days'}
        data={filters}
        countryHandler={countryHandler}
        daysHandler={daysHandler}
        stationHandler={stationHandler}
        handler={applyFilters}
        />
        {
  loading ?
  <div style={{display:'flex', alignItems:'center', justifyContent:'center',padding:'60px'}}>
  <Loader />
</div>
  : <>
<PlaysBarChart {...props} />
  </>
}
</Box>
  )
}

export default PlaysChart