import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	makeStyles,
	createTheme,
	ThemeProvider,
} from '@material-ui/core/styles';
const theme = createTheme({
	palette: {
		secondary: {
			main: '#2EBB55',
		},
	},
});
function CircularProgressBar(props) {
	const { progress } = props;
	return (
		<ThemeProvider theme={theme}>
			<CircularProgress
				variant='determinate'
				value={progress}
				size='5rem'
				color='secondary'
			/>
		</ThemeProvider>
	);
}
export default CircularProgressBar;
