import React from "react";
import { Box, Image, Heading, HStack, VStack,Button, Divider } from "native-base";
import Rating from "@mui/material/Rating";
import ProfileTitle from "../ProfileTitle";
import profile from "../../../assets/header/profile.png";
import "./Testimonials.css"
export const Testimonies = () => {
  const data = [
    {
      id: 1,
      image: profile,
      name: "Zuraid Zameer",
      title: "Artist",
      star: 5,
      date: "05:30 AM   AUG-24-2022",
      des: "Consistency is one of your biggest strengths. I can always count on you to treat all of your tasks with import ance while also recognizing the value of prioritizing. Keep up the good work!",
    },
    {
      id: 1,
      image: profile,
      name: "Zuraid Zameer",
      title: "Artist",
      star: 5,
      date: "05:30 AM   AUG-24-2022",
      des: "Consistency is one of your biggest strengths. I can always count on you to treat all of your tasks with import ance while also recognizing the value of prioritizing. Keep up the good work!",
    },
    {
      id: 1,
      image: profile,
      name: "Zuraid Zameer",
      title: "Artist",
      star: 5,
      date: "05:30 AM   AUG-24-2022",
      des: "Consistency is one of your biggest strengths. I can always count on you to treat all of your tasks with import ance while also recognizing the value of prioritizing. Keep up the good work!",
    },
  ];
  return (
    <Box mt={5} bg={"#000201"} w={"100%"} pl={{lg:"16px",md:0}}>
      
  
      <HStack w={'100%'} justifyContent="space-between" alignItems={"center"} >
      <ProfileTitle fontSize={{md:"2xl",base:"lg"}} text="customers reviews"/>

        <Button variant={'ghost'} _pressed={{bg: 'transparent'}} justifyContent={'flex-end'}>
            <Heading color={'#AFAFAF'} fontWeight={'normal'} textAlign={'center'}    fontSize={{lg:"lg",md:"md",base:"xs"}}>
              See More
         
            </Heading>
            <Divider/>
        </Button>
        </HStack>
        <HStack flexGrow={1} flexWrap={"wrap"} justifyContent={{md:"space-between",base:"center"}}>
          {data.map((item) => (
            <Box
              w={{ xl: "30%", lg: "30%", md: "40%", base: "100%" }}
              rounded={"lg"}
              alignSelf={"center"}
              bg={"#1C1B1B"}
              my={5}
           
            >
              <HStack mx={5} pt={5}>
                <Box>
                  <Image
                    alignSelf={"flex-start"}
                    h="80px"
                    width="60px"
                    resizeMode="contain"
                    source={require("../../../assets/header/profile.png")}
                    alt="image"
                  />
                </Box>
                <VStack mx={5}>
                  <Heading
                    fontSize={{sm:"lg",base:"sm"}}
                    fontWeight={"medium"}
                    color={"#AFAFAF"}
                    textAlign={"left"}
                    py={1}
                  >
                    {item.name}
                  </Heading>
                  <Heading
                  fontSize={{sm:"md",base:"xs"}}
                    fontWeight={"normal"}
                    color={"#AFAFAF"}
                    textAlign={"left"}
                    py={1}
                  >
                    {item.title}
                  </Heading>
                  <div className="starsratings"  py={1}>
                    <Rating  name="read-only" value={item.star} readOnly  />
                  </div >
                </VStack>
              </HStack>
              <VStack m={5}>
                <Heading
                  fontSize={{md:"lg",sm:"md",base:"sm"}}
                  fontWeight={"normal"}
                  color={"#AFAFAF"}
                  textAlign={"left"}
                  mb={5}
                >
                  {item.des}
                </Heading>

                <Heading
                  fontSize={{md:"md",base:"sm"}}
                  fontWeight={"normal"}
                  color={"#AFAFAF"}
                  textAlign={"left"}
                >
                  {item.date}
                </Heading>
              </VStack>
            </Box>
          ))}
        </HStack>
     
    </Box>
  );
};
