import React from 'react';
import {
	Box,
	Image,
	AspectRatio,
	Container,
	HStack,
	Text,
	Link,
	Heading,
	Button,
} from 'native-base';
import FooterIcons from './FooterIcons';
import LogoSVG from '../../assets/footers/footerLogoWithTextAbove.svg';

const Footer = () => {
	const imgs = ['linkedin.png', 'twitter.png', 'instagram.png', 'youtube.png'];
	const imgUrls = [
		'https://www.linkedin.com/company/unitedmarket',
		'https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw',
		'https://instagram.com/unitedmarket?utm_medium',
		'https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ?app=desktop',
	];
	return (
		<Box py={3} backgroundColor={'#141516'}>
			<Container w='100%' marginX='auto'>
				<HStack
					direction={{ md: 'row', base: 'column' }}
					w='100%'
					alignItems={{ lg: 'center', base: 'flex-start' }}
					justifyContent='space-between'
					flexWrap={'wrap'}
				>
					<Box
						w={{ '2xl': '50%', xl: '60%', lg: '60%', base: '100%' }}
						pt={{ lg: 0, base: 15 }}
					>
						<Heading
							fontFamily={'Poppins'}
							fontWeight={'medium'}
							fontSize={{ lg: '24px', base: '14px' }}
							lineHeight={{ lg: '44px', base: '21px' }}
							color={'#e3e3e3'}
							textAlign={'left'}
						>
							Apply for your membership to gain access to the next level
							technology + partnerships to expand your music career.
						</Heading>
					</Box>
					<Box
						w={{ '2xl': '50%', xl: '40%', lg: '40%', base: '100%' }}
						pt={{ lg: 5, base: 5 }}
					>
						<Link
							href='/signup'
							justifyContent={{ lg: 'flex-end', base: 'left' }}
						>
							<Button
								alignSelf={{ lg: 'flex-end', base: 'flex-start' }}
								_text={{
									fontFamily: 'Poppins',
									fontWeight: 'medium',
									fontSize: { xl: '25px', lg: '25px', base: '16px' },
									color: '#fff',
								}}
								borderWidth={1}
								borderColor={'#2ebb55'}
								w={{ '2xl': '50%', lg: '60%', base: '80%' }}
								mb={'5'}
								py={{ lg: 3, base: 2 }}
								rounded={'lg'}
								bg={'#2ebb55'}
								_hover={{ bg: 'transparent', _text: { color: '#2ebb55' } }}
								_pressed={{ bg: '#2ebb55', _text: { color: '#fff' } }}
							>
								Apply Now
							</Button>
						</Link>
					</Box>
				</HStack>

				<HStack
					direction={{ md: 'row', base: 'column' }}
					w='100%'
					alignItems='center'
					justifyContent='space-between'
				>
					<Image
						resizeMode='contain'
						size={{ xl: '7.5rem', lg: '7.5rem', base: '7.5rem' }}
						source={LogoSVG}
					/>
					​
					<Box>
						<HStack alignItems='center'>
							<Text
								mx={{ md: 2, base: 1 }}
								color='#E3E3E3'
								textTransform='uppercase'
								fontSize={{ md: '30', base: '16px' }}
								fontFamily='poppins'
							>
								follow us
							</Text>
							{imgs.map((imgs1, index) => {
								return (
									<FooterIcons
										key={index}
										imgUrl={imgUrls[index]}
										imgName={imgs[index]}
										imgPath='footers'
									/>
								);
							})}
						</HStack>
					</Box>
					<HStack marginTop={{ lg: '', base: '8%' }}>
						<Text
							color='#fff'
							fontFamily='Poppins'
							fontSize='md'
							alignItems={'center'}
						>
							Contact us:
						</Text>
						&nbsp;&nbsp;
						<Link
							_text={{
								color: '#fff',
								fontFamily: 'Poppins',
								fontSize: 'md',
							}}
							to='#'
							onClick={(e) => {
								window.location.href = 'mailto:support@unitedmarket.com';
								e.preventDefault();
							}}
						>
							support@unitedmarket.com
						</Link>
					</HStack>
				</HStack>
			</Container>
		</Box>
	);
};

export default Footer;
