import React from "react";
import { Box, Image, Heading, HStack, VStack, Button, Divider } from "native-base";
// import Rating from "@mui/material/Rating";
import profile from "../../../assets/header/profile.png";
import ProfileTitle from "../ProfileTitle";
export const Teammates = () => {
  const data = [
    {
      id: 1,
      image: profile,
      name: "Austin Moris",
      title: "Role",
      button: [
        {
          text: "Legal",
          color: "#2EBB5530",
          textcolor: "#2EBB55",
        },
        {
          text: "Manager",
          textcolor: "#DBFF00",
          color: "#DBFF0030",
        },
      ],
    },
    {
      id: 2,
      image: profile,
      name: "Austin Moris",
      title: "Role",
      button: [
        {
          text: "A&R",
          color: "#2EBB5530",
          textcolor: "#2EBB55",
        },
        {
          text: "Manager",
          textcolor: "#DBFF00",
          color: "#DBFF0030",
        },
      ],
    },
    {
      id: 3,
      image: profile,
      name: "Austin Moris",
      title: "Role",
      button: [
        {
          text: "Legal",
          color: "#2EBB5530",
          textcolor: "#2EBB55",
        },
        {
          text: "Label",
          color: "#DB26DF30",
          textcolor: "#DB26DF",
        },
      ],
    },
    {
      id: 4,
      image: profile,
      name: "Austin Moris",
      title: "Role",
      button: [
        {
          text: "Legal",
          color: "#2EBB5530",
          textcolor: "#2EBB55",
        },
        {
          text: "Manager",
          textcolor: "#DBFF00",
          color: "#DBFF0030",
        },
      ],
    },
    
  ];
  return (
    <Box mt={5} pl={{lg:"16px",md:0}} bg={"#000201"}>
      <Box >
        <ProfileTitle  fontSize={{md:"2xl",base:"lg"}}   text={"teammates"}/>
        {/* <Heading
          textTransform="uppercase"
          fontSize={"2xl"}
          fontWeight={"medium"}
          color={"#E3E3E3"}
        
        >
          Teammates
        </Heading> */}
        <HStack w={"100%"} space={5} flexGrow={1} flexWrap={"wrap"} justifyContent={{md:"space-between"}}  >
          {data.map((item) => (
            <Box
              w={{ xl: "22%", md: "45%", base: "100%" }}
              rounded={"lg"}

             
              bg={"#1C1B1B"}
              my={5}
             
            >
              <VStack my={5} >
                <Box>
                  <Image
                    alignSelf={"center"}
                   
                    h="82px"
                    width="80px"
                    resizeMode="contain"
                    source={require("../../../assets/header/profile.png")}
                    alt="image"
                  />
                </Box>
                <Heading
                  textAlign={"center"}
                  fontSize={"md"}
                  fontWeight={"medium"}
                  color={"#E3E3E3"}
                  my={3}
                >
                  {item.name}
                </Heading>
                <Heading
                  textAlign={"center"}
                  fontSize={"md"}
                  fontWeight={"normal"}
                  color={"#E3E3E3"}
                  my={3}
                >
                  {item.title}
                </Heading>
                <HStack justifyContent={"center"} my={2} >
                  <Button
                    bg={item.button[0].color}
                    _pressed={{ bg: "transparent" }}
                    _hover={{ bg: "transparent" }}
                    mx={3}
                    w={'30%'}
                  >
                    <Heading
                      fontSize={"xs"}
                      fontWeight={"normal"}
                      color={item.button[0].textcolor}
                     
                    >
                      {item.button[0].text}
                    </Heading>
                  </Button>
                  <Button
                  
                    bg={item.button[1].color}
                    _pressed={{ bg: "transparent" }}
                    _hover={{ bg: "transparent" }}
                    mx={3}
                    w={'30%'}
                  >
                    <Heading
                      fontSize={"xs"}
                      fontWeight={"normal"}
                      color={item.button[1].textcolor}
                    
                    >
                      {item.button[1].text}
                    </Heading>
                  </Button>
                </HStack>
              </VStack>
            </Box>
          ))}
        </HStack>
        <Box w={'100%'} alignItems={'flex-end'} >
        <Button w={'20%'} variant={'ghost'} _pressed={{bg: 'transparent'}} justifyContent={'flex-end'} _hover={{bg: 'transparent'}}>
            <Heading color={'#AFAFAF'} fontWeight={'normal'} textAlign={'center'} fontSize={'lg'}>
              See More
            </Heading>
        <Divider/>
        </Button>
        </Box>
      </Box>
    </Box>
  );
};
