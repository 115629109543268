import { Box, HStack } from 'native-base'
import React,{useEffect} from 'react'
import { useState } from 'react'
import GetTotalShazamCount from '../../../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/Trends/GetTotalShazamCount'
import GetTotalSpotifyStream from '../../../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/Trends/GetTotalSpotifyStream'
import GetTotalYoutubeViews from '../../../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/Trends/GetTotalYoutubeViews'
import Trend from './Trend'
import moment from 'moment'
import GetSpotifyPopularity from '../../../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/Trends/GetSpotifyPopularity'
function Trends() {
const [totalShazamCount,setTotalShazamCount]=useState([])
const [totalSpotifyCount,setTotalSpotifyCount]=useState([])
const [totalYoutubeViews,SettotalYoutubeViews]=useState([])
const [totalSpotifyPopularity,settotalSpotifyPopularity]=useState([])
const [spotifyPopularityGraphLabel, setSpotifyPopularityGraphLabel] =
useState([]);
const [graphLabel, setGraphLabel] = useState([]);
const [spotifyPopularityDataset, setSpotifyPopularityDataset] = useState([]);
	const [spotifyDataset, setSpotifyDataset] = useState([]);
	const [youtubeDataset, setYoutubeDataset] = useState([]);
	const [shazamDataset, setShazamDataset] = useState([]);
	const [airplayData, setAirPlayData] = useState([]);
	const [shazamCount, setShazamCount] = useState(0);
	const [searchUUIDs, setSearchUUIDs] = useState([]);
	const [youtubeCount, setYoutubeCount] = useState(0);
	const [spotifyCount, setSpotifyCount] = useState(0);
const [isLoading,setIsloading]=useState(true)

const getChartData = (platform) => {
	switch (platform) {
		case 'spotify': {
			let datasets = totalSpotifyCount;
			let obj = {
				labels: graphLabel,
				position: 'bottom',
				datasets: datasets,
			};
			return obj;
		}
		case 'sPopularity': {
			let datasets = totalSpotifyPopularity;
			let obj = {
				labels: spotifyPopularityGraphLabel,
				position: 'bottom',
				datasets: datasets,
			};
			return obj;
		}
		case 'youtube': {
			let datasets = totalYoutubeViews;
			let obj = {
				labels: graphLabel,
				position: 'bottom',
				datasets: datasets,
			};
			return obj;
		}
		case 'shazam': {
			let datasets = totalShazamCount;
			let obj = {
				labels: graphLabel,
				position: 'bottom',
				datasets: datasets,
			};
			return obj;
		}
		default:
			return '';
	}
};
  useEffect(()=>{
    const GetTotlaShazamCount = async () => {
			try {
				const apiResponse = await GetTotalShazamCount();
			
					const { items } = apiResponse.data;
					setShazamCount(items.length);
					setGraphLabel(
						items.map((item) => {
							return item.date.split('T')[0];
						})
					);
				console.log("shzam",items[0].followerCount);
				setIsloading(false)
			
				// console.log("month",)
				setTotalShazamCount([...totalShazamCount,items.map((item=> item.followerCount)) ]);
			} catch (err) {
				console.log(err);
			}
		};


    const GetTotlaSpotifyStream = async () => {
			try {
				const apiResponse = await GetTotalSpotifyStream();
				const { items } = apiResponse.data;
				console.log("spotify",apiResponse.data.items);
				console.log("spotifyindex",items[0].value);
				setSpotifyPopularityGraphLabel(
					items.map((item) => {
						return item.date.split('T')[0];
					})
				);
				setIsloading(false)
				setSpotifyCount(items.length);
				setTotalSpotifyCount([...totalSpotifyCount,items.map((item=> item.followerCount)) ]);
			} catch (err) {
				console.log(err);
			}
		};
		const GetSpotifyPopularityIndex = async () => {
			try {
				const apiResponse = await GetSpotifyPopularity();
				const { items } = apiResponse.data;
				console.log("spotify",apiResponse.data.items);
				console.log("spotify",moment(apiResponse.data.items[0].date));
				setSpotifyPopularityGraphLabel(
					items.map((item) => {
						return item.date.split('T')[0];
					})
				);
				setIsloading(false)
				setShazamCount(items.length);
				settotalSpotifyPopularity([...totalSpotifyPopularity,items.map((item=> item.value)) ]);
			} catch (err) {
				console.log(err);
			}
		};
    const GetYoutubeViews = async () => {
			try {
				const apiResponse = await GetTotalYoutubeViews();
			
				const { items } = apiResponse.data;
				console.log("youtube views",apiResponse.data.items[0].followerCount);
				setIsloading(false)
				setGraphLabel(
					items.map((item) => {
						return item.date.split('T')[0];
					})
				);
				setYoutubeCount(items.length);
				SettotalYoutubeViews([...totalYoutubeViews,items.map((item=> item.followerCount)) ]);
			} catch (err) {
				console.log(err);
			}
		};
		GetTotlaShazamCount();
 
	GetSpotifyPopularityIndex()
	GetTotlaSpotifyStream()
    GetYoutubeViews()
  },[])
  return (
    <Box  py={4}  w={"100%"}>

      
<Box w={'100%'}>		
<HStack py={{md:4, base:0}}  flexDirection={{md:"row",base:"column"}} justifyContent={'space-between'} flexGrow={1} flexWrap={'wrap'}>
		<Trend isLoading={isLoading} tableData={getChartData('spotify')} title={`${spotifyCount} Total Spotify Stream`} image={"spotify"}  subtitle={"Total stream over last 28 days"}/>
<Trend isLoading={isLoading}  tableData={getChartData('sPopularity')} title={"Spotify Popuarity Index"} image={"spotify"}  subtitle={"Popularity index last 28 days"}/>

</HStack>
<HStack py={{md:4, base:0}} flexDirection={{md:"row",base:"column"}} justifyContent={'space-between'} flexGrow={1} flexWrap={'wrap'}>


<Trend isLoading={isLoading} tableData={getChartData('shazam')} title={`${shazamCount} Total Shazam Count`} image={"shazam"}  subtitle={"Total videos over last 28 days"}/>
<Trend isLoading={isLoading}   tableData={getChartData('youtube')} title={`${youtubeCount} Total Youtube View`} image={"youtube"}  subtitle={"Total videos over last 28 days"}/>
</HStack>
        </Box>
    </Box>
  )
}

export default Trends