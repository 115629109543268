/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React from "react";
import {
  Box,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
  Button,
} from "native-base";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Avatar } from "@mui/material";
import useAddFollower from "../../Hooks/Feeds/addFollower";
import { toast } from "react-toastify";
function SuggestionCard(props) {
  const { userId, image, title, follower } = props;
  const [loading, addFollower] = useAddFollower();

  const handleClick = async () => {
    console.log("clicking on adding follower button...");
    const response = await addFollower(userId);
    if (response) {
      return toast.success("User added to following..");
    } else {
      return toast.error("user could not be added to following..");
    }
  };
  return (
    <Box w={"100%"} my={2} rounded={"lg"}>
      <Box w={"90%"} alignSelf={"center"}>
        <HStack w={"100%"} py={2}>
          <Box w={"20%"} alignSelf={"center"}>
            <Avatar
              src={image}
              alt=""
              style={{ height: "56px", width: "56px" }}
            />
            {/* <Image
              source={image}
              height={{ xl: '7.5vh', md: '9vh', base: '9vh' }}
              width={{ xl: '100%', md: '80%', base: '100%' }}
              rounded={'full'}
            /> */}
          </Box>
          <Box w={"60%"} alignSelf={"flex-start"} pl={3}>
            <Heading
              fontSize={{ xl: "xl", base: "md" }}
              fontWeight={"medium"}
              fontFamily={"Poppins"}
              textAlign={"left"}
              textTransform={"capitalize"}
              color={"#e3e3e3"}
              pb={2}
            >
              {title}
            </Heading>
            <Heading
              fontSize={{ xl: "14px", base: "xs" }}
              fontWeight={"normal"}
              fontFamily={"Poppins"}
              textAlign={"left"}
              textTransform={"capitalize"}
              color={"#AFAFAF"}
              // py={2}
            >
              {follower} Followers
            </Heading>
          </Box>
          <Box w={"20%"} alignSelf={"center"}>
            <Button
              bg={"#2EBB55"}
              borderWidth={1}
              borderColor={"#2EBB55"}
              _hover={{ bg: "transparent" }}
              _pressed={{ bg: "#2EBB55" }}
              p={{ xl: 4, md: 5, base: 2 }}
              alignSelf={"flex-start"}
              onPress={handleClick}
            >
              <PersonAddAlt1Icon sx={{ color: "#e3e3e3", fontSize: 25 }} />
            </Button>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}

export default SuggestionCard;
