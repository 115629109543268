/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Modal,
  Input,
  Radio,
} from 'native-base';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

export const SubmitModal = (props) => {
  const { showSubmitModal, closeSubmitModal } = props;
  const [value, setValue] = useState(1);
  return (
    <Modal
      isOpen={showSubmitModal}
      bg={'#141313 '}
      h={'auto'}
      shadow={7}
      rounded={'lg'}
      w={{ xl: '40%', lg: '40%', base: '90%' }}
      onClose={closeSubmitModal}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={80}
    >
      <Box w={'100%'} bg={'#141313'} rounded={'lg'}>
        <HStack
          alignSelf={'center'}
          justifyContent={'center'}
          my={3}
          w={'100%'}
        >
          <Heading
            px={3}
            color={'#fff'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            fontSize={{ lg: '2xl', base: 'lg' }}
            alignSelf={'center'}
          >
            Submit
          </Heading>

          <Button
            variant={'ghost'}
            px={3}
            py={0}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeSubmitModal}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>
        <Divider />
        <Box w={'95%'} alignSelf={'center'} mb={5}>
          <Box mt={5}>
            <Heading
              py={2}
              color={'#AFAFAF'}
              fontSize={{ xl: 'xl', base: 'md' }}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
              textAlign={'left'}
            >
              Name
            </Heading>
            <Input
              width={'100%'}
              py={2.5}
              rounded={'md'}
              my={2}
              bg={'#141313'}
              borderColor={'#afafaf'}
              _hover={{ bg: '#141313', borderColor: '#AFAFAF' }}
              _focus={{ bg: '#141313', borderColor: '#AFAFAF' }}
              focusOutlineColor={'#afafaf'}
              color={'#fff'}
              fontSize={'md'}
              placeholderTextColor={'#AFAEAE70'}
              size={'lg'}
            />
          </Box>
          <Box mt={5}>
            <Heading
              py={2}
              color={'#AFAFAF'}
              fontSize={{ xl: 'xl', base: 'md' }}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
              textAlign={'left'}
            >
              Description
            </Heading>
            <Input
              width={'100%'}
              py={2.5}
              rounded={'md'}
              my={2}
              bg={'#141313'}
              borderColor={'#afafaf'}
              _hover={{ bg: '#141313', borderColor: '#AFAFAF' }}
              _focus={{ bg: '#141313', borderColor: '#AFAFAF' }}
              focusOutlineColor={'#afafaf'}
              multiline={true}
              numberOfLines={4}
              color={'#fff'}
              fontSize={'md'}
              placeholderTextColor={'#AFAEAE70'}
              size={'lg'}
            />
          </Box>

          <Button
            my={5}
            size={'sm'}
            bg='#2EBA55'
            borderWidth={1}
            borderColor='#2EBA55'
            _text={{
              color: '#fff',
              fontSize: {
                lg: '18px',
                md: '16px',
                sm: '14px',
                base: '14px',
              },
              fontFamily: 'Poppins',
            }}
            _hover={{
              bg: 'transparent',
              _text: {
                color: '#2EBA55',
              },
            }}
            onPress={closeSubmitModal}
            w={'30%'}
            rounded={'md'}
            alignSelf={'flex-end'}
            _focused={{ bg: '#2EBA55' }}
            _pressed={{ bg: '#2EBA55' }}
          >
            SUBMIT
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
