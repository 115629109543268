import React from 'react';

function Delete() {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M15.8032 3.9225C14.5957 3.8025 13.3882 3.7125 12.1732 3.645V3.6375L12.0082 2.6625C11.8957 1.9725 11.7307 0.9375 9.97567 0.9375H8.01067C6.26017 0.9375 6.09817 1.9275 5.97817 2.655L5.82067 3.615C5.12317 3.66 4.42567 3.705 3.72817 3.7725L2.19817 3.9225C2.04998 3.93642 1.91339 4.00865 1.81845 4.12328C1.72351 4.23791 1.67799 4.38556 1.69192 4.53375C1.70584 4.68194 1.77806 4.81853 1.89269 4.91347C2.00732 5.00841 2.15498 5.05392 2.30317 5.04L3.83317 4.89C7.78361 4.54899 11.7582 4.60174 15.6982 5.0475H15.7582C15.8983 5.04693 16.0334 4.99452 16.1372 4.90036C16.2411 4.8062 16.3064 4.67695 16.3207 4.5375C16.332 4.38768 16.2841 4.2394 16.1874 4.12443C16.0906 4.00947 15.9527 3.93698 15.8032 3.9225Z'
				fill='#AFAFAF'
			/>
			<path
				d='M14.4205 6.105C14.3323 6.01283 14.2264 5.93941 14.1091 5.88915C13.9918 5.83889 13.8656 5.81282 13.738 5.8125H4.25954C4.13184 5.81212 4.00541 5.83788 3.88804 5.88818C3.77067 5.93848 3.66483 6.01227 3.57704 6.105C3.48943 6.19947 3.4216 6.31051 3.37755 6.43159C3.3335 6.55266 3.31412 6.68133 3.32054 6.81L3.78554 14.505C3.86804 15.645 3.97304 17.07 6.59054 17.07H11.4055C14.023 17.07 14.128 15.6525 14.2105 14.505L14.6755 6.8175C14.6827 6.68774 14.6638 6.55786 14.62 6.43551C14.5762 6.31315 14.5084 6.20078 14.4205 6.105ZM10.2445 13.3125H7.74554C7.59635 13.3125 7.45328 13.2532 7.34779 13.1478C7.2423 13.0423 7.18304 12.8992 7.18304 12.75C7.18304 12.6008 7.2423 12.4577 7.34779 12.3523C7.45328 12.2468 7.59635 12.1875 7.74554 12.1875H10.243C10.3922 12.1875 10.5353 12.2468 10.6408 12.3523C10.7463 12.4577 10.8055 12.6008 10.8055 12.75C10.8055 12.8992 10.7463 13.0423 10.6408 13.1478C10.5353 13.2532 10.3922 13.3125 10.243 13.3125H10.2445ZM10.8745 10.3125H7.12454C6.97535 10.3125 6.83228 10.2532 6.72679 10.1478C6.6213 10.0423 6.56204 9.89919 6.56204 9.75C6.56204 9.60082 6.6213 9.45775 6.72679 9.35226C6.83228 9.24677 6.97535 9.1875 7.12454 9.1875H10.8745C11.0237 9.1875 11.1668 9.24677 11.2723 9.35226C11.3778 9.45775 11.437 9.60082 11.437 9.75C11.437 9.89919 11.3778 10.0423 11.2723 10.1478C11.1668 10.2532 11.0237 10.3125 10.8745 10.3125Z'
				fill='#AFAFAF'
			/>
		</svg>
	);
}

export default Delete;
