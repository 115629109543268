import React from 'react';
import { Box, HStack } from 'native-base';
import BookCollab from '../../components/PaidCollab/BookCollab';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';

export default function BookACollab() {
  return (
    <div>
      <Box w={'100%'} bg={'#000201'}>
				<HStack w={'100%'}>
					<Sidebar childComponent={<BookCollab />} activeText='Book A Collab' />
				</HStack>
			</Box>
    </div>
  )
}
