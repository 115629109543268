import { gql } from '@apollo/client';

export const TandC = gql`
	mutation {
		sendTermsAndCondition {
			success
			message
		}
	}
`;
