import { useMutation } from '@apollo/client';
import { SendFeedbackQery } from './Mutation';
import { Notification } from '../../NotifyMsg/Notification';
const SendFeedBackMutation = () => {
	const [sendFeedback] = useMutation(SendFeedbackQery, {
		onCompleted(data) {
			return data;
		},
		onError(error) {
			console.log('error : ', error);
			return error;
		},
	});

	return { sendFeedback };
};

export default SendFeedBackMutation;
