import { Box, HStack } from 'native-base';
import React from 'react';
import Help from '../../components/Help';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
const BarHome = (props) => {
	return (
		<Box w={'100%'} bg={'#000201'}>
			<HStack w={'100%'}>
				<Sidebar childComponent={<Help />} activeText='Help' />
			</HStack>
		</Box>
	);
};

export default BarHome;
