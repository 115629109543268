import { Box, HStack, Icon,Image,Pressable } from 'native-base'
import React from 'react'
 import Referal from '../../Auth/SignUp/Referal'
 import ViralLoops from "viral-loops-react";
 import "./referalmodal.css"
 import { config } from './config';
 import { CloseIcon } from 'native-base';
 import closeIcon from "../../../assets/Profile/close.png"
function ReferalModal({setshowModal}) {
  return (
<Box  position={"absolute"} top={"50%"} marginLeft={"-250px"} py={8} marginTop={"-250px"} left={"50%"} zIndex={9999999}  width={"500px"}  bg={"#1C1B1B"} >
<HStack px={5} justifyContent={"flex-end"}>
    <Pressable  onPress={()=>setshowModal(false)}>    <Image  alt='no icon' source={require("../../../assets/Profile/close.png")} height={"50px"} width={"50px"}/></Pressable>
</HStack>

<div
      
      //   style={{
      //     backgroundColor: "#ffff",
      //     height: "100%",
      //     width: "100%",
      //     minHeight: "100vh",
      //   }}
    >
      <div style={{ width: "100%" }} className="">
        <Box
          w={{ md: "85%", sm: "85%", base: "94%" }}
          fontFamily="poppins"
          mx={"auto"}
        >
          <div>
            {/* <FormTitle
              //   activeStep={activeStep}
              color={"#000201"}
              title={"refer a friend"}
              //   subtitle={props.subtitle}
              //   isMobile={isMobile}
            /> */}
            <ViralLoops
              publicToken={config.viralLoopsCampaignId}
    
        
            />
            {/* <Button
              onPress={handlePress}
              _text={{
                color: "#E3E3E3",
                fontWeight: "normal",
                fontSize: { xl: "1.2rem", md: "1rem", base: "1rem" },
                fontFamily: "poppins",
                textTransform: "capitalize",
              }}
              height={{ lg: "45px", sm: "35px", base: "48px" }}
              backgroundColor={"#2EBB55"}
              mt={activeStep === 5 ? 12 : 4}
              //   onpress={handlePress}
            >
              {props.btnText}
            </Button> */}
            {/* <Box>
              <Text
                fontFamily="poppins"
                onPress={handlePrevious}
                mt={5}
                mx={"auto"}
                //   w={"100%"}
                //   textAlign={"center"}
                color={"#AFAFAF"}
                textTransform={"uppercase"}
                letterSpacing={{ md: "0.8", base: "0" }}
                fontWeight="normal"
                fontSize={{ md: "1.2rem", sm: "0.9rem", base: "0.95rem" }}
                textDecorationLine={"underline"}
              >
                previous step
              </Text>
            </Box> */}
          </div>
        </Box>
      </div>
    </div>
</Box>
  )
}

export default ReferalModal