import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './select.css';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function DaySelecter(props) {
  const { value, handler } = props;
  const handleChange = (event) => {
    // console.log('selected value : ', event.target.value)
    // setAge(event.target.value);
    handler(event.target.value);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <FormControl
        sx={{ m: [0, 0, 0.5], p: 0, mainWidth: [20, 120, 200] }}
        className='country_filter'
      >
        <Select
          labelId='demo-simple-select-helper-label'
          value={value}
          displayEmpty
          onChange={handleChange}
          style={{ color: '#AFAFAF', border: '#AFAFAF', fontSize: 14 }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: '300px',
                backgroundColor: '#1c1b1b',
                color: 'afafaf',
              },
            },
          }}
          sx={{
            '& .MuiSelect-outlined': {
              padding: '5px !important',
            },
          }}
        >
          <MenuItem
            sx={{
              '&:focus': {
                background: '#2EBB55',
                color: 'white',
              },
              '&:hover': {
                background: '#AFAFAF',
                color: 'afafaf',
              },
              fontSize: 12,
            }}
            value='7'
          >
            Today
          </MenuItem>
          <MenuItem
            sx={{
              '&:focus': {
                background: '#2EBB55',
                color: 'white',
              },
              '&:hover': {
                background: '#AFAFAF',
                color: 'afafaf',
              },
              fontSize: 12,
            }}
            value='28'
          >
            Weekly
          </MenuItem>
          <MenuItem
            sx={{
              '&:focus': {
                background: '#2EBB55',
                color: 'white',
              },
              '&:hover': {
                background: '#AFAFAF',
                color: 'white',
              },
              fontSize: 12,
            }}
            value='60'
          >
            Monthly
          </MenuItem>
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

export default DaySelecter;
