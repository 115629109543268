import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import right from '../../../assets/Feeds/right.png';
import left from '../../../assets/Feeds/left.png';
import {
    Button,
    Image
  } from 'native-base';

const previouseIcon = () => {
  <Button
    p={0}
    variant={"ghost"}
    _pressed={{ bg: "transparent" }}
    _hover={{ bg: "transparent" }}
    _focus={{ bg: "transparent" }}
  >
    <Image my={3} source={left} size={20} alignSelf={"center"} />
  </Button>;
};

const nextIcon = () => {
    <Button
      p={0}
      variant={"ghost"}
      _pressed={{ bg: "transparent" }}
      _hover={{ bg: "transparent" }}
      _focus={{ bg: "transparent" }}
    >
      <Image my={3} source={right} size={20} alignSelf={"center"} />
    </Button>;
  };

function Slider(props) {
  const { image } = props;
  return (
    <OwlCarousel
      className="owl-theme custom-carousel"
      loop
      margin={10}
      items={1}
      nav
      navText={[`<img src=${left} />`, `<img src=${right} />`]}
    >
      <div className="item">
        <img src={image} />
      </div>
      <div className="item">
        <img src={image} />
      </div>
      <div className="item">
        <img src={image} />
      </div>
      <div className="item">
        <img src={image} />
      </div>
      <div className="item">
        <img src={image} />
      </div>
    </OwlCarousel>
  );
}

export default Slider;
