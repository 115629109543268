import { Box, Divider, Heading, HStack, Image, Text } from "native-base";
import React from "react";
import { DashboardHeader } from "../Headers";
import SideBar from "../SideBar/SideBar";
import ButtonComp from "../ButtonComponent";
import Feed from "./Feed";
import Trending from "./SideContent/Trending";
import Follows from "./SideContent/Follows";
import Suggestion from "./SideContent/Suggestion";
import profile1 from "../../assets/Feeds/profile.png";
import { getFeedsByUserId } from "../../RestApis/Services/Feeds";
import useAuth from "../../context/AuthContext";
import FeedComponent from "./FeedComponent";
import { Loader } from "../Loader";

function Feeds() {
  const { user, loading, loadUser } = useAuth();
  const [feedsData, setFeedsData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      console.log("user info...", user);
      getFeeds(user._id);
    } else {
      loadUser();
    }
  }, [user]);
  const getFeeds = async (userId) => {
    try {
      setIsLoading(true);
      const response = await getFeedsByUserId(userId);
      console.log("feeds api response..", response);
      setIsLoading(false);
      if (response.status === 200) {
        const opportunities = response?.data?.data?.userFeeds?.getOpportunities;
        // console.log('feeds data...', opportunities)
        setFeedsData(opportunities.map((opportunity) => opportunity?.product));
      }
    } catch (err) {
      setIsLoading(false);
      console.log("feeds api error...", err);
    }
  };
  return loading ? (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    <Box w={"100%"}>
      <HStack w={"100%"} flexGrow={1} flexWrap={"wrap"}>
        <Box w={{ xl: "72%", base: "100%" }}>
          <Box w={"90%"} alignSelf={"center"} py={5}>
            <Heading
              my={5}
              fontSize={{ xl: "4xl", md: "3xl", base: "2xl" }}
              color={"#fff"}
              textTransform={"capitalize"}
              fontFamily={"poppins"}
            >
              your feed
            </Heading>
            <HStack space={5}>
              <ButtonComp
                textProperties={{
                  color: "#E3E3E3",
                  fontWeight: "semibold",
                  fontSize: { xl: "1.2rem", md: "1rem", base: "0.7rem" },
                  fontFamily: "poppins",
                  textTransform: "uppercase",
                }}
                flag={false}
                paddingXMd={5}
                paddingXSm={3}
                paddingYMd={2}
                paddingYSm={1}
                text={"sell kits"}
                backgroundColor="#2EBB55"
                borderwidth={1}
              ></ButtonComp>
              <ButtonComp
                textProperties={{
                  color: "#E3E3E3",
                  fontWeight: "semibold",
                  fontSize: { xl: "1.2rem", md: "1rem", base: "0.7rem" },
                  fontFamily: "poppins",
                  textTransform: "uppercase",
                }}
                flag={false}
                paddingXMd={5}
                paddingXSm={3}
                paddingYMd={2}
                paddingYSm={1}
                text={"sell beats"}
                backgroundColor="#2EBB55"
                borderwidth={1}
              ></ButtonComp>
            </HStack>
            <Box my={10} p={5} bg="#1C1B1B" rounded={"lg"}>
              <HStack space={5} alignItems={"center"}>
                <Image
                  source={require("../../assets/Feeds/cup.png")}
                  height={"30px"}
                  width={"30px"}
                />
                <Text
                  fontSize={"1.1rem"}
                  color={"#E3E3E3"}
                  textTransform={"uppercase"}
                  fontFamily={"poppins"}
                >
                  ARTISTS! Win 10K and a TikTok with SHAQ!
                </Text>
              </HStack>
            </Box>
            {isLoading ? (
              <Box
                style={{
                  width: "100%",
                  minHeight: "30rem",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Loader />
              </Box>
            ) : (
              feedsData.map((feed, key) => (
                <React.Fragment key={key}>
                  <FeedComponent feed={feed} />
                </React.Fragment>
              ))
            )}
            {/* <Feed
              profile={profile1}
              name={"Toms Harry"}
              opportunity={"Posted an opportunity"}
              date={"Aug/20/22"}
              lable={"@ rockstar"}
              ads={true}
              friend={true}
            />
            <Feed
              profile={profile1}
              name={"Toms Harry"}
              opportunity={"Posted an opportunity"}
              date={"Aug/20/22"}
              lable={"@ rockstar"}
              ads={false}
              friend={true}
            />
            <Feed
              profile={profile1}
              name={"Toms Harry"}
              opportunity={"Posted an opportunity"}
              date={"Aug/20/22"}
              lable={"@ rockstar"}
              ads={false}
              friend={false}
            /> */}
          </Box>
        </Box>
        <Box
          w={{ xl: "28%", base: "100%" }}
          borderLeftWidth={{ lg: 1, md: 0, base: 0 }}
          borderColor={"#fff"}
          // borderWidth={1}
        >
          <Box w={"90%"} alignSelf={"center"} pt={{ xl: 8, md: 0, base: 0 }}>
            <Trending />
            <Follows />
            <Suggestion />
          </Box>
        </Box>
      </HStack>
    </Box>
  );
}

export default Feeds;
