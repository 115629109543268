import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";

export const DarkMenu = withStyles({
  paper: {
    "background": "#1c1b1b",
    "border": "1px solid #d3d4d5",
    "minWidth": "12rem",
    top:"60px !important",
    "& ul": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important"
    }
  }
})((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

export const DarkMenuItem = withStyles((theme) => ({
  root: {
    "color": "#AFAFAF",
    "borderBottom": "1px solid #AFAFAF",
    "fontFamily": "Poppins !important",
    textAlign: "center",
    "fontSize": "18px !important",
    "&:hover": {
      backgroundColor: "#2EBB55",
      color: "#FFF"
    }
  }
}))(MenuItem);
