import React, { useContext, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { ForgetPass } from "./Mutation";
import { Notification } from "../../../../NotifyMsg/Notification";
import { AuthContext } from "../../../../Auth/Layout";
function useForgetPasswordMutation() {
  const {
    activeStep,
    setActiveStep,

    disableBtn,

    setIsDisableBtn,
    sendotpAgain,
    setSendOtpAgain,
  } = useContext(AuthContext);
  const [forgetPassword] = useMutation(ForgetPass, {
    onCompleted(data) {
      if (data?.forgetPassword?.success) {
        console.log("success");
        Notification("success", data.forgetPassword.message);
        setTimeout(() => {
          sendotpAgain
            ? setActiveStep(activeStep)
            : setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setIsDisableBtn(false);
        }, 1400);

        
      } else {
        console.log("success");
        Notification("error", data.forgetPassword.message);
        setActiveStep(activeStep);
        setIsDisableBtn(false);
      }
    },
  });

  return { forgetPassword };
}

export default useForgetPasswordMutation;
