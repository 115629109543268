import { Box,Divider,HStack } from 'native-base'
import React from 'react'
import SideBar from '../SideBar/SideBar'
import { Dashboard } from '@mui/icons-material'
import { DashboardHeader } from '../Headers'
const  UserProfile=(props)=> {
    const {childComp}=props
  return (
    <Box w={"100%"} bg={"#000201"}>
    <HStack w={"100%"} >
      <Box
        display={{ lg: "block", base: "none" }}
        w={{ xl: "20%", base: "0%" }}
      >
        <SideBar />
      </Box>


 <Box     borderLeftWidth={{lg:1,md:0}}
          borderColor={"#fff"}     w={{ xl: "80%", base: "100%" }} >
<DashboardHeader/>
<Divider/>
{childComp}
 </Box>

 {/* <Box py={8} px={7}>
  <ProfileHeaderIcon/>
  <ProfileForm/>
 </Box> */}

   
    </HStack>
  </Box>
    
   
  )
}

export default UserProfile