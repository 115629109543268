import { Box, Divider, HStack, Heading, VStack } from 'native-base'
import React from 'react'
import ProfileList from './ProfileList'
import Social from './Social';

function RightSideBar() {

  return (
    <VStack
      width='100%'
      bg="#1C1B1B"
      rounded={'lg'}
      my={2}
    >
      <Social cardName="Profile Views" title="Views" viewCount="569" />

      <HStack
        alignSelf={'center'}
        width='90%'
      >
        <Heading
          w={'25%'}
          fontWeight={'normal'}
          fontSize={'xs'}
          color="#555555"
        >Profile</Heading>
        <Heading
        w={'50%'}
          fontWeight={'normal'}
          fontSize={'xs'}
          color="#555555"
        >Name</Heading>
        <Heading
        w={'25%'}
           fontWeight={'normal'}
           fontSize={'xs'}
           color="#555555"
        >Date</Heading>
      </HStack>
      <Box
        width={'100%'}
      >
        <Divider my="4" _light={{
          bg: "#555555"
        }} _dark={{
          bg: "#555555"
        }}
        />
      </Box>
      <ProfileList imageUrl="userPic1.png" name="Kibriya Zameer" date="8-27-2022" />
      <ProfileList imageUrl="userPic1.png" name="Kibriya Zameer" date="8-27-2022" />
      <ProfileList imageUrl="userPic1.png" name="Kibriya Zameer" date="8-27-2022" />
      <ProfileList imageUrl="userPic1.png" name="Kibriya Zameer" date="8-27-2022" />
    </VStack>
  )
}

export default RightSideBar