/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	VStack,
	Text,
	HStack,
	Input,
	Box,
	CheckIcon,
	Select,
	Modal,
	Button,
	Center,
	Heading,
	Menu,
	Pressable,
	Hidden,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import coverImg from '../../assets/Images/1cover.png';
import styles from './styles.module.css';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
const EditFilePopup = (props) => {
	const { openModal, closeModal, data, onEdit, isEdit, onUpload } = props;
	const [files, setFiles] = useState(null);
	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedImageURL, setSelectedImageURL] = useState(null);
	const [audioName, setAudioName] = useState('');
	const [tags, setTags] = useState([]);
	const [bpm, setBPM] = useState('');
	const [scale, setScale] = useState('');
	const [k, setKey] = useState('');

	const handleChange = (val) => {
		if (val.target.value > 150) {
			val.preventDefault();
			toast.error('Invalid BPM range');
			val.target.value = '';
		} else setBPM(val.target.value);
	};
	const handleBPMInput = (val) => {
		var ch = String.fromCharCode(val.which);

		if (!(/[0-9]/.test(ch) || val.which === 8)) {
			val.preventDefault();
			toast.error('Invalid BPM');
		}
	};
	const addTag = (e) => {
		if (e.key === 'Enter') {
			if (e.target.value.length > 0) {
				setTags([...tags, e.target.value]);
				e.target.value = '';
			}
		}
	};
	const removeTag = (removedTag) => {
		const newTags = tags.filter((tag) => tag !== removedTag);
		setTags(newTags);
	};
	useEffect(() => {
		setAudioName(data?.fileName || '');
		setBPM(data?.BPM || '');
		setKey(data?.fileKey || '');
		setScale(data?.scale || '');
		setTags(data?.tags || []);
		setSelectedImage(data?.audioIconUrl);
		setSelectedImageURL(data?.audioIconUrl);
	}, []);

	const onhandleEdit = () => {
		isEdit
			? onEdit(k, scale, bpm, tags, audioName, selectedImage, data._id)
			: onUpload(k, scale, bpm, tags, selectedImage);
	};
	return (
		<Modal
			isOpen={openModal}
			onClose={closeModal}
			w={{ lg: '60%', base: '90%' }}
			size={'full'}
			alignSelf={'center'}
			justifyContent={'flex-start'}
			mt={20}
		>
			<Box backgroundColor={'#141313'} w={'100%'} rounded={'md'}>
				<Box bg={'#141313'} w={{ lg: '90%', base: '95%' }} alignSelf={'center'}>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						mx={{ lg: 5, base: 0 }}
						mb={{ lg: 0, base: 0 }}
					/>
					<HStack justifyContent={'space-between'}>
						<VStack w={{ lg: '20%', base: '20' }} my={10}>
							<img
								alt={'cover'}
								className={styles.editImg}
								alignSelf={'center'}
								src={
									selectedImageURL && selectedImageURL !== null
										? selectedImageURL
										: coverImg
								}
							/>

							<HStack
								w={{ lg: '136px', base: '70px' }}
								bg={'transparent'}
								rounded={'md'}
								_hover={{ bg: '#2EBA55' }}
								_focused={{ bg: 'transparent' }}
								_pressed={{ bg: 'transparent' }}
								borderWidth={1}
								borderColor={'#afafaf'}
								my={5}
								py={2}
								style={{
									cursor: 'pointer',
								}}
								alignItems={'center'}
								justifyContent={'center'}
							>
								<Center alignSelf={'center'}>
									<label htmlFor='hh' className={styles.editLabel}>
										Edit Cover
									</label>
									<input
										accept='image/*'
										type='file'
										id='hh'
										style={{ display: 'none', visibility: 'none' }}
										onChange={(val) => {
											setSelectedImage(val.target.files[0]);
											setSelectedImageURL(
												URL.createObjectURL(val.target.files[0])
											);
										}}
									/>
								</Center>
							</HStack>
						</VStack>

						<Box w={{ lg: '77%', base: '75%' }}>
							<Text
								mt={8}
								mb={3}
								pr={5}
								fontSize={{ lg: '24px', md: '20px', base: '14px' }}
								fontFamily={'Poppins'}
								color='white'
							>
								{audioName}
							</Text>
							<Text
								my={3}
								fontSize={{ lg: '18px', md: '16px', base: '12px' }}
								fontFamily={'Poppins'}
								color='#e3e3e3'
							>
								Tags
							</Text>
							<HStack
								flexWrap={'wrap'}
								borderBottomWidth={1}
								borderBottomColor={'#afafaf'}
							>
								{tags &&
									tags.map((tag, index) => (
										<HStack
											key={'tag_' + index}
											bg={'transparent'}
											borderColor={'white'}
											rounded='sm'
											borderWidth={1}
											alignSelf={'center'}
											m={1}
											py={1}
											px={1}
										>
											<Text
												p={0}
												alignSelf={'center'}
												color={'#afafaf'}
												fontSize={{ lg: '16px', md: '16px', base: '12px' }}
												fontFamily={'Poppins'}
											>
												{tag}
											</Text>

											<Button
												h={'0.5'}
												bg={'#1C1B1B'}
												mx={1}
												alignSelf={'center'}
												onPress={() => {
													removeTag(tag);
												}}
												_hover={{ bg: '#1C1B1B' }}
												_focused={{ bg: '#1C1B1B' }}
												_pressed={{ bg: '#1C1B1B' }}
											>
												x
											</Button>
										</HStack>
									))}
								<Input
									my={2}
									variant='unstyled'
									placeholder='Add some tags (#drums, #new-album, #stem)'
									w={{ lg: '60%', base: '95%' }}
									onKeyPress={addTag}
									focusOutlineColor={'#afafaf'}
									color='white'
									fontSize={{ lg: '16px', md: '10px' }}
								/>
							</HStack>
							<HStack
								my={{ lg: 5, base: 3 }}
								w={{ lg: '80%', base: '100%' }}
								justifyContent={'space-evenly'}
							>
								<VStack w={'26%'}>
									<Text
										my={{ lg: 3, base: 2 }}
										fontSize={{ lg: '18px', md: '16px', base: '12px' }}
										fontFamily={'Poppins'}
										color='#e3e3e3'
									>
										BMP
									</Text>
									<Input
										my={2}
										alignSelf={'flex-start'}
										_important={{
											backgroundColor: 'transparent',
											borderColor: '#afafaf',
										}}
										placeholder='120'
										value={bpm}
										onChange={handleChange}
										w={{ lg: '90%', base: '100%' }}
										py={'7.5px'}
										onKeyPress={handleBPMInput}
										focusOutlineColor={'#afafaf'}
										color='white'
										fontSize={{ lg: '16px', md: '12px' }}
									/>
								</VStack>

								<VStack w={{ lg: '30%', base: '30%' }}>
									<Text
										my={{ lg: 3, base: 2 }}
										fontSize={{ lg: '18px', md: '16px', base: '12px' }}
										fontFamily={'Poppins'}
										color='#e3e3e3'
									>
										Key
									</Text>
									<Menu
										rounded={'md'}
										borderColor={'#fff'}
										borderWidth={1}
										alignSelf={'center'}
										color={'#afafaf'}
										fontSize={{ md: '16px' }}
										w={'150px'}
										_text={{
											color: '#afafaf',
										}}
										background={'#141313'}
										placement={'bottom'}
										trigger={(triggerProps) => {
											return (
												<Pressable
													rounded={'sm'}
													my={2}
													w={{ lg: '90%', base: '100%' }}
													backgroundColor='transparent'
													borderColor='#afafaf'
													borderWidth={1}
													_hover={{ bg: 'transparent' }}
													_focused={{ bg: 'transparent' }}
													_pressed={{ bg: 'transparent' }}
													focusable={false}
													accessibilityLabel='More options menu'
													{...triggerProps}
												>
													<HStack>
														<Heading
															color={'#afafaf'}
															textAlign={'left'}
															py={'8px'}
															px={{ lg: 2, base: 1 }}
															w={'85%'}
															alignSelf={'flex-start'}
															fontFamily={'Poppins'}
															fontWeight={'normal'}
															fontSize={{ lg: '16px', md: '12px' }}
														>
															{k}
														</Heading>

														<Hidden from='base' till='md'>
															<KeyboardArrowDownRoundedIcon
																sx={{
																	color: '#AFAFAF',
																	fontSize: 25,
																	alignSelf: 'center',
																	fontWeight: 200,
																}}
															/>
														</Hidden>
														<Hidden from='md'>
															<KeyboardArrowDownRoundedIcon
																sx={{
																	color: '#AFAFAF',
																	fontSize: 20,
																	alignSelf: 'center',
																	fontWeight: 200,
																}}
															/>
														</Hidden>
													</HStack>
												</Pressable>
											);
										}}
									>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'0px'}
											borderTopColor='#afafaf'
											onPress={() => setKey('G')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'G'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313t' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setKey('C#Db')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'C#Db'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setKey('D')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'D'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setKey('D#Eb')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'D#Eb'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setKey('E')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'E'}
											</Heading>
										</Menu.Item>
									</Menu>
								</VStack>

								<VStack w={'37%'}>
									<Text
										my={{ lg: 3, base: 2 }}
										fontSize={{ lg: '18px', md: '16px', base: '12px' }}
										fontFamily={'Poppins'}
										color='#e3e3e3'
									>
										Scale
									</Text>
									<Menu
										rounded={'md'}
										borderColor={'#fff'}
										borderWidth={1}
										alignSelf={'center'}
										color={'#afafaf'}
										fontSize={{ md: '16px' }}
										w={'150px'}
										_text={{
											color: '#afafaf',
										}}
										background={'#141313'}
										placement={'bottom'}
										trigger={(triggerProps) => {
											return (
												<Pressable
													rounded={'sm'}
													my={2}
													w={{ lg: '90%', base: '100%' }}
													backgroundColor='transparent'
													borderColor='#afafaf'
													borderWidth={1}
													_hover={{ bg: 'transparent' }}
													_focused={{ bg: 'transparent' }}
													_pressed={{ bg: 'transparent' }}
													focusable={false}
													accessibilityLabel='More options menu'
													{...triggerProps}
												>
													<HStack>
														<Heading
															color={'#afafaf'}
															textAlign={'left'}
															py={'8px'}
															px={{ lg: 2, base: 1 }}
															w={'85%'}
															alignSelf={'flex-start'}
															fontFamily={'Poppins'}
															fontWeight={'normal'}
															fontSize={{ lg: '16px', md: '12px' }}
														>
															{scale}
														</Heading>

														<Hidden from='base' till='md'>
															<KeyboardArrowDownRoundedIcon
																sx={{
																	color: '#AFAFAF',
																	fontSize: 25,
																	alignSelf: 'center',
																	fontWeight: 200,
																}}
															/>
														</Hidden>
														<Hidden from='md'>
															<KeyboardArrowDownRoundedIcon
																sx={{
																	color: '#AFAFAF',
																	fontSize: 20,
																	alignSelf: 'center',
																	fontWeight: 200,
																}}
															/>
														</Hidden>
													</HStack>
												</Pressable>
											);
										}}
									>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'0px'}
											borderTopColor='#afafaf'
											onPress={() => setScale('Tonic')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'Tonic'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313t' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setScale('Supertonic')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'Supertonic'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setScale('Mediant')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'Mediant'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setScale('Subdominant')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'Subdominant'}
											</Heading>
										</Menu.Item>
										<Menu.Item
											bg={'#141313'}
											m={0}
											p={0}
											_focus={{ bg: '#141313' }}
											_hover={{ bg: '#141313' }}
											_pressed={{ bg: '#141313' }}
											borderTopWidth={'1px'}
											borderTopColor='#afafaf'
											onPress={() => setScale('Leading note')}
										>
											<Heading
												color={'#afafaf'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{'Leading note'}
											</Heading>
										</Menu.Item>
									</Menu>
								</VStack>
							</HStack>
						</Box>
					</HStack>

					<Button
						size={'sm'}
						bg='#2EBA55'
						borderWidth={1}
						borderColor='#2EBA55'
						_text={{
							color: '#fff',
							fontSize: {
								lg: '18px',
								md: '16px',
								sm: '14px',
								base: '14px',
							},
						}}
						_hover={{
							bg: 'transparent',
							_text: {
								color: '#2EBA55',
							},
						}}
						w={{ lg: '20%', base: '30%' }}
						my={{ lg: 10, base: 5 }}
						rounded={'md'}
						alignSelf={'flex-end'}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: '#2EBA55' }}
						onPress={() => {
							onhandleEdit();
							//setUploadModal(false);
							// onUpload(files);
						}}
					>
						Finish Editing
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default EditFilePopup;
