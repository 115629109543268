import React from 'react';
import { Box, Divider, Heading, HStack, Image } from 'native-base';
import SideBar from '../../SideBar/SideBar';
import { ProfileHeaderIcon } from '../../Profile/ProfileHeader/ProfileHeaderIcon';
import { DashboardHeader } from '../../Headers';
import { TalentDashboard } from '../TalentDashboard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RightSideBar from '../../RightSideBar/RightSideBar';
import { DocumentsStatus } from './DocumentsStatus';

const TalentOpportunity = () => {
	return (
		<Box w={'100%'} bg={'#000201'}>
			<Box w={'100%'}>
				<HStack w={'100%'} flexGrow={1} flexWrap={'wrap'}>
					<Box w={{ xl: '100%', base: '100%' }}>
						<Box w={'95%'} alignSelf={'start'}>
							{/* <Heading py={3} color={'#fff'} fontSize={{ lg: "4xl", base: "2xl" }} fontWeight={'medium'} textAlign={'left'}>Talent Dashboard</Heading> */}
							{/* <HStack w={'40%'} py={3}>
                    <Box alignSelf={'center'}pr={4}>
                      <ArrowBackIcon sx={{color: '#fff'}} />
                    </Box>
                    <Box>
                      <Image
                        source={require("../../../assets/ForSale/song1.png")}
                        size={{ lg: "20", base: "10" }}
                        rounded={'lg'}
                      />
                    </Box>
                    <Box px={4}>
                      <Heading color={'#fff'} fontSize={{ lg: "2xl", base: "lg" }} fontWeight={'medium'} textAlign={'left'}>One Kiss</Heading>\
                      <Heading color={'#AfAfAf'} fontSize={{ lg: "lg", base: "sm" }} fontWeight={'normal'} textAlign={'left'} >Calvin Harris, hua lipa</Heading>
                    </Box>
                  </HStack> */}

							<TalentDashboard />
							{/* <DocumentsStatus/> */}
						</Box>
					</Box>
					{/* <Box
                w={{ xl: "30%", base: "100%" }}
                borderLeftWidth={{ lg: 1, md: 0, base: 0 }}
                borderColor={"#fff"}
              >
                <Box w={"90%"} alignSelf={"center"} pt={8}>
                  <RightSideBar />
                  <RightSideBar />
                  <RightSideBar />
                </Box>
              </Box> */}
				</HStack>
			</Box>

			{/* <HStack w={"100%"}>
        <Box
          display={{ lg: "block", base: "none" }}
          w={{ xl: "20%", base: "0%" }}
        >
          <SideBar />
        </Box>

        <Box
          borderLeftWidth={{ lg: 1, md: 0 }}
          borderColor={"#fff"}
          w={{ xl: "80%", base: "100%" }}
        >
          <DashboardHeader />
          <Divider my={1} />
       
        </Box>
      </HStack> */}
		</Box>
	);
};

export default TalentOpportunity;
