/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Link,
} from 'native-base';
import React from 'react';

import SearchIcon from '@mui/icons-material/Search';

function PulishingBanner() {
	return (
		<Box w={'100%'} bg={'#000'}>
			<Image
				zIndex={10}
				h={{
					'2xl': '900px',
					xl: '750px',
					lg: '550px',
					md: '450px',
					base: '500px',
				}}
				w={'100%'}
				bg={'#000'}
				opacity={0.5}
				resizeMode='cover'
				source={require('../../assets/Publishing/Banner.png')}
				alt='image'
			/>

			<Box
				zIndex={20}
				position={'absolute'}
				h={{
					'2xl': '900px',
					xl: '750px',
					lg: '550px',
					md: '450px',
					base: '450px',
				}}
				// py={{ xl: '20', base: '10' }}
				flex={1}
				w={'100%'}
				justifyContent={'center'}
			>
				<Box w={'80%'} alignSelf={'center'}>
					<Box w={{ xl: '55%', base: '100%' }} alignSelf={'flex-start'}>
						<Heading
							textTransform={'capitalize'}
							color={'#fff'}
							fontSize={{ xl: '56px', md: '40px', base: '24px' }}
							mt={5}
							fontFamily={'Poppins'}
							fontWeight={'extrabold'}
							textAlign={'left'}
						>
							Collect Your Global
						</Heading>
						<Heading
							textTransform={'capitalize'}
							color={'#fff'}
							fontSize={{ xl: '56px', md: '40px', base: '24px' }}
							mb={5}
							fontFamily={'Poppins'}
							fontWeight={'extrabold'}
							textAlign={'left'}
						>
							Publishing Royalties
						</Heading>
						{/* <Heading
              color={'#fff'}
              fontFamily={'Poppins'}
              fontSize={{ xl: '24px', base: '16px' }}
              fontWeight={'normal'}
              textAlign={'left'}
            >
              You're likely missing out on thousands of dollars in publishing
              royalties. You can collect your performance, mechanical, sync,
              print, and other publishing income through United Market. No
              matter the streaming service or country-- get the money you earned
              from your creativity now!
            </Heading> */}
						{/* <Button
              py={{ xl: 3, base: 1.5 }}
              my={{ xl: 10, base: 5 }}
              w={{ xl: '30%', base: '40%' }}
              alignSelf={{ xl: 'flex-start' }}
              bg={'#2EBB55'}
              borderWidth={1}
              borderColor={'#2EBB55'}
              _hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
              _pressed={{ bg: '#2EBB55' }}
              _text={{
                fontSize: { xl: 'xl', md: 'lg', base: 'md' },
                fontWeight: 'medium',
                textAlign: 'center',
                fontFamily: 'Poppins',
                color: '#FFF',
              }}
            >
              Apply Now
            </Button> */}
					</Box>
					<Link>
						<button
							data-tf-popup='F9YFcKqF'
							data-tf-iframe-props='title=Composition Collection'
							data-tf-medium='snippet'
							className='ApplyNowPublishing'
						>
							&nbsp;Apply Now
						</button>
					</Link>
					{/* <Button
            size={'sm'}
            bg={'#2EBB55'}
            borderWidth={1}
            borderColor='#2EBA55'
            my={{ xl: 10, base: 5 }}
            py={{ xl: 4, base: 2 }}
            _text={{
              color: '#FFF',
              fontSize: {
                xl: '24px',
                lg: '24px',
                md: '18px',

                base: '14px',
              },
              fontFamily: 'Poppins',
            }}
            _hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
            onPress={() => {}}
            w={{ xl: '25%', base: '35%' }}
            rounded={'md'}
            _focused={{ bg: '#2EBA55' }}
            _pressed={{ bg: '#2EBA55' }}
          >
            Apply Now
          </Button> */}
				</Box>
			</Box>
		</Box>
	);
}

export default PulishingBanner;
