/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Box, Divider, Heading, HStack, Image, Text } from "native-base";
import React from "react";
import { DashboardHeader } from "../../Headers";
import SideBar from "../../SideBar/SideBar";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ConnectMeTable from "./ConnectMeTable";

function ConnectMe() {
  return (
    <Box w={"100%"}>
      <Box w={"90%"} alignSelf={"center"} py={5}>
        <Heading
          my={5}
          fontSize={{ xl: "4xl", md: "3xl", base: "2xl" }}
          color={"#fff"}
          textTransform={"capitalize"}
          fontFamily={"poppins"}
        >
          Connect Me
        </Heading>
        <HStack my={3}>
          <Box>
            <StarRoundedIcon style={{ color: "#fff", fontSize: 35 }} />
          </Box>
          <Heading
            fontSize={{ xl: "3xl", md: "2xl", base: "xl" }}
            fontFamily={"Poppins"}
            fontWeight={"medium"}
            textAlign={"left"}
            textTransform={"capitalize"}
            color={"#fff"}
            px={2}
          >
            Favorites
          </Heading>
        </HStack>
        <ConnectMeTable />
      </Box>
    </Box>
  );
}

export default ConnectMe;
