import React from 'react';

function EditProfile() {
	return (
		<svg
			width='29'
			height='29'
			viewBox='0 0 29 29'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='14.8365' cy='14.6549' r='13.8776' fill='#2EBB55' />
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M12.9408 20.4565L19.2915 12.2438C19.6367 11.801 19.7594 11.2889 19.6443 10.7676C19.5446 10.2936 19.2532 9.84298 18.816 9.50111L17.7499 8.6542C16.8218 7.91607 15.6713 7.99377 15.0117 8.84068L14.2984 9.76606C14.2064 9.88183 14.2294 10.0528 14.3444 10.146C14.3444 10.146 16.1469 11.5912 16.1852 11.6223C16.3079 11.7388 16.4 11.8942 16.423 12.0807C16.4613 12.4459 16.2082 12.7877 15.8324 12.8343C15.656 12.8577 15.4872 12.8033 15.3645 12.7023L13.47 11.1949C13.378 11.1258 13.2399 11.1405 13.1633 11.2338L8.661 17.0611C8.36954 17.4263 8.26983 17.9002 8.36954 18.3587L8.94479 20.8528C8.97547 20.9849 9.09052 21.0781 9.22858 21.0781L11.7597 21.047C12.2198 21.0392 12.6494 20.8295 12.9408 20.4565ZM16.4849 19.6798H20.6121C21.0147 19.6798 21.3422 20.0115 21.3422 20.4194C21.3422 20.8281 21.0147 21.1591 20.6121 21.1591H16.4849C16.0822 21.1591 15.7547 20.8281 15.7547 20.4194C15.7547 20.0115 16.0822 19.6798 16.4849 19.6798Z'
				fill='white'
			/>
		</svg>
	);
}

export default EditProfile;
