import React from 'react';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { Box, Checkbox, HStack, Image, Text, VStack } from 'native-base';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

const TwoLineChart = (props) => {
	const { options, data } = props;

	// const data = {
	// 	labels: lables,
	// 	position: 'top',

	// 	datasets: [
	// 		{
	// 			data: [50, 70, 60, 50, 80, 70],

	// 			borderColor: '#2EBA55',
	// 			fill: false,
	// 			backgroundColor: 'rgba(46, 186, 85, 0.3)',
	// 			tension: 0.5,
	// 			borderWidth: 1,
	// 		},
	// 		{
	// 			data: [20, 50, 70, 60, 40],

	// 			borderColor: 'rgba(24, 160, 251, 1)',
	// 			fill: false,
	// 			backgroundColor: 'rgba(24, 160, 251, 1)',
	// 			tension: 0.5,
	// 			borderWidth: 1,
	// 		},
	// 	],
	// };

	return (
		<Box
			bg={'#1C1B1B'}
			borderRadius={10}
			p={5}
			// w={"500px"}
			w={'100%'}
		>
			<VStack>
				<HStack>
					{/* <Image
          variant="ghost"
          size={"5"}
          m={"5px"}
          // source={require("../../../assets/Icons/" + {dataObject.socialIcon})}
          // source={require(`../../assets/Icons${dataObject.socialIcon}`)}
          source={require(`../../../assets/Icons${dataObject.socialIcon}`)}
          alt="Spotify Content"
        /> */}
					<Text
						marginRight={5}
						paddingRight={10}
						fontSize={'18px'}
						fontWeight={'500'}
						mb={5}
						fontFamily={'poppins'}
						// marginBottom={20}
						color='#E3E3E3'
					>
						Spotify & Youtube Views
					</Text>
				</HStack>
				{/* <Text
          // marginRight={5}
          // paddingLeft={10}
          fontSize={"14px"}
          fontWeight={"500"}
          
          fontFamily={"poppins"}
         // marginBottom={20}
          color="#555555"
        >Spotify & TikTok Viewss</Text> */}
				<Line options={options} data={data} />

				<HStack space={8} mt={4} alignItems={'center'} justifyContent='center'>
					<Box>
						<HStack space={3} alignItems={'center'}>
							<Box
								width={3}
								height={3}
								borderRadius={'50%'}
								bg={'#2EBB55'}
							></Box>
							<Text
								color={'#AFAFAF'}
								fontFamily={'poppins'}
								textTransform={'uppercase'}
							>
								spotify
							</Text>
						</HStack>
					</Box>
					<Box>
						<HStack space={3} alignItems={'center'}>
							<Box
								width={3}
								height={3}
								borderRadius={'50%'}
								bg={'#18A0FB'}
							></Box>
							<Text
								color={'#AFAFAF'}
								fontFamily={'poppins'}
								textTransform={'uppercase'}
							>
								Youtube
							</Text>
						</HStack>
					</Box>
					{/* <Text w={"100%"}  textAlign={"right"} color={"#AFAFAF"} textTransform={"capitalize"} underline>see all</Text> */}

					{/* <Checkbox
          color={"#2EBA55"}
          backgroundColor={"#fff"}
          value="test"
          accessibilityLabel="Video checkbox"
          colorScheme={"#2EBA55"}
          defaultIsChecked
        />
        <Box
          w={"20px"}
          h={"10px"}
          marginBottom={"2px"}
          borderBottomStyle="line"
          borderBottomWidth={"2"}
          borderColor={"#2EBA55"}
        ></Box>
        <Text fontSize="12px" color="#ffff">
          Videos
        </Text>
        <Checkbox
          backgroundColor={"#fff"}
          colorScheme={"#2EBA55"}
          value="test"
          accessibilityLabel="Changes checkbox"
          defaultIsChecked
        />
        <Box
           w={"20px"}
          h={"10px"}
          //   py={'2px'}
          marginBottom={"2px"}
          borderBottomStyle="line"
          borderBottomWidth={"2"}
          borderColor={"#2EBA55"}
        >
        
        </Box>
        <Text fontSize="12px" color="#ffff">
          Changes
        </Text> */}
				</HStack>
			</VStack>
		</Box>
	);
};

export default TwoLineChart;
