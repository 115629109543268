import { Box, Button, Divider, HStack, Image, Text, VStack } from 'native-base'
import { useState } from 'react';

// import ChartsListComponent from "./ChartsListComponent";
// import items from "./data.json";
// import SelectMenu from "./SelectMenu";
// import RedImage from "../../assets/Icons/RedArrow.png";
 import GrayImage from "../../assets/Icons/GreenArrow.png";
// import { SvgXml } from "react-native-svg";
// export const RedClickablemage = () => {
//     return (
//       <Image
//         source={RedImage}
//         size="5"
//         alignSelf={"center"}
//         // color="emerald.500"
//       />
//     );
//   };
  
  export const GrayClickablemage = () => {
    return (
      <Image
        source={GrayImage}
        size="5"
        alignSelf={"center"}
        // color="emerald.500"
      />
    );
  };
function TabHeader(props) {
    // const [open, setOpen] = useState(true);
    const [iconItem, setItemIcon] = useState(false);
    // const { headingTitle, chartsHeadingTitle } = props;
  return (


        <HStack 
      //  w={"100%"} 
      justifyContent={"flex-end"}
        py={2}>

            <Button
              w={{ lg: "40px", md: "25px", sm: "16px", base: "18px" }}
              h={{ lg: "40px", md: "25px", sm: "16px", base: "18px" }}
              bg={"#1C1B1B"}
              rounded={"full"}
              mx={2}
              _hover={{ bg: "#2EBA55" }}
            >
              <Image
                source={require("../../assets/Icons/Notification.png")}
                size="6"
                mt="0.5"
              />
            </Button>
            <Button
            w={{ lg: "40px", md: "25px", sm: "16px", base: "18px" }}
            h={{ lg: "40px", md: "25px", sm: "16px", base: "18px" }}
              bg={"#1C1B1B"}
              rounded={"full"}
              mx={2}
              _hover={{ bg: "#2EBA55" }}
            >
              <Image
                source={require("../../assets/Icons/heart.png")}
                size="6"
                mt="0.5"
              />
            </Button>

            <Button
                     w={{ lg: "40px", md: "25px", sm: "16px", base: "18px" }}
                     h={{ lg: "40px", md: "25px", sm: "16px", base: "18px" }}
              bg={"#1C1B1B"}
              rounded={"full"}
              mx={2}
              mr={10}
              _hover={{ bg: "#2EBA55" }}
            >
              <Image
                source={require("../../assets/Icons/downloadIcon.png")}
                size="6"
                mt="0.5"
              />
            </Button>
        
</HStack>




       
 )
}

export default TabHeader;