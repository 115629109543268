import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Loader } from "../../../components/Loader";
import { Box, Divider, Heading, HStack, Image } from "native-base";
import SideBar from "../../SideBar/SideBar";
import { ProfileHeaderIcon } from "../../Profile/ProfileHeader/ProfileHeaderIcon";
import { DashboardHeader } from "../../Headers";
import { TalentDashboard } from "../TalentDashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RightSideBar from "../../RightSideBar/RightSideBar";
import TabHeader from "../../TDTabHeader/TabHeader";
import LinePlatformGraph from "./LinePlatformGraph";
import AirPlayInsightsGraph from "./AirPlayInsightsGraph";
import styles from "./styles.module.css";

const options = {
  opacity: 0.5,

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
        borderDash: [8, 2],
        color: "#fff",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
};
const TalentPlatformGraph = () => {
	const [graphSpotify, setGraphSpotify] = useState({});
	const [graphYoutube, setgraphYoutube] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [days, setDays] = useState('7');
	const [daysYoutube, setDaysYoutube] = useState('7');

	const handleChange = (event) => {
		setDays(event.target.value);
	};
	const handleChangeYoutube = (event) => {
		setDaysYoutube(event.target.value);
	};
	useEffect(() => {
		getSpotify();
	}, [days]);
	useEffect(() => {
		getYoutube();
	}, [daysYoutube]);
	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};
	const getChartData = (platform, label, data) => {
		switch (platform) {
			case 'spotify': {
				let obj = {
					labels: label,
					position: 'bottom',
					datasets: [
						{
							label: '',
							data: data,
							borderColor: '#2EBA55',
							backgroundColor: 'rgba(46, 186, 85, 0.3)',
						},
					],
				};
				return obj;
			}
			case 'youtube': {
				let obj = {
					labels: label,
					position: 'bottom',
					datasets: [
						{
							label: 'Youtube',
							data: data,
							borderColor: 'rgb(53, 162, 235)',
							backgroundColor: 'rgba(53, 162, 235, 0.5)',
						},
					],
				};
				return obj;
			}
			default:
				return '';
		}
	};

	const getSpotify = async () => {
		try {
			const date = new Date();
			if (days === '7') {
				const endDate = moment(date).format('YYYY-MM-DD');
				const startDate = moment(
					new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7)
				).format('YYYY-MM-DD');
			}
			if (days === '28') {
				const endDate = moment(date).format('YYYY-MM-DD');
				const startDate = moment(
					new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
				).format('YYYY-MM-DD');
			}
			if (days === '90') {
				const endDate = moment(date).format('YYYY-MM-DD');
				const startDate = moment(
					new Date(date.getFullYear(), date.getMonth(), date.getDate() - 90)
				).format('YYYY-MM-DD');
			}
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/song/7d534228-5165-11e9-9375-549f35161576/spotify/popularity?endDate=2021-07-01`
			);
			const response = await axios(config);
			const { items } = response.data;
			let label = items.map((item) => {
				return item.date.split('T')[0];
			});
			let data = items.map((item) => {
				return item.value;
			});
			let obj = getChartData('spotify', label, data);
			setGraphSpotify(obj);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log('spotify data', err);
		}
	};
	const getYoutube = async () => {
		try {
			const date = new Date();
			if (daysYoutube === '7') {
				const endDate = moment(date).format('YYYY-MM-DD');
				const startDate = moment(
					new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7)
				).format('YYYY-MM-DD');
			}
			if (daysYoutube === '28') {
				const endDate = moment(date).format('YYYY-MM-DD');
				const startDate = moment(
					new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
				).format('YYYY-MM-DD');
			}
			if (daysYoutube === '90') {
				const endDate = moment(date).format('YYYY-MM-DD');
				const startDate = moment(
					new Date(date.getFullYear(), date.getMonth(), date.getDate() - 90)
				).format('YYYY-MM-DD');
			}
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/song/7d534228-5165-11e9-9375-549f35161576/youtube/views?endDate=2021-07-01`
			);
			const response = await axios(config);
			const { items } = response.data;
			let label = items.map((item) => {
				return item.date.split('T')[0];
			});
			let data = items.map((item) => {
				let views = 0;
				item.videoPlots.map((val) => {
					views = views + val.value;
				});
				return views;
			});
			let obj = getChartData('youtube', label, data);

			setgraphYoutube(obj);
		} catch (err) {
			console.log('youtube data', err);
		}
	};

	useEffect(() => {
		getSpotify();
		getYoutube();
	}, []);
	return (
		<Box w={'100%'} bg={'#000201'}>
			<HStack w={'100%'}>
				<Box w={{ xl: '100%', base: '100%' }}>
					<Box w={'100%'}>
						<HStack w={'100%'} flexGrow={1} flexWrap={'wrap'}>
							<Box w={'100%'}>
								<Box w={'100%'} alignSelf={'center'}>
									{isLoading ? (
										<div className={styles.loaderDiv}>
											<Loader />
										</div>
									) : Object.keys(graphSpotify).length > 0 ? (
										<LinePlatformGraph
											title={'Spotify'}
											event={'This Week'}
											data={graphSpotify}
											options={options}
											days={days}
											handleChange={handleChange}
										/>
									) : (
										''
									)}
									{Object.keys(graphYoutube).length > 0 ? (
										<LinePlatformGraph
											title={'Youtube'}
											event={'This Week'}
											data={graphYoutube}
											daysYoutube={daysYoutube}
											options={options}
											handleChangeYoutube={handleChangeYoutube}
										/>
									) : (
										''
									)}
								</Box>
							</Box>
							<Box
								w={{ xl: '30%', base: '100%' }}
								borderLeftWidth={{ lg: 1, md: 0, base: 0 }}
								borderColor={'#fff'}
							>
								{/* <Box w={"90%"} alignSelf={"center"} pt={8}>
                  <RightSideBar />
                  <RightSideBar />
                  <RightSideBar />
                </Box> */}
              </Box>
            </HStack>
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};

export default TalentPlatformGraph;
