import { Box } from "@mui/material";
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Loader } from "../components/Loader";
import useAuth from "../context/AuthContext";

function ProtectedRoutes({ component: Component, ...rest }) {
  const { isAuthenticated, loading, loadUser, setLoading } = useAuth();

  React.useEffect(() => {
    if (isAuthenticated) {
        return <Outlet />
    }
    else {
      loadUser();
    }
  }, []);

  return loading ? (
    <Box
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader />
    </Box>
  ) : isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" />
  );
}

export default ProtectedRoutes;
