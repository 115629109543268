/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Link,
  Text,
} from 'native-base';
import file from '../../assets/Growth/file.png';

function AffiliateLink() {
  return (
    <Box w={'100%'}>
      <Heading
        my={5}
        fontSize={{ xl: '2xl', base: 'lg' }}
        color={'#fff'}
        textTransform={'capitalize'}
        fontFamily={'poppins'}
        fontWeight={'medium'}
      >
        My Affiliate Link
      </Heading>
      <HStack w={'100%'}>
        <Image source={file} size={5} alignSelf={'center'} mr={5} />
        <Link
          _text={{ color: '#2EBB55', fontSize: { xl: 'md', base: 'sm' } }}
          w={'85%'}
          overflow='hidden'
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          alignSelf={'center'}
        >
          'https://www.figma.com/file/QOy9PNZmQUmjDBGxPD3SqQ/Untitled?node-id=0%3A1
        </Link>
      </HStack>
      <Button
        my={8}
        size={'sm'}
        bg='#2EBA55'
        borderWidth={1}
        borderColor='#2EBA55'
        _text={{
          color: '#fff',
          fontSize: {
            lg: '18px',
            md: '16px',
            sm: '14px',
            base: '12px',
          },
        }}
        _hover={{
          bg: 'transparent',
          _text: {
            color: '#2EBA55',
          },
        }}
        w={{ lg: '15%', base: '30%' }}
        rounded={'md'}
        _focused={{ bg: '#2EBA55' }}
        _pressed={{ bg: '#2EBA55' }}
        onPress={() => {}}
      >
        SYNC CONTACT
      </Button>
    </Box>
  );
}

export default AffiliateLink;
