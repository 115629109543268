import { gql } from '@apollo/client';

export const ADDSUBSCRIPTIONPLAN = gql`
	mutation (
		$userToken: String!
		$priceId: String
		$howDidYouKnowText: String
		$howDidYouKnow: String
	) {
		attachPlan(
			userToken: $userToken
			priceId: $priceId
			howDidYouKnow: $howDidYouKnow
			howDidYouKnowText: $howDidYouKnowText
		) {
			success
			message
			accessToken
			refreshToken
			reactionAccessToken
			reactionRefreshToken
			marketplaceAccessToken
			marketplaceRefreshToken
			sessionId
			user {
				email
				profilePic
				userName
				roleId {
					_id
					roleName
				}
				accountType
				_id
			}
		}
	}
`;
