/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	CloseIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Menu,
	Modal,
	Pressable,
	ScrollView,
	Text,
	VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import { SearchFieldComponent } from '../../SearchField';
import { useNavigate } from 'react-router';
import Slider from '@mui/material/Slider';

import axios from 'axios';

export function ImportModal(props) {
	const { openModal, closeModal } = props;
	const [importAll, setImportAll] = useState([]);
	const [allState, setAllState] = useState(true);
	const [filterValues, setFilterValues] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [bmpValue, setBPMValue] = useState([20, 100]);
	const [selectedTags, setSelectedTags] = useState([]);
	const [searchedFiles, setSearchedFiles] = useState('');
	const [filterTags, setFilterTags] = useState(false);
	const artists = ['abc', 'vbsd', 'nsnsn', 'lkjhs', 'uioyt'];
	const navigate = useNavigate();
	const headerkey = `Bearer ${localStorage.getItem('token')}`;

	//   const handleChange = (event, newValue) => {
	//     setBPMValue(newValue);

	//     handleSearchFiles(searchedFiles, bmpValue[1], selectedTags);
	//   };
	//   const handleSearchFiles = (searchText, mbpm, addedTags) => {
	//     var data = {
	//       searchQuery: searchText,
	//       maxBPM: mbpm,
	//       tags: addedTags,
	//     };

	//     var config = {
	//       method: 'post',
	//       url: 'https://new.unitedmarket.com/file/searchallaudiofiles',
	//       headers: { Authorization: headerkey },
	//       data: data,
	//     };

	//     axios(config)
	//       .then(function (response) {
	//         if (response.data.files) {
	//           setAudioAll(response.data.files);
	//         } else {
	//           setAudioAll();
	//         }
	//       })

	//       .catch(function (error) {
	//         if (error?.response?.status === 404) {
	//           setAudioAll([]);
	//         }
	//         console.log(error);
	//       });
	//   };
	//   const getFilterValue = () => {
	//     var requestOptions = {
	//       method: 'GET',
	//       headers: { Authorization: headerkey },
	//       redirect: 'follow',
	//     };

	//     fetch('https://new.unitedmarket.com/file/getfiltervalues', requestOptions)
	//       .then((response) => response.json())
	//       .then((result) => {
	//         // if (!result?.auth) {
	//         // 	navigate('/signin');
	//         // 	localStorage.clear();
	//         // } else {
	//         setFilterValues(result.filterValues);
	//         let temp = [...bmpValue];
	//         temp[1] = result?.filterValues?.maxBPM;
	//         setBPMValue(temp);
	//         // }
	//       })
	//       .catch((error) => {
	//         if (error?.response?.status === 401) {
	//           setIsLoading(false);
	//           navigate('/signin');
	//           localStorage.clear();
	//         }
	//       });
	//   };
	//   const valuetext = (value) => {
	//     return `${value}`;
	//   };

	// const toggleTag = () => {
	//     setFilterTags(!filterTags);
	//   };

	//   const addFilterTag = (tag) => {
	//     setSelectedTags([...selectedTags, tag]);
	//   };

	//   const removeFilterTag = (removedTag) => {
	//     setSelectedTags(selectedTags.filter((tag) => tag !== removedTag));
	//   };
	//   const allTags = filterValues.tags;

	const onImportSearch = (e) => {
		e.length <= 1 ? fetchImportData() : handleImportSearch(e);
	};
	const handleImportSearch = (searchText) => {
		// setIsLoading(true);
		var data = {
			searchQuery: searchText,
			maxBPM: filterValues?.maxBPM,
			tags: [],
		};

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/searchallaudiofiles`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				if (response.data.files) {
					setImportAll(response.data.files);

					// setIsLoading(false);
				} else {
					setImportAll();
					// setIsLoading(false);
				}
			})

			.catch(function (error) {
				if (error?.response?.status === 401) {
					// setIsLoading(false);
					navigate('/signin');
					localStorage.clear();
				}
				if (error?.response?.status === 404) {
					setImportAll([]);
					// setIsLoading(false);
				}
				// setIsLoading(false);
				console.log(error);
			});
	};
	const fetchImportData = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};
		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallaudiofiles`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.auth == false) {
					navigate('/signin');
					localStorage.clear();
				} else {
					console.log('data', data);
					setImportAll(data.files);
				}
			})
			.catch((error) => {
				console.log('error', error);
				if (error?.response?.status === 401) {
					navigate('/signin');
					localStorage.clear();
				}
			});
	};

	function Detailsheading(props) {
		const { name } = props;
		return (
			<Box
				mx={2}
				my={1}
				borderWidth={'1'}
				borderColor={'#AFAFAF'}
				rounded={'md'}
			>
				<Heading
					p={1}
					fontSize={{ xl: 'sm', base: 'sm' }}
					fontWeight={'normal'}
					color={'#afafaf'}
					fontFamily={'poppins'}
				>
					{name}
				</Heading>
			</Box>
		);
	}

	useEffect(() => {
		fetchImportData();
	}, []);
	return (
		<Modal
			isOpen={openModal}
			onClose={closeModal}
			w={{ xl: '50%', lg: '50%', base: '90%' }}
			bg={'#141313'}
			h={'auto'}
			shadow={7}
			rounded={'lg'}
			alignSelf={'center'}
			justifyContent={'flex-start'}
			mt={80}
			//   borderColor={'#fff'}
			//   borderWidth={1}
		>
			<Box w={'90%'} bg={'#141313'} rounded={'lg'}>
				<HStack
					alignSelf={'center'}
					justifyContent={'space-between'}
					my={5}
					w={'100%'}
				>
					<Heading
						px={5}
						color={'#fff'}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						fontSize={{ lg: '3xl', base: 'xl' }}
					>
						Import Audio Files from your library
					</Heading>

					<Button
						variant={'ghost'}
						px={5}
						// py={3}
						position={'absolute'}
						top={0}
						right={0}
						justifyContent={'flex-end'}
						_hover={{ bg: 'transparent' }}
						_pressed={{ bg: 'transparent' }}
						onPress={closeModal}
						alignItems={'center'}
					>
						<CloseIcon sx={{ color: '#AFAFAF' }} />
					</Button>
				</HStack>
				<HStack
					alignSelf={'center'}
					w={'100%'}
					justifyContent={'space-between'}
					my={5}
				>
					<Box w={allState ? '85%' : '100%'}>
						<SearchFieldComponent
							backgroundColor={'#000'}
							onChangeSearch={onImportSearch}
							placeholder='Search by Name, BPM, key'
							modal={true}
							// placeholder='Search by Name'
							alignSelf={'center'}
						/>
					</Box>
					{allState && (
						<Menu
							rounded={'lg'}
							borderColor={'#fff'}
							borderWidth={1}
							alignSelf={'center'}
							color={'#afafaf'}
							fontSize={{ md: '16px' }}
							mt={2}
							w={'20vw'}
							_text={{
								color: '#afafaf',
							}}
							background={'#1C1B1B'}
							placement={'bottom'}
							trigger={(triggerProps) => {
								return (
									<Pressable
										bg={'#1C1B1B'}
										rounded={'sm'}
										_hover={{ bg: '#2EBA55' }}
										_focused={{ bg: '#2EBA55' }}
										_pressed={{ bg: 'transparent' }}
										accessibilityLabel='More options menu'
										{...triggerProps}
									>
										<HStack
											size={'sm'}
											h='full'
											rounded={'sm'}
											fontSize={{
												lg: '16px',
												md: '16px',
												sm: '12px',
											}}
											_hover={{ bg: '#2EBA55' }}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: 'transparent' }}
										>
											<Image
												source={require('../../../assets/Icons/filter.png')}
												size='8'
												alt='filter'
												alignSelf={'center'}
												mx={3.5}
											/>
										</HStack>
									</Pressable>
								);
							}}
						>
							{/* <Menu.Item
              bg={'#1C1B1B'}
              m={0}
              pb={2}
              w={'100%'}
              flexGrow={1}
              flexWrap={'wrap'}
              flexBasis={'max-content'}
              _focus={{
                bg: '#1C1B1B',
              }}
              _hover={{
                bg: '#1C1B1B',
              }}
              _pressed={{
                bg: '#1C1B1B',
              }}
              _text={{
                color: '#fff',
                textAlign: 'center',
                margin: 'auto',
                textDecoration: 'none',
                py: 2,
              }}
              borderBottomWidth='1px'
              borderBottomColor='#afafaf'
            >
              <HStack flexWrap={'wrap'} flexGrow={1} w={'100%'}>
                <Heading
                  color={'#fff'}
                  textAlign={'center'}
                  py={2}
                  pr={2}
                  fontFamily={'Poppins'}
                  fontWeight={'normal'}
                  fontSize={'md'}
                >
                  Tags:
                </Heading>

                {allTags &&
                  allTags.map((tag, index) => (
                    <HStack
                      bg={'transparent'}
                      borderColor={'white'}
                      rounded='md'
                      borderWidth={1}
                      pl={1}
                      alignSelf={'center'}
                      m={1}
                      color={'#afafaf'}
                    >
                      {tag}

                      <Button
                        h={'0.5'}
                        bg={'#1C1B1B'}
                        alignSelf={'center'}
                        onPress={() => {
                          selectedTags.includes(tag)
                            ? removeFilterTag(tag)
                            : addFilterTag(tag);
                          toggleTag();
                        }}
                        _hover={{ bg: '#1C1B1B' }}
                        _focused={{ bg: '#1C1B1B' }}
                        _pressed={{ bg: '#1C1B1B' }}
                      >
                        {selectedTags.includes(tag) ? 'x' : '+'}
                      </Button>
                    </HStack>
                  ))}
              </HStack>
            </Menu.Item> 
             <Menu.Item
              bg={'#1C1B1B'}
              m={0}
              p={0}
              flexWrap={'wrap'}
              flexBasis={'max-content'}
              _focus={{
                bg: '#1C1B1B',
              }}
              _hover={{
                bg: '#1C1B1B',
              }}
              _pressed={{
                bg: '#1C1B1B',
              }}
              _text={{
                color: '#fff',
                textAlign: 'center',
                margin: 'auto',
                textDecoration: 'none',
                py: 2,
              }}
              borderBottomWidth='1px'
              borderBottomColor='#afafaf'
            >
              <Heading
                color={'#fff'}
                textAlign={'center'}
                py={2}
                pr={2}
                fontFamily={'Poppins'}
                fontWeight={'normal'}
                fontSize={'md'}
              >
                BMP
              </Heading>
              <Slider
                sx={{
                  color: '#2EBA55',
                }}
                getAriaLabel={() => 'BPM range'}
                value={bmpValue}
                onChange={handleChange}
                valueLabelDisplay='auto'
                getAriaValueText={valuetext}
                min={10}
                max={filterValues.maxBPM}
              />
            </Menu.Item>*/}
							<Menu.Item
								bg={'#1C1B1B'}
								m={0}
								p={0}
								w={'100%'}
								flexGrow={1}
								flexWrap={'wrap'}
								flexBasis={'max-content'}
								_focus={{
									bg: '#1C1B1B',
								}}
								_hover={{
									bg: '#1C1B1B',
								}}
								_pressed={{
									bg: '#1C1B1B',
								}}
								_text={{
									color: '#fff',
									textAlign: 'center',
									margin: 'auto',
									textDecoration: 'none',
									py: 2,
								}}
								borderBottomWidth='1px'
								borderBottomColor='#afafaf'
							>
								<HStack flexWrap={'wrap'} flexGrow={1} w={'100%'}>
									<Heading
										color={'#fff'}
										textAlign={'center'}
										py={2}
										pr={2}
										fontFamily={'Poppins'}
										fontWeight={'normal'}
										fontSize={'md'}
									>
										Artists:
									</Heading>
									{artists &&
										artists.map((artist, index) => (
											<HStack
												bg={'transparent'}
												borderColor={'white'}
												rounded='md'
												borderWidth={1}
												alignSelf={'center'}
												m={1}
												color={'#afafaf'}
											>
												<Button
													h={'0.5'}
													px={1}
													bg={'#1C1B1B'}
													alignSelf={'center'}
													// onPress={() => {addTag(artist)} }
													_hover={{ bg: '#1C1B1B' }}
													_focused={{ bg: '#1C1B1B' }}
													_pressed={{ bg: '#1C1B1B' }}
												>
													{artist}
												</Button>
											</HStack>
										))}
								</HStack>
							</Menu.Item>

							<Menu.Item
								bg={'#1C1B1B'}
								m={0}
								p={0}
								flexWrap={'wrap'}
								flexBasis={'max-content'}
								_focus={{
									bg: '#1C1B1B',
								}}
								_hover={{
									bg: '#1C1B1B',
								}}
								_pressed={{
									bg: '#1C1B1B',
								}}
								_text={{
									color: '#fff',
									textAlign: 'center',
									margin: 'auto',
									textDecoration: 'none',
									py: 2,
								}}
								borderBottomWidth='1px'
								borderBottomColor='#afafaf'
							>
								<Heading
									color={'#fff'}
									textAlign={'center'}
									py={2}
									pr={2}
									fontFamily={'Poppins'}
									fontWeight={'normal'}
									fontSize={'md'}
								>
									Key
								</Heading>
								<Input
									h={'12'}
									my={2}
									variant={'unstyled'}
									_important={{
										backgroundColor: 'transparent',
										borderColor: 'ransparent',
									}}
									fontSize={{ lg: '18px', md: '16px', sm: '12px' }}
									color={'#afafaf'}
								></Input>
							</Menu.Item>
							<Menu.Item
								bg={'#1C1B1B'}
								m={0}
								p={0}
								flexWrap={'wrap'}
								flexBasis={'max-content'}
								_focus={{
									bg: '#1C1B1B',
								}}
								_hover={{
									bg: '#1C1B1B',
								}}
								_pressed={{
									bg: '#1C1B1B',
								}}
								_text={{
									color: '#fff',
									textAlign: 'center',
									margin: 'auto',
									textDecoration: 'none',
									py: 2,
								}}
							>
								<Heading
									color={'#fff'}
									textAlign={'center'}
									py={2}
									pr={2}
									fontFamily={'Poppins'}
									fontWeight={'normal'}
									fontSize={'md'}
								>
									Scale
								</Heading>
								<Input
									h={'12'}
									my={2}
									variant={'unstyled'}
									_important={{
										backgroundColor: 'transparent',
										borderColor: 'ransparent',
									}}
									fontSize={{ lg: '18px', md: '16px', sm: '12px' }}
									color={'#afafaf'}
								></Input>
							</Menu.Item>
						</Menu>
					)}
				</HStack>
				<HStack w={'100%'} alignSelf={'center'}>
					<Button
						backgroundColor={'#141313'}
						onPress={() => {
							setAllState(true);
						}}
						px={5}
						_hover={{
							bg: '#141313',
							borderBottomStyle: 'line',
							borderBottomWidth: '4',
							borderColor: '#2EBA55',
						}}
						_focus={{
							bg: '#141313',
							borderBottomStyle: 'line',
							borderBottomWidth: '4',
							borderColor: '#2EBA55',
						}}
						borderBottomStyle='line'
						borderBottomWidth='4'
						borderColor={allState ? '#2EBA55' : '#141313'}
					>
						<Text
							fontSize={{ lg: '20px', md: '18px', sm: '16px' }}
							color={'#AFAFAF'}
							fontFamily={'Poppins'}
							alignSelf={'center'}
						>
							All
						</Text>
					</Button>

					<Button
						backgroundColor={'#141313'}
						onPress={() => {
							setAllState(false);
							//   handleRecent();
						}}
						px={5}
						_hover={{
							bg: '#141313',
							borderBottomStyle: 'line',
							borderBottomWidth: '4',
							borderColor: '#2EBA55',
						}}
						_focus={{
							bg: '#141313',
							borderBottomStyle: 'line',
							borderBottomWidth: '4',
							borderColor: '#2EBA55',
						}}
						borderBottomStyle='line'
						borderBottomWidth='4'
						borderColor={allState ? '#141313' : '#2EBA55'}
					>
						<Text
							fontSize={{ lg: '20px', md: '18px', sm: '16px' }}
							color={'#AFAFAF'}
							fontFamily={'Poppins'}
							alignSelf={'center'}
						>
							Recents
						</Text>
					</Button>
				</HStack>
				<Divider alignSelf={'center'} />
			</Box>
			{/* <Modal.Content backgroundColor={'#141313'} w={'90%'}> */}
			{/* <Modal.Body
        backgroundColor={'#141313'}
        w={'90%'}
        borderColor={'#fff'}
        borderWidth={1}
      > */}
			<Box backgroundColor={'#141313'} w={'100%'} roundedBottom={'lg'}>
				<ScrollView w={'100%'} h={'100vh'} showsVerticalScrollIndicator={false}>
					<VStack w={'90%'} px='1' py='3' alignSelf={'center'}>
						{importAll && importAll.length > 0 ? (
							importAll.map((importAllObj, index) => (
								<HStack
									key={index}
									w={'100%'}
									justifyContent={'space-between'}
									backgroundColor={'#141313'}
									py={2}
								>
									{importAllObj.file.audioIconUrl ? (
										<Image
											w={'28%'}
											height={{ lg: '120px', md: '100px', base: '70px' }}
											rounded={'md'}
											width={{ lg: '110px', md: '85px', sm: '55px' }}
											alignSelf={'center'}
											source={importAllObj.file.audioIconUrl}
											//  size={["8", "10", "12"]}
											mt='0.5'
											alt='icon'
										/>
									) : (
										<Image
											w={'28%'}
											height={{ lg: '120px', md: '100px', base: '70px' }}
											rounded={'md'}
											width={{ lg: '110/px', md: '85px', sm: '55px' }}
											alignSelf={'center'}
											source={require('../../../assets/Images/1cover.png')}
											alt='User Profile Pic'
										/>
									)}
									<Box w={'55%'} alignSelf={'center'}>
										<Text
											fontSize={{
												lg: '18px',
												md: '16px',
												sm: '14px',
												base: 'sm',
											}}
											fontWeight={200}
											color='white'
											fontFamily={'Poppins'}
											py={5}
										>
											{importAllObj.file.fileName}
										</Text>
										<HStack
											flexWrap={'wrap'}
											flexGrow={1}
											alignSelf={'flex-start'}
										>
											{importAllObj.file.BPM != null && (
												<Detailsheading name={importAllObj.file.BPM + ' bpm'} />
											)}
											{importAllObj.file.fileKey != null && (
												<Detailsheading name={importAllObj.file.fileKey} />
											)}
											{importAllObj.file.scale != null && (
												<Detailsheading name={importAllObj.file.scale} />
											)}

											{importAllObj.file.tags
												? importAllObj.file.tags.map((tag, index) => (
														<Detailsheading name={'# ' + tag} />
												  ))
												: null}
										</HStack>
									</Box>

									<Button
										size={'sm'}
										bg='#2EBA55'
										borderWidth={1}
										borderColor='#2EBA55'
										_text={{
											color: '#fff',
											fontSize: {
												lg: '18px',
												md: '16px',
												sm: '14px',
												base: 'xs',
											},
											fontFamily: 'Poppins',
										}}
										_hover={{
											bg: 'transparent',
											_text: {
												color: '#2EBA55',
											},
										}}
										w={'15%'}
										rounded={'md'}
										alignSelf={'center'}
										_focused={{ bg: '#2EBA55' }}
										_pressed={{ bg: '#2EBA55' }}
									>
										Import
									</Button>
								</HStack>
							))
						) : (
							<>
								<HStack>
									<Text
										fontSize={{ lg: '18px', sm: '14px', base: '16px' }}
										py={1}
										color='#afafaf'
										fontWeight={'semibold'}
									>
										No Files Found
									</Text>
								</HStack>
							</>
						)}
					</VStack>
				</ScrollView>
			</Box>
			{/* </Modal.Body> */}
			{/* </Modal.Content> */}
		</Modal>
	);
}
