import { color, textTransform } from "@mui/system";
import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import staticArtist from "../../assets/staticWebsite/staticArtist.png";
import Layout from "../../components/StaticWebSite/LandingPagesComp/Layout";

const Artist = (props) => {
  
  const data = [
    {
      id: 1,
      des: "Artists can now take control of their careers on the United Market platform. Collaboration is essential when creating a song, and finding the right beat is critical. That's why you have the ability to search by genre and sound when looking for the perfect beat to go with your vocals.",
    },
    {
      id: 2,
      des: "Don’t forget, building lasting relationships with producers can help you reach your next career level. ",
    },
    {
      id: 3,
      des: "Find your lifelong producer exclusively on United Market!",
    },
    // {
    //   id: 4,
    //   des: " United Market is your all-in-one collaboration and project management platform. Streamline your current clientele directly to your U.M profile and take advantage of everything we offer!",
    // },
  ];

  return (
    <Box w={"100%"} bg={"#000201"}>
        <Layout
        title={'ARTISTS'}
        subtitle={'UNITED MARKET FOR ARTISTS'}
        desc={'Find the perfect beat for your next hit single from a hot producers anywhere in the world'}
        image={staticArtist}
        content={data}
        />

    </Box>
  );
};

export default Artist;