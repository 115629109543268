import React from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
} from "native-base";
import cover1 from '../../../assets/ForSale/song1.png'
import cover2 from '../../../assets/ForSale/song2.png'
import cover3 from '../../../assets/ForSale/song3.png'
import cover4 from '../../../assets/ForSale/song4.png'
import ButtonComponent from "../../ButtonComponent";
import ProfileTitle from "../ProfileTitle";
import "./Forsale.css"
export const Forsale = () => {

  const data1 = [

    {
      id: 1,
      image: cover1,
      name: "The Beat Bounce",
      rating: "1.9k",
      price: "$30"
    },
    {
      id: 1,
      image: cover2,
      name: "The Beat Bounce",
      rating: "1.9k",
      price: "$30"
    },
    {
      id: 1,
      image: cover3,
      name: "The Beat Bounce",
      rating: "1.9k",
      price: "$30"
    },

  ];

  const data2 = [

    {
      id: 1,
      image: cover3,
      name: "The Beat Bounce",
      rating: "1.9k",
      price: "$30"
    },
    {
      id: 1,
      image: cover1,
      name: "The Beat Bounce",
      rating: "1.9k",
      price: "$30"
    },
    {
      id: 1,
      image: cover4,
      name: "The Beat Bounce",
      rating: "1.9k",
      price: "$30"
    },

  ];

  return (

    <div  className="forsale"><Box

    mt={{lg:0,md:"800px",sm:"720px",base:"730px"}}
 w={"100%"}
      bg={"#000201"}
      // w={{ lg: "65%", base: "100%" }}


alignSelf={'center'}
      // borderWidth={1}
      // borderColor={"#fff"}
    >
      <HStack
   width={"100%"}
        justifyContent={"space-between"}
        py={10}
        pl={{lg:4}}
        // alignSelf={"center"}
      >
        <ButtonComponent backgroundColor={"tranparent"} text={"send beats"} flag={true} textProperties={{fontSize:{ md: "1.2rem", base: "0.8rem" },
            fontWeight:"medium",
            color:"#2EBB55",
            textTransform:"capitalize",
            textAlign:"center" }}
                 w={"40%"}  paddingYMd={"15px"}  paddingYSm={"10px"}    bordercolor={"#2EBB55"}     borderwidth={"1"}
          borderColor={"#2EBB55"}  />
                  <ButtonComponent backgroundColor={"tranparent"} text={"send loops"} flag={true} textProperties={{fontSize:{ md: "1.2rem", base: "0.8rem" },
            fontWeight:"medium",
            color:"#2EBB55",
            textTransform:"capitalize",
            
            textAlign:"center" }}
                 w={"40%"}  paddingYMd={"15px"}  paddingYSm={"10px"}    bordercolor={"#2EBB55"}     borderwidth={"1"}
          borderColor={"#2EBB55"}  />
        {/* <Button
          variant={"ghost"}
          w={"45%"}
          borderWidth={"2"}
          borderColor={"#2EBB55"}
        >
          <Heading
            fontSize={{lg:"lg",md:"md",base:"sm"}}
            fontWeight={"medium"}
            color={"#2EBB55"}
            textAlign={"center"}
          >
            Send Beats
          </Heading>
        </Button> */}
  
      </HStack>
      <VStack w={"100%"} pl={{lg:4}} mb={4} alignSelf={"center"}>
        <HStack justifyContent={"space-between"} my={5} alignItems={"center"} >

        <ProfileTitle fontSize={{md:"2xl",base:"lg"}}   text="beats for sale"/>
          {/* <Heading
            color={"#E3E3E3"}
            fontSize={{md:"2xl",base:"lg"}}
            fontWeight={"medium"}
            textAlign={"center"}
          >
            Beats Fors Sale
          </Heading> */}
          <Box>
            <Heading
              color={"#AFAFAF"}
              fontSize={{lg:"lg",md:"md",base:"xs"}}
              fontWeight={"normal"}
              p={0}
              textAlign={"center"}
            >
              See More
            </Heading>
            <Divider p={0} />
          </Box>
        </HStack>
        <HStack flexGrow={1} flexWrap={"wrap"}   space={{base:"12px"}} justifyContent={"space-between"} >
        {data1.map((item) => (
        <Box mb={{lg:0,base:5}} w={{lg:"30%",sm:"30%",base:"100%"}}>
          <VStack space={2}> <Image
            alignSelf={"flex-start"}
            h={{lg:"230px",md:"270px",sm:"220px",base:"250px"}}
            w={"100%"}
            resizeMode="cover"
            borderRadius={5}
            source={item.image}
            alt="image"
            // mb={4}
          />
          <Heading

            color={"#AFAFAF"}
            fontSize={{md:"lg",sm:"md",base:"md"}}
            fontWeight={"normal"}
       
            pt={1}
            alignSelf={'flex-start'}
            textAlign={"center"}
          >
            The Beat Bounce
          </Heading>
          <HStack 
          w={"98%"}  
          py={2}>
            <HStack w={"60%"} space={2} >
              <Image
       
                alignSelf={"center"}
             size={{md:"30px",base:"25px"}}
                resizeMode="contain"
                source={require("../../../assets/ForSale/fire.png")}
                alt="image"
              />
              <Heading

                alignSelf={"center"}
                color={"#AFAFAF"}
                fontSize={{md:"xl",sm:"lg",base:"md"}}
                fontWeight={"normal"}
                p={0}
                
                textAlign={"center"}
              >
                1.9k
              </Heading>

              <Image
                alignSelf={"center"}
                size={{md:"30px",base:"25px"}}
                resizeMode="contain"
                source={require("../../../assets/ForSale/share.png")}
                alt="image"
              />
            </HStack>
            <Heading

              w={"40%"}
          
              color={"#fff"}
              fontSize={{md:"3xl",base:"xl"}}
              fontWeight={"normal"}
       textAlign={"right"}
           
            
            >
              $30
            </Heading>
          </HStack></VStack>
         
        </Box>))}
        </HStack>
      </VStack>
      <VStack w={"100%"}  pl={{lg:4}} mb={4}  alignSelf={"center"}>
        <HStack w={"100%"} justifyContent={"space-between"} alignItems={"center"} py={2}>
        <ProfileTitle fontSize={{md:"2xl",base:"lg"}}   text="kits for sale"/>
          <Box>
            <Heading
              color={"#AFAFAF"}
              fontSize={{lg:"lg",md:"md",base:"xs"}}
              fontWeight={"normal"}
              p={0}
              textAlign={"center"}
            >
              See More
            </Heading>
            <Divider p={0} />
          </Box>
        </HStack>
        <HStack w={"100%"} flexGrow={1} flexWrap={"wrap"} justifyContent={"space-between"}  space={{base:"10px"}} >
        {data2.map((item) => (
        <Box w={{lg:"30%",sm:"30%",base:"100%"}} my={2}>
          <VStack space={2}> <Image
            alignSelf={"flex-start"}
            h={{lg:"230px",md:"270px",sm:"240px",base:"250px"}}
          w={"100%"}
            resizeMode="cover"
            source={item.image}
            alt="image"
            mb={4}
          />
          <Heading

            color={"#AFAFAF"}
            fontSize={{md:"lg",sm:"md",base:"md"}}
            fontWeight={"normal"}
            p={0}
            pt={1}
            alignSelf={'flex-start'}
            textAlign={"center"}
          >
            The Beat Bounce
          </Heading>
          <HStack w={"100%"} 
          py={2}>
            <HStack w={"60%"} >
              <Image
                alignSelf={"center"}
                size={{md:"30px",base:"25px"}}
                resizeMode="contain"
                source={require("../../../assets/ForSale/fire.png")}
                alt="image"
              />
              <Heading

                alignSelf={"center"}
                color={"#AFAFAF"}
                fontSize={{md:"xl",sm:"lg",base:"md"}}
                fontWeight={"normal"}
                p={0}
                px={2}
                textAlign={"center"}
              >
                1.9k
              </Heading>

              <Image
                alignSelf={"center"}
                size={{md:"30px",base:"25px"}}
                resizeMode="contain"
                source={require("../../../assets/ForSale/share.png")}
                alt="image"
              />
            </HStack>
            <Heading

              w={"40%"}
            
              color={"#fff"}
              fontSize={{md:"3xl",base:"xl"}}
              fontWeight={"normal"}
              p={0}
              textAlign={"right"}
            >
              $30
            </Heading>
          </HStack></VStack>
         
        </Box>))}
        </HStack>
      </VStack>
    </Box></div>
    
  );
};
