import {
	Button,
	Divider,
	HStack,
	Image,
	Input,
	Text,
	VStack,
	Box,
	Heading,
	Menu,
	Center,
	Checkbox,
	Pressable,
	Select,
	CheckIcon,
	ScrollView,
	Modal,
} from 'native-base';
import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import axios from 'axios';
import Slider from '@mui/material/Slider';

import { SearchFieldComponent } from '../SearchField';
import CursorPlugin from 'wavesurfer.js/src/plugin/cursor';
import { FaPause, FaPlay } from 'react-icons/fa';
import ViewAccess from './ViewBeatPack';
import ViewFiles from './ViewFiles';
import { Loader } from '../Loader';
import { WelcomePopup } from '../PopUp/WelcomePopup';
import { DeletePopup } from '../PopUp/DeletePopup';
import { SendPackPopup } from '../PopUp/SendPackPopup';
import { SentPopup } from '../PopUp/SentPopup';
import {
	getUserPaginated,
	searchUsers,
} from '../../Graphql_services/UsersMutation';

import coverImg from '../../assets/Images/1cover.png';
import Delete from '../Icons/Delete';
import Eye from '../Icons/Eye';
import Share from '../Icons/Share';
import styles from './styles.module.css';

const BeatPack = (props) => {
	const { data, getBeatPacks, onDelete, toggleSecondaryBar } = props;

	const myRef = useRef();
	const [isLoading, setIsLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [usersAll, setUsersAll] = useState([]);
	const [file, setFile] = useState();
	const [activeURL, setActiveURL] = useState('');
	const [audioAll, setAudioAll] = useState([]);
	const [packAll, setPackAll] = useState([]);
	const [showBP, setShowBP] = useState([]);
	const [addedFiles, setAddedFiles] = useState([]);
	const [tags, setTags] = useState([]);
	const [added, setAdded] = useState(false);
	const [showAccess, setShowAccess] = useState(false);
	const [showFiles, setShowFiles] = useState(false);
	const [viewObj, setViewObj] = useState();
	const [fileId, setFileId] = useState();
	const [bpId, setbpId] = useState();
	const [bpFiles, setBpFiles] = useState([]);
	const [bplink, setLink] = useState('');
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedAudio, setSelectedAudio] = useState(null);
	const [selectedCover, setSelectedCover] = useState(null);
	const [coverURL, setCoverUrl] = useState();
	const [bpm, setBPM] = useState('');
	const [scale, setScale] = useState('');
	const [k, setKey] = useState('');
	const [isCreated, setIsCreated] = useState(false);

	const [isDisable, setIsDisable] = useState(true);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteBP, setDeleteBP] = useState(false);
	const [searchUser, setSearchUser] = useState('');

	const [isPlay, setIsPlay] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [showWelcomePopup, setShowWelcomePopup] = useState(true);
	const [showCollaboratorPopup, setShowCollaboratorPopup] = useState(false);
	const [showFilePopup, setShowFilePopup] = useState(false);

	const startAudio = () => {
		myRef?.current?.play();
		setPlaying(true);
	};

	const pauseAudio = () => {
		myRef?.current?.pause();
		setPlaying(false);
	};

	useEffect(() => {
		if (activeURL && isPlay) {
			startAudio();
		} else {
			pauseAudio();
		}
	}, [activeURL, isPlay]);

	const [getProfileData] = useLazyQuery(getUserPaginated, {
		context: { clientName: 'user' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (data.getAllUsersByUser.success) {
				setUsersAll(data.getAllUsersByUser.users);
				setUsers(data.getAllUsersByUser.users);
			} else {
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});
	const [searchedUserResult] = useLazyQuery(searchUsers, {
		context: { clientName: 'user' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (data.searchUsers.success) {
				setUsers(data.searchUsers.users);
			} else {
				setUsers(data.searchUsers.users);
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});
	const handleChangeCollaborator = (e) => {
		setSearchUser(e);
		if (e.length > 0) {
			searchedUserResult({ variables: { e: e } });
		} else {
		}
	};
	const handleUserAccess = (fileId, userId) => {
		var inviteHeaders = new Headers();
		inviteHeaders.append('Authorization', `${props.header}`);
		inviteHeaders.append('Content-Type', 'application/json');
		var raw = JSON.stringify({
			fileId: `${fileId}`,
			accessUserIds: userId,
		});
		var requestUserAccess = {
			method: 'POST',
			headers: inviteHeaders,
			body: raw,
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/giveAccessToUser`,
			requestUserAccess
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					// toast.success(result.message);
					setShowCollaboratorPopup(false);
					setShowFilePopup(true);
				}
			})
			.catch((error) => console.log('error', error));
	};
	const handleScreens = (numb, obj) => {
		setViewObj(obj);
		if (numb === 1) {
			setShowAccess(true);
			setShowFiles(false);
		}
		if (numb === 2) {
			setShowAccess(false);
			setShowFiles(true);
		}
	};

	const handleSearchFiles = (searchText, mbpm, addedTags) => {
		var data = {
			searchQuery: searchText,
			maxBPM: mbpm,
			tags: addedTags,
		};

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/searchallaudiofiles`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				if (response.data.files) {
					setAudioAll(response.data.files);
				} else {
					setAudioAll();
				}
			})

			.catch(function (error) {
				if (error?.response?.status === 404) {
					setAudioAll([]);
				}
				console.log(error);
			});
	};
	const headerkey = `Bearer ${localStorage.getItem('token')}`;

	var audioHeaders = new Headers();
	audioHeaders.append('Authorization', headerkey);

	const fetchData = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallaudiofiles`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				setAudioAll(data.files);
			})
			.catch((error) => {
				console.log('error', error);
			});
	};
	const handleInputCover = (val) => {
		setSelectedCover(val.target.files[0]);
		uploadProfile(val.target.files[0]);
	};

	const uploadProfile = (pic) => {
		var data = new FormData();
		data.append('file', pic);
		data.append('type', 'Public');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/uploadprofilepicture`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				setCoverUrl(response.data.file.url);
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const toastId = useRef(null);

	const uploadFile = () => {
		var FormData = require('form-data');
		var data = new FormData();
		data.append('file', selectedAudio);
		data.append('type', 'Public');
		data.append('audioicon', selectedImage);
		data.append('BPM', bpm);
		data.append('key', k);
		data.append('scale', scale);
		data.append('tags', tags);
		data.append('isBeatInvitationAccepted', 'false');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/upload`,
			headers: { Authorization: headerkey },
			data: data,
			onUploadProgress: (p) => {
				const progress = p.loaded / p.total;
				if (toastId.current === null) {
					toastId.current = toast('Upload in Progress', { progress });
				} else {
					toast.update(toastId.current, { progress });
				}
			},
		};

		axios(config)
			.then(function (response) {
				fetchData();

				toast.done(toastId.current);
				toast.success('File Uploaded Successfully');
			})
			.catch(function (error) {
				console.log(error);
			});
		setBPM();
		setKey();
		setScale();
		setSelectedImage();
		setTags([]);
	};

	const addFiles = () => {
		addFiles(addedFiles, bpId);
		addedFiles.map((fId) => {
			var data = {
				fileId: fId,
				beatPackId: bpId,
			};
			var config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/file/addtobeatpack`,
				headers: { Authorization: headerkey },

				data: data,
			};

			axios(config)
				.then(function (response) {
					getBeatPacks();
				})
				.catch(function (error) {
					console.log(error);
				});
		});

		setAddedFiles([]);
		setBpFiles([]);
	};

	const copyBeatPack = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getbeatpacklink/${bpId}/false`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				console.log('url', process.env.REACT_APP_BASE_URL);
				setLink(process.env.REACT_APP_BASE_URL + result.beatPackLinkwoDomain);
				navigator.clipboard.writeText(
					process.env.REACT_APP_BASE_URL + result.beatPackLinkwoDomain
				);
				toast.success('Link Copied!');
			})
			.catch((error) => console.log('error', error));
	};

	useEffect(() => {
		fetchData();
		toggleSecondaryBar(true);
		//getBeatPacks();
		getProfileData();
		// getFilterValue();
		setLink('');
	}, []);

	if (showAccess === true) {
		toggleSecondaryBar(false);
		return (
			<>
				<ViewAccess obj={viewObj} />
			</>
		);
	} else if (showFiles === true)
		return (
			<>
				<ViewFiles obj={viewObj} />
			</>
		);

	return (
		<>
			<Box w={'100%'} bg={'#000'}>
				<HStack w={'100%'}>
					<Box w={{ xl: '100%', lg: '100%', base: '100%' }}>
						<VStack py={1} w={'100%'} bg='#000' justifyContent={'space-around'}>
							<HStack
								w={'100%'}
								py={['1', '1', '1']}
								justifyContent={'space-evenly'}
							>
								<VStack w={'100%'} alignSelf={'center'} my={'5'}>
									{data && data.length > 0 ? (
										isLoading ? (
											<div className={styles.loaderDivBeatPack}>
												<Loader />
											</div>
										) : (
											data.map((bpObj, index) => (
												<>
													<HStack
														key={index}
														justifyContent={'space-between'}
														w={'100%'}
														borderBottomWidth={' 1px'}
														borderBottomColor={'#AFAFAF'}
														borderBottomStyle={'solid'}
														paddingTop={10}
														paddingBottom={10}
													>
														<HStack w={{ lg: '70%', base: '55%' }}>
															{bpObj.beatPackCover ? (
																<img
																	src={bpObj.beatPackCover}
																	alt='cover'
																	className={styles.songImg}
																/>
															) : (
																<img
																	src={coverImg}
																	alt='cover'
																	className={styles.songImg}
																/>
															)}

															<Pressable
																onPress={() => {
																	handleScreens(2, bpObj);
																}}
																alignSelf={'center'}
																w={{ lg: '85%', base: '65%' }}
															>
																<Box w={'100%'}>
																	<Text
																		fontSize={{
																			lg: '24px',
																			sm: '18px',
																			base: '16px',
																		}}
																		py={1}
																		paddingLeft={{ lg: 5, base: 2 }}
																		color='#fff'
																		fontWeight={'500'}
																	>
																		{bpObj.beatPackName
																			? bpObj.beatPackName
																			: 'Untitled Beatpack'}
																	</Text>
																</Box>
															</Pressable>
														</HStack>
														<HStack
															alignSelf={{ lg: 'flex-end', base: 'center' }}
															justifyContent={'flex-end'}
															w={{ lg: '30%', base: '45%' }}
														>
															<div className={styles.shareIcon}>
																<Button
																	onPress={() => {
																		setShowCollaboratorPopup(true);
																		setbpId(bpObj._id);
																	}}
																	py={{ lg: 2, base: 1 }}
																	px={{ lg: 3, base: 1 }}
																	mx={{ lg: 2, base: 0 }}
																	bg={'transparent'}
																	borderColor={'#2EBB55'}
																	borderWidth={1}
																	alignSelf={'center'}
																	_hover={{
																		bg: '#2EBB55',
																		borderColor: '#2EBB55',
																		_text: { color: '#fff' },
																	}}
																	_focused={{ bg: 'transparent' }}
																	_pressed={{ bg: 'transparent' }}
																	_text={{ color: '#2EBB55' }}
																>
																	<HStack>
																		<Share />
																		&nbsp;&nbsp; Share
																	</HStack>
																</Button>
															</div>
															<div className={styles.eyeIcon}>
																<Button
																	py={{ lg: 2, base: 1 }}
																	px={{ lg: 3, base: 1 }}
																	mx={{ lg: 2, base: 1.5 }}
																	bg={'transparent'}
																	borderColor={'#afafaf'}
																	borderWidth={1}
																	onPress={() => {
																		handleScreens(1, bpObj);
																	}}
																	alignSelf={'center'}
																	_hover={{
																		bg: '#2EBB55',
																		borderColor: '#2EBB55',
																	}}
																	_focused={{ bg: 'transparent' }}
																	_pressed={{ bg: 'transparent' }}
																>
																	<HStack>
																		<Eye /> &nbsp;&nbsp;{bpObj.usersViewCount}
																	</HStack>
																</Button>
															</div>
															<div className={styles.deleteIcon}>
																<Button
																	py={{ lg: 2, base: 1 }}
																	px={{ lg: 3, base: 1 }}
																	mx={{ lg: 2, base: 0 }}
																	bg={'transparent'}
																	borderColor={'#afafaf'}
																	borderWidth={1}
																	onPress={() => {
																		setDeleteBP(bpObj._id);
																		setDeleteModal(true);
																	}}
																	alignSelf={'center'}
																	_hover={{
																		bg: '#2EBB55',
																		borderColor: '#2EBB55',
																	}}
																	_focused={{ bg: 'transparent' }}
																	_pressed={{ bg: 'transparent' }}
																>
																	<Delete />
																</Button>
															</div>
														</HStack>
													</HStack>
												</>
											))
										)
									) : (
										<>
											<HStack justifyContent={'center'}>
												<Text
													fontSize={{ lg: '24px', md: '18px', base: '16px' }}
													py={1}
													lineHeight={{
														xl: '36px',
														lg: '24px',
														md: '18px',
														base: '16px',
													}}
													color='#afafaf'
													fontWeight={'400'}
												>
													No Packs Found
												</Text>
											</HStack>
										</>
									)}
								</VStack>
							</HStack>
						</VStack>
					</Box>
				</HStack>
			</Box>

			{showCollaboratorPopup ? (
				<SendPackPopup
					openModal={showCollaboratorPopup}
					data={users}
					showCopyLink={true}
					handleChangeCollaborator={handleChangeCollaborator}
					value={searchUser}
					fileId={fileId}
					copyBeatPack={copyBeatPack}
					handleUserAccess={handleUserAccess}
					closeModal={() => {
						setShowCollaboratorPopup(false);
						setSearchUser('');
					}}
				/>
			) : (
				''
			)}
			{showFilePopup ? (
				<SentPopup
					openModal={showFilePopup}
					closeModal={() => setShowFilePopup(false)}
				/>
			) : (
				''
			)}
			{deleteModal ? (
				<DeletePopup
					openModal={deleteModal}
					isPack={true}
					closeModal={() => {
						setDeleteModal(false);
					}}
					deleteFtn={() => onDelete(deleteBP)}
				/>
			) : (
				''
			)}
			{showWelcomePopup ? (
				<WelcomePopup
					openModal={showWelcomePopup}
					closeModal={() => setShowWelcomePopup(false)}
				/>
			) : (
				''
			)}
		</>
	);
};
export default BeatPack;
