import React, { useEffect,createContext,useState } from 'react';

import { Box, Divider, HStack } from 'native-base';
import SideBar from '../SideBar/SideBar';
import { ProfileHeaderIcon } from '../Profile/ProfileHeader/ProfileHeaderIcon';
import { DashboardHeader } from '../Headers';
import { ApplePlaylist } from './PlayList/ApplePlayList/ApplePlayList';
import { AirplayDemographics } from './PlayList/AirplayDemographics/AirplayDemographics';

import SpotifyPlayList from './PlayList/SpotifyPlayList/SpotifyPlayList';

import AreaChart from './Graphs/Area';
import DeezerPlayList from './PlayList/DeezerPlayList/DeezerPlayList';
import { TiktokPlaylist } from './PlayList/TiktokPlaylist/TiktokPlaylist';
import GetPlayListPosition from '../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/GetPlayListPosition';
import Overview from './Tabs/OverView/Overview';
import Trends from './Tabs/Trends/Trends';
import Playlists from './Tabs/Playlists/Playlists';
import TabHeader from '../TDTabHeader/TabHeader';


export const TabsContext=createContext()
const TalentDashboardLayout = (props) => {
	const [tabOptions, setTabOptions] = useState([]);
	const [value, setValue] = useState(1);
const {childComp}=props
	return (
		<TabsContext.Provider value={{tabOptions, setTabOptions}}>	<Box w={'100%'} bg={'#000201'}>
		<Box py={4} px={7} >	
			<Overview/></Box>
			
		
			{/* <Playlists/> */}
		</Box></TabsContext.Provider>
	

		
	);
};

export default TalentDashboardLayout;
