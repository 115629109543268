import React from 'react';
import { Grid } from '@mui/material';

import styles from './styles.module.css';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import BarProfileArtistInfo from '../../components/BarCopmonent/BarProfileArtistInfo/BarProfileArtistInfo';
const MyUserProfile = () => {
	return (
		<Grid className={styles.bgBlack}>
			<Sidebar activeText='Profile' childComponent={<BarProfileArtistInfo />} />
		</Grid>
	);
};

export default MyUserProfile;
