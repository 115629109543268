/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { fontSize } from '@mui/system';
import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Text,
} from 'native-base';

import React, { useState } from 'react';
import { DashboardHeader } from '../Headers';
import SideBar from '../SideBar/SideBar';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import DaySelecter from './DaySelecter';
import RightBar from './RightBar';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler
// );
// const dataObject = {
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   data: [50, 100, 60, 70, 40, 50, 20],
//   subscribers: '2,100,000',
//   platform: 'Spotify',
//   socialIcon: '/spotify.png',
// };
// {'0%', '10%', '20%', '30%', '40%', '50'}
function Finanicals() {
	const lables = [
		'$0',
		'$50',
		'$100',
		'$150',
		'$200',
		'$250',
		'$300',
		'$350',
		'$400',
		'$450',
		'$500',
		'$550',
	];

	const data = {
		labels: lables,
		position: 'top',

		datasets: [
			{
				data: [25, 28, 30, 35, 30, 25, 17, 33, 30, 20, 35],
				label: 'Card',
				borderColor: '#2EBA55',
				fill: false,
				backgroundColor: 'rgba(46, 186, 85, 0.3)',
				tension: 0.5,
				borderWidth: 2,
				pointStyle: 'circle',
			},
			//   {
			//     data: [10, 15, 20, 33, 30, 20, 35, 25, 28, 30, 35, 30],
			//     label: 'cash',
			//     borderColor: 'rgba(24, 160, 251, 1)',
			//     fill: false,
			//     backgroundColor: 'rgba(24, 160, 251, 1)',
			//     tension: 0.5,
			//     borderWidth: 2,
			//   },
		],
	};
	const options = {
		opacity: 0.5,

		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					display: true,
					borderDash: [8, 2],
					color: '#fff',
				},
			},
		},
		// responsive: true,

		plugins: {
			legend: {
				// backgroundColor: "#000",
				//color: "#0000",
				position: 'bottom',

				align: 'start',
			},
		},
	};

	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};

	return (
		// <Box w={'100%'} bg={'#000201'}>
		// 	<HStack w={'100%'}>
		// 		<Box
		// 			borderLeftWidth={{ lg: 1, md: 0 }}
		// 			borderColor={'#fff'}
		// 			w={{ xl: '83%', lg: '83%', base: '100%' }}
		// 		>
		<Box w={'100%'}>
			<HStack w={'100%'} flexGrow={1} flexWrap={'wrap'}>
				<Box w={{ xl: '75%', lg: '75%', md: '75%', base: '100%' }}>
					<Box w={'90%'} alignSelf={'center'} pt={5} pb={{ lg: 5, base: 0 }}>
						<Heading
							my={5}
							fontSize={{
								xl: '40px',
								lg: '36px',
								md: '30px',
								base: '20px',
							}}
							color={'#fff'}
							textTransform={'capitalize'}
							fontFamily={'poppins'}
							fontWeight={'medium'}
						>
							Hello Kibriya
						</Heading>
						<Heading
							mb={3}
							fontSize={{
								xl: '18px',
								lg: '16px',
								md: '14px',
								base: '12px',
							}}
							color={'#e3e3e3'}
							textTransform={'capitalize'}
							fontFamily={'poppins'}
							fontWeight={'normal'}
						>
							Welcome Back
						</Heading>
						<HStack
							flexGrow={1}
							flexWrap={'wrap'}
							justifyContent={'space-between'}
						>
							<SalesCard
								title={'Total Money Collected from Invoices'}
								number={'$1,373.98'}
								content={false}
								reminder={false}
							/>

							<SalesCard
								title={'Gross Sales'}
								number={'$1,373.98'}
								content={[
									{
										id: 1,
										detail: '35.41 % from last week',
									},
									{
										id: 1,
										detail: '29.47 % from 6 month ago',
									},
								]}
								reminder={false}
							/>
							<SalesCard
								title={'Pending Invoices'}
								number={'182'}
								content={false}
								reminder={false}
							/>
						</HStack>
						<HStack
							alignSelf={'center'}
							w={'100%'}
							justifyContent={'space-between'}
							flexGrow={1}
							flexWrap={'wrap'}
						>
							<Box
								w={{ xl: '65%', base: '100%' }}
								h={{ xl: '38vh', base: '25vh' }}
								rounded={'lg'}
								bg={'#1C1B1B'}
								my={5}
							>
								<Heading
									py={{ lg: 1, base: 3 }}
									px={3}
									color={'#E3E3E3'}
									fontSize={'lg'}
									fontFamily={'Poppins'}
									fontWeight={'normal'}
								>
									Revenue
								</Heading>
								<Box w={'85%'} alignSelf={'center'}>
									<Line options={options} data={data} />
								</Box>
							</Box>
							<SalesCard
								title={'Invoices'}
								number={'$1,373.98'}
								content={[
									{
										id: 1,
										detail: 'Outstanding payments',
									},
								]}
								reminder={true}
							/>
						</HStack>
						<HStack
							justifyContent={'space-between'}
							flexGrow={1}
							flexWrap={'wrap'}
						>
							<Box
								w={{ xl: '65%', base: '100%' }}
								rounded={'lg'}
								bg={'#1C1B1B'}
								my={5}
								py={{ lg: 0, base: 3 }}
							>
								<HStack
									justifyContent={'space-between'}
									alignSelf={'center'}
									w={'90%'}
									mt={1}
								>
									<Heading
										py={2.5}
										color={'#E3E3E3'}
										fontSize={'lg'}
										fontWeight={'normal'}
										fontFamily={'Poppins'}
									>
										Top Selling Items
									</Heading>
									<Box w={'25%'} my={1}>
										<DaySelecter />
									</Box>
								</HStack>
								<Box w={'90%'} alignSelf={'center'}>
									<Table
										title1={'1'}
										title2={'Hip-hop beats'}
										title3={'$123.54'}
										title4={'45'}
									/>
									<Table
										title1={'2'}
										title2={'Classical Music'}
										title3={'$123.54'}
										title4={'45'}
									/>
									<Table
										title1={'3'}
										title2={'Indian Music'}
										title3={'$123.54'}
										title4={'45'}
									/>
									<Table
										title1={'4'}
										title2={'Rug Kits'}
										title3={'$123.54'}
										title4={'45'}
									/>
									<Table
										title1={'5'}
										title2={'Cool Mind Beats'}
										title3={'$123.54'}
										title4={'45'}
									/>
								</Box>
							</Box>
							<SalesCard
								title={'Total Sales'}
								number={'$1,373.98'}
								selecter={true}
								content={false}
								reminder={false}
							/>
						</HStack>
					</Box>
				</Box>
				<Box
					w={{ xl: '25%', lg: '25%', md: '25%', base: '100%' }}
					borderLeftWidth={{ lg: 1, md: 0, base: 0 }}
					borderColor={'#fff'}
					// borderWidth={1}
				>
					<Box w={'90%'} alignSelf={'center'} pt={{ xl: 8, md: 0, base: 0 }}>
						<RightBar />
					</Box>
				</Box>
			</HStack>
		</Box>
		// 		</Box>
		// 	</HStack>
		// </Box>
	);
}

function SalesCard(props) {
	const { title, content, number, reminder, selecter } = props;
	return (
		<Box
			w={{ xl: '30%', lg: '30%', md: '30%', base: '100%' }}
			h={{ '2xl': '30vh', xl: '38vh', lg: '38px', base: '30vh' }}
			bg={'#1C1B1B'}
			rounded={'lg'}
			alignSelf={'center'}
			my={5}
		>
			<Box w={'85%'} alignSelf={'center'} flex={1}>
				<HStack justifyContent={'space-between'}>
					<Box bg={'#323333'} p={5} w={'10%'} rounded={'full'} my={5} />
					<CalendarMonthRoundedIcon
						style={{ color: '#AFAFAF', fontSize: 25, alignSelf: 'center' }}
					/>
				</HStack>
				<Box flex={1} w={'80%'}>
					<Heading
						pb={3}
						color={'#E3E3E3'}
						fontSize={{ xl: '18px', lg: '16px', md: '14px', base: '18px' }}
						fontFamily={'Poppins'}
						fontWeight={'normal'}
					>
						{title}
					</Heading>
					{selecter && (
						<Box w={'70%'} my={2}>
							<DaySelecter />
						</Box>
					)}
					{content &&
						content.map((item) => (
							<Heading
								pb={2}
								color={'#AFAFAF'}
								fontSize={'12px'}
								fontFamily={'Poppins'}
								fontWeight={'normal'}
							>
								{item.detail}
							</Heading>
						))}
				</Box>
				<Box position={'absolute'} w={'100%'} bottom={5}>
					<Heading
						my={1}
						w={'100%'}
						color={'#E3E3E3'}
						fontWeight={'medium'}
						fontSize={'30px'}
						fontFamily={'Poppins'}
						alignSelf={'center'}
					>
						{number}
					</Heading>

					{reminder && (
						<Button
							p={0}
							variant={'ghost'}
							_pressed={{ bg: 'transparent' }}
							_hover={{ bg: 'transparent' }}
							_focus={{ bg: 'transparent' }}
							_text={{
								color: '#2ebb55',
								fontSize: 'md',
								fontFamily: 'Poppins',
								fontWeight: 'normal',
							}}
							alignSelf={'center'}
						>
							Set a Reminder
							<Divider bg={'#2ebb55'} />
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
}
function Table(props) {
	const { title1, title2, title3, title4 } = props;
	return (
		<Box bg={'#1C1B1B'} w={'100%'}>
			<HStack w={'100%'} py={2}>
				<Heading
					w={'15%'}
					color={'#AFAFAF'}
					textTransform={'capitalize'}
					fontFamily={'Poppins'}
					fontSize={'sm'}
					fontWeight={'normal'}
					textAlign={'left'}
				>
					{title1}
				</Heading>
				<Heading
					w={'35%'}
					color={'#AFAFAF'}
					textTransform={'capitalize'}
					fontFamily={'Poppins'}
					fontSize={'sm'}
					fontWeight={'normal'}
					textAlign={'left'}
				>
					{title2}
				</Heading>
				<Heading
					w={'25%'}
					color={'#AFAFAF'}
					textTransform={'capitalize'}
					fontFamily={'Poppins'}
					fontSize={'sm'}
					fontWeight={'normal'}
					textAlign={'center'}
				>
					{title3}
				</Heading>
				<Heading
					w={'25%'}
					pr={2}
					color={'#AFAFAF'}
					textTransform={'capitalize'}
					fontFamily={'Poppins'}
					fontSize={'sm'}
					fontWeight={'normal'}
					textAlign={'center'}
				>
					{title4}
				</Heading>
			</HStack>
			<Divider bg={'#afafaf'} />
		</Box>
	);
}

export default Finanicals;
