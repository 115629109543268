import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Heading, HStack, Button, Divider } from 'native-base';
import moment from 'moment';

import { Loader } from '../../Loader';
import styles from './styles.module.css';

const ArtistCharts = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [showSongs, setShowSongs] = useState(false);
	const [songs, setSongs] = useState([]);
	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};
	const getSongs = async (slug) => {
		try {
			console.log('songs');
			setShowSongs(true);
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/album/${slug}/tracks`
			);
			const response = await axios(config);
			const { items } = response.data;

			setSongs(items);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log('error fetching song data', error);
		}
	};
	const getArtistSong = async () => {
		try {
			console.log('getArtistSong');
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.18/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/albums?offset=0&limit=100&sortBy=title&sortOrder=asc`
			);
			const response = await axios(config);
			const { items } = response.data;

			let songData = items.map((item) => {
				let { name, slug, creditName, releaseDate } = item;
				return { name, slug, creditName, releaseDate };
			});
			setSongs(songData);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log('error', err);
		}
	};
	useEffect(() => {
		getArtistSong();
		setShowSongs(false);
	}, []);

	return (
		<Box bg={'#000'} w={'100%'} mb={15}>
			<HStack w={'100%'} py={3}>
				<Heading
					w={'23%'}
					textTransform={'uppercase'}
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					textAlign={'left'}
				>
					{showSongs ? 'Number' : 'Album'}
				</Heading>
				<Heading
					w={'23%'}
					textTransform={'uppercase'}
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					textAlign={'center'}
				>
					{showSongs ? 'Name' : 'Artist'}
				</Heading>

				<Heading
					w={'23%'}
					pr={2}
					color={'#AFAFAF'}
					textTransform={'uppercase'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					textAlign={'right'}
				>
					{showSongs ? 'Artist' : 'Release Date'}
				</Heading>
			</HStack>
			<Divider my={1} />
			{isLoading ? (
				<div className={styles.loaderDiv}>
					<Loader />
				</div>
			) : (
				songs?.map((item) => (
					<>
						<HStack w={'100%'} py={3}>
							<Heading
								w={'23%'}
								textTransform={'uppercase'}
								color={'#AFAFAF'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'left'}
							>
								{showSongs ? item?.number : item?.name}
							</Heading>
							<Heading
								w={'23%'}
								textTransform={'uppercase'}
								color={'#AFAFAF'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'center'}
							>
								{showSongs ? item?.song?.name : item?.creditName}
							</Heading>

							<Heading
								w={'23%'}
								pr={2}
								color={'#AFAFAF'}
								textTransform={'uppercase'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'right'}
							>
								{showSongs
									? item?.song?.creditName
									: item.releaseDate
									? moment(item?.releaseDate).format('DD MMMM YYYY')
									: ''}
							</Heading>
							{showSongs ? (
								''
							) : (
								<Heading
									w={'23%'}
									pr={2}
									color={'#AFAFAF'}
									textTransform={'uppercase'}
									fontFamily={'Poppins'}
									fontSize={{
										lg: '1rem',
										md: '0.8rem',
										sm: '0.7rem',
										base: '0.6rem',
									}}
									fontWeight={'normal'}
									textAlign={'right'}
								>
									<Button
										variant={'ghost'}
										padding={0}
										_text={{ color: '#fff' }}
										_hover={{ color: '#2EBA55' }}
										onPress={() =>
											getSongs('when-we-all-fall-asleep-where-do-we-go-1')
										}
									>
										View Songs
									</Button>
								</Heading>
							)}
						</HStack>
						<Divider my={1} />
					</>
				))
			)}
		</Box>
	);
};
export default ArtistCharts;
