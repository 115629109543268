import { Button, Divider, HStack, Image, Text, VStack, Box } from 'native-base';
import { useEffect, useState, useRef } from 'react';

import { Modal } from 'native-base';
import React from 'react';
import axios from 'axios';
import AudioLibraryHeader from './AudioLibraryHeader/AudioLibraryHeader';
import { Dashboard } from '@mui/icons-material';
import { DashboardHeader } from '../Headers';
import SideBar from '../SideBar/SideBar';
import AudioLibraryBody from './AudioLibraryBody';
import AudioUpload from './AudioUpload';
import AudioLibrarySample from './AudioLibraryMain';
import { SearchFieldComponent } from '../SearchField';
import CursorPlugin from 'wavesurfer.js/src/plugin/cursor';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsEye } from 'react-icons/bs';
import { FaPause, FaPlay } from 'react-icons/fa';
import ViewAccess from './ViewBeatPack';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { toggleButtonClasses } from '@mui/material';

const SharedBeatPack = () => {
	const myRef = useRef();
	const [bpFiles, setBpFiles] = useState([]);
	const [bp, setBp] = useState({});
	const [activeURL, setActiveURL] = useState('');
	const [addedFiles, setAddedFiles] = useState([]);

	const headerkey = `Bearer ${localStorage.getItem('token')}`;

	var path = window.location.href.split('/');

	const [shareModal, setShareModal] = useState(false);
	const [copyLink, setCopyLink] = useState('');
	const [isPlay, setIsPlay] = useState(false);
	const [saveUrl, setSaveUrl] = useState([]);
	const [saveFile, setSaveFile] = useState([]);
	const [audioUrl, setAudioUrl] = useState();
	const [playing, setPlaying] = useState(false);

	const navigate = useNavigate();

	const saveLink = () => {
		let URL_List = [];
		if (bpFiles) {
			URL_List = bpFiles.map((bpFileObj, index) => {
				return bpFileObj.file.url;
			});
			setSaveUrl(URL_List);
		}
	};
	const startAudio = () => {
		myRef?.current?.play();
		setPlaying(true);
	};

	const pauseAudio = () => {
		myRef?.current?.pause();
		setPlaying(false);
	};

	useEffect(() => {
		if (activeURL && isPlay) {
			startAudio();
		} else {
			pauseAudio();
		}
	}, [activeURL, isPlay]);

	useEffect(() => {
		saveLink();
	}, [bpFiles]);

	var lastSegment = path.pop();

	const saveZip = (filename, urls) => {
		if (!urls) return;
		else {
			const zip = new JSZip();
			const folder = zip.folder('files'); // folder name where all files will be placed in
			urls.forEach((url) => {
				const blobPromise = fetch(url).then((r) => {
					if (r.status === 200) return r.blob();
					return Promise.reject(new Error(r.statusText));
				});
				const name = url.substring(url.lastIndexOf('/') + 1);
				folder.file(name, blobPromise);
			});
			zip
				.generateAsync({ type: 'blob' })
				.then((blob) => saveAs(blob, filename));
		}
	};

	const playAudio = (url, isPlay) => {
		setActiveURL(url);
		setIsPlay(isPlay);
	};

	const viewFiles = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey ? headerkey : null },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/onbeatpackopen/${lastSegment}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				setBpFiles(result.files);
				setBp(result.beatpack);
			})
			.catch((error) => console.log('error', error));
	};

	useEffect(() => {
		viewFiles();
	}, []);

	const saveToLibrary = () => {
		var config = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/file/savetolibrary/${bp._id}`,
			headers: {
				Authorization: `${headerkey}`,
			},
		};
		console.log('config obj', config);
		axios(config)
			.then(function (response) {
				if (response?.data?.auth === false) {
					localStorage.clear();
					navigate('/signin');
				} else {
					toast.success('Pack successfully saved in library');
				}
			})
			.catch(function (error) {
				if (error?.response?.status === 401) {
					localStorage.clear();
					navigate('/signin');
				}
				if (error?.response?.status === 400) {
					toast.error(error?.response?.data?.message);
				}
				console.log(error);
			});
	};
	return (
		<>
			<VStack bg='#000'>
				<ToastContainer />
				<HStack w={'full'} h={'full'}>
					<VStack w={'100%'}>
						<VStack py={2} px={10} mt={5}>
							{/* {beatPack && beatPack.map((bpObj, index) => ( */}
							<HStack w={'100%'} justifyContent={'space-between'}>
								<HStack w={'30%'}>
									<Image
										h={{ lg: '120px', sm: '40px' }}
										href={'/'}
										width={{
											lg: '200px',
											md: '140px',
											sm: '100px',
											base: '80px',
										}}
										// size={"180"}
										resizeMode='contain'
										//  p={5}
										marginLeft={5}
										marginRight={5}
										// paddingX={"20px"}
										source={require('../../assets/Logo/Group 1583.png')}
										alt='logo'
									/>
								</HStack>
								{localStorage.getItem('token') === null ? (
									<HStack w={'25%'} justifyContent={'space-around'}>
										<Button
											bg={'#2EBB55'}
											borderWidth={1}
											borderColor={'#2EBB55'}
											_hover={{
												bg: 'transparent',
												_text: { color: '#2EBB55' },
											}}
											_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
											_text={{
												color: '#e3e3e3',
												fontSize: { xl: '20px', base: 'lg' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											w={'35%'}
											py={2}
											alignSelf={'center'}
											onPress={() => {
												navigate('/signin');
											}}
										>
											Login
										</Button>
										<Button
											bg={'#2EBB55'}
											borderWidth={1}
											borderColor={'#2EBB55'}
											_hover={{
												bg: 'transparent',
												_text: { color: '#2EBB55' },
											}}
											_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
											_text={{
												color: '#e3e3e3',
												fontSize: { xl: '20px', base: 'lg' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											w={'35%'}
											py={2}
											alignSelf={'center'}
											onPress={() => {
												navigate('/signup');
											}}
										>
											Signup
										</Button>
									</HStack>
								) : (
									<Box
										borderColor={'#fff'}
										w={{ xl: '60%', lg: '60%', base: '100%' }}
									>
										<DashboardHeader />
									</Box>
								)}
							</HStack>
							<HStack marginTop={20}>
								<HStack alignSelf={'center'} px={10}>
									<Image
										w={'20%'}
										height={{ lg: '180px', md: '100px', sm: '60px' }}
										rounded={'md'}
										width={{ lg: '150px', md: '85px', sm: '55px' }}
										alignSelf={'center'}
										source={
											bp.beatPackCover
												? { uri: bp.beatPackCover }
												: require('../../assets/Images/1cover.png')
										}
										// source={bp.beatPackCover}
										// source={require('../../assets/Images/1cover.png')}
										alt='User Profile Pic'
									/>
								</HStack>
								<HStack alignSelf={'center'} px={5} w={'80%'}>
									<Text
										fontSize={{ lg: '24px', sm: '18px' }}
										fontFamily={'Poppins'}
										color='white'
										w={'60%'}
										py={5}
										alignSelf={'center'}
									>
										{bp.beatPackName ? bp.beatPackName : 'Untitled Beatpack'}
									</Text>
								</HStack>
							</HStack>
							<HStack justifyContent={'space-between'} w={'40%'} mx={7} my={5}>
								<Button
									bg={'#2EBB55'}
									borderWidth={1}
									borderColor={'#2EBB55'}
									_hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
									_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
									_text={{
										color: '#e3e3e3',
										fontSize: { xl: '20px', base: 'lg' },
										fontFamily: 'Poppins',
										textAlign: 'center',
										textTransform: 'uppercase',
										fontWeight: 'medium',
									}}
									w={'30%'}
									py={2}
									alignSelf={'center'}
									onPress={() => {
										saveToLibrary();
										//   setSaveLibrary(false);
									}}
								>
									Save
								</Button>
								<Button
									bg={'#2EBB55'}
									borderWidth={1}
									borderColor={'#2EBB55'}
									_hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
									_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
									_text={{
										color: '#e3e3e3',
										fontSize: { xl: '20px', base: 'lg' },
										fontFamily: 'Poppins',
										textAlign: 'center',
										textTransform: 'uppercase',
										fontWeight: 'medium',
									}}
									w={'30%'}
									py={2}
									alignSelf={'center'}
									onPress={() => {
										saveZip(bp.beatPackName, saveUrl);
									}}
								>
									Download
								</Button>
								<Button
									bg={'#2EBB55'}
									borderWidth={1}
									borderColor={'#2EBB55'}
									_hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
									_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
									_text={{
										color: '#e3e3e3',
										fontSize: { xl: '20px', base: 'lg' },
										fontFamily: 'Poppins',
										textAlign: 'center',
										textTransform: 'uppercase',
										fontWeight: 'medium',
									}}
									w={'30%'}
									py={2}
									alignSelf={'center'}
									onPress={() => {
										setCopyLink(window.location.href);
										setShareModal(true);
									}}
								>
									Share
								</Button>
							</HStack>
						</VStack>
						<Divider w={'95%'} alignSelf={'center'} my={5}></Divider>

						{bpFiles &&
							bpFiles.map((bpFileObj, index) => (
								<HStack key={index} w={'100%'} py={2} px={10} my={5}>
									<Image
										height={{ lg: '90px', md: '100px', sm: '60px' }}
										rounded={'md'}
										width={{ lg: '80px', md: '85px', sm: '55px' }}
										alignSelf={'center'}
										source={
											bpFileObj.file.audioIconUrl
												? bpFileObj.file.audioIconUrl
												: require('../../assets/Images/1cover.png')
										}
										//  size={["8", "10", "12"]}
										mt='0.5'
										alt='icon'
									/>
									<Text
										fontSize={{ lg: '18px', sm: '14px' }}
										color='white'
										w={'80%'}
										py={5}
										px={5}
										alignSelf={'center'}
									>
										{bpFileObj.file.fileName}
									</Text>

									<audio ref={myRef} src={activeURL} />
									{playing && activeURL === bpFileObj.file.url ? (
										<div onClick={() => playAudio(bpFileObj.file.url, false)}>
											<FaPause
												size={20}
												style={{
													cursor: 'pointer',
													alignSelf: 'center',
													color: 'white',
												}}
											/>
										</div>
									) : (
										<div onClick={() => playAudio(bpFileObj.file.url, true)}>
											<FaPlay
												size={20}
												style={{
													cursor: 'pointer',
													alignSelf: 'center',
													color: 'white',
												}}
											/>
										</div>
									)}
									{/* <Button
										onPress={() => {
											playAudio(bpFileObj.file.url);
										}}
										my={10}
										bg={'transparent'}
										alignSelf={'center'}
										_hover={{ bg: 'transparent' }}
										_focused={{ bg: 'transparent' }}
										_pressed={{ bg: 'transparent' }}
									>
										{playing ? (
											<FaPlay
												// onClick={togglePlay}
												size={20}
												style={{
													cursor: 'pointer',
													alignSelf: 'center',
													color: 'white',
												}}
											/>
										) : (
											<FaPause
												// onClick={togglePlay}
												size={20}
												style={{
													cursor: 'pointer',
													alignSelf: 'center',
													color: 'white',
												}}
											/>
										)}
									</Button> */}

									{/* </Button> */}
								</HStack>
							))}
					</VStack>
				</HStack>
			</VStack>
			<Modal
				isOpen={shareModal}
				onClose={() => setShareModal(false)}
				w={'30%'}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={10}
			>
				<Modal.Content backgroundColor={'transparent'}>
					<Modal.Body backgroundColor={'transparent'} my={2} mx={2}>
						<VStack px={5}>
							<HStack rounded='md' backgroundColor={'#1C1B1B'} h={'40'} my={2}>
								<Modal.CloseButton
									_hover={{ bg: '#1C1B1B' }}
									_focused={{ bg: '#2EBA55' }}
									_pressed={{ bg: 'transparent' }}
								/>
								<Box w={'100%'}>
									<HStack w={'100%'}>
										<VStack>
											<Text
												fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
												color='#afafaf'
												fontWeight={'semibold'}
												px={5}
												py={3}
											>
												Get Link
											</Text>
											<Text
												fontSize={{ lg: '14px', md: '12px', sm: '12px' }}
												color='#afafaf'
												px={5}
												py={2}
											>
												Anyone with this link can play this song
											</Text>
										</VStack>
									</HStack>
									<HStack justifyContent={'flex-end'}>
										<Button
											onPress={() => {
												setShareModal(false);
												navigator.clipboard.writeText(copyLink);
												toast.success('Share Link Copied');
											}}
											size='md'
											mx={10}
											bg={'#2EBA55'}
											rounded={'md'}
											_hover={{ bg: '#1C1B1B' }}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: '#1C1B1B' }}
											alignSelf={'center'}
										>
											<Text
												fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
												color='white'
											>
												Copy Link
											</Text>
										</Button>
									</HStack>
								</Box>
							</HStack>
						</VStack>
					</Modal.Body>
				</Modal.Content>
			</Modal>
		</>
	);
};

export default SharedBeatPack;
