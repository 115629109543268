import React, { useEffect, useState } from 'react';
// import GetPlayListPosition from "../../../../RestApis/Services/TalentDashboard/ViewTalentDashBoard/GetPlayListPosition";
import GetTopPlaylists from '../../../../RestApis/Services/TalentDashboard/GetTopPlaylists';
import { HStack, Box } from 'native-base';
import SpotifyPlayList from '../../PlayList/SpotifyPlayList/SpotifyPlayList';
import { ApplePlaylist } from '../../PlayList/ApplePlayList/ApplePlayList';
import DeezerPlayList from '../../PlayList/DeezerPlayList/DeezerPlayList';
import { TiktokPlaylist } from '../../PlayList/TiktokPlaylist/TiktokPlaylist';
import { AirplayDemographics } from '../../PlayList/AirplayDemographics/AirplayDemographics';
import TabHeader from '../../../TDTabHeader/TabHeader';
const Overview = (props) => {
	const { isArtist } = props;
	const [playLists, SetPlayLists] = useState([]);
	const [isLoading, setIsloading] = useState(true);
	const [sorted, setSorted] = useState(false);
	useEffect(() => {
		const GetSpotifyplaylistFollowers = async () => {
			console.log('heldadhisuagdiusavd');
			try {
				const apiResponse = await GetTopPlaylists();
				setIsloading(false);
				console.log(apiResponse.data.items);
				!sorted && SetPlayLists(apiResponse.data.items);
			} catch (err) {
				console.log(err);
			}
		};
		GetSpotifyplaylistFollowers();
	}, [sorted]);
	return (
		<HStack w={'100%'}>
			<Box
				//borderLeftWidth={{ lg: 1, md: 0 }}
				borderColor={'#fff'}
				w={{ xl: '100%', base: '100%' }}
			>
				<Box py={4} w={'100%'}>
					{/* <TabHeader/> */}
					<HStack
						flexDirection={{ md: 'row', base: 'column' }}
						flexGrow={1}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
					>
						<TiktokPlaylist
							showGraph={false}
							width={{ lg: '100%', md: '99%', base: '100%' }}
						/>
						{/* <TiktokPlaylist showGraph={true} /> */}
					</HStack>
					<SpotifyPlayList
						setSorted={setSorted}
						sorted={sorted}
						SetPlayLists={SetPlayLists}
						isLoading={isLoading}
						playList={playLists}
					/>

					<HStack
						flexDirection={{ md: 'row', base: 'column' }}
						justifyContent={'space-between'}
						flexGrow={1}
						flexWrap={'wrap'}
					>
						<ApplePlaylist isLoading={isLoading} playList={playLists} />
						<DeezerPlayList
							setSorted={setSorted}
							sorted={sorted}
							SetPlayLists={SetPlayLists}
							isLoading={isLoading}
							playList={playLists}
						/>
					</HStack>
					<AirplayDemographics
						isLoading={isLoading}
						title={'Radio Airplay'}
						subtitle={'Radio plays by country in te last 28 days'}
					/>
				</Box>
			</Box>
		</HStack>
	);
};

export default Overview;
