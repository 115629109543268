/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
  Box,
  Button,
  Checkbox,
  CloseIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Menu,
  Modal,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';

export function TypePopup(props) {
  const { openModal, closeModal } = props;

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      w={{ xl: '50%', md: '50%', base: '90%' }}
      size={'full'}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={20}
    >
      <Box w={'90%'} bg={'#141313'} rounded={'lg'}>
        <HStack
          alignSelf={'center'}
          justifyContent={'space-between'}
          my={5}
          w={'90%'}
        >
          <Heading
            w={'100%'}
            color={'#e3e3e3'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
            textAlign={'center'}
            fontSize={{ lg: '2xl', base: 'lg' }}
          >
            Type
          </Heading>

          <Button
            variant={'ghost'}
            px={5}
            // py={3}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeModal}
            alignItems={'center'}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>
        <Box w={'90%'} alignSelf={'center'}>
          <Button
            size={'sm'}
            bg='#1c1b1b'
            rounded={'md'}
            alignSelf={'flex-start'}
            _hover={{
              bg: 'transparent',
            }}
            _focused={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={() => {}}
            px={4}
            my={5}
          >
            <Heading
              color={'#AFAFAF'}
              fontWeight={'normal'}
              fontFamily={'Poppins'}
              fontSize={{ lg: 'md', base: 'xs' }}
            >
              All Files
            </Heading>
          </Button>
          <Button
            size={'sm'}
            bg='transparent'
            rounded={'md'}
            borderColor={'#afafaf'}
            alignSelf={'flex-start'}
            borderWidth={1}
            _hover={{
              bg: 'transparent',
            }}
            _focused={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={() => {}}
            px={4}
            my={5}
          >
            <Heading
              color={'#AFAFAF'}
              fontWeight={'normal'}
              fontFamily={'Poppins'}
              fontSize={{ lg: 'lg', base: 'sm' }}
            >
              Uploads Only
            </Heading>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
