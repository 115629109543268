import React from 'react'
import { Heading } from 'native-base'
const  ProfileTitle=({text,fontSize})=> {
  return (
    <Heading
    textTransform="capitalize"
    fontSize={fontSize}
    fontWeight={"medium"}
    color={"#E3E3E3"}
  
  >
    {text}
  </Heading>
  )
}

export default ProfileTitle