import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Image,
} from 'native-base';
import youtube from '../../../assets/Feeds/youtube.png';

function ConnectToPlatForm(props) {
  const {imageSrc, buttonText, checkboxText, handler} = props;
  return (
    <Box w={"30%"} px={5} alignSelf={"center"}>
      <Image source={imageSrc} size={32} alignSelf={"center"} />
      <Button
        mt={8}
        size={"sm"}
        bg="#000201"
        borderStyle={"solid"}
        borderWidth={"1"}
        borderColor={"#2EBA55"}
        w={"90%"}
        rounded={"md"}
        alignSelf={"center"}
        _text={{
          color: "#2EBA55",
          fontSize: {
            lg: "18px",
            md: "16px",
            sm: "14px",
            base: "14px",
          },
          fontFamily: "Poppins",
        }}
        _hover={{
          bg: "#2EBA55",
          _text: {
            color: "#fff",
          },
        }}
        _focused={{ bg: "#2EBA55" }}
        _pressed={{ bg: "transparent" }}
        onPress={handler}
      >
        {buttonText}
      </Button>
      <Checkbox
        value="one"
        my={5}
        colorScheme={"green"}
        alignItems={'flex-start'}
        bg={'transparent'}
        _text={{
          color: "#AFAFAF",
          fontSize: {
            lg: "16px",
            md: "14px",
            base: "12px",
          },
          fontFamily: "Poppins",
        }}
      >
        {checkboxText}
      </Checkbox>
    </Box>
  );
}

export default ConnectToPlatForm;


// <Box w={'30%'} px={5} alignSelf={'center'}>
//                     <Image source={twitch} size={32} alignSelf={'center'} />
//                     <Button
//                       mt={8}
//                       size={'sm'}
//                       bg='#000201'
//                       borderStyle={'solid'}
//                       borderWidth={'1'}
//                       borderColor={'#2EBA55'}
//                       w={'90%'}
//                       rounded={'md'}
//                       alignSelf={'center'}
//                       _text={{
//                         color: '#2EBA55',
//                         fontSize: {
//                           lg: '18px',
//                           md: '16px',
//                           sm: '14px',
//                           base: '14px',
//                         },
//                         fontFamily: 'Poppins',
//                       }}
//                       _hover={{
//                         bg: '#2EBA55',
//                         _text: {
//                           color: '#fff',
//                         },
//                       }}
//                       _focused={{ bg: '#2EBA55' }}
//                       _pressed={{ bg: 'transparent' }}
//                       onPress={() => setSowConnectPlatfrom(false)}
//                     >
//                       CONNECT WITH TWITCH
//                     </Button>
//                     <Checkbox
//                       value='one'
//                       my={5}
//                       colorScheme={'green'}
//                       _text={{
//                         color: '#AFAFAF',
//                         fontSize: {
//                           lg: '16px',
//                           md: '14px',
//                           base: '12px',
//                         },
//                         fontFamily: 'Poppins',
//                       }}
//                     >
//                       Notify my subscribers when I upload a new video
//                     </Checkbox>
//                   </Box>