/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { fontSize } from '@mui/system';
import {
  Box,
  Button,
  CheckIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Menu,
  Text,
} from 'native-base';

import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
export function CountryGraph(props) {
  const data = [
    {
      id: 1,
      country: 'United States',
      value: '90.66%',
    },
    {
      id: 1,
      country: 'Brazil',
      value: '82.26%',
    },
    {
      id: 1,
      country: 'Syria',
      value: '73.26%',
    },
    {
      id: 1,
      country: 'China',
      value: '65.26%',
    },
    {
      id: 1,
      country: 'Turkey',
      value: '50.26%',
    },
    {
      id: 1,
      country: 'Indonesia',
      value: '29.26%',
    },
    {
      id: 1,
      country: 'Egypt',
      value: '12.26%',
    },
  ];
  return (
    <Box
      w={'100%'}
      pt={5}
      pb={2}
      bg={'#1c1b1b'}
      rounded={'lg'}
      alignSelf={'center'}
    >
      {data.map((item) => (
        <HStack mb={5} w={'95%'} alignSelf={'center'}>
          <Box w={'35%'}>
            <Heading
              color={'#fff'}
              fontSize={{ xl: 'md', base: 'xs' }}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
            >
              {item.country}
            </Heading>
          </Box>
          <HStack w={'65%'}>
            <Heading
              w={'20%'}
              color={'#afafaf'}
              fontSize={{ xl: 'sm', base: '2xs' }}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
            >
              {item.value}
            </Heading>
            <Box
              w={'80%'}
              rounded={'3xl'}
              bg={'#2ebb5550'}
              alignSelf={'center'}
            >
              <Box w={item.value} rounded={'3xl'} bg={'#2ebb55'} py={2} />
            </Box>
          </HStack>
        </HStack>
      ))}
    </Box>
  );
}
