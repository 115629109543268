import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';

import Heart from '../../../assets/Icons/heart.png';
import activeHeart from '../../../assets/Icons/greenHeart.png';
import styles from './styles.module.css';
import useFavouriteArtistMutation from '../../Hooks/TalentDashboard/AddTofavouriteArtist/useFavouriteArtistMutation';
import useRepveFavouriteArtistMutation from '../../Hooks/TalentDashboard/RemoveFavouriteArtist/useRepveFavouriteArtistMutation';
import useRemoveFavouriteSongMutation from '../../../components/Hooks/TalentDashboard/Songs/RemoveFavouriteSong/useRemoveFavouriteSongMutation.js';
import { Link } from 'react-router-dom';
export const TrendingArtist = (props) => {
	const { addFavoriteArtist } = useFavouriteArtistMutation();
	const { removeFavoriteArtist } = useRepveFavouriteArtistMutation();
	const { removeFavoriteSong } = useRemoveFavouriteSongMutation();
	const {
		heading,
		artistData,
		isTrending,
		favoriteArtists,
		songsData,
		setFavSong,
		data,
		trendingArtist,
		setTrendingArtist,
		fav,
		setFav,
		setFavSongId,
		favSongid,
	} = props;
	// const [fovoriteArtists,setFavoriteArtists]=useState(artistData)
	console.log('artists', artistData);
	console.log('songsdata', songsData);
	const addFav = (uuid) => {
		console.log(uuid);
		if (fav.includes(uuid)) {
			setFav((fav) => fav.filter((item) => item !== uuid));
			removeFavoriteArtist({
				variables: {
					artistId: uuid,
				},
				context: { clientName: 'user' },
			});

			console.log('remove');
		} else {
			console.log(uuid);
			addFavoriteArtist({
				variables: {
					artistId: uuid,
				},
				context: { clientName: 'user' },
			});

			setFav([...fav, uuid]);
		}
	};

	const removeFavSong = (uuid) => {
		if (favSongid.includes(uuid)) {
			setFavSongId((favSongid) => favSongid.filter((item) => item !== uuid));
			removeFavoriteSong({
				variables: {
					songId: uuid,
				},
				context: { clientName: 'user' },
			});
			setFavSong(songsData.filter((item) => item.uuid !== uuid));
			console.log('remove');
		}
	};
	return (
		<Grid container>
			<Grid item xs={12} md={12}>
				<h1 className={styles.heading}>{heading}</h1>
			</Grid>
			{artistData && (
				<Grid container direction='row'>
					{artistData?.map((item, index) => (
						<Grid
							item
							xs={12}
							md={2}
							className={styles.artistDiv}
							key={'artist' + index}
						>
							<div className={styles.artistImageDiv}>
								<div>
									{' '}
									<img
										src={item.image}
										alt={item.name}
										loading='lazy'
										style={{ display: 'block' }}
										className={styles.profileImg}
									/>
								</div>
								<div>
									<img
										src={fav?.includes(item.profileLink) ? activeHeart : Heart}
										alt='Heart'
										className={styles.favirotes}
										onClick={() => addFav(item.profileLink)}
									/>
								</div>
							</div>
							<p className={styles.name}>{item.name}</p>
							<p className={styles.role}>{item.role}</p>
							<Link to={`/talent-dashboard/artist-profile/?uuid=${item.profileLink}`} className={styles.profileLink} >View Profile</Link>
							{/* <a href={item.profileLink} className={styles.profileLink}>
								View Profile
							</a> */}
						</Grid>
					))}
				</Grid>
			)}

			{songsData && (
				<Grid container direction='row'>
					{songsData.map((item, index) => (
						<Grid
							item
							xs={12}
							md={2}
							className={styles.artistDiv}
							key={'artist' + index}
						>
							<div className={styles.flex}>
								<img
									src={item.imageUrl}
									alt={item.name}
									loading='lazy'
									style={{ display: 'block' }}
									className={styles.profileImg}
								/>
								<img
									src={favSongid?.includes(item.uuid) ? activeHeart : Heart}
									alt='Heart'
									className={styles.favirotes}
									onClick={() => removeFavSong(item.uuid)}
								/>
							</div>
							<p className={styles.name}>{item.name}</p>
							{/* <p className={styles.role}>{item.role}</p> */}
							{/* <a href={item.profileLink} className={styles.profileLink}>
							View Profile
						</a> */}
						</Grid>
					))}
					{/* <Grid
						item
						xs={12}
						md={2}
						className={styles.artistDiv}
						// key={'artist' + index}
					>
						<div className={styles.flex}>
							<img
								src={songsData.imageUrl}
								alt={songsData.name}
								loading='lazy'
								style={{ display: 'block' }}
								className={styles.profileImg}
							/>
	                        <img
								src={favSongid?.includes(songsData.uuid) ? activeHeart : Heart}
								alt='Heart'
								className={styles.favirotes}
								onClick={() => removeFavSong(songsData.uuid)}
							/>		
						</div>
						<p className={styles.name}>{songsData.name}</p>
						{/* <p className={styles.role}>{item.role}</p> */}
					{/* <a href={item.profileLink} className={styles.profileLink}>
							View Profile
						</a> */}
					{/* </Grid> */}
				</Grid>
			)}
		</Grid>
	);
};
