import { Box, Button } from 'native-base';

export default function Dropdown(props) {
	// const scrollTo = useScrollTo();
	// const width = Dimensions.get('window').width;
	const { item } = props;

	return (
		<>
			<Box
				borderLeftStyle='dotted'
				borderLeftWidth={'2'}
				borderColor={'#fff'}
				marginLeft={'3'}
			>
				<Button
					textAlign={'right'}
					justifyContent={'flex-start'}
					href='/home'
					variant='ghost'
					w={'100%'}
					direction={'row'}
					//opacity={'50%'}

					_text={{
						fontSize: '13px',
						fontWeight: 'regular',
						fontFamily: 'poppins',
						color: '#ffff',
						_hover: {
							color: '#ffff',
						},
					}}
					_hover={{
						bg: '#2EBB55',
						borderRadius: 0,
						// opacity:'20%',
						_text: {
							color: '#fff',
						},
					}}
				>
					{item.title}
				</Button>
			</Box>
		</>
	);
}
