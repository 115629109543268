import Airplay from '../assets/Icons/platform/Airplay.png';
import Amazon from '../assets/Icons/platform/Amazon.png';
import Anghami from '../assets/Icons/platform/Anghami.png';
import AppleMusic from '../assets/Icons/platform/Apple Music.png';
import Deezer from '../assets/Icons/platform/Deezer.png';
import Genius from '../assets/Icons/platform/Genius.png';
import ITunes from '../assets/Icons/platform/ITunes.png';
import LineMusic from '../assets/Icons/platform/LineMusic.png';
import Melon from '../assets/Icons/platform/Melon.png';
import QQMusic from '../assets/Icons/platform/QQ Music.png';
import Shazam from '../assets/Icons/platform/Shazam.png';
import Spotify from '../assets/Icons/platform/Spotify.png';
import TikTok from '../assets/Icons/platform/TikTok.png';
import Youtube from '../assets/Icons/platform/Youtube.png';

export const getLogo = (name) => {
	switch (name) {
		case 'Airplay':
			return (
				<img
					loading='lazy'
					width='10'
					src={Airplay}
					srcSet={Airplay}
					alt={name}
				/>
			);
		case 'Amazon':
			return (
				<img
					loading='lazy'
					width='10'
					src={Amazon}
					srcSet={Amazon}
					alt={name}
				/>
			);
		case 'Anghami':
			return (
				<img
					loading='lazy'
					width='10'
					src={Anghami}
					srcSet={Anghami}
					alt={name}
				/>
			);
		case 'Deezer':
			return (
				<img
					loading='lazy'
					width='10'
					src={Deezer}
					srcSet={Deezer}
					alt={name}
				/>
			);
		case 'Apple Music':
			return (
				<img
					loading='lazy'
					width='10'
					src={AppleMusic}
					srcSet={AppleMusic}
					alt={name}
				/>
			);
		case 'Genius':
			return (
				<img
					loading='lazy'
					width='10'
					src={Genius}
					srcSet={Genius}
					alt={name}
				/>
			);
		case 'iTunes':
			return (
				<img
					loading='lazy'
					width='10'
					src={ITunes}
					srcSet={ITunes}
					alt={name}
				/>
			);
		case 'Line Music':
			return (
				<img
					loading='lazy'
					width='10'
					src={LineMusic}
					srcSet={LineMusic}
					alt={name}
				/>
			);
		case 'Melon':
			return (
				<img loading='lazy' width='10' src={Melon} srcSet={Melon} alt={name} />
			);
		case 'QQ Music':
			return (
				<img
					loading='lazy'
					width='10'
					src={QQMusic}
					srcSet={QQMusic}
					alt={name}
				/>
			);
		case 'Shazam':
			return (
				<img
					loading='lazy'
					width='10'
					src={Shazam}
					srcSet={Shazam}
					alt={name}
				/>
			);
		case 'Spotify':
			return (
				<img
					loading='lazy'
					width='10'
					src={Spotify}
					srcSet={Spotify}
					alt={name}
				/>
			);
		case 'YouTube':
			return (
				<img
					loading='lazy'
					width='10'
					src={Youtube}
					srcSet={Youtube}
					alt={name}
				/>
			);
		case 'TikTok':
			return (
				<img
					loading='lazy'
					width='10'
					src={TikTok}
					srcSet={TikTok}
					alt={name}
				/>
			);
		default:
			return;
	}
};
