/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React from "react";

function HelpIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#AFAFAF"/>
      <path d="M12 6.00007C11.0717 6.00007 10.1815 6.36882 9.52513 7.02519C8.86875 7.68157 8.5 8.57181 8.5 9.50007C8.5 9.76528 8.60536 10.0196 8.79289 10.2072C8.98043 10.3947 9.23478 10.5001 9.5 10.5001C9.76522 10.5001 10.0196 10.3947 10.2071 10.2072C10.3946 10.0196 10.5 9.76528 10.5 9.50007C10.5 9.2034 10.588 8.91339 10.7528 8.66671C10.9176 8.42004 11.1519 8.22778 11.426 8.11425C11.7001 8.00072 12.0017 7.97101 12.2926 8.02889C12.5836 8.08677 12.8509 8.22963 13.0607 8.43941C13.2704 8.64919 13.4133 8.91646 13.4712 9.20743C13.5291 9.4984 13.4994 9.8 13.3858 10.0741C13.2723 10.3482 13.08 10.5824 12.8334 10.7473C12.5867 10.9121 12.2967 11.0001 12 11.0001C11.7348 11.0001 11.4804 11.1054 11.2929 11.293C11.1054 11.4805 11 11.7349 11 12.0001V14.0001C11 14.2653 11.1054 14.5196 11.2929 14.7072C11.4804 14.8947 11.7348 15.0001 12 15.0001C12.2652 15.0001 12.5196 14.8947 12.7071 14.7072C12.8946 14.5196 13 14.2653 13 14.0001V12.8401C13.8081 12.6041 14.5037 12.0839 14.9585 11.3755C15.4133 10.6671 15.5967 9.81814 15.4749 8.98513C15.3532 8.15213 14.9344 7.39122 14.2958 6.84266C13.6572 6.29409 12.8418 5.99484 12 6.00007Z" fill="#AFAFAF"/>
      <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="#AFAFAF"/>
    </svg>

  );
}

export default HelpIcon;
