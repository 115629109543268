import React from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  Divider,
} from "native-base";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import imgDrop from "../../../assets/Icons/arrowRedUp.png";
import { ArrowBack } from "@mui/icons-material";
export const TableHeaderCol5 = (props) => {
  const {
    isHeader,
    title1,
    title2,
    title3,
    title4,
    bg,
    title5,
    showArrow,
    dateOrder,
    sorted,
    handleSort,
  } = props;
  console.log("dateorder",dateOrder)
  return (
    <Box bg={bg} w={"100%"}>
      <HStack w={"100%"} py={5}>
        <Heading
          w={"23%"}
          textTransform={"uppercase"}
          color={isHeader ? "#E3E3E3" : "#AFAFAF"}
          fontFamily={"Poppins"}
          fontSize={
            isHeader
              ? { lg: "0.9rem", md: "0.75rem", sm: "0.7rem", base: "0.6rem" }
              : { lg: "1rem", md: "0.8rem", sm: "0.7rem", base: "0.6rem" }
          }
          fontWeight={"normal"}
          textAlign={"left"}
        >
          {title1 ? title1 : ""}
        </Heading>
        <Heading
          w={"23%"}
          textTransform={"uppercase"}
          color={isHeader ? "#E3E3E3" : "#AFAFAF"}
          fontFamily={"Poppins"}
          fontSize={
            isHeader
              ? { lg: "0.9rem", md: "0.75rem", sm: "0.7rem", base: "0.6rem" }
              : { lg: "1rem", md: "0.8rem", sm: "0.7rem", base: "0.6rem" }
          }
          fontWeight={"normal"}
          textAlign={"center"}
        >
          {title2 ? title2 : ""}
        </Heading>
        <Heading
          w={"23%"}
          color={isHeader ? "#E3E3E3" : "#AFAFAF"}
          textTransform={"uppercase"}
          fontFamily={"Poppins"}
          fontSize={
            isHeader
              ? { lg: "0.9rem", md: "0.75rem", sm: "0.7rem", base: "0.6rem" }
              : { lg: "1rem", md: "0.8rem", sm: "0.7rem", base: "0.6rem" }
          }
          fontWeight={"normal"}
          textAlign={"center"}
        >
          {title3 ? title3 : ""}
        </Heading>
        <Heading
          w={"23%"}
          pr={2}
          color={isHeader ? "#E3E3E3" : "#AFAFAF"}
          textTransform={"uppercase"}
          fontFamily={"Poppins"}
          fontSize={
            isHeader
              ? { lg: "0.9rem", md: "0.75rem", sm: "0.7rem", base: "0.6rem" }
              : { lg: "1rem", md: "0.8rem", sm: "0.7rem", base: "0.6rem" }
          }
          fontWeight={"normal"}
          textAlign={"right"}
        >
          {title4}
        </Heading>
        {title5 && (
          <Heading
            w={"23%"}
            pr={2}
            color={isHeader ? "#E3E3E3" : "#AFAFAF"}
            textTransform={"uppercase"}
            fontFamily={"Poppins"}
            fontSize={
              isHeader
                ? { lg: "0.9rem", md: "0.75rem", base: "0.65rem" }
                : { lg: "1rem", md: "0.8rem", base: "0.65rem" }
            }
            fontWeight={"normal"}
            textAlign={"right"}
          >
            {title5}
          </Heading>
        )}
        {((isHeader && dateOrder)) && (
          // <img src={imgDrop} height={"10px"} width={"10px"} />
         <ArrowDropUp
            onClick={handleSort}
            sx={{ marginTop: "-5px", color: "#f00", cursor: "pointer" }}
          />
          )
          }
          {((isHeader && !dateOrder))  && (<ArrowDropDownIcon
          onClick={handleSort}
          sx={{ marginTop: "-5px", color: "#f00", cursor: "pointer" }}
        />)}
      
        {/* {title5 && isHeader && (
          <HStack>
            <Heading
              w={"23%"}
              pr={2}
              color={isHeader ? "#5F6368" : "#AFAFAF"}
              textTransform={"uppercase"}
              fontFamily={"Poppins"}
              fontSize={
                isHeader
                  ? { lg: "0.9rem", md: "0.75rem", base: "0.65rem" }
                  : { lg: "1rem", md: "0.8rem", base: "0.65rem" }
              }
              fontWeight={"normal"}
              textAlign={"right"}
            >
              {title5}
            </Heading>
            {/* <img src={imgDrop} height={10} width={10} /> */}
        {/* </HStack> */}
        {/* )} */}
      </HStack>
      <Divider my={1} />
    </Box>
  );
};
