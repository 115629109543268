import { color, textTransform } from "@mui/system";
import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import SaasMusicProducer from "../../components/StaticWebSite/SAASPagesComp/SaasMusicProducer";


const SaasProducer = (props) => {


  return (
    <Box w={"100%"} bg={"#000201"}>
        <SaasMusicProducer planFlag ={true}/>

    </Box>
  );
};

export default SaasProducer;