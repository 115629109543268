import { color, textTransform } from "@mui/system";
import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import staticMusic from "../../assets/staticWebsite/staticMusic.png";
import Layout from "../../components/StaticWebSite/LandingPagesComp/Layout";

const MusicProducer = (props) => {
  
  const data = [
    {
      id: 1,
      des: "Finding your next placement or collaborating with any top producer worldwide is easy with United Market. The collaboration process is quick and simple as you can send and receive beats and loops, store files, track projects, and communicate with other producers and artists on the platform.",
    },
    {
      id: 2,
      des: "Offer paid collabs to other producers and get your money on time, every time! Your next opportunity awaits you, whether you charge $100 or $1,000 per project. Potential clients will be able to find you regardless of your genre, beat tone, or any other niche that you fall into.",
    },
    {
      id: 3,
      des: "However, paid collabs aren't the only way to make money on the platform. Monetize your beat and loop-making skills by selling beats, beat packs, and loops to artists on the marketplace. The loop and beat marketplace allows you to swipe left or right on sounds until you find the perfect one. Don't worry about wasting time swiping though, you'll also be able to filter sounds by BPM, key, genre, and more, so finding the right sound is easier than ever.",
    },
    {
      id: 4,
      des: " United Market is your all-in-one collaboration and project management platform. Streamline your current clientele directly to your U.M profile and take advantage of everything we offer!",
    },
  ];

  return (
    <Box w={"100%"} bg={"#000201"}>
        <Layout
        title={'MUSIC PRODUCERS'}
        subtitle={'UNITED MARKET FOR MUSIC PRODUCERS'}
        desc={'Join now to collaborate with thousands music producers on United Market'}
        image={staticMusic}
        content={data}
        />

    </Box>
  );
};

export default MusicProducer;
