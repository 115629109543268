import React from 'react';

function DeleteSvg(props) {
  const { colorSvg } = props;
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 4.98307C14.725 4.70807 11.9333 4.56641 9.15 4.56641C7.49685 4.56656 5.84475 4.65 4.2 4.81641L2.5 4.98307'
        stroke={colorSvg}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.08398 4.1415L7.26732 3.04984C7.40065 2.25817 7.50065 1.6665 8.90898 1.6665H11.0923C12.5007 1.6665 12.609 2.2915 12.734 3.05817L12.9173 4.1415'
        stroke={colorSvg}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.8327 6.25L15.1663 16.0084C15.0747 17.3168 14.9997 18.3334 12.6747 18.3334H7.32467C4.99967 18.3334 4.92467 17.3168 4.83301 16.0084L4.16602 6.25'
        stroke={colorSvg}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.5 13.3335H12.5'
        stroke={colorSvg}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.66602 10H13.3327'
        stroke={colorSvg}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
export default DeleteSvg;
