/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React from "react";

function LogoutIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9.3255 2.5174C8.53508 3.15875 8.04303 4.19631 7.89767 5.88435C7.86213 6.29703 7.49878 6.60277 7.08609 6.56723C6.6734 6.5317 6.36766 6.16834 6.4032 5.75565C6.56784 3.84369 7.15578 2.34625 8.38037 1.35261C9.58426 0.375754 11.2668 0 13.3604 0H13.4904C15.7996 0 17.6114 0.457788 18.827 1.67342C20.0426 2.88905 20.5004 4.70082 20.5004 7.01V13.53C20.5004 15.8392 20.0426 17.6509 18.827 18.8666C17.6114 20.0822 15.7996 20.54 13.4904 20.54H13.3604C11.282 20.54 9.60959 20.1693 8.40816 19.2082C7.18506 18.2297 6.58934 16.7541 6.41367 14.8696C6.37523 14.4572 6.6784 14.0917 7.09082 14.0532C7.50325 14.0148 7.86875 14.318 7.9072 14.7304C8.06153 16.3859 8.55581 17.4053 9.34521 18.0368C10.1563 18.6857 11.4189 19.04 13.3604 19.04H13.4904C15.6513 19.04 16.9695 18.6028 17.7664 17.8059C18.5632 17.0091 19.0004 15.6908 19.0004 13.53V7.01C19.0004 4.84918 18.5632 3.53095 17.7664 2.73408C16.9695 1.93721 15.6513 1.5 13.4904 1.5H13.3604C11.4041 1.5 10.1366 1.85925 9.3255 2.5174Z" fill="#AFAFAF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.12012 10.2578C1.12012 9.8436 1.4559 9.50781 1.87012 9.50781H13.2501C13.6643 9.50781 14.0001 9.8436 14.0001 10.2578C14.0001 10.672 13.6643 11.0078 13.2501 11.0078H1.87012C1.4559 11.0078 1.12012 10.672 1.12012 10.2578Z" fill="#AFAFAF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.63033 6.37592C4.92322 6.66881 4.92322 7.14369 4.63033 7.43658L1.81066 10.2562L4.63033 13.0759C4.92322 13.3688 4.92322 13.8437 4.63033 14.1366C4.33744 14.4295 3.86256 14.4295 3.56967 14.1366L0.21967 10.7866C-0.0732233 10.4937 -0.0732233 10.0188 0.21967 9.72592L3.56967 6.37592C3.86256 6.08303 4.33744 6.08303 4.63033 6.37592Z" fill="#AFAFAF"/>
    </svg>

  );
}

export default LogoutIcon;
