/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Heading,
	HStack,
	Image,
	Menu,
	Text,
	Hidden,
} from 'native-base';
import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import upvoted from '../../assets/Notification/upvoted.png';
import followed from '../../assets/Notification/followed.png';
import purchased from '../../assets/Notification/purchased.png';
import connected from '../../assets/Notification/connected.png';
import subscribed from '../../assets/Notification/subscribed.png';
import download from '../../assets/Notification/download.png';
import favourite from '../../assets/Notification/favourite.png';
import listen from '../../assets/Notification/listen.png';
import play from '../../assets/Notification/play.png';
import shortlist from '../../assets/Notification/shortlist.png';
import slider from '../../assets/Notification/slider.png';
import moment from 'moment/moment';
import useNotification from '../Hooks/Notification/Notification';
import closeIcon from '../../assets/Notification/close-square.png';
import tickIcon from '../../assets/Notification/tick-square.png';
import { Loader } from '../Loader';
import { IconButton, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import {
	acceptOpportunitySplit,
	deleteNotificationById,
	rejectOpportunitySplit,
} from '../Hooks/AudioLibrary/SearchUsers/Query';
import { toast } from 'react-toastify';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
function ViewNotifications() {
	const [notificationId, setNotificationId] = React.useState('');
	const [
		notificationCalled,
		notificationLoading,
		notificationData,
		getNotification,
	] = useNotification();
	const [notifications, setNotifications] = React.useState([]);
	React.useEffect(() => {
		if (!notificationData) {
			getNotification();
		} else {
			setNotifications(notificationData.getMyNotifications.notifications);
			console.log('notification data...', notificationData);
		}
	}, [notificationData]);

	const [deleteNotification] = useMutation(deleteNotificationById, {
		fetchPolicy: 'no-cache',
		context: { clientName: 'user' },
		onCompleted(data) {
			console.log('deleted notification...', data);
			setNotifications(
				notifications.filter(
					(notification) => notification._id !== notificationId
				)
			);
			toast.success('Notification deleted successfully');
		},
		onError(error) {
			toast.error('Something went wrong');
			console.log('error.....', error);
		},
	});

	const handleDeleteNotification = (id) => {
		setNotificationId(id);
		deleteNotification({
			variables: {
				notificationId: id,
			},
		});
	};
	return (
		<Box w={'100%'}>
			<Box w={'90%'} alignSelf={'center'} py={5}>
				<HStack w={'100%'} justifyContent={'space-between'}>
					<Heading
						my={5}
						fontSize={{ xl: '4xl', md: '3xl', base: '2xl' }}
						color={'#fff'}
						textTransform={'capitalize'}
						fontFamily={'poppins'}
					>
						Notifications
					</Heading>
					{/* <HStack>
            <Box alignSelf={"center"} mx={2}>
              <Image source={slider} size={6} />
            </Box>

            <NotificationMenu />
          </HStack> */}
				</HStack>
				<Box my={5}>
					{notificationLoading ? (
						<div className={styles.loaderDiv}>
							<Loader />
						</div>
					) : notifications?.length > 0 ? (
						notifications?.map((notification, key) => (
							<React.Fragment key={key}>
								<NotificationDetail
									id={notification.id}
									notificationId={notification._id}
									type={notification.type}
									title={headingBasedOnType(notification.type)}
									detail={notification.message}
									secondaryId={notification.secondaryId}
									// timeDate={notification.timeDate}
									status={notification.status}
									timeDate={
										moment(notification?.createdAt).format('YYYY-MM-DD') +
										' at ' +
										moment(notification?.createdAt).format('hh:mm:ss A')
									}
									handleDeleteNotification={handleDeleteNotification}
								/>
							</React.Fragment>
						))
					) : (
						<Typography
							style={{ color: '#afafaf', textAlign: 'center', fontSize: 25 }}
						>
							No notifications found
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
}
function NotificationDetail(props) {
	const {
		id,
		notificationId,
		type,
		title,
		detail,
		timeDate,
		status,
		handleDeleteNotification,
		secondaryId,
	} = props;
	console.log('type....', type);
	const [notificationStatus, setNotificationStatus] = React.useState(status);
	const navigate = useNavigate();

	const opportunityToken = localStorage.getItem('opportunityToken');
	const [acceptOpportunitySubmission] = useMutation(acceptOpportunitySplit, {
		fetchPolicy: 'no-cache',
		context: { clientName: 'user' },
		onCompleted(data) {
			console.log('accepted data...', data);
			setNotificationStatus('Accepted');
			toast.success('Split accepted.');
		},
		onError(error) {
			toast.error('Something went wrong');
		},
	});

	const [rejectOpportunitySubmission] = useMutation(rejectOpportunitySplit, {
		fetchPolicy: 'no-cache',
		context: { clientName: 'user' },
		onCompleted(data) {
			console.log('accepted data...', data);
			setNotificationStatus('Rejected');
			toast.success('Split rejected.');
		},
		onError(error) {
			toast.error('Something went wrong');
		},
	});

	const handleAccept = () => {
		acceptOpportunitySubmission({
			variables: {
				notificationId: notificationId,
				reactionAccessToken: opportunityToken,
			},
		});
		console.log('accepted.........');
	};

	const handleReject = () => {
		rejectOpportunitySubmission({
			variables: {
				notificationId: notificationId,
			},
		});
		console.log('rejected ...');
	};

	const handleView = (id) => {
		// let url = "";// type === "opportunitySubmission"
		if (
			type === 'upvotedOpportunity' ||
			type === 'opportunityCreated' ||
			type === 'opportunitySubmitted'
		) {
			return (window.location.href = `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/view/${id}`);
		} else if (type === 'opportunitySubmission') {
			return (window.location.href = `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/view/${secondaryId}`);
		}
	};

	return (
		<Box w={'100%'}>
			<HStack w={'100%'} justifyContent={'space-between'} my={3}>
				<Box alignSelf={'center'}>
					<Button
						p={0.5}
						mr={3}
						alignSelf={'center'}
						borderColor={'#f00'}
						borderWidth={2}
						rounded={'lg'}
						variant={'ghost'}
						_pressed={{ bg: 'transparent' }}
						_focus={{ bg: 'transparent' }}
						_hover={{ bg: 'transparent', borderColor: '#ff000070' }}
						onPress={(e) => handleDeleteNotification(notificationId)}
					>
						<CloseRoundedIcon
							style={{
								color: '#f00',
								fontSize: '14',
							}}
						/>
					</Button>
				</Box>
				<Box alignSelf={'center'}>
					<TypeOfNotification type={type} />
				</Box>
				<Box mx={4} w={'70%'} alignSelf={'flex-start'}>
					<HStack my={1}>
						<Heading
							alignSelf={'center'}
							fontSize={{ xl: 'lg', base: 'sm' }}
							fontWeight={'medium'}
							fontFamily={'Poppins'}
							textAlign={'left'}
							textTransform={'capitalize'}
							color={'#e3e3e3'}
						>
							{title + ' '}
						</Heading>
						{(type === 'upvotedOpportunity' ||
							type === 'opportunityCreated' ||
							type === 'opportunitySubmitted' ||
							type === 'opportunitySubmission') && (
							<Button
								alignSelf={'center'}
								p={0}
								variant={'ghost'}
								_text={{
									underline: 2,
									color: '#2ebb55',
									fontSize: { xl: 'lg', base: 'sm' },
									fontWeight: 'medium',
									fontFamily: 'Poppins',
									textAlign: 'left',
									textTransform: 'capitalize',
								}}
								_hover={{
									bg: 'transparent',
									_text: { color: '#2ebb5570' },
								}}
								_pressed={{ bg: 'transparent' }}
								_focus={{ bg: 'transparent' }}
								onPress={() => handleView(id)}
							>
								{'view'}
							</Button>
						)}
					</HStack>
					<Heading
						alignSelf={'flex-start'}
						fontSize={{ xl: 'md', base: 'sm' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'left'}
						textTransform={'capitalize'}
						color={'#afafaf'}
					>
						{detail}
					</Heading>
				</Box>
				<Hidden from='base' till='md'>
					<Box justifyContent={'flex-start'}>
						<HStack justifyContent={'flex-end'}>
							<QueryBuilderRoundedIcon
								style={{ color: '#AFAFAF', fontSize: 18 }}
							/>
							<Heading
								fontWeight={'medium'}
								fontSize={'sm'}
								fontFamily={'Poppins'}
								textAlign={'left'}
								color={'#afafaf'}
								mx={2}
							>
								{timeDate}
							</Heading>
						</HStack>
						{notificationStatus === 'Pending' && (
							<HStack>
								<IconButton onClick={handleAccept}>
									<img src={tickIcon} alt='' />
								</IconButton>
								<IconButton onClick={handleReject}>
									<img src={closeIcon} alt='' />
								</IconButton>
								{/* <Button
              p={0.5}
              mr={3}
              alignSelf={"center"}
              _pressed={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              _hover={{ bg: "transparent", borderColor: "#ff000070" }}
            >
              <img src={closeIcon} alt="" />
            </Button> */}
							</HStack>
						)}
					</Box>
				</Hidden>
			</HStack>
			<Hidden from='md'>
				<Box justifyContent={'flex-start'} mt={1} mb={5}>
					<HStack>
						<QueryBuilderRoundedIcon
							style={{ color: '#AFAFAF', fontSize: 18 }}
						/>
						<Heading
							fontWeight={'medium'}
							fontSize={'sm'}
							fontFamily={'Poppins'}
							textAlign={'left'}
							color={'#afafaf'}
							mx={2}
						>
							{timeDate}
						</Heading>
					</HStack>
					{notificationStatus === 'Pending' && (
						<HStack>
							<IconButton onClick={handleAccept}>
								<img src={tickIcon} alt='' />
							</IconButton>
							<IconButton onClick={handleReject}>
								<img src={closeIcon} alt='' />
							</IconButton>
						</HStack>
					)}
				</Box>
			</Hidden>
			{/* <HStack
        w={"30%"}
        alignSelf={"flex-end"}
        justifyContent={"space-between"}
        my={3}
      >
        <Button
          size={"sm"}
          bg="#000201"
          borderStyle={"solid"}
          borderWidth={"1"}
          borderColor={"#2EBA55"}
          w={"45%"}
          rounded={"md"}
          alignSelf={"center"}
          _text={{
            color: "#2EBA55",
            fontSize: {
              lg: "18px",
              md: "16px",
              sm: "14px",
              base: "14px",
            },
            fontFamily: "Poppins",
            textTransform: "uppercase",
          }}
          _hover={{
            bg: "#2EBA55",
            _text: {
              color: "#fff",
            },
          }}
          _focused={{ bg: "#2EBA55" }}
          _pressed={{ bg: "transparent" }}
        >
          Reject
        </Button>
        <Button
          size={"sm"}
          bg="#2EBA55"
          borderWidth={1}
          borderColor="#2EBA55"
          _text={{
            color: "#fff",
            fontSize: {
              lg: "18px",
              md: "16px",
              sm: "14px",
              base: "14px",
            },
            fontFamily: "Poppins",
            textTransform: "uppercase",
          }}
          _hover={{
            bg: "transparent",
            _text: {
              color: "#2EBA55",
            },
          }}
          w={"45%"}
          rounded={"md"}
          alignSelf={"center"}
          _focused={{ bg: "#2EBA55" }}
          _pressed={{ bg: "#2EBA55" }}
        >
          Accept
        </Button>
      </HStack> */}
		</Box>
	);
}
function TypeOfNotification(props) {
	const { type } = props;
	if (
		type === 'upvoted' ||
		type === 'opportunityCreated' ||
		type === 'upvotedOpportunity'
	)
		return <Image source={upvoted} size={6} alignSelf={'center'} />;
	else if (type === 'userFollowed' || type === 'userUnfollowed')
		return <Image source={followed} size={6} alignSelf={'center'} />;
	else if (type === 'purchased')
		return <Image source={purchased} size={6} alignSelf={'center'} />;
	else if (type === 'audioDownloaded') {
		return <Image source={download} size={6} alignSelf={'center'} />;
	} else if (type === 'audioOpened') {
		return <Image source={play} size={6} alignSelf={'center'} />;
	} else if (type === 'listenedAudio') {
		return <Image source={listen} size={6} alignSelf={'center'} />;
	} else if (type === 'connected')
		return <Image source={connected} size={6} alignSelf={'center'} />;
	else if (type === 'subscribed')
		return <Image source={subscribed} size={6} alignSelf={'center'} />;
	else {
		return <Image source={subscribed} size={6} alignSelf={'center'} />;
	}
}
function NotificationMenu() {
	return (
		<Menu
			rounded={'lg'}
			borderColor={'#fff'}
			borderWidth={1}
			alignSelf={'center'}
			color={'#afafaf'}
			// fontSize={{ md: '16px' }}
			w={'400px'}
			mx={3}
			_text={{
				color: '#afafaf',
			}}
			background={'#1C1B1B'}
			placement={'bottom left'}
			trigger={(triggerProps) => {
				return (
					<Button
						variant={'ghost'}
						rounded={'md'}
						_hover={{ bg: 'transparent' }}
						_focused={{ bg: '2ebb55' }}
						_pressed={{ bg: 'transparent' }}
						{...triggerProps}
					>
						<SettingsOutlinedIcon style={{ color: '#e3e3e3', fontSize: 25 }} />
					</Button>
				);
			}}
		>
			<Menu.Item
				bg={'#1C1B1B'}
				borderBottomWidth='1px'
				borderBottomColor='#afafaf'
				m={0}
				p={0}
				_focus={{
					bg: '#1C1B1B',
				}}
				_hover={{
					bg: '#1C1B1B',
				}}
				_pressed={{
					bg: '#1C1B1B',
				}}
			>
				<Heading
					my={2}
					fontSize={{ xl: 'xl', base: 'md' }}
					fontWeight={'normal'}
					fontFamily={'Poppins'}
					textAlign={'left'}
					textTransform={'capitalize'}
					color={'#fff'}
				>
					subscriber notification Settings
				</Heading>
			</Menu.Item>

			<Menu.Item
				bg={'#1C1B1B'}
				m={0}
				p={0}
				_focus={{
					bg: '#1C1B1B',
				}}
				_hover={{
					bg: '#1C1B1B',
				}}
				_pressed={{
					bg: '#1C1B1B',
				}}
			>
				<Checkbox
					value='one'
					p={0}
					my={1}
					colorScheme={'green'}
					_text={{
						color: '#AFAFAF',
						fontSize: {
							lg: '14px',
							md: '14px',
							base: '12px',
						},
						fontWeight: 'normal',
						fontFamily: 'Poppins',
					}}
				>
					Notify subscribers when I post a new beat or kit
				</Checkbox>
			</Menu.Item>
			<Menu.Item
				bg={'#1C1B1B'}
				m={0}
				p={0}
				_focus={{
					bg: '#1C1B1B',
				}}
				_hover={{
					bg: '#1C1B1B',
				}}
				_pressed={{
					bg: '#1C1B1B',
				}}
			>
				<Checkbox
					value='one'
					p={0}
					my={1}
					colorScheme={'green'}
					_text={{
						color: '#AFAFAF',
						fontSize: {
							lg: '14px',
							md: '14px',
							base: '12px',
						},
						fontWeight: 'normal',
						fontFamily: 'Poppins',
					}}
				>
					Notify subscribers when I add a new service
				</Checkbox>
			</Menu.Item>
			<Menu.Item
				bg={'#1C1B1B'}
				m={0}
				p={0}
				_focus={{
					bg: '#1C1B1B',
				}}
				_hover={{
					bg: '#1C1B1B',
				}}
				_pressed={{
					bg: '#1C1B1B',
				}}
			>
				<Checkbox
					value='one'
					p={0}
					my={1}
					colorScheme={'green'}
					_text={{
						color: '#AFAFAF',
						fontSize: {
							lg: '14px',
							md: '14px',
							base: '12px',
						},
						fontWeight: 'normal',
						fontFamily: 'Poppins',
					}}
				>
					Notify subscribers when I change my pricing
				</Checkbox>
			</Menu.Item>
			<Menu.Item
				bg={'#1C1B1B'}
				m={0}
				p={0}
				_focus={{
					bg: '#1C1B1B',
				}}
				_hover={{
					bg: '#1C1B1B',
				}}
				_pressed={{
					bg: '#1C1B1B',
				}}
			>
				<Checkbox
					value='one'
					p={0}
					my={1}
					colorScheme={'green'}
					_text={{
						color: '#AFAFAF',
						fontSize: {
							lg: '14px',
							md: '14px',
							base: '12px',
						},
						fontWeight: 'normal',
						fontFamily: 'Poppins',
					}}
				>
					Notify subscribers when I post an opportunity
				</Checkbox>
			</Menu.Item>
		</Menu>
	);
}

export const headingBasedOnType = (type) => {
	switch (type) {
		case 'audioDownloaded':
			return 'Audio Downloaded';

		case 'userFollowed':
			return 'Followed You';

		case 'userUnfollowed':
			return 'Unfollowed You';

		case 'listenedAudio':
			return 'Audio Listened';

		case 'upvotedOpportunity':
			return 'Upvote recieved on opportunity';

		case 'audioOpened':
			return 'Audio Viewed';

		case 'opportunityCreated':
			return 'New Opportunity Created';

		case 'opportunitySubmission':
			return 'Collaboration Request';

		case 'opportunitySubmitted':
			return 'Submission Recieved On Your Opportunity';
		default:
			return 'Notification';
	}
};

export default ViewNotifications;
