import React from 'react';
import { Grid } from '@mui/material';

import styles from './styles.module.css';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import CompareArtist from './CompareArtist';

const CompareArtistLayout = () => {
	return (
		<>
			<Grid className={styles.bgBlack}>
				<Sidebar
					activeText='Talent Dashboard'
					childComponent={<CompareArtist />}
				/>
			</Grid>
		</>
	);
};
export default CompareArtistLayout;
