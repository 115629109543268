import { Box } from 'native-base';
import React, { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
export const AuthContext = createContext();
function Layout(props) {
	const [activeStep, setActiveStep] = useState(0);
	const [accountType, setAccountType] = useState('');
	const [isPassword, setIsPassword] = useState(true);
	const [Password, setPassword] = useState('');
	const [showCalender, setshowCalender] = useState(false);
	const [rolesID, SetRoleID] = useState('');
	const [isSimpleSignup, setIsSimpleSignup] = useState(true);
	const [Email, setEmail] = useState('');
	const [userId, setUserId] = useState(false);
	const [showmodal, setshowmodal] = useState(false);
	const [labelName, setLabel] = useState('');
	const [errmsg, seterrmsg] = useState(false);
	const [radioBtnValue, SetradioBtnValue] = useState('no');
	const [instaMail, setInstaMail] = useState('');
	const [NewRoleId, SetNewRoleId] = useState(null);
	const [errMsg, setErrMsg] = useState('');
	const [phoneNum, setPhoneNum] = useState('');
	const [isEmail, setIsEmail] = useState(false);
	const [selectedroleid, setselectedroleID] = useState(null);
	const [selectedHear, setselectedHear] = useState(null);
	const [genre, setGenre] = useState(null);
	const [selectedGenre, setselectedGenre] = useState(null);
	const [disableBtn, setIsDisableBtn] = useState(false);
	const [resetByPhone, setResetByPhone] = useState(false);
	const [registredEmail, setRegisteredEmail] = useState('');
	const [registredphone, setRegisteredphone] = useState('');
	const [otpvalue, setOtpValue] = useState('');
	const [sendotpAgain, setSendOtpAgain] = useState(false);
	const [countrycode, setCountrycode] = useState('');
	const [phonenum, setphonenum] = useState('');
	const [picture, setPicture] = useState('');
	const [SignUpEmailPhone, SetSignUpEmailPhone] = useState(false);
	const [username, setUsername] = useState('');
	const [selectedAccount, setselectedAccount] = useState('');
	const [genreField, setgenreField] = useState();
	const [socialField, setSocialField] = useState();
	const [othersSocial, setothersSocial] = useState(false);
	const [othersGenre, setothersGenre] = useState(false);
	const [purchased, setPurchased] = useState(false);
	const [Subscriptionprice, setSubscriptionprice] = useState('');
	const [priceID, setPriceId] = useState('');
	const [userID, setUserID] = useState('');
	const [progressbarvalue, setProgressbarValue] = useState(0);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [selectedPublish, setSelectedPublish] = useState('');
	const [showStripeForm, setShowStripeForm] = useState(false);
	const [labelPart, setLabelPart] = useState(null);
	const [showLabelPart, setShowLabelPart] = useState(false);
	const [noOfMonthlyListeners, setNoOfMonthlyListeners] = useState(null);
	const [spotifyUrl, setSpotifyUrl] = useState(null);
	const [otherHear, setOtherHear] = useState('');
	const [selectedHearText, setSelectedHearText] = useState('');
	const [showOtherHear, setShowOtherHear] = useState(false);
	const { childComp } = props;
	// const { step, userToken } = useParams();
	const url = new URL(window.location.href);
	const stepValue = url.searchParams.get('step');
	const userid = url.searchParams.get('userToken');
	const priceid = url.searchParams.get('priceId');
	// console.log(userid);
	useEffect(() => {
		if (stepValue) {
			setActiveStep(Number(stepValue));
			setUserID(userid);
			setPriceId(priceid);
			setProgressbarValue(Number(stepValue));
		}
		// return () => {
		//   second
		// }
	}, [stepValue, userId, priceid]);
	return (
		<AuthContext.Provider
			value={{
				activeStep,
				setActiveStep,
				accountType,
				setAccountType,
				rolesID,
				SetRoleID,
				isPassword,
				setIsPassword,
				showCalender,
				setshowCalender,
				Email,
				setEmail,
				instaMail,
				setInstaMail,
				phoneNum,
				setPhoneNum,
				Password,
				setPassword,
				NewRoleId,
				SetNewRoleId,
				isSimpleSignup,
				setIsSimpleSignup,
				userId,
				setUserId,
				showmodal,
				setshowmodal,
				errmsg,
				seterrmsg,
				radioBtnValue,
				SetradioBtnValue,
				labelName,
				setLabel,
				errMsg,
				setErrMsg,
				isEmail,
				setIsEmail,
				selectedroleid,
				setselectedroleID,
				selectedHear,
				setselectedHear,
				disableBtn,
				setIsDisableBtn,
				resetByPhone,
				setResetByPhone,
				registredEmail,
				setRegisteredEmail,
				registredphone,
				setRegisteredphone,
				otpvalue,
				setOtpValue,
				sendotpAgain,
				countrycode,
				setCountrycode,
				setSendOtpAgain,
				phonenum,
				setphonenum,
				SignUpEmailPhone,
				SetSignUpEmailPhone,
				picture,
				setPicture,
				selectedAccount,
				setselectedAccount,
				selectedGenre,
				setselectedGenre,
				genre,
				setGenre,
				othersSocial,
				setothersSocial,
				othersGenre,
				setothersGenre,
				purchased,
				setPurchased,
				Subscriptionprice,
				setSubscriptionprice,
				priceID,
				setPriceId,
				username,
				setUsername,
				genreField,
				setgenreField,
				socialField,
				setSocialField,
				userID,
				progressbarvalue,
				setProgressbarValue,
				firstName,
				setFirstName,
				lastName,
				setLastName,
				selectedPublish,
				setSelectedPublish,
				showStripeForm,
				setShowStripeForm,
				labelPart,
				setLabelPart,
				showLabelPart,
				setShowLabelPart,
				noOfMonthlyListeners,
				setNoOfMonthlyListeners,
				spotifyUrl,
				setSpotifyUrl,
				otherHear,
				setOtherHear,
				setSelectedHearText,
				selectedHearText,
				showOtherHear,
				setShowOtherHear,
			}}
		>
			{childComp}
		</AuthContext.Provider>
	);
}

export default Layout;
