import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Select,
} from 'native-base';
import React, { useState } from 'react';
import ButtonComponent from '../../ButtonComponent';
import { FeedbackModal } from './FeedbackModal';

const SoonBody = (props) => {
	const { title, image } = props;
	const [modalFlag, setModalFlag] = useState(false);
	return (
		<Box w={'100%'} bg={'#000201'}>
			<Box w={'95%'} alignSelf={'center'}>
				<Heading
					my={5}
					textTransform={'capitalize'}
					fontSize={{ lg: '4xl', base: '2xl' }}
					fontWeight={'medium'}
					textAlign={'left'}
					color={'#fff'}
				>
					{title}
				</Heading>
				<Image
					mt={10}
					h={{ lg: '550px', md: '400px', base: '350px' }}
					w={'90%'}
					bg={'#000'}
					opacity={0.9}
					resizeMode='cover'
					source={image}
					alt='image'
				/>

				<Heading
					my={5}
					textTransform={'capitalize'}
					fontSize={{ lg: '4xl', base: '2xl' }}
					fontWeight={'medium'}
					textAlign={'center'}
					color={'#fff'}
				>
					Coming Soon
				</Heading>
				<Button
					_hover={{
						backgroundColor: 'transparent',
						borderColor: '#2EBB55',
						borderWidth: 1,
					}}
					w={'15%'}
					alignSelf={'flex-end'}
					bg={'#2EBB55'}
					py={3}
					onPress={() => {
						setModalFlag(!modalFlag);
					}}
				>
					<Heading
						fontSize={'xl'}
						fontWeight={'medium'}
						color={'#e3e3e3'}
						textAlign={'center'}
					>
						Feedback
					</Heading>
				</Button>
			</Box>
			<FeedbackModal
				modalFlag={modalFlag}
				close={() => setModalFlag(!modalFlag)}
			/>
		</Box>
	);
};
export default SoonBody;
