import { VStack, Text, Image, HStack, Input, Button } from 'native-base';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BsFillSuitHeartFill } from 'react-icons/bs';
import { useState, useCallback } from 'react';
import { Modal } from 'native-base';
import { useContext } from 'react';
// import { getLyrics, getSong } from 'genius-lyrics-api';
import {
	FaPause,
	FaFastBackward,
	FaFastForward,
	FaStepBackward,
	FaStepForward,
	FaPlay,
} from 'react-icons/fa';
import moment from 'moment';
import ReactAudioPlayer from 'react-audio-player';
import AudioRecived from './AudioRecived';
// import AuthContext from './AudioRecived';
import axios from 'axios';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const AudioFeedback = (props) => {
	const { id } = useParams();
	console.log('propsll..........', props);
	console.log('id-->', id);
	const audioFileObject = props.audioObj;
	console.log('audioObj file', props.audioObj);
	const fileId = audioFileObject?.file._id;
	console.log('fileid.......', fileId);
	const audioUrl = audioFileObject?.file.url;
	const fileName = audioFileObject?.file.fileName.replace(/\.[^/.]+$/, '');
	console.log('fileId, audioUrl, fileName ', fileId, audioUrl, fileName);
	const headerkey = `Bearer ${localStorage.getItem('token')}`;
	const [selectedPoint, setSelectedPoint] = React.useState('00.00');
	const [toggleHeart, setToggleHeart] = useState(false);
	const [showReceived, setRecived] = useState(false);
	const [viewComments, setViewComments] = useState([]);
	const [viewReplies, setViewReplies] = useState([]);
	const [createReplies, setCreateReplies] = useState([]);
	const [showCommentBox, setCommentBox] = useState(false);
	const [commentText, setCommentText] = useState('');
	const [replyText, setReplyText] = useState('');
	const [showReplies, setShowReplies] = useState(false);
	const [saveCommentId, setCommId] = useState('');
	const [songLyrics, setSongLyrics] = useState('');
	const [replyBox, setReplyBox] = useState(false);

	const handleRecived = () => {
		setRecived(true);
	};
	const changeColor = () => {
		setToggleHeart(!toggleHeart);
	};
	const getCommentText = (val) => {
		setCommentText(val.target.value);
	};
	const getReplyText = (val) => {
		setReplyText(val.target.value);
	};

	const useAudio = (url) => {
		const [audio] = useState(new Audio(url));
		const [playing, setPlaying] = useState(false);
		const toggle = () => setPlaying(!playing);

		useEffect(() => {
			playing ? audio.play() : audio.pause();
		}, [playing]);

		useEffect(() => {
			audio.addEventListener('ended', () => setPlaying(false));
			return () => {
				audio.removeEventListener('ended', () => setPlaying(false));
			};
		}, []);
		return [playing, toggle];
	};

	const [playing, toggle] = useAudio(audioUrl);

	const getComments = (fileId) => {
		console.log(fileId);
		var commentsHeader = new Headers();
		commentsHeader.append('Authorization', `${headerkey}`);

		var requestComments = {
			method: 'GET',
			headers: commentsHeader,
			redirect: 'follow',
		};

		return fetch(
			`${process.env.REACT_APP_API_URL}/file/getcomments/${fileId}`,
			requestComments
		)
			.then((response) => response.json())
			.then((result) => setViewComments(result.comments))
			.catch((error) => console.log('error', error));
	};
	const createComment = (fileId) => {
		var data = {
			text: commentText,
			startDuration: '00:00',
			endDuration: selectedPoint,
		};

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/createcomment/${fileId}`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
				getComments(fileId);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getReplies = (commId) => {
		console.log('commentid', commId);
		var requestReplies = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		return fetch(
			`${process.env.REACT_APP_API_URL}/file/getreplies/${commId}`,
			requestReplies
		)
			.then((response) => response.json())
			.then((result) => setViewReplies(result.replies))
			.catch((error) => console.log('error', error));
	};

	const createReply = (commId) => {
		var data = {
			text: replyText,
		};

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/replycomment/${commId}`,
			headers: { Authorization: headerkey },

			data: data,
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
				getReplies(fileId);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const likeComment = (commId) => {
		var axios = require('axios');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/likecomment/${commId}`,
			headers: { Authorization: headerkey },
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSongLyrics = () => {
		const lyricsFinder = require('lyrics-finder');
		(async function (artist, title) {
			let lyrics = (await lyricsFinder(artist, title)) || 'Not Found!';
			console.log(lyrics);
		})('', 'Night Changes');
		// const options = {
		// 	apiKey:
		// 		'zX9C7lYiuBWGJaL1BaBVJssr7eXbFuJ3dG0nsLdvh0pJTo7Ao63iMs4i-HrD1H29',
		// 	title: 'Blinding Lights',
		// 	artist: '',
		// 	optimizeQuery: true,
		// };
		// getLyrics(options).then((lyrics) => console.log(lyrics));
		// getSong(options).then((song) =>
		// 	// console.log(`
		// 	// ${song.id}
		// 	// ${song.title}
		// 	// ${song.url}
		// 	// ${song.albumArt}
		// 	// ${song.lyrics}`);
		// 	setSongLyrics(`${song.lyrics}`)
		// );
	};

	useEffect(() => {
		getComments(fileId);
		getReplies(fileId);
		// getSongLyrics();
	}, []);

	return (
		<>
			{showReceived ? (
				<AudioRecived />
			) : (
				<HStack
					justifyContent={'center'}
					alignSelf={'center'}
					w={['100%', '90%', '95%']}
				>
					<VStack w='60%'>
						<HStack marginBottom={10}>
							<AudioPlayer
								url={audioUrl}
								name={fileName}
								setCommentBox={setCommentBox}
								setSelectedPoint={setSelectedPoint}
							/>
						</HStack>
						{/* <HStack
							rounded={'md'}
							alignSelf={'center'}
							bgColor={'#1C1B1B'}
							w='100%'
							h={24}
							overflow={'clip'}
						>
							<VStack alignSelf={'center'}>
								<Text
									fontSize={{ lg: '24px', md: '18px', sm: '12px' }}
									color='#afafaf'
									justifyContent={'flex-start'}
									px={[2, 0, 10]}
								>
									{fileName}
								</Text>
								<Text
									fontSize={{
										lg: '18px',
										md: '16px',
										sm: '12px',
										base: '18px',
									}}
									color='#afafaf'
									justifyContent={'flex-start'}
									px={[2, 0, 10]}
								>
									Today 10:25 am
								</Text>
							</VStack>
							<HStack
								w={['30%', '25%', '45%']}
								px={[2, 2, 10]}
								justifyContent={'space-between'}
								alignSelf={'left'}
							>
								<FaFastBackward
									size={[30]}
									style={{
										cursor: 'pointer',
										alignSelf: 'center',
										color: '#afafaf',
									}}
								/>
								<FaStepBackward
									size={30}
									style={{
										cursor: 'pointer',
										alignSelf: 'center',
										color: '#afafaf',
									}}
								/>
								{playing ? (
									<FaPause
										size={30}
										style={{
											cursor: 'pointer',
											alignSelf: 'center',
											color: 'white',
										}}
										onClick={toggle}
									/>
								) : (
									<FaPlay
										size={30}
										style={{
											cursor: 'pointer',
											alignSelf: 'center',
											color: 'white',
										}}
										onClick={toggle}
									/>
								)}
								<FaStepForward
									size={30}
									style={{
										cursor: 'pointer',
										alignSelf: 'center',
										color: '#afafaf',
									}}
								/>
								<FaFastForward
									size={30}
									style={{
										cursor: 'pointer',
										alignSelf: 'center',
										color: '#afafaf',
									}}
								/>
							</HStack>
							<Button
								size='sm'
								bg={'#2EBA55'}
								h={'3/5'}
								mx={['0', '2', '5']}
								alignSelf={'center'}
								justifyContent={'flex-end'}
								rounded={'md'}
								_hover={{ bg: '#1C1B1B' }}
								_pressed={{ bg: '#2EBA55' }}
							>
								<Text
									fontSize={{
										lg: '24px',
										md: '20px',
										sm: '16px',
										base: '18px',
									}}
									color='white'
									alignSelf={'center'}
								>
									Share
								</Text>
							</Button>
						</HStack> */}

						<HStack
							alignSelf={'center'}
							mt={10}
							w='100%'
							justifyContent={'space-between'}
						>
							<HStack>
								<Button
									onPress={() => {
										handleRecived();
									}}
									size={'sm'}
									bg={'#000'}
									w={['5%', '10%', '10%']}
									fontSize={{ lg: '16px', md: '16px', sm: '32px' }}
									alignSelf={'center'}
									_hover={{ bg: 'transparent' }}
									_focused={{ bg: 'transparent' }}
									_pressed={{ bg: 'transparent' }}
								>
									<Image
										source={require('../../assets/Icons/arrowLeft.png')}
										size={['5', '7', '8']}
										mt='0.5'
										opacity={'2'}
										alt='back'
									/>
								</Button>
								<Text
									fontSize={{
										lg: '48px',
										md: '20px',
										sm: '16px',
										base: '18px',
									}}
									color='white'
									alignSelf={'center'}
									justifyContent={'flex-start'}
									px={10}
								>
									Comments
								</Text>
							</HStack>
							<Button
								size='lg'
								bg={'transparent'}
								rounded={'md'}
								borderColor={'#2EBA55'}
								borderWidth={'1px'}
								_hover={{ bg: '#1C1B1B' }}
								_pressed={{ bg: '#transparent' }}
								alignSelf={'flex-end'}
								onPress={() => {
									setCommentBox(true);
								}}
							>
								<Text
									fontSize={{
										lg: '24px',
										md: '16px',
										sm: '12px',
										base: '18px',
									}}
									color='#2EBA55'
								>
									Add Comments
								</Text>
							</Button>
						</HStack>

						<HStack
							w={'100%'}
							justifyContent={'space-between'}
							alignSelf={'flex-start'}
							flexDir={'row'}
							flexWrap={'wrap'}
						>
							{viewComments &&
								viewComments.map((commentObj, index) => (
									<HStack
										key={index}
										rounded={'md'}
										alignSelf={'flex-start'}
										bgColor={'#1C1B1B'}
										w={72}
										py={'5'}
										my={5}
									>
										<VStack w={'20%'} px={2} marginLeft={1}>
											{/* <Image
             
            //  size={12} borderRadius={200}
             height={{ lg: "80px", sm: "50px", base: "30px" }}
             width={{ lg: "80px", sm: "50px", base: "30px" }}
             alignSelf={"center"}
             source={require("../../assets/Profile/profile.png")}
              size={["8", "10", "12"]}
             m="0.5"
            /> */}
											<Image
												source={require('../../assets/Profile/profile.png')}
												size={['8', '10', '12']}
												m='0.5'
												alt='user profile'
											/>
										</VStack>

										<VStack w={'80%'} px={2}>
											<Text
												fontSize={{ lg: '18px', md: '16px', base: '12px' }}
												fontStyle={'bold'}
												fontWeight={200}
												color='white'
											>
												{commentObj.userInfo.firstname}
											</Text>
											<Text
												fontSize={{ lg: '16px', md: '14px', base: '10px' }}
												color='#afafaf'
											>
												{moment(commentObj.comment.createdAt).format(
													'DD MMMM YYYY'
												)}
											</Text>
											<Text
												fontSize={{ lg: '16px', md: '14px', base: '10px' }}
												color='white'
												py={1}
												px={1}
											>
												{commentObj.comment.text}
											</Text>
											<HStack>
												<BsFillSuitHeartFill
													size={20}
													style={{ cursor: 'pointer', alignSelf: 'center' }}
													onClick={changeColor}
													color={toggleHeart ? '#C51104' : '#e4e5e9'}
												/>

												<Button
													bg={'transparent'}
													border={'transparent'}
													_hover={{ bg: 'transparent' }}
													_focus={{ bg: 'transparent' }}
													_pressed={{ bg: 'transparent' }}
													onPress={() => {
														setReplyBox(true);
														setCommId(commentObj.comment._id);
													}}
												>
													<Text
														fontSize={{ lg: '16px', md: '12px', sm: '12px' }}
														color={'#afafaf'}
													>
														Reply
													</Text>
												</Button>
												<Button
													onPress={() => {
														console.log(commentObj.comment._id);
														getReplies(commentObj.comment._id);
														setCommId(commentObj.comment._id);
														showReplies &&
														saveCommentId === commentObj.comment._id
															? setShowReplies(false)
															: setShowReplies(true);
													}}
													bg={'transparent'}
													border={'transparent'}
													_hover={{ bg: 'transparent' }}
													_focus={{ bg: 'transparent' }}
													_pressed={{ bg: 'transparent' }}
												>
													<Text
														fontSize={{ lg: '16px', md: '12px', sm: '12px' }}
														color={'#afafaf'}
													>
														{showReplies &&
														saveCommentId === commentObj.comment._id
															? 'Hide Replies'
															: 'View Replies'}
													</Text>
												</Button>
											</HStack>
											{saveCommentId === commentObj.comment._id ? (
												<VStack>
													{showReplies &&
														viewReplies &&
														viewReplies.map((replyObj, index) => (
															<>
																<HStack key={index} w={'100%'}>
																	<VStack w={'20%'}>
																		<Image
																			source={require('../../assets/Profile/profile.png')}
																			size={['8', '10', '12']}
																			m='0.5'
																			alt='user profile'
																		/>
																	</VStack>
																	<VStack w={'80%'} px={3}>
																		<Text
																			fontSize={{
																				lg: '18px',
																				md: '16px',
																				base: '12px',
																			}}
																			fontStyle={'bold'}
																			fontWeight={200}
																			color='white'
																		>
																			{replyObj.userInfo.firstname}
																		</Text>
																		<Text
																			fontSize={{
																				lg: '16px',
																				md: '14px',
																				base: '10px',
																			}}
																			color='#afafaf'
																		>
																			{moment(replyObj.reply.createdAt).format(
																				'DD MMMM YYYY'
																			)}
																		</Text>
																		<Text
																			fontSize={{
																				lg: '16px',
																				md: '14px',
																				base: '10px',
																			}}
																			color='white'
																			py={1}
																			px={1}
																		>
																			{replyObj.reply.text}
																		</Text>
																		<HStack>
																			<BsFillSuitHeartFill
																				size={20}
																				style={{
																					cursor: 'pointer',
																					alignSelf: 'center',
																				}}
																				onClick={changeColor}
																				color={
																					toggleHeart ? '#C51104' : '#e4e5e9'
																				}
																			/>

																			<Button
																				bg={'transparent'}
																				border={'transparent'}
																				_hover={{ bg: 'transparent' }}
																				_focus={{ bg: 'transparent' }}
																				_pressed={{ bg: 'transparent' }}
																			>
																				<Text
																					fontSize={{
																						lg: '16px',
																						md: '12px',
																						sm: '12px',
																					}}
																					color={'#afafaf'}
																				>
																					Reply
																				</Text>
																			</Button>
																		</HStack>
																	</VStack>
																</HStack>
															</>
														))}
												</VStack>
											) : null}
											{saveCommentId === commentObj.comment._id && replyBox ? (
												<VStack>
													<Input
														onChange={getReplyText}
														my={2}
														variant='unstyled'
														placeholder='Reply to this comment'
														backgroundColor={'transparent'}
														borderColor='#afafaf'
														borderWidth={2}
														_important={{
															backgroundColor: 'transparent',
															borderColor: '#afafaf',
															borderWidth: '2',
														}}
														// w={'60%'}
														focusOutlineColor={'#afafaf'}
														color='white'
														fontSize={{ lg: '16px', md: '12px' }}
													/>
													<HStack
														w={'70%'}
														alignContent={'flex-end'}
														justifyContent={'space-around'}
													>
														<Button
															onPress={() => {
																setReplyBox(false);
															}}
															size='sm'
															// w={'30%'}
															rounded={'md'}
															bg={'transparent'}
															borderColor={'#afafaf'}
															_hover={{ bg: '#1C1B1B' }}
															_focused={{ bg: '#2EBA55' }}
															_pressed={{ bg: 'transparent' }}
															alignSelf={'flex-end'}
															borderWidth={'1px'}
														>
															<Text
																fontSize={{
																	lg: '16px',
																	md: '12px',
																	sm: '8px',
																	base: '10px',
																}}
																color='white'
															>
																Cancel
															</Text>
														</Button>
														<Button
															onPress={() => {
																createReply(commentObj.comment._id);
																setReplyBox(false);
															}}
															size='sm'
															// w={'30%'}
															bg={'#2EBA55'}
															rounded={'md'}
															_hover={{ bg: '#1C1B1B' }}
															_focused={{ bg: '#2EBA55' }}
															_pressed={{ bg: '#2EBA55' }}
															alignSelf={'flex-end'}
														>
															<Text
																fontSize={{
																	lg: '16px',
																	md: '12px',
																	sm: '8px',
																	base: '10px',
																}}
																color='white'
															>
																Reply
															</Text>
														</Button>
													</HStack>
												</VStack>
											) : null}
										</VStack>
									</HStack>
								))}
						</HStack>
					</VStack>
					<VStack w={['40%', '35%', '40%']}>
						<HStack
							w={['70%', '80%', '80%']}
							py={5}
							alignSelf={'center'}
							justifyContent={['', '', 'space-around']}
						>
							<Button
								size='lg'
								bg={'transparent'}
								mx={['0', '1', '5']}
								w={['', '20%', '']}
								rounded={'md'}
								borderColor={'#2EBA55'}
								borderWidth={'1px'}
								_hover={{ bg: '#1C1B1B' }}
								_pressed={{ bg: '#2EBA55' }}
								alignSelf={'center'}
							>
								<Text
									fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
									color='#2EBA55'
								>
									Upload Daw File
								</Text>
							</Button>
							<Button
								size='lg'
								bg={'transparent'}
								rounded={'md'}
								mx={['0', '1', '5']}
								w={['', '20%', '']}
								borderColor={'#2EBA55'}
								borderWidth={'1px'}
								_hover={{ bg: '#1C1B1B' }}
								_pressed={{ bg: '#2EBA55' }}
								alignSelf={'center'}
							>
								<Text
									fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
									color='#2EBA55'
								>
									Sync Real Time Daw
								</Text>
							</Button>
						</HStack>
						<HStack
							rounded={'md'}
							alignSelf={'center'}
							bgColor={'#1C1B1B'}
							mt={8}
							w='80%'
							overflow={'scroll'}
						>
							<Text
								fontSize={{ lg: '16px', md: '14px', sm: '12px' }}
								color='#afafaf'
							>
								{fileName}
								{/* {songLyrics} */}
							</Text>
						</HStack>
					</VStack>
				</HStack>
			)}
			<Modal
				isOpen={showCommentBox}
				onClose={() => setCommentBox(false)}
				w={'40%'}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={10}
			>
				<Modal.Content backgroundColor={'#1C1B1B'} py={10}>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
					/>

					<Modal.Body backgroundColor={'#1C1B1B'}>
						<VStack w={'90%'} px='1' alignSelf={'center'}>
							<VStack w={'90%'} px='1' py='30' alignSelf={'center'}>
								<Text
									fontSize={{ lg: '24px', md: '22px', sm: '20px' }}
									color='#afafaf'
								>
									Write Comment
								</Text>
								<Input
									//  py={10}
									onChange={getCommentText}
									setFeedbackText
									h={'48'}
									backgroundColor={'transparent'}
									borderColor='#afafaf'
									borderWidth={2}
									_important={{
										backgroundColor: 'transparent',
										borderColor: '#afafaf',
										borderWidth: '2',
									}}
									// _disabled={{backgroundColor: "amber.900", borderWidth:2, borderColor:"#afafaf"}}
									fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
									color={'#afafaf'}
									multiline
									//  overflowY={"scroll"}
								></Input>
							</VStack>
						</VStack>
					</Modal.Body>

					<Modal.Footer backgroundColor={'#1C1B1B'} borderTopWidth={0}>
						<Button.Group justifyContent={'space-evenly'} width={'30%'}>
							<Button
								onPress={() => {
									setCommentBox(false);
								}}
								size='lg'
								w={'40%'}
								bg={'transparent'}
								rounded={'md'}
								borderColor={'#afafaf'}
								borderWidth={'1px'}
								_hover={{ bg: '#1C1B1B' }}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: 'transparent' }}
								alignSelf={'center'}
							>
								<Text
									fontSize={{
										lg: '32px',
										lg: '24px',
										md: '16px',
										sm: '12px',
										base: '18px',
									}}
									color='#afafaf'
								>
									Cancel
								</Text>
							</Button>
							<Button
								onPress={() => {
									setCommentBox(false);
									createComment(fileId);
								}}
								size='lg'
								w={'40%'}
								bg={'#2EBA55'}
								rounded={'md'}
								_hover={{ bg: '#1C1B1B' }}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: '#2EBA55' }}
								alignSelf={'center'}
							>
								<Text
									fontSize={{
										lg: '32px',
										lg: '24px',
										md: '16px',
										sm: '12px',
										base: '18px',
									}}
									color='white'
								>
									Sumit
								</Text>
							</Button>
						</Button.Group>
					</Modal.Footer>
				</Modal.Content>
			</Modal>
		</>
	);
};
export default AudioFeedback;
