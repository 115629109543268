import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
	<div
		style={{
			textAlign: 'center',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		<h1 style={{ color: '#fff', fontFamily: 'Poppins' }}>404 - Not Found!</h1>
		<Link to='/' style={{ color: '#fff', fontFamily: 'Poppins' }}>
			Go Home
		</Link>
	</div>
);

export default NotFound;
