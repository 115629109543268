import React, { useContext, useState } from "react";
import { AuthContext } from "../Layout";
import { Box, HStack } from "native-base";
import SignInForm from "./SignInForm";
import Sidebar from "./Sidebar";

function SignIn() {
  const { activeStep, resetByPhone } = useContext(AuthContext);
  const SignInSceen = {
    title: "welcome back !",
    subtitle: "",
    type: "email_phone",
    type1: "password",
    btntext: "sign in",
  };
  const forgotPassword = {
    title: "forgot Password",
    subtitle: "No worries we’ll send you the reset instructions",
    type: resetByPhone ? "phone" : "email",
    type1: "",
    btntext: "reset password",
  };
  const checkMail = {
    title: "check your mail",
    subtitle: "We sent a password reset link to your email.",
    type: "",
    type1: "",
    btntext: "open email app",
  };
  const newPassword = {
    title: " Set New Password ",
    subtitle:
      "Your new password must be different than any previously used passwords.",
    type: "password",
    type1: "confirmPassword",
    btntext: "reset password",
  };

  const passwordReset = {
    title: "password is reset",
    subtitle:
      "Your password has been successfully reset. Click below to log back in.",
    type: "",
    type1: "",
    btntext: "continue",
  };

  const OTPScreen = {
    title: "Verification code",
    subtitle: `We sent you a code on your ${
      resetByPhone ? "phone number" : "email"
    }`,
    type: "",
    type1: "",
    btntext: "continue",
  };
  return (
    <Box minH={"100vh"}>
      <HStack minH={"100vh"}>
        <Sidebar />
        {/* {activeStep===0 && <SignInForm {...SignInSceen} />}
        {activeStep===1 && <SignInForm {...forgotPassword}/> }
        {activeStep===2 && <SignInForm {...checkMail}/> }
        {activeStep===3 && <SignInForm {...newPassword}/> }

        {activeStep===4 && <SignInForm {...passwordReset}/> } */}

        {activeStep === 0 && <SignInForm {...SignInSceen} />}
        {activeStep === 1 && <SignInForm {...forgotPassword} />}
        {activeStep === 2 && !resetByPhone && <SignInForm {...OTPScreen} />}
        {activeStep === 3 && !resetByPhone && <SignInForm {...newPassword} />}
        {activeStep === 4 && !resetByPhone && <SignInForm {...passwordReset} />}
        {activeStep === 2 && resetByPhone && <SignInForm {...forgotPassword} />}
        {activeStep === 3 && resetByPhone && <SignInForm {...OTPScreen} />}
        {activeStep === 4 && resetByPhone && <SignInForm {...newPassword} />}
        {activeStep === 5 && resetByPhone && <SignInForm {...passwordReset} />}
      </HStack>
    </Box>
  );
}

export default SignIn;
