/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Title } from 'chart.js';
import {
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Hidden,
	Link,
	VStack,
} from 'native-base';
import React, { useState } from 'react';
import styles from './styles.module.css';
import MblIntro from '../../assets/NewLandingPage/big_logo.svg';
import mail from '../../assets/NewLandingPage/mail.png';
import clock from '../../assets/NewLandingPage/clock.png';
import musicJingal from '../../assets/NewLandingPage/musicJingal.png';
import recard from '../../assets/NewLandingPage/recard.png';
import channals from '../../assets/NewLandingPage/channals.png';
import money from '../../assets/NewLandingPage/money.png';
import pcImg from '../../assets/NewLandingPage/pcImg.png';
import groupIntro from '../../assets/NewLandingPage/big_logo.svg';

import BecomeMEmImg from '../../assets/NewLandingPage/BecomeMEmImg.png';
import step1 from '../../assets/NewLandingPage/step1.png';
import step2 from '../../assets/NewLandingPage/step2.png';
import step3 from '../../assets/NewLandingPage/step3.png';
import arrow from '../../assets/NewLandingPage/arrow.png';
import pcMblImg from '../../assets/NewLandingPage/pcMblImg.png';
import Members from '../Members';
import Customers from '../Customers/Customers';
function Services(props) {
	const { homeOnClickRef } = props;
	return (
		<Box w={'100%'} bg={'#000201'}>
			<Introduction />
			<BuiltForYou />
			<HowItWorks />
			<BecomeMember />
			{/* <Journey /> */}
			<Faq />

			<Box bg={'#0C0B0B'} mb={{ lg: 32, base: 5 }}>
				<Customers />
			</Box>
		</Box>
	);
	function Introduction() {
		return (
			<Box
				w={'100%'}
				alignSelf={'center'}
				bg={'#000201'}
				my={{ xl: 10, lg: 8, base: 5 }}
			>
				<Box alignSelf={'center'} my={{ xl: 10, lg: 8, base: 5 }}>
					<HStack
						w={{ xl: '70%', lg: '70%', base: '85%' }}
						alignSelf={'center'}
						justifyContent={'space-between'}
					>
						<Divider w={'35%'} alignSelf={'center'} />
						<Box>
							<Heading
								textTransform={'uppercase'}
								textAlign={'center'}
								fontWeight={'medium'}
								fontFamily={'Orbitron'}
								color={'#fff'}
								fontSize={{ lg: '16px', base: '8px' }}
							>
								Who
							</Heading>
							<Heading
								textTransform={'uppercase'}
								textAlign={'center'}
								fontWeight={'medium'}
								fontFamily={'Orbitron'}
								color={'#fff'}
								fontSize={{ lg: '40px', base: '20px' }}
								lineHeight={{ lg: '45px', base: '22px' }}
							>
								WE ARE
							</Heading>
						</Box>
						<Divider w={'35%'} alignSelf={'center'} />
					</HStack>
					<HStack mt={{ xl: 10, lg: 8, base: 5 }}>
						<Box
							pl={{ lg: 24, base: 5 }}
							pr={2}
							alignSelf={'center'}
							w={{ xl: '60%', lg: '60%', base: '100%' }}
						>
							<Hidden from='md'>
								<Image
									source={MblIntro}
									w={'40%'}
									h={'230px'}
									margin={'auto'}
									resizeMode={'contain'}
								/>
							</Hidden>
							<Heading
								my={5}
								textAlign={{
									xl: 'left',
									lg: 'left',
									md: 'left',
									base: 'center',
								}}
								fontFamily={'Orbitron'}
								fontWeight={'bold'}
								fontSize={{
									'2xl': '32px',
									xl: '30px',
									lg: '28px',
									md: '24px',
									base: '16px',
								}}
								color={'#fff'}
							>
								What is United Market ?
							</Heading>
							<Heading
								w={{ lg: '80%', md: '80%', base: '90%' }}
								textAlign={{
									xl: 'left',
									lg: 'left',
									md: 'left',
									base: 'center',
								}}
								fontFamily={'Poppins'}
								fontWeight={'normal'}
								lineHeight={{
									'2xl': '36px',
									xl: '30px',
									lg: '30px',
									base: '18px',
								}}
								fontSize={{
									'2xl': '24px',
									xl: '22px',
									lg: '20px',
									md: '16px',
									base: '14px',
								}}
								color={'#D2D4D1'}
							>
								We unite artists and producers, leveraging AI technology to
								curate quality records. By streamlining contracts for each
								record curated, collaborations are protected with smart
								contracts as an extra layer of security - that ensure all
								collaborators remain safe.
							</Heading>
						</Box>
						<Hidden from='base' till='md'>
							<Box
								//borderColor={'#0C0B0B'}
								//borderWidth={'5px'}
								w={{ xl: '40%', lg: '40%', base: '50%' }}
								pl={{ xl: 10, lg: 8, base: 5 }}
								alignSelf={'center'}
							>
								<Image
									source={groupIntro}
									w={'75%'}
									h={{
										'2xl': '400px',
										xl: '290px',
										lg: '260px',
									}}
									resizeMode={'contain'}
								/>

								{/* <Hidden from='md'>
								<Image
									source={MblIntro}
									w={'80%'}
									h={'230px'}
									resizeMode={'contain'}
								/>
							</Hidden> */}
							</Box>
						</Hidden>
					</HStack>
				</Box>
			</Box>
		);
	}

	function BuiltForYou() {
		const [tab, setTab] = useState(1);
		return (
			<Box
				w={'100%'}
				alignSelf={'center'}
				bg={'#0c0b0b'}
				py={{ lg: 10, base: 5 }}
			>
				<Box
					alignSelf={'center'}
					my={{ lg: 10, base: 5 }}
					bg={'#0c0b0b'}
					w={{ '2xl': '60%', lg: '65%', base: '80%' }}
				>
					<Heading
						fontSize={{ '2xl': '64px', xl: '60px', lg: '46px', base: '20px' }}
						fontFamily={'Orbitron'}
						fontWeight={'bold'}
						color={'#fff'}
						textTransform={'uppercase'}
						textAlign={'center'}
						alignSelf={'center'}
					>
						BUILT FOR YOU
					</Heading>

					<HStack my={{ lg: 10, base: 5 }} justifyContent={'space-evenly'}>
						<Button
							variant={'ghost'}
							p={0}
							px={1}
							borderRadius={0}
							borderBottomWidth={3}
							borderBottomColor={tab === 1 ? '#3ebb55' : '#0c0b0b'}
							_text={{
								fontSize: { xl: '32px', lg: '28px', base: '16px' },
								fontFamily: 'Orbitron',
								textTransform: 'capitalize',
								fontWeight: 'bold',
								color: tab === 1 ? '#fff' : '#d2d4d1',
							}}
							onPress={() => setTab(1)}
							_pressed={{ bg: 'transparent' }}
							_hover={{ bg: 'transparent', _text: { color: '#2ebb55' } }}
							_focus={{ bg: 'transparent' }}
						>
							Artists
						</Button>
						<Button
							variant={'ghost'}
							p={0}
							px={1}
							borderRadius={0}
							borderBottomWidth={3}
							borderBottomColor={tab === 2 ? '#3ebb55' : '#0c0b0b'}
							_text={{
								fontSize: { xl: '32px', lg: '28px', base: '16px' },
								fontFamily: 'Orbitron',
								textTransform: 'capitalize',
								fontWeight: 'bold',
								color: tab === 2 ? '#fff' : '#d2d4d1',
							}}
							onPress={() => setTab(2)}
							_pressed={{ bg: 'transparent' }}
							_hover={{ bg: 'transparent', _text: { color: '#2ebb55' } }}
							_focus={{ bg: 'transparent' }}
						>
							Music Producers
						</Button>
						<Button
							variant={'ghost'}
							p={0}
							px={1}
							borderRadius={0}
							borderBottomWidth={3}
							borderBottomColor={tab === 3 ? '#3ebb55' : '#0c0b0b'}
							_text={{
								fontSize: { xl: '32px', lg: '28px', base: '16px' },
								fontFamily: 'Orbitron',
								textTransform: 'capitalize',
								fontWeight: 'bold',
								color: tab === 3 ? '#fff' : '#d2d4d1',
							}}
							onPress={() => setTab(3)}
							_pressed={{ bg: 'transparent' }}
							_hover={{ bg: 'transparent', _text: { color: '#2ebb55' } }}
							_focus={{ bg: 'transparent' }}
						>
							A&R
						</Button>
					</HStack>
					{tab === 1 && (
						<BuiltTabs
							desc={
								"Let us help you focus exclusively on your music. We'll set you up with the producers and beat makers of your dreams, quickly signing contracts so no detail gets skipped over - it's a streamlined process that takes one worry off your mind!"
							}
							tab={1}
						/>
					)}
					{tab === 2 && (
						<BuiltTabs
							desc={
								'Unlock the doors to incredible placement and sync opportunities. Work with upcoming and established artists from across the globe. Streamline contract flow and negotiations and know you are PROTECTED with United Market.'
							}
							tab={2}
						/>
					)}
					{tab === 3 && (
						<BuiltTabs
							desc={
								" Boost your artist's sound to the next level! United Market gives you access to premier music producers and beat-makers so your artists can create memorable music that will stand out from the rest."
							}
							tab={3}
						/>
					)}
				</Box>
			</Box>
		);
	}

	function BuiltTabs(props) {
		const { desc, tab } = props;
		return (
			<Box w={'100%'}>
				<Heading
					fontFamily={'Poppins'}
					fontSize={{ '2xl': '24px', xl: '22px', lg: '20px', base: '12px' }}
					lineHeight={{ lg: '36px', base: '21px' }}
					fontWeight={'normal'}
					textAlign={'center'}
					color={'#D2D4D1'}
					pb={{ lg: 10, base: 5 }}
				>
					{desc}
				</Heading>
				{tab === 1 && (
					<>
						<BuiltTiles
							image={mail}
							desc={
								'No need to create multiple email accounts just to receive beats from your collaborators.'
							}
						/>
						<BuiltTiles
							image={clock}
							desc={'Save Time and focus on your craft.'}
						/>
						<BuiltTiles
							image={musicJingal}
							desc={'Create the best music in the game.'}
						/>
					</>
				)}
				{tab === 2 && (
					<>
						<BuiltTiles image={recard} desc={'Get placements.'} />
						<BuiltTiles
							image={channals}
							desc={'Hear your beats on Films, Ads and TV Shows.'}
						/>
						<BuiltTiles
							image={money}
							desc={'Stay Protected and get paid on time every time.'}
						/>
					</>
				)}
				{tab === 3 && (
					<>
						<BuiltTiles
							image={mail}
							desc={
								'No need to create multiple email accounts just to receive beats for your artists.'
							}
						/>
						<BuiltTiles
							image={clock}
							desc={
								'Save Time you should be able to focus on artists development less management.'
							}
						/>
						<BuiltTiles
							image={musicJingal}
							desc={
								'Create the best music with one less stress off your shoulder.'
							}
						/>
					</>
				)}
			</Box>
		);
	}
	function BuiltTiles(props) {
		const { desc, image } = props;
		return (
			<Box w={'100%'}>
				<HStack
					w={'100%'}
					bg={'#000201'}
					rounded={'md'}
					px={{ '2xl': 10, xl: 8, lg: 6, base: 3 }}
					h={{ xl: '90px', lg: '75px', base: '55px' }}
					justifyContent={'space-evenly'}
					my={{ xl: 4, lg: 3, base: 2 }}
				>
					<Image
						source={image}
						w={{ xl: '55px', lg: '45px', base: '25px' }}
						h={{ xl: '55px', lg: '45px', base: '25px' }}
						alignSelf={'center'}
					/>
					<Heading
						w={'80%'}
						fontFamily={'Poppins'}
						fontSize={{ '2xl': '22px', xl: '20px', lg: '18px', base: '10px' }}
						lineHeight={{ lg: '30px', base: '15px' }}
						fontWeight={'normal'}
						textAlign={'left'}
						color={'#fff'}
						alignSelf={'center'}
					>
						{desc}
					</Heading>
				</HStack>
			</Box>
		);
	}

	function HowItWorks() {
		return (
			<Box
				w={'100%'}
				alignSelf={'center'}
				bg={'#000201'}
				pt={{ lg: 0, base: 5 }}
				ref={homeOnClickRef}
			>
				<HStack justifyContent={'space-between'} flexWrap={'wrap'}>
					<Box
						w={{ lg: '40%', base: '100%' }}
						justifyContent={'center'}
						px={{ lg: 24, base: 5 }}
					>
						<Heading
							alignSelf={{ lg: 'flex-start', base: 'center' }}
							textAlign={'left'}
							fontFamily={'Orbitron'}
							fontWeight={'bold'}
							lineHeight={{
								'2xl': '80px',
								xl: '70px',
								lg: '60px',
								base: '25px',
							}}
							fontSize={{
								'2xl': '64px',
								xl: '56px',
								lg: '40px',
								md: '32px',
								base: '20px',
							}}
							color={'#fff'}
						>
							See How It Works
						</Heading>
						<Link
							href='/signup'
							mt={{ '2xl': 10, xl: 8, lg: 8, base: 5 }}
							alignSelf={{ lg: 'flex-start', base: 'center' }}
						>
							<button
								// data-tf-popup='F9YFcKqF'
								// data-tf-iframe-props='title=Composition Collection'
								// data-tf-medium='snippet'
								className='ApplyNowWork'
							>
								&nbsp;Apply Now
							</button>
						</Link>
					</Box>
					<Hidden from='base' till='md'>
						{/* <Image
							source={pcImg}
							w={{ '2xl': '50%', xl: '50%', lg: '50%' }}
							h={{ '2xl': '700px', xl: '600px', lg: '470px' }}
							resizeMode={'stretch'}
						/> */}
						<Box py={10} width={'50%'}>
							<video controls loop id='video-background' muted>
								<source
									src='https://united-market.s3.us-east-2.amazonaws.com/landingPage/UM-March23-video-04-1080p-compressed.mp4'
									type='video/mp4'
								/>
							</video>
						</Box>
					</Hidden>
					<Hidden from='md'>
						<Box w={'100%'} pt={6} px={10}>
							{/* <Image
								alignSelf={'flex-end'}
								source={pcMblImg}
								w={'75%'}
								h={'186px'}
								resizeMode={'stretch'}
							/> */}
							<video autoPlay loop id='video-background' muted>
								<source
									src='https://united-market.s3.us-east-2.amazonaws.com/landingPage/UM-March23-video-04-1080p-compressed.mp4'
									type='video/mp4'
								/>
							</video>
						</Box>
					</Hidden>
				</HStack>
				<Box>
					<Members />
				</Box>
			</Box>
		);
	}

	function BecomeMember() {
		return (
			<Box
				w={'100%'}
				alignSelf={'center'}
				bg={'#0c0b0b'}
				py={{ xl: 10, lg: 8, base: 5 }}
				px={{ '2xl': 32, lg: 24, base: 5 }}
			>
				<HStack justifyContent={'space-between'} flexWrap={'wrap'}>
					<Box
						alignSelf={'center'}
						my={{ xl: 10, lg: 8, base: 5 }}
						bg={'#0c0b0b'}
						w={{ '2xl': '50%', xl: '60%', lg: '65%', base: '100%' }}
					>
						<Heading
							w={{ xl: '65%', lg: '65%', base: '85%' }}
							pb={{ xl: 10, lg: 8, base: 5 }}
							fontSize={{ '2xl': '40px', xl: '36px', lg: '30px', base: '16px' }}
							lineHeight={{
								'2xl': '50px',
								xl: '40px',
								lg: '30px',
								base: '20px',
							}}
							fontFamily={'Orbitron'}
							fontWeight={'bold'}
							color={'#fff'}
							textAlign={'left'}
						>
							How to become a United Market member
						</Heading>

						<MemberPoints
							num={'01'}
							desc={'Apply for membership to join the community.'}
						/>
						<MemberPoints
							num={'02'}
							desc={
								'Fill out the application with your information and create your credentials. '
							}
						/>
						<MemberPoints
							num={'03'}
							desc={
								'You can log into your account and access our members-only platform.  '
							}
							link={true}
						/>
					</Box>
					<Box w={{ lg: '30%', base: '100%' }}>
						<Image
							source={BecomeMEmImg}
							w={'100%'}
							mt={20}
							h={{ xl: '85%', lg: '500px', base: '250px' }}
							alignSelf={'center'}
							rounded={'lg'}
						/>
					</Box>
				</HStack>
			</Box>
		);
	}

	function MemberPoints(props) {
		const { desc, num, link } = props;
		return (
			<Box py={{ xl: 10, lg: 8, base: 5 }}>
				<HStack w={'100%'}>
					<Heading
						pr={{ xl: 10, lg: 8, base: 4 }}
						fontSize={{ '2xl': '96px', xl: '96px', lg: '64px', base: '36px' }}
						// fontFamily={'Poppins'}
						fontWeight={'medium'}
						color={'#fff'}
						alignSelf={'center'}
						textAlign={'left'}
					>
						{num}
					</Heading>
					<VStack
						w={{ lg: '100%', base: '85%' }}
						justifyContent={'center'}
						alignSelf={'center'}
					>
						<Heading
							px={{ xl: 10, lg: 8, base: 4 }}
							fontSize={{ '2xl': '26px', xl: '26px', lg: '20px', base: '14px' }}
							lineHeight={{
								'2xl': '36px',
								xl: '30px',
								lg: '26px',
								base: '18px',
							}}
							fontFamily={'Poppins'}
							fontWeight={'normal'}
							color={'#fff'}
							textAlign={'left'}
						>
							{desc}
						</Heading>
					</VStack>
				</HStack>
				{link ? (
					<Box
						py={{ xl: 10, lg: 8, base: 5 }}
						pl={{ '2xl': '22%', xl: '25%', lg: '25%', base: '18%' }}
					>
						<Link
							href='/signup'
							//   mt={{ '2xl': 10, xl: 8, lg: 8, base: 5 }}
							//   alignSelf={{ lg: 'flex-start', base: 'center' }}
						>
							<button
								// data-tf-popup='F9YFcKqF'
								// data-tf-iframe-props='title=Composition Collection'
								// data-tf-medium='snippet'
								className='ApplyNowMembership'
							>
								&nbsp;Apply Now for Membership
							</button>
						</Link>
					</Box>
				) : (
					''
				)}
			</Box>
		);
	}

	function Journey() {
		return (
			<Box w={'100%'} px={{ lg: 24, base: 5 }} my={{ lg: 20, base: 10 }}>
				<Heading
					pb={{ lg: 10, base: 5 }}
					fontSize={{ '2xl': '40px', xl: '40px', lg: '36px', base: '16px' }}
					fontFamily={'Orbitron'}
					fontWeight={'bold'}
					color={'#fff'}
					textTransform={'uppercase'}
					textAlign={'center'}
					alignSelf={'center'}
				>
					Member Journey
				</Heading>
				<MemberStep
					step={'Step 01:'}
					img={step1}
					desc={
						'Twytsed Genius a multi-platinum producer submits beats to Young Deion upcoming Artists out of Atlanta'
					}
				/>
				<Image
					my={2}
					source={arrow}
					resizeMode={'contain'}
					alignSelf={'center'}
					w={{ xl: '45px', lg: '35px', base: '25px' }}
					h={{ xl: '45px', lg: '35px', base: '25px' }}
				/>
				<MemberStep
					step={'Step 02:'}
					img={step2}
					desc={
						'District Records, a label partner with United Market review beats selects which they like'
					}
				/>
				<Image
					my={2}
					source={arrow}
					resizeMode={'contain'}
					alignSelf={'center'}
					w={{ xl: '45px', lg: '35px', base: '25px' }}
					h={{ xl: '45px', lg: '35px', base: '25px' }}
				/>
				<MemberStep
					step={'Step 03:'}
					img={step3}
					desc={
						'Twysted Genius gets a new placement on “What You Claim” a song by Young Deion Featuring Hunxho'
					}
				/>
			</Box>
		);
	}

	function MemberStep(props) {
		const { img, desc, step } = props;
		return (
			<Box bg={'#0C0B0B'} rounded={'lg'} my={{ xl: 12, lg: 10, base: 5 }}>
				<Box
					w={{ xl: '50%', lg: '70%', base: '70%' }}
					pl={{ xl: 10, lg: 10, base: 5 }}
					pr={{ xl: 0, lg: 16, base: 5 }}
					justifyContent={'center'}
					alignSelf={{ xl: 'center', lg: 'flex-end', base: 'flex-end' }}
					zIndex={10}
					h={{ xl: '300px', lg: '260px', base: '120px' }}
				>
					<Heading
						pb={{ xl: 8, lg: 6, base: 4 }}
						fontSize={{ '2xl': '32px', xl: '32px', lg: '30px', base: '16px' }}
						fontFamily={'Orbitron'}
						fontWeight={'bold'}
						color={'#fff'}
						textAlign={'left'}
					>
						{step}
					</Heading>
					<Heading
						fontSize={{ xl: '24px', lg: '20px', base: '10px' }}
						lineHeight={{ xl: '40px', lg: '30px' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						color={'#d2d4d1'}
					>
						{desc}
					</Heading>
				</Box>
				<Image
					source={img}
					h={{ xl: '300px', lg: '260px', base: '120px' }}
					w={{ xl: '260px', lg: '240px', base: '100px' }}
					rounded={'lg'}
					zIndex={'20'}
					position={'absolute'}
					left={{ lg: 5, base: 2.5 }}
					bottom={{ lg: 5, base: 2.5 }}
				/>
			</Box>
		);
	}
	function Faq() {
		const [showFaq, setShowFaq] = React.useState();

		const data = [
			{
				id: 1,
				name: 'How do I join United Market?',
				des: 'Apply via our website and our application team will review.',
			},
			{
				id: 2,
				name: 'Who is United Market for?',
				des: 'United Market serves musicians specifically Artists and Producers providing them a streamlined platform to collaborate.',
			},
			{
				id: 3,
				name: 'How can a Management group or A&R benefit from United Market?',
				des: 'We provide you quality production from a global network of beat makers to curate your artists upcoming projects.',
			},
			{
				id: 4,
				name: 'How much does it cost?',
				des: 'Right now we are charging $150 annually and $30 monthly.',
			},
		];
		const handleFAQDrop = (value) => {
			if (value === showFaq) {
				setShowFaq(null);
			} else {
				setShowFaq(value);
			}
		};
		return (
			<Box
				w={{ lg: '70%', base: '90%' }}
				alignSelf={'center'}
				//mt={{ xl: 10, base: 5 }}
				mb={{ xl: 32, base: 10 }}
			>
				<Heading
					textTransform={'capitalize'}
					color={'#fff'}
					fontSize={{ xl: '40px', lg: '36px', base: '16px' }}
					lineHeight={{ xl: '84px', lg: '44px', base: '36px' }}
					mt={5}
					mb={{ '2xl': 5, xl: 5, base: 3 }}
					fontFamily={'Orbitron'}
					fontWeight={'bold'}
					textAlign={'center'}
				>
					Frequently Asked Questions
				</Heading>
				<Heading
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ xl: '24px', lg: '20px', base: '10px' }}
					lineHeight={{ xl: '36px', md: '26px', base: '21px' }}
					fontWeight={'normal'}
					textAlign={'center'}
					mb={{ xl: 10, base: 5 }}
				>
					Have questions? We’re here to help.
				</Heading>
				{data.map((item, index) => {
					return (
						<Box key={index} w={'100%'} alignItems={'center'}>
							<Box bg={'#000201'} rounded={'md'} mx={10} py={2} w={'100%'}>
								<HStack w={'100%'} alignItems={'center'}>
									<Heading
										fontSize={{ xl: '18px', lg: '16px', base: '12px' }}
										lineHeight={{ xl: '27px', base: '21px' }}
										color={'#fff'}
										fontWeight={'medium'}
										alignSelf={'center'}
										py={3}
										w={'85%'}
									>
										{item.name}
									</Heading>
									<Button
										variant={'ghost'}
										w={'15%'}
										alignSelf={'center'}
										_pressed={{ bg: 'transparent' }}
										_hover={{ bg: 'transparent' }}
										_focus={{ bg: 'transparent' }}
										onPress={() => {
											handleFAQDrop(item.id);
										}}
										m={0}
										justifyContent={'flex-end'}
									>
										{item.id === showFaq ? (
											<Heading
												fontSize={'xl'}
												color={'#AFAFAF'}
												px={1.5}
												rounded={'full'}
												// borderWidth={1}
												// borderColor={'#afafaf'}
											>
												-
											</Heading>
										) : (
											<Heading
												fontSize={'xl'}
												px={1.5}
												rounded={'full'}
												color={'#AFAFAF'}
												alignSelf={'center'}
												// borderWidth={1}
												// borderColor={'#afafaf'}
											>
												+
											</Heading>
										)}
									</Button>
								</HStack>
								{item.id === showFaq && (
									<Heading
										w={'65%'}
										pb={5}
										color={'#AFAFAF'}
										fontWeight={'normal'}
										fontSize={{ xl: '18px', lg: '16px', base: '12px' }}
										lineHeight={{ xl: '27px', base: '16px' }}
										textAlign={'left'}
									>
										{item.des}
									</Heading>
								)}
							</Box>
							<Divider bg={'#AFAFAF'} />
						</Box>
					);
				})}
			</Box>
		);
	}
}
export default Services;
