import React from 'react';

function Share() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_726_23750)'>
        <path
          d='M13.4999 11.2499C12.9107 11.2504 12.3452 11.4819 11.9249 11.8949L5.9999 9.25487V8.99987V8.75237L11.9249 6.10487C12.296 6.46864 12.7816 6.69273 13.2992 6.73908C13.8168 6.78544 14.3345 6.6512 14.7643 6.35917C15.1942 6.06714 15.5097 5.63533 15.6573 5.13706C15.8048 4.6388 15.7754 4.10481 15.5739 3.62579C15.3725 3.14677 15.0114 2.75224 14.5521 2.50923C14.0927 2.26622 13.5635 2.18969 13.0541 2.29266C12.5447 2.39562 12.0867 2.67173 11.7578 3.07407C11.429 3.47642 11.2495 3.98021 11.2499 4.49987V4.75487L5.3249 7.39487C5.00867 7.08419 4.60771 6.87384 4.17235 6.79024C3.73699 6.70664 3.28664 6.75351 2.87782 6.92497C2.46901 7.09643 2.11996 7.38483 1.87448 7.75398C1.629 8.12312 1.49805 8.55656 1.49805 8.99987C1.49805 9.44319 1.629 9.87662 1.87448 10.2458C2.11996 10.6149 2.46901 10.9033 2.87782 11.0748C3.28664 11.2462 3.73699 11.2931 4.17235 11.2095C4.60771 11.1259 5.00867 10.9156 5.3249 10.6049L11.2499 13.2524V13.4999C11.2499 13.9449 11.3819 14.3799 11.6291 14.7499C11.8763 15.1199 12.2277 15.4083 12.6389 15.5786C13.05 15.7489 13.5024 15.7935 13.9389 15.7066C14.3753 15.6198 14.7762 15.4055 15.0909 15.0909C15.4056 14.7762 15.6199 14.3753 15.7067 13.9388C15.7935 13.5024 15.7489 13.05 15.5786 12.6388C15.4083 12.2277 15.1199 11.8763 14.7499 11.6291C14.3799 11.3818 13.9449 11.2499 13.4999 11.2499Z'
          fill='#2EBB55'
        />
      </g>
      <defs>
        <clipPath id='clip0_726_23750'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Share;
