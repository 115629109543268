import React from 'react';
import {
	Box,
	Image,
	Heading,
	HStack,
	VStack,
	Button,
	Divider,
} from 'native-base';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const TalentcomponentHeader = (props) => {
	const { image, title, subtitle } = props;
	return (
		<Box bg={'#1C1B1B'} w={'100%'} rounded={'lg'}>
			<Box alignSelf={'flex-start'}>
				<HStack py={2} alignItems={'center'}>
					{image !== '' && (
						<Image source={image} size={{ lg: '6', base: '5' }} mr={3} />
					)}

					<Heading
						textTransform={'capitalize'}
						color={'#fff'}
						fontFamily={'Poppins'}
						fontSize={{ xl: '2xl', md: 'lg', base: 'md' }}
						fontWeight={'medium'}
						textAlign={'center'}
					>
						{title}
					</Heading>
				</HStack>
				<Heading
					mb={5}
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ xl: '2xl', md: 'md', base: 'sm' }}
					fontWeight={'normal'}
					textAlign={'left'}
				>
					{subtitle}
				</Heading>
			</Box>
		</Box>
	);
};
