import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Button, HStack, Text, VStack } from 'native-base';
import { Notification } from '../../NotifyMsg/Notification';
import { AuthContext } from '../Layout';
import FormTitle from '../FormTitle';
import { useParams } from 'react-router-dom';
import { SignUpMutation } from '../../../Graphql_services/SignUpMutation';
import CustomStripeForm from '../SignUp/CustomStripeForm';
import useAuth from '../../../context/AuthContext';
import Nbox from './Nbox';
import ButtonComponent from '../../ButtonComponent';
import { useBreakpointValue } from 'native-base';
function Subscription(props) {
	const { title, price } = props;
	const { isSignup } = useParams();
	const isMobile = useBreakpointValue({
		base: true,
		sm: false,
	});
	const {
		purchased,
		setActiveStep,
		Subscriptionprice,
		setSubscriptionprice,
		Email,
		Password,
		picture,
		instaMail,
		genre,
		phonenum,
		priceID,
		NewRoleId,
		othersSocial,
		rolesID,
		accountType,
		setIsDisableBtn,
		activeStep,
		selectedAccount,
		username,
		firstName,
		lastName,
		showStripeForm,
		setShowStripeForm,
		noOfMonthlyListeners,
		spotifyUrl,
		labelName,
		otherHear,
	} = useContext(AuthContext);
	//const { setUser } = useAuth();
	const [signUp] = useMutation(SignUpMutation, {
		onCompleted(data) {
			if (data?.signUp?.success) {
				localStorage.setItem('email', Email);
				localStorage.setItem('accountType', accountType);
				localStorage.setItem('userId', data?.signUp?.user?._id);
				setShowStripeForm(true);
				setIsDisableBtn(false);
			} else {
				console.log('in else');
				Notification('error', data.signUp.message);
				setActiveStep(activeStep);
				setIsDisableBtn(false);
			}
		},
		onError(err) {
			const { graphQLErrors } = err;
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message }) => {
					Notification('error', message);
					setActiveStep(activeStep);
				});
			}
		},
	});
	const gotoCheckout = () => {
		if (isSignup === '1') {
			if (localStorage.getItem('email')) {
				setShowStripeForm(true);
			} else {
				Notification('error', 'Something went worng');
			}
		} else {
			setIsDisableBtn(true);
			signUp({
				variables: {
					firstname: firstName,
					lastname: lastName,
					email: Email,
					password: Password,
					countrycode: null,
					phoneno: Number(phonenum),
					labelName: labelName,
					roleId: rolesID,
					instaUrl: instaMail,
					profilePic: picture,
					genre: genre,
					username: username,
					noOfMonthlyListeners: noOfMonthlyListeners,
					spotifyUrl: spotifyUrl,
					isPreSignUp: false,
					howDidYouKnow: NewRoleId,
					howDidYouKnowText: otherHear,
				},
			});
			//setShowStripeForm(true);
			//setIsDisableBtn(false);
			console.log({
				firstname: firstName,
				lastname: lastName,
				email: Email,
				password: Password,
				countrycode: null,
				phoneno: Number(phonenum),
				labelName: labelName,
				roleId: rolesID,
				instaUrl: instaMail,
				profilePic: picture,
				genre: genre,
				username: username,
				noOfMonthlyListeners: noOfMonthlyListeners,
				spotifyUrl: spotifyUrl,
				isPreSignUp: false,
				howDidYouKnow: NewRoleId,
				howDidYouKnowText: otherHear,
			});
			//setShowStripeForm(true);
		}
		//	setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	return (
		<Box>
			{!showStripeForm ? (
				purchased ? (
					<Box>
						<FormTitle
							activeStep={activeStep}
							color={'#000201'}
							title={'Choose Your Membership'}
							subtitle={'You will not be charged unless you’re accepted.'}
							isMobile={isMobile}
						/>
						<HStack
							alignItems={'center'}
							space={3}
							my={3}
							justifyContent={'center'}
						>
							<Text color={'#2EBB55'} fontWeight={'bold'} fontSize={'4rem'}>
								${Subscriptionprice}
							</Text>
							<VStack>
								<Text
									textTransform={'capitalize'}
									fontFamily={'poppins'}
									color={'#fff'}
									fontSize={'0.9rem'}
								>
									per
								</Text>
								<Text
									textTransform={'capitalize'}
									fontFamily={'poppins'}
									color={'#fff'}
									fontSize={'0.9rem'}
								>
									{Subscriptionprice === '30' ? 'month' : 'year'}
								</Text>
							</VStack>
						</HStack>
						<Text
							textAlign={'center'}
							fontFamily={'poppins'}
							fontSize={'1.2rem'}
							color={'#AFAFAF'}
							px={8}
							my={3}
						>
							Included in this yearly membership pricing is platform access.
						</Text>
						<Button
							onPress={gotoCheckout}
							_hover={{
								backgroundColor: 'transparent',
								borderColor: '#2EBB55',
								borderWidth: 1,
								_text: { color: '#2EBB55' },
							}}
							mt={3}
							_text={{
								fontFamily: 'poppins',
								fontSize: '1.11rem',
								textTransform: 'capitalize',
							}}
							backgroundColor={'#2EBB55'}
							py={2}
						>
							Add Card Info
						</Button>
					</Box>
				) : (
					<>
						<FormTitle
							activeStep={activeStep}
							color={'#000201'}
							title={'Request Your Membership'}
							subtitle={'You will not be charged unless you’re accepted.'}
							isMobile={isMobile}
						/>

						<HStack
							justifyContent={'space-between'}
							width={'100%'}
							flexDirection={{ lg: 'row', base: 'column' }}
							flexWrap='wrap'
							flexGrow={1}
							space={5}
						>
							<Nbox
								// desc={
								// 	'You are receiving a discounted membership price for being an early adopter.'
								// }
								duration={'month'}
								title={'Monthly Membership'}
								priceid={`${process.env.REACT_APP_MONTHLY_SUBSCRIPTION}`}
								price={'30'}
								isPreSignUp={true}
							/>
							<Nbox
								// desc={
								// 	'Included in this yearly membership pricing is platform access'
								// }
								duration={'year'}
								priceid={`${process.env.REACT_APP_YEARLY_SUBSCRIPTION}`}
								title={'yearly membership'}
								price={'150'}
								isPreSignUp={true}
							/>
						</HStack>
					</>
				)
			) : (
				<>
					<FormTitle
						activeStep={activeStep}
						color={'#000201'}
						title={'Add Card Information'}
						subtitle={'You will not be charged unless you’re accepted.'}
						isMobile={isMobile}
					/>
					<CustomStripeForm />
				</>
			)}
		</Box>
	);
}

export default Subscription;
