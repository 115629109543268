/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
} from "native-base";
import React from "react";
import { Loader } from "../../Loader";
import { DashboardHeader } from "../../Headers";
import SideBar from "../../SideBar/SideBar";
import profile from "../../../assets/Feeds/connectmeProfile.png";
import right from "../../../assets/Feeds/right.png";
import left from "../../../assets/Feeds/left.png";
import star from "../../../assets/Feeds/star.png";
import starlined from "../../../assets/Feeds/starlined.png";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import RightBar from "./RightBar";
import Slider from "./Slider";
import useAuth from "../../../context/AuthContext";
import { Typography } from "@mui/material";
function ConnectMeProfile() {
  const { user, loading, loadUser } = useAuth();

  React.useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      console.log("user...", user);
    } else {
      loadUser();
    }
  }, []);
  function ProfileDetail(props) {
    const { title, info } = props;
    return (
      <Box>
        <Heading
          fontSize={{ xl: "2xl", base: "lg" }}
          fontFamily={"Poppins"}
          fontWeight={"medium"}
          textAlign={"left"}
          textTransform={"capitalize"}
          color={"#fff"}
        >
          {title}
        </Heading>
        <Heading
          py={5}
          fontSize={{ xl: "lg", base: "md" }}
          fontFamily={"Poppins"}
          fontWeight={"normal"}
          textAlign={"left"}
          color={"#e3e3e3"}
        >
          {info}
        </Heading>
      </Box>
    );
  }
  function Profile() {
    return (
      <Box zIndex={99}>
        <Image
          zIndex={10}
          my={3}
          source={profile}
          w={"90%"}
          h={"xl"}
          rounded={"xl"}
          resizeMethod={"scale"}
          alignSelf={"center"}
        />
        <HStack
          zIndex={30}
          position={"absolute"}
          w={"98%"}
          bottom={64}
          alignSelf={"center"}
          justifyContent={"space-between"}
        >
          <Button
            p={0}
            variant={"ghost"}
            _pressed={{ bg: "transparent" }}
            _hover={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
          >
            <Image my={3} source={left} size={20} alignSelf={"center"} />
          </Button>
          <Button
            p={0}
            variant={"ghost"}
            _pressed={{ bg: "transparent" }}
            _hover={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
          >
            <Image my={3} source={right} size={20} alignSelf={"center"} />
          </Button>
        </HStack>
        <Box
          zIndex={20}
          position={"absolute"}
          w={"80%"}
          bottom={44}
          alignSelf={"center"}
        >
          <HStack justifyContent={"space-between"}>
            <Box>
              <HStack>
                <Heading
                  fontSize={{ xl: "4xl", md: "3xl", base: "2xl" }}
                  textAlign={"left"}
                  fontFamily={"Poppins"}
                  fontWeight={"medium"}
                  alignSelf={"center"}
                  textTransform={"capitalize"}
                  color={"#fff"}
                  pr={2}
                >
                  Sameer
                </Heading>
                <Box alignSelf={"center"}>
                  <VerifiedRoundedIcon
                    style={{
                      paddingLeft: 2,
                      color: "#2EBB55",
                      fontSize: 25,
                    }}
                  />
                </Box>
              </HStack>
              <Heading
                fontSize={{ xl: "2xl", base: "lg" }}
                textAlign={"left"}
                fontFamily={"Poppins"}
                fontWeight={"medium"}
                textTransform={"capitalize"}
                color={"#e3e3e3"}
              >
                Dubai, UAE
              </Heading>
            </Box>
            {true ? (
              <Image my={3} source={star} size={25} alignSelf={"center"} />
            ) : (
              <Image my={3} source={starlined} size={25} alignSelf={"center"} />
            )}
          </HStack>
        </Box>
      </Box>
    );
  }
  return loading ? (
    <Box w={"100%"}>
      <Loader />
    </Box>
  ) : user && Object.keys(user).length > 0 ? (
    <Box w={"100%"}>
      <HStack w={"100%"} flexGrow={1} flexWrap={"wrap"}>
        <Box w={{ xl: "75%", base: "100%" }}>
          <Box w={"90%"} alignSelf={"center"} py={5}>
            <Heading
              my={5}
              fontSize={{ xl: "4xl", md: "3xl", base: "2xl" }}
              color={"#fff"}
              textTransform={"capitalize"}
              fontFamily={"poppins"}
            >
              Connect Me
            </Heading>
            {/* <Profile /> */}
            <Slider image={profile} />
            <HStack
              my={5}
              w={"50%"}
              alignSelf={"center"}
              justifyContent={"space-between"}
              style={{ zIndex: 999 }}
            >
              <Button
                size={"sm"}
                bg="#000201"
                borderStyle={"solid"}
                borderWidth={"1"}
                borderColor={"#2EBA55"}
                w={"40%"}
                rounded={"md"}
                alignSelf={"center"}
                _text={{
                  color: "#2EBA55",
                  fontSize: {
                    lg: "18px",
                    md: "16px",
                    sm: "14px",
                    base: "14px",
                  },
                  fontFamily: "Poppins",
                }}
                _hover={{
                  bg: "#2EBA55",
                  _text: {
                    color: "#fff",
                  },
                }}
                _focused={{ bg: "#2EBA55" }}
                _pressed={{ bg: "transparent" }}
              >
                View Favorties
              </Button>
              <Button
                size={"sm"}
                bg="#2EBA55"
                borderWidth={1}
                borderColor="#2EBA55"
                _text={{
                  color: "#fff",
                  fontSize: {
                    lg: "18px",
                    md: "16px",
                    sm: "14px",
                    base: "14px",
                  },
                  fontFamily: "Poppins",
                }}
                _hover={{
                  bg: "transparent",
                  _text: {
                    color: "#2EBA55",
                  },
                }}
                w={"40%"}
                rounded={"md"}
                alignSelf={"center"}
                _focused={{ bg: "#2EBA55" }}
                _pressed={{ bg: "#2EBA55" }}
              >
                Collab
              </Button>
            </HStack>
            <Box w={"80%"} style={{ zIndex: 999 }}>
              <ProfileDetail
                title={"About Me"}
                info={user?.aboutMe || "Not Set"}
              />
              <ProfileDetail
                title={"Loop Speciality"}
                info={user?.loop || "Not Set"}
              />
              <ProfileDetail
                title={"Beat Speciality"}
                info={user?.genre || "Not Set"}
              />
              <ProfileDetail title={"Genre"} info={"Twerk"} />
            </Box>
          </Box>
        </Box>
        <Box
          w={{ xl: "25%", base: "100%" }}
          borderLeftWidth={{ lg: 1, md: 0, base: 0 }}
          borderColor={"#fff"}
          // borderWidth={1}
        >
          <Box w={"90%"} alignSelf={"center"} pt={{ xl: 8, md: 0, base: 0 }}>
            <RightBar />
          </Box>
        </Box>
      </HStack>
    </Box>
  ) : (
    <Typography>ddddddddd</Typography>
  );
}

export default ConnectMeProfile;
