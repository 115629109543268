import { gql } from '@apollo/client';

export const Login = gql`
	mutation (
		$email: String!
		$password: String!
		$countrycode: Float
		$phoneno: Float
	) {
		login(
			email: $email
			password: $password
			countrycode: $countrycode
			phoneno: $phoneno
		) {
			success
			message
			user {
				_id
				firstname
				userName
				coverPic
				lastname
				email
				profilePic
				roleId {
					roleName
				}
			}
			accessToken
			refreshToken
			reactionAccessToken
			reactionRefreshToken
			sessionId
			isPaid
			isPreSignUpUser
		}
	}
`;
