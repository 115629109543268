import { Box, Heading, HStack, Image, Text, VStack } from "native-base";
import React from "react";
import usertick from "../../assets/Feeds/usertick.png";
import adduser from "../../assets/Feeds/adduser.png";

function FeedProfile(props) {
  const { profile, name, opportunity, date, lable, ads, friend } = props;
  return (
    <Box w={"100%"}>
      <HStack alignItems={"center"} w={"90%"} flexWrap={"wrap"} flexGrow={1}>
        <Box>
          <Image
            source={profile}
            height={"60px"}
            width={"60px"}
            borderRadius={"50%"}
          />
        </Box>

        <Box w={{ xl: "40%", md: "40%", base: "100%" }} my={2} px={3}>
          <Text fontSize={"20px"} fontFamily={"poppins"} color={"#E3E3E3"}>
            {name}
          </Text>

          <Text
            fontFamily={"poppins"}
            color={"#AFAFAF"}
            style={{ lineHeight: "1.2rem" }}
          >
            {opportunity}
          </Text>
        </Box>

        <HStack
          w={{ xl: "50%", md: "40%", base: "100%" }}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          my={2}
        >
          <HStack>
            <Text
              fontFamily={"poppins"}
              fontSize={{ xl: "lg", base: "sm" }}
              color={"#AFAFAF"}
            >
              {lable}
              {"  "}
            </Text>
            <Text
              fontFamily={"poppins"}
              fontSize={{ xl: "lg", base: "sm" }}
              color={"#AFAFAF"}
            >
              {date}
            </Text>
          </HStack>
          <Box alignSelf={"center"}>
            {ads ? (
              <Box p={0} rounded={"md"} px={3} py={1} bg={"#141313"}>
                <Heading
                  fontFamily={"Poppins"}
                  fontSize={"md"}
                  fontWeight={"medium"}
                  textAlign={"center"}
                  textTransform={"uppercase"}
                  color={"#e3e3e3"}
                >
                  Ads
                </Heading>
              </Box>
            ) : friend ? (
              <Image source={usertick} size={8} />
            ) : (
              <Image source={adduser} size={8} />
            )}
          </Box>
        </HStack>
      </HStack>
    </Box>
  );
}

export default FeedProfile;
