import React,{useState} from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from "./styles.module.css"
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const configOptions = (method, url) => {
    return {
        method: method,
        url: url,
        headers: {
            'x-api-key': 'soundcharts',
            'x-app-id': 'soundcharts',
        },
    };
};
function CountryFilter(props) {
  const {value, handler} = props
    const [countries, setCountries] = React.useState([])

  const handleChange = (event) => {
    // console.log('selected value : ', event.target.value)
    // setAge(event.target.value);
    handler(event.target.value)
  };

  React.useEffect(() => {
    getAllCountries()
  }, [])

  const getAllCountries = async () => {
    try{
        const config = configOptions(
            'get',
            `https://sandbox.api.soundcharts.com/api/v2/radio/countries`
          );
          const response = await axios(config)
          if(response.status === 200) {
        setCountries(response.data.items)
          }
          console.log('response of countries: ', response.data)
    }catch(err) {

    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <FormControl sx={{width:"100%"}} className='country_filter'>
    <Select
      labelId="demo-simple-select-helper-label"
      defaultValue={"all_countries"}
      value={value}
      displayEmpty
      onChange={handleChange}
      className={styles.customDropdown}
      style={{color:'#AFAFAF', border: '#AFAFAF',
      
      
        }}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                maxHeight: "200px",
                backgroundColor: '#1c1b1b',
                color: '#afafaf',
              },
            },
          },
        }}
      // MenuProps={{ PaperProps: { sx: {maxHeight: '200px', backgroundColor: '#1c1b1b',
      // color: 'white',
       
      
      // } } }}
    >
      <MenuItem   sx={{  
        "&:focus": {
          background: "#2EBB55",
          color: "white"
          },
       "&:hover": {
            background: "#AFAFAF",
            color: "white"
            }
        
        }}
        value="all_countries" selected={true}>
        All Countries
      </MenuItem>
      {
        countries.length > 0 && countries.filter((cntry) => cntry.name).map((country) => 
        <MenuItem  
        sx={{  
        "&:focus": {
          background: "#2EBB55",
          color: "white"
          },
       "&:hover": {
            background: "#AFAFAF",
            color: "white"
            }
        
        }}
        value={country.countryCode}>{country.name}</MenuItem>)
      }
    </Select>
  </FormControl>
    </ThemeProvider>
  )
}

export default CountryFilter