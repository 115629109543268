import { gql } from "@apollo/client";

export const ADDTOFAVOURITEARTIST = gql`
  mutation ($artistId: ID!) {
    addFavoriteArtist(artistId: $artistId) {
      success
      message
      isTokenExpired
      isTokenExpired
    }
  }
`;