/* eslint-disable require-jsdoc */
/* eslint-disable react/jsx-no-undef */
import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    "position": "relative",
    display:"flex",
    alignItems: "center",
    "padding": "7px 0px",
    "borderRadius": "50%",
    "backgroundColor": "#1c1b1b",
    "&:focus-within": {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    "marginLeft": 0,
    "width": "100%",
    transition: theme.transitions.create('border-radius'),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    },
    [theme.breakpoints.down("xs")]: {
      padding: 5
    }
  },
  searchIcon: {
    padding: "0px 14px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px !important",
      "& img": {
        height:15,
        width:15
      }
    },
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    paddingRight:'3px',
    transition: theme.transitions.create("width"),
    // [theme.breakpoints.up("sm")]: {
    "width": "0ch",
    "&:focus": {
      width: "20ch"
    },
    [theme.breakpoints.down("xs")]: {
        padding: "5px 8px 5px 18px",
        fontSize:12,
        "&:focus":{
          paddingLeft:23,
          width: "10ch"
        }
    },
    // }
  }
}));
function SearchBox() {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <img src='/svgs/moon.svg' alt='' />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
}

export default SearchBox;
