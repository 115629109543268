
import React,{useState} from 'react'

import {

	Select,
	FormControl,
	MenuItem,

} from '@mui/material';
import Paper from '@material-ui/core/Paper';

import styles from  "./styles.module.css"

import { useEffect } from 'react';

function PlaylistTypes() {
	const [activePlaylist, setActivePlaylist] = useState('All Playlist Type');
	const [playList, setPlayList] = useState([]);

	const handleChangePlaylist = (event) => {
		// setTableData([]);
		setActivePlaylist(event.target.value);
	};



    useEffect(()=>{
		setPlayList(['All Playlist Type', 'spotify', 'Album']);

    },[])
  return (
<FormControl sx={{width:"100%"}}  >
<Select
									value={activePlaylist}
									onChange={handleChangePlaylist}
									displayEmpty
									className={styles.customDropdownL}
									//inputProps={{ 'aria-label': 'Without label' }
									inputProps={{
										MenuProps: {
											MenuListProps: {
												sx: {
													maxHeight: '300px',
													backgroundColor: '#1c1b1b',
													color: '#afafaf',
												},
											},
										},
									}}
								>
									{playList.map((item, index) => (
										<MenuItem 
										sx={{  
											"&:focus": {
											  background: "#2EBB55",
											  color: "white"
											  },
										   "&:hover": {
												background: "#AFAFAF",
												color: "white"
												}
											}}
										value={item} key={index}>
											{item}
										</MenuItem>
									))}
								</Select>
							</FormControl>
  )
}

export default PlaylistTypes