import React, { useState } from 'react';

import {
	Box,
	Image,
	Heading,
	HStack,
	VStack,
	Button,
	Divider,
} from 'native-base';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TalentcomponentHeader } from '../../TalentComponentHeader/TalentComponentHeader';
import { TableHeaderCol3 } from '../../TalentComponentHeader/TableHeaderCol3';
import { TableContentCol3 } from '../../TableContent/TableContentCol3';
import moment from 'moment';
import SeeMore from '../../SeeMoreButton/SeeMore';
import { Loader } from '../../../Loader';
import styles from './../styles.module.css';
export const ApplePlaylist = ({ playList, isLoading,SetPlayLists }) => {
	const [seeMore, SetSeeMore] = useState(false);

	const [sorted,setSorted]=useState(false)
	console.log('playlist', playList);
	const handleSortIcon=()=>{
		setSorted(!sorted)
		console.log("dsakjdgsa")
		SetPlayLists(playList.sort((a, b) => (a.latestSubscriberCount) - (b.latestSubscriberCount)))
	
	}
	return (
		<Box
			my={5}
			py={5}
			bg={'#1C1B1B'}
			w={{ md: '48%', base: '100%' }}
			rounded={'lg'}
		>
			<Box w={'92%'} alignSelf={'center'}>
				<TalentcomponentHeader
					image={require('../../../../assets/TiktokComponents/mac-os-logo.png')}
					title={'Apple Music Playlists'}
					subtitle={'Top playlists by country reach'}
				/>
				<TableHeaderCol3
					title1={'NAME/COUNTRIES'}
					title2={'POSITIONS'}
					title3={'ADD ON'}
					isHeader={true}
				/>
				{isLoading && (
					<div className={styles.loaderDiv}>
						<Loader />
					</div>
				)}
				{playList.map(
					(data, index) =>
						index < (seeMore ? 14 : 5) && (
							<TableHeaderCol3
								isHeader={false}
								title1={data?.playlist?.name}
								title2={data?.position}
								title3={moment(data?.entryDate).format('DD MMM YYYY')}
								handleSort={""}
							/>
						)
				)}
				<SeeMore seeMore={seeMore} SetSeeMore={SetSeeMore} />
			</Box>
		</Box>
	);
};
