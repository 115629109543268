import { gql } from "@apollo/client";

export const GETFAVOURITESONGS = gql`
  query {
    getMyFavoriteSongs {
      success
      message
      isTokenExpired
      favoriteSongs
    }
  }
`;
