import React from 'react';
import { Box, HStack, Image, Link, Pressable, VStack, Text } from 'native-base';
import FooterIcons from '../../Footer/FooterIcons';
import FormTitle from '../FormTitle';
import Courosel from '../../Courosel/Courosel';
import footerLogo from '../../../assets/footers/logo.png';
import SignInBanner from '../../../assets/banner/SignInBanner.svg';

import { useBreakpointValue } from 'native-base';
function Sidebar() {
	const imgs = ['instagram.png', 'linkedin.png', 'twitter.png', 'youtube.png'];
	const imgUrls = [
		'https://instagram.com/unitedmarket?utm_medium',
		'https://www.linkedin.com/company/unitedmarket',
		'https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw',

		'https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ?app=desktop',
	];
	return (
		<div className='sidebar'>
			<Box h={'100%'} padding={'3%'}>
				<Image
					position={'absolute'}
					width={'100%'}
					height={'95%'}
					// mb={5}
					// mx={{ base: 2 }}
					//size={{ lg: 'full', md: 'full', base: 'full' }}
					resizeMode='cover'
					resizeMethod='scale'
					// source={require('../..//..//assets/SignIn/signinimage.png')}
					source={SignInBanner}
				/>
				<VStack w='90%' py={8} alignSelf={'center'}>
					<HStack width={'70%'}>
						<Link href='http://unitedmarket.com/' w={'100%'}>
							<img
								className='logoSignUpHeader'
								// mb={5}
								// ml={{ md: '50px', base: '20px' }}
								// size={{ lg: 'xl', md: 'lg', base: 'sm' }}

								// resizeMode='contain'
								src={footerLogo}
							/>
						</Link>

						{/* <HStack space={5} alignItems='center'>
							{imgs.map((item, index) => {
								return (
									<FooterIcons
										imgUrl={imgUrls[index]}
										imgPath='SignIn'
										imgName={item}
									/>
								);
							})}
						</HStack> */}
					</HStack>
					<div className='sidebarCoursel'>
						<Box>
							{/* <Text
								fontFamily='poppins'
								fontSize={{ lg: '3xl', md: '2xl', sm: 'lg', base: '1.1rem' }}
								fontWeight='800'
								color='#2EBB55'
							>
								Welcome to United Market
							</Text> */}
							{/* <FormTitle title="Welcome to United Market" /> */}
							{/* <Courosel /> */}
						</Box>
					</div>
				</VStack>
			</Box>
		</div>
	);
}

export default Sidebar;
