import React from 'react';
import { Grid } from '@mui/material';

import styles from './styles.module.css';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import TalentDashboardCharts from './TalentDashboardCharts';

const ChartsLayout = () => {
	return (
		<>
			<Grid className={styles.bgBlack}>
				<Sidebar
					activeText='Talent Dashboard'
					childComponent={<TalentDashboardCharts />}
				/>
			</Grid>
		</>
	);
};
export default ChartsLayout;
