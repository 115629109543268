import graphQLRequest from "../GraphQLRequest/graphqlRequest";
import { GETFEEDBYID, MOSTUPVOTES } from "./Query";

export default async function MostUpVotedBeats() {
    const data = await graphQLRequest(MOSTUPVOTES);
    console.log('data....', data);
    return data && data?.catalogItems?.edges
}

export const getFeedById = async(slugOrId) => {
    const data = await graphQLRequest(GETFEEDBYID, {
        slugOrId
    })
    console.log('feed by id data ..', data);
    return data;
}