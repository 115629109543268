import {
	Button,
	Divider,
	HStack,
	Image,
	Input,
	Text,
	VStack,
	Box,
	Heading,
	Menu,
	Radio,
	Switch,
	Center,
	Container,
	Checkbox,
	Pressable,
	Select,
	CheckIcon,
	ScrollView,
} from 'native-base';
import { useState, useEffect, useRef } from 'react';
import { Modal } from 'native-base';
import React from 'react';
import axios from 'axios';
import AudioLibraryHeader from './AudioLibraryHeader/AudioLibraryHeader';
import { Dashboard } from '@mui/icons-material';
import { DashboardHeader } from '../Headers';
import SideBar from '../SideBar/SideBar';
import AudioLibraryBody from './AudioLibraryBody';
import AudioUpload from './AudioUpload';
import AudioLibrarySample from './AudioLibraryMain';
import { SearchFieldComponent } from '../SearchField';
import CursorPlugin from 'wavesurfer.js/src/plugin/cursor';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsEye } from 'react-icons/bs';
import { FaPause, FaPlay } from 'react-icons/fa';
import ViewAccess from './ViewBeatPack';
import { ToastContainer, toast } from 'react-toastify';
import ViewFiles from './ViewFiles';
import coverImg from '../../assets/Images/1cover.png';
import { FiSend } from 'react-icons/fi';
import { borderRadius } from '@mui/system';
import Slider from '@mui/material/Slider';
import { Loader } from '../Loader';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import styles from './styles.module.css';
// import { useToast } from 'native-base';

const BeatPack = () => {
	const myRef = useRef();
	const [createModal, setCreatetModal] = useState(false);
	const [uploadModal, setUploadModal] = useState(false);
	const [shareModal, setShareModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [file, setFile] = useState();
	const [activeURL, setActiveURL] = useState('');
	const [audioAll, setAudioAll] = useState([]);
	const [packAll, setPackAll] = useState([]);
	const [showBP, setShowBP] = useState([]);
	const [addedFiles, setAddedFiles] = useState([]);
	const [tags, setTags] = useState([]);
	const [added, setAdded] = useState(false);
	const [showAccess, setShowAccess] = useState(false);
	const [showFiles, setShowFiles] = useState(false);
	const [viewObj, setViewObj] = useState();
	const [fileId, setFileId] = useState();
	const [bpId, setbpId] = useState();
	const [bpFiles, setBpFiles] = useState([]);
	const [bplink, setLink] = useState('');
	const [addTitle, setAddTitle] = useState('');
	const [addNote, setAddNote] = useState('');
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedAudio, setSelectedAudio] = useState(null);
	const [selectedCover, setSelectedCover] = useState(null);
	const [coverURL, setCoverUrl] = useState();
	const [bpm, setBPM] = useState('');
	const [scale, setScale] = useState('');
	const [k, setKey] = useState('');
	const [audioName, setAudioName] = useState('');
	const [selectedTags, setSelectedTags] = useState([]);
	const [bmpValue, setBPMValue] = useState([20, 100]);
	const [filterTags, setFilterTags] = useState(false);
	const [searchedFiles, setSearchedFiles] = useState('');
	const [filterValues, setFilterValues] = useState({});
	const [isCreated, setIsCreated] = useState(false);
	const [isSearched, setSearched] = useState(false);
	const [isDisable, setIsDisable] = useState(true);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteBP, setDeleteBP] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [isPlay, setIsPlay] = useState(false);
	const [playing, setPlaying] = useState(false);

	const artists = ['abc', 'vbsd', 'nsnsn', 'lkjhs', 'uioyt'];

	const startAudio = () => {
		myRef?.current?.play();
		setPlaying(true);
	};

	const pauseAudio = () => {
		myRef?.current?.pause();
		setPlaying(false);
	};

	useEffect(() => {
		if (activeURL && isPlay) {
			startAudio();
		} else {
			pauseAudio();
		}
	}, [activeURL, isPlay]);
	const playAudio = (url, isPlay) => {
		console.log('url', url);
		setActiveURL(url);
		setIsPlay(isPlay);
	};

	function Detailsheading(props) {
		const { name } = props;
		return (
			<Box
				mx={2}
				my={1}
				borderWidth={'1'}
				borderColor={'#AFAFAF'}
				rounded={'md'}
			>
				<Heading
					p={1}
					fontSize={{ xl: 'sm', base: 'sm' }}
					fontWeight={'normal'}
					color={'#afafaf'}
					fontFamily={'poppins'}
				>
					{name}
				</Heading>
			</Box>
		);
	}

	const allTags = filterValues.tags;

	const handleAudioUpload = (val) => {
		setSelectedAudio(val.target.files[0]);
		setAudioName(val.target.files[0].name);
		setCreatetModal(false);
		setUploadModal(true);
	};

	const getFilterValue = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getfiltervalues`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				setFilterValues(result.filterValues);
				let temp = [...bmpValue];
				temp[1] = result.filterValues.maxBPM;
				setBPMValue(temp);
			})
			.catch((error) => console.log('error', error));
	};

	const handleBPM = (val) => {
		if (val.target.value > 200) {
			val.preventDefault();
			toast.error('Invalid BPM range');
			val.target.value = '';
		} else setBPM(val.target.value);
	};

	const handleChange = (event, newValue) => {
		setBPMValue(newValue);

		handleSearchFiles(searchedFiles, bmpValue[1], selectedTags);
	};
	const valuetext = (value) => {
		return `${value}`;
	};

	const handleBPMInput = (val) => {
		var ch = String.fromCharCode(val.which);

		if (!(/[0-9]/.test(ch) || val.which === 8)) {
			val.preventDefault();
			toast.error('Invalid BPM');
		}
		//   var key = val.keyCode;
		//   if (!((key == 8) || (key == 46) || (key >= 35 && key <= 40) || (key >= 48 && key <= 57)      || (key >= 96 && key <= 105))) {
		//     val.preventDefault();
		//     toast.error("Invalid BPM")

		// }
	};
	const addTag = (e) => {
		if (e.key === 'Enter') {
			if (e.target.value.length > 0) {
				setTags([...tags, e.target.value]);

				e.target.value = '';
			}
		}
	};
	const removeTag = (removedTag) => {
		const newTags = tags.filter((tag) => tag !== removedTag);
		setTags(newTags);
	};

	const toggleState = () => {
		setAdded(!added);
	};

	const toggleTag = () => {
		setFilterTags(!filterTags);
	};

	const addFilterTag = (tag) => {
		setSelectedTags([...selectedTags, tag]);
	};

	const removeFilterTag = (removedTag) => {
		setSelectedTags(selectedTags.filter((tag) => tag !== removedTag));
	};

	const handleScreens = (numb, obj) => {
		setViewObj(obj);
		if (numb === 1) {
			setShowAccess(true);
			setShowFiles(false);
		}
		if (numb === 2) {
			setShowAccess(false);
			setShowFiles(true);
		}
	};

	const handleState = () => {
		isCreated === true ? addFiles() : createBeatPack();
	};

	const handleSearch = (searchText) => {
		setIsLoading(true);
		var requestOptions = {
			method: 'POST',
			headers: { Authorization: headerkey, 'Content-Type': 'application/json' },
			body: JSON.stringify({ searchQuery: searchText }),
			redirect: 'follow',
		};
		fetch(
			`${process.env.REACT_APP_API_URL}/file/searchbeatpacks`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.beatpacks) {
					setShowBP(result.beatpacks);
					setIsLoading(false);
				} else {
					setShowBP();
					setIsLoading(false);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log('error', error);
			});
	};

	const handleSearchFiles = (searchText, mbpm, addedTags) => {
		var data = {
			searchQuery: searchText,
			maxBPM: mbpm,
			tags: addedTags,
		};

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/searchallaudiofiles`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				if (response.data.files) {
					setAudioAll(response.data.files);
				} else {
					setAudioAll();
				}
			})

			.catch(function (error) {
				if (error?.response?.status === 404) {
					setAudioAll([]);
				}
				console.log(error);
			});
	};

	const onChangeSearch = (e) => {
		// if (e.target.value.length < 0) {
		//   showBeatPacks();
		// }
		e.length <= 1 ? showBeatPacks() : handleSearch(e);
	};

	const onChangeSearchFiles = (e) => {
		setSearchedFiles(e);
		e.length <= 1
			? fetchData()
			: handleSearchFiles(searchedFiles, '', selectedTags);
	};

	const onTagSearch = () => {
		selectedTags.length === 0
			? fetchData()
			: handleSearchFiles(searchedFiles, bmpValue[1], selectedTags);
	};

	const addFuncObj = (file_obj, file_id) => {
		bpFiles.push(file_obj);
		addedFiles.push(file_id);
	};
	const remFuncObj = (file_obj, file_id) => {
		console.log('inside remove func', file_obj);
		const newArr = bpFiles.filter((file) => file !== file_obj);

		setBpFiles(newArr);
		const arr = addedFiles.filter((file) => file !== file_id);

		setAddedFiles(arr);
	};

	useEffect(() => {
		onTagSearch();
	}, [selectedTags]);

	const getTitle = (val) => {
		setAddTitle(val.target.value);
	};

	const getNote = (val) => {
		setAddNote(val.target.value);
	};

	const headerkey = `Bearer ${localStorage.getItem('token')}`;

	var audioHeaders = new Headers();
	audioHeaders.append('Authorization', headerkey);

	const fetchData = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallaudiofiles`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				setAudioAll(data.files);
			})
			.catch((error) => {
				console.log('error', error);
			});
	};
	const handleInputCover = (val) => {
		setSelectedCover(val.target.files[0]);
		uploadProfile(val.target.files[0]);
	};

	const uploadProfile = (pic) => {
		var data = new FormData();
		data.append('file', pic);
		data.append('type', 'Public');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/uploadprofilepicture`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				setCoverUrl(response.data.file.url);
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const toastId = useRef(null);

	const uploadFile = () => {
		var FormData = require('form-data');
		var data = new FormData();
		data.append('file', selectedAudio);
		data.append('type', 'Public');
		data.append('audioicon', selectedImage);
		data.append('BPM', bpm);
		data.append('key', k);
		data.append('scale', scale);
		data.append('tags', tags);
		data.append('isBeatInvitationAccepted', 'false');

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/upload`,
			headers: { Authorization: headerkey },
			data: data,
			onUploadProgress: (p) => {
				const progress = p.loaded / p.total;

				// check if we already displayed a toast
				if (toastId.current === null) {
					toastId.current = toast('Upload in Progress', { progress });
				} else {
					toast.update(toastId.current, { progress });
				}
			},
		};

		axios(config)
			.then(function (response) {
				fetchData();

				toast.done(toastId.current);
				toast.success('File Uploaded Successfully');
			})
			.catch(function (error) {
				console.log(error);
			});
		setBPM();
		setKey();
		setScale();
		setSelectedImage();
		setTags([]);
	};

	const addFiles = () => {
		addFiles(addedFiles, bpId);
		addedFiles.map((fId) => {
			var data = {
				fileId: fId,
				beatPackId: bpId,
			};
			var config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/file/addtobeatpack`,
				headers: { Authorization: headerkey },

				data: data,
			};

			axios(config)
				.then(function (response) {
					showBeatPacks();
				})
				.catch(function (error) {
					console.log(error);
				});
		});

		setAddedFiles([]);
		setBpFiles([]);
	};

	const createBeatPack = () => {
		if (addTitle?.trim()?.length > 1) {
			var newProfile = coverURL ? coverURL : coverImg;
			var data = {
				beatPackName: addTitle,
				beatPackCover: newProfile,
				files: addedFiles,
				note: addNote,
			};
			var config = {
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/file/createbeatpack`,
				headers: { Authorization: headerkey },
				data: data,
			};

			axios(config)
				.then(function (response) {
					setErrorMsg('');
					showBeatPacks();
					setIsDisable(false);
					toast.success('Created BeatPack Successfully');
					setbpId(response.data.newBeatPack._id);
					// setUploadModal(false);
					// setCreatetModal(false);
				})
				.catch(function (error) {
					console.log(error);
				});

			//setAddedFiles([]);
			//setBpFiles([]);
			//setSelectedCover();
		} else {
			setErrorMsg('Beat pack name is required');
		}
		// setbpId(data.newBeatPack._id)
	};

	const copyBeatPack = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getbeatpacklink/${bpId}/false`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				setLink(result.beatPackLink);
				navigator.clipboard.writeText(result.beatPackLink);
				toast.success('Link Copied!');
			})
			.catch((error) => console.log('error', error));
	};
	const handleCloseBeatPack = () => {
		setCreatetModal(true);
		setIsCreated(false);
		setIsDisable(true);
		setAddedFiles([]);
		setBpFiles([]);
		setSelectedCover();
	};
	const showBeatPacks = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallbeatpacks`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.beatpacks) {
					setShowBP(result.beatpacks);
				} else {
					setShowBP();
				}
				//{isSearched===true? setShowBP(searchedPacks): setShowBP(result.beatpacks)}
			})
			.catch((error) => console.log('error', error));
	};

	const deleteBeatPack = (bp_id) => {
		var data = {
			beatPackId: bp_id,
		};
		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/deletebeatpack`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				toast.success('Deleted BeatPack Successfully');
				showBeatPacks();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		showBeatPacks();
		fetchData();
		getFilterValue();
		setLink('');
	}, []);

	if (showAccess === true)
		return (
			<>
				<ViewAccess obj={viewObj} />
			</>
		);
	else if (showFiles === true)
		return (
			<>
				<ViewFiles obj={viewObj} />
			</>
		);
	else
		return (
			<>
				<Box w={'100%'} bg={'#000'}>
					<HStack w={'100%'}>
						<Box
							display={{ lg: 'block', base: 'none' }}
							w={{ xl: '17%', lg: '17%', base: '0%' }}
						>
							<SideBar />
						</Box>
						<Box
							borderLeftWidth={{ xl: 1, lg: 1, md: 0 }}
							borderColor={'#fff'}
							w={{ xl: '83%', lg: '83%', base: '100%' }}
						>
							<DashboardHeader />
							<Divider mt={1} />

							<VStack
								py={1}
								w={'100%'}
								bg='#000'
								justifyContent={'space-around'}
							>
								<HStack
									w={'100%'}
									px='1'
									py={['1', '1', '1']}
									alignSelf={'center'}
									justifyContent={'space-between'}
								>
									<Box w={'15%'} py='3' alignSelf={'center'}>
										<Heading
											fontSize={{ xl: '40px', lg: '4xl', md: '3xl', sm: '2xl' }}
											fontWeight={'medium'}
											color='white'
											alignSelf={'center'}
										>
											Packs
										</Heading>
									</Box>

									<Box w={'65%'} px='1' py='3' alignSelf={'center'}>
										<SearchFieldComponent
											placeholder='Search Packs'
											onChangeSearch={onChangeSearch}
											alignSelf={'center'}
											borderWidth='67px'
											borderColor='#afafaf'
											backgroundColor={'#1C1B1B'}
										/>
									</Box>

									<HStack w={'20%'} px='1' py='3' alignSelf={'center'}>
										{/* <Box w={"35%"} >
        <Button
           bg={"#1C1B1B"} 
          alignSelf={"center"}
          _hover={{ bg: "#2EBA55" }}
          _focused={{ bg: "#2EBA55" }}
          _pressed={{ bg: "transparent" }}
        >
          <Image
            source={require("../../assets/Icons/filter.png")}
            size="7"
            alt='filter'

          />
        </Button>
    </Box> */}
										<Box w={'100%'}>
											<Button
												bg={'#2EBB55'}
												borderWidth={1}
												borderColor={'#2EBB55'}
												_hover={{
													bg: 'transparent',
													_text: { color: '#2EBB55' },
												}}
												_pressed={{
													bg: '#2EBB55',
													_text: { color: '#e3e3e3' },
												}}
												_text={{
													color: '#e3e3e3',
													fontSize: { xl: '20px', base: 'lg' },
													fontFamily: 'Poppins',
													textAlign: 'center',
													textTransform: 'uppercase',
													fontWeight: 'medium',
												}}
												w={'70%'}
												py={2}
												alignSelf={'center'}
												onPress={() => handleCloseBeatPack()}
											>
												Create +
											</Button>
										</Box>
									</HStack>
								</HStack>

								<HStack
									w={'100%'}
									px='1'
									py={['1', '1', '1']}
									justifyContent={'space-evenly'}
								>
									<VStack w={'90%'} alignSelf={'center'} my={'5'}>
										<ToastContainer />

										{showBP ? (
											isLoading ? (
												<div className={styles.loaderDivBeatPack}>
													<Loader />
												</div>
											) : (
												showBP.map((bpObj, index) => (
													<>
														<HStack
															key={index}
															justifyContent={'space-between'}
															w={'95%'}
														>
															<Pressable
																onPress={() => {
																	setCreatetModal(true);
																	setbpId(bpObj._id);
																	setIsCreated(true);
																}}
																alignSelf={'center'}
															>
																{bpObj.beatPackCover ? (
																	<img
																		src={bpObj.beatPackCover}
																		style={{
																			height: '120px',
																			width: '110px',
																			mx: 5,
																			borderRadius: 20,
																		}}
																	/>
																) : (
																	<img
																		src={coverImg}
																		style={{
																			height: '120px',
																			width: '110px',
																			mx: 5,
																			borderRadius: 20,
																		}}
																	/>
																)}
															</Pressable>
															<Pressable
																onPress={() => {
																	handleScreens(2, bpObj);
																}}
																alignSelf={'center'}
																w={'65%'}
															>
																<Box mx={5} w={'100%'} my={2} py={5}>
																	<Text
																		fontSize={{
																			lg: '24px',
																			sm: '18px',
																			base: '16px',
																		}}
																		py={1}
																		color='#afafaf'
																		fontWeight={'semibold'}
																	>
																		{/* Beat Pack 1 */}
																		{bpObj.beatPackName
																			? bpObj.beatPackName
																			: 'Untitled Beatpack'}
																	</Text>

																	<Text
																		fontSize={{
																			lg: '20px',
																			sm: '16px',
																			base: '14px',
																		}}
																		py={1}
																		color='#afafaf'
																	>
																		{/* Beat Pack 1 */}
																		{bpObj.note}
																	</Text>

																	<Text
																		fontSize={{
																			lg: '18px',
																			sm: '14px',
																			base: '12px',
																		}}
																		py={1}
																		color='#afafaf'
																	>
																		{/* 2 Files */}
																		{bpObj.folderId.files.length} Files
																	</Text>
																</Box>
															</Pressable>

															<HStack justifyContent={'flex-end'}>
																<Button
																	onPress={() => {
																		setShareModal(true);
																		setbpId(bpObj._id);
																	}}
																	py={2}
																	bg={'transparent'}
																	borderColor={'#afafaf'}
																	borderWidth={1}
																	mx={2}
																	alignSelf={'center'}
																	_hover={{
																		bg: '#2EBB55',
																		borderColor: '#2EBB55',
																	}}
																	_focused={{ bg: 'transparent' }}
																	_pressed={{ bg: 'transparent' }}
																	_text={{ color: '#e3e3e3' }}
																>
																	Share
																</Button>

																<Button
																	py={2}
																	bg={'transparent'}
																	borderColor={'#afafaf'}
																	borderWidth={1}
																	mx={2}
																	onPress={() => {
																		handleScreens(1, bpObj);
																	}}
																	alignSelf={'center'}
																	_hover={{
																		bg: '#2EBB55',
																		borderColor: '#2EBB55',
																	}}
																	_focused={{ bg: 'transparent' }}
																	_pressed={{ bg: 'transparent' }}
																>
																	<BsEye size={20} color={'#e3e3e3'} />
																</Button>
																<Button
																	py={2}
																	bg={'transparent'}
																	borderColor={'#afafaf'}
																	borderWidth={1}
																	mx={2}
																	onPress={() => {
																		setDeleteBP(bpObj._id);
																		setDeleteModal(true);
																	}}
																	alignSelf={'center'}
																	_hover={{
																		bg: '#2EBB55',
																		borderColor: '#2EBB55',
																	}}
																	_focused={{ bg: 'transparent' }}
																	_pressed={{ bg: 'transparent' }}
																>
																	<RiDeleteBinLine
																		size={20}
																		color={'#e3e3e3'}
																	/>
																</Button>
															</HStack>
														</HStack>
													</>
												))
											)
										) : (
											<>
												<HStack>
													<Text
														fontSize={{ lg: '24px', md: '18px', base: '16px' }}
														py={1}
														color='#afafaf'
														fontWeight={'semibold'}
													>
														No Packs Found
													</Text>
												</HStack>
											</>
										)}
									</VStack>
								</HStack>
							</VStack>
						</Box>
					</HStack>
				</Box>

				<Modal
					isOpen={createModal}
					onClose={() => setCreatetModal(false)}
					w={'80%'}
					size={'full'}
					alignSelf={'center'}
					justifyContent={'flex-start'}
					mt={20}
				>
					<Modal.CloseButton
						_hover={{ bg: 'transparent' }}
						_focused={{ bg: 'transparent' }}
						_pressed={{ bg: 'transparent' }}
						mx={5}
					/>
					<HStack w={'100%'} justifyContent={'space-between'}>
						<VStack
							w={'38%'}
							rounded='md'
							backgroundColor={'#141313'}
							h={'70vh'}
							mb={5}
						>
							<HStack justifyContent={'space-evenly'} my={2}>
								<Box w={'70%'} px='5' py='3' alignSelf={'center'}>
									<Text
										fontSize={{ lg: 'xl', md: 'xl', sm: 'lg' }}
										color='#e3e3e3'
										fontFamily={'Poppins'}
										fontWeight={'normal'}
										alignSelf={'center'}
									>
										Select Files
									</Text>
								</Box>
								<Box w={'30%'} px='1' py='3' alignSelf={'center'}>
									<Button
										bg={'#2EBB55'}
										borderWidth={1}
										borderColor={'#2EBB55'}
										_hover={{
											bg: 'transparent',
											_text: { color: '#2EBB55' },
										}}
										_pressed={{
											bg: '#2EBB55',
											_text: { color: '#e3e3e3' },
										}}
										_text={{
											color: '#e3e3e3',
											fontSize: { xl: '18px', base: 'lg' },
											fontFamily: 'Poppins',
											textAlign: 'center',
											textTransform: 'uppercase',
											fontWeight: 'medium',
										}}
										w={'90%'}
										py={2}
										alignSelf={'center'}
										// onPress={() => {setCreatetModal(false) }}
									>
										<label
											htmlFor='hhj'
											cursor='pointer'
											style={{
												fontSize: 'px',
												color: 'white',
												fontFamily: 'Poppins',
											}}
										>
											Upload
										</label>
										<input
											accept='audio/*'
											type='file'
											id='hhj'
											style={{ display: 'none', visibility: 'none' }}
											onChange={handleAudioUpload}
										/>
									</Button>
								</Box>
							</HStack>
							<Divider />
							<HStack justifyContent={'space-evenly'} py='5'>
								<HStack w={'70%'} px='1' py='3'>
									<SearchFieldComponent
										modal={true}
										onChangeSearch={onChangeSearchFiles}
										backgroundColor={'transparent'}
										placeholder='Search Files'
										alignSelf={'center'}
									/>
								</HStack>
								<HStack
									w={'20%'}
									px='1'
									py={['2', '2', '3']}
									justifyContent={'space-evenly'}
								>
									<Menu
										rounded={'lg'}
										borderColor={'#fff'}
										borderWidth={1}
										alignSelf={'center'}
										color={'#afafaf'}
										fontSize={{ md: '16px' }}
										mt={2}
										w={'20vw'}
										_text={{
											color: '#afafaf',
										}}
										background={'#1C1B1B'}
										placement={'bottom'}
										trigger={(triggerProps) => {
											return (
												<Pressable
													bg={'#1C1B1B'}
													rounded={'sm'}
													_hover={{ bg: '#2EBA55' }}
													_focused={{ bg: '#2EBA55' }}
													_pressed={{ bg: 'transparent' }}
													accessibilityLabel='More options menu'
													{...triggerProps}
												>
													<HStack
														size={'sm'}
														h='full'
														rounded={'sm'}
														fontSize={{
															lg: '16px',
															md: '16px',
															sm: '12px',
														}}
														_hover={{ bg: '#2EBA55' }}
														_focused={{ bg: '#2EBA55' }}
														_pressed={{ bg: 'transparent' }}
													>
														<Image
															source={require('../../assets/Icons/filter.png')}
															size='8'
															alt='filter'
															alignSelf={'center'}
															mx={3.5}
														/>
													</HStack>
												</Pressable>
											);
										}}
									>
										<Menu.Item
											bg={'#1C1B1B'}
											m={0}
											pb={2}
											w={'100%'}
											flexGrow={1}
											flexWrap={'wrap'}
											flexBasis={'max-content'}
											_focus={{
												bg: '#1C1B1B',
											}}
											_hover={{
												bg: '#1C1B1B',
											}}
											_pressed={{
												bg: '#1C1B1B',
											}}
											_text={{
												color: '#fff',
												textAlign: 'center',
												margin: 'auto',
												textDecoration: 'none',
												py: 2,
											}}
											borderBottomWidth='1px'
											borderBottomColor='#afafaf'
										>
											<HStack flexWrap={'wrap'} flexGrow={1} w={'100%'}>
												<Heading
													color={'#fff'}
													textAlign={'center'}
													py={2}
													pr={2}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													fontSize={'md'}
												>
													Tags:
												</Heading>

												{allTags &&
													allTags.map((tag, index) => (
														<HStack
															bg={'transparent'}
															borderColor={'white'}
															rounded='md'
															borderWidth={1}
															pl={1}
															alignSelf={'center'}
															m={1}
															color={'#afafaf'}
														>
															{tag}

															<Button
																h={'0.5'}
																bg={'#1C1B1B'}
																alignSelf={'center'}
																onPress={() => {
																	selectedTags.includes(tag)
																		? removeFilterTag(tag)
																		: addFilterTag(tag);
																	toggleTag();
																}}
																_hover={{ bg: '#1C1B1B' }}
																_focused={{ bg: '#1C1B1B' }}
																_pressed={{ bg: '#1C1B1B' }}
															>
																{selectedTags.includes(tag) ? 'x' : '+'}
															</Button>
														</HStack>
													))}
											</HStack>
										</Menu.Item>
										<Menu.Item
											bg={'#1C1B1B'}
											m={0}
											p={0}
											w={'100%'}
											flexGrow={1}
											flexWrap={'wrap'}
											flexBasis={'max-content'}
											_focus={{
												bg: '#1C1B1B',
											}}
											_hover={{
												bg: '#1C1B1B',
											}}
											_pressed={{
												bg: '#1C1B1B',
											}}
											_text={{
												color: '#fff',
												textAlign: 'center',
												margin: 'auto',
												textDecoration: 'none',
												py: 2,
											}}
											borderBottomWidth='1px'
											borderBottomColor='#afafaf'
										>
											<HStack flexWrap={'wrap'} flexGrow={1} w={'100%'}>
												<Heading
													color={'#fff'}
													textAlign={'center'}
													py={2}
													pr={2}
													fontFamily={'Poppins'}
													fontWeight={'normal'}
													fontSize={'md'}
												>
													Artists:
												</Heading>
												{artists &&
													artists.map((artist, index) => (
														<HStack
															bg={'transparent'}
															borderColor={'white'}
															rounded='md'
															borderWidth={1}
															alignSelf={'center'}
															m={1}
															color={'#afafaf'}
														>
															<Button
																h={'0.5'}
																px={1}
																bg={'#1C1B1B'}
																alignSelf={'center'}
																// onPress={() => {addTag(artist)} }
																_hover={{ bg: '#1C1B1B' }}
																_focused={{ bg: '#1C1B1B' }}
																_pressed={{ bg: '#1C1B1B' }}
															>
																{artist}
															</Button>
														</HStack>
													))}
											</HStack>
										</Menu.Item>
										<Menu.Item
											bg={'#1C1B1B'}
											m={0}
											p={0}
											flexWrap={'wrap'}
											flexBasis={'max-content'}
											_focus={{
												bg: '#1C1B1B',
											}}
											_hover={{
												bg: '#1C1B1B',
											}}
											_pressed={{
												bg: '#1C1B1B',
											}}
											_text={{
												color: '#fff',
												textAlign: 'center',
												margin: 'auto',
												textDecoration: 'none',
												py: 2,
											}}
											borderBottomWidth='1px'
											borderBottomColor='#afafaf'
										>
											<Heading
												color={'#fff'}
												textAlign={'center'}
												py={2}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={'md'}
											>
												BMP
											</Heading>
											<Slider
												sx={{
													color: '#2EBA55',
												}}
												getAriaLabel={() => 'BPM range'}
												value={bmpValue}
												onChange={handleChange}
												valueLabelDisplay='auto'
												getAriaValueText={valuetext}
												min={10}
												max={filterValues.maxBPM}
											/>
										</Menu.Item>
										<Menu.Item
											bg={'#1C1B1B'}
											m={0}
											p={0}
											flexWrap={'wrap'}
											flexBasis={'max-content'}
											_focus={{
												bg: '#1C1B1B',
											}}
											_hover={{
												bg: '#1C1B1B',
											}}
											_pressed={{
												bg: '#1C1B1B',
											}}
											_text={{
												color: '#fff',
												textAlign: 'center',
												margin: 'auto',
												textDecoration: 'none',
												py: 2,
											}}
											borderBottomWidth='1px'
											borderBottomColor='#afafaf'
										>
											<Heading
												color={'#fff'}
												textAlign={'center'}
												py={2}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={'md'}
											>
												Key
											</Heading>
											<Input
												h={'12'}
												my={2}
												variant={'unstyled'}
												_important={{
													backgroundColor: 'transparent',
													borderColor: 'ransparent',
												}}
												fontSize={{ lg: '18px', md: '16px', sm: '12px' }}
												color={'#afafaf'}
											></Input>
										</Menu.Item>
										<Menu.Item
											bg={'#1C1B1B'}
											m={0}
											p={0}
											flexWrap={'wrap'}
											flexBasis={'max-content'}
											_focus={{
												bg: '#1C1B1B',
											}}
											_hover={{
												bg: '#1C1B1B',
											}}
											_pressed={{
												bg: '#1C1B1B',
											}}
											_text={{
												color: '#fff',
												textAlign: 'center',
												margin: 'auto',
												textDecoration: 'none',
												py: 2,
											}}
										>
											<Heading
												color={'#fff'}
												textAlign={'center'}
												py={2}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={'md'}
											>
												Scale
											</Heading>
											<Input
												h={'12'}
												my={2}
												variant={'unstyled'}
												_important={{
													backgroundColor: 'transparent',
													borderColor: 'ransparent',
												}}
												fontSize={{ lg: '18px', md: '16px', sm: '12px' }}
												color={'#afafaf'}
											></Input>
										</Menu.Item>
									</Menu>
								</HStack>
							</HStack>
							<ScrollView showsVerticalScrollIndicator={false}>
								{audioAll && audioAll.length > 0 ? (
									audioAll.map((audioObj, index) => (
										<>
											<HStack
												key={index}
												w={'100%'}
												justifyContent={'space-between'}
												py={2}
												px={3}
												flexWrap={'wrap'}
												// flexGrow={1}
											>
												<Box w={'20%'} px={3}>
													{audioObj.file.audioIconUrl ? (
														<img
															src={audioObj.file.audioIconUrl}
															style={{
																height: 'auto',
																width: '100%',
															}}
														/>
													) : (
														<img
															src={coverImg}
															style={{
																height: 'auto',
																width: '100%',
															}}
														/>
													)}
												</Box>
												<VStack w={'70%'} alignSelf={'center'}>
													<Text
														// w={'90%'}
														fontSize={{
															xl: '16px',
															lg: '16px',
															sm: '14px',
															base: '16px',
														}}
														color='#afafaf'
														mx={1}
													>
														{audioObj.file.fileName}
													</Text>
													<HStack
														// w={'90%'}
														// my={3}
														flexWrap={'wrap'}
														flexGrow={1}
														alignSelf={'flex-start'}
													>
														{audioObj.file.BPM != null && (
															<Detailsheading
																name={audioObj.file.BPM + ' bpm'}
															/>
														)}
														{audioObj.file.fileKey != null && (
															<Detailsheading name={audioObj.file.fileKey} />
														)}
														{audioObj.file.scale != null && (
															<Detailsheading name={audioObj.file.scale} />
														)}

														{audioObj.file.tags
															? audioObj.file.tags.map((tag, index) => (
																	<Detailsheading name={'# ' + tag} />
															  ))
															: null}
													</HStack>
												</VStack>
												<Box w={'10%'} alignSelf={'center'}>
													{bpFiles.includes(audioObj) ? (
														<Button
															onPress={() => {
																remFuncObj(audioObj, audioObj.file._id);
																toggleState();
															}}
															bg={'#1C1B1B'}
															p={0}
															py={1}
															px={2.5}
															alignSelf={'center'}
															_hover={{ bg: '#1C1B1B' }}
															_focused={{ bg: '#1C1B1B' }}
															_pressed={{ bg: '#1C1B1B' }}
														>
															+
														</Button>
													) : (
														<Button
															onPress={() => {
																addFuncObj(audioObj, audioObj.file._id);
																toggleState();
															}}
															p={0}
															py={1.5}
															px={1.5}
															bg={'#2EBA5520'}
															alignSelf={'center'}
															_hover={{ bg: '#2EBA5520' }}
															_focused={{ bg: '#2EBA5520' }}
															_pressed={{ bg: '#2EBA5520' }}
														>
															<DoneRoundedIcon
																sx={{ color: '#2ebb55', fontSize: 15 }}
															/>
														</Button>
													)}
												</Box>
											</HStack>
										</>
									))
								) : (
									<HStack mx={5}>
										<Text
											fontSize={{ lg: '18px', sm: '12px', base: '13px' }}
											py={1}
											color='#afafaf'
											fontWeight={'semibold'}
										>
											No Files Found
										</Text>
									</HStack>
								)}
							</ScrollView>
						</VStack>

						<Box
							w={'58%'}
							rounded='md'
							backgroundColor={'#141313'}
							h={'70vh'}
							mb={5}
						>
							<HStack px={10} py={5}>
								<HStack>
									<img
										width={'150px'}
										height={'180px'}
										mx={5}
										alt={'cover'}
										src={
											selectedCover == null
												? coverImg
												: URL.createObjectURL(selectedCover)
										}
									/>
									<HStack
										variant={'unstyled'}
										px={2}
										rounded={'md'}
										position={'absolute'}
										bottom='1'
										right='5'
										bg={'#2EBA5570'}
									>
										<label
											htmlFor='cover'
											cursor='pointer'
											style={{
												fontSize: '16px',
												color: '#e3e3e3',
												borderRadius: 18,
												fontFamily: 'Poppins',
												backgroundColor: 'transparent',
											}}
										>
											+
										</label>
										<input
											accept='image/*'
											type='file'
											id='cover'
											style={{ display: 'none', visibility: 'none' }}
											// onChange={(val) => {
											// setSelectedCover(val.target.files[0])
											// console.log("selectedCoverUploaded", selectedCover)
											// uploadProfile();
											// }}
											onChange={handleInputCover}
										/>
									</HStack>
								</HStack>
								<VStack justifyContent={'space-evenly'}>
									<Input
										placeholder='Untitled Beatpack '
										onChange={getTitle}
										borderRadius={0}
										borderBottomWidth={1}
										borderBottomColor={'#afafaf'}
										m={2}
										w={'100%'}
										variant={'unstyled'}
										_important={{ backgroundColor: 'transparent' }}
										fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
										color={'#e3e3e3'}
									></Input>
									<Text
										fontSize={{
											lg: '16px',
											sm: '14px',
											base: '14px',
										}}
										py={1}
										px={2}
										color='#FF0000'
									>
										{errorMsg}
									</Text>
									<Input
										placeholder='Add a note'
										onChange={getNote}
										borderRadius={0}
										m={2}
										w={'100%'}
										borderBottomWidth={1}
										borderBottomColor={'#afafaf'}
										variant={'unstyled'}
										_important={{ backgroundColor: 'transparent' }}
										fontSize={{ lg: '16px', md: '12px', sm: '10px' }}
										color={'#afafaf'}
									></Input>
								</VStack>
							</HStack>

							<ScrollView showsVerticalScrollIndicator={false}>
								{bpFiles &&
									bpFiles.map((bpFileObj, index) => (
										<HStack
											key={index}
											w={'95%'}
											alignSelf={'center'}
											justifyContent={'space-between'}
											py={2}
											mt={5}
										>
											<Box w={'25%'}>
												<Image
													height={{ lg: '90px', md: '100px', sm: '60px' }}
													rounded={'md'}
													width={{ lg: '80px', md: '85px', sm: '55px' }}
													alignSelf={'center'}
													source={
														bpFileObj.file.audioIconUrl
															? bpFileObj.file.audioIconUrl
															: require('../../assets/Images/1cover.png')
													}
													//  size={["8", "10", "12"]}
													mt='0.5'
													alt='icon'
												/>
											</Box>

											<Text
												fontSize={{ lg: '18px', sm: '14px' }}
												color='white'
												w={'60%'}
												px={2}
												py={5}
												alignSelf={'center'}
											>
												{bpFileObj.file.fileName}
											</Text>
											<Box w={'10%'} alignSelf={'center'}>
												<Button
													onPress={() => {
														remFuncObj(bpFileObj, bpFileObj.file._id);
														toggleState();
													}}
													p={0}
													py={1.5}
													px={1.5}
													bg={'#2EBA5520'}
													alignSelf={'center'}
													_hover={{ bg: '#2EBA5520' }}
													_focused={{ bg: '#2EBA5520' }}
													_pressed={{ bg: '#2EBA5520' }}
												>
													<DoneRoundedIcon
														sx={{ color: '#2ebb55', fontSize: 15 }}
													/>
												</Button>
											</Box>

											<audio ref={myRef} src={activeURL} />
											{console.log(activeURL, 'active')}
											{playing && activeURL === bpFileObj.file.url ? (
												<div
													onClick={() => playAudio(bpFileObj.file.url, false)}
													style={{ alignSelf: 'center' }}
												>
													<FaPause
														size={20}
														style={{
															cursor: 'pointer',
															alignSelf: 'center',
															color: 'white',
														}}
													/>
												</div>
											) : (
												<div
													onClick={() => playAudio(bpFileObj.file.url, true)}
													style={{ alignSelf: 'center' }}
												>
													<FaPlay
														size={20}
														style={{
															cursor: 'pointer',
															alignSelf: 'center',
															color: 'white',
														}}
													/>
												</div>
											)}
										</HStack>
									))}
							</ScrollView>
						</Box>
					</HStack>
					<HStack w={'100%'} justifyContent={'space-between'}>
						<VStack
							w={'38%'}
							rounded='md'
							backgroundColor={'#141313'}
							h={'30vh'}
							mt={5}
						>
							<HStack
								w={'95%'}
								alignSelf={'center'}
								justifyContent={'space-between'}
								py={2}
								px={3}
								flexWrap={'wrap'}
								// flexGrow={1}
							>
								<Box w={'20%'} px={3}>
									{false ? (
										<img
											src={coverImg}
											style={{
												height: 'auto',
												width: '100%',
											}}
										/>
									) : (
										<img
											src={coverImg}
											style={{
												height: 'auto',
												width: '100%',
											}}
										/>
									)}
								</Box>
								<VStack w={'60%'} alignSelf={'center'}>
									<Text
										// w={'90%'}
										fontSize={{
											xl: '16px',
											lg: '16px',
											sm: '14px',
											base: '16px',
										}}
										color='#afafaf'
										mx={1}
									>
										Funny Music
									</Text>
									{/* doNot delete untill ui is filnal */}
									{/* <HStack
                    // w={'90%'}
                    // my={3}
                    flexWrap={'wrap'}
                    flexGrow={1}
                    alignSelf={'flex-start'}
                  >
                    {audioObj.file.BPM != null && (
                      <Detailsheading name={audioObj.file.BPM + ' bpm'} />
                    )}
                    {audioObj.file.fileKey != null && (
                      <Detailsheading name={audioObj.file.fileKey} />
                    )}
                    {audioObj.file.scale != null && (
                      <Detailsheading name={audioObj.file.scale} />
                    )}

                    {audioObj.file.tags
                      ? audioObj.file.tags.map((tag, index) => (
                          <Detailsheading name={'# ' + tag} />
                        ))
                      : null}
                  </HStack> */}
								</VStack>

								<Button
									onPress={() => {}}
									bg={'#1C1B1B'}
									p={0}
									py={1}
									px={2.5}
									w={'10%'}
									alignSelf={'center'}
									_hover={{ bg: '#1C1B1B' }}
									_focused={{ bg: '#1C1B1B' }}
									_pressed={{ bg: '#1C1B1B' }}
								>
									+
								</Button>
								<Button
									onPress={() => {}}
									bg={'#1C1B1B'}
									p={0}
									py={1}
									px={2.5}
									w={'10%'}
									alignSelf={'center'}
									_hover={{ bg: '#1C1B1B' }}
									_focused={{ bg: '#1C1B1B' }}
									_pressed={{ bg: '#1C1B1B' }}
								>
									+
								</Button>
							</HStack>
						</VStack>
						<HStack
							w={'58%'}
							backgroundColor={'#141313'}
							rounded='md'
							h={'30vh'}
							mt={5}
						>
							<VStack
								w={'60%'}
								justifyContent={'flex-start'}
								my={5}
								px={10}
								alignSelf={'center'}
							>
								<HStack w={'100%'} alignSelf={'center'}>
									<Checkbox
										value='userObj._id'
										alignItem={'center'}
										p={1}
										mx={2}
										my={1.5}
										colorScheme={'green'}
										bg={'#2ebb5520'}
										borderColor={'transparent'}
										_checked={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										_hover={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										// _focus={{ borderColor: '#afafaf' }}
										// _pressed={{ borderColor: '#afafaf' }}
										// _interactionBox={{ borderColor: '#afafaf' }}
										// _unchecked={{
										//   borderColor: '#2EBA55',
										//   bgColor: 'transparent',
										// }}
										// _text = {{fontSize: { lg: "16px", sm: "14px", base: "16px" }, color:"#afafaf", w:"60%", py:5, alignSelf:"center"}}
									></Checkbox>
									<Text
										fontSize={{
											lg: '16px',
											md: '14px',
											base: '12px',
										}}
										fontFamily={'Poppins'}
										fontWeight={'normal'}
										alignSelf={'center'}
										color='#afafaf'
										mx={2}
									>
										Disable Downloads
									</Text>
								</HStack>
								<HStack w={'100%'} alignSelf={'center'}>
									<Checkbox
										value='userObj._id'
										alignSelf={'center'}
										p={1}
										mx={2}
										my={1.5}
										colorScheme={'green'}
										bg={'#2ebb5520'}
										borderColor={'transparent'}
										_checked={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										_hover={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										// _checked={{
										//   borderColor: '#afafaf',
										//   bgColor: '#2EBA55',
										// }}
										// _focus={{ borderColor: '#afafaf' }}
										// _pressed={{ borderColor: '#afafaf' }}
										// _interactionBox={{ borderColor: '#afafaf' }}
										// _unchecked={{
										//   borderColor: '#2EBA55',
										//   bgColor: 'transparent',
										// }}
										// _text = {{fontSize: { lg: "16px", sm: "14px", base: "16px" }, color:"#afafaf", w:"60%", py:5, alignSelf:"center"}}
									></Checkbox>
									<Text
										fontSize={{
											lg: '16px',
											md: '14px',
											base: '12px',
										}}
										fontFamily={'Poppins'}
										fontWeight={'normal'}
										alignSelf={'center'}
										color='#afafaf'
										mx={2}
									>
										Enable Feedback
									</Text>
								</HStack>
								<HStack w={'100%'} alignSelf={'center'}>
									<Checkbox
										value='userObj._id'
										alignSelf={'center'}
										mx={2}
										my={1.5}
										p={1}
										colorScheme={'green'}
										bg={'#2ebb5520'}
										borderColor={'transparent'}
										_checked={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										_hover={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										// _checked={{
										//   borderColor: '#afafaf',
										//   bgColor: '#2EBA55',
										// }}
										// _focus={{ borderColor: '#afafaf' }}
										// _pressed={{ borderColor: '#afafaf' }}
										// _interactionBox={{ borderColor: '#afafaf' }}
										// _unchecked={{
										//   borderColor: '#2EBA55',
										//   bgColor: 'transparent',
										// }}
										// _text = {{fontSize: { lg: "16px", sm: "14px", base: "16px" }, color:"#afafaf", w:"60%", py:5, alignSelf:"center"}}
									></Checkbox>
									<Text
										fontSize={{
											lg: '16px',
											md: '14px',
											base: '12px',
										}}
										fontFamily={'Poppins'}
										fontWeight={'normal'}
										alignSelf={'center'}
										color='#afafaf'
										mx={2}
									>
										Notify me When Accessed
									</Text>
								</HStack>
								<HStack w={'100%'} alignSelf={'center'}>
									<Checkbox
										value='userObj._id'
										alignSelf={'center'}
										mx={2}
										my={1.5}
										p={1}
										colorScheme={'green'}
										bg={'#2ebb5520'}
										borderColor={'transparent'}
										_checked={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										_hover={{
											borderColor: 'transparent',
											bg: '#2EBA55',
										}}
										// _checked={{
										//   borderColor: '#afafaf',
										//   bgColor: '#2EBA55',
										// }}
										// _focus={{ borderColor: '#afafaf' }}
										// _pressed={{ borderColor: '#afafaf' }}
										// _interactionBox={{ borderColor: '#afafaf' }}
										// _unchecked={{
										//   borderColor: '#2EBA55',
										//   bgColor: 'transparent',
										// }}
										// _text = {{fontSize: { lg: "16px", sm: "14px", base: "16px" }, color:"#afafaf", w:"60%", py:5, alignSelf:"center"}}
									></Checkbox>
									<Text
										fontSize={{
											lg: '16px',
											md: '14px',
											base: '12px',
										}}
										fontFamily={'Poppins'}
										fontWeight={'normal'}
										alignSelf={'center'}
										color='#afafaf'
										mx={2}
									>
										Allow Artist to Write Lyrics
									</Text>
								</HStack>
							</VStack>
							<HStack
								w={'40%'}
								justifyContent={'space-between'}
								alignSelf={'self-end'}
								my={5}
								px={3}
							>
								<Button
									onPress={() => {
										// sendFeedback(savefileId);
										handleState();
									}}
									borderWidth={'1'}
									borderColor={'#2EBA55'}
									rounded={'md'}
									w={'45%'}
									py={1}
									bg='transparent'
									_text={{
										color: '#2EBA55',
										fontSize: { xl: '18px', base: 'sm' },
										fontFamily: 'Poppins',
										textAlign: 'center',
										textTransform: 'uppercase',
										fontWeight: 'medium',
									}}
									_hover={{ bg: '#2EBA55', _text: { color: '#e3e3e3' } }}
									_focused={{ bg: '#2EBA55' }}
									_pressed={{ bg: 'transparent' }}
									alignSelf={'center'}
								>
									Save
								</Button>
								<Button
									isDisabled={isDisable}
									bg={'#2EBB55'}
									borderWidth={1}
									borderColor={'#2EBB55'}
									_hover={{
										bg: 'transparent',
										_text: { color: '#2EBB55' },
									}}
									_pressed={{
										bg: '#2EBB55',
										_text: { color: '#e3e3e3' },
									}}
									_text={{
										color: '#e3e3e3',
										fontSize: { xl: '18px', base: 'sm' },
										fontFamily: 'Poppins',
										textAlign: 'center',
										textTransform: 'uppercase',
										fontWeight: 'medium',
									}}
									w={'45%'}
									py={1}
									alignSelf={'center'}
									onPress={() => {
										// sendFeedback(savefileId);
										copyBeatPack();
									}}
								>
									Copy Link
								</Button>
								{/* <Button 
               onPress={() => { 
                // sendFeedback(savefileId); 
                copyBeatPack();
               
              }}
              size="lg"
             
              mx={10}
              bg={"#2EBA55"} 
              rounded={"md"}
              _hover={{ bg:  "#1C1B1B"}}
              _focused={{ bg:  "#2EBA55"}}
              _pressed={{ bg:  "#1C1B1B"}}
              alignSelf={"center"}
              
              >
              <Text fontSize={{ lg: "24px", md: "16px", sm: "12px", base: "18px" }} color="white" >
               Copy Link
              </Text>
              </Button> */}
							</HStack>
						</HStack>
					</HStack>
				</Modal>

				<Modal
					isOpen={uploadModal}
					onClose={() => setUploadModal(false)}
					w={'60%'}
					size={'full'}
					alignSelf={'center'}
					justifyContent={'flex-start'}
					mt={20}
				>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						mx={5}
					/>

					<Modal.Body bg={'#141313'} mx={5} rounded={'md'}>
						<HStack>
							<VStack w={'23%'} justifyContent={'center'}>
								<img
									width={'100%'}
									alt={'cover'}
									height={'230px'}
									my={5}
									alignSelf={'center'}
									src={
										selectedImage == null
											? coverImg
											: URL.createObjectURL(selectedImage)
									}
								/>
								<HStack
									size='lg'
									w='100%'
									bg={'#2EBA55'}
									rounded={'md'}
									_hover={{ bg: '#1C1B1B' }}
									_focused={{ bg: '#2EBA55' }}
									_pressed={{ bg: '#1C1B1B' }}
									alignSelf={'center'}
									justifyContent={'center'}
									my={8}
									py={2}
									style={{
										cursor: 'pointer',
									}}
								>
									<Center>
										<label
											htmlFor='hh'
											style={{
												fontSize: '18px',
												fontFamily: 'Poppins',
												color: 'white',
												cursor: 'pointer',
											}}
										>
											Upload Cover
										</label>
										<input
											accept='image/*'
											type='file'
											id='hh'
											style={{ display: 'none', visibility: 'none' }}
											onChange={(val) => {
												setSelectedImage(val.target.files[0]);
											}}
										/>
									</Center>
								</HStack>
							</VStack>

							<VStack w={'75%'} mx={5}>
								<Box my={5}>
									<Text
										my={7}
										fontSize={{ lg: '2xl', md: 'xl', base: 'lg' }}
										fontFamily={'Poppins'}
										color='white'
									>
										{audioName}
									</Text>
									<Text
										my={3}
										fontSize={{ lg: 'xl', md: 'lg', base: 'md' }}
										fontFamily={'Poppins'}
										color='#e3e3e3'
									>
										Tags
									</Text>
									<HStack borderBottomWidth={1} borderBottomColor={'#afafaf'}>
										{tags &&
											tags.map((tag, index) => (
												<HStack
													bg={'transparent'}
													borderColor={'white'}
													rounded='sm'
													borderWidth={1}
													alignSelf={'center'}
													m={1}
													p={1}
													color={'#afafaf'}
												>
													{tag}
													<Button
														h={'0.5'}
														bg={'#1C1B1B'}
														alignSelf={'center'}
														onPress={() => {
															removeTag(tag);
														}}
														_hover={{ bg: '#1C1B1B' }}
														_focused={{ bg: '#1C1B1B' }}
														_pressed={{ bg: '#1C1B1B' }}
													>
														x
													</Button>
												</HStack>
											))}
										<Input
											my={2}
											variant='unstyled'
											placeholder='Add some tags (#drums, #new-album, #stem)'
											w={'60%'}
											onKeyPress={addTag}
											focusOutlineColor={'#afafaf'}
											color='white'
											fontSize={{ lg: '16px', md: '12px' }}
										/>
									</HStack>

									<HStack my={5} w={'100%'} justifyContent={'space-between'}>
										<VStack w={'30%'}>
											<Text
												my={3}
												fontSize={{ lg: 'xl', md: 'lg', base: 'md' }}
												fontFamily={'Poppins'}
												color='#e3e3e3'
											>
												BMP
											</Text>

											{/* <HStack bg={"transparent"} h={"10"} borderColor={"#afafaf"} my={2} marginRight={5} rounded="sm" borderWidth={1} >
             
            <input type="text" placeholder="120" onChange={handleBPM} onkeyp
             style={{ backgroundColor: "transparent", fontSize:"20px", color:"white", w:"80%"}}
              />
            </HStack> */}

											<Input
												my={2}
												marginRight={5}
												alignSelf={'flex-start'}
												_important={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												placeholder='120'
												w={'90%'}
												onChange={handleBPM}
												onKeyPress={handleBPMInput}
												focusOutlineColor={'#afafaf'}
												color='white'
												fontSize={{ lg: '16px', md: '12px' }}
											/>
										</VStack>

										<VStack w={'30%'}>
											<Text
												my={3}
												fontSize={{ lg: 'xl', md: 'lg', base: 'md' }}
												fontFamily={'Poppins'}
												color='#e3e3e3'
											>
												Key
											</Text>
											{/* <Input my={2} marginRight={5} alignSelf={"flex-start"}
            _important={{backgroundColor: "transparent", borderColor: "#afafaf"}}
            placeholder="Minor"  
            focusOutlineColor={"#afafaf"} color="white" fontSize={{  lg: "16px", md: "12px" }} /> */}
											<Select
												cursor='pointer'
												selectedValue={k}
												my={2}
												marginRight={5}
												_important={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												color='#afafaf'
												fontSize={{ lg: '16px', md: '12px' }}
												backgroundColor='transparent'
												borderColor='#afafaf'
												isHovered={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												_hover={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												isFocused={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												isFocusVisible={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												w={'100%'}
												accessibilityLabel='G'
												placeholder='Key'
												_selectedItem={{
													bg: '#afafaf',
													endIcon: <CheckIcon size='5' cursor='pointer' />,
												}}
												onValueChange={(itemValue) => setKey(itemValue)}
											>
												<Select.Item label='G' value='G' />
												<Select.Item label='C#Db' value='C#Db' />
												<Select.Item label='D' value='D' />
												<Select.Item label='D#Eb' value='D#Eb' />
												<Select.Item label='E' value='E' />
											</Select>
										</VStack>

										<VStack w={'30%'}>
											<Text
												my={3}
												fontSize={{ lg: 'xl', md: 'lg', base: 'md' }}
												fontFamily={'Poppins'}
												color='#e3e3e3'
											>
												Scale
											</Text>

											<Select
												cursor='pointer'
												selectedValue={scale}
												my={2}
												marginRight={5}
												_important={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												color='#afafaf'
												fontSize={{ lg: '16px', md: '12px' }}
												backgroundColor='transparent'
												borderColor='#afafaf'
												isHovered={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												isFocused={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												isFocusVisible={{
													backgroundColor: 'transparent',
													borderColor: '#afafaf',
												}}
												w={'100%'}
												accessibilityLabel='Tonic'
												placeholder='Scale'
												_selectedItem={{
													bg: '#afafaf',
													endIcon: <CheckIcon size='5' />,
												}}
												onValueChange={(itemValue) => setScale(itemValue)}
											>
												<Select.Item label='Tonic' value='Tonic' />
												<Select.Item label='Supertonic' value='Supertonic' />
												<Select.Item label='Mediant' value='Mediant' />
												<Select.Item label='Subdominant' value='Subdominant' />
												<Select.Item
													label='Leading note'
													value='Leading note'
												/>
											</Select>
										</VStack>
									</HStack>
								</Box>
							</VStack>
						</HStack>

						<Button
							size={'sm'}
							bg='#2EBA55'
							borderWidth={1}
							borderColor='#2EBA55'
							_text={{
								color: '#fff',
								fontSize: {
									lg: '18px',
									md: '16px',
									sm: '14px',
									base: '14px',
								},
							}}
							_hover={{
								bg: 'transparent',
								_text: {
									color: '#2EBA55',
								},
							}}
							w={'20%'}
							mb={5}
							rounded={'md'}
							alignSelf={'flex-end'}
							_focused={{ bg: '#2EBA55' }}
							_pressed={{ bg: '#2EBA55' }}
							onPress={() => {
								// sendFeedback(savefileId);
								uploadFile();
								setUploadModal(false);
								setCreatetModal(true);
							}}
						>
							Finish Editing
						</Button>
					</Modal.Body>
				</Modal>

				<Modal
					isOpen={shareModal}
					onClose={() => setShareModal(false)}
					w={'40%'}
					size={'full'}
					alignSelf={'center'}
					justifyContent={'flex-start'}
					mt={20}
				>
					<VStack w={'100%'}>
						<HStack
							rounded='md'
							w={'100%'}
							backgroundColor={'#141313'}
							h={'80'}
							my={2}
						>
							<Modal.CloseButton
								_hover={{ bg: '#1C1B1B' }}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: 'transparent' }}
							/>
							<VStack w={'100%'} justifyContent={'center'} my={5}>
								<HStack w={['70%', '70%', '80%']} alignSelf={'flex-start'}>
									<Text
										fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
										color='#afafaf'
										fontWeight={'semibold'}
										px={5}
										py={3}
									>
										Send Pack
									</Text>
								</HStack>
								<HStack
									w={['70%', '70%', '90%']}
									px='1'
									py='3'
									justifyContent={'center'}
									alignSelf={'center'}
								>
									<SearchFieldComponent
										backgroundColor={'transparent'}
										modal={true}
										placeholder='Search Packs'
										alignSelf={'center'}
									/>
								</HStack>
								<HStack
									w={['70%', '70%', '70%']}
									h={'3/4'}
									px='1'
									py='3'
									justifyContent={'center'}
									alignSelf={'center'}
								>
									<Text
										fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
										color='#afafaf'
										px={5}
										py={3}
									>
										No Collabrators Found
									</Text>
								</HStack>
							</VStack>
						</HStack>
						<HStack
							rounded='md'
							w={'100%'}
							backgroundColor={'#141313'}
							h={'40'}
							my={2}
						>
							<Box w={'100%'}>
								<Box>
									<Text
										fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
										color='#afafaf'
										fontWeight={'semibold'}
										px={5}
										py={3}
									>
										Get Link
									</Text>
									<Text
										fontSize={{ lg: '14px', md: '12px', sm: '12px' }}
										color='#afafaf'
										px={5}
										py={2}
									>
										Anyone with this link can open the pack
									</Text>
								</Box>

								<Box my={3} w={'90%'}>
									<Button
										bg={'#2EBB55'}
										borderWidth={1}
										borderColor={'#2EBB55'}
										_hover={{
											bg: 'transparent',
											_text: { color: '#2EBB55' },
										}}
										_pressed={{
											bg: '#2EBB55',
											_text: { color: '#e3e3e3' },
										}}
										_text={{
											color: '#e3e3e3',
											fontSize: { xl: '18px', base: 'lg' },
											fontFamily: 'Poppins',
											textAlign: 'center',
											textTransform: 'uppercase',
											fontWeight: 'medium',
										}}
										w={'30%'}
										py={2}
										alignSelf={'flex-end'}
										onPress={() => {
											copyBeatPack();
											setShareModal(false);
										}}
									>
										Copy Link
									</Button>
								</Box>
							</Box>
						</HStack>
					</VStack>
				</Modal>

				<Modal
					isOpen={deleteModal}
					onClose={() => setDeleteModal(false)}
					w={'40%'}
					size={'full'}
					alignSelf={'center'}
					justifyContent={'flex-start'}
					mt={50}
				>
					<Modal.Content backgroundColor={'transparent'}>
						<Modal.Body backgroundColor={'transparent'} my={2} mx={2}>
							<VStack px={5}>
								<HStack
									rounded='md'
									backgroundColor={'#1C1B1B'}
									// h={'40'}
									paddingBottom={10}
								>
									<Modal.CloseButton
										_hover={{ bg: '#1C1B1B' }}
										_focused={{ bg: '#2EBA55' }}
										_pressed={{ bg: 'transparent' }}
									/>
									<Box w={'100%'}>
										<HStack w={'100%'}>
											<VStack my={4}>
												<Text
													fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
													color='#afafaf'
													fontWeight={'semibold'}
													px={5}
													py={10}
												>
													Are you sure you want to delete the pack?
												</Text>
											</VStack>
										</HStack>
										<HStack justifyContent={'center'}>
											<Button.Group
												justifyContent={'space-between'}
												mx={5}
												width={'30%'}
											>
												<Button
													size={'sm'}
													bg='#1c1b1b'
													borderStyle={'solid'}
													borderWidth={'1'}
													borderColor={'#2EBA55'}
													w={'40%'}
													rounded={'md'}
													alignSelf={'center'}
													_text={{
														color: '#2EBA55',
														fontSize: {
															lg: '18px',
															md: '16px',
															sm: '14px',
															base: '14px',
														},
													}}
													_hover={{
														bg: '#2EBA55',
														_text: {
															color: '#fff',
														},
													}}
													_focused={{ bg: '#2EBA55' }}
													_pressed={{ bg: 'transparent' }}
													onPress={() => {
														setDeleteModal(false);
													}}
												>
													No
												</Button>

												<Button
													size={'sm'}
													bg='#2EBA55'
													borderWidth={1}
													borderColor='#2EBA55'
													_text={{
														color: '#fff',
														fontSize: {
															lg: '18px',
															md: '16px',
															sm: '14px',
															base: '14px',
														},
													}}
													_hover={{
														bg: 'transparent',
														_text: {
															color: '#2EBA55',
														},
													}}
													onPress={() => {
														deleteBeatPack(deleteBP);
														setDeleteModal(false);
													}}
													w={'40%'}
													rounded={'md'}
													alignSelf={'center'}
													_focused={{ bg: '#2EBA55', color: 'white' }}
													_pressed={{ bg: 'transparent' }}
												>
													Yes
												</Button>
											</Button.Group>
										</HStack>
									</Box>
								</HStack>
							</VStack>
						</Modal.Body>
					</Modal.Content>
				</Modal>
			</>
		);
};
export default BeatPack;
