import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Box, Heading, HStack, Divider, Button } from 'native-base';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { Loader } from '../../../components/Loader';
import styles from './styles.module.css';
import { ArrowDropDownCircle } from '@mui/icons-material';

const ArtistSongs = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [songs, setSongs] = useState([]);
	const [sortName, setSortName] = useState(false);
	const [sortDate, setSortDate] = useState(false);
	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};
	const getSongData = async (uuid, name, releaseDate, artist) => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.8/song/${uuid}`
			);
			const response = await axios(config);

			const { label, isrc } = response.data.object;
			return { label, releaseDate, isrc, uuid, name, artist };
		} catch (error) {
			console.log('error fetching song data', error);
		}
	};
	const enableNameSorting = () => {
		setSortName(!sortName);
		getArtistSong('name');
	};
	const enableDateSorting = () => {
		setSortDate(!sortDate);
		getArtistSong('releaseDate');
	};

	const getArtistSong = async (type) => {
		try {
			let sortType = 'asc';
			if (type === 'name') {
				if (!sortName) {
					sortType = 'desc';
				}
			}
			if (type === 'releaseDate') {
				if (!sortDate) {
					sortType = 'desc';
				}
			}
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.21/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/songs?offset=0&limit=100&sortBy=${type}&sortOrder=${sortType}`
			);
			const response = await axios(config);
			const { items } = response.data;

			const songData = await Promise.allSettled(
				items.map(async (item) => {
					let uuid = item.uuid;
					let name = item.name;
					return await getSongData(
						'7d534228-5165-11e9-9375-549f35161576',
						name,
						item.releaseDate,
						item.creditName
					);
				})
			);
			setSongs(songData.map((item) => item.value));
			setIsLoading(false);
		} catch (err) {
			setIsLoading(true);
			console.log('error', err);
		}
	};
	useEffect(() => {
		getArtistSong('name');
	}, []);

	return (
		<Box bg={'#000'} w={'100%'}>
			<HStack w={'100%'} py={3}>
				<Heading
					w={'23%'}
					textTransform={'uppercase'}
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					textAlign={'left'}
					display={'flex'}
				>
					<div style={{ 'align-self': 'center' }}>Song</div>
					<Button
						variant={'ghost'}
						onPress={() => {
							enableNameSorting('name');
						}}
					>
						{sortName ? (
							<ArrowDropDown sx={{ color: '#f00' }} />
						) : (
							<ArrowDropUp sx={{ color: '#f00' }} />
						)}
					</Button>
				</Heading>
				<Heading
					w={'23%'}
					textTransform={'uppercase'}
					color={'#AFAFAF'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					textAlign={'center'}
					alignSelf={'center'}
				>
					{'Artist'}
				</Heading>
				<Heading
					w={'23%'}
					color={'#AFAFAF'}
					textTransform={'uppercase'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					textAlign={'center'}
					alignSelf={'center'}
				>
					{'ISCR'}
				</Heading>
				<Heading
					w={'23%'}
					pr={2}
					color={'#AFAFAF'}
					textTransform={'uppercase'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					alignSelf={'center'}
					textAlign={'center'}
				>
					{'Label'}
				</Heading>
				<Heading
					w={'23%'}
					pr={2}
					color={'#AFAFAF'}
					textTransform={'uppercase'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }}
					fontWeight={'normal'}
					textAlign={'right'}
					display={'flex'}
				>
					<div style={{ 'align-self': 'center' }}>Release Date</div>

					<Button
						variant={'ghost'}
						onPress={() => {
							enableDateSorting('releaseDate');
						}}
					>
						{sortDate ? (
							<ArrowDropDown sx={{ color: '#f00' }} />
						) : (
							<ArrowDropUp sx={{ color: '#f00' }} />
						)}
					</Button>
				</Heading>
			</HStack>
			<Divider my={1} />
			{isLoading ? (
				<div className={styles.loaderDiv}>
					<Loader />
				</div>
			) : (
				songs?.map((item) => (
					<>
						<HStack w={'100%'} py={3}>
							<Heading
								w={'23%'}
								textTransform={'uppercase'}
								color={'#AFAFAF'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'left'}
							>
								{item?.name}
							</Heading>
							<Heading
								w={'23%'}
								textTransform={'uppercase'}
								color={'#AFAFAF'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'center'}
							>
								{item?.artist}
							</Heading>
							<Heading
								w={'23%'}
								color={'#AFAFAF'}
								textTransform={'uppercase'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'center'}
							>
								{item?.isrc?.value}
							</Heading>
							<Heading
								w={'23%'}
								pr={2}
								color={'#AFAFAF'}
								textTransform={'uppercase'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'right'}
							>
								{item?.label}
							</Heading>
							<Heading
								w={'23%'}
								pr={2}
								color={'#AFAFAF'}
								textTransform={'uppercase'}
								fontFamily={'Poppins'}
								fontSize={{
									lg: '1rem',
									md: '0.8rem',
									sm: '0.7rem',
									base: '0.6rem',
								}}
								fontWeight={'normal'}
								textAlign={'right'}
							>
								{item.releaseDate
									? moment(item?.releaseDate).format('DD MMMM YYYY')
									: ''}
							</Heading>
						</HStack>
						<Divider my={1} />
					</>
				))
			)}
		</Box>
	);
};
export default ArtistSongs;
