import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const configOptions = (method, url) => {
    return {
        method: method,
        url: url,
        headers: {
            'x-api-key': 'soundcharts',
            'x-app-id': 'soundcharts',
        },
    };
};
function DaysFilter(props) {
    
  const {value, handler} = props
const handleChange = (event) => {
  // console.log('selected value : ', event.target.value)
  // setAge(event.target.value);
  handler(event.target.value)
};


  return (
    <ThemeProvider theme={darkTheme}>
      <FormControl sx={{ m: [0,0,1], mainWidth: [20, 120, 240] }} className='country_filter'>
    <Select
      labelId="demo-simple-select-helper-label"
      value={value}
      displayEmpty
      onChange={handleChange}
      style={{color:'#AFAFAF', border: '#AFAFAF'}}
      MenuProps={{ PaperProps: { sx: { maxHeight: '300px', backgroundColor: '#1c1b1b',             color: "afafaf"
    } } }}
    >
      <MenuItem sx={{  
        "&:focus": {
          background: "#2EBB55",
          color: "white"
          },
       "&:hover": {
            background: "#AFAFAF",
            color: "afafaf"
            }
        
        }} value="7">Last 7 days</MenuItem>
        <MenuItem sx={{  
        "&:focus": {
          background: "#2EBB55",
          color: "white"
          },
       "&:hover": {
            background: "#AFAFAF",
            color: "afafaf"
            }
        
        }} value="28">Last 28 days</MenuItem>
        <MenuItem sx={{  
        "&:focus": {
          background: "#2EBB55",
          color: "white"
          },
       "&:hover": {
            background: "#AFAFAF",
            color: "white"
            }
        
        }}  value="60">Last 60 days</MenuItem>
    </Select>
  </FormControl>
    </ThemeProvider>
  )
}

export default DaysFilter