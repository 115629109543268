import React from 'react';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
export default function Notification() {
	const _id = localStorage.getItem('userId');
	console.log(process.env.REACT_APP_SOCKET_URL);
	const socket =
		_id &&
		io.connect(process.env.REACT_APP_SOCKET_URL, {
			path: '/chatt',
			query: {
				userId: _id,
			},
		});
	React.useEffect(() => {
		socket?.on('recieveLiveNotifcation', (data) => {
			console.log('in if data', data);
			toast.success(data.notification.message);
			console.log('socket io data', data);
		});
		// }
	}, [socket]);
	return true;
}
