import { Box, Divider, Heading, HStack, Image } from 'native-base';
import React from 'react';
import HomeBody from './HomeBody';
import { Sidebar } from '../../layout/Sidebar/Sidebar.js';
const BarHome = (props) => {
	console.log('in bar home', props);
	return (
		<Box w={'100%'} bg={'#000201'}>
			<HStack w={'100%'}>
				<Sidebar childComponent={<HomeBody />} />
				{/* <Box
					display={{ lg: 'block', base: 'none' }}
					w={{ xl: '16%', lg: '17%', base: '0%' }}
				>
					<SideBar />
				</Box>

				<Box
					w={{ xl: '84%', lg: '83%', base: '100%' }}
					borderLeftWidth={{ lg: 1, md: 0 }}
					borderColor={'#fff'}
				>
					<DashboardHeader />
					<Divider />
					<HomeBody />
				</Box> */}
			</HStack>
		</Box>
	);
};

export default BarHome;
