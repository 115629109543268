import React, { useState } from 'react';
import {
	Box,
	Image,
	Heading,
	HStack,
	VStack,
	Button,
	Divider,
	useBreakpointValue,
} from 'native-base';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TalentcomponentHeader } from '../../TalentComponentHeader/TalentComponentHeader';
import SeeMore from '../../SeeMoreButton/SeeMore';

// import { TableHeaderCol4 } from '../TalentComponentHeader/TableHeaderCol4';
import { TableHeaderCol2 } from '../../TalentComponentHeader/TableHeaderCol2';
import { TableContentCol2 } from '../../TableContent/TableContentCol2';
import WorldMapImage from '../../../../assets/TiktokComponents/worldMap.png';
// import SeeMore from "../../SeeMoreButton/SeeMore";
export const StatsWithMap = (props) => {
	// const { artist, data } = props;
	const [seeMore, SetSeeMore] = useState(false);
	const { data, count } = props;

	return (
		<Box w={'95%'} alignSelf={'center'}>
			<TalentcomponentHeader
				image={''}
				title={`${count.plays.toLocaleString()} plays from ${
					count.countries
				} countries`}
				subtitle={'Top countries by radio plays in the last 28 days'}
			/>

			<HStack flexDirection={{ xl: 'row', base: 'column' }}>
				<Box w={{ xl: '50%', base: '100%' }}>
					<TableHeaderCol2
						isHeader={true}
						title1={'country'}
						title2={'Plays'}
					/>
					{data?.length > 0 &&
						(!seeMore ? data.slice(0, 10) : data).map((item) => (
							<Box
							// flex={1}
							>
								<TableHeaderCol2
									isHeader={false}
									title1={item.country_name}
									title2={item.plays}
								/>
							</Box>
						))}
					<SeeMore seeMore={seeMore} SetSeeMore={SetSeeMore} />
				</Box>
				<Box
					//  w={"50%"}
					w={{ xl: '50%', base: '100%' }}
				>
					<Image
						source={WorldMapImage}
						w={'100%'}
						h={'350px'}
						resizeMode={'contain'}
						mt='0.5'
					/>
				</Box>
			</HStack>
			{/* <Button
				mt={8}
				p={0}
				variant={'ghost'}
				_hover={"bg: 'transparent"}
				alignSelf={'flex-start'}
				_icon={<ArrowDropDownIcon />}
				onPress={() => setSeeMore(!seeMore)}
			>
				<HStack>
					<Heading
						color={'#E3E3E3'}
						textTransform={'capitalize'}
						fontFamily={'Poppins'}
						fontSize={'md'}
						fontWeight={'normal'}
						textAlign={'left'}
					>
						{seeMore ? 'See Less' : 'See More'}
					</Heading>
					<ArrowDropDownIcon sx={{ color: '#E3E3E3' }} />
				</HStack>
			</Button> */}
		</Box>
	);
};
