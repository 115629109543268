import {
  Box,
  Button,
  CheckIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Select,
} from 'native-base';
import React, { useState } from 'react';
import styles from './styles.module.css';

const BannerHome = (props) => {
  return (
    <Box w={'100%'} bg={'#000201'}>
      <Box bg={'#000'}>
        <div className={styles.bannerImg}>
          <Image
            position={'absolute'}
            // zIndex={10}
            opacity={0.5}
            w={'100%'}
            src={require('../../../assets/BarCommingSoon/homeBanner.png')}
          />
        </div>
        <Box
          w={{ lg: '100%', md: '100%', base: '100%' }}
          // bg={"#ffffff40"}
          zIndex={20}
          // mt={{ lg: '40', md: '24', base: '10' }}
          // mb={{ lg: '40', md: '10', base: '10' }}
          py={10}
          alignSelf={'center'}
          rounded={'xl'}
          alignItems={'center'}
        >
          <div className={styles.heading}>
            <Heading
              fontSize={{ xl: '80px', lg: '70px', base: '40px' }}
              fontFamily={'Clash Display'}
              fontWeight={'500'}
              color={'#ffffff'}
              lineHeight={{ xl: '98px', lg: '82px', base: '46px' }}
            >
              Welcome to
            </Heading>
            <br />
            <Heading
              textTransform={'uppercase'}
              color={'#2EBB55'}
              fontFamily={'Clash Display'}
              fontSize={{ xl: '80px', lg: '70px', base: '40px' }}
              fontWeight={'500'}
              lineHeight={{ xl: '98px', lg: '82px', base: '46px' }}
            >
              United Market
            </Heading>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerHome;
