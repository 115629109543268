import React from 'react';
import { Heading, Box, Text, VStack } from 'native-base';
function FormTitle(props) {
	const verticalSpace = props.mb ? 8 : props.subtitle === 'I’m a(n):' ? 2 : 1;
	return (
		<Box mb={{ lg: 10, base: 5 }} textAlign={props.textCenter ? 'center' : ''}>
			<VStack space={verticalSpace}>
				<Heading
					fontFamily='poppins'
					fontSize={{ lg: '40px', md: '2xl', sm: 'lg', base: '1.1rem' }}
					fontWeight='500'
					textTransform={
						props.title === 'Welcome to United Market' ? '' : 'capitalize'
					}
					textAlign={{ md: 'center', base: 'left' }}
					color={'#fff'}
					// mb={"20px"}
					// mb={{ xl: 2, lg: 2, base: 2 }}
					// mt={{ xl:  }}
				>
					{props.title}
				</Heading>
				{(props.isMobile || !props.isMobile) && (
					<Text
						textAlign={{
							md: props.subtitle === 'I’m a(n):' ? 'left' : 'center',
							base: 'left',
						}}
						fontFamily='poppins'
						color={props.subtitle === 'I’m a(n):' ? '#E3E3E3' : '#AFAFAF'}
						fontSize={{
							lg: props.subtitle === 'I’m a(n):' ? '24px' : '18px',
							md: 'lg',
							sm: 'md',
							base: '1rem',
						}}
						pt={props.subtitle === 'I’m a(n):' ? 25 : 0}
						fontWeight='normal'
					>
						{props.subtitle}
					</Text>
				)}
			</VStack>
		</Box>
	);
}

export default FormTitle;
