import React, { createContext } from "react";

import { Box, Divider, Heading, Image } from "native-base";
import { ProfileHeaderIcon } from "../ProfileHeader/ProfileHeaderIcon";
import { ProfileForm } from "../ProfileForm/ProfileForm";

export const AddUserContext = createContext();
const AddUsers = () => {
  // const [userName, setUserName] = useState("");
  const [service, setService] = React.useState("");
  return (
    <AddUserContext.Provider value={{}}>
      <Box py={8} px={7}>
        {/* <Divider /> */}
        <ProfileHeaderIcon />

        <Box mb={"100px"} position={"relative"}>
          <Box
            bg={"#1C1B1B"}
            mt={5}
            justifyContent={"center"}
            h={{ lg: "300px", md: "200px", base: "200px" }}
            w={"100%"}
            rounded={"lg"}
          >
            <Heading
              alignSelf={"center"}
              py={2}
              px={3}
              rounded={"md"}
              borderWidth={1}
              borderStyle={"dashed"}
              borderColor={"#AFAFAF"}
              color={"#AFAFAF"}
              textAlign={"center"}
              fontWeight={"medium"}
              fontSize={{ md: "2xl", sm: "xl", base: "lg" }}
            >
              + Add Cover Photo
            </Heading>
            <Image
              position={"absolute"}
              zIndex={99999}
              bottom={{ sm: "-70px", base: "-50px" }}
              left={{ sm: "20px", base: "20px" }}
              size={{ md: "170px", sm: "140px", base: "100px" }}
              resizeMode="cover"
              src={require("..//..//..//assets//Profile//userProfile.png")}
            />
          </Box>
        </Box>

        <ProfileForm />
      </Box>
    </AddUserContext.Provider>
  );
};

export default AddUsers;
