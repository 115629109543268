import React from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
} from "native-base";
import Rating from "@mui/material/Rating";
// import profile from "../../assets/header/profile.png";
import "./Profileview.css"
import SocialIconTray from "./SocialIconTray";

export const ProfileView = () => {
  return (
    
    <Box  w={{ lg: '30%', base: "100%" }} zIndex={10}  top={-50}>
    

      <Box flex={1}>
     
        <Box
   
      
          w={"100%"}
        
          zIndex={8}

          height={"100%"}
        >
      
          
            <Image
              alignSelf={"center"}
              mt={{xl:"-250px",lg:"-220px",md:"-120px",base:"-70px"}}
              h={{xl:"300px",lg:"250px",md:"200px",base:"150px"}}
              width={{xl:"300px",lg:"250px",md:"200px",base:"150px"}}
              resizeMode="cover"
              source={require("../../../assets/Profile/userProfile.png")}
              alt="image"
            />
          </Box>
          <VStack
            flex={1}

    // height={"100%"}
            bg={"#1C1B1B"}
            px={{sm:5,base:4}}
            top={{lg:-100,md:-20}}
            rounded={"lg"}
            position={"absolute"}
            space={5}
            mb={{lg:5}}
            // height={"100%"}
            
          >
            <Box   mt={"130px"}>
              <HStack  justifyContent={"center"}  alignItems={"center"} space={5} >  
              <div className="profileviewtext">
              <Heading
                color={"#E3E3E3"}
                fontWeight={"medium"}
                fontSize={{xl:"3xl",lg:"2xl",md:"4xl",base:"xl"}}
                // textAlign={"center"}
                py={2}
                fontFamily={"Poppins"}
              >
        Mike Will Made-It
              </Heading>
</div> 
             
              <Image size={{sm:30,base:"20px"}} resizeMode="contain" src={require("../../../assets/Profile/tick-circle.png")} />
              </HStack>
       
              <Heading
               fontFamily={"Poppins"}
                color={"#AFAFAF"}
                fontWeight={"normal"}
                fontSize={{xl:"2xl",lg:"xl",md:"3xl",base:"lg"}}
                textAlign={"center"}
                py={2}
              >
                Music Producer
              </Heading>
            </Box>
            <Box mb={1}>
              <Heading
                color={"#E3E3E3"}
                fontWeight={"medium"}
                fontSize={{lg:"2xl",md:"2xl",base:"lg"}}
                textAlign={"left"}
                py={2}
                fontFamily={"Poppins"}
              >
                About Me
              </Heading>
              <Heading
                color={"#AFAFAF"}
                fontWeight={"normal"}
                fontSize={{lg:"md",md:"lg",base:"sm"}}
                textAlign={"justify"}
                fontFamily={"Poppins"}
              >
                Scott Samuel "Scooter" Braun (born June 18, 1981) is an American
                talent manager and record executive. Known as the manager for
                artists such as Ariana Grande, Justin Bieber, J Balvin, Demi
                Lovato, and other artists, Jazz, Dubstep, Rhythm and Blues,
                Techno, Country Music, Pop Music
              </Heading>
            </Box>
            <Box mb={1}>
              <Heading
                color={"#E3E3E3"}
                fontWeight={"medium"}
                  fontSize={{lg:"2xl",md:"2xl",base:"lg"}}
                textAlign={"left"}
                py={2}

                fontFamily={"Poppins"}
              >
                Credits
              </Heading>
              <Heading
                color={"#AFAFAF"}
                fontWeight={"normal"}
           fontSize={{lg:"md",md:"lg",base:"sm"}}
                textAlign={"justify"}
                fontFamily={"Poppins"}
              >
                Justin Bieber, Jalvin B
              </Heading>
            </Box>
            <Box mb={1}>
              <Heading
                color={"#E3E3E3"}
                fontWeight={"medium"}
                  fontSize={{lg:"2xl",md:"2xl",base:"lg"}}
                textAlign={"left"}
                py={2}
                fontFamily={"Poppins"}
              >
                Accolades
              </Heading>
              <Heading
                color={"#AFAFAF"}
                fontWeight={"normal"}
           fontSize={{lg:"md",md:"lg",base:"sm"}}
                fontFamily={"Poppins"}
                textAlign={"justify"}
              >
                He is the founder of Schoolboy Records and Ithaca Ventures,
                co-founder
              </Heading>
            </Box>
            <Box mb={1}>
              <Heading
                color={"#E3E3E3"}
                fontWeight={"medium"}
                  fontSize={{lg:"2xl",md:"2xl",base:"lg"}}
                textAlign={"left"}
                py={2}
                fontFamily={"Poppins"}
              >
                Beat Specialties
              </Heading>
              <Heading
                color={"#2EBB55"}
                fontWeight={"normal"}
           fontSize={{lg:"md",md:"lg",base:"sm"}}
                textAlign={"justify"}
                fontFamily={"Poppins"}
              >
                Techno beats, Guitar Beats
              </Heading>
            </Box>
            <Box mb={1}>
              <Heading
                color={"#E3E3E3"}
                fontWeight={"medium"}
                  fontSize={{lg:"2xl",md:"2xl",base:"lg"}}
                textAlign={"left"}
                py={2}
                fontFamily={"Poppins"}
              >
                Loop Specialties
              </Heading>
              <Heading
                color={"#2EBB55"}
                fontWeight={"normal"}
           fontSize={{lg:"md",md:"lg",base:"sm"}}
                textAlign={"justify"}
                fontFamily={"Poppins"}
              >
                Piano Loops, Guitar Loops
              </Heading>
            </Box>
            <Box mb={1}>
              <Heading
                color={"#E3E3E3"}
                fontWeight={"medium"}
                  fontSize={{lg:"2xl",md:"2xl",base:"lg"}}
                textAlign={"left"}
                py={2}
                fontFamily={"Poppins"}
              >
                Social Networks
              </Heading>
              <SocialIconTray />
            </Box>
            {/* <InstagramIcon  sx={{ color: '#fff' }}  sx={{ fontSize: 40 }}/>
             <Icon> InstagramIcon</Icon>
             <Icon  as={FontAwesome} name='instagram' color={'#fff'} size={20}/> */}
          </VStack>
        </Box>
</Box>
   
  );
};
