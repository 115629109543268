/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { fontSize } from '@mui/system';
import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Menu,
	Text,
} from 'native-base';

import React, { useState } from 'react';
import { DashboardHeader } from '../Headers';
import SideBar from '../SideBar/SideBar';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import slider from '../../assets/Notification/slider.png';
import AffiliateLink from './AffiliateLink';
import SuggestedUsers from './SuggestedUsers';
import MyReferrals from './MyReferrals';

function GrowthMain() {
	const [toggleState, setToggleState] = useState(1);

	return (
		// <Box w={'100%'} bg={'#000201'}>
		//   <HStack w={'100%'}>

		//     <Box
		//       borderLeftWidth={{ lg: 1, md: 0 }}
		//       borderColor={'#fff'}
		//       w={{ xl: '83%', lg: '83%', base: '100%' }}
		//     >
		<Box w={'100%'}>
			<Box w={'90%'} alignSelf={'center'} py={5}>
				<Heading
					my={5}
					fontSize={{ xl: '40px', lg: '36px', md: '30px', base: '20px' }}
					color={'#fff'}
					textTransform={'capitalize'}
					fontFamily={'poppins'}
					fontWeight={'medium'}
				>
					Growth
				</Heading>
				<HStack
					w={'100%'}
					borderBottomStyle={'line'}
					borderBottomWidth={1}
					borderColor={'#fff'}
				>
					<Tabbtn name={'Affiliate Link'} value={1} />
					<Tabbtn name={'MY REFERRALS'} value={2} />
					<Tabbtn name={'SUGGESTED USERS'} value={3} />
				</HStack>
				<Box my={5} w={'100%'}>
					{toggleState === 1 && <AffiliateLink />}
					{toggleState === 2 && <MyReferrals />}
					{toggleState === 3 && <SuggestedUsers />}
				</Box>
			</Box>
		</Box>
		//     </Box>
		//   </HStack>
		// </Box>
	);

	function Tabbtn(props) {
		const { name, value } = props;
		return (
			<Button
				size={'sm'}
				bg='#000'
				mt={4}
				px={{ xl: 6, lg: 4, base: 4 }}
				// borderWidth={1}
				// borderColor={'#fff'}
				_hover={{ bg: 'transparent' }}
				_pressed={{
					bg: 'transparent',
					borderBottomStyle: 'line',
					borderBottomWidth: '4',
					borderColor: '#2EBA55',
				}}
				borderBottomStyle={'line'}
				borderBottomWidth={4}
				borderColor={toggleState == value ? '#2EBA55' : '#000'}
				onPress={() => setToggleState(value)}
			>
				<Text
					fontSize={{ xl: '18px', lg: '18px', base: '12px' }}
					fontFamily={'Poppins'}
					color={'#AFAFAF'}
					alignSelf={'center'}
				>
					{name}
				</Text>
			</Button>
		);
	}
}

export default GrowthMain;
