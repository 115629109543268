import {
	VStack,
	Text,
	Image,
	HStack,
	Input,
	Divider,
	Button,
	Heading,
	Box,
	TextField,
	TextArea,
	FormControl,
} from 'native-base';
import React, { useEffect, useRef } from 'react';
import { createContext } from 'react';
import { AiFillPauseCircle, AiFillPlayCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import Waveform from './Waveform';
import WaveSurfer from 'wavesurfer.js';

import { useState, useCallback } from 'react';
import { Modal } from 'native-base';
import { useDropzone } from 'react-dropzone';
import { GoStar } from 'react-icons/go';
import { BsChatDots } from 'react-icons/bs';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Rating from '@mui/material/Rating';
import moment from 'moment';
import AudioFeedback from './Feedback';
import { ToastContainer, toast } from 'react-toastify';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

// export const AuthContext = createContext();

const AudioRecived = (props) => {
	const [audioReceive, setAudioReceive] = useState([]);
	const [viewFeedbacks, setViewFeedbacks] = useState([]);
	const [showBasicFeedback, setBasicFeedback] = useState(false);
	const [showHighFeedback, setHighFeedback] = useState(false);
	const [feedbackText, setFeedbackText] = useState(null);
	const [starRating, setRating] = useState(null);
	const [showAudioPlayer, setAudioPLayer] = useState(false);
	const [savefileId, setFileId] = useState('');
	const [audioURL, setAudioUrl] = useState('');
	const [fileObj, setFileObj] = useState({});
	const [showComments, setShowComments] = useState(false);
	const [playing, setPlay] = useState(false);
	const wavesurfer = useRef(null);
	const [errorMsg, setErrorMsg] = useState('');

	const navigate = useNavigate();

	const getFeedbackText = (val) => {
		setFeedbackText(val.target.value);
	};

	// <AuthContext.Provider value={fileObj}>
	// 	<AudioFeedback />
	// 	<AudioPlayer />
	// </AuthContext.Provider>;

	const headerkey = `Bearer ${localStorage.getItem('token')}`;

	const fetchRecived = () => {
		var fileHeader = new Headers();
		fileHeader.append('Authorization', `${headerkey}`);
		var requestFiles = {
			method: 'GET',
			headers: fileHeader,
			redirect: 'follow',
		};
		return fetch(
			`${process.env.REACT_APP_API_URL}/file/getrecievedfiles`,
			requestFiles
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.auth == false) {
					navigate('/signin');
					localStorage.clear();
				} else {
					setAudioReceive(data.files);
				}
			})
			.catch((error) => console.log('error', error));
	};

	const fetchFeedback = (fileId) => {
		var feedbackHeader = new Headers();
		feedbackHeader.append('Authorization', `${props.header}`);

		var requestFeedback = {
			method: 'GET',
			headers: feedbackHeader,
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getfeedbacks/${fileId}`,
			requestFeedback
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.auth == false) {
					navigate('/signin');
					localStorage.clear();
				} else {
					setViewFeedbacks(data.feedbacks);
				}
			})
			.catch((error) => console.log('error', error));
	};

	const sendFeedback = (fileId) => {
		if (!feedbackText || feedbackText?.trim()?.length < 1) {
			setErrorMsg('Feedback message is required');
		} else {
			var feedbackPostHeader = new Headers();
			feedbackPostHeader.append('Authorization', `${props.header}`);
			feedbackPostHeader.append('Content-Type', 'application/json');

			var raw = JSON.stringify({
				review: feedbackText,
				stars: starRating,
				startDuration: '00:00',
				endDuration: '00:29',
			});

			var postFeedback = {
				method: 'POST',
				headers: feedbackPostHeader,
				body: raw,
				redirect: 'follow',
			};

			fetch(
				`${process.env.REACT_APP_API_URL}/file/createfeedback/${fileId}`,
				postFeedback
			)
				.then((response) => response.text())
				.then((result) => {
					console.log(result);
					toast.success('Feedback submitted successfully');
				})
				.catch((error) => {
					console.log('error', error);
					toast.error('Something went worng. Feedback couldnt be submitted');
				});

			setBasicFeedback(false);
			setFeedbackText('');
		}
	};
	function TabHeader(props) {
		const { w, title } = props;
		return (
			<Box alignSelf={'flex-start'} w={w} py={'2'}>
				<Text
					fontSize={{ lg: 'lg', md: 'sm', sm: 'sm' }}
					color={'#AFAFAF'}
					alignSelf={'flex-start'}
					fontFamily={'Poppins'}
					px={'3'}
				>
					{title}
				</Text>
			</Box>
		);
	}

	const handleComment = () => {
		setShowComments(true);
	};

	const handleTimeInput = (val) => {
		var ch = String.fromCharCode(val.which);

		if (!(/[0-9]/.test(ch) || val.which === 8)) {
			val.preventDefault();
			toast.error('Invalid Input');
		}

		if (val.target.value > 9) {
			val.preventDefault();
			toast.error('Invalid Time Range');
			val.target.value = '';
		}
	};

	useEffect(() => {
		fetchRecived();
	}, []);

	return (
		<>
			{showComments ? (
				<AudioFeedback audioObj={fileObj} />
			) : (
				<VStack
					justifyContent={'center'}
					alignSelf={'center'}
					w={['100%', '95%', '95%']}
				>
					<ToastContainer />
					<HStack alignSelf={'center'} w={'100%'}>
						<TabHeader w={'35%'} title={'Users'} />
						<TabHeader w={'15%'} title={'Date'} />
						<TabHeader w={'15%'} title={'Beats'} />
						<TabHeader w={'20%'} title={'Loops'} />
					</HStack>
					<Divider w={'100%'} alignSelf={'center'} h='1'></Divider>
					<VStack>
						{audioReceive &&
							audioReceive.map((audioAllObj, index) => (
								<HStack key={index} w={'100%'} alignSelf={'center'} py={'5'}>
									<HStack w={'30%'} justifyContent={'space-around'}>
										{audioAllObj.file.audioIconUrl ? (
											<Image
												size={120}
												borderRadius={100}
												height={{ lg: '80px', sm: '50px', base: '30px' }}
												width={{ lg: '80px', sm: '50px', base: '30px' }}
												alignSelf={'center'}
												source={audioAllObj.file.audioIconUrl}
												//  size={["8", "10", "12"]}
												mt='0.5'
												alt='User Profile Pic'
											/>
										) : (
											<Image
												size={120}
												borderRadius={100}
												height={{ lg: '80px', sm: '50px', base: '30px' }}
												width={{ lg: '80px', sm: '50px', base: '30px' }}
												alignSelf={'center'}
												source={require('../../assets/Profile/profile.png')}
												//  size={["8", "10", "12"]}
												mt='0.5'
												alt='User Profile Pic'
											/>
										)}

										<Text
											w={'60%'}
											fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
											color={'#AFAFAF'}
											fontFamily={'Poppins'}
											justifyContent={'center'}
											alignSelf={'center'}
										>
											{audioAllObj.userInfo
												? audioAllObj.userInfo.firstname
												: 'No Name'}
										</Text>
									</HStack>
									<HStack w={'35%'} justifyContent={'space-evenly'}>
										<Text
											fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
											color={'#AFAFAF'}
											fontFamily={'Poppins'}
											alignSelf={'center'}
										>
											{/* {audioAllObj.file.createdAt.moment.format('MM/DD/YYYY')} */}
											{moment(audioAllObj.file.createdAt).format(
												'DD MMMM YYYY'
											)}
										</Text>

										<Button
											size={'sm'}
											bg='#000'
											borderStyle={'solid'}
											borderWidth={'1'}
											borderColor={'#2EBA55'}
											w={['42%', '30%', '30%']}
											rounded={'md'}
											alignSelf={'center'}
											_text={{
												color: '#2EBA55',
												fontSize: {
													lg: '18px',
													md: '16px',
													sm: '14px',
													base: '14px',
												},
												fontFamily: 'Poppins',
											}}
											_hover={{
												bg: '#2EBA55',
												_text: {
													color: '#fff',
												},
											}}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: 'transparent' }}
											onPress={() => {
												setAudioPLayer(true);
												setAudioUrl(audioAllObj.file.url);
												fetchFeedback(audioAllObj.file._id);
											}}
										>
											View File
										</Button>
									</HStack>
									<HStack w={'35%'} justifyContent={'space-evenly'}>
										<Button
											size={'sm'}
											bg='#000'
											borderStyle={'solid'}
											borderWidth={'1'}
											borderColor={'#2EBA55'}
											w={['42%', '30%', '30%']}
											rounded={'md'}
											alignSelf={'center'}
											_text={{
												color: '#2EBA55',
												fontSize: {
													lg: '18px',
													md: '16px',
													sm: '14px',
													base: '14px',
												},
												fontFamily: 'Poppins',
											}}
											_hover={{
												bg: '#2EBA55',
												_text: {
													color: '#fff',
												},
											}}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: 'transparent' }}
											onPress={() => {
												setAudioPLayer(true);
												setAudioUrl(audioAllObj.file.url);
												fetchFeedback(audioAllObj.file._id);
											}}
										>
											View File
										</Button>
										<Button
											size={'sm'}
											bg='#2EBA55'
											borderWidth={1}
											borderColor='#2EBA55'
											_text={{
												color: '#fff',
												fontSize: {
													lg: '18px',
													md: '16px',
													sm: '14px',
													base: '14px',
												},
												fontFamily: 'Poppins',
											}}
											_hover={{
												bg: 'transparent',
												_text: {
													color: '#2EBA55',
												},
											}}
											onPress={() => {
												setBasicFeedback(true);
												setFileObj(audioAllObj);

												setFileId(audioAllObj.file._id);
											}}
											w={['40%', '30%', '30%']}
											rounded={'md'}
											alignSelf={'center'}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: '#2EBA55' }}
										>
											Feedback
										</Button>

										<Button
											onPress={() => {
												setShowComments(true);
												setFileObj(audioAllObj);
											}}
											size={'sm'}
											bg={'#000'}
											w={['5%', '10%', '10%']}
											fontSize={{ lg: '16px', md: '16px', sm: '32px' }}
											alignSelf={'center'}
											_hover={{ bg: 'tranparent' }}
											_focused={{ bg: 'tranparent' }}
											_pressed={{ bg: 'tranparent' }}
										>
											<BsChatDots size={25} color={'#afafaf'} />
										</Button>
									</HStack>
								</HStack>
							))}
					</VStack>
				</VStack>
			)}
			<Modal
				isOpen={showAudioPlayer}
				onClose={() => setAudioPLayer(false)}
				w={{ xl: '40%', base: '90%' }}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.Content backgroundColor={'#1C1B1B'}>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						mx={5}
						my={2}
					/>
					<Modal.Header backgroundColor={'#1C1B1B'} borderBottomWidth={0}>
						<Text
							fontSize={{ lg: '3xl', md: '2xl', base: 'xl' }}
							fontStyle={'bold'}
							color={'white'}
							fontFamily={'Poppins'}
							px='2'
							alignSelf={'left'}
						>
							{/* {savefileId} */}
							F4 File
						</Text>
					</Modal.Header>

					<Modal.Body>
						<VStack>
							<Waveform url={audioURL} />
							<Text
								fontSize={{ xl: '2xl', lg: '2xl', sm: 'xl', base: 'lg' }}
								//fontSize={"11px"}
								w={'80%'}
								marginX={1}
								paddingLeft={1}
								//   px={2}
								color='#E3E3E3'
								fontWeight={500}
								fontFamily={'poppins'}
							>
								Reviews
							</Text>
						</VStack>
						<VStack mx='2' my='3' h={'full'}>
							{viewFeedbacks &&
								viewFeedbacks.map((feedbackObj, index) => (
									<>
										<HStack
											key={index}
											rounded={'md'}
											alignSelf={'center'}
											bgColor={'#1C1B1B'}
											w='100%'
											py={2}
										>
											<Box w={{ xl: '70%', base: '60%' }}>
												<Heading
													fontSize={{
														xl: 'lg',
														lg: 'lg',
														sm: 'md',
														base: 'sm',
													}}
													marginX={1}
													paddingLeft={1}
													color='#E3E3E3'
													fontWeight={500}
													fontFamily={'poppins'}
												>
													{feedbackObj.review}
												</Heading>
											</Box>
											<Box w={{ xl: '30%', base: '40%' }}>
												<Rating
													name='read-only'
													value={feedbackObj.stars || 0}
													emptyIcon={
														<StarRoundedIcon
															style={{ color: '#e4e5e9', fontSize: '25' }}
														/>
													}
													readOnly
												/>
											</Box>

											{/* {[...Array(feedbackObj.stars?feedbackObj.stars:0)].map((star, i) => (
              
              
              <GoStar size={25}  color={"#ffc107"}/>
              ))}
              {[...Array(feedbackObj.stars?5-feedbackObj.stars:5)].map((star, i) => (
              
              
              <GoStar size={25}  color={"#e4e5e9"}/>
              ))} */}
										</HStack>
									</>
								))}
						</VStack>
					</Modal.Body>
				</Modal.Content>
			</Modal>
			<Modal
				isOpen={showBasicFeedback}
				onClose={() => {
					setBasicFeedback(false);
					setErrorMsg('');
				}}
				w={{ xl: '40%', base: '90%' }}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.Content backgroundColor={'#1C1B1B'} py={10}>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						mx={5}
					/>

					<Modal.Header
						fontSize={'lg'}
						backgroundColor={'#1C1B1B'}
						borderBottomWidth={0}
					>
						<Text
							fontSize={{ xl: '2xl', lg: '2xl', sm: 'xl', base: 'lg' }}
							fontFamily={'Poppins'}
							color='white'
							alignSelf={'center'}
						>
							Send Feedback
						</Text>
					</Modal.Header>

					<Modal.Body backgroundColor={'#1C1B1B'}>
						<VStack px='1' py='2'>
							<HStack
								w={'90%'}
								justifyContent={'space-between'}
								alignSelf={'center'}
							>
								<Text
									fontSize={{ xl: '2xl', lg: '2xl', sm: 'xl', base: 'lg' }}
									color={'#afafaf'}
									alignSelf={'center'}
									fontFamily={'Poppins'}
								>
									Basic Feedback
								</Text>
								<HStack>
									<Rating
										name='simple-controlled'
										value={starRating}
										emptyIcon={
											<StarRoundedIcon
												style={{ color: '#e4e5e9', fontSize: '25' }}
											/>
										}
										onChange={(event, newValue) => {
											setRating(newValue);
										}}
									/>
									{/* {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;

                    return (
                      <label>
                        <input
                          type='radio'
                          style={{ display: 'none' }}
                          name='rating'
                          value={ratingValue}
                          onClick={() => setRating(ratingValue)}
                        />
                        <GoStar
                          size={25}
                          style={{ cursor: 'pointer' }}
                          color={
                            ratingValue <= (starHover || starRating)
                              ? '#ffc107'
                              : '#e4e5e9'
                          }
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    );
                  })} */}
								</HStack>
							</HStack>
						</VStack>
						<VStack w={'90%'} px='1' py='30' alignSelf={'center'}>
							<Input
								//  py={10}
								onChange={getFeedbackText}
								multiline={true}
								numberOfLines={5}
								fontFamily={'Poppins'}
								backgroundColor={'transparent'}
								borderColor='#afafaf'
								borderWidth={2}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '2',
									_focus: { borderColor: '#2eba55', shadow: 0 },
								}}
								focusOutlineColor={'#2eba55'}
								fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
								color={'#afafaf'}

								//  overflowY={"scroll"}
							></Input>
							<Text
								fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
								color={'#FF0000'}
								fontFamily={'Poppins'}
								py={3}
								alignSelf={'left'}
							>
								{errorMsg}
							</Text>
							<Text
								py={5}
								fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
								color={'#afafaf'}
								fontFamily={'Poppins'}
								alignSelf={'center'}
							>
								OR
							</Text>
						</VStack>
					</Modal.Body>

					<Modal.Footer backgroundColor={'#1C1B1B'} borderTopWidth={0}>
						<Button.Group justifyContent={'space-around'} width={'100%'}>
							<Button
								size={'sm'}
								bg='#1c1b1b'
								borderStyle={'solid'}
								borderWidth={'1'}
								borderColor={'#2EBA55'}
								w={'40%'}
								rounded={'md'}
								alignSelf={'center'}
								_text={{
									color: '#2EBA55',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
									fontFamily: 'Poppins',
								}}
								_hover={{
									bg: '#2EBA55',
									_text: {
										color: '#fff',
									},
								}}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: 'transparent' }}
								onPress={() => {
									setBasicFeedback(false);
									setHighFeedback(true);
									setErrorMsg('');
									setFeedbackText(null);
									// handleComment();
								}}
							>
								High Level Feedback
							</Button>
							<Button
								size={'sm'}
								bg='#2EBA55'
								borderWidth={1}
								borderColor='#2EBA55'
								_text={{
									color: '#fff',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
									fontFamily: 'Poppins',
								}}
								_hover={{
									bg: 'transparent',
									_text: {
										color: '#2EBA55',
									},
								}}
								onPress={() => {
									sendFeedback(savefileId);
								}}
								w={'20%'}
								rounded={'md'}
								alignSelf={'center'}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: '#2EBA55' }}
							>
								Send
							</Button>
						</Button.Group>
					</Modal.Footer>
				</Modal.Content>
			</Modal>
			<Modal
				isOpen={showHighFeedback}
				onClose={() => {
					setHighFeedback(false);
					setErrorMsg('');
				}}
				w={{ xl: '40%', base: '90%' }}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.Content backgroundColor={'#1C1B1B'} py={10}>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						mx={5}
					/>

					<Modal.Header
						fontSize={'lg'}
						backgroundColor={'#1C1B1B'}
						borderBottomWidth={0}
					>
						<Text
							fontSize={{ xl: '2xl', lg: '2xl', sm: 'xl', base: 'lg' }}
							color='white'
							fontFamily={'Poppins'}
							alignSelf={'center'}
						>
							Send Feedback
						</Text>
					</Modal.Header>

					<Modal.Body backgroundColor={'#1C1B1B'}>
						<VStack px='1' py='2'>
							<HStack
								w={'90%'}
								justifyContent={'space-between'}
								alignSelf={'center'}
							>
								<Text
									fontSize={{ xl: '2xl', lg: '2xl', sm: 'xl', base: 'lg' }}
									color={'#afafaf'}
									fontFamily={'Poppins'}
									alignSelf={'center'}
								>
									High Level Feedback
								</Text>
								<HStack>
									<Rating
										name='simple-controlled'
										value={starRating}
										emptyIcon={
											<StarRoundedIcon
												style={{ color: '#e4e5e9', fontSize: '25' }}
											/>
										}
										onChange={(event, newValue) => {
											setRating(newValue);
										}}
									/>
									{/* {[...Array(5)].map((star, i) => {
                    const ratingValue = i + 1;

                    return (
                      <label>
                        <input
                          type='radio'
                          style={{ display: 'none' }}
                          name='rating'
                          value={ratingValue}
                          onClick={() => setRating(ratingValue)}
                        />
                        <GoStar
                          size={25}
                          style={{ cursor: 'pointer' }}
                          color={
                            ratingValue <= (starHover || starRating)
                              ? '#ffc107'
                              : '#e4e5e9'
                          }
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    );
                  })} */}
								</HStack>
							</HStack>
						</VStack>

						<VStack w={'90%'} px='1' alignSelf={'center'}>
							<HStack py='10' w={'35%'} justifyContent={'space-between'}>
								<Input
									h={'16'}
									w={'12'}
									px={2}
									mx={2}
									onKeyPress={handleTimeInput}
									onChange={handleTimeInput}
									fontFamily={'Poppins'}
									backgroundColor={'transparent'}
									borderColor='#afafaf'
									borderWidth={1}
									rounded={'md'}
									_important={{
										backgroundColor: 'transparent',
										borderColor: '#afafaf',
										borderWidth: '2',
										_focus: { borderColor: '#2eba55', shadow: 0 },
									}}
									focusOutlineColor={'#2eba55'}
									fontSize={{ lg: '36px', md: '36px', sm: '24px' }}
									color={'#afafaf'}
								></Input>
								<Input
									h={'16'}
									w={'12'}
									px={2}
									onKeyPress={handleTimeInput}
									onChange={handleTimeInput}
									fontFamily={'Poppins'}
									backgroundColor={'transparent'}
									borderColor='#afafaf'
									rounded={'md'}
									borderWidth={1}
									_important={{
										backgroundColor: 'transparent',
										borderColor: '#afafaf',
										borderWidth: '2',
										_focus: { borderColor: '#2eba55', shadow: 0 },
									}}
									focusOutlineColor={'#2eba55'}
									fontSize={{ lg: '36px', md: '36px', sm: '24px' }}
									color={'#afafaf'}
								></Input>
								<Box
									h={'16'}
									alignSelf={'center'}
									justifyContent={'center'}
									w={'12'}
								>
									<Text
										fontSize={{ lg: '64px', md: '32px', sm: '24px' }}
										color={'#afafaf'}
										alignSelf={'center'}
										fontFamily={'Poppins'}
										justifyContent={'center'}
									>
										:
									</Text>
								</Box>
								<Input
									h={'16'}
									w={'12'}
									onKeyPress={handleTimeInput}
									onChange={handleTimeInput}
									fontFamily={'Poppins'}
									backgroundColor={'transparent'}
									borderColor='#afafaf'
									rounded={'md'}
									borderWidth={1}
									_important={{
										backgroundColor: 'transparent',
										borderColor: '#afafaf',
										borderWidth: '2',
										_focus: { borderColor: '#2eba55', shadow: 0 },
									}}
									focusOutlineColor={'#2eba55'}
									fontSize={{ lg: '36px', md: '36px', sm: '24px' }}
									color={'#afafaf'}
								></Input>
								<Input
									h={'16'}
									w={'12'}
									mx={2}
									type={'tel'}
									rounded={'md'}
									onKeyPress={handleTimeInput}
									onChange={handleTimeInput}
									fontFamily={'Poppins'}
									backgroundColor={'transparent'}
									borderColor='#afafaf'
									borderWidth={1}
									_important={{
										backgroundColor: 'transparent',
										borderColor: '#afafaf',
										borderWidth: '2',
										_focus: { borderColor: '#2eba55', shadow: 0 },
									}}
									focusOutlineColor={'#2eba55'}
									fontSize={{ lg: '36px', md: '36px', sm: '24px' }}
									color={'#afafaf'}
								></Input>
							</HStack>
							<Input
								//  py={10}
								multiline={true}
								numberOfLines={5}
								fontFamily={'Poppins'}
								backgroundColor={'transparent'}
								borderColor='#afafaf'
								borderWidth={2}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '2',
									_focus: { borderColor: '#2eba55', shadow: 0 },
								}}
								focusOutlineColor={'#2eba55'}
								fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
								color={'#afafaf'}

								//  overflowY={"scroll"}
							></Input>
							<Text
								fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
								color={'#FF0000'}
								py={3}
								fontFamily={'Poppins'}
								alignSelf={'left'}
							>
								{errorMsg}
							</Text>
							<Text
								py={5}
								fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
								color={'#afafaf'}
								fontFamily={'Poppins'}
								alignSelf={'center'}
							>
								OR
							</Text>
						</VStack>
					</Modal.Body>

					<Modal.Footer backgroundColor={'#1C1B1B'} borderTopWidth={0}>
						<Button.Group justifyContent={'space-around'} width={'100%'}>
							<Button
								size={'sm'}
								bg='#1c1b1b'
								borderStyle={'solid'}
								borderWidth={'1'}
								borderColor={'#2EBA55'}
								w={'40%'}
								rounded={'md'}
								alignSelf={'center'}
								_text={{
									color: '#2EBA55',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
									fontFamily: 'Poppins',
								}}
								_hover={{
									bg: '#2EBA55',
									_text: {
										color: '#fff',
									},
								}}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: 'transparent' }}
								onPress={() => {
									setBasicFeedback(true);
									setHighFeedback(false);
									setErrorMsg('');
									setFeedbackText(null);
								}}
							>
								Basic Feedback
							</Button>
							<Button
								size={'sm'}
								bg='#2EBA55'
								borderWidth={1}
								borderColor='#2EBA55'
								_text={{
									color: '#fff',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
									fontFamily: 'Poppins',
								}}
								_hover={{
									bg: 'transparent',
									_text: {
										color: '#2EBA55',
									},
								}}
								onPress={() => {
									sendFeedback(savefileId);
								}}
								w={'20%'}
								rounded={'md'}
								alignSelf={'center'}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: '#2EBA55' }}
							>
								Send
							</Button>
						</Button.Group>
					</Modal.Footer>
				</Modal.Content>
			</Modal>
		</>
	);
};
export default AudioRecived;
