import { Button, Divider, HStack, Image, Text, VStack, Box } from 'native-base';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

import { DaysHoursMinutes } from '../../helpers/DateFtns';
const ViewAccess = (props) => {
	const bpObj = props.obj;
	const [action, setAction] = useState([]);
	const [allowedUsers, setAllowedUsers] = useState([]);

	const headerkey = `Bearer ${localStorage.getItem('token')}`;
	const viewAccessesUsers = (bp_id) => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallbeatpackallowedusers/${bp_id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				setAllowedUsers(result.allowedUsersPopulated2);
			})

			.catch((error) => console.log('error', error));
	};
	useEffect(() => {
		viewAccessesUsers(bpObj._id);
	}, []);

	const navigate = useNavigate();
	return (
		<>
			<Box w={'100%'} bg={'#000'}>
				<HStack w={'100%'}>
					<Box w={{ xl: '100%', lg: '100%', base: '100%' }}>
						<VStack py={1} w={'100%'} bg='#000' justifyContent={'space-around'}>
							<VStack
								w={'100%'}
								py={['1', '1', '1']}
								justifyContent={'space-evenly'}
							>
								<HStack px='1' py='3' my={'5'}>
									<Button
										onPress={() => {
											//handleBeatpack();
											navigate('/audio-library');
										}}
										size={'sm'}
										bg={'#000'}
										w={['5%', '10%', '5%']}
										alignSelf={'center'}
										_hover={{ bg: 'transparent' }}
										_focused={{ bg: 'transparent' }}
										_pressed={{ bg: 'transparent' }}
									>
										<Image
											source={require('../../assets/Icons/arrowLeft.png')}
											size={['5', '7', '8']}
											mt='0.5'
											opacity={'2'}
											alt='back'
										/>
									</Button>
									<Text
										fontSize={{
											xl: '40px',
											lg: '40px',
											md: '30px',
											base: '20px',
										}}
										lineHeight={{
											xl: '60px',
											lg: '50px',
											md: '40px',
											base: '30px',
										}}
										color='white'
										fontFamily={'Poppins'}
										alignSelf={'center'}
									>
										Packs{' > '}
										{bpObj.beatPackName
											? bpObj.beatPackName
											: 'Untitled BeatPack'}
									</Text>
								</HStack>
								<HStack
									justifyContent={'flex- start'}
									w={'100%'}
									alignSelf={'center'}
									my={2}
								>
									<Image
										height={{
											xl: '162px',
											lg: '162px',
											md: '100px',
											base: '60px',
										}}
										rounded={'md'}
										width={{
											xl: '135px',
											lg: '135px',
											md: '80px',
											base: '50px',
										}}
										alignSelf={'center'}
										source={bpObj.beatPackCover}
										alt='Beat pack cover image'
									/>
									<Text
										fontSize={{
											xl: '24px',
											lg: '24px',
											md: '18px',
											base: '16px',
										}}
										color='white'
										alignSelf={'center'}
										fontFamily={'Poppins'}
										mx={3}
									>
										{bpObj.beatPackName} has been viewed by{' '}
										{allowedUsers ? allowedUsers.length : '0'}
									</Text>
								</HStack>
								<Box py={20}>
									{allowedUsers?.length > 0
										? allowedUsers.map((user, index) => (
												<Box
													w={'100%'}
													alignSelf={'center'}
													key={'beatPack_' + index}
												>
													<HStack justifyContent={'space-between'} mt={20}>
														<HStack>
															<Image
																rounded={'full'}
																size={20}
																alignSelf={'center'}
																source={user.profilePic}
																alt='User Profile Pic'
															/>
															<Box mx={3}>
																<Text
																	fontSize={{ lg: '2xl', md: 'xl', base: 'lg' }}
																	color='white'
																	alignSelf={'center'}
																	fontFamily={'Poppins'}
																	py={1}
																>
																	{user.firstname + ' ' + user.lastname}
																</Text>
																<Text
																	fontSize={{ lg: 'md', md: 'sm', base: 'xs' }}
																	color='#afafaf'
																	fontFamily={'Poppins'}
																	py={1}
																>
																	{DaysHoursMinutes(user.viewedAt)}
																</Text>
															</Box>
														</HStack>
														<Button
															variant={'ghost'}
															p={0}
															_hover={{ bg: 'transparent' }}
															_pressed={{ bg: 'transparent' }}
															_focus={{ bg: 'transparent' }}
															onPress={() => {
																if (action.includes(user._id)) {
																	setAction(
																		action.filter((item) => item !== user._id)
																	);
																} else {
																	setAction([...action, user._id]);
																}
															}}
														>
															<HStack>
																<Text
																	fontSize={{ lg: 'lg', md: 'md', base: 'sm' }}
																	color='white'
																	alignSelf={'center'}
																	fontFamily={'Poppins'}
																	py={1}
																	px={2}
																>
																	{user.isSaved ? '2 actions' : '1 action'}
																</Text>

																{!action.includes(user._id) ? (
																	<KeyboardArrowDownRoundedIcon
																		sx={{
																			color: '#afafaf',
																			fontSize: 25,
																			alignSelf: 'center',
																		}}
																	/>
																) : (
																	<KeyboardArrowUpRoundedIcon
																		sx={{
																			color: '#afafaf',
																			fontSize: 25,
																			alignSelf: 'center',
																		}}
																	/>
																)}
															</HStack>
														</Button>
													</HStack>
													{action.includes(user._id) ? (
														<Box>
															<HStack justifyContent={'space-between'} my={2}>
																<Text
																	fontSize={{ lg: 'lg', md: 'md', base: 'sm' }}
																	color='white'
																	alignSelf={'center'}
																	fontFamily={'Poppins'}
																	py={1}
																	px={2}
																>
																	Viewed Pack
																</Text>
																<Text
																	fontSize={{ lg: 'lg', md: 'md', base: 'sm' }}
																	color='#AFAFAF'
																	alignSelf={'center'}
																	fontFamily={'Poppins'}
																	py={1}
																	px={2}
																>
																	{moment(user.viewedAt).format('DD/MM/YYYY') +
																		', ' +
																		moment(
																			user?.viewedAt
																				?.split('T')[1]
																				?.split('.')[0],
																			'HH:mm'
																		).format('hh:mm A')}
																</Text>
															</HStack>
															{user.isSaved ? (
																<HStack justifyContent={'space-between'} my={2}>
																	<Text
																		fontSize={{
																			lg: 'lg',
																			md: 'md',
																			base: 'sm',
																		}}
																		color='white'
																		alignSelf={'center'}
																		fontFamily={'Poppins'}
																		py={1}
																		px={2}
																	>
																		Saved Item to Library
																	</Text>
																	<Text
																		fontSize={{
																			lg: 'lg',
																			md: 'md',
																			base: 'sm',
																		}}
																		color='#AFAFAF'
																		alignSelf={'center'}
																		fontFamily={'Poppins'}
																		py={1}
																		px={2}
																	>
																		{moment(user.savedAt).format('DD/MM/YYYY') +
																			', ' +
																			moment(
																				user?.savedAt
																					?.split('T')[1]
																					?.split('.')[0],
																				'HH:mm'
																			).format('hh:mm A')}
																	</Text>
																</HStack>
															) : (
																''
															)}
														</Box>
													) : (
														''
													)}
													<Divider />
												</Box>
										  ))
										: ''}
								</Box>
							</VStack>
						</VStack>
					</Box>
				</HStack>
			</Box>
		</>
	);
};
export default ViewAccess;
