import React from 'react';
import { Box, HStack } from 'native-base';
import FinanicalGraphs from '../../components/Financails/FinanicalGraphs';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
export default function FinancialsDetails() {
	return (
		<div>
			<Box w={'100%'} bg={'#000201'}>
				<HStack w={'100%'}>
					<Sidebar
						childComponent={<FinanicalGraphs />}
						activeText='Financials'
					/>
				</HStack>
			</Box>
		</div>
	);
}
