import { color, textTransform } from '@mui/system';
import { Box, Button, Divider, Heading, HStack, Image } from 'native-base';
import React from 'react';
import Footer from '../../Footer/Footer';
import GetNotified from '../../Getnotified/GetNotified';
import HeaderComp from '../../Header/HeaderComp';

const Layout = (props) => {
	const { title, subtitle, desc, image, content } = props;

	return (
		<Box w={'100%'} bg={'#000201'}>
			<div className='website-header'>
				<HeaderComp />
			</div>
			<Box
				w={{ xl: '60%', lg: '70%', base: '80%' }}
				alignSelf={'center'}
				my={10}
			>
				<Heading
					fontFamily={'Poppins'}
					pb={5}
					textAlign={'center'}
					fontSize={{ xl: '6xl', lg: '5xl', md: '4xl', base: '3xl' }}
					fontWeight={'extrabold'}
					color={'#2EBB55'}
					textTransform={'uppercase'}
				>
					{title}
				</Heading>
				<Heading
					fontFamily={'Poppins'}
					py={5}
					textAlign={'center'}
					fontSize={{ xl: '40', lg: '4xl', md: '3xl', base: '2xl' }}
					fontWeight={'medium'}
					color={'#e3e3e3'}
					textTransform={'uppercase'}
				>
					{subtitle}
				</Heading>
				<Heading
					fontFamily={'Poppins'}
					textAlign={'center'}
					fontSize={{ xl: '2xl', lg: '2xl', md: 'xl', base: 'lg' }}
					fontWeight={'normal'}
					color={'#AFAFAF'}
					textTransform={'capitalize'}
				>
					{desc}
				</Heading>
			</Box>
			<Box w={{ xl: '95%', base: '90%' }} alignSelf={'center'}>
				<Image
					source={image}
					w={'100%'}
					resizeMode={'cover'}
					h={{ xl: '700px', lg: '500px', md: '400px', base: '300px' }}
					rounded={'lg'}
				/>
				<Box my={5}>
					{content.map((item) => (
						<Heading
							py={5}
							fontSize={{ xl: '2xl', base: 'lg' }}
							fontWeight={'normal'}
							fontFamily={'Poppins'}
							textTransform={'capitalize'}
							textAlign={'left'}
							color={'#AFAFAF'}
						>
							{item.des}
						</Heading>
					))}
					;
				</Box>

				<Button
					bg={'#2EBB55'}
					borderWidth={1}
					borderColor={'#2EBB55'}
					_hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
					_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
					_text={{
						color: '#e3e3e3',
						fontSize: { xl: '2xl', base: 'lg' },
						fontFamily: 'Poppins',
						textAlign: 'center',
						textTransform: 'capitalize',
						fontWeight: 'medium',
					}}
					px={10}
					py={2}
					my={5}
					alignSelf={'center'}
				>
					Let’s Get Start
				</Button>
			</Box>
			<GetNotified />
			<Footer />
		</Box>
	);
};

export default Layout;
