/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React from "react";

function SettingsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 12C15 12.5933 14.8241 13.1734 14.4944 13.6667C14.1648 14.1601 13.6962 14.5446 13.1481 14.7716C12.5999 14.9987 11.9967 15.0581 11.4147 14.9424C10.8328 14.8266 10.2982 14.5409 9.87868 14.1213C9.45912 13.7018 9.1734 13.1672 9.05765 12.5853C8.94189 12.0033 9.0013 11.4001 9.22836 10.8519C9.45543 10.3038 9.83994 9.83524 10.3333 9.50559C10.8266 9.17595 11.4067 9 12 9C12.7957 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z" stroke="#AFAFAF" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 12.8789V11.1189C2.00158 10.6155 2.20227 10.1331 2.55825 9.77714C2.91422 9.42117 3.39658 9.22048 3.9 9.2189C5.71 9.2189 6.45 7.9389 5.54 6.3689C5.29015 5.93244 5.22353 5.41473 5.35474 4.92923C5.48596 4.44374 5.8043 4.03007 6.24 3.7789L7.97 2.7889C8.35203 2.56663 8.80663 2.50497 9.23406 2.61746C9.6615 2.72994 10.0269 3.00737 10.25 3.3889L10.36 3.5789C11.26 5.1489 12.74 5.1489 13.65 3.5789L13.76 3.3889C13.9831 3.00737 14.3485 2.72994 14.7759 2.61746C15.2034 2.50497 15.658 2.56663 16.04 2.7889L17.77 3.7789C18.2057 4.03007 18.524 4.44374 18.6553 4.92923C18.7865 5.41473 18.7199 5.93244 18.47 6.3689C17.56 7.9389 18.3 9.2189 20.11 9.2189C20.6134 9.22048 21.0958 9.42117 21.4518 9.77714C21.8077 10.1331 22.0084 10.6155 22.01 11.1189V12.8789C22.0084 13.3823 21.8077 13.8647 21.4518 14.2207C21.0958 14.5766 20.6134 14.7773 20.11 14.7789C18.3 14.7789 17.56 16.0589 18.47 17.6289C18.7199 18.0654 18.7865 18.5831 18.6553 19.0686C18.524 19.5541 18.2057 19.9677 17.77 20.2189L16.04 21.2089C15.658 21.4312 15.2034 21.4928 14.7759 21.3803C14.3485 21.2679 13.9831 20.9904 13.76 20.6089L13.65 20.4189C12.75 18.8489 11.27 18.8489 10.36 20.4189L10.25 20.6089C10.0269 20.9904 9.6615 21.2679 9.23406 21.3803C8.80663 21.4928 8.35203 21.4312 7.97 21.2089L6.24 20.2189C5.8043 19.9677 5.48596 19.5541 5.35474 19.0686C5.22353 18.5831 5.29015 18.0654 5.54 17.6289C6.45 16.0589 5.71 14.7789 3.9 14.7789C3.39658 14.7773 2.91422 14.5766 2.55825 14.2207C2.20227 13.8647 2.00158 13.3823 2 12.8789V12.8789Z" stroke="#AFAFAF" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
}

export default SettingsIcon;
