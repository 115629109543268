import React,{useState} from 'react'
import { Box } from 'native-base'
import { TalentcomponentHeader } from '../../TalentComponentHeader/TalentComponentHeader'
import LineChart from '../../Graphs/Line'
import styles  from "./../styles.module.css"
import { Loader } from '../../../Loader'
function Trend(props) {
    const {image,title,subtitle,tableData,isLoading}=props

  return (
  <Box my={5}   p={5} bg={"#1C1B1B"} rounded={"lg"} width={{lg:"48%",base:"100%"}}>

<TalentcomponentHeader
        image={require(`../../../../assets/TiktokComponents/${image}.png`)}
        title={title}
        subtitle={subtitle}/>
        
<LineChart isLoading={isLoading} tableData={tableData}/>
    
  </Box>
  )
}

export default Trend