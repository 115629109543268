import React from 'react';
import { Button, HStack, Heading } from 'native-base';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
function SeeMore({ SetSeeMore, seeMore }) {
	return (
		<Button
			onPress={() => SetSeeMore(!seeMore)}
			mt={8}
			p={1}
			variant={'ghost'}
			_hover={{
				backgroundColor: 'transparent',
				borderColor: '#2ebb55',
				borderWidth: '2',
			}}
			_press={{
				backgroundColor: 'transparent',
				borderColor: '#2ebb55',
				borderWidth: '2',
			}}
			alignSelf={'flex-start'}
			borderWidth={'2'}
			borderColor={'#1c1b1c'}
			_icon={<ArrowDropDownIcon />}
		>
			<HStack alignItems={'center'} px={1}>
				<Heading
					px={1}
					color={'#E3E3E3'}
					textTransform={'capitalize'}
					fontFamily={'Poppins'}
					fontSize={{ md: 'md', base: 'sm' }}
					fontWeight={'normal'}
					textAlign={'center'}
				>
					{seeMore ? 'see less' : 'see more'}
				</Heading>
				{seeMore ? (
					<ArrowDropUpIcon sx={{ color: '#E3E3E3' }} />
				) : (
					<ArrowDropDownIcon sx={{ color: '#E3E3E3' }} />
				)}
			</HStack>
		</Button>
	);
}

export default SeeMore;
