/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
  Box,
  Button,
  CloseIcon,
  Heading,
  HStack,
  Image,
  Modal,
} from 'native-base';
import React from 'react';

export function WelcomePopup(props) {
  const { openModal, closeModal } = props;

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      w={{ xl: '50%', lg: '65%', md: '60%', base: '90%' }}
      size={'full'}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={20}
    >
      <Box w={'90%'} py={5} bg={'#141313'} rounded={'lg'}>
        <HStack
          alignSelf={'center'}
          justifyContent={'space-between'}
          my={5}
          w={'90%'}
        >
          <Heading
            w={'100%'}
            color={'#e3e3e3'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
            textAlign={'center'}
            fontSize={{ xl: '24px', lg: '20px', base: '16px' }}
          >
            Welcome To Your Packs
          </Heading>

          <Button
            variant={'ghost'}
            px={{ lg: 5, base: 2 }}
            py={{ lg: 2, base: 0 }}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeModal}
            alignItems={'center'}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>
        <Box
          w={'100%'}
          alignSelf={'center'}
          mt={{ lg: 5, base: 0 }}
          mb={{ lg: 10, base: 5 }}
        >
          <Heading
            w={'100%'}
            color={'#afafaf'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
            textAlign={'center'}
            fontSize={{ xl: '24px', lg: '20px', base: '14px' }}
          >
            Packs are collections of files that you can share with
            collaborators.
          </Heading>
          <Box my={5} w={'80%'} alignSelf={'center'}>
            <HStack my={5} w={'100%'}>
              <Image
                source={require('../../assets/Popup/diagram.png')}
                size={{ xl: 10, lg: 8, base: 5 }}
                alignSelf={'center'}
                mr={2}
              />
              <Box mx={3} w={'90%'}>
                <Heading
                  color={'#fff'}
                  fontWeight={'normal'}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: '24px', lg: '20px', base: '16px' }}
                  py={1}
                >
                  View activity
                </Heading>
                <Heading
                  color={'#afafaf'}
                  fontWeight={'normal'}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: '18px', lg: '16px', base: '12px' }}
                  lineHeight={{ lg: '27px', base: '15px' }}
                >
                  See which packs are getting opened and who's using the files.
                </Heading>
              </Box>
            </HStack>
            <HStack my={5} w={'100%'}>
              <Image
                source={require('../../assets/Popup/music.png')}
                size={{ xl: 10, lg: 8, base: 5 }}
                alignSelf={'center'}
                mr={2}
              />
              <Box mx={3} w={'90%'}>
                <Heading
                  color={'#fff'}
                  fontWeight={'normal'}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: '24px', lg: '20px', base: '16px' }}
                  py={1}
                >
                  Update content
                </Heading>
                <Heading
                  color={'#afafaf'}
                  fontWeight={'normal'}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: '18px', lg: '16px', base: '12px' }}
                  lineHeight={{ lg: '27px', base: '15px' }}
                >
                  Add or remove a file from any pack to keep content up to date.
                </Heading>
              </Box>
            </HStack>
            <HStack my={5} w={'100%'}>
              <Image
                source={require('../../assets/Popup/lock.png')}
                size={{ xl: 10, lg: 8, base: 5 }}
                alignSelf={'center'}
                mr={2}
              />
              <Box mx={3} w={'90%'}>
                <Heading
                  color={'#fff'}
                  fontWeight={'normal'}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: '24px', lg: '20px', base: '16px' }}
                  py={1}
                >
                  Control access
                </Heading>
                <Heading
                  color={'#afafaf'}
                  fontWeight={'normal'}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: '18px', lg: '16px', base: '12px' }}
                  lineHeight={{ lg: '27px', base: '15px' }}
                >
                  Delete packs or update permissions at any time.
                </Heading>
              </Box>
            </HStack>
          </Box>
          <Button
            size={'sm'}
            bg='#2EBA55'
            borderWidth={1}
            borderColor='#2EBA55'
            _text={{
              color: '#fff',
              fontSize: {
                lg: '20px',
                md: '18px',
                base: '16px',
              },
              fontWeight: 'medium',
              fontFamily: 'Poppins',
            }}
            _hover={{
              bg: 'transparent',
              _text: {
                color: '#2EBA55',
              },
            }}
            onPress={() => {
              closeModal();
            }}
            w={{ lg: '20%', base: '30%' }}
            rounded={'md'}
            alignSelf={'center'}
            _focused={{ bg: '#2EBA55' }}
            _pressed={{ bg: '#2EBA55' }}
          >
            Got It
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
