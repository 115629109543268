import React from 'react';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import FeedsComponent from '../../components/Feeds/Feeds';
const Feeds = () => {
	return (
		<Sidebar
			activeText='Social'
			activeSubText='Feed'
			childComponent={<FeedsComponent />}
		/>
	);
};

export default Feeds;
