import React from 'react';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import ConnectMeProfile from '../../components/Socials/ConnectMe/ConnectMeProfile';
const ConnectMe = () => {
	return (
		<Sidebar
			activeText='Social'
			activeSubText='ConnectMe'
			childComponent={<ConnectMeProfile />}
		/>
	);
};
export default ConnectMe;
