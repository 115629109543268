import React from 'react';
import { Box, HStack } from 'native-base';
import Goals from '../../components/GoalsAndAnalytics/Goals/Goals';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
export default function Goal() {
	return (
		<div>
			<Box w={'100%'} bg={'#000201'}>
				<HStack w={'100%'}>
					<Sidebar childComponent={<Goals />} activeText='Goal' />
				</HStack>
			</Box>
		</div>
	);
}
