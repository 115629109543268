import React, { useState, useEffect } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Line } from 'react-chartjs-2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styles from './styles.module.css';
import {
	Box,
	Checkbox,
	Heading,
	HStack,
	Image,
	Text,
	VStack,
	Button,
	Divider,
} from 'native-base';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

const LinePlatformGraph = (props) => {
	const {
		title,
		event,
		data,
		options,
		handleChange,
		days,
		daysYoutube,
		handleChangeYoutube,
	} = props;
	return (
		<Box bg={'#1C1B1B'} rounded={'lg'} my={5} p={5} w={'100%'}>
			<VStack>
				<HStack
					my={2}
					w={'100%'}
					a
					alignItems={'center'}
					justifyContent={'space-between'}
				>
					<Heading
						px={3}
						fontSize={{ xl: '2xl', md: 'lg', base: 'md' }}
						fontWeight={'normal'}
						color={'#e3e3e3'}
						textAlign={'center'}
						alignSelf={'center'}
					>
						{title} View
					</Heading>
					<FormControl className='weekFilter'>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							value={title === 'Spotify' ? days : daysYoutube}
							displayEmpty
							className={styles.customDropdown}
							onChange={
								title === 'Spotify' ? handleChange : handleChangeYoutube
							}
							inputProps={{
								MenuProps: {
									MenuListProps: {
										sx: {
											backgroundColor: '#1c1b1b',
											color: '#afafaf',
										},
									},
								},
							}}
						>
							<MenuItem value={'7'}>This Week</MenuItem>
							<MenuItem value={'28'}>Last 28 Days</MenuItem>
							<MenuItem value={'90'}>Last 90 Days</MenuItem>
						</Select>
					</FormControl>
					{/* <Button
						mx={3}
						p={0}
						py={1}
						variant={'ghost'}
						borderWidth={1}
						borderColor={'#323333'}
						rounded={'lg'}
						rightIcon={<ArrowDropDownIcon sx={{ color: '#e3e3e3' }} />}
					>
						<Heading
							px={2}
							fontSize={'xs'}
							fontWeight={'normal'}
							color={'#AFAFAF'}
						>
							{event}
						</Heading>
					</Button> */}
				</HStack>
				<Line options={options} data={data} />

				<HStack my={2} w={'100%'} justifyContent={'space-between'}>
					<HStack space={2}>
						{' '}
						<FiberManualRecordIcon
							sx={{
								color: title === 'Spotify' ? '#2EBB55' : 'rgb(53, 162, 235)',
								fontSize: 15,
								alignSelf: 'center',
							}}
						/>
						<Heading
							fontSize={'xs'}
							fontWeight={'normal'}
							textTransform={'uppercase'}
							color={'#AFAFAF'}
							textAlign={'center'}
							alignSelf={'center'}
						>
							{title}
						</Heading>
					</HStack>
					{/* 
					<Box w={'50%'} alignSelf={'flex-end'}>
						<Button
							mx={3}
							p={0}
							variant={'ghost'}
							alignSelf={'flex-end'}
							rounded={'lg'}
						>
							<Heading
								px={2}
								fontSize={{ md: 'md', base: 'xs' }}
								fontWeight={'normal'}
								color={'#AFAFAF'}
							>
								See All
							</Heading>
							<Divider />
						</Button>
					</Box> */}
				</HStack>
			</VStack>
		</Box>
	);
};

export default LinePlatformGraph;
