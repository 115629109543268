/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Link,
	Text,
} from 'native-base';
import React from 'react';
import styles from './styles.module.css';
function NewLandingPageBanner() {
	return (
		<Box w={'100%'} bg={'#000'}>
			<video autoPlay loop id='video-background' muted>
				<source
					src='https://umapp-prod.s3.us-west-1.amazonaws.com/Static/header.mp4'
					type='video/mp4'
				/>
			</video>
			{/* <Image
				zIndex={10}
				h={{
					'2xl': '900px',
					xl: '750px',
					lg: '550px',
					md: '450px',
					base: '500px',
				}}
				w={'100%'}
				bg={'#000'}
				opacity={0.5}
				resizeMode='cover'
				source={require('../../assets/Home/header.mp4')}
				alt='image'
			/> */}

			<Box
				zIndex={20}
				position={'absolute'}
				h={{
					'2xl': '900px',
					xl: '750px',
					lg: '550px',
					md: '450px',
					base: '450px',
				}}
				// py={{ xl: '20', base: '10' }}
				flex={1}
				w={'100%'}
			>
				<Box w={'80%'} alignSelf={'center'}>
					<Box w={{ xl: '80%', base: '80%' }} alignSelf={'center'} mt={20}>
						{/* <Text
              textTransform={'capitalize'}
              className={styles.bannerText}
              fontSize={{ xl: '64px', lg: '56px', md: '48px', base: '24px' }}
              mt={16}
              mb={10}
              fontFamily={'Poppins'}
              fontWeight={'extrabold'}
              textAlign={'center'}
            >
              UNITED MARKET
            </Text> */}
						<div className={styles.bannerText}>UNITED MARKET</div>
						<Link href='/signup'>
							<button
								// data-tf-popup='F9YFcKqF'
								// data-tf-iframe-props='title=Composition Collection'
								// data-tf-medium='snippet'
								className='LetsWorkTogether'
							>
								Let's Win Together
							</button>
						</Link>
					</Box>
					{/* <Link alignSelf={'center'}>
              <button
                data-tf-popup='F9YFcKqF'
                data-tf-iframe-props='title=Composition Collection'
                data-tf-medium='snippet'
                className='ApplyNowPublishing'
              >
                &nbsp;Apply Now
              </button>
            </Link> */}
					{/* <Button
              size={'sm'}
              bg={'#2EBB55'}
              borderWidth={1}
              borderColor='#2EBA55'
              my={{ xl: 10, base: 5 }}
              py={{ xl: 4, base: 2 }}
              _text={{
                color: '#FFF',
                fontSize: {
                  xl: '24px',
                  lg: '24px',
                  md: '18px',
  
                  base: '14px',
                },
                fontFamily: 'Poppins',
              }}
              _hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
              onPress={() => {}}
              w={{ xl: '25%', base: '35%' }}
              rounded={'md'}
              _focused={{ bg: '#2EBA55' }}
              _pressed={{ bg: '#2EBA55' }}
            >
              Apply Now
            </Button> */}
				</Box>
			</Box>
		</Box>
	);
}

export default NewLandingPageBanner;
