import { color, textTransform } from "@mui/system";
import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import staticA_R from "../../assets/staticWebsite/staticA_R.png";
import Layout from "../../components/StaticWebSite/LandingPagesComp/Layout";

const A_RS = (props) => {
  
  const data = [
    {
      id: 1,
      des: "Finding the next big thing is hard. However, it is much easier when you have access to thousands of artists and music producers eager for an opportunity to showcase their talents on United Market. No matter where you are based, you can find the top local talent or a talented producer thousands of miles away. Either way, you will no longer have to hope to get lucky on social media or at an event.",
    },
    {
      id: 2,
      des: "Whether you are signed to a label or working independently, we make your job easier and bring the talent straight to you! ",
    },
    {
      id: 3,
      des: "Apply now to be an A&R on United Market.",
    },
    // {
    //   id: 4,
    //   des: " United Market is your all-in-one collaboration and project management platform. Streamline your current clientele directly to your U.M profile and take advantage of everything we offer!",
    // },
  ];

  return (
    <Box w={"100%"} bg={"#000201"}>
        <Layout
        title={'A&RS'}
        subtitle={'UNITED MARKET FOR ARTISTS & REPERTOIRE'}
        desc={'Find the next big thing exclusively on United Market'}
        image={staticA_R}
        content={data}
        />

    </Box>
  );
};

export default A_RS;