import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Select,
} from 'native-base';
import { ToastContainer } from 'react-toastify';
import React, { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import TermsandConditionsMutation from '../../../components/Hooks/TermsAndConditions/SendEmail';
const TermsConditionBody = (props) => {
	const { sendTermsAndCondition } = TermsandConditionsMutation();
	const sendEmail = () => {
		sendTermsAndCondition({
			context: { clientName: 'user' },
		});
	};
	return (
		<Box w={'100%'} bg={'#000201'} flex={1}>
			<Box w={'95%'} alignSelf={'center'} flex={1}>
				<Heading
					mt={5}
					textTransform={'capitalize'}
					fontSize={{ lg: '4xl', base: '2xl' }}
					fontWeight={'medium'}
					textAlign={'left'}
					color={'#fff'}
				>
					Terms And Condition
				</Heading>

				<Heading
					my={3}
					textTransform={'capitalize'}
					fontSize={{ lg: 'lg', base: 'sm' }}
					fontWeight={'normal'}
					textAlign={'left'}
					color={'#AFAFAF'}
				>
					Updated Nov 2022
				</Heading>

				<HStack w={'100%'}>
					<Heading
						my={6}
						w={'20%'}
						textTransform={'capitalize'}
						fontSize={{ lg: '2xl', base: 'lg' }}
						fontWeight={'medium'}
						textAlign={'left'}
						color={'#AFAFAF'}
					>
						Accepting Terms
					</Heading>
					<Divider w={'80%'} alignSelf={'center'} bg={'#AFAFAF'} />
				</HStack>

				<Heading
					my={1}
					fontFamily={'Poppins'}
					textTransform={'capitalize'}
					fontSize={{ lg: 'lg', base: 'sm' }}
					fontWeight={'normal'}
					textAlign={'left'}
					color={'#AFAFAF'}
					lineHeight={30}
				>
					Contrary to popular belief, Lorem Ipsum is not simply random text. It
					has roots in a piece of classical Latin literature from 45 BC, making
					it over 2000 years old. Richard McClintock, a Latin professor at
					Hampden-Sydney College in Virginia, looked up one of the more obscure
					Latin words, consectetur, from a Lorem Ipsum passage, and going
					through the cites of the word in classical literature, discovered the
					undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
					1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and
					Evil) by Cicero, written in 45 BC. This book is a treatise on the
					theory of ethics, very popular during the Renaissance. The first line
					of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
					section 1.10.32.
				</Heading>
				<br />
				<Heading
					my={1}
					fontFamily={'Poppins'}
					textTransform={'capitalize'}
					fontSize={{ lg: 'lg', base: 'sm' }}
					fontWeight={'normal'}
					textAlign={'left'}
					color={'#AFAFAF'}
					lineHeight={30}
				>
					There are many variations of passages of Lorem Ipsum available, but
					the majority have suffered alteration in some form, by injected
					humour, or randomised words which don't look even slightly believable.
					If you are going to use a passage of Lorem Ipsum, you need to be sure
					there isn't anything embarrassing hidden in the middle of text. All
					the Lorem Ipsum generators on the Internet tend to repeat predefined
					chunks as necessary, making this the first true generator on the
					Internet. It uses a dictionary of over 200 Latin words, combined with
					a handful of model sentence structures, to generate Lorem Ipsum which
					looks reasonable. The generated Lorem Ipsum is therefore always free
					from repetition, injected humour, or non-characteristic words etc.
				</Heading>
			</Box>
			<HStack
				bg={'#1C1B1B'}
				flex={1}
				position={'absolute'}
				bottom={0}
				// justifyContent={'flex-end'}
				alignSelf={'flex-end'}
				w={'100%'}
				mt={10}
				py={5}
				px={5}
				justifyContent={'space-between'}
			>
				<Button
					variant={'ghost'}
					p={0}
					_hover={{ bg: 'transparent' }}
					_pressed={{ bg: '#00000070' }}
				>
					<HStack mx={5} alignItems={'center'}>
						<EmailIcon
							sx={{ color: '#2EBB55', marginX: '5', alignSelf: 'center' }}
						/>
						<Heading
							mx={2}
							alignSelf={'center'}
							textTransform={'capitalize'}
							fontSize={{ lg: 'xl', base: 'md' }}
							fontWeight={'medium'}
							textAlign={'left'}
							color={'#2EBB55'}
						>
							Send copy to my email
						</Heading>
					</HStack>
				</Button>
				<Button
					onPress={sendEmail}
					bg={'#2EBB55'}
					w={'15%'}
					mx={10}
					borderWidth={1}
					_hover={{
						backgroundColor: 'transparent',
						borderColor: '#2EBB55',
					}}
					alignItems={'center'}
				>
					<Heading
						textTransform={'capitalize'}
						fontSize={{ lg: 'xl', base: 'md' }}
						fontWeight={'normal'}
						textAlign={'left'}
						color={'#fff'}
					>
						I Agree
					</Heading>
				</Button>
			</HStack>
		</Box>
	);
};

export default TermsConditionBody;
