import { VStack, Text, Image, HStack, Box } from 'native-base';
import React from 'react';
import { useState, useCallback } from 'react';
import CircularProgressBar from '../CircularProgressBar';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';

import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import EditFilePopup from '../PopUp/EditFilePopup';

const AudioUpload = (props) => {
  const { uploadFile } = props;
  const [files, setFiles] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [audioName, setAudioName] = useState('');
  const [showUploadPopup, setShowUploadPopup] = useState(false);

  const onUpload = (k, scale, bpm, tags, selectedImage) => {
    uploadFile(files, k, scale, bpm, tags, selectedImage);
  };
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.fileName = file.name;
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.onload = () => {
        setShowUploadPopup(true);
        setFiles(file);
        setAudioName(file.name);
      };
      reader.readAsArrayBuffer(file);
    });
    if (rejectedFiles.length > 0) {
      console.log('invalid filetype');
      toast.error('Invalid Filetype');
      console.log('rejectedFiles', rejectedFiles);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'audio/*': [],
    },
  });
  return (
    <>
      {loading ? (
        <Box style={{ justifyContent: 'center', alignSelf: 'center' }}>
          <CircularProgressBar progress={progress} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position='relative'
            display='inline-flex'
            alignItems='center'
            justifyContent='center'
          >
            <p style={{ color: '#AFAFAF' }}>{progress}%</p>
          </Box>
        </Box>
      ) : (
        <HStack
          justifyContent={'center'}
          alignSelf={'center'}
          backgroundColor={'#1C1B1B'}
          w={{ lg: '90%', base: '90%' }}
          rounded={'lg'}
          height={{ lg: '300px', base: '150px' }}
          {...getRootProps()}
        >
          <VStack
            backgroundColor={'#1C1B1B'}
            alignSelf={'center'}
            w={'90%'}
            height={{ lg: '240px', base: '120px' }}
            justifyContent={'center'}
            borderStyle={'dashed'}
            borderColor={'white'}
            borderWidth='2'
            rounded={'lg'}
          >
            <input
              {...getInputProps()}
              backgroundColor={'#1C1G1B'}
              accept='audio/*'
            />
            {console.log('uploading')}
            <Image
              source={require('../../assets/Icons/files.png')}
              borderColor={'#1C1G1B'}
              //   size={['6', '15', '20']}
              //   size={8}
              h={{ lg: '50px', base: '25px' }}
              w={{ lg: '80px', base: '40px' }}
              py={{ lg: 10, base: 5 }}
              alignSelf={'center'}
              alt='profile'
            />
            <Text
              fontSize={{ lg: '28px', md: '16px', sm: '14px' }}
              color={'#2EBA55'}
              fontWeight={'bold'}
              alignSelf={'center'}
              fontFamily={'Poppins'}
              py={'5'}
            >
              Drag and Drop more Files!
            </Text>
          </VStack>
        </HStack>
      )}
      {showUploadPopup ? (
        <EditFilePopup
          openModal={showUploadPopup}
          closeModal={() => setShowUploadPopup(false)}
          isEdit={false}
          data={{ fileName: audioName }}
          onUpload={onUpload}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default AudioUpload;
