/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Modal,
  Input,
  Radio,
} from 'native-base';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CloseIcon from '@mui/icons-material/Close';

export const EditGoalsModal = (props) => {
  const { showModal, closeModal } = props;

  return (
    <Modal
      isOpen={showModal}
      bg={'#141313 '}
      h={'auto'}
      shadow={7}
      rounded={'lg'}
      w={{ xl: '40%', lg: '40%', base: '90%' }}
      onClose={closeModal}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={40}
    >
      <Box w={'100%'} bg={'#141313'} rounded={'lg'}>
        <HStack
          alignSelf={'center'}
          justifyContent={'center'}
          my={5}
          w={'100%'}
        >
          <Heading
            px={3}
            color={'#fff'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            fontSize={{ lg: '2xl', base: 'lg' }}
            alignSelf={'center'}
          >
            Edit Goal
          </Heading>

          <Button
            variant={'ghost'}
            px={3}
            py={0}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeModal}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>
        <Divider />
        <Box w={'90%'} alignSelf={'center'} my={3}>
          <Box my={3}>
            <Heading
              color={'#AFAFAF'}
              fontWeight={'normal'}
              fontFamily={'Poppins'}
              fontSize={{ lg: '2xl', base: 'lg' }}
            >
              Title
            </Heading>
            <Input
              py={'12px'}
              my={2}
              _important={{
                backgroundColor: 'transparent',
                borderColor: '#afafaf',
                borderWidth: '1',
                _focus: { borderColor: '#afafaf', shadow: 0 },
              }}
              focusOutlineColor={'#afafaf'}
              fontSize={{ lg: '18px', sm: '14px' }}
              borderWidth={1}
              borderColor={'#AFAFAF'}
              color={'#fff'}
            />
          </Box>
          <Box my={3}>
            <Heading
              color={'#AFAFAF'}
              fontWeight={'normal'}
              fontFamily={'Poppins'}
              fontSize={{ lg: '2xl', base: 'lg' }}
            >
              Goal
            </Heading>
            <Input
              py={'12px'}
              my={2}
              multiline={true}
              numberOfLines={3}
              _important={{
                backgroundColor: 'transparent',
                borderColor: '#afafaf',
                borderWidth: '1',
                _focus: { borderColor: '#afafaf', shadow: 0 },
              }}
              focusOutlineColor={'#afafaf'}
              fontSize={{ lg: '18px', sm: '14px' }}
              borderWidth={1}
              borderColor={'#AFAFAF'}
              color={'#fff'}
            />
          </Box>
          <Button
            mt={2}
            mb={5}
            size={'sm'}
            bg='#2EBA55'
            borderWidth={1}
            borderColor='#2EBA55'
            _text={{
              color: '#fff',
              fontSize: {
                lg: '20px',
                md: '18px',
                sm: '16px',
                base: '16px',
              },
              fontFamily: 'Poppins',
            }}
            _hover={{
              bg: 'transparent',
              _text: {
                color: '#2EBA55',
              },
            }}
            onPress={() => {}}
            w={'25%'}
            rounded={'md'}
            alignSelf={'flex-end'}
            _focused={{ bg: '#2EBA55' }}
            _pressed={{ bg: '#2EBA55' }}
          >
            ADD
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
