import { Box, Button, CloseIcon, Heading, HStack, Modal } from 'native-base';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { cancelSubscription } from '../../Graphql_services/SubscriptionMutation';

export function CancelSubscription(props) {
	const { openModal, closeModal } = props;

	const navigate = useNavigate();
	const [CancelUserSubscription] = useMutation(cancelSubscription, {
		context: { clientName: 'user' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (data.cancelSubscription.success) {
				toast.success('Subscription Canceled successfully');
				closeModal();
				localStorage.clear();
				navigate('/signin');
			} else {
				toast.error(data.upgradeSubscription.message);
				localStorage.clear();
				navigate('/signin');
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});

	return (
		<Modal
			isOpen={openModal}
			onClose={closeModal}
			w={{ '2xl': '35%', xl: '45%', md: '50%', base: '90%' }}
			size={'full'}
			alignSelf={'center'}
			justifyContent={'flex-start'}
			mt={40}
		>
			<Box w={'100%'} bg={'#1c1b1b'} rounded={'lg'} py={{ lg: 5, base: 3 }}>
				<HStack
					alignSelf={'center'}
					justifyContent={'space-between'}
					mt={5}
					mb={3}
					w={'90%'}
				>
					<Heading
						w={'100%'}
						color={'#E3E3E3'}
						fontWeight={'medium'}
						fontFamily={'Poppins'}
						alignSelf={'center'}
						textAlign={'center'}
						fontSize={{ xl: '2xl', lg: 'xl', base: 'lg' }}
					>
						Cancel Subscription
					</Heading>

					<Button
						variant={'ghost'}
						px={5}
						py={1}
						position={'absolute'}
						top={0}
						right={0}
						justifyContent={'flex-end'}
						_hover={{ bg: 'transparent' }}
						_pressed={{ bg: 'transparent' }}
						onPress={closeModal}
						alignItems={'center'}
					>
						<CloseIcon sx={{ color: '#AFAFAF' }} />
					</Button>
				</HStack>
				<Heading
					w={'70%'}
					pt={{ lg: 5, base: 3 }}
					color={'#AFAFAF'}
					fontWeight={'normal'}
					fontFamily={'Poppins'}
					alignSelf={'center'}
					textAlign={'center'}
					fontSize={{ xl: '22px', lg: '18px', base: '14px' }}
				>
					Are you sure you want to cancel your subscription?
				</Heading>
				<HStack
					w={'90%'}
					mt={{ lg: 5, base: 3 }}
					alignSelf={'center'}
					justifyContent={'space-between'}
				>
					<Button
						size={'sm'}
						bg='transparent'
						borderWidth={1}
						borderColor='#2EBA55'
						_text={{
							color: '#2EBA55',
							fontSize: {
								lg: '18px',
								md: '16px',
								sm: '12px',
								base: '12px',
							},
							fontFamily: 'Poppins',
						}}
						_hover={{
							bg: '#2EBA55',
							_text: {
								color: '#fff',
							},
						}}
						onPress={closeModal}
						my={5}
						py={2}
						w={'48%'}
						rounded={'md'}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: '#2EBA55' }}
					>
						Keep Plan
					</Button>
					<Button
						size={'sm'}
						bg='#2EBA55'
						borderWidth={2}
						borderColor='#2EBA55'
						_text={{
							color: '#fff',
							fontSize: {
								lg: '18px',
								md: '16px',
								sm: '12px',
								base: '12px',
							},
							fontFamily: 'Poppins',
						}}
						_hover={{
							bg: 'transparent',
							_text: {
								color: '#2EBA55',
							},
						}}
						onPress={() => CancelUserSubscription()}
						my={5}
						py={2}
						w={'48%'}
						rounded={'md'}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{
							bg: '#2EBA55',
							_text: {
								color: '#fff',
							},
						}}
					>
						Cancel Subscription
					</Button>
				</HStack>
			</Box>
		</Modal>
	);
}
