import React from 'react';
import { Heading, Box, VStack, HStack, Image } from 'native-base';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { browserName } from 'react-device-detect';
import styles from './styles.module.css';
const Members = (props) => {
	const { isHeading, isDashboard } = props;
	return (
		<Box my={20}>
			<Heading
				color='#fff'
				textAlign='center'
				fontSize={{ xl: '40px', lg: '36px', base: '14px' }}
				marginTop={{ md: '30px', base: '20px' }}
				marginBottom={{ md: '50px', base: '30px' }}
				fontWeight={700}
				fontFamily='Orbitron'
			>
				Members from Industry Leaders
			</Heading>

			<Box
				my={8}
				w={{ xl: '85%', lg: '92%', md: '97%', base: '98%' }}
				marginX='auto'
			>
				{/* <HStack alignItems='center' justifyContent='center' flexWrap={'wrap'}> */}
				<OwlCarousel
					margin={10}
					nav
					rewind
					autoplay
					slideBy='1'
					slideSpeed={500}
					smartSpeed={100}
					responsive={{ 0: { items: 1 }, 100: { items: 3 }, 600: { items: 3 } }}
				>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member1.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member2.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							// style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member3.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							// style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member4.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member5.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member6.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member7.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member8.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member9.png`)}
						/>
					</div>
					<div className={isDashboard ? `${styles.member} item` : 'item'}>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member10.png`)}
						/>
					</div>
					<div className='item'>
						<Image
							// flex={2}
							mx='auto'
							size={{ md: 240, base: 60 }}
							style={{ transform: 'translate3d(0,0,0)' }}
							resizeMode='contain'
							source={require(`../../assets/member/member11.png`)}
						/>
					</div>
				</OwlCarousel>
				{/* </HStack> */}
			</Box>
		</Box>
	);
};

export default Members;
