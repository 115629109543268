import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';

import {
	Box,
	HStack,
	Text,
	Image,
	Pressable,
	VStack,
	Button,
} from 'native-base';

import FormTitle from '../FormTitle';
import Textfield from '../../Textfield';
import ThirdPartySignIn from '../../ThirdpartySigjnIn/ThirdPartySignIn';
import ButtonComponent from '../../ButtonComponent';
import Validations from '../../Validations/Validations';

import { AuthContext } from '../Layout';
import useForgetPasswordMutation from '../../Hooks/Authentication/SignIn/ForgetPasword/useForgetPassword';
import useVerifyOTP from '../../Hooks/Authentication/SignIn/VerifyOTP/useVerifyOTP';
import useResetPassword from '../../Hooks/Authentication/SignIn/Resetpassword/useResetPassword';
import useLoginMutation from '../../Hooks/Authentication/SignIn/Login/useLoginMutation';

import './../../Auth/Form.css';
import OTPfield from '../OTP/OTPfield';

function SignInForm(props) {
	const { forgetPassword } = useForgetPasswordMutation();
	const { checkResetOtp } = useVerifyOTP();
	const { resetPassword } = useResetPassword();
	const { login } = useLoginMutation();

	const {
		disableBtn,
		activeStep,
		errMsg,
		setErrMsg,
		setActiveStep,
		resetByPhone,
		setResetByPhone,
		otpvalue,
		setIsDisableBtn,
		registredEmail,
		setRegisteredEmail,
		registredphone,
		setRegisteredphone,
		sendotpAgain,
		isEmail,
		setcountdown,
		countdown,
		setSendOtpAgain,

		phonenum,
		setphonenum,
		setIsEmail,
		setOtpValue,
		setPassword,
		countrycode,
	} = useContext(AuthContext);
	const { email_phoneNoValidation, ValidatePassword } = Validations();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const Screen1 = activeStep === 0;
	const Screen2 = activeStep === 1;
	const Screen3 = activeStep === 2;
	const Screen4 = activeStep === 3;
	const Screen5 = activeStep === 4;
	const Screen6 = activeStep === 5;
	const onSubmit = (data) => {
		// if(Screen6){

		// }
		apicall(data);
	};
	const apicall = (data) => {
		if (data.email_phone && data.password) {
			if (email_phoneNoValidation(data)) {
				const isEmail = data.email_phone.includes('@');
				// console.log("dsadsads");
				// isEmail &&
				setIsDisableBtn(true);
				login({
					variables: {
						email: isEmail ? data.email_phone : '',
						password: data.password,
						countrycode: null,
						phoneno: isEmail ? null : Number(data.email_phone),
					},
				});
				return;
			}
		} else if ((!data.email_phone || !data.password) && activeStep === 0) {
			!data.email_phone && setErrMsg('invalid email/phone number');
			!data.password && setErrMsg('Enter password');
			!data.email_phone &&
				!data.password &&
				setErrMsg('enter email and password');
			return;
		}

		if (
			(data.email && activeStep === 1) ||
			(phonenum.length > 7 && phonenum.length < 14 && Screen3 && resetByPhone)
		) {
			if (data.email ? email_phoneNoValidation(data) : phonenum ? true : '') {
				setIsDisableBtn(true);
				forgetPassword({
					variables: {
						email: data.email ? data.email : '',
						countrycode: null,
						phoneno: data.email ? null : Number(phonenum),
					},
				});
				// setActiveStep((prevActiveStep) => prevActiveStep + 1);
				return;
			}
		} else if (
			(!data.email || !data.phone) &&
			(Screen2 || (Screen3 && resetByPhone))
		) {
			setErrMsg(Screen2 ? 'enter email address' : 'Enter  Phone Number');
			return;
		}
		if ((Screen3 && !resetByPhone) || (Screen4 && resetByPhone)) {
			setIsDisableBtn(true);
			checkResetOtp({
				variables: {
					otp: otpvalue,
				},
			});
			// setActiveStep((prevActiveStep) => prevActiveStep + 1);

			return;
		} else if (
			!otpvalue &&
			((Screen3 && !resetByPhone) || (Screen4 && resetByPhone))
		) {
			setErrMsg('invalid otp');
			return;
		}

		if (data.password && data.confirmPassword) {
			if (ValidatePassword(data)) {
				setIsDisableBtn(true);
				resetPassword({
					variables: {
						password: data.password,
						confirmPassword: data.confirmPassword,
						otp: otpvalue,
					},
				});

				return;
			}
		} else if (
			(!data.password || !data.confirmPassword) &&
			(Screen4 || (Screen5 && resetByPhone))
		) {
			setErrMsg('password should not be empty');
			return;
		}

		if ((Screen5 && !resetByPhone) || (Screen6 && resetByPhone)) {
			setResetByPhone(false);
			setOtpValue('');
			setphonenum('');
			setPassword('');
			setActiveStep((prevActiveStep) => prevActiveStep - activeStep);
		}
	};

	const usePhoneNo = () => {
		setIsEmail(false);
		setSendOtpAgain(false);
		setResetByPhone(true); // reset by using phone
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const sendOtpAgain = () => {
		setSendOtpAgain(true);
		forgetPassword({
			variables: {
				email: isEmail ? registredEmail : '',
				countrycode: null,
				phoneno: !isEmail ? Number(phonenum) : null,
			},
		});
	};
	const gotPreviousPage = () => {
		if (resetByPhone && activeStep === 2) {
			setResetByPhone(false);
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		} else if (activeStep === 1) {
			setRegisteredEmail('');
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		}
	};
	useEffect(() => {
		setErrMsg('');
	}, [activeStep, resetByPhone]);
	return (
		<div className='signInContainer'>
			<Box w={'100%'}>
				<Box w={{ sm: '80%', base: '85%' }} alignSelf={'center'}>
					{activeStep > 0 && activeStep < 5 && (
						<Pressable mb={{ sm: '20', base: 10 }} onPress={gotPreviousPage}>
							<Image
								size={5}
								resizeMode='contain'
								source={require('./../../../assets/SignIn/ArrowLeft.png')}
							/>
						</Pressable>
					)}
					{activeStep === 5 && <Box mb={{ sm: '20', base: 10 }}></Box>}
					<FormTitle
						textCenter={true}
						color={'#fff'}
						subtitle={props.subtitle}
						title={props.title}
						mb={5}
					/>

					{((resetByPhone && Screen4) || (Screen3 && !resetByPhone)) && (
						<OTPfield />
					)}
					{((resetByPhone && Screen4) || (Screen3 && !resetByPhone)) && (
						<Text
							mt={2}
							fontFamily='poppins'
							textTransform='capitalize'
							color='#FF0000'
							fontSize={{ lg: '1.1rem', md: '0.9rem', base: '.9rem' }}
						>
							{errMsg}
						</Text>
					)}

					{(Screen5 || Screen6) && <Box my={{ sm: 10, base: 5 }}></Box>}
					{(Screen1 ||
						Screen2 ||
						(Screen4 && !resetByPhone) ||
						(Screen5 && resetByPhone) ||
						(Screen3 && resetByPhone)) && (
						<div
							style={{ position: 'relative' }}
							className={Screen1 ? 'textfieldsSignIn' : ''}
						>
							<VStack space={''}>
								<Box mb={{ sm: 0, base: 3 }}>
									<Textfield
										color={'#fff'}
										control={control}
										label={
											Screen1
												? 'email/phone number'
												: Screen2
												? 'email'
												: Screen4 || (Screen5 && resetByPhone)
												? 'password'
												: Screen3 && resetByPhone
												? 'phone'
												: ''
										}
										type={props.type}
									/>

									{activeStep === 2 && (
										<Text
											mt={2}
											fontFamily='poppins'
											textTransform='capitalize'
											color='#FF0000'
											fontSize={{ lg: '1.1rem', md: '0.9rem', base: '.9rem' }}
										>
											{errMsg}
										</Text>
									)}
									{(Screen4 || (Screen5 && resetByPhone)) && (
										<Text
											fontFamily={'poppins'}
											fontWeight={'semibold'}
											fontSize={{ md: '1.2rem', base: '0.9rem' }}
											mt={2}
											textAlign='right'
											color={'#AFAFAF'}
										>
											Must be at least 8 characters
										</Text>
									)}
								</Box>
								{(Screen1 ||
									(Screen4 && !resetByPhone) ||
									(Screen5 && resetByPhone)) && (
									<div className='field'>
										<Textfield
											color={'#fff'}
											control={control}
											mt={5}
											label={
												Screen1
													? 'password'
													: Screen4 || (Screen5 && resetByPhone)
													? 'Confirm Password'
													: ''
											}
											type={props.type1}
										/>
									</div>
								)}
							</VStack>
							<HStack
								mt={Screen1 || Screen4 || Screen5 & resetByPhone ? 2 : -3}
								justifyContent={'space-between'}
								alignItems='center'
							>
								{activeStep !== 2 && (
									<Text
										// mt={activeStep>0 && activeStep<3 &7 ?"-5":""}
										mt={3}
										fontFamily='poppins'
										textTransform='capitalize'
										color='#FF0000'
										fontSize={{ lg: '1.1rem', md: '0.9rem', base: '0.9rem' }}
									>
										{errMsg}
									</Text>
								)}
								{activeStep === 0 && (
									//     <Button
									// _hover={{_text:{color:"white"}}}
									//       onPress={() =>
									//         setActiveStep((prevActiveStep) => prevActiveStep + 1)
									//       }
									//       backgroundColor={"transparent"}
									//     >
									//       <Text

									//         fontFamily="poppins"
									//         textAlign={"right"}
									//         fontSize={{ lg: "1.1rem", md: "0.9rem", base: "0.7rem" }}
									//         fontWeight={"semibold"}
									//         textTransform={"capitalize"}
									//         color={"#2EBB55"}
									//         underline

									//       >
									//         forgot password?
									//       </Text>
									//     </Button>
									<Button
										zIndex={1}
										_hover={{
											backgroundColor: 'transparent',

											_text: { color: '#fff' },
										}}
										_text={{
											fontSize: { lg: '1.1rem', md: '0.9rem', base: '0.9rem' },
											fontWeight: 'semibold',
											textAlign: 'right',
											color: '#2EBB55',
											textTransform: 'capitalize',
										}}
										backgroundColor={'transparent'}
										borderWidth={0}
										onPress={() =>
											setActiveStep((prevActiveStep) => prevActiveStep + 1)
										}
									>
										{'forgot password?'}
									</Button>
									// <ButtonComponent
									// 	onpress={() =>
									// 		setActiveStep((prevActiveStep) => prevActiveStep + 1)
									// 	}
									// 	flag={true}
									// 	isText={true}
									// 	borderwidth={0}
									// 	backgroundColor={'transparent'}

									// 	textProperties={{
									// 		fontSize: { lg: '1.1rem', md: '0.9rem', base: '0.9rem' },
									// 		fontWeight: 'semibold',
									// 		textAlign: 'right',
									// 		color: '#2EBB55',
									// 		textTransform: 'capitalize',
									// 	}}
									// 	text={'forgot password?'}
									// />
								)}
							</HStack>
						</div>
					)}

					<ButtonComponent
						textProperties={{
							color: '#E3E3E3',
							fontWeight: 'semibold',
							fontSize: { xl: '1.2rem', md: '1rem', base: '1rem' },
							fontFamily: 'poppins',
							textTransform: 'capitalize',
						}}
						height={{ lg: '45px', md: '35px', base: '48px' }}
						backgroundColor={'#2EBB55'}
						isDisabled={disableBtn ? true : false}
						mt={{ md: 10, base: 5 }}
						mb={10}
						flag={false}
						text={props.btntext}
						onpress={handleSubmit(onSubmit)}
					></ButtonComponent>

					{(Screen2 ||
						(Screen3 && !resetByPhone) ||
						(Screen4 && resetByPhone)) && (
						<HStack
							justifyContent={'center'}
							mt={5}
							space={1}
							alignItems={'center'}
						>
							<Text
								fontFamily='poppins'
								fontSize={{ lg: '1.1rem', md: '0.9rem', base: '0.8rem' }}
								textTransform={'capitalize'}
								color={'#fff'}
							>
								{Screen2 ? 'use' : 'Didn’t recieve the code?'}

								{/* {Screen2
                  ? "use"
                  : Screen3
                  ? "Didn’t recieve the Email?"
                  : Screen4 && resetByPhone
                  ? "Didn’t recieve the code?"
                  : ""} */}
							</Text>
							{/* <Pressable
                onPress={
                  Screen2
                    ? usePhoneNo
                    : sendOtpAgain
                
                }
              >
                <Text
                  fontFamily="poppins"
                  fontSize={{ lg: "1.1rem", md: "0.9rem", base: "0.5rem" }}
                  color="#2EBB55"
                  textTransform="capitalize"
                  fontWeight="semibold"
                  underline
                >
                  {Screen2
                    ? "phone number":"Click to resend"
                    }
                </Text>
              </Pressable> */}

							<Button
								onPress={Screen2 ? usePhoneNo : sendOtpAgain}
								// flag={false}
								// isText={true}
								textTransform={'capitalize'}
								px={1}
								borderWidth={0}
								backgroundColor={'transparent'}
								_text={{
									color: '#2EBB55',
									fontSize: { lg: '1.1rem', md: '0.9rem', base: '0.8rem' },
									fontFamily: 'poppins',
								}}
								_hover={{
									_text: { color: '#fff' },
								}}
								// textProperties={{
								//   fontFamily: "poppins",
								//   fontSize: { lg: "1.1rem", md: "0.9rem", base: "0.8rem" },
								//   fontWeight: "semibold",
								//   textAlign: "right",
								//   color: "#2EBB55",
								//   textTransform: "capitalize",
								// }}
								// text={Screen2 ? "phone number" : "Click to resend"}
							>
								{Screen2 ? 'phone number' : 'Click to resend'}
							</Button>

							{activeStep === 1 && (
								<Text
									fontFamily='poppins'
									fontSize={{ lg: '1.1rem', md: '0.9rem', base: '0.9rem' }}
									textTransform={'capitalize'}
									color={'#fff'}
								>
									instead
								</Text>
							)}
						</HStack>
					)}
					{/* {activeStep === 0 && (
            <Box mt={5}>
              <ThirdPartySignIn flag={true} color={"#fff"} />
            </Box>
          )} */}
				</Box>
			</Box>
		</div>
	);
}

export default SignInForm;
