import React from 'react';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import ConnectMe from '../../components/Socials/ConnectMe/ConnectMe';
const Favourites = () => {
	return (
		<Sidebar
			activeText='Social'
			activeSubText='ConnectMe'
			childComponent={<ConnectMe />}
		/>
	);
};
export default Favourites;
