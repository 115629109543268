import React from 'react';
import { Hidden, Link } from 'native-base';
// import headerLogo from "../../assets/headersAa/headerlogo.jpg";
import { useScrollTo } from 'react-use-window-scroll';
import {
	Box,
	Container,
	Image,
	Flex,
	Spacer,
	Button,
	Menu,
	Pressable,
	HamburgerIcon,
} from 'native-base';
import styles from './styles.module.css';
import { Dimensions } from 'react-native-web';
import musicNote from '../../assets/headers/musicnote.png';
import Logo from '../Icons/Logo';
import LogoWithoutText from '../Icons/LogoWithoutText';
import Instagram from '../Icons/Instagram';
import Twitter from '../Icons/Twitter';

const HeaderComp = (props) => {
	const scrollTo = useScrollTo();
	const width = Dimensions.get('window').width;
	const { homeOnClickRef, scrollFtn } = props;

	return (
		<Box backgroundColor='#1C1B1B' h='80px' maxWidth={'100%'}>
			<Container marginX='auto' w='100%'>
				<Flex
					direction='row'
					alignItems='center'
					h='80px'
					w='100%'
					justifyContent='space-between'
				>
					<Box width={{ xl: 'auto', base: '20%' }}>
						<Link href='#home'>
							<Hidden from='base' till='md'>
								{/* <Logo /> */}
								<div className={styles.headerLogoDesktop}>
									<LogoWithoutText />
								</div>
							</Hidden>
							<Hidden from='md'>
								<LogoWithoutText />
							</Hidden>
						</Link>
					</Box>
					<Hidden from='base' till='md'>
						<Box>
							<Box>
								<Flex direction='row' justify='center'>
									<Link
										href='/#home'
										textDecoration='none'
										textTransform='uppercase'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'center'}
											alignSelf={'center'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Home
										</Button>
									</Link>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									{/* <Link
										href='/#features'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											onPress={() => scrollFtn(homeOnClickRef)}
											justifyContent={'center'}
											alignSelf={'center'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											features
										</Button>
									</Link> */}
									<Link
										href='/#demo'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											onPress={() => scrollFtn(homeOnClickRef)}
											justifyContent={'center'}
											alignSelf={'center'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Demo
										</Button>
									</Link>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									{/* <Link
										href='/publishing'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'center'}
											alignSelf={'center'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											publishing
										</Button>
									</Link> */}
									{/* <Link
										href='/distribution'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'center'}
											alignSelf={'center'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Distribution
										</Button>
									</Link> */}
									<Link
										href='/blog/'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'center'}
											alignSelf={'center'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											blog
										</Button>
									</Link>
									{/* <Link
										href='/credits'
										textTransform='uppercase'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'center'}
											alignSelf={'center'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='uppercase'
										>
											Credits
										</Button>
									</Link> */}
								</Flex>
							</Box>
						</Box>
						<Box justifyContent={'flex-end'}>
							<Flex direction='row' justify='flex-end' alignItems={'center'}>
								<Link href='https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw'>
									<div className={styles.twitter}>
										<Twitter />
									</div>
								</Link>{' '}
								&nbsp;&nbsp;
								<Link href='https://instagram.com/unitedmarket?utm_medium'>
									<div className={styles.intagram}>
										<Instagram />
									</div>
								</Link>
								&nbsp;&nbsp;&nbsp;
								<Link href='/signin'>
									<button className='loginHeaderBtn'>Login</button>
								</Link>{' '}
								&nbsp;
								<Link href='/signup'>
									<button
										// data-tf-popup='F9YFcKqF'
										// data-tf-iframe-props='title=Composition Collection'
										// data-tf-medium='snippet'
										className='submitMusic'
									>
										{/* <Image
											size={{
												xl: '20px',
												lg: '20px',
												md: '10',
												sm: '10',
												base: '10',
											}}
											// width='183'
											//height='40'
											resizeMode='contain'
											source={musicNote}
										/> */}
										Sign Up
									</button>
								</Link>
							</Flex>
						</Box>
					</Hidden>

					<Hidden from='md'>
						<Box w={'80%'} justifyContent='flex-end'>
							<Menu
								overflow={'hidden'}
								backgroundColor='rgba(0,0,0,0.7)'
								top='20px'
								right='0'
								//backgroundColor='rgba(256,0,256,0.7)'
								width={`${width}px`}
								trigger={(triggerProps) => {
									return (
										<Pressable
											alignSelf={'flex-end'}
											accessibilityLabel='More options menu'
											{...triggerProps}
										>
											<HamburgerIcon justifyContent='flex-end' />
										</Pressable>
									);
								}}
							>
								{/* <Link href="#home"> */}
								<Menu.Item p={0} m={0} backgroundColor={'transparent'}>
									<Link
										href='/#home'
										textDecoration='none'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'flex-start'}
											variant={'ghost'}
											p={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Home
										</Button>
									</Link>
								</Menu.Item>
								<Menu.Item p={0} m={0} backgroundColor={'transparent'}>
									<Link
										href='/#demo'
										textDecoration='none'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											onPress={() =>
												scrollTo({ top: 1200, left: 0, behavior: 'smooth' })
											}
											justifyContent={'flex-start'}
											variant={'ghost'}
											px={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											demo
										</Button>
									</Link>
								</Menu.Item>
								{/* <Menu.Item p={0} m={0} backgroundColor={'transparent'}>
									<Link
										href='/#features'
										textDecoration='none'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											onPress={() =>
												scrollTo({ top: 1700, left: 0, behavior: 'smooth' })
											}
											justifyContent={'flex-start'}
											variant={'ghost'}
											px={0}
											py={2}
											m={0}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											features
										</Button>
									</Link>
								</Menu.Item> */}
								{/* <Menu.Item p={0} m={0} backgroundColor={'transparent'}>
									<Link
										href='/publishing'
										textDecoration='none'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'flex-start'}
											variant={'ghost'}
											px={0}
											m={0}
											py={2}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Publishing
										</Button>
									</Link>
								</Menu.Item>
								<Menu.Item p={0} m={0} backgroundColor={'transparent'}>
									<Link
										href='/distribution'
										textDecoration='none'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'flex-start'}
											variant={'ghost'}
											px={0}
											m={0}
											py={2}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Distribution
										</Button>
									</Link>
								</Menu.Item> */}
								<Menu.Item p={0} m={0} backgroundColor={'transparent'}>
									<Link
										href='/blog/'
										textDecoration='none'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'flex-start'}
											variant={'ghost'}
											px={0}
											m={0}
											py={2}
											backgroundColor='transparent'
											_text={{
												color: '#fff',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#2EBA55',
												},
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Blogs
										</Button>
									</Link>
								</Menu.Item>
								<Menu.Item p={0} m={0} backgroundColor={'transparent'}>
									<Link
										href='/signin'
										textDecoration='none'
										textTransform='capitalize'
										_text={{ color: '#E3E3E3', textDecoration: 'none' }}
										marginX={5}
									>
										<Button
											justifyContent={'flex-start'}
											variant={'ghost'}
											px={5}
											m={0}
											py={2}
											backgroundColor='#fff'
											_text={{
												color: '#2EBA55',
												fontSize: { md: '14px', sm: '14px', base: '14px' },
												fontWeight: '500',
											}}
											_hover={{
												_text: {
													color: '#fff',
												},
												backgroundColor: '#2EBA55',
											}}
											w={'100%'}
											textTransform='capitalize'
										>
											Login
										</Button>
									</Link>
								</Menu.Item>
								<Menu.Item backgroundColor={'transparent'}>
									<Link href='/signup' marginX={2}>
										<button
											// data-tf-popup='F9YFcKqF'
											// data-tf-iframe-props='title=Composition Collection'
											// data-tf-medium='snippet'
											className='submitMusic-mob'
										>
											{/* <Image
												size={{
													xl: '20px',
													lg: '20px',
													md: '10',
													sm: '10',
													base: '5',
												}}
												// width='183'
												//height='40'
												resizeMode='contain'
												source={musicNote}
											/> */}
											Sign Up
										</button>
										{/* <Button
											data-tf-popup='QgvSuk10'
											data-tf-iframe-props='title=Submit music'
											data-tf-medium='snippet'
											justifyContent={'flex-start'}
											borderColor='#2EBA55'
											borderWidth={1}
											variant={'ghost'}
											px={2}
											m={0}
											py={2}
											backgroundColor='#2EBA55'
											_text={{
												color: '#fff',
												fontSize: { md: '16px', sm: '14px', base: '14px' },
											}}
											_hover={{
												backgroundColor: 'transparent',
												_text: {
													color: '#2EBA55',
												},
											}}
											h='35px'
											leftIcon={
												<Image
													size={{
														xl: '10px',
														lg: '10px',
														md: '10px',
														sm: '10px',
														base: '15px',
													}}
													height='15'
													resizeMode='contain'
													source={musicNote}
												/>
											}
										>
											Submit Music
										</Button> */}
									</Link>
								</Menu.Item>
							</Menu>
						</Box>
					</Hidden>
				</Flex>
			</Container>
		</Box>
	);
};

export default HeaderComp;
