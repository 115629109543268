import React,{useContext} from 'react'

import { useMutation } from '@apollo/client'
import { AuthContext } from '../../../../Auth/Layout'
import { ResetPasswordSignIn } from './Mutation'
import { Notification } from '../../../../NotifyMsg/Notification'
const  useResetPassword=()=> {
    const {
        activeStep,
        setActiveStep,    
        setIsDisableBtn,
      } = useContext(AuthContext);
const [resetPassword]=useMutation(ResetPasswordSignIn,{
    onCompleted(data){
        if (data?.resetPassword?.success) {
            
                  Notification("success", data.resetPassword.message);
                  setTimeout(() => {
                
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                 setIsDisableBtn(false)
                  }, 1400);
                } else {
                  console.log("success")
                  Notification("error", data.resetPassword.message);
                  setActiveStep(activeStep);
               setIsDisableBtn(false)
                }


    }
})
return {resetPassword}

}

export default useResetPassword