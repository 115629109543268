import { gql } from '@apollo/client';

export const UpdateProfile = gql`
	mutation ($userName: String!, $profilePic: String, $coverPic: String) {
		updateProfile(
			Updateprofilebody: {
				userName: $userName
				profilePic: $profilePic
				coverPic: $coverPic
			}
		) {
			success
			message

			updatedUser {
				firstname
				lastname
				email
				userName
				credits
				accolades
				genre
				aboutMe
				beatSpecialties {
					name
				}
				loopSpecialties {
					name
				}
				profilePic
				coverPic
				experience {
					title
					description
					profileHeadline
					companyName
					startDate
					endDate
				}
			}
		}
	}
`;
