
import React,{useState} from 'react'
import axios from 'axios';
import {
	Grid,
	Select,
	FormControl,
	MenuItem,
	TextField,
	Box,
	Menu,
	Button,
	Autocomplete,
} from '@mui/material';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from 'mui-datatables';

import styles from  "./styles.module.css"

import { useEffect } from 'react';
import { Loader } from '../../../Loader';
import { getLogo } from '../../../../helpers';
function PlatFormDropDown({setPlatform}) {
    const [platformList, setplatformList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [activePlatformList, setActivePlatformList] = useState('Spotify');
	
	
    const handleChange = (event) => {
		setActivePlatformList(event.target.value);
		setPlatform(event.target.value)
	};
    
    const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};
	// const CustomPaper = (props) => {
	// 	return (
	// 		<div className={styles.customPaper}>
	// 			<Paper {...props} />
	// 		</div>
	// 	);
	// };
	const getPlatfrom = async () => {
		try {
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/chart/song/platforms`
			);
			const response = await axios(config);
			const { items } = response.data;
			let platform = items.map((item) => {
				return item.name;
			});
			if (platform) {
				setplatformList(platform);
			}
		} catch (err) {
			console.log(err);
		}
	};

    useEffect(()=>{
getPlatfrom()
    },[])
  return (
	// minWidth: [30, 120, 240]
<FormControl sx={{width:"100%"}} className={styles.PlatFormDropDown}  >
<Select
									defaultValue={activePlatformList}
									onChange={handleChange}
									displayEmpty
									className={styles.customDropdown}
									//inputProps={{ 'aria-label': 'Without label' }
									inputProps={{
										MenuProps: {
											MenuListProps: {
												sx: {
													backgroundColor: '#1c1b1b',
													color: '#afafaf',
												},
											},
										},
									}}
								>
									{platformList.map((item, index) => (
										<MenuItem 
										
										sx={{  
											"&:focus": {
												background: "#2EBB55",
												color: "white"
												},
											 "&:hover": {
												  background: "#AFAFAF",
												  color: "white"
												  }
											}}
										value={item} key={index}>
											{item}
										</MenuItem>
									))}
								</Select>

				
			
							</FormControl>
  )
}

export default PlatFormDropDown