import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import SideBar from '../../../components/SideBar/SideBar';
import { Loader } from '../../../components/Loader';
import { DashboardHeader } from '../../../components/Headers';
import Heart from './../../../assets/Icons/heart.png';
import activeHeart from '../../../assets/Icons/greenHeart.png';
import arrowBack from '../../../assets/Icons/arrowLeft.png';
import styles from './styles.module.css';
import { GETFAVOURITESONGS } from '../../../components/Hooks/TalentDashboard/Songs/GetFavouriteSONG/Query';
import useRemoveFavouriteSongMutation from '../../../components/Hooks/TalentDashboard/Songs/RemoveFavouriteSong/useRemoveFavouriteSongMutation';
import useFavouriteSongMutation from '../../../components/Hooks/TalentDashboard/Songs/AddTofavouriteSongs/useFavouriteSongMutation';
import TalentDashboardLayout from '../../../components/TalentDashboard/TalentDashboardLayout';
import Overview from '../../../components/TalentDashboard/Tabs/OverView/Overview';
import Trends from '../../../components/TalentDashboard/Tabs/Trends/Trends';
import Playlists from '../../../components/TalentDashboard/Tabs/Playlists/Playlists';
import Charts from '../../../components/TalentDashboard/Tabs/Charts/Charts';
import TabHeader from '../../../components/TDTabHeader/TabHeader';
import Airplay from '../../../components/TalentDashboard/Tabs/Airplay/Airplay';
import AirPlayComp from '../../../components/TalentDashboard/Tabs/Airplay/AirPlayComp';
import LinePlatformGraph from '../../../components/TalentDashboard/TalentPlatformGraph/LinePlatformGraph';
import { borderBottom } from '@mui/system';
import AirplayTable from '../../../components/TalentDashboard/Tabs/Airplay/AirplayTable';
import CumulativeTable from '../../../components/TalentDashboard/Tabs/Charts/CumulativeTable';
import TalentPlatformGraph from '..//..//../components/TalentDashboard/TalentPlatformGraph/TalentPlatformGraph.js';
import { Divider, HStack, Text, Box, Image } from 'native-base';
import DetailsChart from '../../../components/TalentDashboard/Tabs/Charts/DetailsChart';
import TalentOpportunity from '../../../components/TalentDashboard/TalentOpportunity/TalentOpportunity';
import { ToastContainer } from 'react-toastify';
import ArtistSongs from '../../../components/TalentDashboard/ArtistSongs/ArtistSongs';
import ArtistCharts from '../../../components/TalentDashboard/ArtistCharts/ArtistCharts';
const ArtistProfile = () => {
	const { data } = useQuery(GETFAVOURITESONGS, {
		context: { clientName: 'user' },
	});

	const [isLoading, setIsLoading] = useState(false);

	const [headerData, setHeaderData] = useState({});
	const [value, setValue] = useState(0);
	const [tabOptions, setTabOptions] = useState([]);
	const [activeState, setActiveState] = useState(0);
	const [activeSubtab, setactiveSubtab] = useState(0);

	const navigate = useNavigate();
	const handleChange = (value) => {
		console.log(value.target.id);
		setValue(value.target.id);
		// if (newValue === 0) {
		// 	navigate('/talent-dashboard/charts');
		// }
	};
	const a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};
	const handleBack = () => {
		navigate(-1);
	};

	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};
	const getArtist = async () => {
		const config = configOptions(
			'get',
			`https://sandbox.api.soundcharts.com/api/v2.9/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396`
		);
		const response = await axios(config);
		const { object } = response.data;
		const { name, imageUrl, uuid } = object;
		setHeaderData({ name, imageUrl, uuid });
	};

	useEffect(() => {
		getArtist();
		setTabOptions(['OVERVIEW', 'CHARTS', 'PLAYLIST', 'SONG', 'AIRPLAY']);
	}, []);

	return (
		<Grid container className={styles.bgBlack}>
			<ToastContainer />
			<Grid
				item
				display={{ xs: 'none', lg: 'block' }}
				lg={2}
				className={styles.rightBorder}
			>
				<SideBar />
			</Grid>

			<Grid item xs={12} lg={10}>
				<Grid item xs={12} md={12} className={styles.bottomBorder}>
					<DashboardHeader />
				</Grid>

				<Grid className={styles.content}>
					<div className={styles.searchDiv}></div>

					<Grid className={''}>
						<Grid className={styles.content}>
							<Grid
								container
								direction='row'
								alignItems={'center'}
								justifyContent={'space-between'}
							>
								<Grid item xs={10} className={styles.headingDiv}>
									<img
										src={arrowBack}
										alt='back icon'
										className={styles.backIcon}
										onClick={() => handleBack()}
									/>{' '}
									&nbsp; &nbsp;
									<div className={styles.flex}>
										<img
											src={headerData?.imageUrl}
											alt={headerData?.name}
											className={styles.songCover}
										/>
										<div>
											<p className={styles.songName}>{headerData?.name}</p>
											<p className={styles.creditName}>
												{headerData?.creditName}
											</p>
										</div>
									</div>
								</Grid>

								<Grid
									display={'flex'}
									justifyContent={'end'}
									item
									xs={2}
									className={styles.iconParentDiv}
								></Grid>
							</Grid>

							<Grid container className={styles.optionDiv}>
								<Box width={'100%'}>
									<Tabs
										TabIndicatorProps={{ style: { background: 'none' } }}
										value={value}
										aria-label='lab API tabs example'
										scrollButtons={false}
										onChange={handleChange}
										sx={{ borderBottom: 1, borderColor: '#AFAFAF' }}
									>
										{tabOptions.map((item, index) => (
											<>
												<Tab
													key={index}
													label={item}
													id={index}
													onClick={() => setValue(index)}
													//onChange={() => handleChange(index)}
													className={styles.option}
													value={index}
													wrapped
													sx={{
														borderBottom: value === index ? 4 : 0,
														borderColor: '#2EBB55',
													}}
													iconPosition={'right'}
													icon={
														index === 4 ? (
															<Image
																height={'15px'}
																width={'15px'}
																resizeMode={'contain'}
																source={require('../../../assets/Icons/arrrowTabs.png')}
															/>
														) : (
															''
														)
													}
												/>
												{/* {index === 2 && (
                          
                        )} */}
											</>
										))}

										{/* <Divider
											alignSelf={'end'}
											borderColor={'#AFAFAF'}
											borderBottomWidth={1}
											w={'80%'}
										/> */}
									</Tabs>
									{/* <Divider
										alignSelf={'center'}
										borderColor={'#AFAFAF'}
										borderBottomWidth={4}
										w={"100%"}></Divider> */}

									{value === 4 && (
										<Box py={4} bg={'#1C1B1B'}>
											<HStack
												space={{ md: '5rem', base: '1rem' }}
												justifyContent={'center'}
												flexGrow={1}
												flexWrap={'wrap'}
											>
												<Text
													fontFamily={'poppins'}
													fontSize={{ md: 'lg', base: 'sm' }}
													textTransform={'capitalize'}
													color={'#AFAFAF'}
													cursor='pointer'
													borderBottomWidth={activeSubtab === 0 ? 2 : 0}
													borderColor={'#2EBB55'}
													onClick={() => setactiveSubtab(0)}
												>
													{value === 2 ? 'commulative table' : 'insights'}
												</Text>
												<Text
													fontFamily={'poppins'}
													fontSize={{ md: 'lg', base: 'sm' }}
													textTransform={'capitalize'}
													color={'#AFAFAF'}
													cursor='pointer'
													borderBottomWidth={activeSubtab === 1 ? 2 : 0}
													borderColor={'#2EBB55'}
													onClick={() => setactiveSubtab(1)}
												>
													{value === 2 ? 'details' : 'commulative table'}
												</Text>
												<Text
													cursor='pointer'
													fontFamily={'poppins'}
													fontSize={{ md: 'lg', base: 'sm' }}
													textTransform={'capitalize'}
													color={'#AFAFAF'}
													borderColor={'#2EBB55'}
													borderBottomWidth={activeSubtab === 2 ? 2 : 0}
													onClick={() => setactiveSubtab(2)}
												>
													{value === 2 ? '' : 'live feed'}
												</Text>
											</HStack>
										</Box>
									)}
								</Box>
							</Grid>
							{value === 0 ? (
								<div className={styles.loaderDiv}>
									<Overview isArtist={true} />
								</div>
							) : value === 1 ? (
								<div className={styles.loaderDiv}>
									<DetailsChart />
								</div>
							) : value === 2 ? (
								<div className={styles.loaderDiv}>
									<ArtistCharts />
								</div>
							) : value === 3 ? (
								<div className={styles.loaderDiv}>
									<ArtistSongs />
								</div>
							) : value === 4 && activeSubtab === 0 ? (
								<div className={styles.loaderDiv}>
									<TalentOpportunity />
								</div>
							) : value === 4 && activeSubtab === 1 ? (
								<AirPlayComp />
							) : value === 4 && activeSubtab === 2 ? (
								<TalentPlatformGraph />
							) : (
								''
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default ArtistProfile;
