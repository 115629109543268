import { gql } from '@apollo/client';

export const cancelSubscription = gql`
  mutation {
    cancelSubscription {
      success
      message
      isTokenExpired
      user {
        firstname
        lastname
      }
    }
  }
`;

export const upgradeSubscription = gql`
  mutation {
    upgradeSubscription {
      success
      message
      isTokenExpired
    }
  }
`;

export const downgradeSubscription = gql`
  mutation {
    downgradeSubscription {
      success
      message
      isTokenExpired
    }
  }
`;
