/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Box, Divider, HStack, Image } from 'native-base';
import React from 'react';

import Footer from '../Footer/Footer';
import GetNotified from '../Getnotified/GetNotified';
import HeaderComp from '../Header/HeaderComp';
import PulishingBanner from './PublishingBanner';
import Servicers from './Servicers';
// import { ClaimFAQ } from '../ClaimFAQ/ClaimFAQ';
// import ClaimHeader from '../ClaimHeader/ClaimHeader';
// import ClaimLatest from '../ClaimLatest/ClaimLatest';
// import ClaimProfileBanner from '../ClaimProfileBanner/ClaimProfileBanner';
// import ClaimProfileFeature from '../ClaimProfileFeature/ClaimProfileFeature';
// import ClaimSerivces from '../ClaimServices/ClaimServices';
// import CollabMasonry from '../CollabMasonry/CollabMasonry';

function Publishing() {
  return (
    <Box w={'100%'} bg={'#000201'}>
      <HeaderComp />
      <PulishingBanner />
      <Servicers />
      <GetNotified />
      <Footer />
    </Box>
  );
}

export default Publishing;
