import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Loader } from '../../Loader';
import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	HStack,
	Text,
	Image,
	Input,
	Select,
} from 'native-base';

import useHomeMutation from '../../Hooks/home/useHomeMutation';
import ImageUpload from '../ImageUpload';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import Textfield from '../../Textfield';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import uploadicon from './../../../assets/LoginScreens/uploadicon.png';
import styles from './../styles.module.css';
import DefaultProfile from '../../../assets/Icons/profile.png';
import defaultCover from '../../../assets/Home/defaultCover.png';
import { ToastContainer } from 'react-toastify';
import EditProfile from '../../Icons/EditProfile';

const InfoBody = (props) => {
	const { updateProfile } = useHomeMutation();
	const token = localStorage.getItem('token');
	const [service, setService] = useState('');
	const [userName, setUserName] = useState('');
	const [isLoadingProfile, setIsLoadingProfile] = useState(false);
	const [isLoadingCover, setIsLoadingCover] = useState(false);
	const [coverPicture, setcoverpicture] = useState('');
	const [profilePicture, setprofilePicture] = useState('');
	const [erorMessage, setErrorMessage] = useState('');
	const types = ['image/jpeg', 'image/png'];

	useEffect(() => {
		const accountType = localStorage.getItem('accountType');
		const username = localStorage.getItem('userName');
		const cover = localStorage.getItem('coverPhoto');
		const profilePic = localStorage.getItem('profilePic');

		if (username) {
			if (
				username !== null &&
				username !== 'null' &&
				username !== 'undefined'
			) {
				setUserName(username);
			} else {
				setUserName('');
			}
		}
		if (accountType) {
			setService(accountType);
		}
		// if (cover) {
		// 	setcoverpicture(cover);
		// }
		if (profilePic) {
			setprofilePicture(profilePic);
		}
		// if (accountType === 'A & R') {
		// 	setService('A & R');
		// } else if (accountType === 'Manager') {
		// 	setService('Manager');
		// } else if (accountType === 'Artist') {
		// 	setService('Artist');
		// } else if (accountType === 'Music Producer') {
		// 	setService('Music Producer');
		// } else if (accountType === 'Label') {
		// 	setService('Label');
		// }
	}, []);
	const handleChange = (e) => {
		setUserName(e.target.value);
	};
	const handleSave = () => {
		if (userName === '') {
			setErrorMessage('Enter user name');
			return;
		} else {
			localStorage.setItem('profilePic', profilePicture);
			// localStorage.setItem('coverPhoto', coverPicture);
			localStorage.setItem('userName', userName);
			// localStorage.setItem('accounType', service);
			updateProfile({
				variables: {
					userName: userName,
					profilePic: profilePicture,
					coverPic: null,
				},
				context: { clientName: 'user' },
			});
		}
	};
	const verifyImageFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0];
			const currentFileType = currentFile.type;

			if (!types.includes(currentFileType)) {
				console.log('not accepted');
				return false;
			}
			return true;
		}
	};

	const handleOnDropLogoImage1 = async (files, rejectedFiles) => {
		const profileData = new FormData();
		if (rejectedFiles && rejectedFiles.length > 0) {
			verifyImageFile(rejectedFiles);
		}
		if (files && files.length > 0) {
			if (verifyImageFile(files)) {
				profileData.append('file', files[0]);
				profileData.append('type', 'Public');
				setIsLoadingProfile(true);
				var config = {
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}/file/uploadprofilepicture`,

					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: profileData,
				};
				axios(config)
					.then(function (response) {
						// console.log(JSON.stringify(response.data));
						// console.log(JSON.stringify(response.data.file.url));
						setprofilePicture(response.data.file.url);
						setIsLoadingProfile(false);
					})
					.catch(function (error) {
						console.log(error);
						setIsLoadingProfile(false);
					});
				return;
			} else {
				setIsLoadingProfile(false);
				// setErrMsg("invalid file type");
				return;
			}
		}
	};

	// const verifyImageFile = (files) => {
	//   if (files && files.length > 0) {
	//     const currentFile = files[0];
	//     const currentFileType = currentFile.type;

	//     if (!types.includes(currentFileType)) {
	//       console.log("not accepted");
	//       return false;
	//     }
	//     return true;
	//   }
	// };

	const handleOnDropCoverImage = async (files, rejectedFiles) => {
		const coverData = new FormData();
		if (rejectedFiles && rejectedFiles.length > 0) {
			verifyImageFile(rejectedFiles);
		}
		if (files && files.length > 0) {
			if (verifyImageFile(files)) {
				coverData.append('file', files[0]);
				coverData.append('type', 'Public');
				setIsLoadingCover(true);
				var config = {
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}/file/uploadprofilepicture`,

					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: coverData,
				};
				axios(config)
					.then(function (response) {
						// console.log(JSON.stringify(response.data));
						// console.log(JSON.stringify(response.data.file.url));
						setcoverpicture(response.data.file.url);
						setIsLoadingCover(false);
					})
					.catch(function (error) {
						console.log(error);
						setIsLoadingCover(false);
					});
				return;
			} else {
				setIsLoadingCover(false);
				// setErrMsg("invalid file type");
				return;
			}
		}
	};
	return (
		<Box w={'100%'} bg={'#000201'} position='relative'>
			<Box w={'95%'} alignSelf={'center'}>
				<Heading
					my={5}
					textTransform={'capitalize'}
					fontSize={{ lg: '4xl', base: '2xl' }}
					fontWeight={'medium'}
					textAlign={'left'}
					color={'#fff'}
				>
					Profile
				</Heading>

				{/* <Box mb={'100px'} position={'relative'}> */}
				<Box
				// bg={'#1C1B1B'}
				// mt={5}
				// justifyContent={'center'}
				// h={{ lg: '300px', md: '200px', base: '200px' }}
				// w={'100%'}
				// rounded={'lg'}
				>
					{/* <div className={styles.coverPhoto}>
							{isLoadingCover ? (
								<div className={styles.loaderCover}>
									<Loader />
								</div>
							) : (
								<Dropzone
									name='dropzone1'
									accept='image/*'
									onDrop={handleOnDropCoverImage}
									multiple={false}
								>
									{({ getRootProps, getInputProps }) => (
										<section className={styles.section}>
											<div {...getRootProps()} className={styles.presentation}>
												<input {...getInputProps()} />

												<div className={styles.UploadIconContainer}>
													<img
														src={coverPicture ? coverPicture : defaultCover}
														alt=''
														className='mb-3'
													/>
													<button
														style={{
															background: '#2ebb55',
															outline: 'none',
															cursor: 'pointer',
															border: 'none',
															color: '#fff',
															fontSize: '1.2rem',
															position: 'absolute',
															top: '50%',
															marginLeft: '-150px',
															marginTop: '-5px',
															left: '50%',
															width: '20%',
															padding: '0.5rem 1rem',
															borderRadius: '5px',
														}}
													>
														<label htmlFor='uploadImg'>
															{!coverPicture ? 'Upload' : 'Edit'}
														</label>
													</button>
												</div>
											</div>
										</section>
									)}
								</Dropzone>
							)}
						</div> */}

					<Box
						// position={'absolute'}
						// zIndex={99999}
						// bottom={{
						// 	sm: isLoadingProfile ? '-110px' : '-70px',
						// 	base: '-50px',
						// }}
						my={10}
						left={{ sm: isLoadingProfile ? '20px' : '20px', base: '20px' }}
						height={isLoadingProfile ? '150px' : '150px'}
						width={isLoadingProfile ? '150px' : '150px'}
						resizeMode='cover'
						alignItems={'center'}
						borderRadius={'50%'}
						justifyContent={'center'}
						src={require('../../../assets/Icons/profile.png')}
					>
						{isLoadingProfile ? (
							<div className={styles.loaderProfile}>
								<Loader />
							</div>
						) : (
							<Dropzone
								name='dropzone1'
								accept='image/*'
								onDrop={handleOnDropLogoImage1}
								multiple={false}
							>
								{({ getRootProps, getInputProps }) => (
									<section className={styles.section}>
										<div className={styles.presentation} {...getRootProps()}>
											<div className={styles.UploadIconContainer}>
												<img
													src={profilePicture ? profilePicture : DefaultProfile}
													style={{
														borderRadius: '50%',
													}}
													className={styles.profilePic}
												/>
												<input {...getInputProps()} />

												<button className={styles.profileEditBtn}>
													<EditProfile />
												</button>
											</div>
										</div>
									</section>
								)}
							</Dropzone>
						)}
					</Box>
				</Box>
				{/* </Box> */}

				<Heading
					my={5}
					textTransform={'capitalize'}
					fontSize={{ lg: 'xl', md: '1rem', base: '1rem' }}
					fontWeight='semibold'
					textAlign={'left'}
					color={'#afafaf'}
				>
					Username
				</Heading>

				<Input
					_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
					_hover={{ borderColor: '#2EBB55' }}
					// onFocus={borderColor:"green"}
					value={userName}
					onChange={handleChange}
					focusOutlineColor='#2EBB55'
					fontFamily='poppins'
					fontSize={{ lg: '18px', md: '16px', base: '14px' }}
					color={'#AFAFAF'}
					// onBlur={onBlur}
					borderColor='#AFAFAF'
					height={{
						xl: '60px',
						base: '45px',
					}}
					// py={3}
					// my={2}
					// _focus={{ bg: "transparent", borderColor: "transparent" }}
					// borderWidth={2}
					// borderColor={"#AFAFAF"}
					// color={"#fff"}
				/>
				<Text
					textTransform='capitalize'
					color='#FF0000'
					fontWeight={'normal'}
					fontFamily='poppins'
					mt={2}
					fontSize={{
						lg: '1.1rem',
						md: '0.9rem',
						base: '0.9rem',
					}}
				>
					{erorMessage}
				</Text>
				{/* <Textfield
          color={"#000"}
          label={props.label}
          control={"control"}
          type={props.type}
        /> */}
				<Heading
					my={5}
					textTransform={'capitalize'}
					fontSize={{ lg: '2xl', base: 'lg' }}
					fontWeight={'medium'}
					textAlign={'left'}
					color={'#afafaf'}
				>
					Role
				</Heading>
				<Input
					editable={false}
					_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
					_hover={{ borderColor: '#2EBB55' }}
					// onFocus={borderColor:"green"}
					value={service}
					focusOutlineColor='#2EBB55'
					fontFamily='poppins'
					fontSize={{ lg: '18px', md: '16px', base: '14px' }}
					color={'#AFAFAF'}
					borderColor='#AFAFAF'
					height={{
						xl: '60px',
						base: '45px',
					}}
				/>
				{/* <div className={styles.generDropdown}>
					<Select
						fontSize={{ lg: '18px', md: '16px', base: '14px' }}
						color={'#AFAFAF'}
						w={'100%'}
						selectedValue={service}
						height={{
							xl: '52.9px',
							base: '45px',
						}}
						defaultValue={localStorage.getItem('accountType').trim()}
						_hover={{ boxShadow: 'none' }}
						accessibilityLabel='Choose Service'
						placeholder='Choose Service'
						_selectedItem={{
							// bg: "teal.600",
							fontSize: 'md',
							endIcon: <CheckIcon size='5' />,
						}}
						mt={1}
						onValueChange={(itemValue) => setService(itemValue)}
					>
						<Select.Item label='Artist' value='Artist' />
						<Select.Item label='Manager' value='Manager' />
						<Select.Item label='Music Producer' value='Music Producer' />
						<Select.Item label='A & R' value='A & R' />
						<Select.Item label='Label' value='Label' />
					</Select>
				</div> */}
				<HStack
					alignSelf={'flex-start'}
					w={'40%'}
					my={10}
					justifyContent={'space-between'}
				>
					<Button
						w={'45%'}
						borderColor='#2EBA55'
						borderWidth={1}
						_pressed={{ bg: '#2EBB55' }}
						backgroundColor='#2EBA55'
						_text={{
							color: '#fff',
						}}
						_hover={{
							backgroundColor: 'transparent',
							_text: {
								color: '#2EBA55',
								fontSize: { md: '16px', sm: '14px', base: '14px' },
							},
						}}
						alignItems={'center'}
						onPress={handleSave}
					>
						<Heading
							textTransform={'capitalize'}
							fontSize={{ lg: 'xl', base: 'md' }}
							fontWeight={'normal'}
							textAlign={'left'}
							color={'#fff'}
						>
							Save
						</Heading>
					</Button>
					{/* <Button
						backgroundColor='#2EBA55'
						borderColor='#2EBA55'
						onPress={() => props.setshowModal(true)}
						borderWidth={1}
						_text={{
							color: '#fff',
						}}
						_hover={{
							backgroundColor: 'transparent',
							_text: {
								color: '#2EBA55',
								fontSize: { md: '16px', sm: '14px', base: '14px' },
							},
						}}
						w={'45%'}
						_pressed={{ bg: '#2EBB55' }}
						alignItems={'center'}
					>
						<Heading
							textTransform={'capitalize'}
							fontSize={{ lg: 'xl', base: 'md' }}
							fontWeight={'normal'}
							textAlign={'left'}
							color={'#fff'}
						>
							Refer musician
						</Heading>
					</Button> */}
				</HStack>
			</Box>
		</Box>
	);
};

export default InfoBody;

/* <FormControl mt={mt}>
  <FormControl.Label
    fontFamily="poppins"
    // mb={2}

    _text={{
      fontSize: { lg: "xl", md: "1rem", base: "1rem" },
      color: color,
      fontFamily: "poppins",
      fontWeight: "semibold",
    }}
    textTransform={"capitalize"}
  >
    {label}
  </FormControl.Label>
  {props.type === "phone" || props.type === "phoneNumber" ? (
    <CountryCodes type={props.type} />
  ) : (
    <Controller
      control={control}
      defaultValue={
        isPassword
          ? Password
          : isregistrationEmail
          ? registredEmail
          : isRegistredphone
          ? registredphone
          : ""
      }
      render={({ field: { onChange, value, onBlur } }) => (
        <Input
          _hover={{ borderColor: "#2EBB55" }}
          // onFocus={borderColor:"green"}
          focusOutlineColor="#2EBB55"
          _focus={{ borderColor: "#2EBB55" }}
          //  _focus={{}}
          // type={props.type === "password" ? "password" : ""}
          onChangeText={onChange}
          _text={{ fontSize: "1rem", fontFamily: "poppins" }}
          fontFamily="poppins"
          fontSize={{ lg: "18px", md: "16px", base: "14px" }}
          color={color}
          onBlur={onBlur}
          borderColor="#AFAFAF"
          height={{
            xl: "60px",
            base: "45px",
          }}
          secureTextEntry={
            show &&
            (props.type === "password" || props.type === "confirmPassword")
              ? true
              : false
          }
          //  InputLeftElement={props.type==="phone" && <CountryCodes/>}
          InputRightElement={
            props.type === "password" || props.type === "confirmPassword" ? (
              <Pressable mr={5} onPress={() => setShow(!show)}>
                {!show && (
                  <Image
                    height={{ md: "25px", base: "18px" }}
                    width={{ md: "28px", base: "20px" }}
                    resizeMode=""
                    source={require("..//assets//LoginScreens/Show.png")}
                  />
                )}
                {show && (
                  <Image
                    height={{ md: "25px", base: "18px" }}
                    width={{ md: "28px", base: "20px" }}
                    resizeMode=""
                    source={require("..//assets//LoginScreens/eye-slash.png")}
                  />
                )}
              </Pressable>
            ) : (
              ""
            )
          }
          value={value}
        />
      )}
      name={type}
    />
  )}
</FormControl>; */
// }
