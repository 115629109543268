import React from 'react';
import { Box, HStack } from 'native-base';
import Analytics from '../../components/GoalsAndAnalytics/Analytics/Analytics';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
export default function Analytic() {
	return (
		<div>
			<Box w={'100%'} bg={'#000201'}>
				<HStack w={'100%'}>
					<Sidebar childComponent={<Analytics />} activeText='Analytics' />
				</HStack>
			</Box>
		</div>
	);
}
