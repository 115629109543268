import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  VStack,
  Image,
  Text,
  Modal,
} from "native-base";
import React, { useRef, useState, useEffect } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import song1 from "../../assets/audioPlayer/song1.png";
import song2 from "../../assets/audioPlayer/song2.png";
import song3 from "../../assets/audioPlayer/song3.png";
import WaveSurfer from "wavesurfer.js";
import FastForwardRoundedIcon from "@mui/icons-material/FastForwardRounded";
import FastRewindRoundedIcon from "@mui/icons-material/FastRewindRounded";
import { ToastContainer, toast } from "react-toastify";
import wavesurfer from "wavesurfer.js";

const AudioPlayer = (props) => {
  console.log("props.url", props.url);

  const [shareModal, setShareModal] = useState(false);
  const [copyLink, setCopyLink] = useState("");
  const song = props.url;
  const songName = props.name;
  const { setCommentBox, setSelectedPoint } = props;
  const audioList = [
    {
      name: "Despacito",
      singer: "Luis Fonsi",
      cover:
        "http://res.cloudinary.com/alick/image/upload/v1502689731/Despacito_uvolhp.jpg",
      musicSrc:
        "https://du6zmi1l7ys6.cloudfront.net/fileupload/scanskill-1670559011635-Dil_Nu.mp3",
    },
    {
      name: "Bedtime Stories",
      singer: "Jay Chou",
      cover:
        "http://res.cloudinary.com/alick/image/upload/v1502375978/bedtime_stories_bywggz.jpg",
      musicSrc:
        "http://res.cloudinary.com/alick/video/upload/v1502375674/Bedtime_Stories.mp3",
    },
    {
      name: "Dorost Nemisham",
      singer: "Sirvan Khosravi",
      cover:
        "https://res.cloudinary.com/ehsanahmadi/image/upload/v1573758778/Sirvan-Khosravi-Dorost-Nemisham_glicks.jpg",
      musicSrc:
        "https://res.cloudinary.com/ehsanahmadi/video/upload/v1573550770/Sirvan-Khosravi-Dorost-Nemisham-128_kb8urq.mp3",
    },
  ];

  const [audioInstance, setAudioInstance] = useState(null);
  const [audioSeek, setAudioSeek] = useState(null);
  const [actionPlay, setActionPlay] = useState(true);
  const [indexPlay, setIndexPlay] = useState(0);
  const [audioUrl, setAudioUrl] = useState(song);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);

  const handlePlay = () => {
    wavesurfer.current.play();
  };
  const handlePause = () => {
    wavesurfer.current.pause();
  };
  const handleSkipBackward = () => {
    wavesurfer.current.skipBackward();
  };
  const handleSkipForward = () => {
    wavesurfer.current.skipForward();
  };
  const handlePlayNext = () => {
    wavesurfer.current.skipForward();
    // if (indexPlay >= 0 && indexPlay != audioList.length - 1) {
    // 	setIndexPlay(indexPlay + 1);
    // 	setAudioUrl(audioList[indexPlay].musicSrc);
    // } else {
    // 	setIndexPlay(0);
    // 	setAudioUrl(audioList[indexPlay].musicSrc);
    // }
  };
  const handlePlayPre = () => {
    // if (indexPlay == 0) {
    // 	setIndexPlay(audioList.length - 1);
    // 	setAudioUrl(audioList[indexPlay].musicSrc);
    // } else if (indexPlay > 0) {
    // 	setIndexPlay(indexPlay - 1);
    // 	setAudioUrl(audioList[indexPlay].musicSrc);
    // }
    wavesurfer.current.skipBackward();
  };

  useEffect(() => {
    setPlay(false);
    //  console.log(audioList.length);
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(audioUrl);
    wavesurfer.current.on("finish ", function () {
      setActionPlay(true);
    });

    wavesurfer.current.on("ready", function () {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();
      // setPlay(true);

      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    wavesurfer.current.on("seek", function () {
      console.log("current time,", wavesurfer.current.getCurrentTime());
      setSelectedPoint(wavesurfer.current.getCurrentTime());
      setCommentBox(true);
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [audioUrl]);

  return (
    <>
      <Box w={"100%"} bg={"#000201"} alignSelf={"center"}>
        {/* <ReactJkMusicPlayer {...options} /> */}

        {/* <Box zIndex={1000}>
<ReactJkMusicPlayer
{...options}
//audioLists={audioList3}
getAudioInstance={(instance) => {
  setAudioInstance(instance);
}}
onAudioSeeked={(instance) =>{
   console.log(instance)
}}
// playIndex={(index)=>{setIndexPlay(index)}}
/>
</Box> */}
        <ToastContainer />

        <HStack
          w={{ xl: "100%", base: "85%" }}
          alignSelf={"center"}
          bg={"#1C1B1B"}
          py={{ xl: 3, lg: 3, md: 2, base: 2 }}
          my={5}
          rounded={"lg"}
          flexWrap={"wrap"}
          flexGrow={1}
        >
          <Box
            w={{ xl: "30%", lg: "30%", md: "30%", base: "90%" }}
            px={3}
            alignSelf={"center"}
          >
            <Heading
              color={"#e3e3e3"}
              fontSize={{ xl: "lg", base: "md" }}
              fontWeight={"medium"}
              fontFamily={"Poppins"}
              textAlign={"left"}
              textTransform={"capitalize"}
            >
              {songName}
            </Heading>
            <Heading
              color={"#e3e3e3"}
              fontSize={{ xl: "sm", base: "xs" }}
              fontWeight={"thin"}
              fontFamily={"Poppins"}
              textAlign={"left"}
              textTransform={"capitalize"}
            >
              {"Singer Name"}
            </Heading>
          </Box>
          <HStack
            w={{ xl: "35%", lg: "35%", md: "35%", base: "90%" }}
            alignSelf={"center"}
            justifyContent={"center"}
            py={{ lg: "0", base: "5" }}
          >
            <Button
              variant={"ghost"}
              p={0}
              px={2}
              onPress={() => {
                //   audioInstance.playPrev();
                // setActionPlay(true);
                handleSkipBackward();
              }}
            >
              <FastRewindRoundedIcon sx={{ color: "#AFAFAF", fontSize: 30 }} />
            </Button>
            <Button
              variant={"ghost"}
              p={0}
              px={2}
              onPress={() => {
                //   audioInstance.playPrev();
                setActionPlay(true);
                handlePlayPre();
              }}
            >
              <SkipPreviousRoundedIcon
                sx={{ color: "#AFAFAF", fontSize: 30 }}
              />
            </Button>
            <Button
              variant={"ghost"}
              p={0}
              onPress={() => {
                //   {
                //     actionPlay ? audioInstance.play() : audioInstance.pause();
                //   }
                setActionPlay(!actionPlay);
                {
                  actionPlay ? handlePlay() : handlePause();
                }
              }}
            >
              {actionPlay ? (
                <PlayArrowRoundedIcon sx={{ color: "#e3e3e3", fontSize: 30 }} />
              ) : (
                <PauseRoundedIcon sx={{ color: "#e3e3e3", fontSize: 30 }} />
              )}
            </Button>
            <Button
              variant={"ghost"}
              p={0}
              px={2}
              onPress={() => {
                //   audioInstance.playNext();
                setActionPlay(true);
                handlePlayNext();
              }}
            >
              <SkipNextRoundedIcon sx={{ color: "#AFAFAF", fontSize: 30 }} />
            </Button>
            <Button
              variant={"ghost"}
              p={0}
              px={2}
              onPress={() => {
                //   audioInstance.playNext();
                //setActionPlay(true);
                handleSkipForward();
              }}
            >
              <FastForwardRoundedIcon sx={{ color: "#AFAFAF", fontSize: 30 }} />
            </Button>
          </HStack>
          <HStack
            w={{ xl: "35%", lg: "35%", md: "35%", base: "100%" }}
            //   borderWidth={1}
            //   borderColor={"#fff"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box px={2} alignSelf={"center"}>
              <AvatarGroup max={3}>
                <Avatar
                  style={{
                    border: "0px",
                  }}
                  alt="Remy Sharp"
                  src={song1}
                />
                <Avatar
                  style={{
                    border: "0px",
                  }}
                  alt="Travis Howard"
                  src={song2}
                />
                <Avatar
                  style={{
                    border: "0px",
                  }}
                  alt="Cindy Baker"
                  src={song3}
                />
              </AvatarGroup>
            </Box>
            <Button
              onPress={() => {
                setCopyLink(song);
                setShareModal(true);
              }}
              alignSelf={"center"}
              _hover={{ bg: "#1C1B1B" }}
              _focused={{ bg: "#2EBA55" }}
              _pressed={{ bg: "#1C1B1B" }}
              bg={"#2EBB55"}
              mx={3}
              rounded={"md"}
              variant={"ghost"}
              px={4}
            >
              <Heading
                color={"#fff"}
                fontSize={{ xl: "lg", base: "sm" }}
                fontWeight={"medium"}
                fontFamily={"Poppins"}
                textAlign={"center"}
                textTransform={"uppercase"}
              >
                SHARE
              </Heading>
            </Button>
          </HStack>
        </HStack>
        <Box
          w={{ xl: "100%", base: "85%" }}
          rounded={"lg"}
          px={2}
          bg={"#1C1B1B"}
          alignSelf={"center"}
        >
          <div id="waveform" ref={waveformRef} />
        </Box>
      </Box>
      <Modal
        isOpen={shareModal}
        onClose={() => setShareModal(false)}
        w={"30%"}
        size={"full"}
        alignSelf={"center"}
        justifyContent={"flex-start"}
        mt={20}
      >
        <Modal.Content backgroundColor={"transparent"}>
          <Modal.Body backgroundColor={"transparent"} my={2} mx={2}>
            <VStack px={5}>
              <HStack rounded="md" backgroundColor={"#1C1B1B"} h={"40"} my={2}>
                <Modal.CloseButton
                  _hover={{ bg: "#1C1B1B" }}
                  _focused={{ bg: "#2EBA55" }}
                  _pressed={{ bg: "transparent" }}
                />
                <Box w={"100%"}>
                  <HStack w={"100%"}>
                    <VStack>
                      <Text
                        fontSize={{ lg: "20px", md: "18px", sm: "12px" }}
                        color="#afafaf"
                        fontWeight={"semibold"}
                        px={5}
                        py={3}
                      >
                        Get Link
                      </Text>
                      <Text
                        fontSize={{ lg: "14px", md: "12px", sm: "12px" }}
                        color="#afafaf"
                        px={5}
                        py={2}
                      >
                        Anyone with this link can play this song
                      </Text>
                    </VStack>
                  </HStack>
                  <HStack justifyContent={"flex-end"}>
                    <Button
                      onPress={() => {
                        setShareModal(false);
                        navigator.clipboard.writeText(copyLink);
                        toast.success("Share Link Copied");
                      }}
                      size="md"
                      mx={10}
                      bg={"#2EBA55"}
                      rounded={"md"}
                      _hover={{ bg: "#1C1B1B" }}
                      _focused={{ bg: "#2EBA55" }}
                      _pressed={{ bg: "#1C1B1B" }}
                      alignSelf={"center"}
                    >
                      <Text
                        fontSize={{ lg: "20px", md: "18px", sm: "12px" }}
                        color="white"
                      >
                        Copy Link
                      </Text>
                    </Button>
                  </HStack>
                </Box>
              </HStack>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default AudioPlayer;

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#eee",
  progressColor: "#2EBB55",
  cursorColor: "#2EBB55",
  barWidth: 3,
  barRadius: 2,
  responsive: true,
  height: 240,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
  skipLength: 3,
  backgroundColor: "#1C1B1B",
});

const audioList2 = {
  name: "Bedtime Stories",
  singer: "Jay Chou",
  cover:
    "http://res.cloudinary.com/alick/image/upload/v1502375978/bedtime_stories_bywggz.jpg",
  musicSrc:
    "http://res.cloudinary.com/alick/video/upload/v1502375674/Bedtime_Stories.mp3",
};

const audioList3 = [
  {
    name: "Despacito",
    singer: "Luis Fonsi",
    cover:
      "http://res.cloudinary.com/alick/image/upload/v1502689731/Despacito_uvolhp.jpg",
    musicSrc:
      "http://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3",
  },
  {
    name: "Bedtime Stories",
    singer: "Jay Chou",
    cover:
      "http://res.cloudinary.com/alick/image/upload/v1502375978/bedtime_stories_bywggz.jpg",
    musicSrc:
      "http://res.cloudinary.com/alick/video/upload/v1502375674/Bedtime_Stories.mp3",
  },
  {
    name: "Dorost Nemisham",
    singer: "Sirvan Khosravi",
    cover:
      "https://res.cloudinary.com/ehsanahmadi/image/upload/v1573758778/Sirvan-Khosravi-Dorost-Nemisham_glicks.jpg",
    musicSrc:
      "https://res.cloudinary.com/ehsanahmadi/video/upload/v1573550770/Sirvan-Khosravi-Dorost-Nemisham-128_kb8urq.mp3",
  },
];

const options = {
  // audio lists model
  audioLists: audioList3,
};
