import { Box } from 'native-base';
import React, { useState } from 'react';
import Members from '../../Members';
import BannerHome from './BannerHome';

import styles from './styles.module.css';
const HomeBody = () => {
	console.log('in home body');

	return (
		<Box w={'100%'} bg={'#000201'}>
			<BannerHome />
			{/* <Box
				w={'100%'}
				alignSelf={'center'}
				alignItems={'center'}
				mt={{ lg: '40', md: '40', base: '20' }}
			>
				<Heading
					textTransform={'uppercase'}
					color={'#fff'}
					fontFamily={'Poppins'}
					fontSize={{ lg: '4xl', base: '2xl' }}
					fontWeight={'bold'}
				>
					TRUSTED BY
				</Heading>

				<Box w={'100%'}>
					<div className={styles.memberSection}>
						<Members isHeading={false} isDashboard={true} />
					</div>
				</Box>
			</Box> */}
		</Box>
	);
};

export default HomeBody;
