/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
} from 'native-base';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DaySelecter from './DaySelecter';

function RightBar() {
  return (
    <Box w={'100%'} bg={'#000201'} my={{ lg: 5, base: 0 }}>
      <SalesCard
        title={'Beat Sales'}
        number={'$456.34'}
        selecter={true}
        content={[{ id: 1, detail: 'Average per customer' }]}
        reminder={false}
      />
      <SalesCard
        title={'Kit Sales'}
        number={'$456.34'}
        selecter={true}
        content={[{ id: 1, detail: 'Average per customer' }]}
        reminder={false}
      />
      <SalesCard
        title={'Services Sales'}
        number={'$456.34'}
        selecter={true}
        content={[{ id: 1, detail: 'Average per customer' }]}
        reminder={false}
      />
      <SalesCard
        title={'Top Item Purchased'}
        number={'$456.34'}
        selecter={true}
        content={false}
        reminder={false}
      />
    </Box>
  );
}
function SalesCard(props) {
  const { title, content, number, reminder, selecter } = props;
  return (
    <Box
      w={'100%'}
      h={{ '2xl': '30vh', xl: '38vh', lg: '35vh', md: '38vh', base: '30vh' }}
      bg={'#1C1B1B'}
      rounded={'lg'}
      alignSelf={'center'}
      my={5}
    >
      <Box w={'90%'} alignSelf={'center'} flex={1}>
        <HStack justifyContent={'space-between'}>
          <Box bg={'#323333'} p={5} w={'10%'} rounded={'full'} my={5} />
          <CalendarMonthRoundedIcon
            style={{ color: '#AFAFAF', fontSize: 25, alignSelf: 'center' }}
          />
        </HStack>
        <HStack justifyContent={'space-between'}>
          <Box w={'57%'} alignSelf={'center'}>
            <Heading
              pb={3}
              color={'#E3E3E3'}
              fontSize={'lg'}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
            >
              {title}
            </Heading>

            {content &&
              content.map((item) => (
                <Heading
                  pb={2}
                  color={'#AFAFAF'}
                  fontSize={'xs'}
                  fontFamily={'Poppins'}
                  fontWeight={'normal'}
                >
                  {item.detail}
                </Heading>
              ))}
          </Box>
          {selecter && (
            <Box w={'40%'} my={2} alignSelf={'center'}>
              <DaySelecter />
            </Box>
          )}
        </HStack>
        <Box position={'absolute'} w={'100%'} bottom={5}>
          <Heading
            my={1}
            w={'100%'}
            color={'#E3E3E3'}
            fontWeight={'medium'}
            fontSize={'3xl'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
          >
            {number}
          </Heading>

          {reminder && (
            <Button
              p={0}
              variant={'ghost'}
              _pressed={{ bg: 'transparent' }}
              _hover={{ bg: 'transparent' }}
              _focus={{ bg: 'transparent' }}
              _text={{
                color: '#2ebb55',
                fontSize: 'md',
                fontFamily: 'Poppins',
                fontWeight: 'normal',
              }}
              alignSelf={'center'}
            >
              Set a Reminder
              <Divider bg={'#2ebb55'} />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default RightBar;
