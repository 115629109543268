import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import Footer from "../../Footer/Footer";
import GetNotified from "../../Getnotified/GetNotified";
import HeaderComp from "../../Header/HeaderComp";
import { useLocation } from "react-router-dom";

const CreditDetails = () => {
  const location = useLocation();
  return (
    <Box w={"100%"} bg={"#000201"}>
      <HeaderComp />
      <Box
        w={{ xl: "60%", lg: "70%", base: "80%" }}
        alignSelf={"center"}
        my={10}
      >
        <Heading
          fontFamily={"Poppins"}
          py={5}
          textAlign={"center"}
          fontSize={{ xl: "6xl", lg: "5xl", md: "4xl", base: "3xl" }}
          fontWeight={"extrabold"}
          color={"#2EBB55"}
          textTransform={"uppercase"}
        >
          {location.state.songname}
        </Heading>
      </Box>
      <Box w={{ xl: "95%", base: "90%" }} alignSelf={"center"}>
        <Image
          source={location.state.image}
          w={"100%"}
          resizeMode={"cover"}
          h={{ xl: "700px", lg: "500px", md: "400px", base: "300px" }}
          rounded={"lg"}
          flexWrap={'wrap'}
        />
        <Box my={10}>
          <HStack w={'100%'} flexGrow={1} flexWrap={'wrap'}>
            <Box w={{xl: '50%', base: '100%'}}>
              <Box my={5}>
                <Heading
                  color={"#e3e3e3"}
                  fontWeight={"medium"}
                  fontFamily={'Poppins'}
                  fontSize={{  lg: "4xl", md: "3xl", base: "2xl" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  Artist
                </Heading>
                <Heading
                  py={2}
                  color={"#AFAFAF"}
                  fontWeight={"normal"}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: "2xl", base: "lg" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  {location.state.artist}
                </Heading>
              </Box>
              <Box my={5}>
                <Heading
                  color={"#e3e3e3"}
                  fontWeight={"medium"}
                  fontFamily={'Poppins'}
                  fontSize={{  lg: "4xl", md: "3xl", base: "2xl" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  RELEASED DATE
                </Heading>
                <Heading
                  py={2}
                  color={"#AFAFAF"}
                  fontWeight={"normal"}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: "2xl", base: "lg" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  {location.state.releasedDate}
                </Heading>
              </Box>
              <Box my={5}>
                <Heading
                  color={"#e3e3e3"}
                  fontWeight={"medium"}
                  fontFamily={'Poppins'}
                  fontSize={{ lg: "4xl", md: "3xl", base: "2xl" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  RECORDED BY
                </Heading>
                <Heading
                  py={2}
                  color={"#AFAFAF"}
                  fontWeight={"normal"}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: "2xl", base: "lg" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  {location.state.recordedBy}
                </Heading>
              </Box>
              <Box my={5}>
                <Heading
                  color={"#e3e3e3"}
                  fontWeight={"medium"}
                  fontFamily={'Poppins'}
                  fontSize={{ lg: "4xl", md: "3xl", base: "2xl" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  MIXED BY
                </Heading>
                <Heading
                  py={2}
                  color={"#AFAFAF"}
                  fontWeight={"normal"}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: "2xl", base: "lg" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  {location.state.mixedBy}
                </Heading>
              </Box>
              <Box my={5}>
                <Heading
                  color={"#e3e3e3"}
                  fontWeight={"medium"}
                  fontFamily={'Poppins'}
                  fontSize={{ lg: "4xl", md: "3xl", base: "2xl" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  MASTARD BY
                </Heading>
                <Heading
                  py={2}
                  color={"#AFAFAF"}
                  fontWeight={"normal"}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: "2xl", base: "lg" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  {location.state.masterBy}
                </Heading>
              </Box>
            </Box>
            <Box w={{xl: '50%', base: '100%'}}>
              <Heading
              pb={1}
                color={"#e3e3e3"}
                fontWeight={"medium"}
                fontFamily={'Poppins'}
                fontSize={{ lg: "4xl", md: "3xl", base: "2xl" }}
                textAlign={"left"}
                textTransform={"uppercase"}
              >
                ALBUMB CREDITS
              </Heading>
              {location.state.credits.map((item) => (
                <Heading
                py={1}
                  color={"#AFAFAF"}
                  fontWeight={"normal"}
                  fontFamily={'Poppins'}
                  fontSize={{ xl: "2xl", base: "lg" }}
                  textAlign={"left"}
                  textTransform={"uppercase"}
                >
                  {item.credit}
                </Heading>
              ))}
              ;
            </Box>
          </HStack>
          <Button
            bg={"#2EBB55"}
            borderWidth={1}
            borderColor={"#2EBB55"}
            _hover={{ bg: "transparent", _text: { color: "#2EBB55" } }}
            _pressed={{ bg: "#2EBB55", _text: { color: "#e3e3e3" } }}
            _text={{
              color: "#e3e3e3",
              fontSize: { xl: "2xl", base: "lg" },
              fontFamily: "Poppins",
              textAlign: "center",
              textTransform: "capitalize",
              fontWeight: "medium",
            }}
            px={10}
            py={2}
            my={10}
            alignSelf={"center"}
          >
            Claim Your United Market Profile
          </Button>
        </Box>
      </Box>
      <GetNotified />
      <Footer />
    </Box>
  );
};

export default CreditDetails;
