
import axios from 'axios'

export  const AxiosInstance=axios.create({
    baseURL:`https://sandbox.api.soundcharts.com/api`,
	headers: {
        'x-api-key': 'soundcharts',
        'x-app-id': 'soundcharts',
    },
})


