import { Box, HStack, Image, Text } from 'native-base'
import React from 'react'

function SocialIcons() {
  return (
  <Box>
    <HStack  alignItems={"center"} space={5} >
        <HStack space={2} alignItems={"center"}>
            <Image height={"25px"} width={"25px"} source={require("../../assets/Feeds/fire.png")}/>
            <Text fontFamily={"poppins"} fontSize={"1.1rem"} color={"#AFAFAF"}>1.6k</Text>
            </HStack>
            <HStack alignItems={"center"} space={2}>
            <Image  height={"25px"} width={"25px"}  source={require("../../assets/Feeds/clipboardtick.png")}/>
            <Text  fontFamily={"poppins"}  fontSize={"1.1rem"} color={"#AFAFAF"}>29</Text>
            </HStack>
            <HStack alignItems={"center"} space={2}>
            <Image  height={"25px"} width={"25px"}  source={require("../../assets/Feeds/message.png")}/>
            <Text  fontFamily={"poppins"}  fontSize={"1.1rem"} color={"#AFAFAF"}>29</Text>
            </HStack>
    </HStack>
  </Box>
  )
}

export default SocialIcons