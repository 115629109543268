/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import { Box, Divider, Heading, HStack, Image, Text } from "native-base";
import SideCard from "./SideCard";
import trendingImg from "../../../assets/Feeds/trendingImg.png";
import followImg from "../../../assets/Feeds/followImg.png";
import MostUpVotedBeats from "../../Hooks/Feeds/UpVotes";

const getCoverPhoto = (data) => {
  let cover_photo = '';
  if(data.length > 0) {
    for(var i=0; i<data.length; i++) {
      if(data[i].key === 'cover_photo') {
        cover_photo = data[i].value;
      }
    }
  }
  return cover_photo;
}
function Trending() {
  const [beats, setBeats] = React.useState();

  React.useEffect(() => {
    getMostUpvotedBeats();
  }, [])
  const getMostUpvotedBeats = async () => {
    try{
      const response = await MostUpVotedBeats();
      // console.log('response of trending api...', response)
      if(response?.length > 0) {
        setBeats(response.map((resp) => ({
          title: resp?.node.product.title,
          upvotes: resp?.node.product.upVotes,
          coverPhoto: getCoverPhoto(resp?.node.product.metafields)
        })));
      }
    }
    catch(err) {
      console.log('error...', err);
    }
  }
  return (
    <Box w={"100%"} bg={"#000201"} my={5}>
      <Heading
        my={5}
        fontSize={{ xl: "2xl", base: "lg" }}
        fontWeight={"medium"}
        fontFamily={"Poppins"}
        color={"#fff"}
        textAlign={"left"}
        textTransform={"capitalize"}
      >
        Trending Most Upvotes
      </Heading>
      {
        beats?.length > 0 && beats.map((beat, key) => (
          <React.Fragment key={key}>
            <SideCard image={beat.coverPhoto || trendingImg} title={beat.title} subtitle={"Upvoted"}/>
          </React.Fragment>
        ))
      }
      
      {/* <SideCard image={followImg} title={"Mars Way Music"} subtitle={"Upvoted"}/> */}
      {/* <SideCard image={trendingImg} title={"Lofi"} subtitle={"Upvoted"}/> */}
    </Box>
  );
}

export default Trending;
