import React from 'react'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconButton, Avatar, Typography } from '@material-ui/core'
function UserInfo({user}) {
  return (
    <IconButton sx={{ p: 0 }}>
      <Avatar
        alt="Remy Sharp"
        src={user?.profilePic ? user?.profilePic : "/static/images/avatar/2.jpg"}
        style={{width:'40px', height:'40px'}}
      />
      <div style={{ paddingLeft: 10 }}>
        <Typography style={{ color: "#E3E3E3", fontSize: 16 }}>
        {user?.firstname || user?.lastname ? user?.firstname + ' '+ user?.lastname : 'Guest'}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:'flex-end'
          }}
        >
          <Typography style={{ color: "#AFAFAF", fontSize: 12 }}>
          {user?.roleId?.roleName || 'Guest'}
          </Typography>
        </div>
      </div>
    </IconButton>
  )
}

export default UserInfo
