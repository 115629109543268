import { useLazyQuery } from "@apollo/client";
import React from "react";
import { FetchNotifications } from "../AudioLibrary/SearchUsers/Query";

export default function useNotification() {
  const [
    getNotification,
    {
      called: notificationCalled,
      loading: notificationLoading,
      data: notificationData,
    },
  ] = useLazyQuery(FetchNotifications, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
  });
  return [
    notificationCalled,
    notificationLoading,
    notificationData,
    getNotification,
  ];
}
