import { color, textTransform } from "@mui/system";
import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import staticCredits from "../../assets/staticWebsite/staticCredits.png";
import CreditsLayout from "../../components/StaticWebSite/LandingPagesComp/CreditsLayout";
import credit610 from "../../assets/staticWebsite/credit610.png";
import credit611 from "../../assets/staticWebsite/credit611.png";
import credit612 from "../../assets/staticWebsite/credit612.png";
import credit613 from "../../assets/staticWebsite/credit613.png";
import credit614 from "../../assets/staticWebsite/credit614.png";
import credit615 from "../../assets/staticWebsite/credit615.png";
import credit616 from "../../assets/staticWebsite/credit616.png";
import credit617 from "../../assets/staticWebsite/credit617.png";
import credit618 from "../../assets/staticWebsite/credit618.png";
import credit619 from "../../assets/staticWebsite/credit619.png";
import credit620 from "../../assets/staticWebsite/credit620.png";

const Credits = (props) => {
  
    const recentAlbum = [
        {
          id: 1,
          image: credit610,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 2,
          image: credit611,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 3,
          image: credit612,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 4,
          image: credit613,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
      ];
    const otherAlbum = [
        {
          id: 1,
          image: credit614,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],

          
        },
        {
          id: 2,
          image: credit615,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 3,
          image: credit616,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 4,
          image: credit613,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 5,
          image: credit617,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 6,
          image: credit618,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 7,
          image: credit619,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
        {
          id: 8,
          image: credit620,
          songname: "Trench Baby 3",
          artist: "LIL ZAY OASAMA",
          releasedDate: "AUG-19-2022",
          recordedBy:
            "JUSVIBES, PREP BAIJAN X TERRANCE “HOBB” SMITH X TRAVISH MARSH",
          mixedBy: "ETON CHUENG X REBORT MENDROID X DANIAL VERGAS",
          masterBy: "JOE LAPROTA",
          credits: [
            {
                id: 1,
                credit: "1. FUCK AH BIG HOMIE - EINER BANKZ X JTK "
            },
            {
                id: 2,
                credit: "2. CHASE EM DOWN (FT.G HERBO) - DJ L X TRAVISH MARSH  "
            },
            {
                id: 3,
                credit: "3. GLAH GLAH - DRAKO X PRODPAROAH"
            },
            {
                id: 4,
                credit: "4. FUCK MY COUSIN, PT. II BY LIL ZAY OSAMA & LIL DURK- FATMANBEATS X VITO"
            },
            {
                id: 5,
                credit: "5. IT'S UP - QUAXAR  "
            },
            {
                id: 6,
                credit: "6. FUCK YO HOMIE DEAD ASS - BEEZO X EPHORTLESS X LASOURCE X STOPHER BEATS "
            },
            {
                id: 7,
                credit: "7. 41 PRESIDENTIAL ( FT. ICEWEAR VEZZO - DJ BANDZ X MAGIC "
            },
            {
                id: 8,
                credit: "8. TRIM ( FT. PGF NUK - COLD BLIME X DRAKO "
            },
            {
                id: 9,
                credit: "9. MMMHMMMMM - KING ZO "
            },
            {
                id: 10,
                credit: "10. SET UP (FET FREDO BANG) - IAM YOUGEN X VB GOT THIS  "
            },
          ],
        },
      ];

  return (
    <Box w={"100%"} bg={"#000201"}>
        <CreditsLayout
        title={'CREDITS'}
        subtitle={'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden'}
        image={staticCredits}
        recentAlbum={recentAlbum}
        otherAlbum={otherAlbum}
        />

    </Box>
  );
};

export default Credits;