import { gql } from '@apollo/client';

export const createSubscription = gql`
  mutation attachSubscriptionForUser(
    $priceId: String!
    $email: String!
    $paymentMethodId: String!
    $promoCode: String
  ) {
    attachSubscriptionForUser(
      priceId: $priceId
      email: $email
      paymentMethodId: $paymentMethodId
      isPreSignup: false
      promoCode: $promoCode
    ) {
      success
      message
      user {
        _id
        profilePic
        userName
        roleId {
          roleName
        }
      }
      accessToken
      refreshToken
      reactionAccessToken
      reactionRefreshToken
      sessionId
    }
  }
`;

export const addPaymentMethod = gql`
  mutation addPaymentMethod($paymentMethodId: String) {
    addPaymentMethod(paymentMethodId: $paymentMethodId) {
      success
      message
      isTokenExpired
    }
  }
`;

export const getMyPaymentMethod = gql`
  query {
    getMyPaymentMethods {
      success
      message
      isTokenExpired
      paymentMethods {
        id
        type
        isDefaultPaymentMethod
        card {
          last4
          exp_year
          exp_month
          brand
        }
      }
    }
  }
`;

export const defaultPaymentMethod = gql`
  mutation setDefaultPaymentMethod($paymentMethodId: String) {
    setDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
      success
      message
      isTokenExpired
    }
  }
`;

export const deletePaymentMethod = gql`
  mutation deletePaymentMethod($paymentMethodId: String) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId) {
      success
      message
      isTokenExpired
    }
  }
`;

export const renewSubscription = gql`
  mutation {
    renewSubscription(paymentMethodId: null) {
      success
      message
      isTokenExpired
    }
  }
`;
