import React from 'react';

function Pencil() {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.84127 2.39995L3.36794 8.19328C3.13793 8.46633 2.98388 8.79513 2.92127 9.14661L2.6746 11.3073C2.64226 11.4753 2.65279 11.6487 2.70519 11.8116C2.7576 11.9744 2.85021 12.1214 2.97446 12.239C3.0987 12.3566 3.25058 12.441 3.41607 12.4844C3.58155 12.5278 3.7553 12.5288 3.92127 12.4873L6.06794 12.1206C6.4175 12.0439 6.73853 11.8706 6.9946 11.6206L12.4679 5.82728C13.4146 4.82728 13.8413 3.68728 12.3679 2.29395C10.9013 0.913279 9.78793 1.39995 8.84127 2.39995Z'
				stroke='#AFAFAF'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.92773 3.3667C8.0675 4.2606 8.50014 5.08279 9.15774 5.7042C9.81535 6.32561 10.6607 6.71105 11.5611 6.80003'
				stroke='#AFAFAF'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2 14.6667H14'
				stroke='#AFAFAF'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default Pencil;
