import React from "react";
import { Box, Text, HStack, Image, VStack } from "native-base";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function Courosel() {
  return (
    <Box mx={"auto"} width={{ md: "80%", sm: "90%" }}>
      <OwlCarousel
        className="owl-theme"
        margin={10}
        nav
        items={1}
        rewind
        autoplay
        slideSpeed={500}
        smartSpeed={200}
        responsive={{ 0: { items: 1 }, 400: { items: 1 }, 600: { items: 1 } }}
      >
        <Box
          borderRadius={10}
          p={5}
          backgroundColor="rgba(175, 175, 175, 0.25)"
        >
          <VStack>
            {" "}
            <Text
              fontFamily={"popins"}
              textAlign={"left"}
              fontSize={{
                lg: "1.1rem",
                md: "1rem",
                sm: "0.8rem",
                base: "0.6rem",
              }}
              color={"#fff"}
            >
              “A nice blend of prediction and surprise seem to be at the Heart
              of the best art.”
            </Text>
            <HStack height={"50px"} justifyContent={"space-between"}>
              <Text
                fontFamily={"popins"}
                alignSelf={"center"}
                textAlign={"left"}
                fontSize={{
                  lg: "2.3rem",
                  md: "1.3rem",
                  sm: "1.2rem",
                  base: "1rem",
                }}
                color={"#fff"}
              >
                Wendy Carlos
              </Text>

              <Image
                alignSelf={"start"}
                height={"80px"}
                width="120px"
                resizeMode="contain"
                source={require("../../assets/SignIn/stars.png")}
              />
            </HStack>
            <HStack
              height={"80px"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box alignSelf={"center"}>
                <Text
                  fontFamily={"popins"}
                  fontSize={{ lg: "1.3rem", md: "1.1rem", sm: "0.9rem" }}
                  color={"#fff"}
                >
                  Music Producer
                </Text>
                <Text
                  fontFamily={"popins"}
                  textAlign={"left"}
                  fontSize={{ lg: "1.1rem", md: "0.9rem", sm: "0.7rem" }}
                  color={"#fff"}
                >
                  Dom Dias
                </Text>
              </Box>
              {/* 
            <Image
              size={"lg"}
              resizeMode="contain"
              source={require("../../assets/SignIn/arrows.png")}
            /> */}
            </HStack>
          </VStack>
        </Box>
      </OwlCarousel>
    </Box>
  );
}

export default Courosel;
