import { gql } from '@apollo/client';

export const SendFeedbackQery = gql`
	mutation ($feedback: String!, $rating: Float) {
		sendFeedback(feedback: $feedback, rating: $rating) {
			success
			message
		}
	}
`;
