import React from "react";
import { Box, Image, Heading, HStack, VStack, Button } from "native-base";

import profile from "../../../assets/header/profile.png";
import ProfileTitle from "../ProfileTitle";
import ButtonComponent from "../../ButtonComponent";
export const ProducerServices = () => {
 
  return (
    <Box mt={5}  pl={{lg:"16px",md:0}} bg={"#000201"} w={"100%"}>
      <Box >
     <ProfileTitle fontSize={{md:"2xl",base:"lg"}}   text="services"/>
        <Box bg={"#1C1B1B"}  my={3} p={{sm:7,base:4}} rounded={'lg'}>
          <Heading
            color={"#E3E3E3"}
            fontSize={"lg"}
            fontWeight={"medium"}
            textAlign={"left"}
            py={2}
          >
            Get Feedback
          </Heading>
          <Heading
            color={"#AFAFAF"}
            fontSize={"sm"}
            fontWeight={"normal"}
            textAlign={"left"}
         
          >
            I will provide a high level of feedback on your beat with time stamp
            recommendations and suggestions. This will help you improve your
            sound and this specific beat to a higher caliber.
          </Heading>
          <HStack mt={5}  alignSelf={'flex-end'} space={4}>
            <Heading
              color={"#2EBB55"}
              fontSize={{md:"2xl",sm:"xl"}}
              fontWeight={"medium"}
              textAlign={"left"}
              alignSelf={'center'}
            >
              $56.00
            </Heading>
            <ButtonComponent flag={false} text={"book"} paddingYMd={3} paddingXMd={8} paddingYSm={1} paddingXSm={2} backgroundColor={"#2EBB55"} textProperties={{textTransform:"capitalize",color:"#fff",fontSize:{md:"xl",base:"sm"}}} />
            {/* <Button bg={'#2EBB55'} py={2} px={4} mx={3}>
              <Heading
                color={"#fff"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"center"}
              >
                Book
              </Heading>
            </Button> */}
          </HStack>
        </Box>
        <Box bg={"#1C1B1B"} my={3} p={{sm:7,base:4}}  rounded={'lg'}>
          <Heading
            color={"#E3E3E3"}
            fontSize={"lg"}
            fontWeight={"medium"}
            textAlign={"left"}
            py={2}
          >
            Get Feedback
          </Heading>
          <Heading
            color={"#AFAFAF"}
            fontSize={"sm"}
            fontWeight={"normal"}
            textAlign={"left"}
            py={1}
          >
            I will provide a high level of feedback on your beat with time stamp
            recommendations and suggestions. This will help you improve your
            sound and this specific beat to a higher caliber.
          </Heading>
          <HStack mt={5} alignItems={"center"}  alignSelf={'flex-end'} space={4}>
            <Heading
              color={"#2EBB55"}
              fontSize={{md:"2xl",sm:"xl"}}
              fontWeight={"medium"}
              textAlign={"left"}
              alignSelf={'center'}
            >
              $56.00
            </Heading>
            <ButtonComponent flag={false} text={"book"} paddingYMd={3} paddingXMd={8} paddingYSm={1} paddingXSm={2} backgroundColor={"#2EBB55"} textProperties={{textTransform:"capitalize",color:"#fff",fontSize:{md:"xl",base:"sm"}}} />
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
