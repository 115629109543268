import { useLazyQuery, useQuery } from '@apollo/client'
import React from 'react'
import { GETFOLLOWINGS } from './Query'

function useFeedsQuery() {
    const [loading, setLoading] = React.useState(false);
    const [followings, setFollowing] = React.useState([]);
    const [Get_Followings] = useLazyQuery(GETFOLLOWINGS, {
        context: { clientName: 'user' },
        fetchPolicy:'no-cache', 
        onCompleted(data){
            setLoading(false);
            console.log('dat...', data);
            if(data.getMyFollowings.success) {
                setFollowing([...data.getMyFollowings.followings]);
            }
        },
        onerror(err) {
            setLoading(false);
            console.log('error on fetching followings data..', err);
        }
    });

    const getFollowers = () => {
        setLoading(true);
        Get_Followings();
    }
  return [loading, followings, getFollowers]
}

export default useFeedsQuery