import { gql } from "@apollo/client";

export const SEARCHUSERS = gql`
  query {
    searchUsers {
      users {
        firstname
        lastname
        _id
        profilePic
      }
    }
  }
`;

export const FetchNotifications = gql`
  query {
    getMyNotifications {
      success
      message
      notifications {
        _id
        message
        userId
        type
        id
        createdAt
        status
        id
        secondaryId
      }
      isTokenExpired
    }
  }
`;

export const acceptOpportunitySplit = gql`
  mutation accept($notificationId: String, $reactionAccessToken: String) {
    acceptOpportunitySubmissionStatus(
      notificationId: $notificationId
      reactionAccessToken: $reactionAccessToken
    ) {
      success
      message
      isTokenExpired
    }
  }
`;

export const rejectOpportunitySplit = gql`
  mutation rejectOpportunitySubmissionStatus($notificationId: String) {
    rejectOpportunitySubmissionStatus(notificationId: $notificationId) {
      success
      message
      isTokenExpired
    }
  }
`;

export const deleteNotificationById = gql`
  mutation deleteNotification($notificationId: String) {
    deleteNotification(notificationId: $notificationId) {
      success
      message
    }
  }
`;
