import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TableHead } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, HStack } from 'native-base';
import CountryFilter from "./CountryFilter";
import DaysFilter from "./DaysFilter";
import StationFilter from "./StationFilter";
import {Heading,Text} from 'native-base'
import { TableHeaderCol3 } from '../../TalentComponentHeader/TableHeaderCol3';
import { TableHeaderCol4 } from '../../TalentComponentHeader/TableHeaderCol4';
import ApplyFiters from '../ApplyFiters';
import { Loader } from '../../../Loader';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
function TablePaginationActions(props) {

  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }} className='table_pagination' display={{base:'block', md:'flex'}}>
      <div>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
      </div>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Cupcake', 305, 3.7),
  createData('Donut', 452, 25.0),
  createData('Eclair', 262, 16.0),
  createData('Frozen yoghurt', 159, 6.0),
  createData('Gingerbread', 356, 16.0),
  createData('Honeycomb', 408, 3.2),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Jelly Bean', 375, 0.0),
  createData('KitKat', 518, 26.0),
  createData('Lollipop', 392, 0.2),
  createData('Marshmallow', 318, 0),
  createData('Nougat', 360, 19.0),
  createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function AirplayTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
const {radioData, filterHandler, loading} = props

const [filters, setFilters] = React.useState({
  country: 'all_countries',
  days:'28',
})

const countryHandler = (e) => {
  console.log('country handler', e)
  setFilters(prevState =>({
    ...prevState,
    country: e
  }))
}
const daysHandler = (e) => {
  setFilters(prevState =>({
    ...prevState,
    days: e
  }))
}
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    console.log('new page ', newPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={darkTheme}>

      <Box p={5} bg={"#1C1B1B"} py={3} rounded={"lg"}>  <HStack flexDirection={{md:"row",base:"column"}} justifyContent={{md:'space-between'}} alignItems={{md:'center'}} >
        <Heading
             color={"#AFAFAF"}
             fontFamily={"Poppins"}
             fontSize={{xl:"2xl", md:"lg", base:"md"}}
             fontWeight={"normal"}
             textAlign={"left"}
           >
             Radio plays per station
           </Heading>
        <HStack flexDirection={{md:"row",base:"column"}} my={5}  px={{md: 5}}>
        {/* <CountryFilter value={filters.country} handler={countryHandler} />
        <DaysFilter value={filters.days} handler={daysHandler} />
        <ApplyFiters handler={filterHandler} /> */}
        </HStack>
      </HStack>
    <TableContainer component={Paper}>
    {/* <TableHeaderCol4   title2={"radio station"} title3={"plays"} title4={"radio reach"}>
              
            </TableHeaderCol4 > */}
      {
        loading ? <div style={{display:'flex', alignItems:'center', justifyContent:'center',padding:'60px'}}>
            <Loader />
        </div> : <Table aria-label="custom pagination table" >
        <TableHead>
        <TableRow>
        <TableCell style={{color:'#AFAFAF'}}>
                
            </TableCell>
            <TableCell  style={{color:''}}>
             <Text color={"#AFAFAF"} textTransform={"uppercase"}   fontSize={{lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.7rem"}} style={{whiteSpace:'normal'}}>radio station</Text>
            </TableCell>
            <TableCell  style={{color:'#AFAFAF'}}>
            <Text color={"#AFAFAF"} textTransform={"uppercase"}   fontSize={{lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.7rem"}} style={{whiteSpace:'normal'}}>plays</Text>
            </TableCell>
            <TableCell style={{color:'#AFAFAF'}}>
            <Text color={"#AFAFAF"} textTransform={"uppercase"}   fontSize={{lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.7rem"}} style={{whiteSpace:'normal'}}>radio reach</Text>
            </TableCell>
              
            </TableRow>
        </TableHead>
        <TableBody>
            {
                (rowsPerPage > 0 ? radioData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage): radioData).map((radio, key) => (<TableRow key={key}>
                    <TableCell component="th" scope="row"  style={{color:'#AFAFAF', width:'25px', paddingRight:'5px'}}>
                    <Text width={"100px"}  color={"#AFAFAF"} textTransform={"uppercase"}   fontSize={{lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.7rem"}}>{key+1}</Text>               
                    </TableCell>
                    <TableCell component="th" scope="row"  style={{color:'#AFAFAF'}}>
                    <Text  color={"#AFAFAF"} textTransform={"uppercase"}   fontSize={{lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.7rem"}} style={{whiteSpace:'normal'}}>   {radio.radio_name}</Text>        
                    </TableCell>
                    <TableCell style={{ width: 160,color:'#AFAFAF' }} >
                    <Text color={"#AFAFAF"} textTransform={"uppercase"}   fontSize={{lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.7rem"}} style={{whiteSpace:'normal'}}>   {radio.plays}</Text>   
                    </TableCell>
                    <TableCell style={{ width: 160,color:'#AFAFAF' }} >
                    <Text color={"#AFAFAF"} textTransform={"uppercase"}   fontSize={{lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.7rem"}} style={{whiteSpace:'normal'}}>  {radio.plays}</Text>        
                    </TableCell>
                  </TableRow>))
            }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
            
              rowsPerPageOptions={[25,50,100]}
              colSpan={4}
              count={radioData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      }
    </TableContainer></Box>
    
    </ThemeProvider>
  );
}