import { useState, useContext } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { Loader } from '../../Loader';
import styles from './styles.module.css';
import {
	Box,
	Button,
	CheckIcon,
	Divider,
	Heading,
	HStack,
	Text,
	Image,
	Input,
	Select,
} from 'native-base';
import DefaultProfile from './../../../assets/LoginScreens/default.png';
import camera from '../../../assets/LoginScreens/camera.png';
// import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from '../Layout';
// import ImageUploader from "react-images-upload";
// import axios from "axios";
// import { Heading, Image } from "native-base";
// import "./UploadPicture.css";

const UploadProfile = () => {
	const token = localStorage.getItem('token');
	const [service, setService] = useState('');
	const [userName, setUserName] = useState('');
	const [isLoadingProfile, setIsLoadingProfile] = useState(false);
	const [isLoadingCover, setIsLoadingCover] = useState(false);
	const [coverPicture, setcoverpicture] = useState('');
	const [profilePicture, setprofilePicture] = useState('');
	const [erorMessage, setErrorMessage] = useState('');
	const types = ['image/jpeg', 'image/png'];
	const { picture, setPicture } = useContext(AuthContext);
	//   const myref = useRef(null);
	//   //   const [] = useState("");

	//   const types = [];
	//   var data1 = new FormData();

	//   const uploadImg = (e) => {
	//     console.log("cliekd");
	//     myref.current.click();
	//     //    console.log(e)
	//   };
	//   const onDrop = (pictureData) => {
	//     console.log("picturedata", pictureData);
	//     if (pictureData.length > 0) {
	//       data1.append("file", pictureData[0]);
	//       data1.append("type", "Public");
	//       //   setPicture(pictureData[0]);
	//       var config = {
	//         method: "post",
	//         url: "https://new.unitedmarket.com/file/uploadprofilepicture",

	//         headers: {
	//           "Content-Type": "multipart/form-data",
	//         },
	//         data: data1,
	//       };
	//       axios(config)
	//         .then(function (response) {
	//           console.log(JSON.stringify(response.data));
	//           console.log("photourl", response.data.file.url);
	//           setPicture(response.data.file.url);
	//           localStorage.setItem("profilePic", response.data.file.url);
	//         })
	//         .catch(function (error) {
	//           console.log(error);
	//         });
	//       return;
	//     } else {
	//       // setErrMsg("invalid file type");
	//       return;
	//     }
	//   };

	//   const changeHandler = (e) => {
	//     console.log("file...", e.target.files);
	//     onDrop(e.target.files);
	//   };

	//   return (
	//     <div className="imageupload">
	//       {" "}
	//       <ImageUploader
	//         // {...props}
	//         withPreview={true}
	//         withIcon={true}
	//         withLabel={false}
	//         // defaultImage=[picture]

	//         buttonText={
	//           !picture ? (
	//             <Image
	//               height={"70%"}
	//               width={"70%"}
	//               source={require("../../../assets/LoginScreens/default.png")}
	//             />
	//           ) : (
	//             <img className="profileimg" src={picture} alt="" />
	//           )
	//         }
	//         onChange={onDrop}
	//         imgExtension={[".jpg", ".gif", ".png", ".gif"]}
	//         maxFileSize={5242880}
	//         singleImage={true}
	//         buttonStyles={{
	//           // display: !picture ? "block" : "none",
	//           fontSize: "0.7rem",
	//           position: "absolute",
	//           // padding: "0.4rem 0.5rem",

	//           // top: "50%",
	//           // marginTop: "-20px",
	//           // // left: "50%",
	//           // marginLeft: "-10px",
	//           zIndex: 333333,
	//         }}
	//       />
	//       <input
	//         ref={myref}
	//         id="imgupload"
	//         type={"file"}
	//         onChange={changeHandler}
	//         style={{ display: "none" }}
	//       />
	//       <img
	//         src={camera}
	//         height={"25px"}
	//         width={"25px"}
	//         for="imgupload"
	//         onClick={uploadImg}
	//         alt=""
	//       />
	//     </div>
	//   );
	// };

	// export default UploadProfile;
	const verifyImageFile = (files) => {
		if (files && files.length > 0) {
			const currentFile = files[0];
			const currentFileType = currentFile.type;

			if (!types.includes(currentFileType)) {
				console.log('not accepted');
				return false;
			}
			return true;
		}
	};

	const handleOnDropLogoImage1 = async (files, rejectedFiles) => {
		const profileData = new FormData();
		if (rejectedFiles && rejectedFiles.length > 0) {
			verifyImageFile(rejectedFiles);
		}
		if (files && files.length > 0) {
			if (verifyImageFile(files)) {
				profileData.append('file', files[0]);
				profileData.append('type', 'Public');
				setIsLoadingProfile(true);
				var config = {
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}/file/uploadprofilepicture`,

					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: profileData,
				};
				axios(config)
					.then(function (response) {
						setPicture(response.data.file.url);
						setIsLoadingProfile(false);
					})
					.catch(function (error) {
						console.log(error);
						setIsLoadingProfile(false);
					});
				return;
			} else {
				setIsLoadingProfile(false);
				// setErrMsg("invalid file type");
				return;
			}
		}
	};
	return (
		<Box
			position={'absolute'}
			zIndex={99999}
			bottom={{
				sm: isLoadingProfile ? '-110px' : '-70px',
				base: '-50px',
			}}
			//   left={{ sm: isLoadingProfile ? "20px" : "20px", base: "20px" }}
			// size={{ md: "170px", sm: "140px", base: "100px" }}
			left={'50%'}
			height={'150px'}
			width={'150px'}
			resizeMode='cover'
			alignItems={'center'}
			borderRadius={'50%'}
			justifyContent={'center'}
			textAlign={'center'}
			src={require('../../../assets/Profile/userProfile.png')}
			style={{ transform: 'translateX(-50%)' }}
		>
			{isLoadingProfile ? (
				<div className={styles.loaderProfile}>
					<Loader />
				</div>
			) : (
				<Dropzone
					name='dropzone1'
					accept='image/*'
					onDrop={handleOnDropLogoImage1}
					multiple={false}
				>
					{({ getRootProps, getInputProps }) => (
						<section className={styles.section}>
							<div className={styles.presentation} {...getRootProps()}>
								<div className={styles.UploadIconContainer}>
									<img
										alt=''
										src={picture ? picture : DefaultProfile}
										style={{
											borderRadius: '50%',
											width: '100%',
											height: '100%',
										}}
										className=''
									/>
									<input {...getInputProps()} />
									{/* <input className="input-zone" {...getInputProps()} /> */}
									{/* {!profilePicture && (
                <p className="text-capitalize mb-4 mx-auto w-75">
                  drop your image here
                </p>
              )} */}

									<button
										style={{
											//   background: "#2ebb55",
											background: 'none',
											cursor: 'pointer',
											outline: 'none',
											border: 'none',
											color: '#fff',

											position: 'absolute',
											top: '70%',
											marginLeft: '-35px',
											marginTop: '-5px',
											left: '100%',
											width: '50%',
											padding: '0.5rem 1rem',
											borderRadius: '5px',
										}}
									>
										<span>
											<img
												alt='noimage'
												width={'25px'}
												height={'25px'}
												src={camera}
												className={'cameraimg'}
											/>
										</span>
										{/* {"Upload"} */}
									</button>
								</div>
							</div>
						</section>
					)}
				</Dropzone>
			)}
		</Box>
	);
};

export default UploadProfile;
