import React from 'react';

function KeyandBPMMbl() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.9688 18C11.9688 18.7911 11.7342 19.5645 11.2946 20.2223C10.8551 20.8801 10.2304 21.3928 9.49949 21.6955C8.76858 21.9983 7.96431 22.0775 7.18839 21.9231C6.41247 21.7688 5.69973 21.3878 5.14032 20.8284C4.58091 20.269 4.19995 19.5563 4.04561 18.7804C3.89127 18.0044 3.97048 17.2002 4.27323 16.4693C4.57598 15.7384 5.08867 15.1136 5.74647 14.6741C6.40427 14.2346 7.17763 14 7.96875 14C9.02962 14 10.047 14.4214 10.7972 15.1716C11.5473 15.9217 11.9688 16.9391 11.9688 18Z'
        stroke='#AFAFAF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9688 18V4'
        stroke='#AFAFAF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6114 2.10997L19.0314 3.57997C19.583 3.79277 20.061 4.16121 20.4072 4.64053C20.7533 5.11985 20.9528 5.6894 20.9814 6.27997V7.44997C21.0125 7.77641 20.9577 8.1053 20.8224 8.40403C20.6872 8.70275 20.4762 8.96093 20.2104 9.15296C19.9446 9.34499 19.6332 9.46419 19.3071 9.49875C18.981 9.53331 18.6515 9.48202 18.3514 9.34997L13.9314 7.87997C13.3798 7.66717 12.9018 7.29872 12.5556 6.81941C12.2095 6.34009 12.01 5.77053 11.9814 5.17997V3.99997C11.9498 3.67364 12.0044 3.34473 12.1397 3.04611C12.275 2.74749 12.4864 2.48963 12.7526 2.2983C13.0189 2.10697 13.3307 1.98888 13.6569 1.95583C13.983 1.92278 14.3122 1.97593 14.6114 2.10997V2.10997Z'
        stroke='#AFAFAF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default KeyandBPMMbl;
