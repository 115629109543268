import React from 'react';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import SubscribeComponent from '../../components/Socials/Subscribers/Subscribers';
const Favourites = () => {
	return (
		<Sidebar
			activeText='Social'
			activeSubText='Subscribers'
			childComponent={<SubscribeComponent />}
		/>
	);
};
export default Favourites;
