/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
} from 'native-base';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

function RightBar() {
  return (
    <Box w={'100%'} bg={'#000201'} my={5}>
      <Heading
        my={5}
        fontSize={{ xl: '2xl', base: 'lg' }}
        fontWeight={'medium'}
        fontFamily={'Poppins'}
        color={'#fff'}
        textAlign={'left'}
        textTransform={'capitalize'}
      >
        Preferences
      </Heading>
      <Dropdowns name={'Loop Specialities'} />
      <Dropdowns name={'Beat Specialities'} />
      <Dropdowns name={'Genre'} />
      <Dropdowns name={'Location'} />
      <Dropdowns name={'Label Status'} />
      <Heading
        my={5}
        fontSize={{ xl: '2xl', base: 'lg' }}
        fontWeight={'medium'}
        fontFamily={'Poppins'}
        color={'#fff'}
        textAlign={'left'}
        textTransform={'capitalize'}
      >
        Upload Audio Sample
      </Heading>
      <Button
        size={'sm'}
        bg='#2EBA55'
        borderWidth={1}
        borderColor='#2EBA55'
        _text={{
          color: '#fff',
          fontSize: {
            lg: '18px',
            md: '16px',
            sm: '14px',
            base: '14px',
          },
          fontFamily: 'Poppins',
        }}
        _hover={{
          bg: 'transparent',
          _text: {
            color: '#2EBA55',
          },
        }}
        w={'40%'}
        rounded={'md'}
        //   alignSelf={'center'}
        _focused={{ bg: '#2EBA55' }}
        _pressed={{ bg: '#2EBA55' }}
      >
        UPLOAD
      </Button>
    </Box>
  );
}
function Dropdowns(props) {
  const { name } = props;
  const [showOption, setShowOption] = useState(false);
  return (
    <Box w={'100%'} bg={'#000201'}>
      <Button
        p={0}
        variant={'ghost'}
        _hover={{ bg: 'transparent' }}
        _pressed={{ bg: 'transparent' }}
        _focus={{ bg: 'transparent' }}
        justifyContent={'flex-start'}
        onPress={() => setShowOption(!showOption)}
      >
        <HStack>
          <Heading
            mb={5}
            fontSize={{ xl: 'xl', base: 'md' }}
            fontWeight={'medium'}
            fontFamily={'Poppins'}
            color={'#e3e3e3'}
            textAlign={'left'}
            textTransform={'capitalize'}
            alignSelf={'center'}
            mr={3}
          >
            {name}
          </Heading>
          <Box>
            <KeyboardArrowDownRoundedIcon
              style={{ color: '#e3e3e3', fontSize: '25', alignSelf: 'center' }}
            />
          </Box>
        </HStack>
      </Button>
      {showOption && (
        <Box
          w={'80%'}
          bg={'#1c1b1b'}
          borderColor={'#afafaf'}
          borderWidth={2}
          rounded={'md'}
          mb={2}
        >
          <Heading
            py={2}
            pl={2}
            fontSize={{ xl: 'md', base: 'sm' }}
            fontWeight={'medium'}
            fontFamily={'Poppins'}
            color={'#afafaf'}
            textAlign={'left'}
            textTransform={'capitalize'}
            borderBottomColor={'#afafaf'}
            borderBottomWidth={2}
          >
            Signed
          </Heading>
          <Heading
            py={2}
            pl={2}
            fontSize={{ xl: 'md', base: 'sm' }}
            fontWeight={'medium'}
            fontFamily={'Poppins'}
            color={'#afafaf'}
            textAlign={'left'}
            textTransform={'capitalize'}
            //   borderBottomColor={'#afafaf'}
            //   borderBottomWidth={2}
          >
            Unsigned
          </Heading>
        </Box>
      )}
    </Box>
  );
}

export default RightBar;
