import {
	Box,
	Button,
	CloseIcon,
	Heading,
	HStack,
	Image,
	Input,
	Modal,
	ScrollView,
	Text,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import profile from '../../assets/Popup/profile.png';

export function SendPackPopup(props) {
	const {
		openModal,
		closeModal,
		data,
		value,
		handleChangeCollaborator,
		handleUserAccess,
		fileId,
		showCopyLink,
		copyBeatPack,
	} = props;
	const [selected, setSelected] = useState([]);
	const handleFAQDrop = (value) => {
		if (selected.length > 0) {
			if (selected.includes(value)) {
				setSelected(selected.filter((s) => s !== value));
			} else {
				setSelected([...selected, value]);
			}
		} else {
			setSelected([value]);
		}
	};
	const sendFile = () => {
		handleUserAccess(fileId, selected);
	};

	return (
		<Modal
			isOpen={openModal}
			onClose={closeModal}
			w={{ xl: '45%', md: '50%', base: '90%' }}
			size={'full'}
			alignSelf={'center'}
			justifyContent={'flex-start'}
			mt={20}
		>
			<Box w={'90%'} bg={'#1c1b1b'} rounded={'lg'} py={{ lg: 5, base: 3 }}>
				<HStack
					alignSelf={'center'}
					justifyContent={'space-between'}
					my={5}
					w={'90%'}
				>
					<Heading
						color={'#afafaf'}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'left'}
						fontSize={{ xl: '2xl', lg: 'xl', base: 'lg' }}
					>
						Send Pack
					</Heading>

					<Button
						variant={'ghost'}
						px={5}
						py={1}
						position={'absolute'}
						top={0}
						right={0}
						justifyContent={'flex-end'}
						_hover={{ bg: 'transparent' }}
						_pressed={{ bg: 'transparent' }}
						onPress={closeModal}
						alignItems={'center'}
					>
						<CloseIcon sx={{ color: '#AFAFAF' }} />
					</Button>
				</HStack>
				<Box w={'90%'} mt={{ lg: 5, base: 3 }} alignSelf={'center'}>
					<HStack
						backgroundColor={'#1c1b1b'}
						w={'100%'}
						borderRadius={10}
						// py={{ md: 2, base: 1 }}
						_focus={{ bg: 'transparent', borderWidth: 0 }}
					>
						<Input
							w={'100%'}
							pl={1}
							py={4}
							color={'#AFAFAF'}
							fontSize={{ xl: '20', lg: '18', md: '20', base: '16' }}
							borderWidth={0}
							rounded={'lg'}
							borderColor={'transparent'}
							bg={'1C1B1B'}
							placeholder={'Search tags in your library'}
							value={value}
							onChangeText={(e) => {
								handleChangeCollaborator(e);
							}}
							_important={{
								bg: 'transparent',
								borderWidth: 0.5,
								borderColor: '#afafaf',
								_focus: { borderColor: '#2eba55', shadow: 0 },
							}}
							focusOutlineColor={'#2eba55'}
							InputLeftElement={
								<Image
									source={require('../../assets/Icons/searchIcon.png')}
									alt='Search Field'
									color='#ffff'
									size={{ xl: 5, lg: 5, md: 5.5, base: 4 }}
									mx={3}
									alignItems={'center'}
								/>
							}
						/>
					</HStack>
				</Box>
				<Box alignSelf={'center'} width={'90%'} my={{ xl: 8, base: 4 }}>
					<ScrollView maxH={'390px'} showsVerticalScrollIndicator={false}>
						{data.length > 0 ? (
							data.map((item, index) => (
								<HStack my={2} key={'key_' + index}>
									<Box w={'15%'} alignSelf={'center'}>
										<Image
											source={item.profilePic ? item.profilePic : profile}
											size={{ xl: 16, lg: 12, md: 10, base: 8 }}
											alignSelf={'center'}
											borderRadius={50}
										/>
									</Box>
									<Heading
										fontSize={{ xl: '20px', lg: '18px', base: '16px' }}
										fontWeight={'normal'}
										fontFamily={'Poppins'}
										color={'#fff'}
										alignSelf={'center'}
										w={'75%'}
										px={2}
									>
										{item.userName
											? item.userName
											: item.firstname + ' ' + item.lastname}
									</Heading>
									<Button
										size={'sm'}
										bg='#1c1b1b'
										rounded={'md'}
										alignSelf={'center'}
										_hover={{ bg: 'transparent' }}
										_focused={{ bg: 'transparent' }}
										_pressed={{ bg: 'transparent' }}
										onPress={() => {
											handleFAQDrop(item._id);
										}}
									>
										<Heading
											px={1}
											color={selected.includes(item._id) ? '#f00' : '#AFAFAF'}
											fontWeight={'normal'}
											fontFamily={'Poppins'}
											alignSelf={'center'}
											fontSize={{ xl: '36px', lg: '30px', base: '20px' }}
										>
											{selected.includes(item._id) ? '-' : '+'}
										</Heading>
									</Button>
								</HStack>
							))
						) : (
							<Heading
								w={'100%'}
								color={'#afafaf'}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								alignSelf={'center'}
								fontSize={{ xl: '18px', lg: '16px', base: 'xs' }}
								justifyContent={'center'}
								textAlign={'center'}
							>
								No user found
							</Heading>
						)}
					</ScrollView>
					<Button
						size={'sm'}
						bg='#2EBA55'
						borderWidth={1}
						borderColor='#2EBA55'
						_text={{
							color: '#fff',
							fontSize: {
								lg: '18px',
								md: '16px',
								sm: '14px',
								base: '12px',
							},
							fontFamily: 'Poppins',
						}}
						_hover={{
							bg: 'transparent',
							_text: {
								color: '#2EBA55',
							},
						}}
						onPress={() => {
							sendFile();
						}}
						mt={8}
						px={8}
						rounded={'md'}
						alignSelf={'flex-end'}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: '#2EBA55' }}
					>
						{selected.length === 0
							? 'SEND'
							: 'SEND TO ' + selected.length + ' PERSON'}
					</Button>
				</Box>
			</Box>
			{showCopyLink ? (
				<Box rounded='md' w={'90%'} backgroundColor={'#1c1b1b'} my={2}>
					<Box w={'90%'} alignSelf={'center'}>
						<Box>
							<Text
								fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
								color='#afafaf'
								fontWeight={'semibold'}
								py={3}
							>
								Get Link
							</Text>
							<Text
								fontSize={{ lg: '16px', md: '14px', sm: '10px' }}
								color='#afafaf'
								py={2}
							>
								Anyone with this link can open the pack
							</Text>
						</Box>

						<Box my={3} mb={5} w={'100%'} alignSelf={'center'}>
							<Button
								bg={'#2EBB55'}
								borderWidth={1}
								borderColor={'#2EBB55'}
								_hover={{
									bg: 'transparent',
									_text: { color: '#2EBB55' },
								}}
								_pressed={{
									bg: '#2EBB55',
									_text: { color: '#e3e3e3' },
								}}
								_text={{
									color: '#e3e3e3',
									fontSize: { xl: '18px', base: '12px' },
									fontFamily: 'Poppins',
									textAlign: 'center',
									textTransform: 'uppercase',
									fontWeight: 'medium',
								}}
								w={'30%'}
								py={2}
								alignSelf={'flex-end'}
								onPress={() => {
									copyBeatPack();
								}}
							>
								Copy Link
							</Button>
						</Box>
					</Box>
				</Box>
			) : (
				''
			)}
		</Modal>
	);
}
