import {
  Box,
  Button,
  CloseIcon,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
} from 'native-base';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { resetUserPassword } from '../../Graphql_services/UsersMutation';

import styles from './styles.module.css';

export function ResetPasswordPopup(props) {
  const { openModal, closeModal } = props;
  const [showPassword, setShowPassword] = useState(true);
  const [showConfrimPassword, setShowConfrimPassword] = useState(true);
  const [passwordVal, setPasswordVal] = useState('');
  const [confrimPasswordVal, setConfrimPasswordVal] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  const [UpdatePassword] = useMutation(resetUserPassword, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.updateProfile.success) {
        toast.success('Password updated successfully');
        setErrMsg('');
        closeModal();
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on getting user info', err);
    },
  });

  const PasswordCheck = () => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const uppercasePassword = uppercaseRegExp.test(passwordVal);
    const digitsPassword = digitsRegExp.test(passwordVal);
    if (passwordVal && confrimPasswordVal) {
      if (passwordVal.length >= 8 && confrimPasswordVal.length >= 8) {
        if (passwordVal === confrimPasswordVal) {
          if (uppercasePassword && digitsPassword) {
            UpdatePassword({
              variables: {
                password: passwordVal,
              },
            });
            return true;
          } else {
            setErrMsg(
              '8 character at least with 1 or more upper case and 1 or more number '
            );
            return false;
          }
        } else {
          setErrMsg('Password and confirm password should match ');
          return false;
        }
      } else {
        setErrMsg('Length must be atleast 8 characters');
        return false;
      }
    } else {
      setErrMsg('Password and confirm password should match ');
      return false;
    }
  };
  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      w={{ '2xl': '35%', xl: '45%', md: '50%', base: '90%' }}
      size={'full'}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={20}
    >
      <Box w={'100%'} bg={'#1c1b1b'} rounded={'lg'} py={{ lg: 5, base: 3 }}>
        <HStack
          alignSelf={'center'}
          justifyContent={'space-between'}
          mt={5}
          mb={3}
          w={'90%'}
        >
          <Heading
            w={'100%'}
            color={'#E3E3E3'}
            fontWeight={'medium'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
            textAlign={'center'}
            fontSize={{ xl: '2xl', lg: 'xl', base: 'lg' }}
          >
            Reset Password
          </Heading>

          <Button
            variant={'ghost'}
            px={5}
            py={1}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeModal}
            alignItems={'center'}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>
        <Heading
          w={'70%'}
          color={'#e3e3e3'}
          fontWeight={'normal'}
          fontFamily={'Poppins'}
          alignSelf={'center'}
          textAlign={'center'}
          fontSize={{ xl: '16px', lg: '14px', base: '12px' }}
        >
          A strong password includes numbers, letters and punctuation marks.
        </Heading>
        <Box w={'90%'} mt={{ lg: 5, base: 3 }} alignSelf={'center'}>
          <Box mb={8}>
            <Heading
              color={'#afafaf'}
              fontWeight={'medium'}
              fontFamily={'Poppins'}
              py={2}
              textAlign={'left'}
              fontSize={{ xl: '24px', lg: '20px', base: '16px' }}
            >
              Enter New Password
            </Heading>
            <Input
              w={'100%'}
              pl={1}
              py={3}
              bg={'transparent'}
              fontSize={{ xl: '20', lg: '18', md: '20', base: '16' }}
              borderWidth={0}
              rounded={'md'}
              value={passwordVal}
              _important={{
                bg: 'transparent',
                borderWidth: 0.5,
                borderColor: '#afafaf',
                _focus: { borderColor: '#2eba55', shadow: 0 },
              }}
              color={'#afafaf'}
              focusOutlineColor={'#2eba55'}
              secureTextEntry={showPassword}
              onChange={(e) => setPasswordVal(e.target.value)}
              InputRightElement={
                <IconButton
                  _hover={{ bg: 'transparent' }}
                  _pressed={{ bg: 'transparent' }}
                  onPress={() => setShowPassword(!showPassword)}
                  icon={
                    showPassword ? (
                      <VisibilityOffOutlinedIcon
                        sx={{ color: '#afafaf', fontSize: 20 }}
                      />
                    ) : (
                      <RemoveRedEyeOutlinedIcon
                        sx={{ color: '#afafaf', fontSize: 20 }}
                      />
                    )
                  }
                />
              }
            />
          </Box>
          <Box mb={5}>
            <Heading
              color={'#afafaf'}
              fontWeight={'medium'}
              fontFamily={'Poppins'}
              py={2}
              textAlign={'left'}
              fontSize={{ xl: '24px', lg: '20px', base: '16px' }}
            >
              Confirm Password
            </Heading>
            <Input
              w={'100%'}
              pl={1}
              py={3}
              bg={'transparent'}
              fontSize={{ xl: '20', lg: '18', md: '20', base: '16' }}
              borderWidth={0}
              rounded={'md'}
              value={confrimPasswordVal}
              onChange={(e) => setConfrimPasswordVal(e.target.value)}
              _important={{
                bg: 'transparent',
                borderWidth: 0.5,
                borderColor: '#afafaf',
                _focus: { borderColor: '#2eba55', shadow: 0 },
              }}
              color={'#afafaf'}
              focusOutlineColor={'#2eba55'}
              secureTextEntry={showConfrimPassword}
              InputRightElement={
                <IconButton
                  _hover={{ bg: 'transparent' }}
                  _pressed={{ bg: 'transparent' }}
                  onPress={() => setShowConfrimPassword(!showConfrimPassword)}
                  icon={
                    showConfrimPassword ? (
                      <VisibilityOffOutlinedIcon
                        sx={{ color: '#afafaf', fontSize: 20 }}
                      />
                    ) : (
                      <RemoveRedEyeOutlinedIcon
                        sx={{ color: '#afafaf', fontSize: 20 }}
                      />
                    )
                  }
                />
              }
            />
          </Box>
          <p className={styles.errMsg}>{errMsg}</p>
          <Button
            size={'sm'}
            bg='#2EBA55'
            borderWidth={1}
            borderColor='#2EBA55'
            _text={{
              color: '#fff',
              fontSize: {
                lg: '18px',
                md: '16px',
                sm: '12px',
                base: '12px',
              },
              fontFamily: 'Poppins',
            }}
            _hover={{
              bg: 'transparent',
              _text: {
                color: '#2EBA55',
              },
            }}
            onPress={PasswordCheck}
            my={5}
            px={8}
            rounded={'md'}
            alignSelf={'flex-end'}
            _focused={{ bg: '#2EBA55' }}
            _pressed={{ bg: '#2EBA55' }}
          >
            RESET PASSWORD
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
