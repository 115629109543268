import { gql } from "@apollo/client";


export const JOBTYPE=gql`
query{
    getemploymentTypes{
      success
      message
      employmentTypes{
        name
        _id
      }
    }
  }
  

`