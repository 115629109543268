import React, { useState, useContext } from "react";
import { Box, HStack, Input } from "native-base";
import OtpInput from "react-otp-input";
import { useBreakpointValue } from "native-base";
import { AuthContext } from "../Layout";
import "../Form.css"
const OTPfield = () => {
  const { otpvalue, setOtpValue } = useContext(AuthContext);
  const size = useBreakpointValue({
    base: {
      textAlign: "center",
      width: "33px",
      height: "33px",
      fontSize: "1.1rem",
      marginRight: "1rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
    fontFamily:"poppins"
    },
    sm: {
      width: "60px",
      height: "60px",
      fontSize: "2rem",
      marginRight: "0.6rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
      fontFamily:"poppins"
    },
    md: {
      margin: "0 auto",
      width: "70px",
      height: "70px",
      fontSize: "3rem",
      marginRight: "1rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
      fontFamily:"poppins"
    },
    lg: {
      margin: "0 auto",
      width: "100px",
      height: "100px",
      fontSize: "3rem",
      marginRight: "1rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
      fontFamily:"poppins"
    },
  });
  // const [otpvalue1, setOTPvalue1] = useState("");
  // const [otpvalue2, setOTPvalue2] = useState("");
  // const [otpvalue3, setOTPvalue3] = useState("");
  // const [otpvalue4, setOTPvalue4] = useState("");

  // const handleChange1 = (e) => {
  //   console.log(e.target.value);
  //   setOTPvalue1(e.target.value);
  // };
  // const handleChange2 = (e) => {
  //   console.log(e.target.value);
  //   setOTPvalue2(e.target.value);
  // };
  // const handleChange3 = (e) => {
  //   console.log(e.target.value);
  //   setOTPvalue3(e.target.value);
  // };
  // const handleChange4 = (e) => {
  //   console.log(e.target.value);
  //   setOTPvalue4(e.target.value);
  // };

  // useEffect(() => {
  //   const otp = `${otpvalue1}${otpvalue2}${otpvalue3}${otpvalue4}`;
  //   if (otp.length > 3) {
  //     setOtpValue(otp);
  //   }
  // }, [otpvalue1, otpvalue2, otpvalue3, otpvalue4]);

  return (
    <Box mb={10}>
      <HStack space={3} justifyContent="center">
        <OtpInput
        
          containerStyle={{ textAign: "center" }}
          className="otpfield"
          inputStyle={size}
          value={otpvalue}
          onChange={setOtpValue}
          numInputs={4}
          separator={" "}
        />
        {/* <Input
          onChange={handleChange1}
          type="number"
          color={"white"}
          width={{ md: "100px", sm: "70px", base: "33px" }}
          height={{ md: "100px", sm: "70px", base: "30px" }}
          fontSize={{ md: "2rem", sm: "1.5rem", base: "0.7rem" }}
          textAlign="center"
        />
        <Input
          onChange={handleChange2}
          type="number"
          color={"white"}
          width={{ md: "100px", sm: "70px", base: "33px" }}
          height={{ md: "100px", sm: "70px", base: "30px" }}
          fontSize={{ md: "2rem", sm: "1.5rem", base: "0.7rem" }}
          textAlign="center"
        />
        <Input
          onChange={handleChange3}
          type="number"
          color={"white"}
          width={{ md: "100px", sm: "70px", base: "33px" }}
          height={{ md: "100px", sm: "70px", base: "30px" }}
          fontSize={{ md: "2rem", sm: "1.5rem", base: "0.7rem" }}
          textAlign="center"
        />
        <Input
          onChange={handleChange4}
          type="number"
          color={"white"}
          width={{ md: "100px", sm: "70px", base: "33px" }}
          height={{ md: "100px", sm: "70px", base: "30px" }}
          fontSize={{ md: "2rem", sm: "1.5rem", base: "0.7rem" }}
          textAlign="center"
        /> */}

        {/* <Nbox/>

    <Nbox/>
    <Nbox/>
    <Nbox/> */}
      </HStack>
    
    </Box>
  );
};

export default OTPfield;
