/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { fontSize } from '@mui/system';
import {
  Box,
  Button,
  CheckIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Menu,
  Text,
} from 'native-base';

import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
export function LineGraphFilled() {
  const lables = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const data = {
    labels: lables,
    position: 'top',

    datasets: [
      {
        data: [230, 250, 270, 300, 350, 370, 380],
        label: 'Card',
        borderColor: '#2EBA55',
        fill: true,
        backgroundColor: 'rgba(46, 186, 85, 0.3)',
        tension: 0,
        borderWidth: 2,
        pointStyle: 'circle',
      },
      //   {
      //     data: [10, 15, 20, 33, 30, 20, 35, 25, 28, 30, 35, 30],
      //     label: 'cash',
      //     borderColor: 'rgba(24, 160, 251, 1)',
      //     fill: false,
      //     backgroundColor: 'rgba(24, 160, 251, 1)',
      //     tension: 0.5,
      //     borderWidth: 2,
      //   },
    ],
  };
  const options = {
    opacity: 0.5,

    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          borderDash: [8, 2],
          color: '#fff',
        },
        ticks: {
          precision: 0,
        },
      },
    },
    // responsive: true,

    plugins: {
      legend: {
        // backgroundColor: "#000",
        //color: "#0000",
        position: 'bottom',

        align: 'start',
      },
    },
  };

  return (
    <Box w={'100%'}>
      <Box w={'100%'} alignSelf={'center'} rounded={'lg'} bg={'#1c1b1b'} py={5}>
        <Box w={'90%'} alignSelf={'center'}>
          <Line options={options} data={data} />
        </Box>
      </Box>
    </Box>
  );
}
