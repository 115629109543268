/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Link,
  Text,
} from 'native-base';
import { Bar } from 'react-chartjs-2';
import profile from '../../assets/Growth/profile.png';
import './styles.css';

function MyReferrals() {
  const lables = [
    'july 20',
    'july 25',
    'july 30',
    'Aug 1',
    'Aug 5',
    'Aug 10',
    'Aug 15',
    'Aug 20',
    'Aug 25',
    'Aug 30',
  ];
  const data = {
    labels: lables,
    position: 'top',

    datasets: [
      {
        data: [230, 350, 270, 300, 360, 270, 280, 220, 300, 350, 200],
        label: 'Clicks',
        borderColor: '#2EBA55',
        fill: true,
        backgroundColor: 'rgba(46, 186, 85, 0.3)',
        tension: 0,
        borderWidth: 2,
        pointStyle: 'circle',
      },
      {
        data: [360, 270, 280, 220, 300, 350, 230, 350, 270, 270, 280],
        label: 'Sign Ups',
        borderColor: 'rgba(24, 160, 251, 1)',
        fill: false,
        backgroundColor: 'rgba(24, 160, 251, 1)',
        tension: 0.5,
        borderWidth: 2,
      },
    ],
  };
  const options = {
    opacity: 0.5,
    // barThickness: 10,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          borderDash: [8, 2],
          color: '#fff',
        },
        ticks: {
          precision: 0,
        },
      },
    },
    axisX: {
      title: 'Social label',
      reversed: true,
    },
    // responsive: true,

    plugins: {
      legend: {
        position: 'top',

        align: 'end',
        paddingBottom: 5,
      },
    },
  };

  return (
    <Box w={'100%'}>
      <Heading
        my={5}
        fontSize={{ xl: '24px', lg: '20px', md: '18px', base: '16px' }}
        color={'#fff'}
        textTransform={'capitalize'}
        fontFamily={'poppins'}
        fontWeight={'medium'}
      >
        User OverView
        <Heading
          my={5}
          mx={3}
          fontSize={{ xl: '16px', lg: '14px', md: '12px', base: '10px' }}
          color={'#afafaf'}
          textTransform={'capitalize'}
          fontFamily={'poppins'}
          fontWeight={'medium'}
        >
          {'(for last 30 days)'}
        </Heading>
      </Heading>
      <HStack w={'100%'}>
        <div className='verticalText'>User OverView</div>
        <Box w={'90%'} alignSelf={'center'}>
          <Bar options={options} data={data} />
        </Box>
      </HStack>
      <HStack my={5}>
        <Box mr={10}>
          <Heading
            my={3}
            fontSize={{ xl: '20px', lg: '18px', md: '16px', base: '14px' }}
            textTransform={'capitalize'}
            fontFamily={'poppins'}
            color={'#fff'}
            fontWeight={'normal'}
          >
            Clicks
          </Heading>
          <Heading
            fontSize={{ xl: '20px', lg: '18px', base: '14px' }}
            color={'#fff'}
            textTransform={'capitalize'}
            fontFamily={'poppins'}
            fontWeight={'medium'}
          >
            2,000
          </Heading>
        </Box>
        <Box mr={10}>
          <Heading
            my={3}
            fontSize={{ xl: '20px', lg: '18px', base: '14px' }}
            textTransform={'capitalize'}
            fontFamily={'poppins'}
            textAlign={'center'}
            color={'#fff'}
            fontWeight={'normal'}
          >
            Sign Ups
          </Heading>
          <Heading
            fontSize={{ xl: '20px', lg: '18px', base: '14px' }}
            color={'#fff'}
            textTransform={'capitalize'}
            fontFamily={'poppins'}
            fontWeight={'medium'}
            textAlign={'center'}
          >
            523
          </Heading>
        </Box>
      </HStack>
    </Box>
  );
}

export default MyReferrals;
