/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
  Box,
  Button,
  Checkbox,
  CloseIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Menu,
  Modal,
  Pressable,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import { useNavigate } from 'react-router';
import { fontSize } from '@mui/system';
import { SearchFieldComponent } from '../SearchField';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Slider } from '@mui/material';

export function BpmPopup(props) {
  const {
    openModal,
    closeModal,
    setKey,
    selectedKey,
    scale,
    setScale,
    bpm,
    setBPM,
    isPack,
  } = props;

  const dataScales = [
    'Major',
    'Minor',
    'Major Pentatonic',
    'Minor  Pentatonic',
    'Whole Tone',
    'Chromatic',
    'Blues',
  ];
  const dataKeys = ['c', 'C#/Bd', '#lofi', '#FFF', 'f', 'E', 'A#/BD'];

  const handleChange = (event, newValue) => {
    setBPM(newValue);
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      w={{ xl: '50%', md: '50%', base: '90%' }}
      size={'full'}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={20}
    >
      <Box w={'90%'} bg={'#141313'} rounded={'lg'}>
        <HStack
          alignSelf={'center'}
          justifyContent={'space-between'}
          my={5}
          w={'90%'}
        >
          <Heading
            w={'100%'}
            color={'#e3e3e3'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
            textAlign={'center'}
            fontSize={{ lg: '2xl', base: 'lg' }}
          >
            {isPack ? 'Bpm' : 'Key & Bpm'}
          </Heading>

          <Button
            variant={'ghost'}
            px={5}
            // py={3}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeModal}
            alignItems={'center'}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>
        <Box w={'90%'} alignSelf={'center'}>
          <Heading
            my={5}
            w={'100%'}
            color={'#AFAFAF'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            textAlign={'left'}
            fontSize={{ lg: '2xl', base: 'lg' }}
          >
            Bpm
          </Heading>
          <HStack
            w={'100%'}
            my={2}
            alignSelf={'center'}
            justifyContent={'space-between'}
          >
            <Heading
              color={'#e3e3e3'}
              fontWeight={'normal'}
              fontFamily={'Poppins'}
              fontSize={{ lg: 'lg', base: 'sm' }}
            >
              10
            </Heading>
            <Heading
              color={'#e3e3e3'}
              fontWeight={'normal'}
              fontFamily={'Poppins'}
              fontSize={{ lg: 'lg', base: 'sm' }}
            >
              200
            </Heading>
          </HStack>

          <HStack w={'100%'} alignSelf={'center'} mb={5}>
            <Box bg={'#2ebb55'} p={1.5} rounded={'full'} alignSelf={'center'}>
              <Box bg={'#fff'} p={1.5} rounded={'full'} alignSelf={'center'} />
            </Box>

            <Slider
              sx={{
                color: '#2EBA55',
                borderRadius: 0,
              }}
              // getAriaLabel={() => 'BPM range'}
              value={bpm}
              onChange={handleChange}
              valueLabelDisplay='auto'
              // getAriaValueText={valuetext}
              min={10}
              max={200}
            />
            <Box bg={'#2ebb55'} p={1.5} rounded={'full'} alignSelf={'center'}>
              <Box bg={'#fff'} p={1.5} rounded={'full'} alignSelf={'center'} />
            </Box>
          </HStack>
        </Box>
        {isPack ? (
          ''
        ) : (
          <>
            <Box w={'90%'} alignSelf={'center'}>
              <Heading
                my={5}
                w={'100%'}
                color={'#AFAFAF'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                textAlign={'left'}
                fontSize={{ lg: 'lg', base: 'sm' }}
              >
                Key\Scale
              </Heading>
            </Box>
            <HStack
              flexGrow={1}
              alignSelf={'center'}
              flexWrap={'wrap'}
              width={'90%'}
              my={8}
            >
              <Menu
                rounded={'md'}
                borderColor={'#fff'}
                borderWidth={1}
                alignSelf={'center'}
                color={'#afafaf'}
                fontSize={{ md: '16px' }}
                mt={2}
                w={'12vw'}
                _text={{
                  color: '#afafaf',
                }}
                background={'#1C1B1B'}
                placement={'bottom'}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      bg={'#1C1B1B'}
                      rounded={'sm'}
                      mr={2}
                      w={'30%'}
                      _hover={{ bg: '#1C1B1B' }}
                      _focused={{ bg: '#1C1B1B' }}
                      _pressed={{ bg: '#1C1B1B' }}
                      accessibilityLabel='More options menu'
                      {...triggerProps}
                    >
                      <HStack>
                        <Heading
                          color={'#afafaf'}
                          textAlign={'left'}
                          py={2}
                          px={2}
                          w={'85%'}
                          alignSelf={'flex-start'}
                          fontFamily={'Poppins'}
                          fontWeight={'normal'}
                          fontSize={'md'}
                        >
                          {selectedKey}
                        </Heading>
                        <KeyboardArrowDownRoundedIcon
                          sx={{
                            color: '#AFAFAF',
                            fontSize: 30,
                            alignSelf: 'center',
                          }}
                        />
                      </HStack>
                    </Pressable>
                  );
                }}
              >
                {dataKeys.map((item, index) => (
                  <Menu.Item
                    bg={'#1C1B1B'}
                    m={0}
                    p={0}
                    _focus={{ bg: 'transparent' }}
                    _hover={{ bg: 'transparent' }}
                    _pressed={{ bg: 'transparent' }}
                    borderTopWidth={index === 0 ? '0' : '1px'}
                    borderTopColor='#afafaf'
                    onPress={() => setKey(item)}
                  >
                    <Heading
                      color={'#fff'}
                      textAlign={'center'}
                      py={1}
                      pr={2}
                      fontFamily={'Poppins'}
                      fontWeight={'normal'}
                      fontSize={{ xl: 'md', base: 'xs' }}
                    >
                      {item}
                    </Heading>
                  </Menu.Item>
                ))}
              </Menu>
              <Menu
                rounded={'md'}
                borderColor={'#fff'}
                borderWidth={1}
                alignSelf={'center'}
                color={'#afafaf'}
                fontSize={{ md: '16px' }}
                mt={2}
                w={'15vw'}
                _text={{
                  color: '#afafaf',
                }}
                background={'#1C1B1B'}
                placement={'bottom'}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      bg={'#1C1B1B'}
                      rounded={'sm'}
                      ml={2}
                      w={'35%'}
                      _hover={{ bg: '#1C1B1B' }}
                      _focused={{ bg: '#1C1B1B' }}
                      _pressed={{ bg: '#1C1B1B' }}
                      accessibilityLabel='More options menu'
                      {...triggerProps}
                    >
                      <HStack>
                        <Heading
                          color={'#afafaf'}
                          textAlign={'left'}
                          py={2}
                          px={2}
                          w={'90%'}
                          fontFamily={'Poppins'}
                          fontWeight={'normal'}
                          fontSize={'md'}
                        >
                          {scale}
                        </Heading>
                        <KeyboardArrowDownRoundedIcon
                          sx={{
                            color: '#AFAFAF',
                            fontSize: 30,
                            alignSelf: 'center',
                          }}
                        />
                      </HStack>
                    </Pressable>
                  );
                }}
              >
                {dataScales.map((item, index) => (
                  <Menu.Item
                    bg={'#1C1B1B'}
                    m={0}
                    p={0}
                    _focus={{ bg: 'transparent' }}
                    _hover={{ bg: 'transparent' }}
                    _pressed={{ bg: 'transparent' }}
                    borderTopWidth={index === 0 ? '0' : '1px'}
                    borderTopColor='#afafaf'
                    onPress={() => setScale(item)}
                  >
                    <Heading
                      w={'100%'}
                      color={'#fff'}
                      textAlign={'center'}
                      py={1}
                      fontFamily={'Poppins'}
                      fontWeight={'normal'}
                      fontSize={{ xl: 'md', base: 'xs' }}
                    >
                      {item}
                    </Heading>
                  </Menu.Item>
                ))}
              </Menu>
            </HStack>
          </>
        )}
      </Box>
    </Modal>
  );
}
