import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import useRemoveFavouriteSongMutation from '../../../components/Hooks/TalentDashboard/Songs/RemoveFavouriteSong/useRemoveFavouriteSongMutation.js';
import useFavouriteSongMutation from '../../../components/Hooks/TalentDashboard/Songs/AddTofavouriteSongs/useFavouriteSongMutation';
import {
	Grid,
	Select,
	FormControl,
	MenuItem,
	TextField,
	Box,
	Menu,
	Button,
	Autocomplete,
} from '@mui/material';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from 'mui-datatables';
import { Loader } from '../../../components/Loader';

import { getLogo } from '../../../helpers';
import SideBar from '../../../components/SideBar/SideBar';
import { DashboardHeader } from '../../../components/Headers';
import { GETFAVOURITESONGS } from '../../../components/Hooks/TalentDashboard/Songs/GetFavouriteSONG/Query.js';
import arrowBack from '../../../assets/Icons/arrowLeft.png';
import Heart from '../../../assets/Icons/heart.png';
import arrowDownRed from '../../../assets/Icons/arrowDownRed.png';
import activeHeart from '../../../assets/Icons/greenHeart.png';
import download from '../../../assets/Icons/download.png';
import notification from '../../../assets/header/icon/notification.png';
import filter from '../../../assets/Icons/filter.png';
import mask from '../../../assets/Icons/mask.png';
import profileDelete from '../../../assets/Icons/profile-delete.png';
import ApplyFilters from '../../../components/TalentDashboard/Tabs/ApplyFiters';
import styles from './styles.module.css';

const TalentDashboardCharts = () => {
	const { state } = useLocation();
	const { data } = useQuery(GETFAVOURITESONGS, {
		context: { clientName: 'user' },
	});
	const { addFavoriteSong } = useFavouriteSongMutation();
	const { removeFavoriteSong } = useRemoveFavouriteSongMutation();
	const [platformList, setplatformList] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [activePlatformList, setActivePlatformList] = useState('Spotify');
	const [songList, setSongList] = useState([]);
	const [activeSong, setActiveSong] = useState('Songs');
	const [globalList, setGlobalList] = useState(['Global']);
	const [activeGlobal, setActiveGlobal] = useState('Global');
	const [wmList, setWMList] = useState([]);
	const [activeWM, setActiveWM] = useState('Weekly');
	const [topSongList, setTopSongList] = useState([]);
	const [activeTopSong, setActiveTopSong] = useState('Top Songs');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [fav, setFav] = useState([]);
	const open = Boolean(anchorEl);
	const handleClickFilter = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const addFav = (uuid) => {
		if (fav.includes(uuid)) {
			setFav((fav) => fav.filter((item) => item !== uuid));
			removeFavoriteSong({
				variables: {
					songId: uuid,
				},
				context: { clientName: 'user' },
			});
		} else {
			// console.log(uuid);
			addFavoriteSong({
				variables: {
					songId: uuid,
				},
				context: { clientName: 'user' },
			});
			setFav([...fav, uuid]);
		}
	};
	const CustomPaper = (props) => {
		return (
			<div className={styles.customPaper}>
				<Paper {...props} />
			</div>
		);
	};
	const navigate = useNavigate();

	const handleChange = (event) => {
		// console.log('handleChange : ', event)
		setActivePlatformList(event);
	};
	const handleChangeSong = (event) => {
		// setTableData([]);
		setActiveSong(event.target.value);
	};
	const handleChangeGlobal = (event) => {
		console.log(event);
		setActiveGlobal(event);
	};
	const handleChangeWM = (event) => {
		setActiveWM(event.target.value);
	};
	const handleChangeTopSong = (event) => {
		setActiveTopSong(event);
	};
	const handleBack = () => {
		navigate(-1);
	};
	const configOptions = (method, url) => {
		return {
			method: method,
			url: url,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};
	};

	const getPlatfrom = async () => {
		try {
			setIsLoading(true);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/chart/song/platforms`
			);

			const response = await axios(config);
			const { items } = response.data;
			let platform = items.map((item) => {
				return item.name;
			});
			if (platform) {
				setplatformList(platform);
				setIsLoading(false);
			}
		} catch (err) {
			console.log(err);
		}
	};
	let counter = 0;
	const getSong = async (data) => {
		const { change, doc } = data;
		const config = configOptions(
			'get',
			`https://sandbox.api.soundcharts.com/api/v2.8/song/7d534228-5165-11e9-9375-549f35161576`
		);
		const response = await axios(config);
		const { object } = response.data;
		const { releaseDate, name, label, imageUrl, creditName, uuid, isrc } =
			object;
		if (counter === 0) {
			if (Object.keys(isrc).length > 0) {
				console.log([...globalList], 'in get song');
				setGlobalList([...globalList, isrc.countryName]);
			}
			let data = [
				{ releaseDate, name, label, imageUrl, creditName, uuid, change, doc },
			];
			setTableData([...data]);
			counter++;
		}

		//return { releaseDate, name, label };
	};
	const getChart = async () => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.14/chart/song/global-28/ranking/latest?offset=0&limit=100`
			);
			const response = await axios(config);
			const { items } = response.data;

			let songs = items.map((item) => {
				let temp = {
					change: item?.positionEvolution,
					doc: item?.timeOnChart,
				};
				return temp;
			});
			if (songs) {
				let data = songs.map(async (item) => {
					await getSong(item);
				});

				// if (data) {
				// 	console.log(data);
				// }
				//setTableData(data);
			}
		} catch (err) {
			console.log(err);
		}
	};
	const getSingleAlbum = async (data, td = []) => {
		const { change, doc, slug } = data;
		const config = configOptions(
			'get',
			`https://sandbox.api.soundcharts.com/api/v2.15/album/by-slug/when-we-all-fall-asleep-where-do-we-go-1`
		);
		const response = await axios(config);

		const { object } = response.data;
		const { releaseDate, name, label, imageUrl, creditName, artist } = object;

		if (counter === 0) {
			// if (Object.keys(isrc).length > 0) {
			// 	console.log([...globalList], 'in get song');
			// 	setGlobalList([...globalList, isrc.countryName]);
			// }
			let data = td;
			data.push({
				releaseDate,
				name,
				label,
				imageUrl,
				creditName,
				artist,
				change,
				doc,
			});
			console.log('data on album : ', data);
			setTableData([...data]);
			counter++;
		}

		//return { releaseDate, name, label };
	};

	const getSingleSong = async (data, td = []) => {
		const { change, doc, slug } = data;
		const config = configOptions(
			'get',
			`https://sandbox.api.soundcharts.com/api/v2.8/song/7d534228-5165-11e9-9375-549f35161576`
		);
		const response = await axios(config);

		const { object } = response.data;
		const { releaseDate, name, label, imageUrl, creditName, artist } = object;

		if (counter === 0) {
			// if (Object.keys(isrc).length > 0) {
			// 	console.log([...globalList], 'in get song');
			// 	setGlobalList([...globalList, isrc.countryName]);
			// }
			let data = td;
			data.push({
				releaseDate,
				name,
				label,
				imageUrl,
				creditName,
				artist,
				change,
				doc,
			});
			console.log('data on album : ', data);
			setTableData([...data]);
			counter++;
		}

		//return { releaseDate, name, label };
	};
	const getAlbum = async () => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/chart/album/top-200-metal/ranking/latest?offset=0&limit=100`
			);
			const response = await axios(config);
			const { items } = response.data;

			let album = items.map((item) => {
				let temp = {
					change: item?.positionEvolution,
					doc: item?.timeOnChart,
					slug: item?.album?.slug,
				};
				return temp;
			});
			if (album) {
				let data = album.map(async (item) => {
					await getSingleAlbum(item);
				});

				// if (data) {
				// 	console.log(data);
				// }
				//setTableData(data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const getAlbumEvolution = async (slug, data) => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/chart/album/top-200-metal/ranking/latest?offset=0&limit=100`
			);

			const response = await axios(config);
			if (response.status === 200) {
				const { items } = response.data;
				let album = items.map((item) => {
					let temp = {
						change: item?.positionEvolution,
						doc: item?.timeOnChart,
						slug: item?.album?.slug,
					};
					return temp;
				});
				if (album) {
					// album.map(async (item) => {
					// 	await getSingleAlbum(item);
					// });
					getSingleAlbum(album[0], data);
					// if (data) {
					// 	console.log(data);
					// }
					//setTableData(data);
				}
			}
		} catch (err) {}
	};

	const getSongEvolution = async (slug, data) => {
		try {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2.14/chart/song/global-28/ranking/2020-10-06T18:00:01+00:00?offset=0&limit=100`
			);

			const response = await axios(config);
			if (response.status === 200) {
				const { items, related } = response.data;
				let songs = items.map((item) => {
					let temp = {
						change: item?.positionEvolution,
						doc: item?.timeOnChart,
						slug: related?.chart?.slug,
						uuid: item?.song?.uuid,
					};
					return temp;
				});
				if (songs && songs.length > 0) {
					// album.map(async (item) => {
					// 	await getSingleAlbum(item);
					// });
					getSingleSong(songs[0], data);
					// if (data) {
					// 	console.log(data);
					// }
					//setTableData(data);
				}
			}
		} catch (err) {
			console.log('error :', err);
		}
	};

	const getChartAlbum = async (platform, country, data) => {
		try {
			let country_url = '';
			if (country) {
				country_url = `countryCode=${country}&`;
			}
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/chart/album/by-platform/${platform.toLowerCase()}?${country_url}offset=0&limit=100`
			);

			const response = await axios(config);
			if (response.status === 200) {
				const { items } = response.data;
				getAlbumEvolution(items[0].slug, data);
				// items.map((item) => getAlbumEvolution(item.slug))
			}
		} catch (err) {}
	};

	const getChartSong = async (platform, country, data) => {
		try {
			let country_url = '';
			if (country) {
				country_url = `countryCode=${country}&`;
			}
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/chart/song/by-platform/${platform.toLowerCase()}?${country_url}offset=0&limit=100`
			);

			const response = await axios(config);
			if (response.status === 200) {
				const { items } = response.data;
				getSongEvolution(items[0].slug, data);

				// items.map((item) => Album(item.slug))
			}
		} catch (err) {}
	};

	const viewDetail = (uuid) => {
		navigate(`/talent-dashboard/song/?uuid=${uuid}`);
	};
	useEffect(() => {
		getPlatfrom();
		getChart();
		setSongList(['Songs', 'Artist', 'Album']);
		setGlobalList([{ countryName: 'Global', value: '-1', countryCode: '0' }]);
		setWMList(['Weekly', 'Monthly']);
		setTopSongList([
			'Top Songs',
			'Airplay',
			'Amazon',
			'Anghami',
			'Apple Music',
		]);
	}, []);
	// useEffect(() => {
	// 	if (activeSong === 'Album') {
	// 		getAlbum();
	// 	}
	// }, [activeSong]);
	const columns = [
		{
			name: 'action',
			label: '#',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return <p>{tableMeta.rowIndex + 1}</p>;
				},
			},
		},
		{
			name: 'action',
			label: 'CHANGE',
			options: {
				filter: false,

				customBodyRender: (value, tableMeta, updateValue) => {
					let data = tableData[tableMeta.rowIndex];

					return (
						<div>
							<p className={styles.changeSong}>{data.change}</p>
						</div>
					);
				},
			},
		},
		{
			name: 'action',
			label: 'SONG',
			options: {
				filter: false,

				customBodyRender: (value, tableMeta, updateValue) => {
					let data = tableData[tableMeta.rowIndex];

					return (
						<div
							className={styles.songData}
							onClick={() => viewDetail(data.uuid)}
						>
							<img
								src={data.imageUrl}
								alt={data.name}
								className={styles.songCover}
							/>
							<div>
								<p className={styles.songName}>{data.name}</p>
								<p className={styles.songCreditName}>{data.creditName}</p>
							</div>
						</div>
					);
				},
			},
		},
		{
			name: 'label',
			label: 'LABEL',
			options: {
				filter: true,
				sort: true,
			},
		},

		{
			name: 'action',
			label: 'RELEASE DATE',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					let data = tableData[tableMeta.rowIndex];
					return <div>{data.releaseDate.split('T')[0]}</div>;
				},
			},
		},
		{
			name: 'action',
			label: 'DOC',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					let data = tableData[tableMeta.rowIndex];

					return (
						<div>
							<p className={styles.songDOC}>{data.doc}</p>
						</div>
					);
				},
			},
		},
		{
			name: '',
			label: '',
			options: {
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					let data = tableData[tableMeta.rowIndex];

					return (
						<div>
							<img
								src={fav?.includes(data.uuid) ? activeHeart : Heart}
								onClick={() => addFav(data.uuid)}
								className={styles.favirotes}
								alt='heart icon'
							/>
						</div>
					);
				},
			},
		},
	];

	const options = {
		responsive: 'vertical',
		selectableRows: false,
		rowsPerPage: 100,
		search: false,
		print: false,
		download: false,
		filter: false,
		viewColumns: false,
		rowsPerPageOptions: [100, 200, 500],
	};

	useEffect(() => {
		if (data) {
			setFav(data.getMyFavoriteSongs.favoriteSongs);
		}
	}, [data]);

	const filter_handler = () => {
		console.log('handler : ', activeSong, activeGlobal, activePlatformList);
		// let country =
		let country = activeGlobal;
		if (country === 'United States') {
			country = 'US';
		} else if (country === 'Global') {
			country = '';
		}
		let data = [];
		setTableData(data);
		if (activeSong === 'Song') {
			getChartSong(activePlatformList, country, data);
		} else if (activeSong === 'Album') {
			getChartAlbum(activePlatformList, country, data);
		}
	};

	useEffect(() => {
		console.log('table data : ', tableData);
	}, [tableData]);
	return (
		<Grid container className={styles.bgBlack}>
			{/* <Grid item xs={0} md={2} className={styles.rightBorder}>
				<SideBar />
			</Grid> */}
			<Grid item xs={12} md={12}>
				{/* <Grid item xs={12} md={12} className={styles.bottomBorder}>
					<DashboardHeader />
				</Grid> */}
				<Grid className={styles.content}>
					<Grid container direction='row'>
						<Grid item md={8} sm={12} className={styles.headingDiv}>
							<img
								src={arrowBack}
								alt='back icon'
								className={styles.backIcon}
								onClick={() => handleBack()}
							/>{' '}
							&nbsp; &nbsp;
							<p className={styles.heading}>Charts</p>
						</Grid>
						{/* <Grid item md={4} sm={12} className={styles.iconParentDiv}>
							<div className={styles.iconDiv}>
								<img
									src={notification}
									alt='notification icon'
									className={styles.icons}
								/>
							</div>{' '}
							&nbsp;&nbsp;
							<div className={styles.iconDiv}>
								<img src={Heart} alt='Heart icon' className={styles.icons} />
							</div>
							&nbsp;&nbsp;
							<div className={styles.iconDiv}>
								<img
									src={download}
									alt='download icon'
									className={styles.icons}
								/>
							</div>
						</Grid> */}
					</Grid>
					<Grid container direction='row'>
						<Grid item col>
							<FormControl sx={{ m: 1 }}>
								<Autocomplete
									disablePortal
									id='combo-box-demo'
									className={styles.customAutoComplete}
									disableClearable={true}
									defaultValue={activePlatformList}
									options={platformList}
									PaperComponent={CustomPaper}
									renderOption={(props, option) => (
										<Box
											component='li'
											sx={{
												'& > img': { mr: 2, flexShrink: 0 },
												'&:focus': {
													background: '#2EBB55',
												},
											}}
											{...props}
										>
											{getLogo(option)}
											{option}
										</Box>
									)}
									onChange={(event, value) => handleChange(value)}
									renderInput={(params) => (
										<TextField
											{...params}
											className={styles.autoCompleteDropdown}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item col>
							<FormControl sx={{ m: 1 }}>
								<Select
									value={activeSong}
									onChange={handleChangeSong}
									displayEmpty
									className={styles.customDropdown}
									inputProps={{
										MenuProps: {
											MenuListProps: {
												sx: {
													backgroundColor: '#1c1b1b',
													color: '#afafaf',
													//  _focus: {backgroundColor:'#2EBB55'}
												},
											},
										},
									}}
								>
									{songList.map((item, index) => (
										<MenuItem
											sx={{
												'&:focus': {
													background: '#2EBB55',
													color: 'white',
												},
												'&:hover': {
													background: '#AFAFAF',
													color: 'white',
												},
											}}
											value={item}
											key={index}
										>
											{item}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item col>
							<FormControl sx={{ m: 1 }}>
								<Autocomplete
									disablePortal
									id='combo-box-demo'
									className={styles.customAutoComplete}
									disableClearable={true}
									defaultValue={activeGlobal}
									options={globalList}
									PaperComponent={CustomPaper}
									renderOption={(props, option) => (
										<Box
											component='li'
											sx={{
												'& > img': { mr: 2, flexShrink: 0 },
												'&:focus': {
													background: '#2EBB55',
												},
											}}
											{...props}
										>
											{option}
										</Box>
									)}
									onChange={(event, value) => handleChangeGlobal(value)}
									renderInput={(params) => (
										<TextField
											{...params}
											className={styles.autoCompleteDropdown}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item col>
							<FormControl sx={{ m: 1 }}>
								<Select
									value={activeWM}
									onChange={handleChangeWM}
									displayEmpty
									className={styles.customDropdown}
									//inputProps={{ 'aria-label': 'Without label' }
									inputProps={{
										MenuProps: {
											MenuListProps: {
												sx: {
													backgroundColor: '#1c1b1b',
													color: '#afafaf',
												},
											},
										},
									}}
								>
									{wmList.map((item, index) => (
										<MenuItem
											sx={{
												'&:focus': {
													background: '#2EBB55',
													color: 'white',
												},
												'&:hover': {
													background: '#AFAFAF',
													color: 'white',
												},
											}}
											value={item}
											key={index}
										>
											{item}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item col>
							<FormControl sx={{ m: 1 }}>
								<Autocomplete
									disablePortal
									id='combo-box-demo'
									className={styles.customAutoComplete}
									disableClearable={true}
									defaultValue={activeTopSong}
									options={topSongList}
									PaperComponent={CustomPaper}
									renderOption={(props, option) => (
										<Box
											component='li'
											sx={{
												'& > img': { mr: 2, flexShrink: 0 },
												'&:focus': {
													background: '#2EBB55',
												},
											}}
											{...props}
										>
											{getLogo(option)}
											{option}
										</Box>
									)}
									onChange={(event, value) => handleChangeTopSong(value)}
									renderInput={(params) => (
										<TextField
											{...params}
											className={styles.autoCompleteDropdown}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item col>
							<Grid item col>
								<div>
									<Button
										id='basic-button'
										aria-controls={open ? 'basic-menu' : undefined}
										aria-haspopup='true'
										aria-expanded={open ? 'true' : undefined}
										className={styles.filterBtn}
										onClick={handleClickFilter}
									>
										<img src={filter} alt='filter icon' />
									</Button>
									<Menu
										id='basic-menu'
										anchorEl={anchorEl}
										className={styles.filterMenu}
										open={open}
										onClose={handleClose}
									>
										<MenuItem onClick={handleClose}>
											<img
												src={mask}
												alt='artist contry'
												className={styles.filterMenuMaskIcon}
											/>{' '}
											&nbsp; &nbsp;&nbsp;Artists Country
										</MenuItem>
										<MenuItem onClick={handleClose}>
											<img
												src={profileDelete}
												alt='block list'
												className={styles.filterMenuIcon}
											/>{' '}
											&nbsp;&nbsp;&nbsp; BlockList
										</MenuItem>
									</Menu>
								</div>
							</Grid>
						</Grid>
						<Grid item col>
							<FormControl
								sx={{ m: 1, height: '78%' }}
								className='country_filter'
							>
								<Button
									variant='outlined'
									className='apply_fitlters'
									onClick={filter_handler}
								>
									Apply
								</Button>
							</FormControl>
						</Grid>
					</Grid>
					{isLoading ? (
						<div className={styles.loaderDiv}>
							<Loader />
						</div>
					) : (
						<>
							<Grid className={''}>
								<div
									style={{ height: '100%', width: '100%' }}
									className={styles.muiTable}
								>
									{/* <DataGrid rows={rows} columns={columns} checkboxSelection /> */}
									<MUIDataTable
										data={tableData}
										columns={columns}
										options={options}
									/>
								</div>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
export default TalentDashboardCharts;
