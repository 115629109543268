import { gql } from "@apollo/client";

export const REMOVEFAVOURITESONGS=gql`
mutation($songId:ID!){
    removeFavoriteSong(songId:$songId){
      success
      message
      isTokenExpired
    }
  }
`