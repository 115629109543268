import { AxiosInstance } from "../../AxiosInstance.js/AxiosInstance";

export const GetTopPlaylists = () => {



  return new Promise((resolve, reject) => {
    AxiosInstance.get("/v2.20/song/7d534228-5165-11e9-9375-549f35161576/playlist/current/spotify?type=all&offset=0&limit=100&sortBy=entryDate&sortOrder=desc"
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
export default GetTopPlaylists;