import React from 'react';

function Reply() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5062 5.5V0L24 9L12.5062 18.5V13C5.31003 13 1.17904 20.3333 0.0130603 24C-0.38672 7.6 8.49533 6 12.5062 5.5Z'
        fill='white'
      />
    </svg>
  );
}

export default Reply;
