/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	CloseIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Menu,
	Modal,
	Pressable,
	ScrollView,
	Text,
	VStack,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import { useNavigate } from 'react-router';
import { fontSize } from '@mui/system';

export function DeletePopup(props) {
	const { openModal, closeModal, deleteFtn, isPack } = props;

	return (
		<Modal
			isOpen={openModal}
			onClose={closeModal}
			w={{ xl: '40%', md: '40%', base: '90%' }}
			size={'full'}
			alignSelf={'center'}
			justifyContent={'flex-start'}
			mt={20}
		>
			<Modal.Content backgroundColor={'#141313'}>
				<Modal.CloseButton
					_hover={{ bg: '#141313' }}
					_focused={{ bg: '#2EBA55' }}
					_pressed={{ bg: 'transparent' }}
					mx={5}
				/>

				<VStack
					pt='20'
					backgroundColor={'#141313'}
					w={'90%'}
					alignSelf={'center'}
				>
					<Image
						w={'30%'}
						height={{ lg: '80px', sm: '50px', base: '30px' }}
						width={{ lg: '80px', sm: '50px', base: '30px' }}
						borderRadius={30}
						borderColor={'#141313'}
						alignSelf={'center'}
						source={require('../../assets/Popup/trash.png')}
						alt='User Profile Pic'
					/>

					<Text
						fontSize={{ lg: '24px', md: '16px', sm: '16px' }}
						fontStyle={'bold'}
						color={'#e3e3e3'}
						py='2'
						alignSelf={'center'}
					>
						{isPack ? 'DELETE PACK' : 'DELETE FILE'}
					</Text>

					<Text
						w={'70%'}
						fontSize={{ lg: '20px', md: '16px', sm: '16px' }}
						fontStyle={'bold'}
						color={'#afafaf'}
						py='2'
						alignSelf={'center'}
						textAlign={'center'}
					>
						{isPack
							? 'Do you really want to delete this pack?'
							: 'Do you really want to delete this file?'}
					</Text>
				</VStack>
				<Button.Group
					justifyContent={'space-between'}
					alignSelf={'center'}
					width={'50%'}
					my={8}
				>
					<Button
						size={'sm'}
						bg='#141313'
						borderStyle={'solid'}
						borderWidth={'1'}
						borderColor={'#2EBA55'}
						w={'40%'}
						rounded={'md'}
						alignSelf={'center'}
						_text={{
							color: '#2EBA55',
							fontSize: {
								lg: '18px',
								md: '16px',
								sm: '14px',
								base: '14px',
							},
							fontFamily: 'Poppins',
						}}
						_hover={{
							bg: '#2EBA55',
							_text: {
								color: '#fff',
							},
						}}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						onPress={() => {
							closeModal();
						}}
					>
						CANCEL
					</Button>
					<Button
						size={'sm'}
						bg='#2EBA55'
						borderWidth={1}
						borderColor='#2EBA55'
						_text={{
							color: '#fff',
							fontSize: {
								lg: '18px',
								md: '16px',
								sm: '14px',
								base: '14px',
							},
							fontFamily: 'Poppins',
						}}
						_hover={{
							bg: 'transparent',
							_text: {
								color: '#2EBA55',
							},
						}}
						onPress={() => {
							deleteFtn();
							closeModal();
						}}
						w={'40%'}
						rounded={'md'}
						alignSelf={'center'}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: '#2EBA55' }}
					>
						DELETE
					</Button>
				</Button.Group>
			</Modal.Content>
		</Modal>
	);
}
