/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Link,
  Text,
} from 'native-base';
import profile from '../../assets/Growth/profile.png';

function SuggestedUsers() {
  return (
    <Box w={'100%'}>
      <Heading
        my={5}
        fontSize={{ xl: '24px', lg: '20px', md: '18px', base: '16px' }}
        color={'#fff'}
        textTransform={'capitalize'}
        fontFamily={'poppins'}
        fontWeight={'medium'}
      >
        Suggested Users
      </Heading>
      <User image={profile} name={'Andrew Girfield'} />
      <User image={profile} name={'Sabrina Carpenter'} />
      <User image={profile} name={'Reece Black'} />
      <User image={profile} name={'Zayn Malik'} />
      <User image={profile} name={'Andrew Girfield'} />
      <User image={profile} name={'Reece Black'} />
    </Box>
  );

  function User(props) {
    const { image, name } = props;
    return (
      <HStack justifyContent={'space-between'} mb={8}>
        <HStack alignSelf={'center'}>
          <Image source={image} size={12} alignSelf={'center'} />
          <Heading
            px={5}
            fontSize={{ xl: '20px', lg: '18px', md: '16px', base: '14px' }}
            color={'#afafaf'}
            textTransform={'capitalize'}
            fontFamily={'poppins'}
            fontWeight={'normal'}
            alignSelf={'center'}
          >
            {name}
          </Heading>
        </HStack>

        <Button
          size={'sm'}
          bg='#2EBA55'
          borderWidth={1}
          borderColor='#2EBA55'
          _text={{
            color: '#fff',
            fontSize: {
              xl: '18px',
              lg: '16px',
              md: '14px',
              base: '12px',
            },
            fontFamily: 'Poppins',
          }}
          _hover={{
            bg: 'transparent',
            _text: {
              color: '#2EBA55',
            },
          }}
          alignSelf={'center'}
          py={{ lg: 3, md: 2, base: 2 }}
          px={{ xl: 10, lg: 8, md: 6, base: 4 }}
          rounded={'md'}
          _focused={{ bg: '#2EBA55' }}
          _pressed={{ bg: '#2EBA55' }}
          onPress={() => {}}
        >
          Follow
        </Button>
      </HStack>
    );
  }
}

export default SuggestedUsers;
