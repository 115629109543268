import { Button, HStack, Image, Text, VStack, Box } from 'native-base';
import { useEffect, useState } from 'react';

import { Modal } from 'native-base';
import React from 'react';
import axios from 'axios';
import { Dashboard } from '@mui/icons-material';
import CursorPlugin from 'wavesurfer.js/src/plugin/cursor';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsEye } from 'react-icons/bs';
import { FaPause, FaPlay } from 'react-icons/fa';
import BeatPackTab from './BeatPackTab';
import { toast } from 'react-toastify';
import { TbArrowForwardUp } from 'react-icons/tb';

const ViewFiles = (props) => {
	const bpObj = props.obj;
	const [showBeats, setShowBeats] = useState(false);
	const [allowedUsers, setAllowedUsers] = useState([]);
	const [userLength, setUserLength] = useState('');
	const [viewFiles, setViewFiles] = useState([]);
	const [shareModal, setShareModal] = useState(false);
	const [copyLink, setCopyLink] = useState('');

	const handleBeatpack = () => {
		setShowBeats(true);
	};

	const headerkey = `Bearer ${localStorage.getItem('token')}`;

	const viewBeatpackFiles = (bp_id) => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getbeatpackfiles/${bp_id}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				setViewFiles(result.files);
				console.log('result.files', result.files);
			})
			.catch((error) => console.log('error', error));
	};

	useEffect(() => {
		viewBeatpackFiles(bpObj._id);
	}, []);
	const handleCopyLink = (fileObj) => {
		console.log('Ghhh');
		navigator.clipboard.writeText(fileObj.file.url);
		toast.success('Share Link Copied');
	};

	return (
		<>
			{showBeats ? (
				<BeatPackTab />
			) : (
				<Box w={'100%'} bg={'#000'}>
					<HStack w={'100%'}>
						<Box>
							<VStack py={1} bg='#000' justifyContent={'space-around'}>
								<VStack w={'100%'} p={1} justifyContent={'space-evenly'}>
									<HStack px='1' py='3' my={'5'}>
										<Button
											onPress={() => {
												handleBeatpack();
											}}
											size={'sm'}
											bg={'#000'}
											w={['5%', '10%', '10%']}
											fontSize={{ lg: '16px', md: '16px', sm: '32px' }}
											alignSelf={'center'}
											_hover={{ bg: 'transparent' }}
											_focused={{ bg: 'transparent' }}
											_pressed={{ bg: 'transparent' }}
										>
											<Image
												source={require('../../assets/Icons/arrowLeft.png')}
												size={['5', '7', '8']}
												mt='0.5'
												opacity={'2'}
												alt='back'
											/>
										</Button>
										<Text
											fontSize={{ lg: '4xl', md: '3xl', base: '2xl' }}
											color='white'
											fontFamily={'Poppins'}
											alignSelf={'center'}
										>
											Packs{' > '}
											{bpObj.beatPackName
												? bpObj.beatPackName
												: 'Untitled BeatPack'}
										</Text>
									</HStack>
									<HStack justifyContent={'center'} my={2}>
										<Image
											height={{ lg: '230px', md: '100px', sm: '60px' }}
											rounded={'md'}
											width={{ lg: '200px', md: '85px', sm: '55px' }}
											alignSelf={'center'}
											source={bpObj.beatPackCover}
											alt='User Profile Pic'
										/>
									</HStack>
									<HStack justifyContent={'center'} my={2}>
										<Text
											fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
											color='white'
											alignSelf={'center'}
										>
											{bpObj.beatPackName}
										</Text>
									</HStack>
									<VStack>
										{viewFiles &&
											viewFiles.map((fileObj, index) => (
												<HStack
													key={index}
													w={'100%'}
													justifyContent={'space-evenly'}
													py={2}
													px={10}
												>
													<Image
														w={'25%'}
														height={{ lg: '90px', md: '100px', sm: '60px' }}
														rounded={'md'}
														width={{ lg: '80px', md: '85px', sm: '55px' }}
														alignSelf={'center'}
														source={
															fileObj.file.audioIconUrl
																? fileObj.file.audioIconUrl
																: require('../../assets/Images/1cover.png')
														}
														//  size={["8", "10", "12"]}
														mt='0.5'
														alt='icon'
													/>

													<Text
														fontSize={{ lg: '24px', sm: '14px', base: '16px' }}
														color='#afafaf'
														w={'50%'}
														py={5}
														alignSelf={'center'}
													>
														{fileObj.file.fileName}
													</Text>
													<HStack w={'25%'} justifyContent={'space-between'}>
														{/* <Button
															bg={'#2EBB55'}
															borderWidth={1}
															borderColor={'#2EBB55'}
															_hover={{
																bg: 'transparent',
																_text: { color: '#2EBB55' },
															}}
															_pressed={{
																bg: '#2EBB55',
																_text: { color: '#e3e3e3' },
															}}
															_text={{
																color: '#e3e3e3',
																fontSize: { xl: '20px', base: 'md' },
																fontFamily: 'Poppins',
																textAlign: 'center',
																textTransform: 'uppercase',
																fontWeight: 'medium',
																px: 3,
															}}
															py={2}
															alignSelf={'center'}
															//   onPress={() => {remFuncObj(audioObj.file._id); toggleState()}}
														>
															Save
														</Button> */}
														<Button
															onPress={() => {
																setCopyLink(fileObj.file.url);
																setShareModal(true);
															}}
															bg={'transparent'}
															alignSelf={'transparent'}
															_hover={{ bg: 'transparent' }}
															_focused={{ bg: 'transparent' }}
															_pressed={{ bg: 'transparent' }}
														>
															<TbArrowForwardUp
																size={30}
																style={{
																	cursor: 'pointer',
																	alignSelf: 'center',
																	color: 'white',
																}}
																// onclick={handleCopyLink(fileObj)}
															/>
														</Button>
														{/* <Button
															//    onPress={() => {handleDelete(fileObj._id)} }
															bg={'transparent'}
															alignSelf={'transparent'}
															_hover={{ bg: 'transparent' }}
															_focused={{ bg: 'transparent' }}
															_pressed={{ bg: 'transparent' }}
														>
															<Image
																py={5}
																mx={5}
																alignSelf={'center'}
																// size="xs"
																w={'10px'}
																h={'30px'}
																source={require('../../assets/Icons/menu.png')}
																alt='User Profile Pic'
															/>
														</Button> */}
													</HStack>
												</HStack>
											))}
									</VStack>
								</VStack>
							</VStack>
						</Box>
					</HStack>
				</Box>
			)}
			<Modal
				isOpen={shareModal}
				onClose={() => setShareModal(false)}
				w={'40%'}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.Content backgroundColor={'transparent'}>
					<Modal.Body backgroundColor={'transparent'} my={2} mx={2}>
						<VStack px={5}>
							<HStack rounded='md' backgroundColor={'#1C1B1B'} h={'40'} my={2}>
								<Modal.CloseButton
									_hover={{ bg: '#1C1B1B' }}
									_focused={{ bg: '#2EBA55' }}
									_pressed={{ bg: 'transparent' }}
									mx={5}
								/>
								<Box w={'100%'}>
									<HStack w={'100%'}>
										<VStack>
											<Text
												fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
												color='#afafaf'
												fontWeight={'semibold'}
												px={5}
												py={3}
											>
												Get Link
											</Text>
											<Text
												fontSize={{ lg: '14px', md: '12px', sm: '12px' }}
												color='#afafaf'
												px={5}
												py={2}
											>
												Anyone with this link can play this song
											</Text>
										</VStack>
									</HStack>
									<HStack m={3} justifyContent={'flex-end'}>
										{/* <Button 
            onPress={() => { 
              setShareModal(false);
              navigator.clipboard.writeText(copyLink);
              toast.success("Share Link Copied");

            }}
            size="md"
            mx={10}
            bg={"#2EBA55"} 
            rounded={"md"}
            _hover={{ bg:  "#1C1B1B"}}
            _focused={{ bg:  "#2EBA55"}}
            _pressed={{ bg:  "#1C1B1B"}}
            alignSelf={"center"} 
            >
           <Text fontSize={{ lg: "20px", md: "18px", sm: "12px" }} color="white" >
              Copy Link
            </Text>
            </Button> */}
										<Button
											bg={'#2EBB55'}
											borderWidth={1}
											borderColor={'#2EBB55'}
											_hover={{
												bg: 'transparent',
												_text: { color: '#2EBB55' },
											}}
											_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
											_text={{
												color: '#e3e3e3',
												fontSize: { xl: '18px', base: 'md' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
												px: 3,
											}}
											py={1}
											alignSelf={'center'}
											onPress={() => {
												setShareModal(false);
												navigator.clipboard.writeText(copyLink);
												toast.success('Share Link Copied');
											}}
										>
											Copy Link
										</Button>
									</HStack>
								</Box>
							</HStack>
						</VStack>
					</Modal.Body>
				</Modal.Content>
			</Modal>
		</>
	);
};
export default ViewFiles;
