import {
	Button,
	HStack,
	Image,
	Text,
	VStack,
	Menu,
	Box,
	Pressable,
	Heading,
	Hidden,
} from 'native-base';
import { useState } from 'react';
import { Modal } from 'native-base';
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import coverImg from '../../assets/Images/1cover.png';
import { toast } from 'react-toastify';
import {
	getUserPaginated,
	searchUsers,
} from '../../Graphql_services/UsersMutation';
import { DeletePopup } from '../PopUp/DeletePopup';
import { SendPackPopup } from '../PopUp/SendPackPopup';
import Reply from '../Icons/Reply';
import Dots from '../Icons/Dot';
import styles from './styles.module.css';
import EditFilePopup from '../PopUp/EditFilePopup';
import { SentPopup } from '../PopUp/SentPopup';
import Pencil from '../Icons/Pencil';
import { DaysHoursMinutes } from '../../helpers/DateFtns';
import DotsMbl from '../Icons/DotMbl';
import ReplyOld from '../Icons/ReplyOld';

const AudioLibraryBody = (props) => {
	const {
		createBeatPack,
		searchResult,
		onEditFile,
		editFileModal,
		showFileModal,
	} = props;
	const sfiles = searchResult;
	var headerkey = props.header;
	const [users, setUsers] = useState([]);
	const [usersAll, setUsersAll] = useState([]);
	const [showInvitetModal, setShowInvite] = useState(false);
	const [showInvitetSent, setInviteSent] = useState(false);
	const [userId, setUserId] = useState('');
	const [fileId, setFileId] = useState('');
	const [shareModal, setShareModal] = useState(false);
	const [copyLink, setCopyLink] = useState('');
	const [showTagList, setShowTagList] = useState([]);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSong, setDeleteSong] = useState(false);
	const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
	const [searchUser, setSearchUser] = useState('');
	const [editFile, setEditFile] = useState(null);
	const [showFilePopup, setShowFilePopup] = useState(false);

	const [getProfileData] = useLazyQuery(getUserPaginated, {
		context: { clientName: 'user' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (data.getAllUsersByUser.success) {
				setUsersAll(data.getAllUsersByUser.users);
				setUsers(data.getAllUsersByUser.users);
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});
	const [searchedUserResult] = useLazyQuery(searchUsers, {
		context: { clientName: 'user' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (data.searchUsers.success) {
				setUsers(data.searchUsers.users);
			} else {
				setUsers(data.searchUsers.users);
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});
	const handleChangeCollaborator = (e) => {
		setSearchUser(e);
		if (e.length > 0) {
			searchedUserResult({ variables: { e: e } });
		} else {
		}
	};
	useEffect(() => {
		getProfileData();
	}, []);

	const downloadAudio = (url, filename) => {
		fetch(url)
			.then((res) => res.blob())
			.then((file) => {
				let tempUrl = URL.createObjectURL(file);
				const aTag = document.createElement('a');
				aTag.href = tempUrl;
				aTag.download = filename;
				document.body.appendChild(aTag);
				aTag.click();
				aTag.remove();
				toast.success('Downloaded Successfully');
			})
			.catch(() => {
				toast.error('Download Unsuccessful');
			});
	};
	const handleUserAccess = (fileId, userId) => {
		var inviteHeaders = new Headers();
		inviteHeaders.append('Authorization', `${props.header}`);
		inviteHeaders.append('Content-Type', 'application/json');
		var raw = JSON.stringify({
			fileId: `${fileId}`,
			accessUserIds: userId,
		});
		var requestUserAccess = {
			method: 'POST',
			headers: inviteHeaders,
			body: raw,
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/giveAccessToUser`,
			requestUserAccess
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					// toast.success(result.message);
					setShowCollaboratorModal(false);
					setShowFilePopup(true);
				}
			})
			.catch((error) => console.log('error', error));
	};
	const options = [
		{ name: 'Edit' },
		{ name: 'Downlaod MP3' },
		{ name: 'Create Pack' },
		{ name: 'Delete File' },
	];
	const inviteList = (id) => {
		if (users.includes(id)) {
			setUsers(users.filter((user) => user !== id));
		} else {
			setUsers([...users, id]);
		}
	};
	const showTagsListItems = (id) => {
		if (!showTagList.includes(id)) {
			setShowTagList([...showTagList, id]);
		}
	};

	function Detailsheading(props) {
		const { name } = props;
		return (
			<Box
				px={{ xl: 5, lg: 4, base: 2 }}
				py={{ xl: 2, lg: 2, base: 1 }}
				m={1}
				borderWidth={'1'}
				borderColor={'#AFAFAF'}
				rounded={'md'}
			>
				<Heading
					fontSize={{ xl: '18px', lg: '16px', base: '10px' }}
					fontWeight={'normal'}
					color={'#afafaf'}
					fontFamily={'Poppins'}
					alignSelf={'center'}
				>
					{name}
				</Heading>
			</Box>
		);
	}
	return (
		<>
			<VStack justifyContent={'center'} alignSelf={'center'} w={'100%'}>
				<HStack
					w={'100%'}
					borderBottomColor='#afafaf'
					borderBottomWidth={2}
					mb={5}
				>
					<Box width={{ xl: '80%', lg: '80%', base: '70%' }} pb={2}>
						<Heading
							color='#afafaf'
							fontFamily='poppins'
							fontWeight={'normal'}
							fontSize={{ xl: '16px' }}
						>
							Name
						</Heading>
					</Box>
					<Box>
						<Heading
							color='#afafaf'
							fontFamily='poppins'
							fontWeight={'normal'}
							fontSize={{ xl: '16px' }}
						>
							Date Added
						</Heading>
					</Box>
				</HStack>
				{sfiles && sfiles.length > 0 ? (
					sfiles.map((audioAllObj, index) => (
						<HStack py={3} key={index}>
							<HStack width={'65%'}>
								{audioAllObj.file.audioIconUrl ? (
									<img
										src={audioAllObj.file.audioIconUrl}
										className={styles.songImg}
										alt='cover'
									/>
								) : (
									<img src={coverImg} className={styles.songImg} alt='cover' />
								)}

								<Box
									w={'75%'}
									alignItem={'center'}
									paddingX={{ xl: 3, lg: 3, base: 1 }}
									marginBottom={2}
								>
									<Text
										fontSize={{ xl: '24px', lg: '20px', base: '12px' }}
										mb={{ xl: 3, lg: 3, base: 1 }}
										height={{ lg: '2rem', base: '1rem' }}
										overflow='hidden'
										style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
										paddingLeft={1}
										color='#fff'
										fontWeight={'medium'}
										fontFamily={'poppins'}
									>
										{audioAllObj.file.fileName}
									</Text>

									<HStack width={'100%'} flexWrap='wrap'>
										{audioAllObj.file.BPM != null && (
											<Detailsheading name={audioAllObj.file.BPM + ' bpm'} />
										)}
										{audioAllObj.file.fileKey != null && (
											<Detailsheading name={audioAllObj.file.fileKey} />
										)}
										{audioAllObj.file.scale != null && (
											<Detailsheading name={audioAllObj.file.scale} />
										)}

										{audioAllObj.file.tags &&
										audioAllObj.file.tags.length > 0 &&
										!showTagList.includes(audioAllObj.file._id) ? (
											<Button
												px={{ xl: 5, lg: 4, base: 2 }}
												py={{ xl: 2, lg: 2, base: 1 }}
												m={1}
												borderWidth={'1'}
												borderColor={'#AFAFAF'}
												_text={{
													fontSize: { xl: '18px', lg: '16px', base: '12px' },
													fontWeight: 'normal',
													color: '#afafaf',
													fontFamily: 'poppins',
												}}
												rounded={'md'}
												bg='transparent'
												_hover={{
													bg: '#2EBA55',
													color: '#fff',
													_text: {
														color: '#fff',
													},
												}}
												_focused={{ bg: 'transparent' }}
												_pressed={{ bg: 'transparent' }}
												onPress={() => {
													showTagsListItems(audioAllObj.file._id);
												}}
											>
												{`+  ${audioAllObj.file.tags.length} more`}
											</Button>
										) : null}
										{showTagList.includes(audioAllObj.file._id)
											? audioAllObj.file.tags.map((tag, index) => (
													<Detailsheading name={'# ' + tag} />
											  ))
											: ''}
										<Hidden from='md'>
											<Button
												bg='transparent'
												alignSelf={'center'}
												_hover={{
													cursor: 'pointer',
													bg: '#2EBA55',
													_text: {
														color: '#fff',
													},
												}}
												_focused={{ bg: '#2EBA55' }}
												_pressed={{ bg: 'transparent' }}
												onPress={() => {
													showFileModal();
													setEditFile(audioAllObj.file);
												}}
											>
												<Pencil />
											</Button>
										</Hidden>
									</HStack>
								</Box>
							</HStack>
							<HStack width={'35%'} justifyContent={'space-between'}>
								<Hidden from='base' till='md'>
									<Box alignSelf={'center'} width={'18%'}>
										<div className={styles.pencilIcon}>
											<Button
												size={'sm'}
												bg='transparent'
												borderWidth={'1'}
												borderColor={'#afafaf'}
												w={'100%'}
												rounded={'md'}
												alignSelf={'center'}
												_text={{
													color: '#afafaf',
													fontSize: {
														lg: '16px',
														md: '14px',
														sm: '12px',
														base: '12px',
													},
													fontFamily: 'Poppins',
												}}
												_hover={{
													cursor: 'pointer',
													bg: '#2EBA55',
													_text: {
														color: '#fff',
													},
												}}
												_focused={{ bg: '#2EBA55' }}
												_pressed={{ bg: 'transparent' }}
												onPress={() => {
													showFileModal();
													setEditFile(audioAllObj.file);
												}}
												rightIcon={<Pencil />}
											>
												Edit
											</Button>
										</div>
									</Box>
								</Hidden>
								<Box
									alignSelf={'center'}
									width={{ lg: '45%', base: '60%' }}
									justifyContent={'center'}
								>
									<Heading
										color='#fff'
										fontSize={{ xl: '20px', lg: '18px', base: '10px' }}
										fontWeight={'normal'}
										alignSelf={'center'}
									>
										{DaysHoursMinutes(audioAllObj.file.createdAt)}
									</Heading>
								</Box>
								<Box alignSelf={'center'} justifyContent={'center'}>
									{props.numb !== 2 ? (
										<HStack>
											<div
												onClick={() => {
													setFileId(audioAllObj.file._id);
													setShowCollaboratorModal(true);
												}}
												style={{ alignSelf: 'center' }}
											>
												<Hidden from='base' till='md'>
													<ReplyOld />
												</Hidden>
												<Hidden from='md'>
													<Reply />
												</Hidden>
											</div>
											&nbsp;&nbsp;&nbsp;
											<Menu
												rounded={'lg'}
												borderColor={'#fff'}
												borderWidth={1}
												alignSelf={'center'}
												color={'#afafaf'}
												w={{ lg: '280px', base: '180px' }}
												mx={{ lg: 3, base: 1 }}
												_text={{
													color: '#afafaf',
												}}
												background={'#1C1B1B'}
												placement={'bottom left'}
												trigger={(triggerProps) => {
													return (
														<Pressable
															accessibilityLabel='More options menu'
															{...triggerProps}
														>
															{props.numb === 1 || props.numb === 7 ? (
																<div className={styles.dotIcon}>
																	<Hidden from='base' till='md'>
																		<Dots />
																	</Hidden>
																	<Hidden from='md'>
																		<DotsMbl />
																	</Hidden>
																</div>
															) : (
																''
															)}
														</Pressable>
													);
												}}
											>
												{options.map((item, index) => (
													<Menu.Item
														key={'key' + index}
														bg={'#1C1B1B'}
														borderBottomWidth={
															index === options.length - 1 ? '0' : '1px'
														}
														borderBottomColor='#afafaf'
														m={0}
														p={0}
														_focus={{
															bg: '#1C1B1B',
														}}
														_hover={{
															bg: '#1C1B1B',
														}}
														_pressed={{
															bg: '#1C1B1B',
														}}
													>
														<Button
															variant={'ghost'}
															onPress={() => {
																if (item.name === 'Delete File') {
																	setDeleteModal(true);
																	setDeleteSong(audioAllObj.file._id);
																} else if (item.name === 'Edit') {
																	showFileModal();
																	setEditFile(audioAllObj.file);
																} else if (item.name === 'Downlaod MP3') {
																	downloadAudio(
																		audioAllObj.file.url,
																		audioAllObj.file.fileName
																	);
																} else if (item.name === 'Create Pack') {
																	createBeatPack();
																} else {
																	console.log('not specified yet');
																}
															}}
															p={0}
															m={0}
															justifyContent={'center'}
															alignSelf={'stretch'}
															bg={'#1C1B1B'}
															w={'100%'}
															textDecoration={'none'}
															color={'#fff'}
															py={1}
															_text={{
																color: '#fff',
																textAlign: 'center',
																margin: 'auto',
																textDecoration: 'none',
																fontSize: {
																	xl: '16px',
																	lg: '16px',
																	md: '16px',
																	base: '14px',
																},
																py: 0,
															}}
															_focus={{
																_text: {
																	color: '#2EBA55',
																	bg: '#1C1B1B',
																},
																bg: '#1C1B1B',
															}}
															_pressed={{
																_text: {
																	color: '#2EBA55',
																	bg: '#1C1B1B',
																},
																bg: '#1C1B1B',
															}}
															_hover={{
																_text: {
																	color: '#2EBA55',
																},
																bg: '#1C1B1B',
															}}
														>
															{item.name}
														</Button>
													</Menu.Item>
												))}
											</Menu>
										</HStack>
									) : (
										<Button
											size={'sm'}
											bg='#2EBA55'
											borderWidth={1}
											borderColor='#2EBA55'
											_text={{
												color: '#fff',
												fontSize: {
													lg: '18px',
													md: '16px',
													sm: '14px',
													base: '14px',
												},
											}}
											_hover={{
												bg: 'transparent',
												_text: {
													color: '#2EBA55',
												},
											}}
											onPress={() => {
												setShowInvite(true);
												setFileId(audioAllObj.file._id);
											}}
											w={'150px'}
											rounded={'md'}
											alignSelf={'center'}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: '#2EBA55' }}
										>
											Send
										</Button>
									)}
								</Box>
							</HStack>
						</HStack>
					))
				) : (
					<>
						<HStack justifyContent={'center'}>
							<Text
								fontSize={{ lg: '24px', sm: '18px', base: '16px' }}
								py={1}
								color='#afafaf'
								fontWeight={'normal'}
							>
								No Files Found
							</Text>
						</HStack>
					</>
				)}
			</VStack>
			<Modal
				isOpen={showInvitetSent}
				onClose={() => setInviteSent(false)}
				w={'40%'}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.Content backgroundColor={'#1C1B1B'}>
					<Modal.CloseButton
						_hover={{ bg: '#1C1B1B' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
					/>
					<Modal.Body backgroundColor={'#1C1B1B'}>
						<VStack px='10' py='50'>
							<VStack w={'100%'} justifyContent={'center'}>
								<Image
									w={'30%'}
									height={{ lg: '80px', sm: '50px', base: '30px' }}
									width={{ lg: '80px', sm: '50px', base: '30px' }}
									borderRadius={30}
									borderColor={'#1C1B1B'}
									alignSelf={'center'}
									source={require('../../assets/Icons/Checkbox.png')}
									alt='User Profile Pic'
								/>

								<Text
									fontSize={{ lg: '24px', md: '16px', sm: '16px' }}
									fontStyle={'bold'}
									color={'#afafaf'}
									py='2'
									alignSelf={'center'}
								>
									Invite Sent
								</Text>

								<Text
									fontSize={{ lg: '24px', md: '16px', sm: '16px' }}
									fontStyle={'bold'}
									color={'#afafaf'}
									py='2'
									alignSelf={'center'}
									textAlign={'center'}
								>
									Congratulations! Your invite has been sent to selected Person.
								</Text>
							</VStack>
						</VStack>
						<VStack px='1' py='3'>
							<HStack
								w={'100%'}
								justifyContent={'space-evenly'}
								backgroundColor={'#1C1B1B'}
							>
								<Box w={'20%'} justifyContent={'space-evenly'}>
									<Image
										py={'2'}
										size={150}
										borderRadius={100}
										height={{ lg: '100px', sm: '50px', base: '30px' }}
										width={{ lg: '100px', sm: '50px', base: '30px' }}
										alignSelf={'center'}
										source={require('../../assets/Images/1cover.png')}
										alt='User Profile Pic'
									/>
									<Text
										fontSize={{
											lg: '20px',
											md: '16px',
											sm: '12px',
											base: '12px',
										}}
										alignSelf={'center'}
										py={2}
										color='#2EBA55'
									>
										{localStorage.getItem('userName') || 'User'}
									</Text>
								</Box>
								<Box w={'60%'} justifyContent={'space-evenly'}></Box>
								<Box w={'20%'} justifyContent={'space-evenly'}>
									<Image
										py={'2'}
										size={150}
										borderRadius={100}
										height={{ lg: '90px', sm: '50px', base: '30px' }}
										width={{ lg: '90px', sm: '50px', base: '30px' }}
										alignSelf={'center'}
										source={require('../../assets/Images/1cover.png')}
										alt='User Profile Pic'
									/>
									<Text
										fontSize={{
											lg: '20px',
											md: '16px',
											sm: '12px',
											base: '12px',
										}}
										alignSelf={'center'}
										py={2}
										color='#afafaf'
									>
										Uzair
									</Text>
								</Box>
							</HStack>
						</VStack>
					</Modal.Body>
				</Modal.Content>
			</Modal>

			<Modal
				isOpen={shareModal}
				onClose={() => setShareModal(false)}
				w={'30%'}
				size={'full'}
				alignSelf={'center'}
				justifyContent={'flex-start'}
				mt={20}
			>
				<Modal.Content backgroundColor={'transparent'}>
					<Modal.Body backgroundColor={'transparent'} my={2} mx={2}>
						<VStack px={5}>
							<HStack rounded='md' backgroundColor={'#1C1B1B'} h={'40'} my={2}>
								<Modal.CloseButton
									_hover={{ bg: '#1C1B1B' }}
									_focused={{ bg: '#2EBA55' }}
									_pressed={{ bg: 'transparent' }}
								/>
								<Box w={'100%'}>
									<HStack w={'100%'}>
										<VStack>
											<Text
												fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
												color='#afafaf'
												fontWeight={'semibold'}
												px={5}
												py={3}
											>
												Get Link
											</Text>
											<Text
												fontSize={{ lg: '14px', md: '12px', sm: '12px' }}
												color='#afafaf'
												px={5}
												py={2}
											>
												Anyone with this link can play this song
											</Text>
										</VStack>
									</HStack>
									<HStack justifyContent={'flex-end'}>
										<Button
											onPress={() => {
												setShareModal(false);
												navigator.clipboard.writeText(copyLink);
												toast.success('Share Link Copied');
											}}
											size='md'
											mx={10}
											bg={'#2EBA55'}
											rounded={'md'}
											_hover={{ bg: '#1C1B1B' }}
											_focused={{ bg: '#2EBA55' }}
											_pressed={{ bg: '#1C1B1B' }}
											alignSelf={'center'}
										>
											<Text
												fontSize={{ lg: '20px', md: '18px', sm: '12px' }}
												color='white'
											>
												Copy Link
											</Text>
										</Button>
									</HStack>
								</Box>
							</HStack>
						</VStack>
					</Modal.Body>
				</Modal.Content>
			</Modal>

			{editFileModal ? (
				<EditFilePopup
					openModal={editFileModal}
					isEdit={true}
					closeModal={showFileModal}
					data={editFile}
					onEdit={onEditFile}
				/>
			) : (
				''
			)}
			{showCollaboratorModal ? (
				<SendPackPopup
					openModal={showCollaboratorModal}
					data={users}
					showCopyLink={false}
					handleChangeCollaborator={handleChangeCollaborator}
					value={searchUser}
					fileId={fileId}
					handleUserAccess={handleUserAccess}
					closeModal={() => {
						setShowCollaboratorModal(false);
						setSearchUser('');
					}}
				/>
			) : (
				''
			)}
			{deleteModal ? (
				<DeletePopup
					openModal={deleteModal}
					isPack={false}
					closeModal={() => setDeleteModal(false)}
					deleteFtn={() => props.handleDelete(deleteSong)}
				/>
			) : (
				''
			)}
			{showFilePopup ? (
				<SentPopup
					openModal={showFilePopup}
					closeModal={() => setShowFilePopup(false)}
				/>
			) : (
				''
			)}
		</>
	);
};
export default AudioLibraryBody;
