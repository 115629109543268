import { color, textTransform } from "@mui/system";
import { Box, Button, Divider, Heading, HStack, Image } from "native-base";
import React from "react";
import { useNavigate } from "react-router-dom";


const AlbumsCard = (props) => {
  const { card } = props;
  const navigate =  useNavigate();

  return (
    <Box w={"100%"} bg={"#000201"}>
      <HStack
        
        w={"100%"}
        flexGrow={1}
        flexWrap={"wrap"}
        alignSelf={"center"}
      >
        {card.map((item) => (
          <Box w={{xl:"25%", md: "50%", base: '100%'}} my={5}>
            <Box w={'90%'} alignSelf={'center'}>

            <Image
              w={"100%"}
              h={{ xl: "60vh", lg: '50vh', md: '45vh', base: '50vh' }}
              rounded={'lg'}
              alignSelf={"center"}
              resizeMode={"cover"}
              source={item.image}
            />

            <Heading
              py={3}
              fontSize={{ xl: "3xl", base: "xl" }}
              fontWeight={"medium"}
              fontFamily={"Poppins"}
              textTransform={"capitalize"}
              textAlign={"left"}
              color={"#e3e3e3"}
            >
              {item.songname}
            </Heading>
            <Heading
              fontSize={{ xl: "xl", base: "md" }}
              fontWeight={"normal"}
              fontFamily={"Poppins"}
              textTransform={"capitalize"}
              textAlign={"left"}
              color={"#AFAFAF"}
            >
              {item.artist}
            </Heading>
            <Button
            alignSelf={'flex-start'}
              variant={"ghost"}
              py={2}
              px={0}
              _hover={{bg: 'transparent'}}
              _pressed={{bg: 'transparent'}}
              _text={{
                underline: '1',
                color: "#2EBB55",
                fontWeight: "normal",
                fontSize: { xl: "xl", base: "md" },
                textAlign: "left",
                textTransform: "capitalize",
                fontFamily: "Poppins",
              }}
              onPress = {()=>  navigate('/credit-details', {state: item})}
            >
              View Credits
            </Button>
            </Box>
          </Box>
        ))}
        ;
      </HStack>
    </Box>
  );
};

export default AlbumsCard;
