import React from "react";
import styles  from "./styles.module.css"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Loader } from "../../Loader";
import { Line } from "react-chartjs-2";
import { Box, Checkbox, HStack, Image, Text, VStack } from "native-base";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,

  Tooltip,
  Legend,
  Filler,

);
const dataObject = {
    "labels": ["January", "February", "March","April","May","June","July" ,],
    "data":  [50, 100, 60, 70, 40, 50, 20],
    "subscribers": "2,100,000",
    "platform": "Spotify",
    "socialIcon": "/spotify.png"

}
function LineChart({tableData,isLoading}) {
  console.log("tabledata",tableData)
const lables = tableData?.labels;
    
    const data = {
    labels: lables,
    position: "top",
    
    datasets: [
      {
        yAxisID:"A",
        data: tableData?.datasets?.[0],
    
        borderColor: "#2EBA55",
        fill: false,
        backgroundColor: "rgba(46, 186, 85, 0.3)",
        tension: 0.5,
        borderWidth: 1,
      },
      {
        yAxisID:"B",
        // data: [0, 25, 50, 75,100],
    
        borderColor: "#2EBA55",
        fill: false,
        backgroundColor: "rgba(46, 186, 85, 0.3)",
        tension: 0.5,
        borderWidth: 1,
      },


    ],
  };
  const options = {

    
    opacity: 0.5,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
     A: {
 
        type: 'linear',
        position: 'left',
        ticks: {
          callback: function (value, index, values) {
            return value + "M";
        },
        
        precision:2,
      
 },
        grid: {
          display: true,
          borderDash: [8, 2],
          color: "#AFAFAF",
        },
      },
     B: {
 
      
        type: 'linear',
        position: 'right',
        ticks: {
          callback: function (value, index, values) {
            return value + "K";
        },
          precision:0,
          stepSize:25
    },
  
        },
         
    },
    responsive: true,

    plugins: {
  legend:{
    display:false
  }
    },
  };

  return (
    <Box
   
    // borderRadius={10}

    // w={"500px"}
    // w={"100%"}
  >
     {isLoading? <div className={styles.loaderDiv}><Loader/></div>: <Line options={options} data={data} /> }
    <VStack>
  
   
     
{/*       
      <HStack mt={4} space={2}
      alignItems={"center"}
      >
        <Checkbox
    
      //  size={{md:"sm",base:"xs"}}

    value={""}
  
          colorScheme="green"
          defaultIsChecked
        />
        <Box
      w={10}
      
          borderBottomStyle="line"
          borderBottomWidth={"2"}
          borderColor={"#2EBA55"}
        ></Box>
        <Text fontSize={{md:"1rem",sm:"0.8rem",base:"0.7rem"}} color="#AFAFAF" fontFamily={"poppins"}>
          Videos
        </Text>
        <Checkbox
    
    size={{md:"sm",base:"xs"}}
 
 value={""}

       colorScheme="green"
       defaultIsChecked
     />
        <Box
        w={10}
          //   py={'2px'}
    
          borderBottomStyle="line"
          borderBottomWidth={"2"}
          borderColor={"#2EBA55"}
        >
        
        </Box>
        <Text  fontSize={{md:"1rem",sm:"0.8rem",base:"0.7rem"}}  color="#AFAFAF" fontFamily={"poppins"}>
          Changes
        </Text>
      </HStack> */}
    </VStack>
  </Box>
  );
}

export default LineChart;
