import React, { useEffect, useState } from 'react';
import {
	Box,
	Image,
	Heading,
	HStack,
	VStack,
	Button,
	Divider,
} from 'native-base';
import axios from 'axios';
import { TalentcomponentHeader } from '../../TalentComponentHeader/TalentComponentHeader';
import { TableHeaderCol3 } from '../../TalentComponentHeader/TableHeaderCol3';
import { TableContentCol3 } from '../../TableContent/TableContentCol3';
import { Loader } from '../../../Loader';
import LineChart from '../../Graphs/Line';
import SeeMore from '../../SeeMoreButton/SeeMore';
import styles from './../styles.module.css';
export const TiktokPlaylist = (props) => {
	const { width } = props;
	const [seeMore, SetSeeMore] = useState(false);
	const { showGraph } = props;
	const [isLoading, setIsloading] = useState(true);
	const [tiktokMusicLinks, setTikokMusicLinks] = useState([]);
	const [sorted, setSorted] = useState(false);

	const handleSortIcon = () => {
		setSorted(!sorted);
		console.log('dsakjdgsa');
		setTikokMusicLinks(
			tiktokMusicLinks.sort((a, b) => a.totalValue - b.totalValue)
		);
	};
	useEffect(() => {
		console.log('hello tiktok');
		const configOptions = {
			method: 'get',
			url: `https://sandbox.api.soundcharts.com/api/v2/chart/tiktok/music/weekly/ranking/latest?offset=0&limit=15`,
			headers: {
				'x-api-key': 'soundcharts',
				'x-app-id': 'soundcharts',
			},
		};

		axios(configOptions)
			.then((response) => {
				console.log(response.data.items);
				setIsloading(false);
				!sorted && setTikokMusicLinks(response.data.items);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [sorted]);
	return (
		<Box my={5} py={5} bg={'#1C1B1B'} w={width} rounded={'lg'}>
			<Box w={'92%'} alignSelf={'center'}>
				{!showGraph && (
					<>
						<TalentcomponentHeader
							image={require('../../../../assets/TiktokComponents/tik-tok1.png')}
							title={'Tiktok links'}
							subtitle={'Top 15 links by video account'}
						/>
						<TableHeaderCol3
							isHeader={true}
							title1={'LINK NAME'}
							title2={'CREATOR'}
							title3={'TOTAL VIDEOS'}
							handleSort={handleSortIcon}
							sorted={sorted}
						/>
						{isLoading && (
							<div className={styles.loaderDiv}>
								<Loader />
							</div>
						)}
						{tiktokMusicLinks.map(
							(link, index) =>
								index < (seeMore ? 14 : 5) && (
									<TableHeaderCol3
										isHeader={false}
										title1={link.music.title}
										title2={link.music.authorName}
										title3={link.totalValue}
									/>
								)
						)}
						<SeeMore seeMore={seeMore} SetSeeMore={SetSeeMore} />{' '}
					</>
				)}

				{showGraph && (
					<>
						{/* {' '} */}
						<TalentcomponentHeader
							image={require('../../../../assets/TiktokComponents/tiktok-1.png')}
							title={'2,100,000 Total Tiktok Videos'}
							subtitle={'Total videos over last 28 days'}
						/>
						<LineChart isLoading={isLoading} />{' '}
					</>
				)}
			</Box>
		</Box>
	);
};
