import { Box } from 'native-base';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from 'react-chartjs-2'
import { TalentcomponentHeader } from '../TalentComponentHeader/TalentComponentHeader';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,

  } from "chart.js";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,

  
  );

const Barchart=({tableData})=> {
const [radioList,setRadioList]=useState([])
const [seeMore,SetSeeMore]=useState(false)

  useEffect(()=>{
    const configOptions = {
      
    method: "get",
    url: `https://sandbox.api.soundcharts.com/api/v2/song/7d534228-5165-11e9-9375-549f35161576/broadcasts?offset=0&limit=28" -H "x-app-id: soundcharts" -H "x-api-key: soundcharts`,
    headers: {
      'x-api-key': 'soundcharts',
      'x-app-id': 'soundcharts',
    },
  
  };
  
  axios(configOptions).then((response)=>{
  console.log(response.data.items)
  setRadioList(response.data.items)
  }).catch((err)=>{
  console.log(err)
  })
  },[
  
  ])

const arr = []
radioList.forEach(element => {arr.push(element.duration)})

const date = []
radioList.forEach(element => {date.push(element.airedAt.replace('T', ' ').split(' ')[0])})


console.log(date)
    
const data = {
labels: date,
position: "top",

datasets: [
  {
    yAxisID:"A",
    data: arr,

    borderColor: "#2EBA55",
    fill: false,
    backgroundColor: "rgba(46, 186, 85, 0.3)",
    tension: 0.5,
    borderWidth: 1,
  },


],
};
const options = {


opacity: 0.5,
scales: {
  x: {
    grid: {
      display: false,
    },
  },
 A: {

    type: 'linear',
    position: 'left',
    ticks: {
      callback: function (value, index, values) {
        return value + "";
    },
    
    precision:2,
  
},
    grid: {
      display: true,
      borderDash: [8, 2],
      color: "#AFAFAF",
    },
  },
 B: {

  
    type: 'linear',
    position: 'right',
    ticks: {
      callback: function (value, index, values) {
        return value + "K";
    },
      precision:0,
      stepSize:15
},

    },
     
},
responsive: true,

plugins: {
legend:{
display:false
}
},
};
  return (

    <Box  >

    <Bar data={data} options={options}/>
    </Box>
  )
}

export default Barchart