import React, { useEffect, useState } from 'react';
import { Hidden, VStack } from 'native-base';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from './styles.module.css';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Box, Button, HStack } from 'native-base';
import PlatFormDropDown from '../Playlists/PlatFormDropDown';
import CountryFilter from '../Airplay/CountryFilter';

import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExportCsv from '../../../../pages/talentDashboard/ExportCsv';
import moment from 'moment';
import SeeMore from '../../SeeMoreButton/SeeMore';
import { Loader } from '../../../Loader';
import ApplyFiters from '../ApplyFiters';
function DetailsChart() {
	const [checked, setChecked] = React.useState(true);
	const [platform, setPlatform] = useState('spotify');
	const [applyFilter, SetApplyFilter] = useState(false);
	const [seeMore, SetSeeMore] = useState(false);
	const [chartDetails, setChartDetails] = useState([]);
	const [isLoading, setIsloading] = useState(true);
	const [csvData, setCSVdata] = useState([]);
	const headers = [
		{ label: '#', key: 'serialNo' },
		{ label: 'R-1', key: 'R1' },
		{ label: 'Peak', key: 'peak' },
		{ label: 'Chart', key: 'Chart' },
		{ label: 'Country', key: 'country' },
		{ label: 'Peak Date', key: 'peakdate' },
		{ label: 'TOC', key: 'TOC' },
	];

	// const data = [
	//   {
	//     serialNo: 1,
	//     R1: 1,
	//     peak: 1,
	//     Chart: "Top Cebu City,Weekly",
	//     country: "Philippines",
	//     peakdate: "Oct 27,2022",
	//     TOC: 3,
	//   },
	//   {
	//     serialNo: 1,
	//     R1: 1,
	//     peak: 1,
	//     Chart: "Top Cebu City,Weekly",
	//     country: "Philippines",
	//     peakdate: "Oct 27,2022",
	//     TOC: 3,
	//   },
	//   {
	//     serialNo: 1,
	//     R1: 1,
	//     peak: 1,
	//     Chart: "Top Cebu City,Weekly",
	//     country: "Philippines",
	//     peakdate: "Oct 27,2022",
	//     TOC: 3,
	//   },
	//   {
	//     serialNo: 1,
	//     R1: 1,
	//     peak: 1,
	//     Chart: "Top Cebu City,Weekly",
	//     country: "Philippines",
	//     peakdate: "Oct 27,2022",
	//     TOC: 3,
	//   },
	//   {
	//     serialNo: 1,
	//     R1: 1,
	//     peak: 1,
	//     Chart: "Top Cebu City,Weekly",
	//     country: "Philippines",
	//     peakdate: "Oct 27,2022",
	//     TOC: 3,
	//   },
	// ];

	// function createData(serialNo, R1, Peak, Chart, country, peakdate, toc) {
	//   return { serialNo, R1, Peak, Chart, country, peakdate, toc };
	// }

	// const rows = [

	//   chartDetails.map((item,index)=>createData(index,item.maxResultsCount,item.name,item.countryName,0,0,0)),
	//   // createData(
	//   //   1,
	//   //   1,
	//   //   1,
	//   //   "Top Cebu City,Weekly",
	//   //   "Philippines",
	//   //   "Oct 27,2022",
	//   //   3
	//   // ),
	//   // createData(1, 1, 1, "Top Cebu City,Weekly", "Singapore", "Oct 27,2022", 3),
	//   // createData(1, 1, 1, "Top Cebu City,Weekly", "Singapore", "Oct 27,2022", 3),
	//   // createData(1, 1, 1, "Top Cebu City,Weekly", "Global", "Oct 27,2022", 3),
	//   // createData(1, 1, 1, "Top Cebu City,Weekly", "Australia", "Oct 27,2022", 3),
	// ];
	const handleChange = (event) => {
		setChecked(event.target.checked);
	};
	const handleFilter = () => {
		SetApplyFilter(!applyFilter);
	};
	useEffect(() => {
		const configOptions = (method, url) => {
			return {
				method: method,
				url: url,
				headers: {
					'x-api-key': 'soundcharts',
					'x-app-id': 'soundcharts',
				},
			};
		};
		const getChartDetails = async () => {
			console.log('hello');
			let Platform = platform.toLowerCase();
			console.log('platform', Platform);
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/chart/album/by-platform/${Platform}?offset=0&limit=100`
			);
			const response = await axios(config);
			const { items } = response.data;
			setChartDetails(items);
			setIsloading(false);
		};
		getChartDetails();
	}, [applyFilter]);
	useEffect(() => {
		setCSVdata(
			chartDetails.map((Chartsdata, index) => {
				return {
					serialNo: index,
					R1: Chartsdata.platform,
					peak: Chartsdata.maxResultsCount,
					Chart: Chartsdata.name,
					country: Chartsdata.countryName,
					peakdate: moment().format('DD MMM YYYY'),
					TOC: Chartsdata.type,
				};
			})
		);
	}, [chartDetails, platform]);

	//   useEffect(()=>{
	// setChartDetails(chartDetails.filter((item=>item.platform===platform.toLowerCase())))
	//   },[platform])
	//   console.log(platform)
	return (
		<Box p={5} mt={5} bg={'#1C1B1B'}>
			<HStack
				mb={3}
				flexDirection={{ md: 'row', sm: 'column' }}
				justifyContent={'space-between'}
				w={'100%'}
				alignItems='center'
			>
				<HStack
					width={{ md: '60%', base: '100%' }}
					alignSelf={'center'}
					flexWrap={'wrap'}
					space={4}
					flexDirection={{ sm: 'row', base: 'column' }}
				>
					<Box
						mb={{ sm: '0', base: 2 }}
						w={{ xl: '25%', md: '30%', sm: '48%', base: '100%' }}
					>
						<PlatFormDropDown setPlatform={setPlatform} />
					</Box>

					<Box w={{ xl: '25%', md: '30%', sm: '48%', base: '100%' }}>
						<CountryFilter />
					</Box>
				</HStack>

				{/* <Hidden   from="lg" till="xl" >  
  <HStack space={4} flexDirection={{sm:"row",base:"column"}} w={ {base:"80%"}} justifyContent={{md:"space-between",base:"center"}}>
         <VStack alignItems={"center"} space={5}> <PlatFormDropDown />
           
         </VStack> 
         
<VStack alignItems={"center"} space={5}> 


              <CountryFilter /></VStack>
          
              </HStack>
              </Hidden> */}
				{/* 
              <Hidden from="sm" till="md">  
  <VStack >
         <PlatFormDropDown />
           
           <PlaylistTypes />
         
 <CurrentPlaylist />
              <CountryFilter />
          
              </VStack></Hidden>     */}
				<HStack
					mt={{ md: '0', base: '5' }}
					width={{ md: '40%' }}
					justifyContent={{ md: 'end' }}
					alignItems={'center'}
					space={2}
				>
					<ApplyFiters handler={handleFilter} />
					<Button
						mt={{ sm: 0, base: 4 }}
						height={'50px'}
						px={5}
						bg={'#2EBB55'}
						_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
						fontFamily='poppins'
						_hover={{
							background: 'transaprent',
							borderColor: '#2EBB55',
							borderWidth: 2,
							_text: { color: '#2EBB55' },
						}}
					>
						<ExportCsv color={'#fff'} data={csvData} headers={headers} />
					</Button>
				</HStack>

				{/* <HStack
        
              mt={{ lg: 0, base: 6 }}
              justifyContent={{ md: "start", base: "center" }}
              alignItems={"center"}
            >
             
            </HStack>

            <HStack
         
              mt={{ lg: 0, base: 6 }}
              justifyContent={{ md: "start", base: "center" }}
              alignItems={"center"}
            > */}
				{/* <DropDown showIcon={false} text={"current playlist"}/> */}

				{/* </HStack> */}
			</HStack>
			{/* <HStack space={5} alignItems={"center"} my={10}>
        <PlatFormDropDown setPlatform={setPlatform} />
        <CountryFilter />
        <FormControlLabel
          sx={{ color: "grey", textTransform: "capitalize" }}
          label={"see cities"}
          control={
            <Checkbox
              checked={checked}
              sx={{
                color: "#AFAFAF",
                "&.Mui-checked": {
                  color: "#2EBB55",
                },
              }}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            >
              show cities
            </Checkbox>
          }
        />
        <HStack justifyContent={"flex-end"}>
          <Button
            textTransform="capitalize"
            mt={{ lg: 0, base: 6 }}
            height={"50px"}
            fontFamily="poppins"
            _hover={{
              background: "transaprent",
              borderColor: "#2EBB55",
              borderWidth: 2,
              _text: { color: "#2EBB55" },
            }}
            px={5}
            bg={"#2EBB55"}
            _text={{ fontSize: "1rem", fontFamily: "poppins" }}
          >
            <ExportCsv color={"#fff"} data={csvData} headers={headers} />
          </Button>
        </HStack>
      </HStack> */}

			<TableContainer className={styles.tableContainer} component={Paper}>
				<Table sx={{ minWidth: '450px' }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell width={'10px'}>
								<HStack justifyContent={'center'}>
									#<ArrowDropUp sx={{ color: '#f00' }} />
								</HStack>
							</TableCell>
							<TableCell width={'10px'} align='center'>
								R-1
							</TableCell>
							<TableCell width={'10px'} align='center'>
								<HStack justifyContent={'center'}>
									Peak
									<ArrowDropUp sx={{ color: '#f00' }} />
								</HStack>
							</TableCell>
							<TableCell width={'200px'} align='left'>
								{' '}
								Chart
							</TableCell>
							<TableCell width={'10px'} align='left'>
								{' '}
								Country
							</TableCell>
							<TableCell width={'10px'} align='center'>
								<HStack justifyContent={'center'}>
									{' '}
									Peak Date
									<ArrowDropDown sx={{ color: 'grey' }} />
								</HStack>
							</TableCell>
							<TableCell width={'10px'} align='center'>
								{' '}
								TOC
							</TableCell>
							{/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
						</TableRow>
					</TableHead>

					<TableBody>
						{isLoading && (
							<div className={styles.loaderDiv}>
								<Loader />
							</div>
						)}
						{chartDetails.map(
							(row, index) =>
								index < (seeMore ? chartDetails.length : 5) && (
									<TableRow
										key={index}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component='th' align='center' scope='row'>
											{index}
										</TableCell>

										<TableCell align='center'>{row.platform}</TableCell>
										<TableCell align='center'>{row.maxResultsCount}</TableCell>
										<TableCell align='left'>{row.name}</TableCell>
										<TableCell align='left'>{row.countryName}</TableCell>
										<TableCell align='center'>
											{moment().format('DD MMM YYYY')}
										</TableCell>
										<TableCell align='center'>{row.type}</TableCell>
									</TableRow>
								)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<SeeMore seeMore={seeMore} SetSeeMore={SetSeeMore} />
		</Box>
	);
}

export default DetailsChart;
