/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Link,
  Text,
} from 'native-base';
import { Bar } from 'react-chartjs-2';

import '../../../Growth/styles.css';

export function BarGraphBeats() {
  const lables = [
    'july 20',
    'july 25',
    'july 30',
    'Aug 1',
    'Aug 5',
    'Aug 10',
    'Aug 15',
    'Aug 20',
    'Aug 25',
    'Aug 30',
  ];
  const data = {
    labels: lables,
    position: 'top',

    datasets: [
      {
        data: [0, 50, 0, 300, 360, 270, 280, 220, 300, 350, 200],
        label: 'Impression',
        borderColor: '#2EBA55',
        fill: true,
        backgroundColor: '#2EBA55',
        tension: 0,
        borderWidth: 2,

        pointStyle: 'circle',
      },
      {
        data: [360, 270, 280, 220, 300, 350, 230, 350, 270, 270, 280],
        label: 'Clicks',
        borderColor: '#AFAFAF',
        fill: false,
        backgroundColor: '#AFAFAF',
        tension: 0.5,
        borderWidth: 2,
      },
    ],
  };
  const options = {
    opacity: 0.5,
    barThickness: 20,

    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          borderDash: [8, 2],
          color: '#fff',
        },
        ticks: {
          precision: 0,
        },
      },
    },
    axisX: {
      title: 'Social label',
      reversed: true,
    },
    // responsive: true,

    plugins: {
      legend: {
        position: 'top',

        align: 'end',
        paddingBottom: 5,
      },
    },
  };

  return (
    <Box w={'100%'}>
      <HStack
        w={'100%'}
        justifyContent={'space-evenly'}
        px={{ lg: 3, base: 2 }}
      >
        <div className='verticalText'>Impression</div>

        <Box w={{ lg: '95%', base: '85%' }} alignSelf={'center'}>
          <Bar options={options} data={data} />
        </Box>
      </HStack>
    </Box>
  );
}
