/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */

import React from "react";
import { Box, Divider, Heading, HStack, Image, Text } from "native-base";
import SideCard from "./SideCard";
import profile from "../../../assets/Feeds/profile.png";
import SuggestionCard from "./SuggestionCard";
import { getSuggestedUsersByUserId } from "../../../RestApis/Services/Feeds";
import { Typography } from "@mui/material";

function Suggestion() {
  const [suggestedUsers, setSuggestedUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getSuggestedUsers();
  }, []);
  const getSuggestedUsers = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      const response = await getSuggestedUsersByUserId(userId);
      console.log("response of get suggested users api..", response);
      setLoading(false);
      if (response.status === 200) {
        setSuggestedUsers(response.data.suggestedUsers);
      }
    } catch (err) {
      setLoading(false);
      console.log("error on get suggested users api...", err);
    }
  };
  return (
    <Box w={"100%"} bg={"#000201"} my={5}>
      <Heading
        my={5}
        fontSize={{ xl: "2xl", base: "lg" }}
        fontWeight={"medium"}
        fontFamily={"Poppins"}
        color={"#fff"}
        textAlign={"left"}
        textTransform={"capitalize"}
      >
        Suggested
      </Heading>
      {loading ? (
        <Typography
          style={{ color: "#AFAFAF", fontFamily: "Poppins", fontSize: 16 }}
        >
          Loading...
        </Typography>
      ) : suggestedUsers.length > 0 ? (
        suggestedUsers.map((user, key) => (
          <React.Fragment key={key}>
            <SuggestionCard
              image={user.profilePic}
              title={user.firstname + " " + user.lastname}
              userId={user._id}
              follower={""}
            />
          </React.Fragment>
        ))
      ) : (
        <Typography
          style={{ color: "#AFAFAF", fontFamily: "Poppins", fontSize: 16 }}
        >
          No suggested users found
        </Typography>
      )}
    </Box>
  );
}

export default Suggestion;
