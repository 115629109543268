import { Box, Divider, Heading, HStack, Image } from 'native-base';
import React, { useState } from 'react';
import { DashboardHeader } from '../../Headers';
import SideBar from '../../SideBar/SideBar';
import InfoBody from './InfoBody';
import Textfield from '../../Textfield';

import ReferalModal from './ReferalModal';
const BarProfileArtistInfo = (props) => {
	const [showModal, setshowModal] = useState('');
	return (
		<Box w={'100%'} bg={'#000201'} position={'relative'}>
			{showModal && <ReferalModal setshowModal={setshowModal} />}
			{showModal && (
				<Box
					position={'absolute'}
					top={0}
					left={0}
					bottom={0}
					right={0}
					background='#000'
					opacity={'0.4'}
					zIndex={2222}
				></Box>
			)}
			<HStack w={'100%'}>
				<Box
					w={{ xl: '100%', lg: '100%', base: '100%' }}
					borderLeftWidth={{ lg: 1, md: 0 }}
					borderColor={'#fff'}
				>
					<InfoBody setshowModal={setshowModal} />
				</Box>
			</HStack>
		</Box>
	);
};

export default BarProfileArtistInfo;
