import {
	ArrowDownIcon,
	Box,
	ChevronDownIcon,
	ChevronUpIcon,
	HStack,
	Image,
	Pressable,
	Text,
	VStack,
} from 'native-base';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Dropdown from './Dropdown';

import styles from './styles.module.css';
export default function SidebarItem(props) {
	const [open, setOpen] = useState(false);
	const { item } = props;
	const navigate = useNavigate();
	// const toggleArrow = () => {
	// return	<ChevronDownIcon
	// 	variant='ghost'
	// 	size={'4'}
	// 	right={'10px'}
	// 	marginRight={"8px"}
	// 	alt='home'
	// />
	// }
	const NBBox = (props) => {
		return (
			<>
				{item.childrens.length > 0 ? (
					<Box marginRight={{ xl: '10px', lg: '10px', base: '15px' }}>
						<ChevronDownIcon
							variant='ghost'
							size={{ xl: '5', base: '3' }}
							// right={'10px'}
							// marginRight={"15px"}
							alt='Down Arrow Icon'
						/>
					</Box>
				) : (
					<Text> </Text>
				)}
			</>
		);
	};
	const handleClick = (path, hasChildren) => {
		if (hasChildren) {
			setOpen((open) => !open);
		} else if (path === '/signin') {
			localStorage.clear();
			navigate(path);
		} else if (path === '/opporunity') {
			window.location.href =
				'https://opporunity.unitedmarket.com/opportunities';
		} else {
			navigate(path);
		}
	};
	if (item.childrens) {
		return (
			<>
				{' '}
				<Pressable
					onPress={() =>
						handleClick(item.path, item.childrens.length > 0 ? true : false)
					}
					backgroundColor={'#000'}
					_hover={{
						bg: '#2EBA55',
						fontWeight: 'bold',
						color: '#fff',
						fill: '#fff',
					}}
				>
					<div className={styles.sidebarDiv}>
						<HStack paddingLeft={2}>
							<HStack
								flexDirection={'row'}
								height={'100%'}
								width={'100%'}
								py={1}
								alignItems={'center'}
							>
								<Image
									marginY={3}
									marginLeft={{ xl: '5px', lg: '2px', md: '0px', base: '15px' }}
									variant='ghost'
									size={{ xl: '5', base: '3' }}
									source={require(`../../assets/Icons/${item.icon}`)}
									alt={item.title}
									_hover={{
										zIndex: 1,
										bg: '#fff',
										// leftIcon:"../../assets/Icons/shareIcon.png"
										fill: '#fff',
									}}
								/>
								<Text
									fontSize={{
										xl: '16px',
										lg: '16px',
										sm: '14px',
										base: '16px',
									}}
									w={'100%'}
									paddingLeft={1}
									color='#afafaf'
									fontWeight={400}
									fontFamily={'poppins'}
								>
									{item.title}
								</Text>
								<NBBox m='2' p='5' />
							</HStack>
						</HStack>
						{open && item.childrens.length > 0 && (
							<VStack
								fontSize='md'
								fontWeight={400}
								fontFamily={'poppins'}
								color='#2EBA55'
								backgroundColor={'#000'}
							>
								<VStack>
									{item.childrens.map((child, index) => (
										<Dropdown key={index} item={child} />
									))}
								</VStack>
							</VStack>
						)}
					</div>
				</Pressable>
			</>
		);
	}
}
