import {
	Button,
	HStack,
	Image,
	Text,
	VStack,
	Input,
	Pressable,
	Menu,
	Box,
	Heading,
	Hidden,
} from 'native-base';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import React from 'react';
import { FiSend } from 'react-icons/fi';
import Slider from '@mui/material/Slider';
import { SearchFieldComponent } from '../SearchField';
import AudioLibraryBody from './AudioLibraryBody';
import AudioUpload from './AudioUpload';
import { Loader } from '../Loader';
import { TagsPopup } from '../PopUp/TagsPopup';
import { BpmPopup } from '../PopUp/BpmPopup';
import { TypePopup } from '../PopUp/TypePopup';
import BeatPackCreation from '../PopUp/BeatPackCreation';
import BeatPackTab from './BeatPackTab';
import axios from 'axios';
import TagIcon from '../Icons/TagIcon';
import KeyandBPM from '../Icons/KeyandBPM';
import styles from './styles.module.css';
import Filter from '../Icons/Filter';
import { toast } from 'react-toastify';
import TagIconMbl from '../Icons/TagIconMbl';
import KeyandBPMMbl from '../Icons/KeyandBPMMbl';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const AudioLibrarySample = () => {
	const [showImportModal, setImportModal] = useState(false);
	const [audioAll, setAudioAll] = useState([]);
	const [importAll, setImportAll] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [toggleState, setToggleState] = useState(1);
	const [loopsSwitch, setLoopsSwitch] = useState(false);
	const [beatsSwitch, setBeatsSwitch] = useState(false);
	const [position, setPosition] = useState('bottom');
	const [allState, setAllState] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [bmpValue, setBPMValue] = useState([20, 100]);
	const [added, setAdded] = useState(false);
	const [searched, setSearched] = useState('');
	const [filterValues, setFilterValues] = useState({});
	const [showTagModal, setShowTagModal] = useState(false);
	const [tagSearch, setTagSearch] = useState('');
	const [tagList, setTagList] = useState([]);
	const [showKeyBpmModal, setShowKeyBpmModal] = useState(false);
	const [showTypeModal, setShowTypeModal] = useState(false);
	const [librarySearch, setLibrarySearch] = useState('');
	const [selectedBPM, setSelectedBPM] = useState(0);
	const [selectedKey, setSelectedKey] = useState('Key');
	const [selectedScale, setSelectedScale] = useState('Scale');
	const [beatPackData, setBeatPackData] = useState([]);
	const [showBeatPackCreate, setShowBeatPack] = useState(false);
	const [showSecondaryBar, setShowSecondaryBar] = useState(true);
	const [editFileModal, setEditFileModal] = useState(false);
	const dataScales = [
		'Major',
		'Minor',
		'Major Pentatonic',
		'Minor  Pentatonic',
		'Whole Tone',
		'Chromatic',
		'Blues',
	];
	const dataKeys = ['c', 'C#/Bd', '#lofi', '#FFF', 'f', 'E', 'A#/BD'];
	const navigate = useNavigate();

	const toggleTag = () => {
		setAdded(!added);
	};
	const headerkey = `Bearer ${localStorage.getItem('token')}`;
	const getButton = (btn) => {
		setToggleState(btn);
	};
	const fetchData = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};
		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallaudiofiles`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.auth === false) {
					navigate('/signin');
					localStorage.clear();
				} else {
					setAudioAll(data.files);
				}
			})
			.catch((error) => {
				console.log('error', error);
				if (error?.response?.status === 401) {
					navigate('/signin');
					localStorage.clear();
				}
			});
	};
	const fetchImportData = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};
		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallaudiofiles`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.auth === false) {
					navigate('/signin');
					localStorage.clear();
				} else {
					setImportAll(data.files);
				}
			})
			.catch((error) => {
				console.log('error', error);
				if (error?.response?.status === 401) {
					navigate('/signin');
					localStorage.clear();
				}
			});
	};
	const tags = filterValues?.tags;
	const addTag = (tag) => {
		setSelectedTags([...selectedTags, tag]);
	};
	const removeTag = (removedTag) => {
		setSelectedTags(selectedTags.filter((tag) => tag !== removedTag));
	};

	const handleChange = (event, newValue) => {
		setBPMValue(newValue);
		handleSearch(searched, bmpValue[1], selectedTags);
	};
	const valuetext = (value) => {
		return `${value}`;
	};

	const getFilterValue = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getfiltervalues`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				setFilterValues(result.filterValues);
				setTagList(result.filterValues.tagsWithCount || []);
				let temp = [...bmpValue];
				temp[1] = result?.filterValues?.maxBPM;
				setBPMValue(temp);
			})
			.catch((error) => {
				if (error?.response?.status === 401) {
					setIsLoading(false);
					navigate('/signin');
					localStorage.clear();
				}
			});
	};
	const getBeatPacks = () => {
		var requestOptions = {
			method: 'GET',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};

		fetch(
			`${process.env.REACT_APP_API_URL}/file/getallbeatpacks`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.beatpacks) {
					setBeatPackData(result.beatpacks);
				} else {
					setBeatPackData([]);
				}
			})
			.catch((error) => console.log('error', error));
	};

	const handleSearch = (
		searchText,
		mbpm,
		addedTags,
		selectedKey,
		selectedScale
	) => {
		setIsLoading(true);
		var data = {
			searchQuery: searchText,
			maxBPM: mbpm,
			tags: addedTags,
			keys: selectedKey,
			scales: selectedScale,
		};

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/searchallaudiofiles`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				if (response.data.files) {
					setAudioAll(response.data.files);
					setIsLoading(false);
				} else {
					setAudioAll();
					setIsLoading(false);
				}
			})

			.catch(function (error) {
				if (error?.response?.status === 401) {
					setIsLoading(false);
					navigate('/signin');
					localStorage.clear();
				}
				if (error?.response?.status === 404) {
					console.log('in error');
					setAudioAll([]);
					setIsLoading(false);
				}
				setIsLoading(false);
				console.log(error);
			});
	};
	const handleSearchBeatPack = (searchText, mbpm, addedTags) => {
		setIsLoading(true);
		var requestOptions = {
			method: 'POST',
			headers: { Authorization: headerkey, 'Content-Type': 'application/json' },
			body: JSON.stringify({
				searchQuery: searchText,
				tags: addedTags?.length > 0 ? addedTags : null,
				minBPM: 0,
				maxBPM: mbpm || 200,
			}),
			redirect: 'follow',
		};
		fetch(
			`${process.env.REACT_APP_API_URL}/file/searchbeatpacks`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.beatpacks) {
					setBeatPackData(result.beatpacks);
					setIsLoading(false);
				} else {
					setBeatPackData([]);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				console.log('error', error);
			});
	};
	const handleImportSearch = (searchText) => {
		// setIsLoading(true);
		var data = {
			searchQuery: searchText,
			maxBPM: filterValues?.maxBPM,
			tags: [],
		};

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/searchallaudiofiles`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				if (response.data.files) {
					setImportAll(response.data.files);
				} else {
					setImportAll();
				}
			})

			.catch(function (error) {
				if (error?.response?.status === 401) {
					navigate('/signin');
					localStorage.clear();
				}
				if (error?.response?.status === 404) {
					setImportAll([]);
				}
				console.log(error);
			});
	};

	const handleDelete = (fileId) => {
		var requestDelete = {
			method: 'DELETE',
			headers: { Authorization: headerkey },
			redirect: 'follow',
		};
		fetch(
			`${process.env.REACT_APP_API_URL}/file/deletefile/${fileId}`,
			requestDelete
		)
			.then((response) => response.text())
			.then((result) => {
				fetchData();
				toast.success('Deleted successfully');
			})
			.catch((error) => {
				console.log('error', error);
				if (error?.response?.status === 401) {
					navigate('/signin');
					localStorage.clear();
				}
			});
	};
	const handleDeleteBeatPack = (bp_id) => {
		var data = {
			beatPackId: bp_id,
		};
		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/deletebeatpack`,
			headers: { Authorization: headerkey },
			data: data,
		};

		axios(config)
			.then(function (response) {
				toast.success('Deleted BeatPack Successfully');
				getBeatPacks();
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleRecent = () => {
		console.log('importAll', importAll);
	};
	const onChangeSearch = (e) => {
		setSearched(e);
		e.length <= 1
			? fetchData()
			: handleSearch(searched, bmpValue[1], selectedTags);
		getButton(1);
	};
	const onhandleSearchLibrary = (e) => {
		setLibrarySearch(e);
		e.length <= 1
			? toggleState === 1
				? fetchData()
				: getBeatPacks()
			: toggleState === 1
			? handleSearch(
					e,
					selectedBPM,
					selectedTags.length > 1 ? selectedTags : null,
					selectedKey.length > 0 && selectedKey !== 'Key' ? selectedKey : null,
					selectedScale.length > 0 && selectedScale !== 'Scale'
						? selectedScale
						: null
			  )
			: handleSearchBeatPack(
					e,
					selectedBPM,
					selectedTags.length > 1 ? selectedTags : null
			  );
	};
	const onImportSearch = (e) => {
		e.length <= 1 ? fetchImportData() : handleImportSearch(e);
	};
	const onTagSearch = () => {
		selectedTags.length === 0
			? fetchData()
			: handleSearch(searched, bmpValue[1], selectedTags);
	};

	const handleChangeTag = (e) => {
		setTagSearch(e);

		if (e.length > 1) {
			var config = {
				method: 'get',
				url: `${process.env.REACT_APP_API_URL}/file/searchtagfiltervalues/${e}`,
				headers: {
					Authorization: headerkey,
				},
			};
			axios(config)
				.then(function (response) {
					if (response.data.success) {
						setTagList(response.data.searchedTags);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			setTagList(filterValues.tagsWithCount);
		}
	};
	const handleChangeKeyandBpm = (e) => {
		setTagSearch(e);
	};

	function Tabbtn(props) {
		const { name, value } = props;
		return (
			<Button
				size={'sm'}
				bg='#000201'
				mt={{ xl: 4, lg: 4, md: 3, base: 1 }}
				px={{ xl: 6, lg: 5, md: 5, base: 8 }}
				// borderWidth={1}
				// borderColor={'#fff'}
				_hover={{ bg: 'transparent' }}
				_pressed={{
					bg: 'transparent',
					borderBottomStyle: 'line',
					borderBottomWidth: '4',
					borderColor: '#2EBA55',
				}}
				borderBottomStyle={'line'}
				borderBottomWidth={4}
				borderColor={toggleState === value ? '#2EBA55' : '#000201'}
				onPress={() => getButton(value)}
			>
				<Text
					fontSize={{ xl: '18px', lg: '16px', md: '16px', base: '16px' }}
					fontFamily={'Poppins'}
					fontWeight={'normal'}
					color={toggleState === value ? '#fff' : '#AFAFAF'}
					alignSelf={'center'}
				>
					{name}
				</Text>
			</Button>
		);
	}

	function TabSwitch(props) {
		const { name, showMore, setShowMore } = props;
		const clickHandler = (e) => {
			setShowMore(e.target?.checked);
		};
		return (
			<HStack w={'50%'} px={3} py='3'>
				<Text
					fontSize={{ lg: '14px', base: '10px' }}
					color={'#D9D9D9'}
					alignSelf={'center'}
					pr={2}
					fontFamily={'Poppins'}
				>
					{name}
				</Text>
				<label className={styles.switch}>
					<input type='checkbox' onChange={(e) => clickHandler(e)} />
					<span className={`${styles.slider} ${styles.round}`} />
				</label>
			</HStack>
		);
	}
	const toggleSecondaryBar = (val) => {
		setShowSecondaryBar(val);
	};
	const onEditFile = (key, scale, bpm, tags, name, coverImage, id) => {
		var data = new FormData();
		data.append('audioicon', coverImage);
		data.append('BPM', bpm);
		data.append('key', key);
		data.append('scale', scale);
		tags.map((tag) => {
			data.append('tags', tag);
		});
		data.append('fileName', name);

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/file/editfile/${id}`,
			headers: {
				Authorization: headerkey,
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				const { data } = response;
				console.log(response);
				if (data.file) {
					setEditFileModal(false);
					toast.success('File updated successfully');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const toggleEditFileModal = () => {
		console.log('toggleEditFileModal', editFileModal);
		setEditFileModal(!editFileModal);
	};
	useEffect(() => {
		fetchData();
		// fetchImportData();
		getFilterValue();
		getBeatPacks();
	}, []);

	return (
		<Box bg='#000201' w={'100%'} alignSelf={'center'}>
			<Box
				bg='#000201'
				w={'90%'}
				alignSelf={'center'}
				//   px={{ xl: 10, lg: 8, md: 8, base: 4 }}
			>
				<HStack
					py={{ xl: 10, lg: 8, md: 8, base: 4 }}
					bg='#000201'
					w={'100%'}
					flexWrap={'wrap'}
					flexGrow={1}
					alignSelf={'center'}
					justifyContent={'space-between'}
				>
					<Heading
						fontSize={{ xl: '40px', lg: '36px', md: '30px', base: '20px' }}
						fontWeight={'medium'}
						fontFamily={'Poppins'}
						color='#fff'
						alignSelf={'center'}
						py={3}
					>
						My Library
					</Heading>
					<Hidden from='base' till='md'>
						<Box w={{ '2xl': '50%', xl: '45%' }} alignSelf={'center'} py={3}>
							<SearchFieldComponent
								placeholder='Search in Library'
								onChangeSearch={onChangeSearch}
								alignSelf={'center'}
								borderWidth='67px'
								borderColor='#afafaf'
								backgroundColor={'#1C1B1B'}
							/>
						</Box>
					</Hidden>

					<HStack
						w={{ xl: '30%', lg: '30%', base: '55%' }}
						py={3}
						justifyContent={'space-between'}
					>
						<Hidden from='md'>
							<Button
								py={1.5}
								px={2}
								bg={'#1C1B1B'}
								alignSelf={'center'}
								_pressed={{ bg: '#2EBB55' }}
								_hover={{ bg: '#2EBB55' }}
							>
								<Image
									source={require('../../assets/Icons/searchIcon.png')}
									alt='Search Field'
									color='#ffff'
									w={{ xl: '30px', lg: '24px', md: '20px', base: '12px' }}
									h={{ xl: '30px', lg: '24px', md: '20px', base: '12px' }}
									alignItems={'center'}
								/>
							</Button>
						</Hidden>
						<Menu
							rounded={'lg'}
							borderColor={'#fff'}
							borderWidth={1}
							alignSelf={'center'}
							color={'#afafaf'}
							fontSize={{ md: '16px' }}
							mt={2}
							w={{ lg: '20vw', base: '30vh' }}
							_text={{
								color: '#afafaf',
							}}
							background={'#1C1B1B'}
							placement={'bottom'}
							trigger={(triggerProps) => {
								return (
									<Pressable
										bg={'#1C1B1B'}
										rounded={'sm'}
										alignSelf={'center'}
										//   p={0}
										py={{ xl: 2.5, lg: 2.5, base: 1.5 }}
										px={{ xl: 3, lg: 3, base: 2 }}
										_hover={{ bg: '#2EBA55' }}
										_focused={{ bg: '#2EBA55' }}
										_pressed={{ bg: 'transparent' }}
										accessibilityLabel='More options menu'
										{...triggerProps}
									>
										<Image
											source={require('../../assets/Icons/filter.png')}
											w={{ xl: '30px', lg: '24px', md: '20px', base: '12px' }}
											h={{ xl: '30px', lg: '24px', md: '20px', base: '12px' }}
											alt='filter'
											alignSelf={'center'}
										/>
									</Pressable>
								);
							}}
						>
							<Menu.Item
								bg={'#1C1B1B'}
								m={0}
								pb={2}
								w={'100%'}
								flexGrow={1}
								flexWrap={'wrap'}
								flexBasis={'max-content'}
								_focus={{
									bg: '#1C1B1B',
								}}
								_hover={{
									bg: '#1C1B1B',
								}}
								_pressed={{
									bg: '#1C1B1B',
								}}
								_text={{
									color: '#fff',
									textAlign: 'center',
									margin: 'auto',
									textDecoration: 'none',
									py: 2,
								}}
								borderBottomWidth='1px'
								borderBottomColor='#afafaf'
							>
								<HStack flexWrap={'wrap'} flexGrow={1} w={'100%'}>
									<Heading
										color={'#fff'}
										textAlign={'center'}
										py={2}
										pr={2}
										fontFamily={'Poppins'}
										fontWeight={'normal'}
										fontSize={'md'}
									>
										Tags:
									</Heading>

									{tags &&
										tags.map((tag, index) => (
											<HStack
												bg={'transparent'}
												borderColor={'white'}
												rounded='md'
												borderWidth={1}
												pl={1}
												alignSelf={'center'}
												m={1}
												color={'#afafaf'}
											>
												{tag}

												<Button
													h={'0.5'}
													bg={'#1C1B1B'}
													alignSelf={'center'}
													onPress={() => {
														selectedTags.includes(tag)
															? removeTag(tag)
															: addTag(tag);
														toggleTag();
													}}
													_hover={{ bg: '#1C1B1B' }}
													_focused={{ bg: '#1C1B1B' }}
													_pressed={{ bg: '#1C1B1B' }}
												>
													{selectedTags.includes(tag) ? 'x' : '+'}
												</Button>
											</HStack>
										))}
								</HStack>
							</Menu.Item>
							<Menu.Item
								bg={'#1C1B1B'}
								m={0}
								p={0}
								flexWrap={'wrap'}
								flexBasis={'max-content'}
								_focus={{
									bg: '#1C1B1B',
								}}
								_hover={{
									bg: '#1C1B1B',
								}}
								_pressed={{
									bg: '#1C1B1B',
								}}
								_text={{
									color: '#fff',
									textAlign: 'center',
									margin: 'auto',
									textDecoration: 'none',
									py: 2,
								}}
								borderBottomWidth='1px'
								borderBottomColor='#afafaf'
							>
								<Heading
									color={'#fff'}
									textAlign={'center'}
									py={2}
									pr={2}
									fontFamily={'Poppins'}
									fontWeight={'normal'}
									fontSize={'md'}
								>
									BMP
								</Heading>
								<Slider
									sx={{
										color: '#2EBA55',
									}}
									getAriaLabel={() => 'BPM range'}
									value={bmpValue}
									onChange={handleChange}
									valueLabelDisplay='auto'
									getAriaValueText={valuetext}
									min={10}
									max={filterValues?.maxBPM}
								/>
							</Menu.Item>
							<Menu.Item
								bg={'#1C1B1B'}
								m={0}
								p={0}
								flexWrap={'wrap'}
								flexBasis={'max-content'}
								_focus={{
									bg: '#1C1B1B',
								}}
								_hover={{
									bg: '#1C1B1B',
								}}
								_pressed={{
									bg: '#1C1B1B',
								}}
								_text={{
									color: '#fff',
									textAlign: 'center',
									margin: 'auto',
									textDecoration: 'none',
									py: 2,
								}}
								borderBottomWidth='1px'
								borderBottomColor='#afafaf'
							>
								<Menu
									rounded={'md'}
									borderColor={'#fff'}
									borderWidth={1}
									alignSelf={'center'}
									color={'#afafaf'}
									fontSize={{ md: '16px' }}
									mt={2}
									w={'12vw'}
									_text={{
										color: '#afafaf',
									}}
									background={'#1C1B1B'}
									placement={'bottom'}
									trigger={(triggerProps2) => {
										return (
											<Pressable
												bg={'#1C1B1B'}
												rounded={'sm'}
												mr={2}
												w={'100%'}
												_hover={{ bg: '#1C1B1B' }}
												_focused={{ bg: '#1C1B1B' }}
												_pressed={{ bg: '#1C1B1B' }}
												accessibilityLabel='More options menu'
												{...triggerProps2}
											>
												<HStack justifyContent={'space-between'} py={1}>
													<Heading
														color={'#fff'}
														textAlign={'center'}
														py={2}
														pr={2}
														fontFamily={'Poppins'}
														fontWeight={'normal'}
														fontSize={'md'}
													>
														{selectedKey}
													</Heading>
													<KeyboardArrowDownRoundedIcon
														sx={{
															color: '#AFAFAF',
															fontSize: 30,
															alignSelf: 'center',
														}}
													/>
												</HStack>
											</Pressable>
										);
									}}
								>
									{dataKeys.map((item, index) => (
										<Menu.Item
											bg={'#1C1B1B'}
											m={0}
											p={0}
											_focus={{ bg: 'transparent' }}
											_hover={{ bg: 'transparent' }}
											_pressed={{ bg: 'transparent' }}
											borderTopWidth={index === 0 ? '0' : '1px'}
											borderTopColor='#afafaf'
											onPress={() => setSelectedKey(item)}
										>
											<Heading
												color={'#fff'}
												textAlign={'center'}
												py={1}
												pr={2}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{item}
											</Heading>
										</Menu.Item>
									))}
								</Menu>
								{/* <Heading
									color={'#fff'}
									textAlign={'center'}
									py={2}
									pr={2}
									fontFamily={'Poppins'}
									fontWeight={'normal'}
									fontSize={'md'}
								>
									Key
								</Heading>
								<Input
									h={'12'}
									my={2}
									variant={'unstyled'}
									_important={{
										backgroundColor: 'transparent',
										borderColor: 'ransparent',
									}}
									fontSize={{ lg: '18px', md: '16px', sm: '12px' }}
									color={'#afafaf'}
								></Input> */}
							</Menu.Item>
							<Menu.Item
								bg={'#1C1B1B'}
								m={0}
								p={0}
								flexWrap={'wrap'}
								flexBasis={'max-content'}
								_focus={{
									bg: '#1C1B1B',
								}}
								_hover={{
									bg: '#1C1B1B',
								}}
								_pressed={{
									bg: '#1C1B1B',
								}}
								_text={{
									color: '#fff',
									textAlign: 'center',
									margin: 'auto',
									textDecoration: 'none',
									py: 2,
								}}
							>
								<Menu
									rounded={'md'}
									borderColor={'#fff'}
									borderWidth={1}
									alignSelf={'center'}
									color={'#afafaf'}
									fontSize={{ md: '16px' }}
									mt={2}
									w={'15vw'}
									_text={{
										color: '#afafaf',
									}}
									background={'#1C1B1B'}
									placement={'bottom'}
									trigger={(triggerProps3) => {
										return (
											<Pressable
												bg={'#1C1B1B'}
												rounded={'sm'}
												w={'100%'}
												_hover={{ bg: '#1C1B1B' }}
												_focused={{ bg: '#1C1B1B' }}
												_pressed={{ bg: '#1C1B1B' }}
												accessibilityLabel='More options menu'
												{...triggerProps3}
											>
												<HStack justifyContent={'space-between'}>
													<Heading
														color={'#fff'}
														textAlign={'center'}
														py={2}
														pr={2}
														fontFamily={'Poppins'}
														fontWeight={'normal'}
														fontSize={'md'}
													>
														{selectedScale}
													</Heading>
													<KeyboardArrowDownRoundedIcon
														sx={{
															color: '#AFAFAF',
															fontSize: 30,
															alignSelf: 'center',
														}}
													/>
												</HStack>
											</Pressable>
										);
									}}
								>
									{dataScales.map((item, index) => (
										<Menu.Item
											bg={'#1C1B1B'}
											m={0}
											p={0}
											_focus={{ bg: 'transparent' }}
											_hover={{ bg: 'transparent' }}
											_pressed={{ bg: 'transparent' }}
											borderTopWidth={index === 0 ? '0' : '1px'}
											borderTopColor='#afafaf'
											onPress={() => setSelectedScale(item)}
										>
											<Heading
												w={'100%'}
												color={'#fff'}
												textAlign={'center'}
												py={1}
												fontFamily={'Poppins'}
												fontWeight={'normal'}
												fontSize={{ xl: 'md', base: 'xs' }}
											>
												{item}
											</Heading>
										</Menu.Item>
									))}
								</Menu>
								{/* <Heading
                  color={'#fff'}
                  textAlign={'center'}
                  py={2}
                  pr={2}
                  fontFamily={'Poppins'}
                  fontWeight={'normal'}
                  fontSize={'md'}
                >
                  Scale
                </Heading>
                <Input
                  h={'12'}
                  my={2}
                  variant={'unstyled'}
                  _important={{
                    backgroundColor: 'transparent',
                    borderColor: 'ransparent',
                  }}
                  fontSize={{ lg: '18px', md: '16px', sm: '12px' }}
                  color={'#afafaf'}
                ></Input> */}
							</Menu.Item>
						</Menu>

						<Button
							py={{ xl: 2.5, lg: 2.5, base: 1.5 }}
							px={{ xl: 3, lg: 3, base: 2 }}
							bg={'#1C1B1B'}
							// w={'18%'}
							// h='full'
							// fontSize={{ lg: '16px', md: '16px', sm: '12px' }}
							alignSelf={'center'}
							_pressed={{ bg: '#2EBB55' }}
							_hover={{ bg: '#2EBB55' }}
							onPress={() => {
								setToggleState(1);
								fetchData();
							}}
						>
							<Hidden from='base' till='md'>
								<FiSend
									size='30'
									my='4'
									style={{ alignSelf: 'center' }}
									color={toggleState === 6 ? '#2EBA55' : '#e3e3e3'}
								/>
							</Hidden>
							<Hidden from='md'>
								<FiSend
									size='12'
									my='4'
									style={{ alignSelf: 'center' }}
									color={toggleState === 6 ? '#2EBA55' : '#e3e3e3'}
								/>
							</Hidden>
						</Button>
						<Button
							bg={'#2EBB55'}
							borderWidth={1}
							borderColor={'#2EBB55'}
							_hover={{ bg: 'transparent', _text: { color: '#2EBB55' } }}
							_pressed={{ bg: '#2EBB55', _text: { color: '#e3e3e3' } }}
							_text={{
								color: '#e3e3e3',
								fontSize: { xl: '24px', lg: '20px', md: '16px', base: '12px' },
								fontFamily: 'Poppins',
								textAlign: 'center',
								textTransform: 'uppercase',
								fontWeight: 'medium',
							}}
							w={{ xl: '45%', lg: '45%', base: '30%' }}
							py={{ xl: 1.5, lg: 1.5, base: '2px' }}
							alignSelf={'center'}
							onPress={() => setShowBeatPack(true)}
						>
							Upload
						</Button>
						<Hidden from='md'>
							<Button
								py={1.5}
								px={1.5}
								bg={'#1C1B1B'}
								alignSelf={'center'}
								_pressed={{ bg: '#2EBB55' }}
								_hover={{ bg: '#2EBB55' }}
							>
								<Image
									w={'12px'}
									h={'12px'}
									source={require('../../assets/Icons/menu.png')}
									alt='User Profile Pic'
								/>
							</Button>
						</Hidden>
					</HStack>
				</HStack>
				<VStack
					bg='#000201'
					w={'100%'}
					alignSelf={'center'}
					mb={{ xl: 10, lg: 8, md: 8, base: 6 }}
				>
					<HStack
						borderBottomStyle={'line'}
						borderBottomWidth={2}
						borderColor={'#fff'}
						w={'100%'}
						justifyContent={['normal', 'space-between', 'space-between']}
						flexWrap={'wrap'}
					>
						<HStack w={{ xl: '60%', lg: '60%', md: '60%', base: '100%' }}>
							<Tabbtn name={'All'} value={1} />
							<Tabbtn name={'Beat Pack'} value={2} />
							<Tabbtn name={'Inbox'} value={3} />
						</HStack>
						<Hidden from='base' till='md'>
							<HStack w={'40%'} justifyContent={'space-evenly'}>
								<TabSwitch
									name={'Receive Loops'}
									showMore={loopsSwitch}
									setShowMore={() => setLoopsSwitch(true)}
								/>
								<TabSwitch
									name={'Receive Beats'}
									showMore={beatsSwitch}
									setShowMore={() => setBeatsSwitch(true)}
								/>
							</HStack>
						</Hidden>
					</HStack>
				</VStack>
				{showSecondaryBar ? (
					<VStack width={'100%'} alignSelf={'center'}>
						<HStack
							w={'100%'}
							px='1'
							py={['1', '1', '1']}
							alignSelf={'center'}
							justifyContent={'space-between'}
						>
							<Box>
								<Heading
									color={'#fff'}
									fontSize={{ xl: 40, lg: 30, md: 24, base: 18 }}
									fontWeight={500}
									fontFamily={'Poppins'}
									lineHeight={{ xl: 60, lg: 50, md: 40, base: 20 }}
								>
									{toggleState === 1 ? 'Library' : 'Pack'}
								</Heading>
							</Box>
							{toggleState === 2 ? (
								<HStack
									// w={{ lg: '20%', base: '30%' }}
									px='1'
									py='3'
									alignSelf={'center'}
								>
									<Box w={'100%'}>
										<Button
											bg={'#2EBB55'}
											borderWidth={1}
											borderColor={'#2EBB55'}
											_hover={{
												bg: 'transparent',
												_text: { color: '#2EBB55' },
											}}
											_pressed={{
												bg: '#2EBB55',
												_text: { color: '#e3e3e3' },
											}}
											_text={{
												color: '#e3e3e3',
												fontSize: { xl: '20px', base: '16px' },
												fontFamily: 'Poppins',
												textAlign: 'center',
												textTransform: 'uppercase',
												fontWeight: 'medium',
											}}
											w={{ lg: '100%', base: '100%' }}
											py={2}
											alignSelf={'center'}
											onPress={() => setShowBeatPack(true)}
										>
											Create +
										</Button>
									</Box>
								</HStack>
							) : (
								''
							)}
						</HStack>

						<HStack pt={{ xl: 10, lg: 8, md: 8, base: 5 }} pb={5}>
							<Hidden from='base' till='md'>
								<Box width={417}>
									<HStack
										backgroundColor={'#1c1b1b'}
										w={'100%'}
										borderRadius={5}
										_focus={{ bg: 'transparent', borderWidth: 0 }}
									>
										<Input
											w={'100%'}
											py={3.5}
											color={'#AFAFAF'}
											fontSize={{ md: 'lg', base: 'sm' }}
											borderWidth={0}
											rounded={'lg'}
											borderColor={'transparent'}
											bg={'1C1B1B'}
											placeholder={'Search Pack'}
											value={librarySearch}
											onChangeText={(e) => {
												onhandleSearchLibrary(e);
											}}
											_important={{
												bg: 'transparent',
												_focus: { borderColor: '#2eba55', shadow: 0 },
											}}
											focusOutlineColor={'#2eba55'}
											InputLeftElement={
												<Image
													source={require('../../assets/Icons/searchIcon.png')}
													alt='Search Field'
													color='#ffff'
													size={{ xl: 5, md: 5.5, base: 4 }}
													mx={3}
													alignItems={'center'}
												/>
											}
										/>
									</HStack>
								</Box>
							</Hidden>
							<Hidden from='md'>
								<Button
									py={2}
									px={2.5}
									bg={'#1C1B1B'}
									alignSelf={'center'}
									_pressed={{ bg: '#2EBB55' }}
									_hover={{ bg: '#2EBB55' }}
								>
									<Image
										source={require('../../assets/Icons/searchIcon.png')}
										alt='Search Field'
										color='#ffff'
										w={{ xl: '30px', lg: '24px', md: '20px', base: '16px' }}
										h={{ xl: '30px', lg: '24px', md: '20px', base: '16px' }}
										alignItems={'center'}
									/>
								</Button>
							</Hidden>
							<Box px={{ xl: 5, lg: 4, md: 4, base: 2 }} alignSelf={'center'}>
								<div className={styles.tagIcon}>
									<Button
										size={'sm'}
										py={{ xl: '13px', lg: '10px', md: '8px', base: '5px' }}
										bg='transparent'
										borderWidth={'1'}
										borderColor={'#afafaf'}
										w={{ lg: '100%', base: '80%' }}
										rounded={'md'}
										alignSelf={'center'}
										_text={{
											color: '#afafaf',
											fontSize: {
												lg: '16px',
												md: '16px',
												sm: '14px',
												base: '14px',
											},
											px: 1,
											fontFamily: 'Poppins',
										}}
										_hover={{
											bg: '#2EBA55',
											_text: {
												color: '#fff',
											},
										}}
										_focused={{ bg: 'transparent' }}
										_pressed={{ bg: 'transparent' }}
										onPress={() => {
											setShowTagModal(true);
										}}
										leftIcon={
											<>
												<Hidden from='base' till='md'>
													<TagIcon />
												</Hidden>
												<Hidden from='md'>
													<TagIconMbl />
												</Hidden>
											</>
										}
									>
										Tags
									</Button>
								</div>
							</Box>
							<Box alignSelf={'center'}>
								<div className={styles.bpmandkey}>
									<Button
										size={'sm'}
										py={{ xl: '13px', lg: '10px', md: '8px', base: '5px' }}
										bg='transparent'
										borderWidth={'1'}
										borderColor={'#afafaf'}
										w={{ lg: '100%', base: '90%' }}
										rounded={'md'}
										alignSelf={'center'}
										justifyContent={'flex-start'}
										_text={{
											color: '#afafaf',
											fontSize: {
												lg: '16px',
												md: '16px',
												sm: '14px',
												base: '14px',
											},

											px: 1,
											fontFamily: 'Poppins',
										}}
										_hover={{
											bg: '#2EBA55',
											_text: {
												color: '#fff',
											},
										}}
										_focused={{ bg: 'transparent' }}
										_pressed={{ bg: 'transparent' }}
										onPress={() => {
											setShowKeyBpmModal(true);
										}}
										leftIcon={
											<>
												<Hidden from='base' till='md'>
													<KeyandBPM />
												</Hidden>
												<Hidden from='md'>
													<KeyandBPMMbl />
												</Hidden>
											</>
										}
									>
										{toggleState === 1 ? 'Key & BPM' : 'BPM'}
									</Button>
								</div>
							</Box>

							{/* <Box px={5}>
							<div className={styles.filter}>
								<Button
									size={'sm'}
									py={'13px'}
									bg='transparent'
									borderWidth={'1'}
									borderColor={'#afafaf'}
									w={'100%'}
									rounded={'md'}
									alignSelf={'center'}
									_text={{
										color: '#afafaf',
										fontSize: {
											lg: '16px',
											md: '16px',
											sm: '14px',
											base: '14px',
										},
										fontFamily: 'Poppins',
									}}
									_hover={{
										bg: '#2EBA55',
										_text: {
											color: '#fff',
										},
									}}
									onPress={() => {
										setShowTypeModal(true);
									}}
									leftIcon={<Filter />}
								>
									Type
								</Button>
							</div>
						</Box> */}
						</HStack>
					</VStack>
				) : (
					''
				)}
				<VStack bg='#000201' width={'100%'} alignSelf={'center'}>
					<VStack py={5}>
						{toggleState === 1 ? (
							isLoading ? (
								<div className={styles.loaderDiv}>
									<Loader />
								</div>
							) : (
								<AudioLibraryBody
									numb={1}
									createBeatPack={() => setShowBeatPack(true)}
									searchResult={audioAll}
									handleDelete={handleDelete}
									fetchData={fetchData}
									header={headerkey}
									editFileModal={editFileModal}
									showFileModal={toggleEditFileModal}
									onEditFile={onEditFile}
								/>
							)
						) : null}
						{toggleState === 2 ? (
							<BeatPackTab
								data={beatPackData}
								getBeatPack={getBeatPacks}
								onDelete={handleDeleteBeatPack}
								createBeatPack={() => setShowBeatPack(true)}
								toggleSecondaryBar={(val) => toggleSecondaryBar(val)}
								onEditFile={onEditFile}
							/>
						) : null}
						{toggleState === 3 ? <AudioUpload header={headerkey} /> : null}
					</VStack>
				</VStack>
			</Box>
			{showTagModal ? (
				<TagsPopup
					openModal={showTagModal}
					closeModal={() => {
						setShowTagModal(false);
						toggleState === 1
							? handleSearch(
									librarySearch,
									selectedBPM,
									selectedTags.length > 0 ? selectedTags : null,
									selectedKey.length > 0 && selectedKey !== 'Key'
										? selectedKey
										: null,
									selectedScale.length > 0 && selectedScale !== 'Scale'
										? selectedScale
										: null
							  )
							: handleSearchBeatPack(
									librarySearch,
									selectedBPM,
									selectedTags.length > 0 ? selectedTags : null
							  );
					}}
					data={tagList}
					value={tagSearch}
					selectedTags={selectedTags}
					addTag={addTag}
					removeTag={removeTag}
					handleChangeTag={handleChangeTag}
				/>
			) : (
				''
			)}
			{showKeyBpmModal ? (
				<BpmPopup
					openModal={showKeyBpmModal}
					setKey={setSelectedKey}
					isPack={toggleState === 1 ? false : true}
					selectedKey={selectedKey}
					scale={selectedScale}
					setScale={setSelectedScale}
					bpm={selectedBPM}
					setBPM={setSelectedBPM}
					closeModal={() => {
						setShowKeyBpmModal(false);
						toggleState === 1
							? handleSearch(
									librarySearch,
									selectedBPM,
									selectedTags.length > 0 ? selectedTags : null,
									selectedKey.length > 0 && selectedKey !== 'Key'
										? selectedKey
										: null,
									selectedScale.length > 0 && selectedScale !== 'Scale'
										? selectedScale
										: null
							  )
							: handleSearchBeatPack(
									librarySearch,
									selectedBPM,
									selectedTags.length > 0 ? selectedTags : null
							  );
					}}
				/>
			) : (
				''
			)}
			{showTypeModal ? (
				<TypePopup
					openModal={showTypeModal}
					closeModal={() => setShowTypeModal(false)}
				/>
			) : (
				''
			)}
			{showBeatPackCreate ? (
				<BeatPackCreation
					openModal={showBeatPackCreate}
					getAllAudioFiles={fetchData}
					audioData={audioAll}
					getBeatPacks={getBeatPacks}
					librarySearch={librarySearch}
					onhandleSearchLibrary={onhandleSearchLibrary}
					editFileModal={editFileModal}
					showFileModal={toggleEditFileModal}
					showTagModal={setShowTagModal}
					showKeyBpmModal={setShowKeyBpmModal}
					closeModal={() => setShowBeatPack(false)}
					onEditFile={onEditFile}
				/>
			) : (
				''
			)}
		</Box>
	);
};
export default AudioLibrarySample;
