/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Modal,
  Input,
  Radio,
  Text,
} from 'native-base';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
// import { AddSevicersModal } from './AddServicesModal/AddServicesModal';

// import ProfileTitle from '../ProfileTitle';

const PlatformModal = (props) => {
  const { showUploadModal, closeUploadModal, platform } = props;

  return (
    <Modal
      isOpen={showUploadModal}
      bg={'#141313 '}
      h={'auto'}
      shadow={7}
      rounded={'lg'}
      w={{ xl: '60%', lg: '60%', base: '90%' }}
      onClose={closeUploadModal}
      alignSelf={'center'}
      justifyContent={'flex-start'}
      mt={40}
    >
      <Box w={'100%'} bg={'#141313'} rounded={'lg'}>
        <HStack my={3} w={'100%'}>
          <Heading
            px={3}
            color={'#fff'}
            fontWeight={'normal'}
            fontFamily={'Poppins'}
            textTransform={'capitalize'}
            fontSize={{ lg: '3xl', base: 'xl' }}
          >
            Upload {platform} Video
          </Heading>

          <Button
            variant={'ghost'}
            px={3}
            py={0}
            position={'absolute'}
            top={0}
            right={0}
            justifyContent={'flex-end'}
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            onPress={closeUploadModal}
          >
            <CloseIcon sx={{ color: '#AFAFAF' }} />
          </Button>
        </HStack>

        <Box w={'95%'} alignSelf={'center'} mb={5}>
          <Box mt={5}>
            <Heading
              py={2}
              color={'#AFAFAF'}
              fontSize={{ xl: 'xl', base: 'md' }}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
              textTransform={'capitalize'}
              textAlign={'left'}
            >
              {platform} video URL
            </Heading>
            <Input
              width={'100%'}
              py={2.5}
              rounded={'md'}
              my={2}
              bg={'#141313'}
              borderColor={'#afafaf'}
              _hover={{ bg: '#141313', borderColor: '#AFAFAF' }}
              _focus={{ bg: '#141313', borderColor: '#AFAFAF' }}
              focusOutlineColor={'#afafaf'}
              color={'#fff'}
              fontSize={'md'}
              placeholderTextColor={'#AFAEAE70'}
              size={'lg'}
            />
          </Box>
          <Box mt={5}>
            <Heading
              py={2}
              color={'#AFAFAF'}
              fontSize={{ xl: 'xl', base: 'md' }}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
              textAlign={'left'}
            >
              Description
            </Heading>
            <Input
              width={'100%'}
              py={2.5}
              rounded={'md'}
              my={2}
              bg={'#141313'}
              borderColor={'#afafaf'}
              _hover={{ bg: '#141313', borderColor: '#AFAFAF' }}
              _focus={{ bg: '#141313', borderColor: '#AFAFAF' }}
              focusOutlineColor={'#afafaf'}
              multiline={true}
              numberOfLines={5}
              color={'#fff'}
              fontSize={'md'}
              placeholderTextColor={'#AFAEAE70'}
              size={'lg'}
            />
          </Box>
          <Text
            py={5}
            fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
            color={'#afafaf'}
            fontFamily={'Poppins'}
            alignSelf={'center'}
          >
            OR
          </Text>
          <Box
            my={4}
            backgroundColor={'#1c1b1b'}
            py={5}
            rounded={'lg'}
            alignSelf={'center'}
            w={'100%'}
          >
            <VStack
              backgroundColor={'#1c1b1b'}
              alignSelf={'center'}
              w={'85%'}
              height={150}
              justifyContent={'center'}
              borderStyle={'dashed'}
              borderColor={'white'}
              borderWidth='2'
              rounded={'lg'}
            >
              {/* <input
							{...getInputProps()}
							backgroundColor={'#1C1G1B'}
							accept='audio/*'
						/> */}

              {/* {fileRejectionItems} */}
              {console.log('uploading')}
              <Image
                source={require('../../../assets/Icons/files.png')}
                borderColor={'#1C1G1B'}
                size={12}
                alignSelf={'center'}
                alt='profile'
              />
              <Text
                fontSize={{ lg: '24px', md: '16px', sm: '16px' }}
                color={'#2EBA55'}
                fontWeight={'bold'}
                alignSelf={'center'}
                py={3}
              >
                Drag and Drop more Files!
              </Text>
            </VStack>
          </Box>
          <HStack
            my={8}
            w={'40%'}
            alignSelf={'flex-end'}
            justifyContent={'space-between'}
          >
            <Button
              size={'sm'}
              bg='#141313'
              borderStyle={'solid'}
              borderWidth={'1'}
              borderColor={'#2EBA55'}
              w={'45%'}
              rounded={'md'}
              alignSelf={'center'}
              _text={{
                color: '#2EBA55',
                fontSize: {
                  lg: '18px',
                  md: '16px',
                  sm: '14px',
                  base: '14px',
                },
                fontFamily: 'Poppins',
              }}
              _hover={{
                bg: '#2EBA55',
                _text: {
                  color: '#fff',
                },
              }}
              _focused={{ bg: '#2EBA55' }}
              _pressed={{ bg: 'transparent' }}
              onPress={closeUploadModal}
            >
              Cancel
            </Button>
            <Button
              size={'sm'}
              bg='#2EBA55'
              borderWidth={1}
              borderColor='#2EBA55'
              _text={{
                color: '#fff',
                fontSize: {
                  lg: '18px',
                  md: '16px',
                  sm: '14px',
                  base: '14px',
                },
                fontFamily: 'Poppins',
              }}
              _hover={{
                bg: 'transparent',
                _text: {
                  color: '#2EBA55',
                },
              }}
              onPress={closeUploadModal}
              w={'45%'}
              rounded={'md'}
              alignSelf={'flex-end'}
              _focused={{ bg: '#2EBA55' }}
              _pressed={{ bg: '#2EBA55' }}
            >
              UPLOAD
            </Button>
          </HStack>
        </Box>
      </Box>
    </Modal>
  );
};

export default PlatformModal;
