import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UpdateProfile } from './Mutation';
import { Notification } from '../../NotifyMsg/Notification';
// import { AuthContext } from "../../Auth/Layout";
function useHomeMutation() {
	const navigate = useNavigate(null);
	//   const {
	//     activeStep,
	//     setActiveStep,

	//     disableBtn,

	//     setIsDisableBtn,
	//     sendotpAgain,
	//     setSendOtpAgain,
	//   } = useContext(AuthContext);
	const [updateProfile] = useMutation(UpdateProfile, {
		onCompleted(data) {
			if (data?.updateProfile?.success) {
				Notification('success', data.updateProfile.message);
				setTimeout(() => {
					// navigate("/talent-dashboard")
					//   sendotpAgain
					//     ? setActiveStep(activeStep)
					//     : setActiveStep((prevActiveStep) => prevActiveStep + 1);
					//   setIsDisableBtn(false);
				}, 1400);
			} else {
				Notification('error', data.updateProfile.message);
				navigate('/signin');
				//     setActiveStep(activeStep);
				//     setIsDisableBtn(false);
			}
		},
	});

	return { updateProfile };
}

export default useHomeMutation;
