import React from 'react';

function FlagSvg(props) {
  const { color, width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='-2.4 -2.4 28.80 28.80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        stroke-linecap='round'
        stroke-linejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <g id='Navigation / Flag'>
          <path
            id='Vector'
            d='M4 21V15.6871M4 15.6871C9.81818 11.1377 14.1818 20.2363 20 15.6869V4.31347C14.1818 8.86284 9.81818 -0.236103 4 4.31327V15.6871Z'
            stroke={color}
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          ></path>
        </g>
      </g>
    </svg>
  );
}
export default FlagSvg;
