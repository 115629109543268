import { gql } from '@apollo/client';

export const addUpdateBillingInfo = gql`
  mutation addUpdateBillingInformation(
    $country: String
    $state: String
    $address: String
    $zipCode: Float
  ) {
    addUpdateBillingInformation(
      country: $country
      state: $state
      address: $address
      zipCode: $zipCode
    ) {
      success
      message
      isTokenExpired
      billingInformation {
        country
        state
        address
        zipCode
      }
    }
  }
`;
export const getMyBillingInfo = gql`
  query {
    getMyBillingInformations {
      success
      message
      isTokenExpired
      billingInformation {
        country
        address
        zipCode
        state
      }
    }
  }
`;
