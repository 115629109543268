import { gql } from '@apollo/client';

export const SignUpMutation = gql`
	mutation (
		$firstname: String!
		$lastname: String!
		$email: String!
		$password: String!
		$countrycode: Float
		$phoneno: Float
		$labelName: String
		$roleId: String!
		$instaUrl: String!
		$profilePic: String!
		$username: String!
		$genre: String
		$isPreSignUp: Boolean!
		$noOfMonthlyListeners: Float
		$spotifyUrl: String
		$howDidYouKnow: String
		$howDidYouKnowText: String
	) {
		signUp(
			firstname: $firstname
			lastname: $lastname
			email: $email
			password: $password
			countrycode: $countrycode
			phoneno: $phoneno
			labelName: $labelName
			roleId: $roleId
			instaUrl: $instaUrl
			profilePic: $profilePic
			userName: $username
			genre: $genre
			isPreSignUp: $isPreSignUp
			noOfMonthlyListeners: $noOfMonthlyListeners
			spotifyUrl: $spotifyUrl
			howDidYouKnow: $howDidYouKnow
			howDidYouKnowText: $howDidYouKnowText
		) {
			success
			message
			user {
				firstname
				userName
				profilePic
				email
				countrycode
				phoneno
				accountType
				labelName
				_id
			}
			accessToken
			refreshToken
			reactionAccessToken
			reactionRefreshToken
			marketplaceAccessToken
			marketplaceRefreshToken
			sessionId
		}
	}
`;

export const sendOTP = gql`
	mutation ($email: String!, $countrycode: Float, $phoneno: Float) {
		sendOtp(email: $email, countrycode: $countrycode, phoneno: $phoneno) {
			success
			message
		}
	}
`;

export const verifyOTP = gql`
	mutation ($otp: String!) {
		verifyOtp(otp: $otp) {
			success
			message
		}
	}
`;

export const SignInWithFacebook = gql`
	mutation ($userID: String!, $accessToken: String!) {
		facebooklogin(userID: $userID, accessToken: $accessToken) {
			success
			user {
				firstname
			}
			message
		}
	}
`;

export const SetRole = gql`
	mutation (
		$userId: String!
		$roleId: String!
		$labelName: String!
		$howDidYouKnow: String!
		$instaUrl: String!
	) {
		setRole(
			userId: $userId
			roleId: $roleId
			labelName: $labelName
			howDidYouKnow: $howDidYouKnow
			instaUrl: $instaUrl
		) {
			success
			message
		}
	}
`;

export const getNewRoles = gql`
	query {
		getHowDoYouKnows {
			_id
			name
		}
	}
`;

export const GetRoles = gql`
	query {
		getRoles {
			roleName
			_id
		}
	}
`;

export const SignInWithGoogle = gql`
	mutation ($idToken: String!) {
		googlelogin(idToken: $idToken) {
			success
			message
			isLogin
			user {
				_id
				firstname
				lastname
				email
			}
			accessToken
			refreshToken
		}
	}
`;
