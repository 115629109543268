import { useMutation } from "@apollo/client";
import React from "react";
import { ADDFOLLOWER } from "./Query";

export default function useAddFollower() {
  const [loading, setLoading] = React.useState(false);

  const [addUserToFollower] = useMutation(ADDFOLLOWER, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setLoading(false);
      console.log("data...", data);
      return true;
    },
    onError(err) {
      setLoading(false);
      return false;
    },
  });
  const addFollower = async (profileId) => {
    setLoading(true);
    const response = await addUserToFollower({
      variables: {
        profileId,
      },
    });
    return response;
  };

  return [loading, addFollower];
}
