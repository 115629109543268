import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Modal,
  Input,
  Radio,
} from "native-base";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { AddSevicersModal } from "../ProfileModals/AddServicesModal/AddServicesModal";

import ProfileTitle from "../ProfileTitle";
import { AddTeamModal } from "../ProfileModals/AddTeamModal";

export const ProfileHeaderIcon = () => {
  const [modalFlag1, setModalFlag1] = useState(false);
  const [modalFlag2, setModalFlag2] = useState(false);
  const [modalFlag3, setModalFlag3] = useState(false);
  const [value, setValue] = useState(1);
  const [displayServices, setDisplayServices] = useState(true);
  return (
    <Box bg={"#000201"} w={"100%"} py={10}>
      <HStack alignItems={"center"}>
        <Box w={"40%"} >
        <ProfileTitle fontSize={{lg:"4xl",md:"3xl",sm:"2xl",base:"xl"}}   color={"#fff"} text={"music producer"} />
     
        </Box>
        <Box w={"60%"} alignItems={"flex-end"}>
          <HStack w={"50%"} justifyContent={"flex-end"}>
            <Button
              bg={"#1C1B1B"}
              rounded={"full"}
              mx={{ lg: "2", base: "1" }}
              _hover={{ bg: "#AFAFAF" }}
              onPress={() => {
                setModalFlag1(!modalFlag1);
              }}
            >
              <Image
                source={require("../../../assets/Profile/HeaderIcon/briefcase.png")}
             size={{ lg: "6", base: "3" }}
                mt="0.5"
              />
            </Button>
            <Button
              size="sm"
              bg={"#1C1B1B"}
              rounded={"full"}
              mx={{ lg: "2", base: "1" }}
              _hover={{ bg: "#AFAFAF" }}
              onPress={() => {
                setModalFlag2(!modalFlag2);
              }}
            >
              <Image
                source={require("../../../assets/Profile/HeaderIcon/adduser.png")}
             size={{ lg: "6", base: "3" }}
                mt="0.5"
              />
            </Button>
            <Button
              size="sm"
              bg={"#1C1B1B"}
              rounded={"full"}
              mx={{ lg: "2", base: "1" }}
              // mr={{ lg: '10', base: '5' }}
              _hover={{ bg: "#AFAFAF" }}
              onPress={() => {
                setModalFlag3(!modalFlag3);
              }}
            >
              <Image
                source={require("../../../assets/Profile/HeaderIcon/edit.png")}
                size={{ lg: "6", base:"3"}}
                mt="0.5"
              />
            </Button>
          </HStack>
        </Box>
      </HStack>
      <AddSevicersModal modalFlag={modalFlag1} close={()=>setModalFlag1(!modalFlag1) }/>
      <AddTeamModal modalFlag={modalFlag2} close={()=>setModalFlag2(!modalFlag2) }/>
      {/* <Modal
        isOpen={modalFlag}
        bg={"#00000070 "}
        size={"full"}
        w={{xl: '35%', lg: "40%", base: "90%" }}
        m={"auto"}
        h={"50%"}
        onClose={() => setModalFlag(!modalFlag)}
      >
        <Box w={"100%"} bg={"#141313"} rounded={"lg"}>
          <HStack w={"95%"} alignSelf={"center"} my={3}>
            <HStack w={"90%"}>
              <Box alignSelf={"center"}>
                <BusinessCenterIcon sx={{ color: "#AFAFAF" }} />
              </Box>
              <Heading
                px={3}
                color={"#E3E3E3"}
                fontWeight={"normal"}
                fontSize={{ lg: "2xl", base: "lg" }}
              >
                Add Services
              </Heading>
            </HStack>
            <Button
              w={"#10%"}
              borderWidth={2}
              justifyContent={'flex-end'}
              variant={"ghost"}
              p={0}
              onPress={() => setModalFlag(!modalFlag)}
            >
              <CloseIcon sx={{ color: "#AFAFAF" }} />
            </Button>
          </HStack>
          <Divider />
          <Box w={"95%"} alignSelf={"center"}>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Name
              </Heading>
              <HStack
                w={"100%"}
                rounded={"md"}
                borderWidth={1}
                borderColor={"#D2D4D1"}
                justifyContent={"space-between"}
              >
                <Button
                  variant={"ghost"}
                  p={0}
                  px={0.5}
                  w={"90%"}
                  onPress={() => setDisplayServices(false)}
                  _pressed={{ bg: "#000" }}
                  _hover={{ bg: "transparent" }}
                  justifyContent={"flex-start"}
                >
                  <Heading
                    p={3}
                    color={"#AFAFAF"}
                    fontSize={"xl"}
                    fontWeight={"normal"}
                    textAlign={"left"}
                  >
                    Basic Feedback
                  </Heading>
                </Button>
                <Box alignSelf={"center"}>
                  <ExpandMoreIcon sx={{ color: "#AFAFAF" }} />
                </Box>
              </HStack>
            </Box>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Description
              </Heading>
              <Input
                placeholder={"Enter Description"}
                multiline={true}
                numberOfLines={5}
                width={"100%"}
                py={2.5}
                textAlignVertical={"top"}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
              />
            </Box>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Price
              </Heading>
              <Input
                placeholder={"Enter your Price"}
                width={"100%"}
                py={2.5}
                textAlignVertical={"top"}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
              />
            </Box>
            <HStack
              my={5}
              alignSelf={"center"}
              w={"60%"}
              justifyContent={"space-between"}
            >
              <Button
                w={"40%"}
                variant={"ghost"}
                borderWidth={1}
                borderColor={"#AFAFAF"}
                _pressed={{ bg: "transparent", borderColor: "#AFAFAF" }}
              >
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  Cancel
                </Heading>
              </Button>
              <Button w={"40%"} bg={"#2EBB55"}>
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  OK
                </Heading>
              </Button>
            </HStack>
          </Box>
        </Box>
      </Modal> */}
      {/* <Modal
        isOpen={modalFlag1}
        bg={"#00000070 "}
        size={"full"}
        w={{xl: '35%', lg: "40%", base: "90%" }}
        m={"auto"}
        h={"50%"}
        onClose={() => setModalFlag1(!modalFlag1)}
      >
        <Box w={"100%"} bg={"#141313"} rounded={"lg"}>
          <HStack w={"95%"} alignSelf={"center"} my={3}>
            <HStack w={"90%"}>
              <Box alignSelf={"center"}>
                <BusinessCenterIcon sx={{ color: "#AFAFAF" }} />
              </Box>
              <Heading
                px={3}
                color={"#E3E3E3"}
                fontWeight={"normal"}
                fontSize={{ lg: "2xl", base: "lg" }}
              >
                Add Services
              </Heading>
            </HStack>
            <Button
              w={"10%"}
              variant={"ghost"}
              p={0}
              justifyContent={'flex-end'}
              onPress={() => setModalFlag1(!modalFlag1)}
            >
              <CloseIcon sx={{ color: "#AFAFAF" }} />
            </Button>
          </HStack>
          <Divider />
          <Box w={"95%"} alignSelf={"center"}>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Name
              </Heading>
              <Input
                placeholder={"Enter Name"}
                width={"100%"}
                py={2.5}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
              />
            </Box>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                What permissions would you like over these users?
              </Heading>
              <Input
                placeholder={"Enter your Price"}
                width={"100%"}
                py={2.5}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
                InputRightElement={
                  <HStack alignSelf={"center"}>
                    <Heading
                      fontSize={"md"}
                      fontWeight={"normal"}
                      color={"#afafaf"}
                    >
                      Artist
                    </Heading>
                    <KeyboardArrowDownOutlinedIcon sx={{ color: "#afafaf" }} />
                  </HStack>
                }
              />
            </Box>
            <Box mt={2} mb={4}>
              <Radio.Group
                name="myRadioGroup"
                accessibilityLabel="favorite number"
                value={value}
                onChange={(nextValue) => {
                  setValue(nextValue);
                }}
              >
                <HStack flexWrap={"wrap"} flexGrow={1} alignSelf={"flex-start"}>
                  <Radio size="sm" value={1} my={1} colorScheme="emerald">
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      Full Access
                    </Heading>
                  </Radio>
                  <Radio
                    size="sm"
                    value={2}
                    my={1}
                    mx={1}
                    colorScheme="emerald"
                  >
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      Manager
                    </Heading>
                  </Radio>
                  <Radio
                    size="sm"
                    value={3}
                    my={1}
                    mx={1}
                    colorScheme="emerald"
                  >
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      A&R
                    </Heading>
                  </Radio>
                  <Radio
                    size="sm"
                    value={4}
                    my={1}
                    mx={1}
                    colorScheme="emerald"
                  >
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}
                    >
                      Legal
                    </Heading>
                  </Radio>
                  <Radio size="sm" value={5} my={1} colorScheme="emerald">
                    <Heading
                      fontSize={"lg"}
                      fontWeight={"medium"}
                      color={"#807F7F"}

                    >
                      No Permissions
                    </Heading>
                  </Radio>
                </HStack>
              </Radio.Group>
            </Box>
            <HStack
              my={5}
              alignSelf={"center"}
              w={"60%"}
              justifyContent={"space-between"}
            >
              <Button
                w={"40%"}
                variant={"ghost"}
                borderWidth={1}
                borderColor={"#AFAFAF"}
                _pressed={{ bg: "transparent", borderColor: "#AFAFAF" }}
              >
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  Cancel
                </Heading>
              </Button>
              <Button w={"40%"} bg={"#2EBB55"}>
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  OK
                </Heading>
              </Button>
            </HStack>
          </Box>
        </Box>
      </Modal> */}
      {/* <Modal
        isOpen={modalFlag2}
        bg={"#00000070 "}
        size={"full"}
        w={{xl: '35%', lg: "45%", base: "90%" }}
        m={"auto"}
        h={"50%"}
        onClose={() => setModalFlag2(!modalFlag2)}
      >
        <Box w={"100%"} bg={"#141313"} rounded={"lg"}>
          <HStack w={"95%"} alignSelf={"center"} my={3}>
            <HStack w={"90%"}>
              <Box alignSelf={"center"}>
                <BusinessCenterIcon sx={{ color: "#AFAFAF" }} />
              </Box>
              <Heading
                px={3}
                color={"#E3E3E3"}
                fontWeight={"normal"}
                fontSize={{ lg: "2xl", base: "lg" }}
              >
                Add Services
              </Heading>
            </HStack>
            <Button
              w={"#10%"}
              variant={"ghost"}
              p={0}
              onPress={() => setModalFlag2(!modalFlag2)}
            >
              <CloseIcon sx={{ color: "#AFAFAF" }} />
            </Button>
          </HStack>
          <Divider />
          <Box w={"95%"} alignSelf={"center"} my={3} mb={5}>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                Basic Feedback
              </Heading>
              <Button bg={"#2EBB55"} w={"20%"}>
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                High Level Feedback
              </Heading>
              <Button bg={"#2EBB55"} w={"20%"}>
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                Beat Submissions
              </Heading>
              <Button bg={"#2EBB55"} w={"20%"}>
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                Paid Collabs
              </Heading>
              <Button bg={"#2EBB55"} w={"20%"}>
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
          </Box>
        </Box>
      </Modal> */}
    </Box>
  );
};
