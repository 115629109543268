import React from 'react';

function Eye() {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M15.9375 6.86422C14.205 4.14172 11.67 2.57422 9 2.57422C7.64829 2.58414 6.32619 2.97116 5.1825 3.69172C3.91778 4.5019 2.85146 5.58615 2.0625 6.86422C1.6938 7.51492 1.5 8.25007 1.5 8.99797C1.5 9.74587 1.6938 10.481 2.0625 11.1317C3.795 13.8617 6.33 15.4217 9 15.4217C10.3517 15.4118 11.6738 15.0248 12.8175 14.3042C14.0822 13.494 15.1485 12.4098 15.9375 11.1317C16.3062 10.481 16.5 9.74587 16.5 8.99797C16.5 8.25007 16.3062 7.51492 15.9375 6.86422ZM9 12.0317C8.40072 12.0317 7.8149 11.854 7.31662 11.5211C6.81834 11.1881 6.42998 10.7149 6.20065 10.1612C5.97131 9.60759 5.91131 8.99836 6.02822 8.41059C6.14514 7.82283 6.43372 7.28294 6.85747 6.85919C7.28122 6.43543 7.82112 6.14685 8.40888 6.02994C8.99664 5.91303 9.60587 5.97303 10.1595 6.20236C10.7132 6.4317 11.1864 6.82006 11.5194 7.31834C11.8523 7.81662 12.03 8.40244 12.03 9.00172C12.0305 9.39976 11.9525 9.79399 11.8004 10.1618C11.6483 10.5297 11.4251 10.8639 11.1436 11.1454C10.8622 11.4268 10.528 11.65 10.1601 11.8021C9.79228 11.9542 9.39805 12.0322 9 12.0317Z'
				fill='#AFAFAF'
			/>
			<path
				d='M8.9998 6.85547C8.57662 6.85621 8.16315 6.98233 7.8116 7.2179C7.46005 7.45347 7.18618 7.78792 7.02458 8.17903C6.86298 8.57014 6.82089 9.00036 6.90363 9.41537C6.98637 9.83039 7.19023 10.2116 7.48946 10.5108C7.7887 10.81 8.16989 11.0139 8.5849 11.0966C8.99991 11.1794 9.43013 11.1373 9.82124 10.9757C10.2124 10.8141 10.5468 10.5402 10.7824 10.1887C11.0179 9.83712 11.1441 9.42365 11.1448 9.00047C11.1451 8.7187 11.0898 8.43964 10.9821 8.17926C10.8744 7.91889 10.7164 7.68231 10.5172 7.48307C10.318 7.28383 10.0814 7.12584 9.82101 7.01815C9.56063 6.91045 9.28157 6.85517 8.9998 6.85547Z'
				fill='#AFAFAF'
			/>
		</svg>
	);
}

export default Eye;
