import React from "react";

import { Box, HStack,Image } from "native-base";
import { StatsWithMap } from "../Airplay/StatsWithMap";
import axios from "axios";
import moment from "moment"
import AirplayTable from "../Airplay/AirplayTable";
import CountryFilter from "../Airplay/CountryFilter";
import ApplyFiters from "../ApplyFiters";
import PlatFormDropDown from "../Playlists/PlatFormDropDown";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./styles.module.css"
function CumulativeTable() {
  const [countryPlays, setCountryPlays] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);
  const [radioPlays, setRadioPlays] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [totalCount, setTotalCount] = React.useState({
    countries: 0,
    plays: 0,
  });
  React.useEffect(() => {
    getPlaysFromCountry();
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const configOptions = (method, url) => {
    return {
      method: method,
      url: url,
      headers: {
        "x-api-key": "soundcharts",
        "x-app-id": "soundcharts",
      },
    };
  };
  const getPlaysFromCountry = async () => {
    try {
      const date = new Date();
      const endDate = moment(date).format("YYYY-MM-DD");
      const startDate = moment(
        new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
      ).format("YYYY-MM-DD");
      const config = configOptions(
        "get",
        `https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/broadcasts?offset=0&limit=100`
      );
      setLoading(true);
      const response = await axios(config);
      if (response.status === 200) {
        let { items } = response.data;
        let radioWithDuration = items.map((item) => {
          return {
            country_name: item.radio.countryName,
            plays: item.duration,
            airedAt: moment(item.airedAt).format("YYYY-MM-DD"),
            radio_name: item.radio.name,
          };
        });

        let countryWithPlays = [];
        let playsWithDate = [];
        let radioWithPlays = [];
        for (var i = 0; i < radioWithDuration.length; i++) {
          //get all countries with their plays
          if (countryWithPlays.length === 0) {
            countryWithPlays.push(radioWithDuration[i]);
          } else {
            // eslint-disable-next-line no-loop-func
            let checkCountry = countryWithPlays.findIndex(
              (play) => play.country_name === radioWithDuration[i].country_name
            );
            if (checkCountry < 0) {
              countryWithPlays.push(radioWithDuration[i]);
            } else {
              countryWithPlays[checkCountry].plays +=
                radioWithDuration[i].plays;
            }
          }

          //get plays with their dates

          if (playsWithDate.length === 0) {
            playsWithDate.push(radioWithDuration[i]);
          } else {
            let checkDate = playsWithDate.findIndex(
              (play) => play.airedAt === radioWithDuration[i].airedAt
            );
            if (checkDate < 0) {
              playsWithDate.push(radioWithDuration[i]);
            } else {
              playsWithDate[checkDate].plays += radioWithDuration[i].plays;
            }
          }

          if (radioWithPlays.length === 0) {
            radioWithPlays.push(radioWithDuration[i]);
          } else {
            let checkRadio = radioWithPlays.findIndex(
              (play) => play.radio_name === radioWithDuration[i].radio_name
            );
            if (checkRadio < 0) {
              radioWithPlays.push(radioWithDuration[i]);
            } else {
              radioWithPlays[checkRadio].plays += radioWithDuration[i].plays;
            }
          }
        }
        setCountryPlays(countryWithPlays.sort((a, b) => b.plays - a.plays));
        setChartData(playsWithDate.sort((a, b) => b.airedAt - a.airedAt));
        setRadioPlays(radioWithPlays.sort((a, b) => b.plays - a.plays));
        setTotalCount({
          countries: countryWithPlays.length,
          plays: countryWithPlays.reduce((acc, cwp) => acc + cwp.plays, 0),
        });
        console.log("plays : ", playsWithDate);
      }
      setLoading(false);
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  return (
    <>
    <Box>
      <HStack
        // flexDirection={{ sm: "row", base: "column" }}
        // justifyContent={"space-between"}
        w={"100%"}
        alignItems="center"  >
        <HStack  
        space={{xl:2}}
    flexWrap={"wrap"}
w={{base:"98%"}}
alignItems={"center"}
         >
            <Box alignItems={{sm:"",base:"center"}} w={{xl:"17%",lg:"22%",md:"50%",sm:"50%",base:"100%"}}>  <PlatFormDropDown/></Box>
         <Box  alignItems={{sm:"",base:"center"}}  w={{xl:"17%",lg:"22%",md:"50%",sm:"50%",base:"100%"}}>  <CountryFilter/></Box>
         <Box  alignItems={{sm:"",base:"center"}}  w={{xl:"17%",lg:"22%",md:"50%",sm:"50%",base:"100%"}}>  <CountryFilter/></Box>
    <Box   alignItems={{sm:"center",base:"center"}} alignSelf={{lg:"center",md:"center"}}  w={{xl:"15%",lg:"22%",md:"50%",sm:"50%",base:"100%"}}>  <ApplyFiters/></Box>
  <Box  alignItems={{sm:"",base:"center"}} alignSelf={{lg:"center",md:"start"}}> <div  className={styles.checkboxCharts} > 
      <FormControlLabel

sx={{ color: "grey", textTransform: "capitalize",alignSelf:"center"}}
label={"   Show historical peaks"}
control={
  <Checkbox
    checked={checked}
    sx={{
      color: "#AFAFAF",
      "&.Mui-checked": {
        color: "#2EBB55",
      },
    }}
    onChange={handleChange}
    inputProps={{ "aria-label": "controlled" }}
  >
Show historical peaks

  </Checkbox>


}
/></div></Box>
     

              </HStack>
              <HStack justifyContent={"end"} width={{base:"2%"}}><Image source={require("../../../../assets/Icons/excalmationIcon.png")} height={{lg:"40px",base:"25px"}} width={{lg:"40px",base:"25px"}} resizeMode={"contain"}/></HStack>
      </HStack>
   
    <Box my={5} py={5} bg={"#1C1B1B"} w={"100%"} rounded={"lg"}>
      
        <AirplayTable
          radioData={radioPlays}
          filterHandler={getPlaysFromCountry}
          loading={loading}
        />
      </Box>
    </Box>
      {/* <Box my={5} py={5} bg={'#1C1B1B'} w={'100%'} rounded={'lg'}>
				<StatsWithMap data={countryPlays} count={totalCount} />
			</Box>
			<Box my={5} py={5} bg={'#1C1B1B'} w={'100%'} rounded={'lg'}>
				<PlaysChart data={chartData} />
			</Box> */}
    
    </>
  );
}

export default CumulativeTable;
