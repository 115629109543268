import { AxiosInstance } from "../../../AxiosInstance.js/AxiosInstance";
export const GetPlayListPosition = (platform, dateOrder) => {
  let Platform = platform.toLowerCase();
  console.log("platform", Platform);

  return new Promise((resolve, reject) => {
    AxiosInstance.get(
      `/v2.20/song/7d534228-5165-11e9-9375-549f35161576/playlist/current/${Platform}?type=all&offset=0&limit=15&sortBy=entryDate&sortOrder=${
        dateOrder ? "asc" : "desc"
      }`
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
export default GetPlayListPosition;
