import { useState } from 'react';
import { Box, HStack, VStack, Button, Hidden, Text } from 'native-base';
import React, { useEffect } from 'react';
import { AuthContext } from './Layout';
import { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { getNewRoles, GetRoles } from '../../Graphql_services/SignUpMutation';

const AccountType = (props) => {
	const { w, h, flag, borderadius } = props;
	const { data } = useQuery(flag ? GetRoles : getNewRoles);
	const [accounts, setAccounts] = useState([]);

	const {
		setAccountType,
		SetRoleID,
		SetNewRoleId,

		selectedroleid,
		setselectedroleID,
		selectedHear,
		setselectedHear,

		setothersSocial,
		setselectedAccount,
	} = useContext(AuthContext);

	const handlePress = (e) => {
		if (flag) {
			setAccountType(e.currentTarget.innerText.trim());
		}
		if (e.currentTarget.innerText === 'Other') {
			setothersSocial(true);
		} else {
			setothersSocial(false);
		}
		flag ? SetRoleID(e.currentTarget.id) : SetNewRoleId(e.currentTarget.id);
		flag && setselectedAccount(e.currentTarget.innerText);
		flag
			? setselectedroleID(e.currentTarget.id)
			: setselectedHear(e.currentTarget.id);
	};

	useEffect(() => {
		if (data && flag) {
			setAccounts([
				data.getRoles[2].roleName,
				data.getRoles[1].roleName,
				data.getRoles[3].roleName,

				data.getRoles[4].roleName,
				data.getRoles[0].roleName,
			]);
		} else if (data && !flag) {
			setAccounts([
				data.getHowDoYouKnows[0].name,
				data.getHowDoYouKnows[1].name,
				data.getHowDoYouKnows[2].name,

				data.getHowDoYouKnows[3].name,
				data.getHowDoYouKnows[4].name,
				data.getHowDoYouKnows[5].name,
				data.getHowDoYouKnows[6].name,
			]);
		}
	}, [data]);
	return (
		<Box mb={{ md: 10, base: 5 }}>
			{/* <Hidden from="base" till="lg"> */}

			{flag && (
				<>
					<Hidden from='base' till='sm'>
						<VStack space={10}>
							<HStack
								space={{ xl: '30px', md: '30px', base: '30px' }}
								flexGrow={1}
								flexWrap={'wrap'}
								justifyContent={{
									lg: 'center',
									md: 'space-between',
									base: 'center',
								}}
							>
								<Button
									nativeID={
										flag ? data?.getRoles[2]._id : data?.getHowDoYouKnows[0]._id
									}
									onPress={(e) => handlePress(e)}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									mb={{ md: 0, base: 5 }}
									_text={{
										color:
											selectedroleid === data?.getRoles[2]._id
												? '#fff'
												: '#AFAFAF',
										textTransform: 'capitalize',
										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.9rem',
											base: '0.75rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedroleid === data?.getRoles[2]._id
											? '#2EBB55'
											: '#1C1B1B'
									}
									borderRadius={borderadius}
									w={w}
									h={h}
								>
									{accounts[0]}
								</Button>
								{/* <Button
									nativeID={
										flag ? data?.getRoles[1]._id : data?.getHowDoYouKnows[1]._id
									}
									onPress={handlePress}
									px={{ base: 1 }}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									_text={{
										color:
											selectedroleid === data?.getRoles[1]._id
												? '#E3E3E3'
												: '#AFAFAF',
										textTransform: 'capitalize',
										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.9rem',
											base: '0.75rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedroleid === data?.getRoles[1]._id
											? '#2EBB55'
											: '#1C1B1B'
									}
									borderColor={'#AFAFAF'}
									borderRadius={borderadius}
									w={w}
									h={h}
								>
									{accounts[1]}
								</Button> */}

								<div className='btn'>
									<Button
										nativeID={
											flag
												? data?.getRoles[3]._id
												: data?.getHowDoYouKnows[2]._id
										}
										mb={{ md: 0, base: 5 }}
										onPress={handlePress}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										_text={{
											color:
												selectedroleid === data?.getRoles[3]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												xl: '18px',
												md: '1rem',
												sm: '0.9rem',
												base: '0.75rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedroleid === data?.getRoles[3]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										borderColor={'#AFAFAF'}
										borderRadius={borderadius}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[2]}
									</Button>
								</div>
								<Button
									nativeID={
										flag ? data?.getRoles[4]._id : data?.getHowDoYouKnows[3]._id
									}
									onPress={handlePress}
									_focus={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									backgroundColor={
										selectedroleid === data?.getRoles[4]._id
											? '#2EBB55'
											: '#1C1B1B'
									}
									_text={{
										color:
											selectedroleid === data?.getRoles[4]._id
												? '#E3E3E3'
												: '#AFAFAF',
										textTransform: 'capitalize',
										fontFamily: 'poppins',
										textAlign: 'center',
										fontSize: {
											xl: '18px',
											md: '1rem',
											sm: '0.9rem',
											base: '0.75rem',
										},
									}}
									_hover={{
										backgroundColor: '#2EBB55',
										_text: {
											color: '#E3E3E3',
										},
									}}
									borderColor={'#AFAFAF'}
									borderRadius={borderadius}
									w={w}
									h={h}
								>
									{accounts[3]}
								</Button>
							</HStack>

							<Hidden>
								<HStack></HStack>
							</Hidden>

							<Box>
								<HStack
									space={{ xl: '30px', md: '20px', base: '30px' }}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={'center'}
								>
									{/* <Button
										nativeID={
											flag
												? data?.getRoles[4]._id
												: data?.getHowDoYouKnows[3]._id
										}
										onPress={handlePress}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedroleid === data?.getRoles[4]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										_text={{
											color:
												selectedroleid === data?.getRoles[4]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												xl: '18px',
												md: '1rem',
												sm: '0.9rem',
												base: '0.75rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										borderColor={'#AFAFAF'}
										borderRadius={borderadius}
										w={w}
										h={h}
									>
										{accounts[3]}
									</Button> */}
									<Button
										nativeID={
											flag
												? data?.getRoles[0]._id
												: data?.getHowDoYouKnows[4]._id
										}
										onPress={handlePress}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										_text={{
											color:
												selectedroleid === data?.getRoles[0]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												xl: '18px',
												md: '1rem',
												sm: '0.9rem',
												base: '0.75rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedroleid === data?.getRoles[0]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										borderColor={'#AFAFAF'}
										borderRadius={borderadius}
										h={h}
										w={w}
									>
										{accounts[4]}
									</Button>
									<Box w={w} h={h}></Box>
									<Box w={w} h={h}></Box>
								</HStack>
							</Box>
						</VStack>
					</Hidden>
					<Hidden from='sm'>
						<div className='accounttypesSignup'>
							<VStack space={5}>
								<HStack
									space={'10px'}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={'center'}
								>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[2]._id
													: data?.getHowDoYouKnows[0]._id
											}
											onPress={(e) => handlePress(e)}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[2]._id
														? '#fff'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[2]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											_
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[0]}
										</Button>
									</div>

									{/* <div className='button'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[1]._id
													: data?.getHowDoYouKnows[1]._id
											}
											onPress={handlePress}
											px={{ base: 1 }}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[1]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[1]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											w={w}
											h={h}
										>
											{accounts[1]}
										</Button>
									</div> */}
									<div className='button btn'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[3]._id
													: data?.getHowDoYouKnows[3]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[3]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											_text={{
												color:
													selectedroleid === data?.getRoles[3]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[2]}
										</Button>
									</div>
								</HStack>
								<HStack
									space={'10px'}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={'center'}
								>
									{/* <div className='button btn'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[3]._id
													: data?.getHowDoYouKnows[3]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[3]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											_text={{
												color:
													selectedroleid === data?.getRoles[3]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[2]}
										</Button>
									</div> */}
									<div className='button'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[4]._id
													: data?.getHowDoYouKnows[4]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[4]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[4]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											h={h}
											w={w}
										>
											{accounts[3]}
										</Button>
									</div>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[0]._id
													: data?.getHowDoYouKnows[2]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[0]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[0]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[4]}
										</Button>
									</div>
								</HStack>
								{/* <HStack justifyContent={'center'}>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[0]._id
													: data?.getHowDoYouKnows[2]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getRoles[0]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getRoles[0]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											w={w}
											h={h}
										>
											{accounts[4]}
										</Button>
									</div>
								</HStack> */}
							</VStack>
						</div>
					</Hidden>
				</>
			)}

			{!flag && (
				<>
					<Hidden from='base' till='sm'>
						<VStack mt={12} space={8}>
							<VStack space={5}>
								<HStack
									space={{ xl: '30px', md: '30px', base: '30px' }}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={{
										lg: 'center',
										md: 'space-between',
										base: 'center',
									}}
								>
									<Button
										nativeID={
											flag
												? data?.getRoles[2]._id
												: data?.getHowDoYouKnows[0]._id
										}
										onPress={(e) => handlePress(e)}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[0]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[0]._id
													? '#fff'
													: '#AFAFAF',
											textTransform: 'capitalize',

											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												xl: '18px',
												md: '1rem',
												sm: '0.75rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[0]}
									</Button>
									<Button
										nativeID={
											flag
												? data?.getRoles[1]._id
												: data?.getHowDoYouKnows[1]._id
										}
										onPress={handlePress}
										px={{ base: 1 }}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[1]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[1]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[1]}
									</Button>
									{/* <Button
										nativeID={
											flag
												? data?.getRoles[3]._id
												: data?.getHowDoYouKnows[2]._id
										}
										onPress={handlePress}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[2]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',

											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[2]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[2]}
									</Button> */}
									<Button
										nativeID={
											flag
												? data?.getRoles[4]._id
												: data?.getHowDoYouKnows[3]._id
										}
										onPress={handlePress}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[3]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[3]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[3]}
									</Button>
								</HStack>
							</VStack>

							<VStack>
								<HStack
									// space={5}
									// flexGrow={1}
									// flexWrap={'wrap'}
									// justifyContent={{
									// 	lg: 'center',
									// 	md: 'space-between',
									// 	base: 'center',
									// }}
									space={{ xl: '30px', md: '30px', base: '30px' }}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={{
										lg: 'center',
										md: 'space-between',
										base: 'center',
									}}
								>
									<Button
										nativeID={
											flag
												? data?.getRoles[1]._id
												: data?.getHowDoYouKnows[4]._id
										}
										onPress={handlePress}
										px={{ base: 1 }}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[4]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[4]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[4]}
									</Button>
									<Button
										nativeID={
											flag
												? data?.getRoles[1]._id
												: data?.getHowDoYouKnows[5]._id
										}
										onPress={handlePress}
										px={{ base: 1 }}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[5]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[5]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[5]}
									</Button>
									<Button
										nativeID={
											flag
												? data?.getRoles[1]._id
												: data?.getHowDoYouKnows[6]._id
										}
										onPress={handlePress}
										px={{ base: 1 }}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[6]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[6]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[6]}
									</Button>
								</HStack>
							</VStack>
							<VStack>
								<HStack
									space={{ xl: '30px', md: '30px', base: '30px' }}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={{
										lg: 'center',
										md: 'space-between',
										base: 'center',
									}}
								>
									<Button
										nativeID={
											flag
												? data?.getRoles[3]._id
												: data?.getHowDoYouKnows[2]._id
										}
										onPress={handlePress}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[2]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',

											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[2]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[2]}
									</Button>
									<Button backgroundColor={'transparent'} w={w} h={h}></Button>
									<Button backgroundColor={'transparent'} w={w} h={h}></Button>
								</HStack>
							</VStack>
							{/* <VStack space={10}>
            <HStack space={5}></HStack>
          </VStack> */}
						</VStack>
					</Hidden>
					<Hidden from='sm'>
						<div className='accounttypesSignup'>
							<VStack space={5}>
								<HStack
									space={'10px'}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={'space-evenly'}
								>
									<Button
										nativeID={
											flag
												? data?.getRoles[2]._id
												: data?.getHowDoYouKnows[0]._id
										}
										onPress={(e) => handlePress(e)}
										_focus={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										backgroundColor={
											selectedHear === data?.getHowDoYouKnows[0]._id
												? '#2EBB55'
												: '#1C1B1B'
										}
										_text={{
											color:
												selectedHear === data?.getHowDoYouKnows[0]._id
													? '#E3E3E3'
													: '#AFAFAF',
											textTransform: 'capitalize',
											fontFamily: 'poppins',
											textAlign: 'center',
											fontSize: {
												lg: '18px',
												md: '1rem',
												sm: '0.8rem',
												base: '1rem',
											},
										}}
										_hover={{
											backgroundColor: '#2EBB55',
											_text: {
												color: '#E3E3E3',
											},
										}}
										borderColor={'#AFAFAF'}
										borderRadius={5}
										// borderWidth="1"
										w={w}
										h={h}
									>
										{accounts[0]}
									</Button>

									<div className='button'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[1]._id
													: data?.getHowDoYouKnows[1]._id
											}
											onPress={handlePress}
											px={{ base: 1 }}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getHowDoYouKnows[1]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getHowDoYouKnows[1]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											w={w}
											h={h}
										>
											{accounts[1]}
										</Button>
									</div>
								</HStack>
								<HStack
									space={'10px'}
									flexGrow={1}
									flexWrap={'wrap'}
									justifyContent={'space-evenly'}
								>
									<div className='button btn'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[3]._id
													: data?.getHowDoYouKnows[2]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getHowDoYouKnows[2]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											_text={{
												color:
													selectedroleid === data?.getHowDoYouKnows[2]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											w={w}
											h={h}
										>
											{accounts[2]}
										</Button>
									</div>
									<div className='button'>
										{' '}
										<Button
											nativeID={
												flag
													? data?.getRoles[4]._id
													: data?.getHowDoYouKnows[3]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedroleid === data?.getHowDoYouKnows[3]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													xl: '18px',
													md: '1rem',
													sm: '0.75rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedroleid === data?.getHowDoYouKnows[3]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											h={h}
											w={w}
										>
											{accounts[3]}
										</Button>
									</div>
								</HStack>
								<HStack justifyContent={'space-evenly'}>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[0]._id
													: data?.getHowDoYouKnows[4]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedHear === data?.getHowDoYouKnows[4]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													lg: '18px',
													md: '1rem',
													sm: '0.8rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedHear === data?.getHowDoYouKnows[4]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											w={w}
											h={h}
										>
											{accounts[4]}
										</Button>
									</div>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[0]._id
													: data?.getHowDoYouKnows[5]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedHear === data?.getHowDoYouKnows[5]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													lg: '18px',
													md: '1rem',
													sm: '0.8rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedHear === data?.getHowDoYouKnows[5]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											w={w}
											h={h}
										>
											{accounts[5]}
										</Button>
									</div>
								</HStack>
								<HStack justifyContent={'space-evenly'}>
									<div className='button'>
										<Button
											nativeID={
												flag
													? data?.getRoles[0]._id
													: data?.getHowDoYouKnows[6]._id
											}
											onPress={handlePress}
											_focus={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											_text={{
												color:
													selectedHear === data?.getHowDoYouKnows[6]._id
														? '#E3E3E3'
														: '#AFAFAF',
												textTransform: 'capitalize',
												fontFamily: 'poppins',
												textAlign: 'center',
												fontSize: {
													lg: '18px',
													md: '1rem',
													sm: '0.8rem',
													base: '1rem',
												},
											}}
											_hover={{
												backgroundColor: '#2EBB55',
												_text: {
													color: '#E3E3E3',
												},
											}}
											backgroundColor={
												selectedHear === data?.getHowDoYouKnows[6]._id
													? '#2EBB55'
													: '#1C1B1B'
											}
											borderColor={'#AFAFAF'}
											borderRadius={borderadius}
											// borderWidth="1"
											w={w}
											h={h}
										>
											{accounts[6]}
										</Button>
									</div>
									<div className='button'>
										<Button
											w={w}
											h={h}
											backgroundColor={'transparent'}
										></Button>
									</div>
								</HStack>
							</VStack>
						</div>
					</Hidden>
				</>
			)}
		</Box>
	);
};

export default AccountType;
