import React from 'react';
import { Button } from 'native-base';
import '..//components//Auth//Form.css';
const ButtonComponent = (props) => {
	const {
		textProperties,
		text,
		backgroundColor,
		borderwidth,
		paddingXMd,
		paddingXSm,
		paddingYMd,
		bordercolor,
		paddingYSm,
		flag,
		height,

		isDisabled,
		mt,
		w,
		isText,
		onpress,
		paddingXBase,
		paddingYBase,

		// label,
	} = props;

	// const ControlModal = () => {
	//   if (showmodal && !isSaved) {
	//     if (value === "yes") {
	//       setIsSaved(true);
	//       return;
	//     } else if (value === "no") {
	//       setshowmodal(false);
	//     }
	//   } else if (showmodal && isSaved) {
	//     if (labelName === "") {
	//       seterrmsg("label should not be empty");
	//     } else {
	//       setshowmodal(false);

	//       setIsSaved(false);
	//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
	//     }
	//   }
	// };
	// const handlepress = () => {
	//   onpress;
	//   // if (flag) {
	//   //   setshowmodal(false);
	//   // } else {
	//   //   ControlModal();
	//   // }
	// };
	return (
		<Button
			zIndex={-1}
			_hover={
				flag
					? { backgroundColor: '#2EBB55', _text: { color: '#fff' } }
					: {
							backgroundColor: 'transparent',
							borderColor: '#2EBB55',
							borderWidth: 1,
							_text: { color: '#2EBB55' },
					  }
			}
			_text={textProperties}
			px={{ md: paddingXMd, base: paddingXSm }}
			py={{ md: paddingYMd, base: paddingYSm }}
			h={height}
			mt={mt}
			borderColor={bordercolor}
			backgroundColor={backgroundColor}
			borderWidth={borderwidth}
			onPress={onpress}
			isDisabled={isDisabled}
			w={w}
		>
			{text}
		</Button>
	);
};

export default ButtonComponent;
