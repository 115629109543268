import React from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  Divider,
 
} from "native-base";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DownArrow from '../../../assets/Icons/downArrow2.png'

import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
export const TableHeaderCol2 = (props) => {
  const {     isHeader,
    title1,
    title2,
    title3,
    title4,
    bg,
    title5,
    showArrow,
    dateOrder,
    sorted,
    handleSort,} = props;
  return (
    <Box bg={"#1C1B1B"} w={"100%"}>
      <HStack w={"100%"} py={2}>
        <HStack w={"67%"} pr={2} justifyContent={"flex-start"}>
          <Heading
            textTransform={"uppercase"}
         color={!isHeader?"#AFAFAF":"#5F6368"}
            fontFamily={"Poppins"}
             fontSize={ isHeader ?  {lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.6rem"}:{lg:"1rem",md:"0.8rem",sm:"0.7rem",base:"0.6rem"}}
            fontWeight={"normal"}
            textAlign={"left"}
            style={{display:'flex', alignItems:'center'}}
          >
            {title1}
          </Heading>
        </HStack>
        <HStack w={"33%"} pr={2} justifyContent={"flex-end"} >
          <Heading
          
            textTransform={"uppercase"}
         color={!isHeader?"#AFAFAF":"#5F6368"}
            fontFamily={"Poppins"}
            fontSize={ isHeader ?  {lg:"0.9rem",md:"0.75rem",sm:"0.7rem", base:"0.6rem"}:{lg:"1rem",md:"0.8rem",sm:"0.7rem",base:"0.6rem"}}
            fontWeight={"normal"}
            // textAlign={"center"}
          >
            <HStack alignItems={"center"}>
                <p>{title2}</p>
                
                {((isHeader && sorted)) && (
          // <img src={imgDrop} height={"10px"} width={"10px"} />
         <ArrowDropUp
            onClick={handleSort}
            sx={{ marginTop: "-5px", color: "#f00", cursor: "pointer" }}
          />
          )
          }
          {((isHeader && !sorted))  && (<ArrowDropDownIcon
          onClick={handleSort}
          sx={{ marginTop: "-5px", color: "#f00", cursor: "pointer" }}
        />)}
              
                </HStack>
          </Heading>
    
        </HStack>

      </HStack>
      <Divider my={1} />
    </Box>
  );
};
