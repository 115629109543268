import React from 'react';
import { ToastContainer } from 'react-toastify';
import { setContext } from '@apollo/client/link/context';
import 'react-toastify/dist/ReactToastify.css';
import { HttpLink } from '@apollo/client';
import ReactDOM from 'react-dom';
import './index.css';

import Routes from './routes';
import {
	ApolloClient,
	InMemoryCache,
	ApolloLink,
	ApolloProvider,
} from '@apollo/client';
import { AuthProvider } from './context/AuthContext';
const token = localStorage.getItem('token');
const auth = new HttpLink({
	uri: `${process.env.REACT_APP_API_URL}/auth`,
	//	credentials: 'include',
	// other link options...
});

const user = new HttpLink({
	uri: `${process.env.REACT_APP_API_URL}/user`,
	headers: {
		Authorization: token,
	},
	//	credentials: 'include',
});

const client = new ApolloClient({
	link: ApolloLink.split(
		(operation) => operation.getContext().clientName === 'user',
		// the string "third-party" can be anything you want,
		// we will use it in a bit
		user, // <= apollo will send to this if clientName is "third-party"
		auth // <= otherwise will send to this
	),
	cache: new InMemoryCache(),

	// other options
});

// const client = new ApolloClient({
//   // uri: 'http://3.23.184.237:8001/',
//   cache: new InMemoryCache(),
//   uri: "https://new.unitedmarket.com/auth",

// })
ReactDOM.render(
	<ApolloProvider client={client}>
		{/* <ToastContainer /> */}
		<AuthProvider>
			<Routes />
		</AuthProvider>
	</ApolloProvider>,

	document.getElementById('root')
);
