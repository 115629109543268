import { useContext } from 'react';
import { AuthContext } from '../../components/Auth/Layout';
import { useMutation } from '@apollo/client';
import { sendOTP } from '../../Graphql_services/SignUpMutation';
import { Notification } from '../../components/NotifyMsg/Notification';

const useEmailPhoneNoApi = () => {
	const {
		activeStep,
		setActiveStep,
		sendotpAgain,
		setSendOtpAgain,
		setIsPassword,
		setProgressbarValue,
		progressbarvalue,
		setIsDisableBtn,
	} = useContext(AuthContext);

	// const setbtndisabled = () => {
	//   setIsDisableBtn(false);
	// };
	const [sendOtp, { data, loading }] = useMutation(sendOTP, {
		onCompleted(data) {
			if (data?.sendOtp?.success) {
				Notification('success', data.sendOtp.message);
				setTimeout(() => {
					setIsPassword(true);
					// setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
					if (sendotpAgain) {
						setActiveStep(activeStep);
						setProgressbarValue(progressbarvalue);
					} else {
						setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
						setActiveStep((prevActiveStep) => prevActiveStep + 1);
					}
					// sendotpAgain
					//   ? setActiveStep(activeStep)
					//   : setActiveStep((prevActiveStep) => prevActiveStep + 1);
					setIsDisableBtn(false);
				}, 1400);
			} else {
				Notification('error', data.sendOtp.message);
				setActiveStep(activeStep);
				setIsDisableBtn(false);
			}
		},
	});

	return { sendOtp };
};

export default useEmailPhoneNoApi;
