import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Select,
  Text,
} from "native-base";
import { CheckIcon } from "native-base";
import { useQuery } from "@apollo/client";
import { FeildInput } from "./FieldInput";
import { useState } from "react";
import SocialIconTray from "../ProfileView/SocialIconTray";
import ButtonComponent from "../../ButtonComponent";

import { JOBTYPE } from "../../Hooks/UserProfile/AddProfile/JobType/Query";
import { useEffect } from "react";
export const ProfileForm = () => {

  const { data } = useQuery(JOBTYPE,{context:{clientName :"user"}});
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [userName, setUserName] = useState("");
  const [service, setService] = React.useState("");

  useEffect(()=>{
    console.log("job",data)
  },[data])
  return (
    <Box w={"100%"} bg={"#000201"}>
      <Box w={"100%"}>
        <HStack
          // w={"100%"}
          flexGrow={1}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          // alignItems="center"
        >
          <Box w={{ xl: "48%", base: "100%" }}>
            <FeildInput
              label={"User Name"}
              color={"#AFAFAF"}
              control={control}
              type={"username"}
            />
          </Box>
          <Box py={5} alignSelf={"center"} w={{ xl: "48%", base: "100%" }}>
            <Heading
              textTransform={"capitalize"}
              mb={1}
              color={"#AFAFAF"}
              fontWeight={"medium"}
              fontSize={{ lg: "xl", md: "1rem", base: "1.2rem" }}
            >
              job field
            </Heading>
            <Select
              fontSize={{ lg: "18px", md: "16px", base: "14px" }}
              color={"#AFAFAF"}
              w={"100%"}
              selectedValue={service}
              height={{
                xl: "52.9px",
                base: "45px",
              }}
              accessibilityLabel="Choose Service"
              placeholder="Choose Service"
              _
              _actionSheetBody={{ backgroundColor: "black", height: "200px" }}
              _selectedItem={{
                bg: "teal.600",
                fontSize: "md",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => setService(itemValue)}
            >
              <Select.Item label="UX Research" value="ux" />
              <Select.Item label="Web Development" value="web" />
              <Select.Item label="Cross Platform Development" value="cross" />
              <Select.Item label="UI Designing" value="ui" />
              <Select.Item label="Backend Development" value="backend" />
            </Select>
          </Box>
          <Box w={{ xl: "48%", base: "100%" }}>
            <FeildInput
              label={"about me"}
              color={"#AFAFAF"}
              control={control}
              type={"about me"}
            />
          </Box>
          <Box w={{ xl: "48%", base: "100%" }}>
            <FeildInput
              label={"genre"}
              color={"#AFAFAF"}
              control={control}
              type={"genre"}
            />
          </Box>
          <Box py={5} alignSelf={"center"} w={{ xl: "48%", base: "100%" }}>
            <Heading
              textTransform={"capitalize"}
              mb={1}
              color={"#AFAFAF"}
              fontWeight={"medium"}
              fontSize={{ lg: "xl", md: "1rem", base: "1.2rem" }}
            >
              Beats Specialties
            </Heading>
            <Select
              fontSize={{ lg: "18px", md: "16px", base: "14px" }}
              color={"#AFAFAF"}
              w={"100%"}
              selectedValue={service}
              height={{
                xl: "52.9px",
                base: "45px",
              }}
              accessibilityLabel="Choose Service"
              placeholder="Choose Service"
              _selectedItem={{
                bg: "teal.600",
                fontSize: "md",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => setService(itemValue)}
            >
              <Select.Item label="UX Research" value="ux" />
              <Select.Item label="Web Development" value="web" />
              <Select.Item label="Cross Platform Development" value="cross" />
              <Select.Item label="UI Designing" value="ui" />
              <Select.Item label="Backend Development" value="backend" />
            </Select>
          </Box>

          <Box py={5} alignSelf={"center"} w={{ xl: "48%", base: "100%" }}>
            <Heading
              textTransform={"capitalize"}
              mb={1}
              color={"#AFAFAF"}
              fontWeight={"medium"}
              fontSize={{ lg: "xl", md: "1rem", base: "1.2rem" }}
            >
              Loop Specialties
            </Heading>
            <Select
              fontSize={{ lg: "18px", md: "16px", base: "14px" }}
              color={"#AFAFAF"}
              w={"100%"}
              selectedValue={service}
              height={{
                xl: "52.9px",
                base: "45px",
              }}
              accessibilityLabel="Choose Service"
              placeholder="Choose Service"
              _selectedItem={{
                bg: "teal.600",
                fontSize: "md",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => setService(itemValue)}
            >
              <Select.Item label="UX Research" value="ux" />
              <Select.Item label="Web Development" value="web" />
              <Select.Item label="Cross Platform Development" value="cross" />
              <Select.Item label="UI Designing" value="ui" />
              <Select.Item label="Backend Development" value="backend" />
            </Select>
          </Box>
          {/* <Box w={{ xl: "48%", base: "95%" }}>
            <FeildInput
              title={"Beats Specialties"}
              UserName={(text) => setUserName(text)}
            />
          </Box>
          <Box w={{ xl: "48%", base: "95%" }}>
            <FeildInput
              title={"Loop Specialties"}
              UserName={(text) => setUserName(text)}
            />
          </Box> */}
          <Box w={{ xl: "48%", base: "100%" }}>
            <FeildInput
              label={"credits"}
              social={true}
              color={"#AFAFAF"}
              control={control}
              type={"credits"}
            />
          </Box>
          <Box w={{ xl: "48%", base: "100%" }}>
            <FeildInput
              label={"Accolades"}
              info={true}
              color={"#AFAFAF"}
              control={control}
              type={"Accolades"}
            />
          </Box>
          <Box w={"100%"} py={5}>
            <Heading
              color={"#AFAFAF"}
              fontWeight={"medium"}
              fontSize={{ lg: "xl", md: "1rem", base: "1.2rem" }}
            >
              Payments
            </Heading>
            <HStack
              direction={{ md: "row", sm: "column" }}
              w={"100%"}
              space={{ md: "", base: 5 }}
              justifyContent={"space-between"}
              py={2}
              // alignSelf={"center"}
              flexGrow={1}
              flexWrap={"wrap"}
            >
              <ButtonComponent
                backgroundColor={"transparent"}
                // paddingXMd={"80px"}
                paddingXSm={"30px"}
                paddingYSm={2}
                text={"Activate Payout Account"}
                paddingYMd={4}
                textProperties={{
                  color: "#2EBB55",
                  fontWeight: "",
                  fontSize: { xl: "1.2rem", md: "1rem", base: "0.9rem" },
                  fontFamily: "poppins",
                  textTransform: "capitalize",
                }}
                bordercolor={"#2EBB55"}
                borderwidth={2}
                onpress={""}
                w={{ md: "48%", base: "100%" }}
                flag={true}
              />
              <ButtonComponent
                backgroundColor={"transparent"}
                paddingXMd={"80px"}
                paddingXSm={"30px"}
                paddingYSm={"8px"}
                text={"Add Card On File"}
                paddingYMd={4}
                textProperties={{
                  color: "#2EBB55",
                  fontWeight: "",
                  fontSize: { xl: "1.2rem", md: "1rem", base: "0.9rem" },
                  fontFamily: "poppins",
                  textTransform: "capitalize",
                }}
                bordercolor={"#2EBB55"}
                borderwidth={2}
                onpress={""}
                w={{ md: "48%", base: "100%" }}
                flag={true}
              />
              {/* <Button
                variant={"ghost"}
                py={4}
                my={1}
                w={{ xl: "48%", base: "95%" }}
                borderWidth={"2"}
                borderColor={"#2EBB55"}
              >
                <Heading
                  fontSize={"lg"}
                  fontWeight={"medium"}
                  color={"#2EBB55"}
                  textAlign={"center"}
                >
                  Activate Payout Account
                </Heading>
              </Button>
              <Button
                variant={"ghost"}
                py={4}
                my={1}
                w={{ xl: "48%", base: "95%" }}
                borderWidth={"2"}
                borderColor={"#2EBB55"}
              >
                <Heading
                  fontSize={"lg"}
                  fontWeight={"medium"}
                  color={"#2EBB55"}
                  textAlign={"center"}
                >
                  Add Card On File
                </Heading>
              </Button> */}
            </HStack>
          </Box>
          <Box w={"100%"} py={5}>
            <Heading
              mb={1}
              color={"#AFAFAF"}
              fontSize={{ lg: "xl", md: "1rem", base: "1.2rem" }}
            >
              Connect Social Networks
            </Heading>
            <Box>
              <SocialIconTray />
              <ButtonComponent
                backgroundColor={"transparent"}
                paddingXMd={"80px"}
                paddingXSm={"30px"}
                paddingYSm={"8px"}
                text={"Connect"}
                paddingYMd={4}
                textProperties={{
                  color: "#2EBB55",
                  fontWeight: "",
                  fontSize: { xl: "1.2rem", md: "1rem", base: "0.9rem" },
                  fontFamily: "poppins",
                  textTransform: "capitalize",
                }}
                bordercolor={"#2EBB55"}
                borderwidth={2}
                onpress={""}
                w={{ xl: "48%", base: "100%" }}
                flag={true}
              />
              {/* <Button
                variant={"ghost"}
                py={4}
                w={{ xl: "48%", base: "95%" }}
                borderWidth={"2"}
                borderColor={"#2EBB55"}
              >
                <Heading
                  fontSize={"lg"}
                  fontWeight={"medium"}
                  color={"#2EBB55"}
                  textAlign={"center"}
                >
                 Connect
                </Heading>
              </Button> */}
            </Box>
          </Box>
          <Box w={"100%"} py={5}>
            <Heading
              mb={1}
              color={"#AFAFAF"}
              fontWeight={"medium"}
              fontSize={{ lg: "xl", md: "1rem", base: "1.2rem" }}
            >
              Studio
            </Heading>
            <ButtonComponent
              backgroundColor={"transparent"}
              paddingXMd={"80px"}
              paddingXSm={"30px"}
              paddingYSm={"8px"}
              text={"List My Studio"}
              paddingYMd={4}
              textProperties={{
                color: "#2EBB55",
                fontWeight: "",
                fontSize: { xl: "1.2rem", md: "1rem", base: "0.9rem" },
                fontFamily: "poppins",
                textTransform: "capitalize",
              }}
              bordercolor={"#2EBB55"}
              borderwidth={2}
              onpress={""}
              w={{ xl: "48%", base: "100%" }}
              flag={true}
            />
            {/* <Button
                variant={"ghost"}
                py={4}
                my={3}
                w={{ xl: "48%", base: "95%" }}
                borderWidth={"2"}
                borderColor={"#2EBB55"}
              >
                <Heading
                  fontSize={"lg"}
                  fontWeight={"medium"}
                  color={"#2EBB55"}
                  textAlign={"center"}
                >
                  List My Studio
                </Heading>
              </Button> */}
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
