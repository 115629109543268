import { HStack, Image, Input } from 'native-base';
import React, { useState } from 'react';
export const SearchFieldComponent = (props) => {
	const { placeholder, onChangeSearch, backgroundColor, modal } = props;
	const [search, setSearch] = useState('');
	const handleChange = (e) => {
		setSearch(e);
		onChangeSearch(e);
		//console.log(search);
	};
	return (
		<HStack
			backgroundColor={backgroundColor}
			w={'100%'}
			borderRadius={10}
			// py={{ md: 2, base: 1 }}
			_focus={{ bg: 'transparent', borderWidth: 0 }}
		>
			<Input
				w={'100%'}
				pl={1}
				py={modal ? 3 : 3}
				color={'#AFAFAF'}
				fontSize={{ md: modal ? 'md' : 'lg', base: 'sm' }}
				borderWidth={0}
				rounded={'lg'}
				borderColor={'transparent'}
				bg={'1C1B1B'}
				placeholder={placeholder}
				value={search}
				onChangeText={(e) => {
					//console.log('asdasd' + e);
					handleChange(e);
				}}
				_important={{
					bg: 'transparent',
					borderWidth: 0.5,
					borderColor: '#afafaf',
					_focus: { borderColor: '#2eba55', shadow: 0 },
				}}
				focusOutlineColor={'#2eba55'}
				// _important={{backgroundColor: "transparent", borderColor:"#afafaf" , borderWidth: "1"}}
				InputLeftElement={
					<Image
						source={require('../../assets/Icons/searchIcon.png')}
						alt='Search Field'
						color='#ffff'
						size={{ xl: modal ? 5 : 7, md: 5.5, base: 4 }}
						mx={3}
						alignItems={'center'}
					/>
				}
			/>
		</HStack>
	);
};
