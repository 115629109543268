import React from 'react';
import {
	Box,
	Image,
	Heading,
	HStack,
	VStack,
	Button,
	Divider,
} from 'native-base';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
export const TableHeaderCol3 = (props) => {
	const { title1, title2, title3, isHeader, handleSort, sorted } = props;

	// const handleClick=()=>{
	// 	handleSort()
	// }
	return (
		<Box bg={'#1C1B1B'} w={'100%'}>
			<HStack w={'100%'} py={3}>
				<HStack w={'38%'} pr={2} justifyContent={''} alignItems='center'>
					<Heading
						textTransform={'uppercase'}
						color={isHeader ? '#AFAFAF' : '#E3E3E3'}
						fontFamily={'Poppins'}
						fontSize={
							isHeader
								? { lg: '0.9rem', md: '0.75rem', sm: '0.7rem', base: '0.6rem' }
								: { lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }
						}
						fontWeight={'normal'}
						textAlign={'left'}
					>
						{title1}
					</Heading>
				</HStack>
				<HStack w={'32%'} pr={2} justifyContent={'center'} alignItems='center'>
					<Heading
						textTransform={'uppercase'}
						color={isHeader ? '#AFAFAF' : '#E3E3E3'}
						fontFamily={'Poppins'}
						fontSize={
							isHeader
								? { lg: '0.9rem', md: '0.75rem', sm: '0.7rem', base: '0.6rem' }
								: { lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }
						}
						fontWeight={'normal'}
						textAlign={'center'}
					>
						{title2}
					</Heading>
				</HStack>
				<HStack w={'32%'} pr={2} justifyContent={'end'} alignItems='center'>
					<Heading
						color={isHeader ? '#AFAFAF' : '#E3E3E3'}
						textTransform={'uppercase'}
						fontFamily={'Poppins'}
						fontSize={
							isHeader
								? { lg: '0.9rem', md: '0.75rem', sm: '0.7rem', base: '0.6rem' }
								: { lg: '1rem', md: '0.8rem', sm: '0.7rem', base: '0.6rem' }
						}
						fontWeight={'normal'}
						textAlign={'right'}
					>
						{title3}
					</Heading>

					<Box>
						{isHeader && !sorted && (
							<ArrowDropDownIcon
								onClick={handleSort}
								sx={{ color: '#f00', cursor: 'pointer' }}
							/>
						)}
						{isHeader && sorted && (
							<ArrowDropUp
								onClick={handleSort}
								sx={{ color: '#f00', cursor: 'pointer' }}
							/>
						)}
						{sorted}
					</Box>
				</HStack>
			</HStack>
			<Divider my={1} />
		</Box>
	);
};
