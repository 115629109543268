import React from 'react';
import { Grid } from '@mui/material';

import styles from './styles.module.css';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import Settings from '../../components/User/Settings';
const MySettings = () => {
	return (
		<Grid className={styles.bgBlack}>
			<Sidebar activeText='Settings' childComponent={<Settings />} />
		</Grid>
	);
};

export default MySettings;
