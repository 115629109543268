/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useState } from 'react';
import {
  Box,
  Button,
  CheckIcon,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Text,
} from 'native-base';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function CircularProgressWithLabel(props) {
  const { value } = props;
  return (
    <HStack position='relative'>
      <CircularProgress
        variant='determinate'
        {...props}
        sx={{ color: value > 50 ? '#2ebb55' : '#f00' }}
      />
      <Heading
        position={'absolute'}
        alignSelf={'center'}
        right={0}
        left={{ lg: value == 100 ? 1 : 2, base: value == 100 ? 2 : 3 }}
        color={value > 50 ? '#2ebb55' : '#f00'}
        fontSize={{ xl: '12px', md: '12px', base: '10px' }}
      >
        {`${Math.round(value)}%`}
      </Heading>
      {/* <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          color={value > 50 ? '#2ebb55' : '#f00'}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box> */}
    </HStack>
  );
}

export default function StaticProgress(props) {
  const [progress, setProgress] = useState(props.progress);

  return <CircularProgressWithLabel value={progress} />;
}
