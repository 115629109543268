import React, { useState } from 'react';
import { Box, HStack } from 'native-base';
import { Loader } from '../../../../components/Loader';
import { StatsWithMap } from './StatsWithMap';
import axios from 'axios';
import moment from 'moment';
import PlaysChart from './PlaysChart';
import AirplayTable from './AirplayTable';
import {configOptions} from '../../../../RestApis/AxiosInstance.js/SoundChartAPIConfig'
import styles from './styles.module.css';

export const getStation_country_data = (radioWithDuration) => {
	let countryWithPlays = [];
	let playsWithDate = [];
	let radioWithPlays = [];
	for (var i = 0; i < radioWithDuration.length; i++) {
		//get all countries with their plays
		if (countryWithPlays.length === 0) {
			countryWithPlays.push(radioWithDuration[i]);
		} else {
			// eslint-disable-next-line no-loop-func
			let checkCountry = countryWithPlays.findIndex(
				(play) => play.country_name === radioWithDuration[i].country_name
			);
			if (checkCountry < 0) {
				countryWithPlays.push(radioWithDuration[i]);
			} else {
				countryWithPlays[checkCountry].plays +=
					radioWithDuration[i].plays;
			}
		}

		//get plays with their dates

		if (playsWithDate.length === 0) {
			playsWithDate.push(radioWithDuration[i]);
		} else {
			let checkDate = playsWithDate.findIndex(
				(play) => play.airedAt === radioWithDuration[i].airedAt
			);
			if (checkDate < 0) {
				playsWithDate.push(radioWithDuration[i]);
			} else {
				playsWithDate[checkDate].plays += radioWithDuration[i].plays;
			}
		}

		if (radioWithPlays.length === 0) {
			radioWithPlays.push(radioWithDuration[i]);
		} else {
			let checkRadio = radioWithPlays.findIndex(
				(play) => play.radio_name === radioWithDuration[i].radio_name
			);
			if (checkRadio < 0) {
				radioWithPlays.push(radioWithDuration[i]);
			} else {
				radioWithPlays[checkRadio].plays += radioWithDuration[i].plays;
			}
		}
	}
	return {
		countryWithPlays,playsWithDate,radioWithPlays,
	}
}
function AirPlayComp() {
	const [isLoading, setIsLoading] = useState(false);
	const [countryPlays, setCountryPlays] = React.useState([]);
	const [chartData, setChartData] = React.useState([]);
	const [radioPlays, setRadioPlays] = React.useState([]);
	const [playChartLoading, setPlayChartLoading] = React.useState(false)
	const [tableLoading, setTableLoading] = React.useState(false)
	const [totalCount, setTotalCount] = React.useState({
		countries: 0,
		plays: 0,
	});
	const [sorted,setSorted]=useState(false)
    const handleSortIcon=()=>{
      setSorted(!sorted)
      console.log("spotify sorted")
      setCountryPlays(countryPlays.sort((a, b) => (a.playCount) - (b.playCount)))
    
    }
	React.useEffect(() => {
		getPlaysFromCountry();
	}, [sorted]);

	
	const getPlaysFromCountry = async () => {
		try {
			const date = new Date();
			const endDate = moment(date).format('YYYY-MM-DD');
			const startDate = moment(
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - 28)
			).format('YYYY-MM-DD');
			setIsLoading(true)
			const response = await getArtistRecord();
			if (response.status === 200) {
				let { items } = response.data;
				let radioWithDuration = items.map((item) => {
					return {
						country_name: item.radio.countryName,
						plays: item.duration,
						airedAt: moment(item.airedAt).format('YYYY-MM-DD'),
						radio_name: item.radio.name,
					};
				});

				const {countryWithPlays, playsWithDate, radioWithPlays} = getStation_country_data(radioWithDuration)
				setCountryPlays(countryWithPlays.sort((a, b) => b.plays - a.plays));
				setChartData(playsWithDate.sort((a, b) => b.airedAt - a.airedAt));
				setRadioPlays(radioWithPlays.sort((a, b) => b.plays - a.plays));
				setTotalCount({
					countries: countryWithPlays.length,
					plays: countryWithPlays.reduce((acc, cwp) => acc + cwp.plays, 0),
				});
				console.log('plays : ', playsWithDate);
				setIsLoading(false);
			}
		} catch (err) {
			setIsLoading(false);
			console.log('error : ', err);
		}
	};

	const getArtistRecord = () => {
		return new Promise((resolve, reject) => {
			const config = configOptions(
				'get',
				`https://sandbox.api.soundcharts.com/api/v2/artist/11e81bcc-9c1c-ce38-b96b-a0369fe50396/broadcasts?offset=0&limit=100`
			);
			axios(config).then((res) => resolve(res)).catch((err) => reject(err));
		})
	}

	const getChartData = async() => {
		try{
			setPlayChartLoading(true);
			const response = await getArtistRecord();
			if (response.status === 200) {
				let { items } = response.data;
				let radioWithDuration = items.map((item) => {
					return {
						country_name: item.radio.countryName,
						plays: item.duration,
						airedAt: moment(item.airedAt).format('YYYY-MM-DD'),
						radio_name: item.radio.name,
					};
				});

				const {radioWithPlays} = getStation_country_data(radioWithDuration)
				setRadioPlays(radioWithPlays.sort((a, b) => b.plays - a.plays));
				setPlayChartLoading(false)
			}
		}catch(err){
			setPlayChartLoading(false)
		}
	}

	const getRadioStations = async() => {
		try{
			setTableLoading(true);
			const response = await getArtistRecord();
			if (response.status === 200) {
				let { items } = response.data;
				let radioWithDuration = items.map((item) => {
					return {
						country_name: item.radio.countryName,
						plays: item.duration,
						airedAt: moment(item.airedAt).format('YYYY-MM-DD'),
						radio_name: item.radio.name,
					};
				});

				const {playsWithDate} = getStation_country_data(radioWithDuration)
				setChartData(playsWithDate.sort((a, b) => b.airedAt - a.airedAt));
				setTableLoading(false)
			}
		}catch(err){
			setTableLoading(false)
		}
	}
	return (
		<>
			{isLoading ? (
				<div className={styles.loaderDiv}>
					<Loader />
				</div>
			) : (
				<>
					<Box my={5} py={5} bg={'#1C1B1B'} w={'100%'} rounded={'lg'}>
						<StatsWithMap data={countryPlays} count={totalCount} />
					</Box>
					<Box my={5} py={5} bg={'#1C1B1B'} w={'100%'} rounded={'lg'}>
						<PlaysChart data={chartData} filterHandler={getChartData} loading={playChartLoading}  />
					</Box>
					<Box my={5} py={5} bg={'#1C1B1B'} w={'100%'} rounded={'lg'}>
						<AirplayTable radioData={radioPlays} filterHandler={getRadioStations} loading={tableLoading} />
					</Box>
				</>
			)}
		</>
	);
}

export default AirPlayComp;
