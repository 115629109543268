import { Box } from 'native-base'
import React from 'react'
import TalentPlatformGraph from '../../TalentPlatformGraph/TalentPlatformGraph'

function Airplay() {
  return (
  <Box>
<TalentPlatformGraph/>
  </Box>
  )
}

export default Airplay