import { Box, Button, Center, HStack, Image } from 'native-base'
import React from 'react'

function SocialIconTray(props) {
  return (
    <Box>
      <HStack mb={5}  >
        <Button variant="ghost" _hover={{
          bg: '#2EBA55',
          _text: {
            color: '#fff'
          }
        }} size="small">

          <Image href={'https://www.Instagram.com'} m="2" size={{sm:"30px",base:"20px"}} source={require("../../../assets/Profile/insta.png")}
            alt="Alternate Text"
      
            _hover={{
              bg: '#2EBA55',
            }}
         
          />
        </Button>


        <Button variant="ghost" _hover={{
          bg: '#2EBA55',
          _text: { color: '#fff' }
        }} size="small">

          <Image href={'https://www.twitter.com'} m="2" source={require("../../../assets/Profile/spotify.png")}
            alt="Alternate Text"
            _hover={{
              bg: '#2EBA55',
            }}
            size={{sm:"30px",base:"20px"}} />
        </Button>
   

        <Button variant="ghost" _hover={{
          bg: '#2EBA55',
          _text: { color: '#fff' }
        }} size="small">
          <Image href={'https://www.discord.com'} m="2" source={require("../../../assets/Profile/social.png")}
            alt="Alternate Text" size={{sm:"30px",base:"20px"}} />
        </Button>

        <Button variant="ghost"
          _hover={{
            bg: '#2EBA55',
            _text: { color: '#fff' }
          }} size="small">
          <Image href={'https://www.youtube.com'} m="2" source={require("../../../assets/Profile/twitch.png")}
            alt="Alternate Text" _hover={{
              bg: '#2EBA55',
            }} size={{sm:"30px",base:"20px"}} />
        </Button>
        <Button variant="ghost"
          _hover={{
            bg: '#2EBA55',
            _text: { color: '#fff' }
          }} size="small">
          <Image href={'https://www.youtube.com'} m="2" source={require("../../../assets/Profile/youtube.png")}
            alt="Alternate Text" _hover={{
              bg: '#2EBA55',
            }} size={{sm:"30px",base:"20px"}} />
        </Button>
      </HStack>
    </Box>
  )
}

export default SocialIconTray