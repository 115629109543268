import {

  Box,
  HStack,
  Image,
  Input,
  Menu,
  Pressable,
  Text,
} from "native-base";
import React, { useState } from "react";


function DropDown(props) {
const {showIcon,text}=props
  const [isOpen, setIsopen] = useState(false);
  
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  return (
    <>
      <HStack   sx={{ m: [0,0,1], minWidth: [30, 120, 240]}} justifyContent={{lg:"space-between",base:"center"}}>
        <Menu

          backgroundColor={"#0000"}
          onClick={ToggleSidebar}
          trigger={(triggerProps) => {
            return (
          
              <Pressable
            
                accessibilityLabel="More options menu"
                {...triggerProps}
              >
                <HStack
           px={3}
           py={2}
            
  
             justifyContent={{lg:"space-between",base:"center"}}
                  alignItems={"center"}
                  backgroundColor={"#000"}
               borderWidth={1}
               borderColor={"#AFAFAF"}
               borderRadius="0.5rem"
            
                  variant="outline"
                  // mx={{
                  //   base: "auto",
                  //   md: "0",
                  // }}
                >
          {showIcon &&  <Image
                    variant="ghost"
                    size={"7"}
                    m={"5px"}
                    source={require("../../assets/Icons/spotify.png")}
                    alt="Demo"
                  />}       
                  <Text
             
                    marginTop={"8px"}
                    color={"#AFAFAF"}
                    m={"5px"}
                    width={{md:"130px",base:"150px"}}
                    fontSize={"1rem"}
                    textTransform={"capitalize"}
                    // fontWeight={"semibold"}
                    fontFamily={"poppins"}
                  >
                    {text}
                  </Text>

                  <Image
                    variant="ghost"
                    size={{md:"5",base:"5"}}
                  
                    source={require("../../assets/Icons/downArrow.png")}
                    alt="Demo"
                  />
                </HStack>
              </Pressable>
            );
          }}
        >
       {/* { isOpen === false ? { */}
          {/* <Menu.Item bg={"grey"}>
   
            <Input
              w={"100%"}
              pl={1}
              color={"#000"}
              fontSize={{
                md: "xl",
                base: "14px",
              }}
              borderWidth={0}
              borderRadius={10}
              bg={"1C1B1B"}
              _focus={{
                bg: "tranparent",
                borderWidth: 0,
              }}
              placeholder={"Search"}
              InputLeftElement={
                <Image
                  source={require("../../assets/Icons/searchIcon.png")}
                  alt="Alternate Text"
                  color="#ffff"
                  size="20px"
                  m="3"
                  alignItems="right"
                /> }
                />
          </Menu.Item> */}
                 
          {/* <Menu.Item fontFamily={"poppins"} backgroundColor={"#D9D9D9"} fontSize={"1.2em"}>
            Nunito Sans
          </Menu.Item>
          <Menu.Item fontFamily={"poppins"} backgroundColor={"#D9D9D9"}>
            Roboto
          </Menu.Item>
          <Menu.Item fontFamily={"poppins"} backgroundColor={"#D9D9D9"}>
            Poppins
          </Menu.Item>
          <Menu.Item fontFamily={"poppins"} backgroundColor={"#D9D9D9"}>
            SF Pro
          </Menu.Item>
          <Menu.Item fontFamily={"poppins"} backgroundColor={"#D9D9D9"}>
            Helvetica
          </Menu.Item>
          <Menu.Item isDisabled>Sofia</Menu.Item>
          <Menu.Item backgroundColor={"#D9D9D9"} fontFamily={"poppins"}>
            Cookie
          </Menu.Item> */}
   
          
        </Menu>
      </HStack>
      ;
    </>
  );
}

export default DropDown;
