import React, { useEffect, useRef, useState } from 'react';
import { Button, Heading, HStack } from 'native-base';
import { AiFillPauseCircle, AiFillPlayCircle } from 'react-icons/ai';

import WaveSurfer from 'wavesurfer.js';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#AFAFAF',
  progressColor: '#2EBA55',
  cursorColor: '#000',
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 50,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});

export default function WavesurferPlayer({ url, percentage }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [isLoaded, setIsLoaded] = useState(false);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on('ready', function () {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
        setIsLoaded(true);
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const onVolumeChange = (e) => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: '8%' }}>
        <Button
          onPress={() => {
            handlePlayPause();
          }}
          bg={'transparent'}
          _hover={{ bg: 'transparent' }}
          _focused={{ bg: 'transparent' }}
          _pressed={{ bg: 'transparent' }}
        >
          {!playing ? (
            <AiFillPlayCircle
              size={30}
              style={{
                cursor: 'pointer',
                alignSelf: 'center',
                color: '#2EBA55',
              }}
            />
          ) : (
            <AiFillPauseCircle
              size={30}
              style={{
                cursor: 'pointer',
                alignSelf: 'center',
                color: '#2EBA55',
              }}
            />
          )}
        </Button>
      </div>
      <div style={{ width: '87%' }}>
        <div ref={waveformRef} />
      </div>
      <div style={{ width: '5%', alignSelf: 'center' }}>
        <Heading
          alignSelf={'center'}
          fontSize={{ lg: 'lg', md: 'md', sm: 'sm' }}
          color={'#2EBB55'}
          fontWeight={'normal'}
          fontFamily={'Poppins'}
          textAlign={'center'}
        >
          {percentage}
        </Heading>
      </div>
    </div>
  );
}
