/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Menu,
	Text,
} from 'native-base';
import React, { useState } from 'react';
import { DashboardHeader } from '../Headers';
import SideBar from '../SideBar/SideBar';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import { ImportModal } from './Modals/ImportModal';
import { SentCollabModal } from './Modals/SentCollabModal';
import { DeletePopup } from '../PopUp/DeletePopup';
import { TagPopup } from '../PopUp/TagsPopup';
import { BpmPopup } from '../PopUp/BpmPopup';
import { TypePopup } from '../PopUp/TypePopup';
import { SentPopup } from '../PopUp/SentPopup';
import { WelcomePopup } from '../PopUp/WelcomePopup';
import { SendPackPopup } from '../PopUp/SendPackPopup';
import { ResetPasswordPopup } from '../PopUp/ResetPasswordPopup';

function BookCollab() {
	const [collaborater, setCollaborater] = useState('');
	const [service, setService] = useState('');
	const [fileNo, setFileNo] = useState();
	const [servicePrice, setServicePrice] = useState('');
	const [showImportModal, setShowImportModal] = useState(false);
	return (
		<>
			<Box w={'100%'}>
				<Box w={'90%'} alignSelf={'center'} py={5}>
					<Heading
						my={5}
						fontSize={{ xl: '4xl', md: '3xl', base: '2xl' }}
						color={'#fff'}
						textTransform={'capitalize'}
						fontFamily={'poppins'}
					>
						Book A Collab
					</Heading>
					<HStack
						w={'100%'}
						mt={5}
						flexGrow={1}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
						// borderWidth={1}
						// borderColor={'#fff'}
					>
						<Box w={{ xl: '48%', base: '100%' }} my={5}>
							<ElementHeader title={'Collaborating with'} />
							<Input
								py={'10px'}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '1',
									_focus: { borderColor: '#afafaf', shadow: 0 },
								}}
								focusOutlineColor={'#afafaf'}
								fontSize={{ lg: '20px', sm: '16px' }}
								borderWidth={1}
								borderColor={'#AFAFAF'}
								color={'#555555'}
							/>
						</Box>
						<Box w={{ xl: '48%', base: '100%' }} my={5}>
							<ElementHeader title={'Service'} />
							<Input
								py={'10px'}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '1',
									_focus: { borderColor: '#afafaf', shadow: 0 },
								}}
								focusOutlineColor={'#afafaf'}
								fontSize={{ lg: '20px', sm: '16px' }}
								borderWidth={1}
								borderColor={'#AFAFAF'}
								color={'#555555'}
							/>
						</Box>
					</HStack>
					<HStack
						w={'100%'}
						flexGrow={1}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
					>
						<Box w={{ xl: '48%', base: '100%' }} my={5}>
							<ElementHeader title={'No. of Files Attached'} />

							<Input
								py={'10px'}
								keyboardType={'number-pad'}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '1',
									_focus: { borderColor: '#afafaf', shadow: 0 },
								}}
								value={fileNo}
								focusOutlineColor={'#afafaf'}
								fontSize={{ lg: '20px', sm: '16px' }}
								borderWidth={1}
								borderColor={'#AFAFAF'}
								color={'#555555'}
								InputRightElement={
									<HStack
										bg={'#D9D9D9'}
										px={2}
										py={{ lg: '14px', base: '10px' }}
									>
										<Button
											variant={'ghost'}
											p={0}
											_focus={{ bg: 'transparent' }}
											_hover={{ bg: 'transparent' }}
											_pressed={{ bg: 'transparent' }}
										>
											<ArrowBackIosNewRoundedIcon
												sx={{ color: '#000', fontSize: 20 }}
											/>
										</Button>
										<Heading
											fontSize={{ xl: 'lg', base: 'sm' }}
											color={'#000'}
											fontFamily={'Poppins'}
											fontWeight={'medium'}
											alignSelf={'center'}
											mx={2}
										>
											25
										</Heading>
										<Button
											variant={'ghost'}
											p={0}
											_focus={{ bg: 'transparent' }}
											_hover={{ bg: 'transparent' }}
											_pressed={{ bg: 'transparent' }}
										>
											<ArrowForwardIosRoundedIcon
												sx={{ color: '#000', fontSize: 20 }}
											/>
										</Button>
									</HStack>
								}
							/>
						</Box>
						<Box w={{ xl: '48%', base: '100%' }} my={5}>
							<ElementHeader title={'Price of Service'} />
							<Input
								py={'10px'}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '1',
									_focus: { borderColor: '#afafaf', shadow: 0 },
								}}
								focusOutlineColor={'#afafaf'}
								fontSize={{ lg: '20px', sm: '16px' }}
								borderWidth={1}
								borderColor={'#AFAFAF'}
								color={'#555555'}
							/>
						</Box>
					</HStack>
					<Box w={{ xl: '48%', base: '100%' }} my={5}>
						<ElementHeader title={'Date of Final Composition Delivery'} />
						<Input
							py={'10px'}
							_important={{
								backgroundColor: 'transparent',
								borderColor: '#afafaf',
								borderWidth: '1',
								_focus: { borderColor: '#afafaf', shadow: 0 },
							}}
							focusOutlineColor={'#afafaf'}
							fontSize={{ lg: '20px', sm: '16px' }}
							borderWidth={1}
							borderColor={'#AFAFAF'}
							color={'#555555'}
							InputRightElement={
								<Button
									mx={4}
									alignSelf={'center'}
									variant={'ghost'}
									p={0}
									_focus={{ bg: 'transparent' }}
									_hover={{ bg: 'transparent' }}
									_pressed={{ bg: 'transparent' }}
								>
									<CalendarMonthRoundedIcon
										sx={{ color: '#DADADA', fontSize: 25 }}
									/>
								</Button>
							}
						/>
					</Box>
					<Box w={'100%'} my={5}>
						<ElementHeader
							title={'Are there any Co-producer for this Audio File?'}
						/>
						<HStack>
							<Checkbox
								value='one'
								p={1}
								mr={5}
								my={1}
								bg={'transparent'}
								colorScheme={'green'}
								_text={{
									color: '#AFAFAF',
									color: '#fff',
									fontSize: {
										lg: '20px',
										md: '18px',
										base: '16px',
									},
									fontWeight: 'normal',
									fontFamily: 'Poppins',
								}}
							>
								Yes
							</Checkbox>
							<Checkbox
								value='one'
								p={1}
								ml={5}
								my={1}
								bg={'transparent'}
								colorScheme={'green'}
								_text={{
									color: '#fff',
									fontSize: {
										lg: '20px',
										md: '18px',
										base: '16px',
									},
									fontWeight: 'normal',
									fontFamily: 'Poppins',
								}}
							>
								No
							</Checkbox>
						</HStack>
					</Box>
					<Box w={'100%'} my={5}>
						<ElementHeader title={'How Many?'} />
						<Input
							py={'10px'}
							_important={{
								backgroundColor: 'transparent',
								borderColor: '#afafaf',
								borderWidth: '1',
								_focus: { borderColor: '#afafaf', shadow: 0 },
							}}
							focusOutlineColor={'#afafaf'}
							fontSize={{ lg: '20px', sm: '16px' }}
							borderWidth={1}
							borderColor={'#AFAFAF'}
							color={'#555555'}
						/>
					</Box>
					<Box w={'100%'} my={5}>
						<ElementHeader title={'Email'} />
						<Input
							py={'10px'}
							_important={{
								backgroundColor: 'transparent',
								borderColor: '#afafaf',
								borderWidth: '1',
								_focus: { borderColor: '#afafaf', shadow: 0 },
							}}
							focusOutlineColor={'#afafaf'}
							fontSize={{ lg: '20px', sm: '16px' }}
							borderWidth={1}
							borderColor={'#AFAFAF'}
							color={'#555555'}
						/>
					</Box>
					<HStack
						w={'100%'}
						flexGrow={1}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
					>
						<Box w={{ xl: '48%', base: '100%' }} my={5}>
							<ElementHeader title={'Preferred Publisihing%'} optional={true} />
							<Input
								py={'10px'}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '1',
									_focus: { borderColor: '#afafaf', shadow: 0 },
								}}
								focusOutlineColor={'#afafaf'}
								fontSize={{ lg: '20px', sm: '16px' }}
								borderWidth={1}
								borderColor={'#AFAFAF'}
								color={'#555555'}
								leftElement={
									<Heading
										fontSize={{ lg: '20px', sm: '16px' }}
										color={'#fff'}
										fontWeight={'medium'}
										px={2}
									>
										%
									</Heading>
								}
							/>
						</Box>
						<Box w={{ xl: '48%', base: '100%' }} my={5}>
							<ElementHeader title={'Preferred Royalty%'} optional={true} />
							<Input
								py={'10px'}
								_important={{
									backgroundColor: 'transparent',
									borderColor: '#afafaf',
									borderWidth: '1',
									_focus: { borderColor: '#afafaf', shadow: 0 },
								}}
								focusOutlineColor={'#afafaf'}
								fontSize={{ lg: '20px', sm: '16px' }}
								borderWidth={1}
								borderColor={'#AFAFAF'}
								color={'#555555'}
								leftElement={
									<Heading
										fontSize={{ lg: '20px', sm: '16px' }}
										color={'#fff'}
										fontWeight={'medium'}
										px={2}
									>
										%
									</Heading>
								}
							/>
						</Box>
					</HStack>
					<Box w={{ xl: '48%', base: '100%' }} my={5}>
						<ElementHeader
							title={'Preferred Advance Payment'}
							optional={true}
						/>
						<Input
							py={'10px'}
							_important={{
								backgroundColor: 'transparent',
								borderColor: '#afafaf',
								borderWidth: '1',
								_focus: { borderColor: '#afafaf', shadow: 0 },
							}}
							focusOutlineColor={'#afafaf'}
							fontSize={{ lg: '20px', sm: '16px' }}
							borderWidth={1}
							borderColor={'#AFAFAF'}
							color={'#555555'}
							leftElement={
								<Heading
									fontSize={{ lg: '20px', sm: '16px' }}
									color={'#2EBB55'}
									bg={'#092712'}
									px={8}
									py={'11px'}
									fontWeight={'medium'}
								>
									$
								</Heading>
							}
						/>
					</Box>
					<HStack
						w={'100%'}
						flexGrow={1}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
					>
						<Box w={{ xl: '45%', base: '100%' }} my={5}>
							<ElementHeader title={'Drag & Drop Audio File'} />
							<Button
								size={'sm'}
								py={'10px'}
								bg='transparent'
								borderStyle={'dashed'}
								borderWidth={'1'}
								borderColor={'#2EBA55'}
								w={'100%'}
								rounded={'md'}
								alignSelf={'center'}
								_text={{
									color: '#2EBA55',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
									fontFamily: 'Poppins',
								}}
								_hover={{
									bg: '#2EBA55',
									_text: {
										color: '#fff',
									},
								}}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: 'transparent' }}
								onPress={() => {}}
								leftIcon={
									<MusicNoteRoundedIcon
										sx={{ color: '#2ebb55', fontSize: '25' }}
									/>
								}
							>
								Upload Audio File
							</Button>
						</Box>
						<Box w={{ xl: '45%', base: '100%' }} my={5}>
							<ElementHeader title={'Drag & Drop Audio File'} />
							<Button
								size={'sm'}
								py={'10px'}
								bg='#2EBA55'
								borderWidth={1}
								borderColor='#2EBA55'
								_text={{
									color: '#fff',
									fontSize: {
										lg: '18px',
										md: '16px',
										sm: '14px',
										base: '14px',
									},
									fontFamily: 'Poppins',
								}}
								_hover={{
									bg: 'transparent',
									_text: {
										color: '#2EBA55',
									},
								}}
								onPress={() => {
									setShowImportModal(true);
								}}
								w={'100%'}
								rounded={'md'}
								alignSelf={'center'}
								_focused={{ bg: '#2EBA55' }}
								_pressed={{ bg: '#2EBA55' }}
							>
								Import from Audio Library
							</Button>
						</Box>
					</HStack>
					<Box w={'100%'} my={5}>
						<ElementHeader
							title={'Additional Notes About Audio Compositions'}
						/>
						<Input
							py={'10px'}
							multiline={true}
							numberOfLines={6}
							_important={{
								backgroundColor: 'transparent',
								borderColor: '#afafaf',
								borderWidth: '1',
								_focus: { borderColor: '#afafaf', shadow: 0 },
							}}
							focusOutlineColor={'#afafaf'}
							fontSize={{ lg: '20px', sm: '16px' }}
							borderWidth={1}
							borderColor={'#AFAFAF'}
							color={'#555555'}
						/>
					</Box>
					<Box w={'100%'} my={5}>
						<ElementHeader
							title={'Preferred Advance Payment'}
							optional={true}
						/>
						<Input
							py={'10px'}
							_important={{
								backgroundColor: 'transparent',
								borderStyle: 'dashed',
								borderColor: '#2EBB55',
								borderWidth: '1',
								_focus: { borderColor: '#2EBB55', shadow: 0 },
							}}
							focusOutlineColor={'#2EBB55'}
							fontSize={{ lg: '20px', sm: '16px' }}
							borderWidth={1}
							borderColor={'#2EBB55'}
							color={'#555555'}
							leftElement={
								<Box bg={'#092712'} px={8} py={'11px'}>
									<AttachFileRoundedIcon
										sx={{ color: '#2ebb55', fontSize: 25, rotate: '30deg' }}
									/>
								</Box>
							}
						/>
					</Box>
					<Heading
						fontSize={{ xl: 'md', base: 'xs' }}
						color={'#afafaf'}
						fontFamily={'Poppins'}
						fontWeight={'medium'}
						mb={5}
					>
						Any document in regards to Legal Terms & Use of audio files
						attached.
					</Heading>
					<Checkbox
						value='one'
						p={1}
						my={5}
						bg={'transparent'}
						colorScheme={'green'}
						_text={{
							color: '#AFAFAF',

							fontSize: {
								lg: '18px',
								md: '18px',
								base: '16px',
							},
							fontWeight: 'medium',
							fontFamily: 'Poppins',
						}}
					>
						Attach audio file composition general protection agreement
					</Checkbox>
					<Button
						size={'sm'}
						bg='#2EBA55'
						borderWidth={1}
						borderColor='#2EBA55'
						_text={{
							color: '#fff',
							fontSize: {
								lg: '24px',
								md: '20px',
								sm: '18px',
								base: '18px',
							},
							fontFamily: 'Poppins',
						}}
						_hover={{
							bg: 'transparent',
							_text: {
								color: '#2EBA55',
							},
						}}
						onPress={() => {}}
						w={{ lg: '20%', base: '30%' }}
						mt={5}
						mb={10}
						rounded={'md'}
						alignSelf={'flex-end'}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: '#2EBA55' }}
					>
						Send
					</Button>
				</Box>
			</Box>

			<ImportModal
				openModal={showImportModal}
				closeModal={() => setShowImportModal(false)}
			/>
		</>
	);

	function ElementHeader(props) {
		const { title, optional } = props;
		return (
			<Box w={'100%'}>
				<HStack justifyContent={'space-between'}>
					<Heading
						fontSize={{ xl: '2xl', base: 'lg' }}
						color={'#afafaf'}
						fontFamily={'Poppins'}
						fontWeight={'medium'}
						mb={5}
					>
						{title}
					</Heading>
					{optional && (
						<Heading
							fontSize={{ xl: 'md', base: 'xs' }}
							color={'#afafaf'}
							fontFamily={'Poppins'}
							fontWeight={'medium'}
							mb={5}
							alignSelf={'center'}
						>
							Optional
						</Heading>
					)}
				</HStack>
			</Box>
		);
	}
}
export default BookCollab;
