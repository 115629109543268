import { Box,Divider,HStack,Image } from 'native-base'
import React from 'react'
import { DashboardHeader } from '../../Headers'
import { ProfileView } from '../ProfileView/ProfileView'
import { Forsale } from '../ForSale/Forsale'
import { Teammates } from '../Teammates/Tesmmates'
import Subscribe from '../Subscribe'
import { ProducerServices } from '../ProducerServices/ProducerServices'
import { Testimonies } from '../Testimonies/Testomonies'
import ProfileTitle from '../ProfileTitle'
const  ViewProfile=()=> {
  return (

  
        //   w={{ xl: "80%", base: "100%" }}
        //   borderLeftWidth={1}
        //   borderColor={"#fff"}
      
  

  
          <Box py={8} px={7} w={'100%'}>
            {/* <ProfileHeader /> */}

          <Box >
        <ProfileTitle text={"music producer"}/>
            <Image
             
              mt={5}
              h={{ lg: '350px', md: '300px', base: '200px' }}
              // h={"400px"}
              w={"100%"}
              resizeMode="cover"
              source={require("../../../assets/Profile/cover.png")}
              alt="image"
            />
            <HStack flexDirection={{lg:"row",base:"column"}} pl={{lg:4,md:0}} flexGrow={1} flexWrap={"wrap"} >
              <ProfileView />
              <Forsale />
            </HStack>
          </Box>
          <ProducerServices />
          <Subscribe/>
          <Teammates />
          <Testimonies /></Box>
  )
}

export default ViewProfile