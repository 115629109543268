import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Modal,
  Input,
} from "native-base";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const AddSevicersModal = (props) => {
  const { modalFlag, close } = props;
  const [servicesModal, setServicesModal] = useState(false);
  const [value, setValue] = useState("");

  // useEffect(() => {
  // console.log("MOdalesdswsd",modalFlag,"sdasdaw",close);
  // }, [])

  return (
    <Box>
      <Modal
        isOpen={modalFlag}
        bg={"#00000070 "}
        size={"full"}
        w={{ xl: "35%", lg: "45%", base: "90%" }}
        m={"auto"}
        h={"50%"}
        onClose={close}
      >
        <Box w={"100%"} bg={"#141313"} rounded={"lg"}>
          <HStack w={"95%"} alignSelf={"center"} my={3}>
            <HStack w={"90%"}>
              <Box alignSelf={"center"}>
                <BusinessCenterIcon sx={{ color: "#AFAFAF" }} />
              </Box>
              <Heading
                px={3}
                color={"#E3E3E3"}
                fontWeight={"normal"}
                fontSize={{ lg: "2xl", base: "lg" }}
              >
                Add Services
              </Heading>
            </HStack>
            <Button
              justifyContent={"flex-end"}
              w={"10%"}
              _hover={{bg: 'transparent'}}
              variant={"ghost"}
              p={0}
              onPress={close}
            >
              <CloseIcon sx={{ color: "#AFAFAF" }} />
            </Button>
          </HStack>
          <Divider />
          <Box w={"95%"} alignSelf={"center"} my={3} mb={5}>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                Basic Feedback
              </Heading>
              <Button
                bg={"#2EBB55"}
                w={"20%"}
                _hover={{ bg: "#2EBB5570" }}
                _pressed={{ bg: "#2EBB55" }}
                onPress={() => {
                  setServicesModal(true);
                  setValue("Basic Feedback");
                  close();
                }}
              >
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                High Level Feedback
              </Heading>
              <Button
                bg={"#2EBB55"}
                w={"20%"}
                _hover={{ bg: "#2EBB5570" }}
                
                _pressed={{ bg: "#2EBB55" }}
                onPress={() => {
                  setServicesModal(true);
                  setValue("High Level Feedback");
                  close();
                }}
              >
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                Beat Submissions
              </Heading>
              <Button
                bg={"#2EBB55"}
                w={"20%"}
                _hover={{ bg: "#2EBB5570" }}
                _pressed={{ bg: "#2EBB55" }}
                onPress={() => {
                  setServicesModal(true);
                  setValue(" Beat Submissions");
                  close();
                }}
              >
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
            <HStack mt={5} my={2}>
              <Heading
                py={2}
                w={"70%"}
                color={"#AFAFAF"}
                fontSize={"2xl"}
                fontWeight={"medium"}
                textAlign={"left"}
              >
                Paid Collabs
              </Heading>
              <Button
                bg={"#2EBB55"}
                w={"20%"}
                _hover={{ bg: "#2EBB5570" }}
                _pressed={{ bg: "#2EBB55" }}
                onPress={() => {
                  setServicesModal(true);
                  setValue(" Beat Submissions");
                  close();
                }}
              >
                + ADD
              </Button>
              <Box w={"10%"} px={3} alignSelf={"center"}>
                <InfoOutlinedIcon sx={{ color: "#AFAFAF" }} />
              </Box>
            </HStack>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={servicesModal}
        bg={"#00000070 "}
        size={"full"}
        w={{ xl: "35%", lg: "40%", base: "90%" }}
        m={"auto"}
        h={"50%"}
        onClose={() => setServicesModal(!servicesModal)}
      >
        <Box w={"100%"} bg={"#141313"} rounded={"lg"}>
          <HStack w={"95%"} alignSelf={"center"} my={3}>
            <HStack w={"90%"}>
              <Box alignSelf={"center"}>
                <BusinessCenterIcon sx={{ color: "#AFAFAF" }} />
              </Box>
              <Heading
                px={3}
                color={"#E3E3E3"}
                fontWeight={"normal"}
                fontSize={{ lg: "2xl", base: "lg" }}
              >
                Add Services
              </Heading>
            </HStack>
            <Button
              w={"10%"}
              _hover={{bg: 'transparent'}}
              justifyContent={"flex-end"}
              variant={"ghost"}
              p={0}
              onPress={() => setServicesModal(!servicesModal)}
            >
              <CloseIcon sx={{ color: "#AFAFAF" }} />
            </Button>
          </HStack>
          <Divider />
          <Box w={"95%"} alignSelf={"center"}>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Name
              </Heading>
              <HStack
                w={"100%"}
                rounded={"md"}
                borderWidth={1}
                borderColor={"#D2D4D1"}
                justifyContent={"space-between"}
              >
                <Button
                  variant={"ghost"}
                  p={0}
                  px={0.5}
                  w={"90%"}
                  //  onPress={() => setDisplayServices(false)}
                  _pressed={{ bg: "#000" }}
                  _hover={{ bg: "transparent" }}
                  justifyContent={"flex-start"}
                >
                  <Heading
                    p={3}
                    color={"#AFAFAF"}
                    fontSize={"xl"}
                    fontWeight={"normal"}
                    textAlign={"left"}
                  >
                    Basic Feedback
                  </Heading>
                </Button>
                <Box alignSelf={"center"}>
                  <ExpandMoreIcon sx={{ color: "#AFAFAF" }} />
                </Box>
              </HStack>
            </Box>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Description
              </Heading>
              <Input
                placeholder={"Enter Description"}
                multiline={true}
                numberOfLines={5}
                width={"100%"}
                py={2.5}
                textAlignVertical={"top"}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
              />
            </Box>
            <Box mt={5}>
              <Heading
                py={2}
                color={"#AFAFAF"}
                fontSize={"xl"}
                fontWeight={"normal"}
                textAlign={"left"}
              >
                Price
              </Heading>
              <Input
                placeholder={"Enter your Price"}
                width={"100%"}
                py={2.5}
                textAlignVertical={"top"}
                rounded={"md"}
                my={2}
                bg={"#141313"}
                color={"#fff"}
                fontSize={"md"}
                placeholderTextColor={"#AFAEAE70"}
                size={"lg"}
              />
            </Box>
            <HStack
              my={5}
              alignSelf={"center"}
              w={"60%"}
              justifyContent={"space-between"}
            >
              <Button
                w={"40%"}
                variant={"ghost"}
                borderWidth={1}
                borderColor={"#AFAFAF"}
                _pressed={{ bg: "transparent", borderColor: "#AFAFAF" }}
              >
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  Cancel
                </Heading>
              </Button>
              <Button w={"40%"} bg={"#2EBB55"}>
                <Heading
                  color={"#AFAFAF"}
                  fontSize={"xl"}
                  fontWeight={"normal"}
                  textAlign={"center"}
                >
                  OK
                </Heading>
              </Button>
            </HStack>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
