import { Box, HStack, Text, Input, Button, Container } from 'native-base';
import React from 'react';
import { LogBox } from 'react-native-web';
import CustomForm from './CustomForm';

const GetNotified = () => {
	return (
		<Box mt={10} paddingY={20} backgroundColor={'#141516'}>
			<Container marginX='auto' w='100%'>
				<HStack
					w='100%'
					direction={{ md: 'row', base: 'column' }}
					justifyContent='space-between'
					space={10}
					alignItems='center'
				>
					<Text
						fontSize={{ md: '24px', base: '16px' }}
						textAlign={{ md: 'start', base: 'center' }}
						color='#E3E3E3'
						width={{ md: '700px', base: '' }}
						fontFamily='poppins'
					>
						Enter your email to be notified when we are onboarding a new batch
						of members
					</Text>
					<CustomForm />
				</HStack>
			</Container>
		</Box>
	);
};

export default GetNotified;
