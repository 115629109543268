import { Grid, TextareaAutosize, Button } from '@mui/material';
import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { ReportQuery } from '../../Graphql_services/HelpMutation';
const Help = () => {
	const navigate = useNavigate();
	const [textareaVal, setTextareaVal] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const handleChange = (e) => {
		setTextareaVal(e.target.value);
	};
	const [report] = useMutation(ReportQuery, {
		context: { clientName: 'user' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			console.log(data);
			if (data.sendHelpQuery.success) {
				toast.success('Issue reported successfully');
				setErrMsg('');
				setTextareaVal('');
			} else {
				localStorage.clear();
				navigate('/signin');
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});
	const sendReport = () => {
		if (textareaVal.length > 0) {
			report({
				variables: {
					report: textareaVal,
				},
			});
		} else {
			setErrMsg('Field is required ');
		}
	};

	return (
		<Grid container className={styles.bgBlack}>
			<Grid item xs={12} lg={12} px={'3%'} py={'2%'}>
				<Grid className={styles.content}>
					<p className={styles.heading}>Help</p>
					<p className={styles.subHeading}>Report An Issue</p>
					<TextareaAutosize
						aria-label='minimum height'
						minRows={10}
						value={textareaVal}
						onChange={handleChange}
						className={styles.textarea}
					/>
					<p className={styles.errMsg}>{errMsg}</p>
					<Button className={styles.feedbackBtn} onClick={sendReport}>
						Feedback
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Help;
