/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Heading,
	HStack,
	Image,
	Menu,
	Text,
} from 'native-base';
import React, { useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { DashboardHeader } from '../../Headers';
import SideBar from '../../SideBar/SideBar';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import youtube from '../../../assets/Feeds/youtube.png';
import twitch from '../../../assets/Feeds/twitch.png';
import umarket from '../../../assets/Feeds/umarket.png';
import PlatformModal from './UploadModal';
import useYoutubeSubscriber from '../../Hooks/Feeds/YoutubeSubscriber';
import ConnectToPlatForm from './ConnectToPlatForm';
import useTwitchSubscriber from '../../Hooks/Feeds/useTwitchSubscriber';
import { getTwitchToken } from '../../../RestApis/Services/Twitch';

function Subscribe() {
	const [showConnectPlatfrom, setSowConnectPlatfrom] = useState(true);
	const [modalFlag, setModalFlag] = useState(false);
	const [modalPlatform, setModalPlatform] = useState();

	const [
		isYoutubeLoading,
		isConnectedYoutube,
		youtubeSubscriber,
		youtubeSubscribe,
	] = useYoutubeSubscriber();

	const [isTwitchLoading, isConnectedTwitch, twitchSubscribers] =
		useTwitchSubscriber();

	const onSuccess = (data) => {
		console.log('google login.....', data);
		const { accessToken } = data;
		console.log('access token ...', accessToken);
		youtubeSubscribe(accessToken);
	};
	const { signIn, loaded } = useGoogleLogin({
		onSuccess: onSuccess,
		clientId:
			'531177032810-5ectpdh8s33qmqkd0adct3gpjl0g9sju.apps.googleusercontent.com',
		scope: 'https://www.googleapis.com/auth/youtube',
		cookiePolicy: 'single_host_origin',
	});

	const fetchTwichToken = () => {
		const token = localStorage.getItem('token');
		let link = document.createElement('a');
		link.href = `https://api.twitch.tv/kraken/oauth2/authorize?response_type=code&client_id=wmn69jh5nat0c9ms3wyuniniqzbf17&redirect_uri=${process.env.REACT_APP_API_URL}/composition/twitchlogin&scope=user_read&state=${token}`;
		document.body.appendChild(link);
		link.click();
	};

	function PlatformSubscriber(props) {
		const { Subscribers, platform, image } = props;
		return (
			<Box bg={'#141313'} w={'30%'} py={8} rounded={'lg'}>
				<Button
					p={0}
					variant={'ghost'}
					_hover={{ bg: 'transparent' }}
					_pressed={{ bg: 'transparent' }}
					_focus={{ bg: 'transparent' }}
					onPress={() => {
						setModalFlag(true);
						setModalPlatform(platform);
					}}
				>
					<Image my={5} source={image} size={32} alignSelf={'center'} />
					<Heading
						my={2}
						fontSize={{ xl: '4xl', md: '3xl', base: '2xl' }}
						color={'#fff'}
						textTransform={'capitalize'}
						textAlign={'center'}
						fontWeight={'medium'}
						fontFamily={'poppins'}
					>
						{Subscribers}
					</Heading>
				</Button>
			</Box>
		);
	}

	function NotificationMenu() {
		return (
			<Menu
				rounded={'lg'}
				borderColor={'#fff'}
				borderWidth={1}
				alignSelf={'center'}
				color={'#afafaf'}
				// fontSize={{ md: '16px' }}
				w={'400px'}
				mx={3}
				_text={{
					color: '#afafaf',
				}}
				background={'#1C1B1B'}
				placement={'bottom left'}
				trigger={(triggerProps) => {
					return (
						<Button
							variant={'ghost'}
							rounded={'md'}
							_hover={{ bg: 'transparent' }}
							_focused={{ bg: '2ebb55' }}
							_pressed={{ bg: 'transparent' }}
							{...triggerProps}
						>
							<SettingsOutlinedIcon
								style={{ color: '#e3e3e3', fontSize: 25 }}
							/>
						</Button>
					);
				}}
			>
				<Menu.Item
					bg={'#1C1B1B'}
					borderBottomWidth='1px'
					borderBottomColor='#afafaf'
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Heading
						my={2}
						fontSize={{ xl: 'xl', base: 'md' }}
						fontWeight={'normal'}
						fontFamily={'Poppins'}
						textAlign={'left'}
						textTransform={'capitalize'}
						color={'#fff'}
					>
						subscriber notification Settings
					</Heading>
				</Menu.Item>

				<Menu.Item
					bg={'#1C1B1B'}
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Checkbox
						value='one'
						p={0}
						my={1}
						colorScheme={'green'}
						_text={{
							color: '#AFAFAF',
							fontSize: {
								lg: '14px',
								md: '14px',
								base: '12px',
							},
							fontWeight: 'normal',
							fontFamily: 'Poppins',
						}}
					>
						Notify subscribers when I post a new beat or kit
					</Checkbox>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Checkbox
						value='one'
						p={0}
						my={1}
						colorScheme={'green'}
						_text={{
							color: '#AFAFAF',
							fontSize: {
								lg: '14px',
								md: '14px',
								base: '12px',
							},
							fontWeight: 'normal',
							fontFamily: 'Poppins',
						}}
					>
						Notify subscribers when I add a new service
					</Checkbox>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Checkbox
						value='one'
						p={0}
						my={1}
						colorScheme={'green'}
						_text={{
							color: '#AFAFAF',
							fontSize: {
								lg: '14px',
								md: '14px',
								base: '12px',
							},
							fontWeight: 'normal',
							fontFamily: 'Poppins',
						}}
					>
						Notify subscribers when I change my pricing
					</Checkbox>
				</Menu.Item>
				<Menu.Item
					bg={'#1C1B1B'}
					m={0}
					p={0}
					_focus={{
						bg: '#1C1B1B',
					}}
					_hover={{
						bg: '#1C1B1B',
					}}
					_pressed={{
						bg: '#1C1B1B',
					}}
				>
					<Checkbox
						value='one'
						p={0}
						my={1}
						colorScheme={'green'}
						_text={{
							color: '#AFAFAF',
							fontSize: {
								lg: '14px',
								md: '14px',
								base: '12px',
							},
							fontWeight: 'normal',
							fontFamily: 'Poppins',
						}}
					>
						Notify subscribers when I post an opportunity
					</Checkbox>
				</Menu.Item>
			</Menu>
		);
	}

	return (
		<Box w={'100%'}>
			<Box w={'90%'} alignSelf={'center'} py={5}>
				<HStack w={'100%'} justifyContent={'space-between'}>
					<Heading
						my={5}
						fontSize={{ xl: '4xl', md: '3xl', base: '2xl' }}
						color={'#fff'}
						textTransform={'capitalize'}
						fontFamily={'poppins'}
					>
						Subscribe
					</Heading>
					<NotificationMenu />
				</HStack>
				{(isConnectedYoutube || isConnectedTwitch) && (
					<>
						<Heading
							my={3}
							fontSize={{ xl: '2xl', base: 'lg' }}
							fontWeight={'normal'}
							fontFamily={'Poppins'}
							textAlign={'left'}
							textTransform={'capitalize'}
							color={'#fff'}
						>
							Total Number Of subscribers
						</Heading>
						<Heading
							my={2}
							fontSize={{ xl: '4xl', md: '3xl', base: '2xl' }}
							color={'#2ebb55'}
							textTransform={'capitalize'}
							fontFamily={'poppins'}
						>
							{(youtubeSubscriber || 0) + (twitchSubscribers + 0)}
						</Heading>
					</>
				)}
				<HStack w={'100%'} style={{ gap: '15px' }}>
					{isConnectedYoutube ? (
						<PlatformSubscriber
							Subscribers={youtubeSubscriber}
							platform={'youtube'}
							image={youtube}
						/>
					) : (
						<ConnectToPlatForm
							buttonText={'CONNECT WITH YOUTUBE'}
							imageSrc={youtube}
							handler={signIn}
							checkboxText={'Notify my subscribers when I upload a new video'}
						/>
					)}

					{isConnectedTwitch ? (
						<PlatformSubscriber
							Subscribers={twitchSubscribers}
							platform={'twitch'}
							image={twitch}
						/>
					) : (
						<ConnectToPlatForm
							buttonText={'CONNECT WITH TWITCH'}
							imageSrc={twitch}
							handler={fetchTwichToken}
							checkboxText={'Notify my subscribers when Istart streaming'}
						/>
					)}
				</HStack>
				{/* {showConnectPlatfrom ? (
                <HStack w={"100%"} style={{gap:'15px'}}>
                  {isConnectedYoutube ? (
                    <PlatformSubscriber
                      Subscribers={youtubeSubscriber}
                      platform={"youtube"}
                      image={youtube}
                    />
                  ) : (
                    <ConnectToPlatForm
                      buttonText={"CONNECT WITH YOUTUBE"}
                      imageSrc={youtube}
                      checkboxText={
                        " Notify my subscribers when I upload a new video"
                      }
                    />
                  )}

                  {isConnectedTwitch ? (
                    <PlatformSubscriber
                      Subscribers={twitchSubscribers}
                      platform={"twitch"}
                      image={twitch}
                    />
                  ) : (
                    <ConnectToPlatForm
                      buttonText={"CONNECT WITH TWITCH"}
                      imageSrc={twitch}
                      checkboxText={
                        " Notify my subscribers when I upload a new video"
                      }
                    />
                  )}
                </HStack>
              ) : (
                <Box>
                  <Heading
                    my={3}
                    fontSize={{ xl: "2xl", base: "lg" }}
                    fontWeight={"normal"}
                    fontFamily={"Poppins"}
                    textAlign={"left"}
                    textTransform={"capitalize"}
                    color={"#fff"}
                  >
                    Total Number Of subscribers
                  </Heading>
                  <Heading
                    my={2}
                    fontSize={{ xl: "4xl", md: "3xl", base: "2xl" }}
                    color={"#2ebb55"}
                    textTransform={"capitalize"}
                    fontFamily={"poppins"}
                  >
                    13.25k
                  </Heading>
                  <HStack my={5} justifyContent={"space-between"}>
                    <PlatformSubscriber
                      Subscribers={"1.12k"}
                      platform={"youtube"}
                      image={youtube}
                    />
                    <PlatformSubscriber
                      Subscribers={"12.3k"}
                      platform={"twitch"}
                      image={twitch}
                    />
                    <PlatformSubscriber
                      Subscribers={"108"}
                      platform={"united market"}
                      image={umarket}
                    />
                  </HStack>
                </Box>
              )} */}
			</Box>
			<PlatformModal
				showUploadModal={modalFlag}
				closeUploadModal={() => setModalFlag(false)}
				platform={modalPlatform}
			/>
		</Box>
	);
}

export default Subscribe;
