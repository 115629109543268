import { gql } from "@apollo/client";

export const GETFAVOURITEARTIST = gql`
  query {
    getMyFavoriteArtists {
      success
      message
      isTokenExpired
      favoriteArtists
    }
  }
`;
