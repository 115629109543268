import React from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Button,
  ZStack,
  Divider,
  Input,
  Menu,
  FormControl,
} from "native-base";
import { ProfileHeaderIcon } from "../ProfileHeader/ProfileHeaderIcon";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Textfield from "../../Textfield"
import { useState } from "react";
import { borderColor } from "@mui/system";

export const FeildInput = (props) => {
//   const [name, setName] = useState("");
//   const [position, setPosition] = useState("auto");
//   return (
//     <Box bg={"#000201"} w={"100%"} py={5}>
//       <HStack w={"100%"} justifyContent={"space-between"}>
//         <Heading color={"#AFAFAF"} fontWeight={"medium"} fontSize={"2xl"}>
//           {props.title}
//         </Heading>
//         {props.info && (
//           <Menu
//             rounded={"2xl"}
//             bg={"#1C1B1B"}
//             // bg={'#000'}
//             py={0}
//             w={'40%'}
//             alignSelf={'flex-end'}
//             shouldOverlapWithTrigger={false} // @ts-ignore
//             placement={'left top'}
//             trigger={(triggerProps) => {
//               return (
//                 <Button
//                   //bg={'#000'}
//                   // _pressed={{ bg: '#284B75' }}
//                   p={0}
//                   pt={1}
//                   _focus={{ borderColor: "transparent" }}
//                   _pressed={{ bg: "" }}
//                   _hover={{ bg: "" }}
//                   variant="ghost"
//                   size={"md"}
//                   alignSelf="center"
//                   startIcon={<InfoOutlinedIcon sx={{ color: "#afafaf" }} />}
//                   {...triggerProps}
//                 />
//               );
//             }}
//           >
//             <Menu.Item p={0} rounded={"xl"} _focus={{bg:'transparent'}} bg={"#1C1B1B"}>
//             <Heading color={'#afafaf'}  fontSize={'3xs'} fontWeight={'normal'} textAlign={'left'}>
//               {'Let us know anything you have accomplished in music production space'}
//             </Heading>
//             </Menu.Item>
//           </Menu>
//         )}
//         {props.social && (
//           <HStack>
//             <Heading
//               alignSelf={"center"}
//               color={"#2EBB55"}
//               fontSize={"lg"}
//               fontWeight={"normal"}
//               textAlign={"center"}
//             >
//               {"(Log in with)"}
//             </Heading>

//             <Button
//               p={0}
//               variant={"ghost"}
//               rounded={"full"}
//               mx={{ lg: "2", base: "1" }}
//               _hover={{ bg: "#AFAFAF" }}
//             >
//               <Image
//                 source={require("../../../assets/Profile/ProfileForm/social1.png")}
//                 size={{ lg: "6", base: "3" }}
//               />
//             </Button>
//             <Button
//               p={0}
//               variant={"ghost"}
//               rounded={"full"}
//               mx={{ lg: "2", base: "1" }}
//               _hover={{ bg: "#AFAFAF" }}
//             >
//               <Image
//                 source={require("../../../assets/Profile/ProfileForm/social2.png")}
//                 size={{ lg: "6", base: "3" }}
//               />
//             </Button>
//             <Button
//               p={0}
//               variant={"ghost"}
//               rounded={"full"}
//               mx={{ lg: "2", base: "1" }}
//               _hover={{ bg: "#AFAFAF" }}
//             >
//               <Image
//                 source={require("../../../assets/Profile/ProfileForm/social3.png")}
//                 size={{ lg: "6", base: "3" }}
//               />
//             </Button>
//           </HStack>
//         )}
//       </HStack>
  

  const {label,color,control,type,info,social}=props
  const [name, setName] = useState('');  
  return (
    <Box bg={"#000201"} w={"100%"} py={5}>
        <FormControl>
        <HStack w={"100%"} justifyContent={"space-between"}>
      <FormControl.Label
        fontFamily="poppins"
        // mb={2}

        fontWeight="semibold"
        _text={{
          fontSize: { lg: "xl", md: "1rem", base: "1.2rem" },
          color: color,
        }}
        textTransform={"capitalize"}
      >
        {label}
      </FormControl.Label>
      {info && (
          <Menu
            rounded={"2xl"}
            bg={"#1C1B1B"}
            // bg={'#000'}
            py={0}
            w={'40%'}
            alignSelf={'flex-end'}
            shouldOverlapWithTrigger={false} // @ts-ignore
            placement={'left top'}
            trigger={(triggerProps) => {
              return (
                <Button
                  //bg={'#000'}
                  // _pressed={{ bg: '#284B75' }}
                  p={0}
                  pt={1}
                  _focus={{ borderColor: "transparent" }}
                  _pressed={{ bg: "" }}
                  _hover={{ bg: "" }}
                  variant="ghost"
                  size={"md"}
                  alignSelf="center"
                  startIcon={<InfoOutlinedIcon sx={{ color: "#afafaf" }} />}
                  {...triggerProps}
                />
              );
            }}
          >
            <Menu.Item p={0} rounded={"xl"} _focus={{bg:'transparent'}} bg={"#1C1B1B"}>
            <Heading color={'#afafaf'}  fontSize={'3xs'} fontWeight={'normal'} textAlign={'left'}>
              {'Let us know anything you have accomplished in music production space'}
            </Heading>
            </Menu.Item>
          </Menu>
        )}
      {social && (
          <HStack>
            <Heading
              alignSelf={"center"}
              color={"#2EBB55"}
              fontSize={"lg"}
              fontWeight={"normal"}
              textAlign={"center"}
            >
              {"(Log in with)"}
            </Heading>

            <Button
              p={0}
              variant={"ghost"}
              rounded={"full"}
              mx={{ lg: "2", base: "1" }}
              _hover={{ bg: "#AFAFAF" }}
            >
              <Image
                source={require("../../../assets/Profile/ProfileForm/social1.png")}
                size={{ lg: "6", base: "3" }}
              />
            </Button>
            <Button
              p={0}
              variant={"ghost"}
              rounded={"full"}
              mx={{ lg: "2", base: "1" }}
              _hover={{ bg: "#AFAFAF" }}
            >
              <Image
                source={require("../../../assets/Profile/ProfileForm/social2.png")}
                size={{ lg: "6", base: "3" }}
              />
            </Button>
            <Button
              p={0}
              variant={"ghost"}
              rounded={"full"}
              mx={{ lg: "2", base: "1" }}
              _hover={{ bg: "#AFAFAF" }}
            >
              <Image
                source={require("../../../assets/Profile/ProfileForm/social3.png")}
                size={{ lg: "6", base: "3" }}
              />
            </Button>
          </HStack>
        )}
      </HStack>
  <Controller
        control={control}
       
        render={({ field: { onChange, value, onBlur } }) => (
      
          <Input
     
            // type={props.type === "password" ? "password" : ""}
            onChangeText={onChange}
            _text={{ fontSize: "1rem" }}
            fontSize={{ lg: "18px", md: "16px", base: "14px" }}
            color={color}
            onBlur={onBlur}
            borderColor="#AFAFAF"
            height={{
              xl: "52.9px",
              base: "45px",
             
            }}
        
            
        //  InputLeftElement={props.type==="phone" && <CountryCodes/>}
             
            value={value}
          />
        )}
        name={type}
      />
     
    </FormControl>
      {/* <Heading color={'#AFAFAF'} fontWeight={'medium'} fontSize={'2xl'}>{props.title}</Heading>
      <Textfield/> */}
      {/* <Input
       
        // width={"100%"}
        py={4}
        borderWidth={1}
        borderColor={"#AFAFAF"}
        _hover={{ borderColor: "#AFAFAF" }}
        _focus={{ bg: "transparent" }}
        rounded={"lg"}
        mt={2}
        mb={1}
        value={name}
        color={"#AFAFAF"}
        onChangeText={(text) => setName(text)}
        onEndEditing={() => props.UserName(name)}
        fontSize={"md"}

        
       // placeholderTextColor={"#555555"}

      /> */}
    </Box>
  );
};
