import { Box } from '@mui/system';
import { HStack, Image, Text, VStack, Link, Input, Button } from 'native-base';
import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import Footer from '../components/Footer/Footer';
import GetNotified from '../components/Getnotified/GetNotified';

import HeaderComp from '../components/Header/HeaderComp';

const Artists = () => {
	const distribution = [
		'Distribution',
		'A&R Staff',
		'Premier Playlisting powered by \nAudioSalad x Merline',
		'Custom Royality Splits',
		'Publishing Administration',
		'Licensing  + Sync Opportunities',
		'Cover Song Licensing',
		'Custom One Link for release',
		'Get your Flobal raning as artist',
		'Advances - Artists are alllowed to maitain while \nUnited Market licesnses the catalog for \nan agreed term',
		'Gain access to industry leading technology',
		'Exclusive opportunites to work with sports \norganizations',
	];

	const features = [
		{
			imag: 'https://o.remove.bg/downloads/4826d300-7c80-4425-969e-e922e39f0b4b/8-83812_hand-dollar-comments-hand-with-dollar-icon-removebg-preview.png',
			text: 'Get and advance upto 8x your annual streaming income.',
		},

		{
			imag: 'https://cdn-icons-png.flaticon.com/512/20/20426.png',
			text: 'Keep 100% of ownership of your masters, publishing, touring, merch and sync profiles.',
		},

		{
			imag: 'https://static.thenounproject.com/png/492234-200.png',
			text: "Customize your contract's team length, content, and monthly income levels.",
		},
		{
			imag: 'https://cdn-icons-png.flaticon.com/512/506/506431.png',
			text: 'Use your own service, marketing, and production partners.',
		},
	];

	return (
		<>
			<HeaderComp />
			<VStack w={'100%'} bg={'#000'}>
				<VStack alignSelf={'center'} bg={'#000'} w={['95%', '90%', '100%']}>
					<VStack
						alignSelf={'center'}
						w={'100%'}
						bg={'#000'}
						height={['200', '400', '950']}
					>
						<Image
							width={'100%'}
							h={'full'}
							alignSelf={'center'}
							opacity={25}
							source={{
								uri: 'https://robbreport.com/wp-content/uploads/2022/09/378WE_MusicRoom.jpg',
							}}
						/>
						<HStack
							variant={'unstyled'}
							position={'absolute'}
							bottom='200'
							left='150'
							bg={'transparent'}
							h={'2/5'}
							w={'30%'}
							alignSelf={'flex-start'}
						>
							<VStack>
								<Text
									fontSize={{ lg: '50px', md: '16px', sm: '12px' }}
									fontWeight={'semibold'}
									color='white'
									mx={5}
								>
									DISTRIBUTE WITH
								</Text>
								<Text
									fontSize={{ lg: '50px', md: '16px', sm: '12px' }}
									fontWeight={'semibold'}
									color='#2EBA55'
									mx={5}
								>
									UNITED MARKET
								</Text>
								<Text
									fontSize={{ lg: '24px', md: '16px', sm: '12px' }}
									color='#afafaf'
									alignSelf={'center'}
									mx={5}
								>
									Access future earnings from your catalog and <br />
									unreleased music to invest in yourself
									<br />
									<br />
									Retain 100% ownership of your music
								</Text>
							</VStack>
						</HStack>
						<HStack
							variant={'unstyled'}
							position={'absolute'}
							top='100'
							right='150'
							bg={'#afafaf'}
							h={'4/5'}
							w={'35%'}
							alignSelf={'flex-end'}
							backgroundColor={'#1C1B1B'}
						>
							<VStack mx={5} my={10}>
								{distribution.map((dist, index) => (
									<HStack key={index} flexWrap={'wrap'} px={5} mx={2} my={2}>
										<AiFillCheckCircle size={20} mx={1} color='#2EBA55' />
										<Text
											fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
											color='#afafaf'
											alignSelf={'center'}
											mx={5}
										>
											{dist}
										</Text>
									</HStack>
								))}
							</VStack>
						</HStack>
					</VStack>

					<VStack
						alignSelf={'center'}
						bg={'#000'}
						w={'90%'}
						height={['100', '400', '400']}
					>
						<HStack
							w={'90%'}
							justifyContent={'space-evenly'}
							alignSelf={'center'}
							my={20}
						>
							{features &&
								features.map((feat, index) => (
									<VStack
										key={index}
										w={'20%'}
										backgroundColor={'#1C1B1B'}
										h={'270'}
										rounded={'md'}
										px={5}
										py={5}
									>
										<Image
											w={'30%'}
											size={100}
											borderRadius={50}
											px={2}
											py={2}
											height={{ lg: '80px', sm: '50px', base: '30px' }}
											width={{ lg: '80px', sm: '50px', base: '30px' }}
											bgColor={'#636363'}
											// alignSelf={"center"}
											mx={5}
											source={
												feat.imag
													? { uri: feat.imag }
													: require('../assets/Images/1cover.png')
											}
											alt='User Profile Pic'
										/>
										<Text
											fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
											color='#afafaf'
											alignSelf={'center'}
											mx={2}
											my={5}
										>
											{feat.text}
										</Text>
									</VStack>
								))}
						</HStack>
					</VStack>

					<VStack
						alignSelf={'center'}
						bg={'#000'}
						w={'90%'}
						height={['250', '400', '900']}
					>
						{/* <Image  
    alignSelf={"center"}        
    width='1281px'
    height={["250","450","886"]}
    resizeMode='contain'
    source={require('../assets/Images/flows.png')}  alt='image' /> */}
						<HStack px={10} py={10} mx={10}>
							<VStack mx={20} w={'50%'}>
								<Text
									fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
									color='#afafaf'
									mx={5}
									my={5}
								>
									S T E P 1
								</Text>
								<Text
									fontSize={{ lg: '36px', md: '16px', sm: '12px' }}
									color='white'
									fontWeight={'semibold'}
									mx={5}
									my={5}
								>
									SEE IF YOU QUALIFY FOR AN ADVANCE
								</Text>
								<Text
									fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
									color='#afafaf'
									mx={5}
									my={5}
								>
									Choose the numbers of years, the music you want to include,
									and if you want to recive income during the term, it's all
									upto you!
								</Text>
							</VStack>
							<VStack w={'50%'} px={10} py={10}>
								<Image
									width={'50%'}
									h={'full'}
									alignSelf={'center'}
									resizeMode={'contain'}
									// opacity={25}
									source={{
										uri: 'https://www.netitltd.com/wp-content/uploads/2017/03/icon-product-quality-250x250.png',
									}}
								/>
								{/* source = {{uri: "https://o.remove.bg/downloads/32f84608-3d6c-45e3-ae75-a688072f0f7a/print-142152378-removebg-preview.png"}} /> */}
							</VStack>
						</HStack>
						<HStack px={10} py={10} mx={10}>
							<VStack w={'50%'} px={10} py={10}>
								<Image
									width={'50%'}
									h={'full'}
									alignSelf={'center'}
									opacity={25}
									source={{
										uri: 'https://img.icons8.com/emoji/256/heavy-dollar-sign-emoji.png',
									}}
								/>
							</VStack>
							<VStack mx={20} w={'50%'}>
								<Text
									fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
									color='#afafaf'
									mx={5}
									my={5}
								>
									S T E P 2
								</Text>
								<Text
									fontSize={{ lg: '36px', md: '16px', sm: '12px' }}
									color='white'
									fontWeight={'semibold'}
									mx={5}
									my={5}
								>
									GET YOUR MONEY AS SOON AS THE NEXT DAY
								</Text>
								<Text
									fontSize={{ lg: '20px', md: '16px', sm: '12px' }}
									color='#afafaf'
									mx={5}
									my={5}
								>
									We will check them and send your contract. Signing is easy
									with an electronic signature.
								</Text>
							</VStack>
						</HStack>
						<HStack alignSelf={'center'}>
							<Text
								fontSize={{ lg: '36px', md: '16px', sm: '12px' }}
								color='white'
								fontWeight={'semibold'}
								my={5}
								alignSelf={'center'}
							>
								HOW IS
							</Text>
							<Text
								fontSize={{ lg: '36px', md: '16px', sm: '12px' }}
								color='#2EBA55'
								fontWeight={'semibold'}
								my={5}
								alignSelf={'center'}
							>
								{' UNITED MARKET '}
							</Text>
							<Text
								fontSize={{ lg: '36px', md: '16px', sm: '12px' }}
								color='white'
								fontWeight={'semibold'}
								my={5}
								alignSelf={'center'}
							>
								DIFFERENT?
							</Text>
						</HStack>
					</VStack>

					<VStack
						alignSelf={'center'}
						bg={'#000'}
						w={'90%'}
						height={['250', '500', '1050']}
					>
						<Image
							alignSelf={'center'}
							width='1331px'
							height={['250', '500', '1028']}
							resizeMode='contain'
							source={require('../assets/Images/flow.png')}
							alt='image'
						/>
					</VStack>
					<VStack
						alignSelf={'center'}
						bg={'#000'}
						w={'100%'}
						height={['100', '100', '300']}
					></VStack>
					<GetNotified />

					<Footer />
				</VStack>
			</VStack>
		</>
	);
};
export default Artists;
